/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight path Information Query
 */
import {Query} from '../types';
import {MapPointType} from '../../../utils/MapUtil';

export interface FlightPathInfo {
  availability: string;
  endTstamp: string;
  events: Array<any>;
  isDualBand: string;
  isLanded: string;
  lat: number;
  lng: number;
  network_type: string;
  startTstamp: string;
  status: string;
  terminal_id: string;
  timestamp: string;
  isRoaming?: boolean;
}


export interface FlightPathInfoDataProps {
  start: FlightPathInfo;
  end: FlightPathInfo;
  flightPath: Array<FlightPathInfo>;
  futureFlightPath: Array<MapPointType>;
  origin?: MapPointType;
  destination?: MapPointType;
}

export const useFlightPathInfoQuery: Query<FlightPathInfoDataProps> = {
  route: `fleetMap/flightPath`,
  transform: null
};

export default useFlightPathInfoQuery;
