/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: App Reducer
 */
import {AirportsType} from '../../utils/MapUtil';

export const APP_STORE_CONTEXT = 'app';

export interface AppState {
  menuOpened: boolean;
  isInternalLink: boolean;
  airports: AirportsType;
}

export const InitialAppState: AppState = {
  menuOpened: false,
  isInternalLink: false,
  airports: undefined
};

export const SessionStoreKeys: string[] = [];

export enum AppAction {
  SET_MENU_OPENED = 'SET_MENU_OPENED',
  PROCESS_URL_PARAMS = 'PROCESS_URL_PARAMS',
  SET_AIRPORTS = 'SET_AIRPORTS'
}

export const filterHistory = (currentState: any) => ({
  ...currentState,
  app: {
    ...currentState.app
  }
});

/**
 * Menu action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const AppReducer = (state: AppState, action: any): AppState => {
  console.debug(action.type);
  switch (action.type) {
    case AppAction.SET_MENU_OPENED:
      state = {
        ...state,
        menuOpened: action.payload
      };
      break;

    case AppAction.PROCESS_URL_PARAMS:
      const {isInternalLink} = action.payload.params;
      let newState = {};
      // make sure the url parm exists (params are always strings) and don't set unless it's different
      if (typeof isInternalLink === 'string' && isInternalLink !== String(state.isInternalLink)) {
        newState = {
          ...newState,
          isInternalLink: isInternalLink === 'true'
        };
      }
      state = {
        ...state,
        ...newState
      };
      break;
    case AppAction.SET_AIRPORTS:
      return {
        ...state,
        airports: action.payload.airports
      };
    default:
      break;
  }
  return state;
};
