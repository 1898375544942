/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Use card display hook
 */

import {useCallback, useMemo} from 'react';
import DataUsageCard from './cards/DataUsageCard';
import DownloadUsageCard from './cards/DownloadUsageCard';
import UploadUsageCard from './cards/UploadUsageCard';
import PingLatencyCard from './cards/PingLatencyCard';
import TrafficCompositionCard from './cards/TrafficCompositionCard';
import SatelliteHealthCard from './cards/SatelliteHealthCard';

import {DataUsageCardView, FlightDetailsAction} from '../../store/reducers/FlightDetailsReducer';
import CombinedUsageCard from './cards/CombinedUsageCard';
import {useStore} from '../../store/Store';
import AntennaEncoderCard from './cards/AntennaEncoderCard';

const PAGE_HEADER_HEIGHT = 53;
const FLIGHT_DETAILS_ABS_INTERNAL_CARD_HEIGHT = 296;
const FLIGHT_DETAILS_ABS_EXTERNAL_CARD_HEIGHT = 220;
const FLIGHT_DETAILS_ABS_SPACE_BETWEEN_HEIGHT = 16;
interface CardInterface {
  id: string;
  hash: string;
  component: (any) => JSX.Element;
  title: string;
  display: boolean;
  disabled: boolean;
  isInternal: boolean;
  allowForVar?: boolean;
}

/**
 * Reorders a list of items by moving 1 item from an index to another index
 * @param list List
 * @param startIndex Start index of item
 * @param endIndex End index of item
 * @returns New list
 */
const reorder = <Type>(list: Type[], startIndex, endIndex): Type[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Creates a unique 4 char string from the input text
 * based on djb2 hash http://www.cse.yorku.ca/~oz/hash.html
 * @param plainText string
 * @returns 4 char code
 */
const simpleHash = (plainText: string) =>
  plainText
    .split('')
    .reduce((hash, ch) => (hash << 5) + hash + ch.charCodeAt(0), 5381)
    .toString(16)
    .slice(-4);

const initialCardDetails: CardInterface[] = [
  {
    id: 'SatelliteHealthCard',
    title: 'Satellite Beam Number Used',
    component: SatelliteHealthCard,
    display: true,
    disabled: false,
    isInternal: true,
    allowForVar: true
  },
  {
    id: 'CombinedUsageCard',
    title: 'Data Usage',
    component: CombinedUsageCard,
    display: true,
    disabled: false,
    isInternal: false
  },
  {
    id: 'DownloadUsageCard',
    title: 'Download Usage',
    component: DownloadUsageCard,
    display: true,
    disabled: false,
    isInternal: false
  },
  {
    id: 'UploadUsageCard',
    title: 'Upload Usage',
    component: UploadUsageCard,
    display: true,
    disabled: false,
    isInternal: false
  },
  {
    id: 'DataUsageCard',
    title: 'Cumulative Data Usage',
    component: DataUsageCard,
    display: true,
    disabled: false,
    isInternal: false
  },
  {
    id: 'TrafficCompositionCard',
    title: 'Traffic Composition',
    component: TrafficCompositionCard,
    display: true,
    disabled: false,
    isInternal: false
  },
  {
    id: 'PingLatencyCard',
    title: 'Ping Latency',
    component: PingLatencyCard,
    display: true,
    disabled: false,
    isInternal: false
  },
  {
    id: 'AntennaEncoderCard',
    title: 'Antenna Pointing',
    component: AntennaEncoderCard,
    display: true,
    disabled: false,
    isInternal: false
  }
].map((row) => ({
  ...row,
  hash: simpleHash(row.id)
}));

const useCardDisplay = (
  cardOverallOrder: string[],
  hiddenCards: string[],
  dispatch: (IAction) => void,
  isInternal: boolean,
  isValueAddedReseller: boolean,
  showChartForKaBand: boolean
): {onDragEnd: (result: any) => void; cards: CardInterface[]; height: number} => {
  const {store} = useStore();
  const dataUsageCardView = store.flightDetails.dataUsageCardView;
  const {cards, cardOrder, displayHeight} = useMemo(() => {
    const displayCards = initialCardDetails.map((card: CardInterface) => ({
      ...card,
      disabled:
        (card.id === 'PingLatencyCard' && !showChartForKaBand) ||
        (card.id === 'AntennaEncoderCard' && !showChartForKaBand) ||
        (card.isInternal &&
          card.allowForVar &&
          ((!card.isInternal && !card.allowForVar) || (!isInternal && !isValueAddedReseller))) ||
        (card.id === 'CombinedUsageCard' && dataUsageCardView === DataUsageCardView.SPLIT_CHART) ||
        (card.id === 'DownloadUsageCard' && dataUsageCardView === DataUsageCardView.MERGE_CHART) ||
        (card.id === 'UploadUsageCard' && dataUsageCardView === DataUsageCardView.MERGE_CHART),
      display: !hiddenCards.includes(card.hash)
    }));
    const cardOrder =
      cardOverallOrder &&
      cardOverallOrder.length === displayCards.length &&
      displayCards.every(({hash}) => cardOverallOrder.includes(hash))
        ? cardOverallOrder.map((hash) => displayCards.findIndex((card) => card.hash === hash))
        : Array.from({length: displayCards.length}, (x, i) => i);

    const cards = cardOrder.map((cardIndex) => displayCards[cardIndex]);

    const displayHeight = cards.reduce((acc: number, card: CardInterface) => {
      return card && card.display && !card.disabled
        ? acc +
            (card.isInternal || card.allowForVar
              ? FLIGHT_DETAILS_ABS_INTERNAL_CARD_HEIGHT
              : FLIGHT_DETAILS_ABS_EXTERNAL_CARD_HEIGHT) +
            FLIGHT_DETAILS_ABS_SPACE_BETWEEN_HEIGHT
        : acc;
    }, -PAGE_HEADER_HEIGHT);

    return {
      cards,
      cardOrder,
      displayHeight
    };
  }, [cardOverallOrder, hiddenCards, showChartForKaBand, isInternal, dataUsageCardView, isValueAddedReseller]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const newOrder = reorder<number>(cardOrder, result.source.index, result.destination.index);
      dispatch({
        type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_ORDER,
        payload: newOrder.map((index) => initialCardDetails[index].hash)
      });
    },

    [cardOrder, dispatch]
  );

  return {
    onDragEnd,
    cards,
    height: displayHeight
  };
};

export default useCardDisplay;
