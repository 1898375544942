/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight Details Page
 */
import {useLocation, useNavigate} from 'react-router-dom';
import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import AddChart from '@mui/icons-material/Addchart';
import WarningIcon from '@mui/icons-material/WarningSharp';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import moment from 'moment';
import {forEach, isNil, last} from 'lodash';
import {DataToolTip, LoadingBar, RightDataToolTip} from '@viasat/insights-components';
import ReactToPrint from 'react-to-print';

import {useHover} from '../../utils/useHover';
import PageContainerTemplate from '../common/layout/page/PageContainerTemplate';
import {
  FLIGHT_DETAILS_BACK_BUTTON_COLOR,
  FLIGHT_DETAILS_BORDER,
  FLIGHT_DETAILS_SECONDARY_GREY,
  FLIGHT_DETAILS_SCROLLBAR_THUMB,
  FLIGHT_DETAILS_BACKGROUND,
  FLIGHT_DETAILS_PRIMARY_BLUE,
  FLIGHT_DETAILS_BACKGROUND_DARK,
  FLIGHT_DETAILS_PRIMARY_BLUE_DARK,
  MODAL_FONT_COLOR,
  DARK_FLIGHT_ALERT_COLOR,
  DARK_FLIGHT_ALERT_ICON_COLOR,
  FLIGHT_DETAILS_DECOMMISSIONED_BANNER_BG_COLOR
} from '../common/theme/Colors';
import BoldSpan from '../common/BoldSpan';
import {ChartTimeSettings, determineIfLiveFlight} from './flightDetailsUtil';
import {
  formatToMoment,
  formatMomentInput,
  DATE_TIME_LOCAL_FORMAT_MINUTES,
  getMinTimestamp,
  getMaxTimestamp,
  DATE_VERBOSE_FULL_FORMAT
} from '../../utils/DateTimeUtils';

import LiveTimeEntry from '../common/LiveTimeEntry';
import {liveTimeOverrideEnabled} from '../../utils/config';
import {
  API_REREQUEST_INTERVAL_MS,
  FlightPhase,
  BAND_KA,
  DEMO_OBFUSCATED_STR_LENGTH,
  NO_DATA_INDICATOR,
  BAND_KU,
  FLIGHT_DETAILS_ID_BASE
} from '../../utils/constants';
import {useStore} from '../../store/Store';
import {safeClearSessionStorage} from '../../utils/app-utils';
import {AircraftMapData, AirportsType} from '../../utils/MapUtil';
import {DisableSelect, HiddenSelect, Span} from '../mapView/aircraftPopups/AircraftPopupStyles';
import ShareButton from '../common/elements/share/ShareButton';
import ViewAsCustomerButton from '../common/elements/viewAsCustomer/ViewAsCustomerButton';
import {selectedAircraftAnimation} from '../mapView/MapStyles';
import {SelectedTotalDataUsageCategories} from './charts/TotalDataUsage';

// Flight Details cards - Left panel
import MiniMap from './cards/MiniMap';
import TailInformation from './TailInformation';
import FlightInformation from './FlightInformation';
import CabinConnectivity from './CabinConnectivity';

// Flight Details cards - Right panel
import MapCard from './cards/MapCard';
import EventsTimelineCard from './cards/EventsTimelineCard';
import {FlightPhaseData} from './charts/EventsTimelineUtils';
import {
  IDataUsageInfo,
  TrafficCompositionData as ITrafficComposition
} from '../flightDetails/charts/TrafficCompositionUtils';

// Flight Details Reducer
import {FlightDetailsAction} from '../../store/reducers/FlightDetailsReducer';

// Flight Details queries
import useTailInfoQuery, {TailInfoDataProps} from '../../store/queries/flightDetails/tailInfoQuery';
import useSatelliteInfoQuery, {SatelliteInfoData} from '../../store/queries/flightDetails/satelliteInfoQuery';
import useFlightPathInfoQuery, {FlightPathInfoDataProps} from '../../store/queries/flightDetails/flightPathInfoQuery';
import usePingLatencyQuery, {IPingLatency} from '../../store/queries/flightDetails/pingLatencyQuery';
import useTrafficCompositionQuery from '../../store/queries/flightDetails/trafficCompositionQuery';
import useDataUsageQuery, {IDataUsage} from '../../store/queries/flightDetails/dataUsageQuery';
import useMonthlyUsageQuery, {IMonthlyUsage} from '../../store/queries/flightDetails/monthlyUsageQuery';
import useInFlightUsageQuery, {IInFlightDataUsage} from '../../store/queries/flightDetails/inFlightUsageQuery';
import {
  useQueryInputsForFlightEntry,
  useQueryInputsForMonthlyUsage,
  useQueryInputsForInFlightUsage,
  useQueryInputsForFlightPathRoamingUsage
} from '../../store/queries/queryUtils';
import useFlightEntryQuery from '../../store/queries/flightDetails/flightEntryQuery';
import {useSatelliteHealthQuery, ISatelliteHealthData} from '../../store/queries/flightDetails/satelliteHealthQuery';
import {getChartTimeSettingsFromMinMaxTimestamps} from './flightDetailsUtil';

// Flight Details query utils
import {
  useQueryInputsForTailAndSatelliteInfo,
  useQueryInputsForFlightPath,
  useQueryInputsForPlAndTc,
  useQueryInputsForDataUsage,
  useQueryInputsForAntennaQueryInfo
} from '../../store/queries/queryUtils';

// Common useFetch hook
import useFetch, {QueryParams} from '../../utils/useFetch';
import HoverLine from './HoverLine';
import {getAircraftStatus} from '../../store/queries/fleetMap/aircraftStatusQuery';
import FlightIdCard from './FlightIdCard';

// View Graphs Panel
import ViewGraphs from './ViewGraphs';

import useCardDisplay from './useCardDisplay';
import useWindowSize from '../../utils/useWindowSize';
import {getElementIdFromSectionBase} from '../../utils/ElementIdUtils';
import useAntennaEncoderAngleQuery, {
  IAntennaEncoderInfo
} from '../../store/queries/flightDetails/antennaEncoderAngleQuery';
import useCabinInfoQuery, {ICabinRouterInfo} from '../../store/queries/flightDetails/cabinRouterInfoQuery';
import useSwVersionsQuery, {ISwVersionsInfo} from '../../store/queries/flightDetails/swVersionsQuery';
import airportsQuery from '../../store/queries/common/airportsQuery';
import {AppAction} from '../../store/reducers/AppReducer';
import useFlightPathRoamingUsageQuery, {
  FlightPathRoamingUsageInfo
} from '../../store/queries/common/flightRoamingUsageQuery';
import {BROWSER_LIST, getBrowserName} from '../../utils/browserUtils';
import StyledButton from '../common/StyledButton';
import {reflowChartCardsForPrint} from '../common/elements/chart/chartUtils';
import {AlertContainer, AlertMessage} from '../common/commonStyles';

const PAGE_HEADER_HEIGHT = 54;
const PAGE_DARK_FLIGHT_HEADER_HEIGHT = 120;
const PAGE_DARK_FLIGHT_LEFT_HEIGHT = 70;
const GUTTER_PADDING = 16;
const CONTENT_SIDEBAR_WIDTH = 352;
const SIDEBAR_TRANSITION_MS = 500;

const SCROLL_CONTAINER_WINDOW_OFFSET_MIN = 119;
const SCROLL_CONTAINER_WINDOW_OFFSET = 487;
const FLIGHT_DETAILS_RIGHT_TOP_HEIGHT_NO_MAP = 470;

const FLIGHT_DETAILS_CENTER_OFFSET_OUTER = 66;
const FLIGHT_DETAILS_RIGHT_BAR_WIDTH = 277;
const FLIGHT_DETAILS_DRAWER_WIDTH = 352;
const FLIGHT_DETAILS_BASE_WIDTH = 120;
const FLIGHT_DETAILS_MENU_WIDTH = 187;

const SELECTABLE_STYLE = `
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      cursor: text;
    `;

const FlightDetailsBody = styled.div<{networkType?: string; browserType?: string}>`
  display: flex;
  width: 100%;
  overflow: hidden;
  @media print {
    transform: ${(props) =>
      BROWSER_LIST.indexOf(props.browserType) > -1
        ? 'translateX(-13%) translateY(-17%) scale(0.65);'
        : 'translateX(0%) translateY(1%) scale(0.95)'};
    height: fit-content;
    width: 1580px;
    right: 10px;
    padding-top: 10px;
    @page {
      size: ${(props) => (BROWSER_LIST.indexOf(props.browserType) > -1 ? 'A4' : 'A3')};
    }
    /* Chrome, Safari */
    -webkit-print-color-adjust: exact !important;
    -webkit-filter: opacity(1);
    /* Firefox */
    color-adjust: exact !important;
    filter: opacity(1);
  }
`;

const FlightDetailsLeft = styled.div<{open: boolean}>`
  border: 1px solid ${FLIGHT_DETAILS_BORDER};
  border-bottom: 0;
  border-left: 0;
  background-color: ${FLIGHT_DETAILS_SECONDARY_GREY};
  width: ${(props) => (props.open ? CONTENT_SIDEBAR_WIDTH : 0)}px;
  min-width: ${(props) => (props.open ? CONTENT_SIDEBAR_WIDTH : 0)}px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: ${FLIGHT_DETAILS_SCROLLBAR_THUMB} ${FLIGHT_DETAILS_SECONDARY_GREY} !important;
  scrollbar-width: thin !important;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &.scroll-container-hover {
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  &::-webkit-scrollbar-track {
    background: ${FLIGHT_DETAILS_SECONDARY_GREY};
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: ${FLIGHT_DETAILS_SCROLLBAR_THUMB};
    visibility: hidden;
  }
  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  position: relative;
  bottom: 1px;
  transition-duration: ${SIDEBAR_TRANSITION_MS}ms;
  transition-timing-function: ease-out;
  @media print {
    height: 1950px;
    background-color: white;
    top: 50px;
  }
`;

const FlightDetailsCenter = styled.div<{width: string; height: number}>`
  width: ${(props) => props.width};
  @media only screen and (max-width: 600px) {
    width: 100vw;
  }
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - ${(props) => props.height}px);
  transition-duration: ${SIDEBAR_TRANSITION_MS}ms;
  transition-timing-function: ease-out;
  @media print {
    height: 100%;
    width: 100%;
  }
`;

const FlightDetailsCenterChartContainer = styled.div<{width: string}>`
  width: 100%;
  min-width: ${(props) => props.width};
`;

const FlightDetailsRight = styled.div<{open: boolean}>`
  width: ${(props) => (props.open ? FLIGHT_DETAILS_RIGHT_BAR_WIDTH : 0)}px;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  transition-duration: ${SIDEBAR_TRANSITION_MS}ms;
  transition-timing-function: ease-out;
  position: fixed;
  right: 0;
  z-index: 2;
  border-left: 1px solid ${FLIGHT_DETAILS_BORDER};
  background: ${FLIGHT_DETAILS_SECONDARY_GREY};
  @media print {
    display: none;
    height: 1850px;
  }
`;

const BackButton = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  padding: 4px;
  cursor: pointer;
  border-radius: 6px;
  color: #626262;
  &:hover {
    background: ${FLIGHT_DETAILS_BACK_BUTTON_COLOR};
  }
`;

const FlightDetailsTitle = styled.div`
  height: 35px;
  margin-left: 10px;
  .bread-crumb {
    height: 10px;
    font-size: 10px;
    opacity: 0.6;
    margin: 0px;
  }
  .flight-number {
    font-size: 18px;
    font-weight: 400;
  }
  .tail-id {
    font-size: 16px;
  }
  ${SELECTABLE_STYLE}
`;

const DatePickerButtonAlt = styled.div`
  margin-left: 210px !important;
  top: 1px;
  position: absolute;
  padding: 12px 12px 12px 0;
  border-right: 1px solid ${FLIGHT_DETAILS_BORDER};
  height: 30px;
  width: 111px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  .month-year-title {
    opacity: 0.6;
    margin-right: 12px;
    text-align: right;
    font-size: 12px;
  }
  ${SELECTABLE_STYLE}
`;

const ChartScrollContainer = styled.div<{width: string; height: number}>`
  height: calc(100vh - ${(props) => props.height - 1}px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  & > div:first-child > div.chart-card {
    margin-top: 0px;
  }
  & > div:last-child {
    margin-bottom: 28px;
  }
  scrollbar-color: ${FLIGHT_DETAILS_SCROLLBAR_THUMB} ${FLIGHT_DETAILS_SECONDARY_GREY} !important;
  scrollbar-width: thin !important;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${FLIGHT_DETAILS_SECONDARY_GREY};
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: ${FLIGHT_DETAILS_SCROLLBAR_THUMB};
  }
  .chart-card {
    width: ${(props) => props.width};
  }
  @media print {
    top: 35px;
    height: 100%;
    &.drawerOpen {
      .chart-card {
        width: 1130px !important;
      }
    }
  }
`;

const DrawerButton = styled.div<{open: boolean; marginLeft: string; mobileMarginLeft: string; right: boolean}>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  z-index: 9999;
  position: fixed;
  margin-left: ${(props) => props.marginLeft};
  @media only screen and (max-width: 600px) {
    margin-left: ${(props) => props.mobileMarginLeft};
  }
  top: 41px;
  transition-duration: ${SIDEBAR_TRANSITION_MS}ms;
  transition-timing-function: ease-out;
  background-color: ${(props) => (props.open ? FLIGHT_DETAILS_BACKGROUND : FLIGHT_DETAILS_PRIMARY_BLUE)};
  border: 1px solid ${FLIGHT_DETAILS_BORDER};
  color: ${(props) => (props.open ? FLIGHT_DETAILS_PRIMARY_BLUE : FLIGHT_DETAILS_BACKGROUND)};
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    background-color: ${(props) => (props.open ? FLIGHT_DETAILS_BACKGROUND_DARK : FLIGHT_DETAILS_PRIMARY_BLUE_DARK)};
  }
  svg {
    right: ${(props) => (props.open ? (props.right ? '1px' : '2px') : props.right ? '2px' : '1px')};
    bottom: 1px;
    position: relative;
    width: 25px;
    height: 24px;
  }
  @media print {
    display: none;
  }
`;

const LeftDetailContainer = styled.div`
  position: relative;
  bottom: 50px;
  padding-top: 50px;
  ${SELECTABLE_STYLE}
  @media print {
    bottom: 0px;
    padding-top: 0px;
  }
`;


const LiveTimeEntryContainer = styled.div`
  position: relative;
  left: 250px;
`;

const PrintHeader = styled.div`
  display: none;
  font-weight: 800;
  font-size: 40px;
  font-family: 'Source Sans Pro';
  @media print {
    display: flex;
    position: absolute;
    top: -5px;
  }
`;

const ButtonTitle = styled.span`
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  color: ${MODAL_FONT_COLOR};
  padding-left: 8px;
  @media only screen and (max-width: 875px) {
    display: none;
  }
`;

const getFlightDetailsCenterScalar = (drawerOpen: boolean, menuOpened: boolean, rightBarOpen: boolean) =>
  FLIGHT_DETAILS_BASE_WIDTH +
  (rightBarOpen ? FLIGHT_DETAILS_RIGHT_BAR_WIDTH : 0) +
  (menuOpened ? FLIGHT_DETAILS_MENU_WIDTH : 0) +
  (drawerOpen ? FLIGHT_DETAILS_DRAWER_WIDTH : 0);

const getFlightDetailsCenterWidth = (
  drawerOpen: boolean,
  menuOpened: boolean,
  rightBarOpen: boolean,
  offset: number = 0
) => `calc(100vw - ${getFlightDetailsCenterScalar(drawerOpen, menuOpened, rightBarOpen) - offset}px)`;

const getFlightDetailsContainerClass = (
  drawerOpen: boolean,
  menuOpened: boolean,
  rightBarOpen: boolean,
  mapMinimized?: boolean
) =>
  [
    drawerOpen ? 'drawerOpen' : '',
    menuOpened ? 'menuOpened' : '',
    rightBarOpen ? 'rightBarOpen' : '',
    mapMinimized ? 'mapMinimized' : ''
  ]
    .filter((i) => i.length)
    .join(' ');

const getDrawerButtonLeft = (isRightBar: boolean, isTargetOpen: boolean, isMenuOpened?: boolean) => {
  let scalar = 0;
  if (isRightBar) {
    scalar = 72 + (isMenuOpened ? 189 : 0) + (isTargetOpen ? 269 : 0);
  } else {
    scalar = isTargetOpen ? 341 : -13;
  }
  return isRightBar ? `calc(100vw - ${scalar}px)` : `${scalar}px`;
};
const getDrawerButtonMobileLeft = (isRightBar: boolean, isTargetOpen: boolean) => {
  let scalar = 0;
  if (isRightBar) {
    scalar = isTargetOpen ? 290 : 25;
  } else {
    scalar = isTargetOpen ? 341 : 7;
  }
  return isRightBar ? `calc(100vw - ${scalar}px)` : `${scalar}px`;
};

interface FlightDetailsPageProps {
  breadCrumbs: string[];
  pathname: string;
  flightId: string;
}

const FlightDetailsPage: React.FC<FlightDetailsPageProps> = ({breadCrumbs, flightId}: FlightDetailsPageProps) => {
  // This will set aircraft pulse animation globally
  selectedAircraftAnimation();

  const {store, dispatch} = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const printComponentRef = useRef();

  const {historicalConnectedStart, timeOverride, cardOrder, hiddenCards} = store.flightDetails;
  const {menuOpened, airports} = store.app;
  const {
    init: {viewAsCustomer, isInternal, isValueAddedReseller}
  } = store;
  const displayInternal = isInternal && !viewAsCustomer;
  const displayValueAddedReseller = isValueAddedReseller;

  if (flightId === null) {
    dispatch({
      type: FlightDetailsAction.INVALID_FLIGHT_IDENTIFIER,
      payload: {intl}
    });
    navigate('/');
  }

  // Flag to avoid showing the loading spinner on every minute refresh of API data
  const [refreshFromInterval, setRefreshFromInterval] = useState<boolean>(false);
  // "Now" Timestamp for the API calls
  const [nowTimeStamp, setNowTimeStamp] = useState<string>(
    timeOverride.enabled
      ? moment(timeOverride.value).format(DATE_TIME_LOCAL_FORMAT_MINUTES)
      : moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES)
  );

  const defaultLiveFlightOffset = {
    startOffSet: 0,
    unit: 0
  };
  const [liveFlightOffset, setLiveFlightOffset] = useState<{
    startOffSet: number;
    unit: number;
  }>(defaultLiveFlightOffset);
  // Flight Details Map Set Minimized flag
  const [isMapMinimized, setMapMinimizedState] = useState(false);

  // Time Override component
  const [isTimeOverrideEnabled, setTimeOverrideState] = useState<boolean>(timeOverride.enabled);
  const [timeOverrideValue, setTimeOverrideValue] = useState<moment.Moment>(moment(timeOverride.value));

  const [dataUsageObject, setDataUsageObject] = useState<IDataUsageInfo>({});

  // Persist Total Data Usage category visibility
  const [visibleCategories, setVisibleCategories] = useState<SelectedTotalDataUsageCategories>({
    streamingGamingUsage: true,
    bsSocialNwUsage: true,
    pdClServicesUsage: true,
    bgAppUpdatesUsage: true,
    othrSpdTestUsage: true,
    vpnUsage: true
  });

  const updateTimeOverride = (
    flightDetailsTimeOverrideEnable: boolean,
    flightDetailsTimeOverrideValue: moment.Moment
  ) => {
    setTimeOverrideState(flightDetailsTimeOverrideEnable);
    setTimeOverrideValue(flightDetailsTimeOverrideValue);
    if (flightDetailsTimeOverrideEnable) {
      setNowTimeStamp(moment(flightDetailsTimeOverrideValue).format(DATE_TIME_LOCAL_FORMAT_MINUTES));
    } else {
      setNowTimeStamp(moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES));
    }
  };

  // useEffect to capture the timeOverride component changes
  useEffect(() => {
    if (isTimeOverrideEnabled) {
      dispatch({type: FlightDetailsAction.SET_TIME_OVERRIDE, payload: timeOverrideValue});
    } else {
      dispatch({type: FlightDetailsAction.RESET_TIME_OVERRIDE, payload: moment.utc()});
    }
  }, [isTimeOverrideEnabled, timeOverrideValue, dispatch]);

  // This will remove the charts tooltip from DOM whenever user navigates to other views
  useEffect(() => {
    return () => {
      document.querySelectorAll('.highcharts-tooltip-container').forEach((el) => el.remove());
    };
  }, []);

  // Query and URL param
  const groupCode = store.customer.current.code ? store.customer.current.code : '';

  useEffect(() => {
    if (store.flightDetails.dataUsageCardView !== null) {
      return;
    } else {
      if (groupCode === 'BIZAV_FLEXJETLLC') {
        dispatch({type: FlightDetailsAction.MERGE_DATA_USAGE_CARD});
      } else {
        dispatch({type: FlightDetailsAction.SPLIT_DATA_USAGE_CARD});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCode]);

  // Determines if cached data should be used while flightEntry is loading
  const canUseCacheWhileLoadingForFlightEntry = useCallback(
    (cacheQueryParams: QueryParams): boolean => {
      return cacheQueryParams.groupCode === groupCode && cacheQueryParams.flightId === flightId;
    },
    [groupCode, flightId]
  );

  // Flight entry
  const flightEntryQueryParams = useQueryInputsForFlightEntry(groupCode, flightId, nowTimeStamp);
  const {
    data: flightEntryData,
    hasError: hasFlightEntryErr,
    isLoading: isFlightEntryLoading
  } = useFetch(useFlightEntryQuery, flightEntryQueryParams, canUseCacheWhileLoadingForFlightEntry);

  const flightConnectionStart = !isNil(flightEntryData?.connectedStart)
    ? flightEntryData?.connectedStart
    : flightEntryData?.actualDepartureTimestamp;
  const flightConnectionEnd = !isNil(flightEntryData?.connectedEnd)
    ? flightEntryData?.connectedEnd
    : !isNil(flightEntryData?.actualArrivalTimestamp)
    ? flightEntryData?.actualArrivalTimestamp
    : flightEntryData?.estimatedArrivalTimestamp;

  const isLiveFlight = determineIfLiveFlight(
    flightEntryData?.flightStart,
    flightEntryData?.flightEnd,
    flightConnectionEnd,
    nowTimeStamp
  );

  const isDualBand = flightEntryData?.networkCapability.includes('Dual');
  const aircraftConnectionEndTimestamp =
    flightEntryData?.lastNetwork === BAND_KU ? flightConnectionEnd : flightEntryData?.pingsConnectedEnd;
  const aircraftConnectionStatus = getAircraftStatus(
    flightEntryData?.last5MinutesConnectivity,
    moment.utc(aircraftConnectionEndTimestamp),
    moment.utc(flightConnectionEnd),
    moment.utc(flightEntryData?.pingsConnectedEnd),
    flightEntryData?.lastFlightPhase === FlightPhase.ON_GROUND,
    flightEntryData?.lastNetwork,
    moment.utc(nowTimeStamp)
  );
  const arePrereqsLoading = isFlightEntryLoading;
  const isLanded =
    !isLiveFlight ||
    flightEntryData?.lastFlightPhase === null ||
    flightEntryData?.lastFlightPhase === FlightPhase.ON_GROUND;

  const emptyParams = useMemo(() => ({}), []);

  // Airports
  const {data: airportsList, isLoading: isAirportsLoading} = useFetch<AirportsType>(airportsQuery, emptyParams);

  useEffect(() => {
    if (isAirportsLoading) return;
    if (airportsList && !isAirportsLoading) {
      dispatch({
        type: AppAction.SET_AIRPORTS,
        payload: {
          airports: airportsList
        }
      });
    }
  }, [dispatch, airportsList, isAirportsLoading]);

  // Callback to determine whether the current cached data for a given query should be returned while a new query is in
  // progress (i.e.loading).
  const canUseCacheWhileLoading = useCallback(
    (cacheQueryParams: QueryParams): boolean => {
      return (
        isLiveFlight &&
        cacheQueryParams.groupCode === groupCode &&
        cacheQueryParams.aircraftId === flightEntryData?.aircraftId
      );
    },
    [isLiveFlight, groupCode, flightEntryData?.aircraftId]
  );

  const tailInfoQueryParams = useQueryInputsForTailAndSatelliteInfo(
    flightEntryData?.aircraftId,
    groupCode,
    flightConnectionStart,
    timeOverride.enabled ? nowTimeStamp : flightConnectionEnd,
    flightEntryData?.isDark,
    flightEntryData?.kaTerminalSwVersion,
    flightEntryData?.kuTerminalSwVersion
  );

  // Tail information
  const {
    data: tailInformation,
    hasError: hasTailInfoErr,
    isLoading: isTailInfoLoading
  } = useFetch<TailInfoDataProps>(useTailInfoQuery, tailInfoQueryParams, canUseCacheWhileLoading);

  const cabinRouterQueryParams = useMemo(() => {
    if (!flightEntryData) return;
    if (flightEntryData && flightEntryData?.networkCapability !== BAND_KU) {
      return tailInfoQueryParams;
    } else {
      return;
    }
  }, [flightEntryData, tailInfoQueryParams]);

  // Cabin Router information
  const {data: cabinRouterInfo, hasError: hasCabinRouterInfoErr} = useFetch<ICabinRouterInfo>(
    useCabinInfoQuery,
    cabinRouterQueryParams,
    canUseCacheWhileLoading
  );

  // Software Versions information
  const {
    data: swVersionsInfo,
    hasError: hasSwVersionError,
    isLoading: isSwVersionsLoading
  } = useFetch<ISwVersionsInfo>(useSwVersionsQuery, tailInfoQueryParams, canUseCacheWhileLoading);

  // Satellite info
  const {
    data: satelliteInformation,
    hasError: hasSatelliteInfoErr,
    isLoading: isSatelliteInfoLoading
  } = useFetch<SatelliteInfoData>(useSatelliteInfoQuery, tailInfoQueryParams, canUseCacheWhileLoading);

  //Satellite Health
  const {
    data: satelliteHealthInfo,

    isLoading: isSatelliteHealthInfoLoading
  } = useFetch<ISatelliteHealthData[]>(useSatelliteHealthQuery, tailInfoQueryParams, canUseCacheWhileLoading);

  // Monthly Usage
  const monthlyUsageQueryParam = useQueryInputsForMonthlyUsage(
    groupCode,
    flightEntryData?.aircraftId,
    moment.utc(flightConnectionStart).startOf('month').format(DATE_TIME_LOCAL_FORMAT_MINUTES),
    timeOverride.enabled ? nowTimeStamp : flightConnectionEnd
  );
  const {
    data: monthlyDataUsage,
    hasError: hasMonthlyUsageErr,
    isLoading: isMonthlyUsageLoading
  } = useFetch<IMonthlyUsage>(useMonthlyUsageQuery, monthlyUsageQueryParam, canUseCacheWhileLoading);

  const inFlightUsageQueryParams = useQueryInputsForInFlightUsage(
    groupCode,
    flightEntryData?.aircraftId,
    flightConnectionStart,
    timeOverride.enabled ? nowTimeStamp : flightConnectionEnd,
    isLanded,
    tailInformation?.is_regional_plan,
    flightEntryData?.isDark
  );

  const {data: inFlightDataUsage} = useFetch<IInFlightDataUsage>(
    useInFlightUsageQuery,
    inFlightUsageQueryParams,
    canUseCacheWhileLoading
  );

  // Data Usage information
  const dataUsageInfoQueryParam = useQueryInputsForDataUsage(
    flightEntryData?.aircraftId,
    flightConnectionStart,
    timeOverride.enabled ? nowTimeStamp : flightConnectionEnd,
    isLanded,
    groupCode,
    flightEntryData?.isDark
  );
  const {data: dataUsageInfo, isLoading: isDataUsageInfoLoading} = useFetch<IDataUsage[]>(
    !arePrereqsLoading ? useDataUsageQuery : null,
    dataUsageInfoQueryParam,
    canUseCacheWhileLoading
  );

  // Flight Path Information
  const flightPathInfoQueryParams = useQueryInputsForFlightPath(
    groupCode,
    flightEntryData?.aircraftId,
    flightConnectionStart,
    timeOverride.enabled ? nowTimeStamp : flightConnectionEnd,
    isLanded,
    flightId,
    flightEntryData?.origin,
    flightEntryData?.destination,
    flightEntryData?.isDark
  );

  const {
    data: flightPathInfo,
    hasError: hasFlightPathInfoErr,
    isLoading: isFlightPathInfoLoading
  } = useFetch<FlightPathInfoDataProps>(
    !arePrereqsLoading ? useFlightPathInfoQuery : null,
    flightPathInfoQueryParams,
    canUseCacheWhileLoading
  );

  // Antenna Encoder information
  const antennaEncoderInfoQueryParam = useQueryInputsForAntennaQueryInfo(
    groupCode,
    flightEntryData?.aircraftId,
    flightConnectionStart,
    timeOverride.enabled ? nowTimeStamp : flightConnectionEnd,
    flightEntryData?.isDark,
    flightEntryData?.networkCapability
  );
  const {data: antennaEncoderInfo, isLoading: isAntennaEncoderInfoLoading} = useFetch<IAntennaEncoderInfo[]>(
    !arePrereqsLoading ? useAntennaEncoderAngleQuery : null,
    antennaEncoderInfoQueryParam,
    canUseCacheWhileLoading
  );

  //flight path roaming data usage
  const flightPahRoamingUsageQueryParams = useQueryInputsForFlightPathRoamingUsage(
    groupCode,
    flightEntryData?.aircraftId,
    flightConnectionStart,
    timeOverride.enabled ? nowTimeStamp : flightConnectionEnd,
    isLanded
  );

  const {data: flightPathRoamingUsageInfo} = useFetch<FlightPathRoamingUsageInfo[]>(
    !arePrereqsLoading ? useFlightPathRoamingUsageQuery : null,
    flightPahRoamingUsageQueryParams,
    canUseCacheWhileLoading
  );

  useEffect(() => {
    forEach(flightPathInfo?.flightPath, (flightEvent) => {
      flightEvent.isRoaming = flightPathRoamingUsageInfo?.find(
        (roamingInfo) => roamingInfo.timestamp === flightEvent.timestamp
      )?.isRoaming;
    });
  }, [flightPathInfo, flightPathRoamingUsageInfo]);

  const flightPathEvents = useMemo(() => {
    let flightEvents = [];
    let flightEventTime = '';

    forEach(flightPathInfo?.flightPath, (flightEvent) => {
      flightEventTime = formatMomentInput(flightEvent?.timestamp, DATE_TIME_LOCAL_FORMAT_MINUTES);
      if (flightEventTime !== 'Invalid date') {
        flightEvents.push({
          eventDate: moment.utc(flightEventTime),
          status: flightEvent?.availability,
          isRoaming: flightPathRoamingUsageInfo?.find(
            (roamingInfo) =>
              formatMomentInput(roamingInfo?.timestamp, DATE_TIME_LOCAL_FORMAT_MINUTES) === flightEventTime
          )?.isRoaming
        });
      }
    });

    return flightEvents;
  }, [flightPathInfo, flightPathRoamingUsageInfo]);

  // Prepare flight details data for live map
  const flightStatusData = flightPathInfo?.end;
  const aircraftMapData: AircraftMapData = {
    aircraftId: flightEntryData?.aircraftId,
    aircraftManufacturer: flightEntryData?.aircraftManufacturer,
    isLabTerminal: flightEntryData?.isLabTerminal,
    aircraftType: flightEntryData?.aircraftType,
    networkCapability: flightEntryData?.networkCapability,
    lastLatitude: flightStatusData?.lat,
    lastLongitude: flightStatusData?.lng,
    tailId: flightEntryData?.tailId,
    serialNumber: flightEntryData?.serialNumber,
    connectedEndTimestamp: flightConnectionEnd,
    connectedStartTimestamp: flightConnectionStart,
    status: aircraftConnectionStatus,
    lastNetwork: flightEntryData?.lastNetwork,
    lastFlightPhase: flightEntryData?.lastFlightPhase,
    flightStartTimestamp: flightEntryData?.flightStart,
    flightEndTimestamp: flightEntryData?.flightEnd,
    lastHeading: flightEntryData?.lastHeading,
    pingsConnectionStartTimestamp: flightEntryData?.pingsConnectedStart,
    pingsConnectionEndTimestamp: flightEntryData?.pingsConnectedEnd
  };

  // Query param for ping latency and traffic composition
  const plAndTcQueryParams = useQueryInputsForPlAndTc(
    groupCode,
    flightEntryData?.aircraftId,
    flightConnectionStart,
    timeOverride.enabled ? nowTimeStamp : flightConnectionEnd,
    isLanded,
    flightEntryData?.isDark
  );
  // Prepare ping latency data
  const {data: pingLatencyInfo, isLoading: isPingLatencyInfoLoading} = useFetch<IPingLatency[]>(
    !arePrereqsLoading && (flightEntryData?.networkCapability === BAND_KA || isDualBand) ? usePingLatencyQuery : null,
    plAndTcQueryParams,
    canUseCacheWhileLoading
  );

  // Prepare traffic composition data
  const {data: trafficCompositionInfo, isLoading: isTrafficCompositionLoading} = useFetch<ITrafficComposition[]>(
    !arePrereqsLoading ? useTrafficCompositionQuery : null,
    plAndTcQueryParams,
    canUseCacheWhileLoading
  );

  useEffect(() => {
    let dataUsageObj = {};

    dataUsageInfo?.forEach((usage) => {
      dataUsageObj[moment(usage['timestamp']).valueOf()] = usage;
    });
    setDataUsageObject(dataUsageObj);
  }, [dataUsageInfo]);

  // Determine the common time-based characteristics for all our charts
  const allChartsTimeSettings: ChartTimeSettings = useMemo(() => {
    const start = getMinTimestamp(
      flightConnectionStart,
      flightPathEvents?.[0]?.eventDate,
      dataUsageInfo?.[0]?.timestamp,
      trafficCompositionInfo?.[0]?.timestamp,
      pingLatencyInfo?.[0]?.timestamp,
      satelliteHealthInfo?.[0]?.tstamp
    );
    const end = getMaxTimestamp(
      timeOverride.enabled ? nowTimeStamp : flightConnectionEnd,
      flightPathEvents && flightPathEvents.length > 0 ? last(flightPathEvents).eventDate : undefined,
      dataUsageInfo && dataUsageInfo.length > 0 ? last(dataUsageInfo).timestamp : undefined,
      trafficCompositionInfo && trafficCompositionInfo.length > 0 ? last(trafficCompositionInfo).timestamp : undefined,
      pingLatencyInfo && pingLatencyInfo.length > 0 ? last(pingLatencyInfo).timestamp : undefined,
      satelliteHealthInfo && satelliteHealthInfo.length > 0 ? last(satelliteHealthInfo).tstamp : undefined
    );

    return getChartTimeSettingsFromMinMaxTimestamps(start, end);
  }, [
    flightPathEvents,
    dataUsageInfo,
    trafficCompositionInfo,
    pingLatencyInfo,
    satelliteHealthInfo,
    nowTimeStamp,
    timeOverride.enabled,
    flightConnectionEnd,
    flightConnectionStart
  ]);

  const flightPhaseData: FlightPhaseData = useMemo(() => {
    const connectionStart = flightConnectionStart;
    const connectionEnd = flightConnectionEnd;
    const flightStart = flightEntryData?.isDark
      ? flightEntryData?.actualDepartureTimestamp
      : flightEntryData?.flightStart;
    const flightEnd = flightEntryData?.isDark ? flightEntryData?.actualArrivalTimestamp : flightEntryData?.flightEnd;
    const cruiseStart = flightEntryData?.cruiseStart;
    const cruiseEnd = flightEntryData?.cruiseEnd;

    const retVal = {
      connectionStart: formatToMoment(connectionStart),
      connectionEnd: formatToMoment(connectionEnd),
      flightStart: formatToMoment(flightStart),
      flightEnd: formatToMoment(flightEnd),
      cruiseStart: formatToMoment(cruiseStart),
      cruiseEnd: formatToMoment(cruiseEnd)
    };

    return retVal;
  }, [flightEntryData, flightConnectionEnd, flightConnectionStart]);

  const hasAnyQueryErr =
    hasFlightEntryErr ||
    hasSatelliteInfoErr ||
    hasMonthlyUsageErr ||
    hasTailInfoErr ||
    hasFlightPathInfoErr ||
    hasCabinRouterInfoErr ||
    hasSwVersionError;

  useEffect(() => {
    if (hasAnyQueryErr) {
      console.error('Error in retrieving flight information data');
      safeClearSessionStorage();

      dispatch({type: FlightDetailsAction.RESET_TIME_OVERRIDE, payload: moment.utc()});

      if (!hasFlightEntryErr) {
        dispatch({
          type: FlightDetailsAction.INVALID_FLIGHT_IDENTIFIER,
          payload: {intl}
        });
        navigate('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAnyQueryErr, hasFlightEntryErr, intl, dispatch, location, isLiveFlight]);

  // Set interval need to add eventTimeline
  useEffect(() => {
    if (isLiveFlight) {
      const interval = setInterval(() => {
        if (!timeOverride.enabled) {
          setNowTimeStamp(moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES));
        }
        setRefreshFromInterval(true);
      }, API_REREQUEST_INTERVAL_MS);
      // You want the changing of the inputs to reset the interval
      return () => clearInterval(interval);
    }
  }, [isLiveFlight, timeOverride]);

  const [isHovered] = useHover();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(
    sessionStorage.flightDetailsDrawerOpen ? sessionStorage.flightDetailsDrawerOpen === 'true' : true
  );

  const setDrawerOpenAction = (open: boolean) => {
    setDrawerOpen(open);
    sessionStorage.flightDetailsDrawerOpen = String(open);

    // We want to delay the resize until after the css has completed
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, SIDEBAR_TRANSITION_MS);
  };

  const renderBreadcrumb = (flightDetected: boolean) => {
    const pageTitle = 'Flight Details';
    const tempBreadCrumb = [];
    breadCrumbs.forEach((breadCrumb) => {
      tempBreadCrumb.push(
        !flightEntryData?.isDark && (flightEntryData?.origin || flightEntryData?.destination)
          ? breadCrumb
          : !flightDetected && !isLiveFlight && breadCrumb === pageTitle
          ? 'Ground Session'
          : breadCrumb
      );
    });

    if (!tempBreadCrumb) return pageTitle;

    if (tempBreadCrumb.length === 1) {
      return <span>{tempBreadCrumb[0]}</span>;
    }

    if (tempBreadCrumb.length === 2) {
      return (
        <span>
          {tempBreadCrumb[0]}&nbsp;&nbsp;&rsaquo;&nbsp;&nbsp;{tempBreadCrumb[1]}
        </span>
      );
    }

    if (tempBreadCrumb.length > 2) {
      return (
        <span>
          {tempBreadCrumb[0]}&nbsp;&nbsp;&rsaquo; ... &rsaquo;&nbsp;&nbsp;{tempBreadCrumb[tempBreadCrumb.length - 1]}
        </span>
      );
    }

    return pageTitle;
  };

  const renderHeader = (headerSerialNumber: string, headerTailId: string, isLoading: boolean) => {
    if (isLoading) {
      return <LoadingBar id={`flight-details-page-flight-id-loading`} width={130} height={15} />;
    }

    return (
      <>
        <BoldSpan>{headerSerialNumber}</BoldSpan> /{' '}
        <Span className="tail-id">
          {headerTailId ? (
            headerSerialNumber.length + headerTailId.length >= DEMO_OBFUSCATED_STR_LENGTH ? (
              <DataToolTip
                arrow
                placement="top"
                enterDelay={250}
                title={
                  <>
                    <div style={{lineHeight: '18px', margin: '11px 13px'}}>
                      <div>
                        <span style={{fontWeight: 600}}>{headerTailId}</span>
                      </div>
                    </div>
                  </>
                }
              >
                <span>
                  {headerTailId.substring(0, 3)}
                  <DisableSelect>...</DisableSelect>
                  <HiddenSelect>{headerTailId.substring(3, headerTailId.length - 3)}</HiddenSelect>
                  {headerTailId.substring(headerTailId.length - 3)}
                </span>
              </DataToolTip>
            ) : (
              headerTailId
            )
          ) : (
            NO_DATA_INDICATOR
          )}
        </Span>
      </>
    );
  };

  const generateDateLabel = (isLoading: boolean, startTime: string, endtime: string) => {
    if (isLoading) {
      return null;
    }
    const startDay = formatMomentInput(startTime, 'D');
    const startYear = formatMomentInput(startTime, 'YYYY');
    const startMonth = formatMomentInput(startTime, 'MMMM');
    const endDay = formatMomentInput(endtime, 'D');
    const endYear = formatMomentInput(endtime, 'YYYY');
    const endMonth = formatMomentInput(endtime, 'MMMM');

    let label: string | JSX.Element = '';
    if (startYear !== endYear) {
      label = (
        <>
          {startDay} {startMonth} {startYear} <br /> {endDay} {endMonth} {endYear}
        </>
      );
    } else if (startMonth !== endMonth) {
      label = (
        <>
          {startDay} {startMonth} <br /> {endDay} {endMonth} {endYear}
        </>
      );
    } else if (startDay !== endDay) {
      label = `${startDay}-${endDay} ${endMonth} ${endYear}`;
    } else {
      label = `${endDay} ${endMonth} ${endYear}`;
    }
    return <div className="month-year-title">{label}</div>;
  };

  const liveMask = isLiveFlight && flightEntryData?.lastFlightPhase !== FlightPhase.ON_GROUND;

  const rightPaneRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const rightPaneLeftEdge = rightPaneRef.current?.getBoundingClientRect().left;

  const [rightBarOpen, setRightBarOpen] = useState<boolean>(
    sessionStorage.flightDetailsRightBarOpen ? sessionStorage.flightDetailsRightBarOpen === 'true' : false
  );

  const setRightBarOpenAction = (open: boolean) => {
    setRightBarOpen(open);
    sessionStorage.flightDetailsRightBarOpen = String(open);

    // We want to delay the resize until after the css has completed
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, SIDEBAR_TRANSITION_MS);
  };
  let cardProps = {};
  const showChartForKaBand = flightEntryData?.networkCapability === BAND_KA || isDualBand;
  const {
    cards,
    onDragEnd,
    height: cardsHeight
  } = useCardDisplay(cardOrder, hiddenCards, dispatch, displayInternal, displayValueAddedReseller, showChartForKaBand);

  let customFilters: any = {
    mapMinimized: encodeURIComponent(isMapMinimized),
    hiddenCards,
    cardOrder
  };
  const historicalFlightDetailFilters = isLiveFlight ? {} : {connectedStart: historicalConnectedStart};
  customFilters = {...customFilters, historicalFlightDetailFilters};

  const {height: windowHeight} = useWindowSize();
  const scrollHeight =
    windowHeight - (isMapMinimized ? SCROLL_CONTAINER_WINDOW_OFFSET_MIN : SCROLL_CONTAINER_WINDOW_OFFSET);

  const overallHeight = Math.max(cardsHeight + FLIGHT_DETAILS_RIGHT_TOP_HEIGHT_NO_MAP, scrollHeight);
  return (
    <PageContainerTemplate
      title={''}
      subtitle=""
      getFullElementId={(name, type) => `detail-pageContainer__${name}-${type}`}
      leftStack={[
        breadCrumbs && breadCrumbs.length > 1 ? (
          <BackButton id="flightDetails--header__back-button" key="back-button" onClick={() => navigate(-1)}>
            <ArrowBack />
          </BackButton>
        ) : (
          <React.Fragment key="back-button" />
        ),
        <FlightDetailsTitle key="flight-button">
          <div className="bread-crumb">{renderBreadcrumb(flightEntryData?.flightDetected)}</div>
          <div id="flightDetails--header__flightNumber-label" className="flight-number">
            {renderHeader(
              flightEntryData?.serialNumber,
              flightEntryData?.tailId,
              (arePrereqsLoading || isTailInfoLoading || isSwVersionsLoading) && !refreshFromInterval
            )}
          </div>
        </FlightDetailsTitle>,
        <DatePickerButtonAlt id="flightDetails--header__datePicker-label" key="date-badge">
          {generateDateLabel(arePrereqsLoading, flightConnectionStart, flightConnectionEnd)}
        </DatePickerButtonAlt>,
        <LiveTimeEntryContainer key="live-time-entry-container">
          {liveTimeOverrideEnabled ? (
            <div key="manual-date-time">
              <LiveTimeEntry
                idBase="flightDetails--time_override_container"
                enable={timeOverride.enabled}
                value={moment(timeOverride.value)}
                onChange={updateTimeOverride}
              />
            </div>
          ) : (
            <></>
          )}
        </LiveTimeEntryContainer>
      ]}
      rightStack={[
        <ViewAsCustomerButton idBase="flightDetails" key={'flightDetails'} />,
        <RightDataToolTip
          id={`flight-details-toggle-rightbar-tooltip`}
          arrow
          placement={rightBarOpen ? 'right' : 'left'}
          enterDelay={300}
          title={
            <div style={{lineHeight: '18px', fontSize: 14, margin: '6px 10px'}}>
              <BoldSpan>{rightBarOpen ? 'Collapse View Graph Panel' : 'Expand View Graph Panel'}</BoldSpan>
            </div>
          }
        >
          <StyledButton
            id={`flight-details-toggle-rightbar`}
            onClick={() => setRightBarOpenAction(!rightBarOpen)}
            key="view-graphs-btn"
          >
            <AddChart sx={{fontSize: 24}} />
            {rightBarOpen ? <ButtonTitle>Hide Graphs</ButtonTitle> : <ButtonTitle>View Graphs</ButtonTitle>}
          </StyledButton>
        </RightDataToolTip>,
        <ReactToPrint
          trigger={() => (
            <StyledButton key="print-btn">
              <PrintIcon style={{fontSize: 24}} />
              <ButtonTitle className="buttonText">Print</ButtonTitle>
            </StyledButton>
          )}
          content={() => printComponentRef.current}
          onBeforeGetContent={() => {
            reflowChartCardsForPrint('1146px');
          }}
          onAfterPrint={() => {
            reflowChartCardsForPrint('auto');
          }}
        />,

        <ShareButton
          idBase="flightDetails"
          key={'ShareFlightDetails'}
          disabled={false}
          customFilters={customFilters}
          skipCustomerFilters={true}
          skipDateFilters={true}
        />
      ]}
    >
      <FlightDetailsBody
        key="flight-details-body"
        ref={printComponentRef}
        networkType={flightEntryData?.networkCapability}
        browserType={getBrowserName()}
      >
        <PrintHeader>
          Flight Details for S/N: &nbsp;{flightEntryData?.serialNumber} / {flightEntryData?.tailId}{' '}
        </PrintHeader>
        <RightDataToolTip
          id={`flight-details-toggle-drawer-tooltip`}
          arrow
          placement={drawerOpen ? 'left' : 'right'}
          enterDelay={300}
          title={
            <div style={{lineHeight: '18px', fontSize: 14, margin: '6px 10px'}}>
              <BoldSpan>{drawerOpen ? 'Collapse Side Panel' : 'Expand Side Panel'}</BoldSpan>
            </div>
          }
        >
          <DrawerButton
            id={'drawerButton'}
            marginLeft={getDrawerButtonLeft(false, drawerOpen)}
            mobileMarginLeft={getDrawerButtonMobileLeft(false, drawerOpen)}
            onClick={() => setDrawerOpenAction(!drawerOpen)}
            open={drawerOpen}
            right={false}
          >
            {drawerOpen ? <ChevronLeft /> : <ChevronRight />}
          </DrawerButton>
        </RightDataToolTip>
        <FlightDetailsLeft open={drawerOpen} className={isHovered ? 'scroll-container-hover' : ''}>
          <LeftDetailContainer>
            {isMapMinimized ? (
              <MiniMap
                showAircraft={!flightEntryData?.flightDetected ? flightEntryData?.flightDetected : isLiveFlight}
                key="minimizedFlightMap"
                setMinimize={() => setMapMinimizedState(false)}
                flightPathData={flightPathInfo}
                aircraftData={aircraftMapData}
                isLoading={isFlightPathInfoLoading && !refreshFromInterval}
                hoverLineContainerRef={scrollContainerRef}
                chartTimeSettings={allChartsTimeSettings}
                liveFlightOffset={liveFlightOffset}
                parentLeftOffset={rightPaneLeftEdge}
                flightDetected={flightEntryData?.flightDetected}
                isLiveFlight={isLiveFlight}
                origin={flightEntryData?.origin}
                destination={flightEntryData?.destination}
                airports={airports}
              />
            ) : (
              <></>
            )}
            <TailInformation
              key="tailInformationCard"
              width={CONTENT_SIDEBAR_WIDTH}
              gutter={GUTTER_PADDING}
              isLoading={
                (arePrereqsLoading || isTailInfoLoading || (isLiveFlight && isMonthlyUsageLoading)) &&
                !refreshFromInterval
              }
              serialNumber={flightEntryData?.serialNumber}
              tailId={flightEntryData?.tailId}
              aircraftType={flightEntryData?.aircraftType}
              networkCapability={flightEntryData?.networkCapability}
              isDualBand={isDualBand}
              kaSwVersion={swVersionsInfo?.ka.version}
              kuSwVersion={swVersionsInfo?.ku.version}
              kaSwVersionStatus={swVersionsInfo?.ka}
              kuSwVersionStatus={swVersionsInfo?.ku}
              macAddress={tailInformation?.mac_address}
              ipAddress={tailInformation?.ip_address}
              showMonthlyDataUsage={true}
              monthlyDataUsage={monthlyDataUsage}
              isRegionalPlan={tailInformation?.is_regional_plan}
              customer={tailInformation?.customer}
              valueAddedReseller={tailInformation?.value_added_reseller}
              displayInternal={displayInternal}
              displayValueAddedReseller={isValueAddedReseller}
              endUser={flightEntryData?.endUser}
              isLabTerminal={flightEntryData?.isLabTerminal}
              cpeIpAddress={cabinRouterInfo?.cpeIpAddress}
            />
            <FlightInformation
              key="flightInformationCard"
              width={CONTENT_SIDEBAR_WIDTH}
              gutter={GUTTER_PADDING}
              isLoading={arePrereqsLoading && !refreshFromInterval && isAirportsLoading}
              origin={flightEntryData?.origin}
              destination={flightEntryData?.destination}
              flightStart={
                flightEntryData?.isDark ? flightEntryData?.actualDepartureTimestamp : flightEntryData?.flightStart
              }
              flightEnd={flightEntryData?.isDark ? flightEntryData?.actualArrivalTimestamp : flightEntryData?.flightEnd}
              flightPhase={isLiveFlight ? flightEntryData?.lastFlightPhase : undefined}
              flightDuration={isLiveFlight ? undefined : flightEntryData?.flightDuration}
              connectedStartTime={flightConnectionStart}
              connectedEndTime={flightConnectionEnd}
              isHistorical={!isLiveFlight}
              flightDetected={flightEntryData?.flightDetected}
              connectedTime={flightEntryData?.connectedDuration}
              airports={airports}
              isDark={flightEntryData?.isDark}
            />
            <CabinConnectivity
              key="cabinConnectivityCard"
              width={CONTENT_SIDEBAR_WIDTH}
              gutter={GUTTER_PADDING}
              isLoading={
                (isSatelliteInfoLoading || isTrafficCompositionLoading || isDataUsageInfoLoading) &&
                !refreshFromInterval
              }
              isHistorical={!isLiveFlight}
              flightDetected={flightEntryData?.flightDetected}
              connectedStart={flightConnectionStart}
              connectedEnd={flightConnectionEnd}
              connectedTime={flightEntryData?.connectedDuration}
              serialNumber={flightEntryData?.serialNumber}
              status={aircraftConnectionStatus}
              satNetwork={satelliteInformation?.network}
              satNetworkRegionName={satelliteInformation?.network_region_name}
              satNetworkRegionCode={satelliteInformation?.network_region_code}
              dataUsage={trafficCompositionInfo}
              totalUsage={dataUsageInfo}
              isRegionalPlan={tailInformation?.is_regional_plan}
              visibleCategories={visibleCategories}
              setVisibleCategories={setVisibleCategories}
              inFlightDataUsage={inFlightDataUsage}
            />
            {displayInternal ? (
              <FlightIdCard
                width={FLIGHT_DETAILS_DRAWER_WIDTH}
                gutter={GUTTER_PADDING}
                flightId={!isNil(flightEntryData?.faFlightId) ? flightEntryData?.faFlightId : flightEntryData?.flightId}
                isLoading={isFlightEntryLoading}
              />
            ) : (
              <></>
            )}
          </LeftDetailContainer>
        </FlightDetailsLeft>
        <FlightDetailsCenter
          width={getFlightDetailsCenterWidth(drawerOpen, menuOpened, rightBarOpen, FLIGHT_DETAILS_CENTER_OFFSET_OUTER)}
          height={flightEntryData?.isDark ? PAGE_DARK_FLIGHT_LEFT_HEIGHT : PAGE_HEADER_HEIGHT}
          ref={rightPaneRef}
        >
          {flightEntryData?.isDark && (
            <AlertContainer bgColor={DARK_FLIGHT_ALERT_COLOR} width={472}>
              <AlertMessage id={getElementIdFromSectionBase(FLIGHT_DETAILS_ID_BASE, 'darkFlight', 'alert')}>
                <WarningIcon sx={{color: DARK_FLIGHT_ALERT_ICON_COLOR, width: 25, height: 20}} />
                <span className="darkFlight">Dark flight detected:</span>
                &nbsp; Reason unknown
              </AlertMessage>
            </AlertContainer>
          )}
          {tailInformation?.is_decommissioned && (
            <AlertContainer bgColor={FLIGHT_DETAILS_DECOMMISSIONED_BANNER_BG_COLOR} width={472}>
              <AlertMessage id={getElementIdFromSectionBase(FLIGHT_DETAILS_ID_BASE, 'decommissionedAircraft', 'alert')}>
                <ErrorRoundedIcon sx={{color: DARK_FLIGHT_ALERT_ICON_COLOR, width: 24, height: 24}} />
                <Span className="decommissionedBanner">
                  This aircraft has been decommissioned on &nbsp;
                  {moment.utc(tailInformation?.decommissioned_tstamp).format(DATE_VERBOSE_FULL_FORMAT)}
                </Span>
              </AlertMessage>
            </AlertContainer>
          )}

          <FlightDetailsCenterChartContainer
            width={getFlightDetailsCenterWidth(
              drawerOpen,
              menuOpened,
              rightBarOpen,
              FLIGHT_DETAILS_CENTER_OFFSET_OUTER
            )}
          >
            <ChartScrollContainer
              className={getFlightDetailsContainerClass(drawerOpen, menuOpened, rightBarOpen, isMapMinimized)}
              width={getFlightDetailsCenterWidth(drawerOpen, menuOpened, rightBarOpen)}
              height={flightEntryData?.isDark ? PAGE_DARK_FLIGHT_HEADER_HEIGHT : PAGE_HEADER_HEIGHT}
              ref={scrollContainerRef}
            >
              {!isMapMinimized ? (
                <MapCard
                  showAircraft={!flightEntryData?.flightDetected ? flightEntryData?.flightDetected : isLiveFlight}
                  setMinimize={() => setMapMinimizedState(true)}
                  flightPathData={flightPathInfo}
                  aircraftData={aircraftMapData}
                  hoverLineContainerRef={scrollContainerRef}
                  chartTimeSettings={allChartsTimeSettings}
                  liveFlightOffset={liveFlightOffset}
                  isLoading={isFlightPathInfoLoading && !refreshFromInterval}
                  isMinimized={isMapMinimized}
                  parentLeftOffset={rightPaneLeftEdge}
                  flightDetected={flightEntryData?.flightDetected}
                  isLiveFlight={isLiveFlight}
                  origin={flightEntryData?.origin}
                  destination={flightEntryData?.destination}
                  airports={airports}
                />
              ) : (
                <></>
              )}
              {!arePrereqsLoading ? (
                <HoverLine
                  containerRef={scrollContainerRef}
                  parentLeftOffset={rightPaneLeftEdge}
                  chartTimeSettings={allChartsTimeSettings}
                  eventsTimelineData={flightPathEvents}
                  isMinimized={isMapMinimized}
                  isLoading={isFlightPathInfoLoading && !refreshFromInterval}
                  liveFlightOffset={liveFlightOffset}
                  lineHeight={overallHeight}
                />
              ) : (
                <></>
              )}
              <EventsTimelineCard
                currentTime={moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES)}
                isLoading={isFlightPathInfoLoading && !refreshFromInterval}
                chartTimeSettings={allChartsTimeSettings}
                maxDate={Number(moment.utc().format('x'))}
                parentLeftOffset={rightPaneLeftEdge}
                isLiveFlight={isLiveFlight}
                lastFlightPhase={flightEntryData?.lastFlightPhase}
                flightPhaseData={flightPhaseData}
                eventsTimeLineData={flightPathEvents}
                liveMask={liveMask}
                eventsTimelineChartHeight={192}
                setLiveFlightOffset={setLiveFlightOffset}
                scrollContainerRef={scrollContainerRef}
              />
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {cards.map((card, index) => {
                        switch (card.id) {
                          case 'SatelliteHealthCard':
                            cardProps[card.id] = {
                              currentTime: moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES),
                              parentLeftOffset: rightPaneLeftEdge,
                              chartData: satelliteHealthInfo,
                              isLoading: isSatelliteHealthInfoLoading && !refreshFromInterval,
                              chartTimeSettings: allChartsTimeSettings,
                              liveMask: liveMask
                            };
                            break;
                          case 'CombinedUsageCard':
                            cardProps[card.id] = {
                              currentTime: moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES),
                              parentLeftOffset: rightPaneLeftEdge,
                              chartData: dataUsageInfo,
                              isLoading: isDataUsageInfoLoading && !refreshFromInterval,
                              chartTimeSettings: allChartsTimeSettings,
                              liveMask: liveMask,
                              draggableProps: null
                            };
                            break;
                          case 'DownloadUsageCard':
                            cardProps[card.id] = {
                              currentTime: moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES),
                              parentLeftOffset: rightPaneLeftEdge,
                              chartData: dataUsageInfo,
                              isLoading: isDataUsageInfoLoading && !refreshFromInterval,
                              chartTimeSettings: allChartsTimeSettings,
                              liveMask: liveMask,
                              draggableProps: null
                            };
                            break;
                          case 'UploadUsageCard':
                            cardProps[card.id] = {
                              currentTime: moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES),
                              parentLeftOffset: rightPaneLeftEdge,
                              chartData: dataUsageInfo,
                              isLoading: isDataUsageInfoLoading && !refreshFromInterval,
                              chartTimeSettings: allChartsTimeSettings,
                              liveMask: liveMask,
                              draggableProps: null
                            };
                            break;
                          case 'DataUsageCard':
                            cardProps[card.id] = {
                              currentTime: moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES),
                              parentLeftOffset: rightPaneLeftEdge,
                              chartData: dataUsageInfo,
                              isLoading: isDataUsageInfoLoading && !refreshFromInterval,
                              chartTimeSettings: allChartsTimeSettings,
                              liveMask: liveMask,
                              draggableProps: null
                            };
                            break;
                          case 'TrafficCompositionCard':
                            cardProps[card.id] = {
                              currentTime: moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES),
                              parentLeftOffset: rightPaneLeftEdge,
                              isLoading: isTrafficCompositionLoading && !refreshFromInterval,
                              chartData: trafficCompositionInfo,
                              chartTimeSettings: allChartsTimeSettings,
                              liveMask: liveMask,
                              draggableProps: null,
                              dataUsage: dataUsageObject
                            };
                            break;
                          case 'PingLatencyCard':
                            cardProps[card.id] = {
                              currentTime: moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES),
                              parentLeftOffset: rightPaneLeftEdge,
                              isLoading: isPingLatencyInfoLoading && !refreshFromInterval,
                              chartData: pingLatencyInfo,
                              chartTimeSettings: allChartsTimeSettings,
                              liveMask: liveMask,
                              draggableProps: null
                            };
                            break;

                          case 'AntennaEncoderCard':
                            cardProps[card.id] = {
                              currentTime: moment.utc().format(DATE_TIME_LOCAL_FORMAT_MINUTES),
                              parentLeftOffset: rightPaneLeftEdge,
                              isLoading: isAntennaEncoderInfoLoading && !refreshFromInterval,
                              chartData: antennaEncoderInfo,
                              chartTimeSettings: allChartsTimeSettings,
                              liveMask: liveMask,
                              draggableProps: null
                            };
                            break;
                        }

                        return card.display && !card.disabled ? (
                          <Draggable key={card.hash} draggableId={card.hash} index={index}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} {...provided.draggableProps}>
                                <card.component
                                  currentTime={cardProps[card.id].currentTime}
                                  parentLeftOffset={cardProps[card.id].parentLeftOffset}
                                  chartData={cardProps[card.id].chartData}
                                  isLoading={cardProps[card.id].isLoading}
                                  chartTimeSettings={cardProps[card.id].chartTimeSettings}
                                  liveMask={cardProps[card.id].liveMask}
                                  draggableProps={provided?.dragHandleProps}
                                  dataUsage={cardProps[card.id]?.dataUsage}
                                />
                              </div>
                            )}
                          </Draggable>
                        ) : (
                          <React.Fragment key={card.hash}></React.Fragment>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ChartScrollContainer>
          </FlightDetailsCenterChartContainer>
        </FlightDetailsCenter>
        <FlightDetailsRight open={rightBarOpen}>
          <ViewGraphs isLoading={false} width={FLIGHT_DETAILS_RIGHT_BAR_WIDTH} gutter={GUTTER_PADDING} cards={cards} />
        </FlightDetailsRight>
      </FlightDetailsBody>
    </PageContainerTemplate>
  );
};

export default FlightDetailsPage;
