/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight Path Event util
 */

import {IFlightPlanEvents} from '../../store/queries/connectivityPlanner/flightPathQuery';
import moment from 'moment';
import {DATE_TIME_FORMAT_WITH_HR_MINS} from '../../utils/DateTimeUtils';
import {ConnectivityOutlookStatus} from './ConnectivityOutlookUtils';

export interface Coordinate {
  latitude: number;
  longitude: number;
}

// Event Names
export enum EVENT_NAMES {
  TAKEOFF = 'TakeOff',
  TAKEOFF_TURBULENCE = 'TakeoffTurbulence',
  ENTERED_COVERAGE = 'EnteredCoverage',
  LEFT_COVERAGE = 'LeftCoverage',
  HANDOVER = 'Handover',
  HANDOVER_END = 'HandoverEnd',
  LANDING = 'Landing',
  LANDING_TURBULENCE = 'LandingTurbulence',
  ENTERED_ATI_HNA = 'EnteredAtiHna',
  LEFT_ATI_HNA = 'LeftAtiHna',
  NO_CHANGE = ''
}

export const EXCLUDE_EVENTS: string[] = [
  EVENT_NAMES.HANDOVER_END,
  EVENT_NAMES.TAKEOFF_TURBULENCE,
  EVENT_NAMES.LANDING_TURBULENCE,
  EVENT_NAMES.TAKEOFF,
  EVENT_NAMES.LANDING,
  EVENT_NAMES.LEFT_ATI_HNA,
  EVENT_NAMES.ENTERED_COVERAGE
];

/**
 * Converts the given Degree to Radian
 * @param degree - Degree
 * @returns - Radian
 */
export const degToRad = (degree: number): number => {
  return (degree * Math.PI) / 180.0;
};

/**
 * Converts the given Radian to Degree
 * @param radian - Radian
 * @returns - Degree
 */
export const radToDeg = (radian: number): number => {
  return (radian * 180) / Math.PI;
};

/**
 * Returns list of intermediate coordinates
 * this implementation is based on https://stackoverflow.com/questions/7676845/how-to-find-a-geographic-point-between-two-other-points/7677040#7677040
 * @param startLatDeg Start latitude in degrees
 * @param startLonDeg Start latitude in degrees
 * @param endLatDeg End latitude in degrees
 * @param endLonDeg End latitude in degrees
 * @param numInterCoord Number of intermediate coordinates
 * @returns List of coordinates
 */
export const getIntermediateCoordinates = (
  startLatDeg: number,
  startLonDeg: number,
  endLatDeg: number,
  endLonDeg: number,
  numInterCoord: number
): Coordinate[] => {
  const ACOS_UPPER_BOUND = 1;
  const ACOS_LOWER_BOUND = -1;

  // Convert degrees to radians
  const startLatRad = degToRad(startLatDeg);
  const startLonRad = degToRad(startLonDeg);
  const endLatRad = degToRad(endLatDeg);
  const endLonRad = degToRad(endLonDeg);

  // Calculate distance in longitude
  const diffLon = endLonRad - startLonRad;

  // Calculate common variables
  const startLatRadSin = Math.sin(startLatRad);
  const endLatRadSin = Math.sin(endLatRad);
  const startLatRadCos = Math.cos(startLatRad);
  const endLatRadCos = Math.cos(endLatRad);
  const diffLonCos = Math.cos(diffLon);

  // Find distance from A to B
  const distanceInput = startLatRadSin * endLatRadSin + startLatRadCos * endLatRadCos * diffLonCos;
  const boundedDistanceInput = Math.min(Math.max(distanceInput, ACOS_LOWER_BOUND), ACOS_UPPER_BOUND); // Keeps input between -1 and 1 to prevent distance from being NaN. This can happen when the coordinates for start and end are identicial.
  const distance = Math.acos(boundedDistanceInput);

  // Find bearing from A to B
  const bearing = Math.atan2(
    Math.sin(diffLon) * endLatRadCos,
    startLatRadCos * endLatRadSin - startLatRadSin * endLatRadCos * diffLonCos
  );

  const intermediateCoordinates = [];
  for (let index = 1; index < numInterCoord + 1; index++) {
    intermediateCoordinates.push(
      findCoordinate(distance, bearing, startLatRadSin, startLatRadCos, startLonRad, index / (numInterCoord + 1))
    );
  }
  return intermediateCoordinates;
};

/**
 * Returns a coordinate
 * @param distance A distance
 * @param bearing A bearing
 * @param startLatRadSin The start latitude radians sinus
 * @param startLatRadCos The start latitude radians cosinus
 * @param startLonRad The start longitude radians
 * @param scale How much of the distance to use, from 0 through 1
 * @returns a coordinate
 */
const findCoordinate = (
  distance: number,
  bearing: number,
  startLatRadSin: number,
  startLatRadCos: number,
  startLonRad: number,
  scale: number
): Coordinate => {
  // Find new point
  const angularDistance = distance * scale;
  const angDistSin = Math.sin(angularDistance);
  const angDistCos = Math.cos(angularDistance);
  const xlatRad = Math.asin(startLatRadSin * angDistCos + startLatRadCos * angDistSin * Math.cos(bearing));
  const xlonRad =
    startLonRad +
    Math.atan2(Math.sin(bearing) * angDistSin * startLatRadCos, angDistCos - startLatRadSin * Math.sin(xlatRad));

  // Convert radians to microdegrees
  let xlat = radToDeg(xlatRad);
  let xlon = radToDeg(xlonRad);
  if (xlat > 90) xlat = 90;
  if (xlat < -90) xlat = -90;
  while (xlon > 180) xlon -= 360;
  while (xlon <= -180) xlon += 360;
  return {latitude: xlat, longitude: xlon};
};

/**
 * Back fill the events data for every minute
 * @param flightPlanData original flight plan data
 * @returns flight plan with backfilled data
 */
export const backFillConnectivityEvent = (flightPlanData: IFlightPlanEvents): IFlightPlanEvents => {
  const backFilledFlightPlan = JSON.parse(JSON.stringify(flightPlanData));
  let timeDifferenceDuration = 0;
  let idx = 0;
  const backFilledFlightEvent = backFilledFlightPlan.events;
  for (let i = 0; i < Object.keys(flightPlanData.events).length; i++) {
    let exitBackFilling = false;
    idx = idx + timeDifferenceDuration;
    const fillIndex = idx;
    if (backFilledFlightEvent[backFilledFlightEvent.length - 1].timestamp === backFilledFlightEvent[idx + 1].timestamp)
      exitBackFilling = true;
    if (EXCLUDE_EVENTS.includes(backFilledFlightEvent[idx].event.eventName)) {
      backFilledFlightEvent[idx].event.eventName = backFilledFlightEvent[idx].event.availability;
      backFilledFlightEvent[idx].event.displayName = '';
    }

    if (EXCLUDE_EVENTS.includes(backFilledFlightEvent[idx + 1].event.eventName)) {
      backFilledFlightEvent[idx + 1].event.eventName = backFilledFlightEvent[idx + 1].event.availability;
      backFilledFlightEvent[idx + 1].event.displayName = '';
    }

    const eventsTimeDifference = moment
      .utc(moment.utc(backFilledFlightEvent[idx + 1].timestamp))
      .diff(moment.utc(backFilledFlightEvent[idx].timestamp));
    timeDifferenceDuration = moment.duration(eventsTimeDifference).asMinutes();
    if (timeDifferenceDuration > 1) {
      const newCoords = getIntermediateCoordinates(
        backFilledFlightEvent[idx].lat,
        backFilledFlightEvent[idx].lng,
        backFilledFlightEvent[idx + 1].lat,
        backFilledFlightEvent[idx + 1].lng,
        timeDifferenceDuration - 1
      );
      let nextFlightPath = backFilledFlightEvent[idx];
      let endConnection = false;
      if (
        backFilledFlightEvent[idx].availability === ConnectivityOutlookStatus.SATELLITE_HANDOVER &&
        backFilledFlightEvent[idx + 1].availability === ConnectivityOutlookStatus.CONNECTED
      ) {
        endConnection = true;
      }
      if (backFilledFlightEvent[idx].event.eventType === 'END_BAD_CONNECTION')
        nextFlightPath = backFilledFlightEvent[idx + 1];
      let backFillRelativeTime = backFilledFlightEvent[fillIndex].relativeTime;
      const backFillDataList = newCoords.map((coord, id) => {
        const backFillEvent = JSON.parse(JSON.stringify(nextFlightPath));
        backFillEvent.lat = coord.latitude;
        backFillEvent.lng = coord.longitude;
        const newTime = moment.utc(backFilledFlightEvent[fillIndex].timestamp).add(id + 1, 'minute');
        backFillEvent.timestamp = newTime.format(DATE_TIME_FORMAT_WITH_HR_MINS);
        backFillRelativeTime += 1;
        backFillEvent.relativeTime = backFillRelativeTime;
        return backFillEvent;
      });
      if (endConnection) {
        backFillDataList[backFillDataList.length - 1].event.eventType = 'END_BAD_CONNECTION';
      }
      backFilledFlightEvent.splice(idx + 1, 0, ...backFillDataList);
    } else {
      timeDifferenceDuration = 1;
    }
    if (exitBackFilling) break;
  }
  return backFilledFlightPlan;
};
