/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: The Google Map marker
 */

import React from 'react';
import {OverlayView} from '@react-google-maps/api';
import {isNil} from 'lodash';
import {BuildingPinIcon} from '@viasat/insights-components';
import styled from '@emotion/styled';

import {getElementId} from '../../utils/ElementIdUtils';
import {PinIconContainer} from './MapStyles';
import {START_Z_INDEX, MAP_ELID_SECTION} from '../../utils/constants';
import {MapMarkerProps, MapViewContext} from '../../utils/MapUtil';

import OriginAirportPinIcon from '../common/theme/icons/OriginAirportPinIcon';
import DestinationAirportPinIcon from '../common/theme/icons/DestinationAirportPinIcon';
import {AirportCodeTooltip} from '@viasat/insights-components';
import NonFlightPinIcon from '../common/theme/icons/NonFlightPinIcon';

const AirportPinIcon = styled(BuildingPinIcon)`
  .gradientPrimary {
    stop-color: #00528c;
  }
  .gradientSecondary {
    stop-color: #00a2c0;
  }
`;

/**
 * The Google Map marker
 * @param props Map Aircraft Props
 * @returns Map overlayview
 */
const MapMarker: React.FC<MapMarkerProps> = (props) => {
  const {type, internal, location, airport, mapDivRef, context, pixelPositionOffset, children, flightDetected} = props;

  const renderedMapMarker = () => {
    if (type === 'start' || type === 'end') {
      return (
        <PinIconContainer zIndex={START_Z_INDEX}>
          {isNil(airport) ? (
            context === MapViewContext.FLIGHT_DETAILS_MAP ? (
              flightDetected ? (
                <>
                  {type === 'start' && (
                    <OriginAirportPinIcon id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')} />
                  )}
                  {type === 'end' && (
                    <DestinationAirportPinIcon id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')} />
                  )}
                </>
              ) : (
                <NonFlightPinIcon id={getElementId(MAP_ELID_SECTION, `on-ground`, 'pin', 'icon')} />
              )
            ) : (
              <AirportPinIcon
                id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')}
                sx={{width: 24, height: 24}}
              />
            )
          ) : context === MapViewContext.FLIGHT_DETAILS_MAP ? (
            flightDetected && sessionStorage.getItem('mapPathHover') !== 'true' ? (
              <>
                {type === 'start' && (
                  <AirportCodeTooltip
                    airportCode={airport.iataCode}
                    airport={airport}
                    container={mapDivRef.current ? mapDivRef.current.mapRef : null}
                    getFullElementId={(name: string, type: string) => `airportTooltip__${name}-${type}`}
                    position={'top'}
                    yMargin={'0px'}
                    arrow={false}
                    invertColors={true}
                  >
                    <OriginAirportPinIcon id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')} />
                  </AirportCodeTooltip>
                )}
                {type === 'end' && (
                  <AirportCodeTooltip
                    airportCode={airport.iataCode}
                    airport={airport}
                    container={mapDivRef.current ? mapDivRef.current.mapRef : null}
                    getFullElementId={(name: string, type: string) => `airportTooltip__${name}-${type}`}
                    position={'top'}
                    yMargin={'0px'}
                    arrow={false}
                    invertColors={true}
                  >
                    <DestinationAirportPinIcon id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')} />
                  </AirportCodeTooltip>
                )}
              </>
            ) : airport && !flightDetected ? (
              <>
                {type === 'start' && (
                  <AirportCodeTooltip
                    airportCode={airport.iataCode}
                    airport={airport}
                    container={mapDivRef.current ? mapDivRef.current.mapRef : null}
                    getFullElementId={(name: string, type: string) => `airportTooltip__${name}-${type}`}
                    position={'top'}
                    yMargin={'0px'}
                    arrow={false}
                    invertColors={true}
                  >
                    <OriginAirportPinIcon id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')} />
                  </AirportCodeTooltip>
                )}
                {type === 'end' && (
                  <AirportCodeTooltip
                    airportCode={airport.iataCode}
                    airport={airport}
                    container={mapDivRef.current ? mapDivRef.current.mapRef : null}
                    getFullElementId={(name: string, type: string) => `airportTooltip__${name}-${type}`}
                    position={'top'}
                    yMargin={'0px'}
                    arrow={false}
                    invertColors={true}
                  >
                    <DestinationAirportPinIcon id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')} />
                  </AirportCodeTooltip>
                )}
              </>
            ) : (
              <NonFlightPinIcon id={getElementId(MAP_ELID_SECTION, `on-ground`, 'pin', 'icon')} />
            )
          ) : (
            <AirportCodeTooltip
              airportCode={airport.iataCode}
              airport={airport}
              container={mapDivRef.current ? mapDivRef.current.mapRef : null}
              getFullElementId={(name: string, type: string) => `airportTooltip__${name}-${type}`}
              position={'top'}
              yMargin={'0px'}
              arrow={false}
              invertColors={true}
            >
              {context === MapViewContext.CONNECTIVITY_OUTLOOK_MAP ? (
                <AirportPinIcon
                  id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')}
                  sx={{width: 38, height: 38}}
                />
              ) : (
                <AirportPinIcon
                  id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')}
                  sx={{width: 24, height: 24}}
                />
              )}
            </AirportCodeTooltip>
          )}
        </PinIconContainer>
      );
    } else {
      return <>{children}</>;
    }
  };

  return internal ? (
    renderedMapMarker()
  ) : (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={{lat: location?.lat, lng: location?.lng}}
      getPixelPositionOffset={(x, y) => pixelPositionOffset}
    >
      {renderedMapMarker()}
    </OverlayView>
  );
};

export default MapMarker;
