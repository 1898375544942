/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Circular Dot to demonstrate availability target
 */
import {createSvgIcon} from '@mui/material';

/**
 * Circular dot to demonstrate availability target
 * returns Availability DOT SVG
 */
const AvailabilityDot = createSvgIcon(
  <svg
    focusable="false"
    viewBox="0 0 17 16"
    fill="currentColor"
    aria-hidden="true"
    role="presentation"
    style={{
      position: 'relative',
      verticalAlign: 'text-top'
    }}
  >
    <circle cx={6} cy={10} r={6} />
  </svg>,
  'AvailabilityDot'
);

export default AvailabilityDot;
