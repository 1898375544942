/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Traffic Composition ToolTip Style and Formatting
 */

import React from 'react';
import {ColorProperty} from 'csstype';

import SeriesPrefixIcon from '../../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesLabel from '../../../common/elements/chart/ToolTip/SeriesLabel';
import SeriesValue from '../../../common/elements/chart/ToolTip/SeriesValue';

import {formatTrafficCompositionValue, formatTrafficCompositionMBValue} from '../../flightDetailsUtil';

export interface TrafficCompositionToolTipProps {
  prefixIconColor?: ColorProperty;
  trafficType: number | string;
  trafficUsage: number;
  dataUsage: number;
}

const TrafficCompositionToolTip: React.FC<TrafficCompositionToolTipProps> = ({
  prefixIconColor,
  trafficType,
  trafficUsage,
  dataUsage
}: TrafficCompositionToolTipProps): JSX.Element => {
  const formatDataUsage = formatTrafficCompositionMBValue(dataUsage);
  let dataUsageValue;
  if (trafficUsage >= 0.5 && formatDataUsage === '0 MB') {
    dataUsageValue = '<1 MB';
  } else {
    dataUsageValue = formatDataUsage;
  }
  return (
    <>
      <SeriesPrefixIcon color={prefixIconColor} />
      <SeriesLabel label={trafficType} width="150px" />
      <SeriesValue value={!isNaN(dataUsage) ? dataUsageValue : null} width="40px" />
      <SeriesValue
        value={formatTrafficCompositionValue(trafficUsage, !isNaN(dataUsage))}
        width={!isNaN(dataUsage) ? '38px' : '40px'}
        fontWeight={!isNaN(dataUsage) ? 400 : undefined}
      />
    </>
  );
};

export default TrafficCompositionToolTip;
