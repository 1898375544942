/**
/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Wrapper Component for Map
 */

import React, {useEffect, useRef, useState} from 'react';
import {useStore} from '../../store/Store';
import styled from '@emotion/styled';
import {OverlayView} from '@react-google-maps/api';
import {calculateZIndex, MapViewContext} from '../../utils/MapUtil';
import MapControls from '../mapView/MapControls';
import {FLIGHT_DETAILS_LIVE_BG_COLOR} from '../common/theme/Colors';
import MapMarker from '../mapView/MapMarker';
import MapFlightPath from '../mapView/MapFlightPath';
import {MAP_DEFAULT_ZOOM, MAP_MIN_ZOOM} from '../../utils/constants';
import {IFlightPath, IFlightPathPoint} from '../../store/queries/connectivityPlanner/flightPathQuery';
import {getSelectedLegEventMarkers, getSelectedLegFlightPath, getSelectedLegMapMarkers} from './SelectedFlightLegUtils';
import ConnectivityStatusIcon from '../common/theme/icons/ConnectivityStatusIcon';
import {ConnectivityOutlookIconColors, ConnectivityOutlookIconTextColors} from './ConnectivityOutlookUtils';
import MapStateContainer from '../mapView/MapStateContainer';

const ConnectivityOutlookMapContainer = styled.div`
  background-color: ${FLIGHT_DETAILS_LIVE_BG_COLOR};
  display: flex;
  justify-content: row;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: text;
  height: 100%;
  width: calc(100vw - 412px);
  margin-left: 380px;
  white-space: nowrap;
  overflow: hidden;
  .gm-style > div,
  .gm-style > div > div:last-child {
    overflow: hidden;
  }
  @media print {
    width: 70%;
    margin-left: unset;
    margin-top: 70px;
    background-color: white;

    #connectivityOutlookMap__googleMap-map {
      width: 100% !important;
      height: 718px !important;
    }
  }
`;

const EventsMarker = styled.div<{availability: string}>`
  position: absolute;
  z-index: ${(props) => calculateZIndex(props.availability) + 11};
  pointer-events: none;
`;
export interface IMapMarker {
  location: IFlightPathPoint;
  name: string;
  type: string;
}

export interface ConnectivityOutlookMapProps {
  isLoading: boolean;
}

const ConnectivityOutlookMap: React.FC<ConnectivityOutlookMapProps> = (ConnectivityOutlookMapProps) => {
  const {store} = useStore();
  const {airports, flightPathInfo, flightPlanInfo, selectedFlightLegIdx} = store.connectivityPlanner;
  const {isLoading} = ConnectivityOutlookMapProps;

  const [flightPathToDisplay, setFlightPathToDisplay] = useState<IFlightPath>(null);
  const [mapMarkers, setMapMarkers] = useState<IMapMarker[]>(null);
  const [boundList, setBoundList] = useState<any>(null);
  const [arrowHeadLocation, setArrowHeadLocation] = useState<{lat: number; lng: number; timestamp: string}[]>(null);
  const [eventMarkers, setEventMarkers] = useState<IMapMarker[]>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [zoom, setZoom] = useState(sessionStorage.curMapZoom ? Number(sessionStorage.curMapZoom) : MAP_DEFAULT_ZOOM);

  const mapDivRef = useRef(null);

  useEffect(() => {
    if (!isLoading && flightPathInfo && flightPlanInfo) {
      const selectedFlightPath = getSelectedLegFlightPath(flightPathInfo, selectedFlightLegIdx);
      setFlightPathToDisplay(selectedFlightPath);
      setBoundList(
        selectedFlightPath
          ? [
              selectedFlightPath.start,
              selectedFlightPath.end,
              ...selectedFlightPath.flightPath.map((p: any) => ({lat: p.lat, lng: p.lng})),
              ...selectedFlightPath.futureFlightPath.map((p: any) => ({lat: p.lat, lng: p.lng}))
            ]
          : []
      );
      setMapMarkers(getSelectedLegMapMarkers(flightPathInfo, flightPlanInfo, selectedFlightLegIdx));
      setEventMarkers(getSelectedLegEventMarkers(flightPathInfo, selectedFlightLegIdx));
      setArrowHeadLocation(
        flightPathInfo.map((path) => {
          return {lat: path.end.lat, lng: path.end.lng, timestamp: path.end.timestamp};
        })
      );
    }
  }, [flightPathInfo, flightPlanInfo, isLoading, selectedFlightLegIdx]);

  const pixelPositionOffset = {
    x: -20,
    y: -38
  };

  return (
    <ConnectivityOutlookMapContainer>
      <MapStateContainer
        getFullElementId={(name, type) => `connectivityOutlookMap__${name}-${type}`}
        isLoading={isLoading}
        initialBoundList={boundList}
        mapContainerStyle={{height: isFullScreen ? '100vh' : 'calc(100vh - 303px)'}}
        mapDivRef={mapDivRef}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        zoom={zoom}
        setZoom={setZoom}
      >
        <MapControls
          mapControlContext={MapViewContext.CONNECTIVITY_OUTLOOK_MAP}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          mapDivRef={mapDivRef}
          popupFaFlightId={null}
          setPopup={() => {}}
          setHoveredFaFlightId={null}
          minZoom={MAP_MIN_ZOOM}
          boundList={boundList}
          searchBoxDisabled={true}
          disableTailTagConfig={true}
          canMinimize={false}
        />
        {mapMarkers &&
          mapMarkers.length &&
          mapMarkers.map((mapMarker, index) => {
            return (
              <MapMarker
                key={`${index}-marker`}
                type={mapMarker.type}
                internal={false}
                mapDivRef={mapDivRef}
                location={mapMarker.location}
                airport={airports[mapMarker.name]}
                context={MapViewContext.CONNECTIVITY_OUTLOOK_MAP}
                pixelPositionOffset={pixelPositionOffset}
              />
            );
          })}
        {eventMarkers &&
          eventMarkers.length &&
          eventMarkers.map((eventMarker, index) => {
            return (
              <MapMarker
                key={`${index}-event-marker`}
                type={eventMarker.type}
                internal={false}
                mapDivRef={mapDivRef}
                location={eventMarker.location}
                context={MapViewContext.CONNECTIVITY_OUTLOOK_MAP}
                pixelPositionOffset={{x: -13, y: -15}}
              >
                <EventsMarker availability={eventMarker.location.availability}>
                  <ConnectivityStatusIcon
                    id={`connectivity-outlook-path-icon`}
                    text={eventMarker.name}
                    color={ConnectivityOutlookIconColors[eventMarker.location.availability]}
                    textColor={ConnectivityOutlookIconTextColors[eventMarker.location.availability]}
                    strokeWidth={2}
                    textX="3.5"
                    textY="1"
                  />
                </EventsMarker>
              </MapMarker>
            );
          })}
        {flightPathToDisplay?.start && (
          <MapFlightPath
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            parentLeftOffset={0}
            flightPathData={flightPathToDisplay}
            isFlightPathLoading={isLoading}
            arrowHeadLocation={arrowHeadLocation}
            mapViewContext={MapViewContext.CONNECTIVITY_OUTLOOK_MAP}
            disablePopup={true}
          />
        )}
      </MapStateContainer>
    </ConnectivityOutlookMapContainer>
  );
};
export default ConnectivityOutlookMap;
