/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Antenna Encoder ToolTip Style and Formatting
 */

import React from 'react';
import {ColorProperty} from 'csstype';

import SeriesPrefixIcon from '../../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import WarningIcon from '@mui/icons-material/Warning';
import styled from '@emotion/styled';
import {ANTENNA_ENCODER_CHART_PALETTE, TAIL_BLOCKAGE_WARNING} from '../../../common/theme/Colors';

export interface AntennaEncoderToolTipProps {
  prefixIconColor?: ColorProperty;
  angleType: string;
  angle: number;
  isTailBlocked: boolean;
}

const AntennaEncoderToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const AntennaAnglesContainer = styled.div<{angleType: string}>`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  justify-content: center;
  margin-bottom: ${(props) => (props.angleType === 'Azimuth (Left Scale)' ? -4 : 0)}px;
`;

const SeriesLabel = styled.div`
  text-align: left;
  width: 115px;
`;

const SeriesValue = styled.div`
  text-align: right;
  width: 66px;
`;

const TailBlockageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  height: 32px;
  width: 190px;
  border-radius: 4px;
  margin-top: 10px;
  background: ${ANTENNA_ENCODER_CHART_PALETTE[2]};
  div {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    height: 16px;
    text-align: center;
    display: flex;
    margin: 8px;
    margin-left: 0px;
  }
`;
const AntennaEncoderToolTip: React.FC<AntennaEncoderToolTipProps> = ({
  prefixIconColor,
  angleType,
  angle,
  isTailBlocked
}: AntennaEncoderToolTipProps): JSX.Element => {
  return (
    <AntennaEncoderToolTipContainer>
      <AntennaAnglesContainer angleType={angleType}>
        <SeriesPrefixIcon color={prefixIconColor} />
        <SeriesLabel>{angleType}</SeriesLabel>
        <SeriesValue>{`${angle.toFixed(1)}°`} </SeriesValue>
      </AntennaAnglesContainer>
      {isTailBlocked && (
        <TailBlockageContainer>
          <WarningIcon
            sx={{
              color: TAIL_BLOCKAGE_WARNING,
              width: '14.67px',
              height: '12.67px',
              marginRight: '5px',
              marginTop: '8px',
              marginBottom: '8px'
            }}
          />
          <div>Possible Tail Blockage</div>
        </TailBlockageContainer>
      )}
    </AntennaEncoderToolTipContainer>
  );
};

export default AntennaEncoderToolTip;
