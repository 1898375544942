/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Google maps custom controls component
 */
import React, {useState, useRef, useCallback, useEffect} from 'react';
import {useIntl, injectIntl} from 'react-intl';
import {forEach} from 'lodash';
import {ClickAwayListener} from '@mui/material';
import {Close} from '@mui/icons-material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {useGoogleMap} from '@react-google-maps/api';
import SearchIcon from '@mui/icons-material/Search';
import LayersIcon from '@mui/icons-material/Layers';
import {KEY_RETURN, KEY_UP, KEY_DOWN} from 'keycode-js';
import {
  LeftDataToolTip,
  RightDataToolTip,
  MapZoomCenterControls,
  MapControlsButton,
  MinimizeMapIcon,
  ReturnIcon
} from '@viasat/insights-components';

import AvailabilityDot from '../common/AvailabilityDot';
import BoldSpan from '../common/BoldSpan';
import {usePrevious} from '../../utils/PreviousStateUtils';
import {
  clearAllStoredRecentSearches,
  getAllStoredRecentSearches,
  getAvailabilityColor,
  MapControlsProps,
  storeRecentSearch,
  getAircraftStyleAttributes,
  AircraftMapData,
  MapViewContext
} from '../../utils/MapUtil';
import {
  AvailabilityDotContainer,
  BlackBoldSpan,
  BorderSearchContainerWrapper,
  MapControlsButtonContainer,
  PopupCloseButton,
  SearchBoxBody,
  SearchBoxCardLabel,
  SearchBoxCardSubtext,
  SearchBoxContainerWrapper,
  SearchBoxHeader,
  SearchControlIconButton,
  SearchInput,
  SearchResult,
  useStyles
} from './MapStyles';
import {getElementId, getElementIdFragmentBase, getElementIdFromFragmentBase} from '../../utils/ElementIdUtils';
import {useStore} from '../../store/Store';
import {FleetMapAction, InitialFleetMapState} from '../../store/reducers/FleetMapReducer';
import {
  MAP_ELID_SECTION,
  MAP_ELID_CONTROLS_SUBSECTION,
  CHAR_SEARCH_COUNT,
  MAP_CONTROL_WIDTH,
  SEARCH_ROW_HEIGHT,
  MAP_POPUP_Z_INDEX,
  MAP_MAX_ZOOM,
  MAP_CENTER,
  MAP_DEFAULT_ZOOM,
  ConnectionStatus
} from '../../utils/constants';
import {AircraftStatus} from '../../store/queries/fleetMap/aircraftStatusQuery';
import MapOverlayConfigurations from './overlays/MapOverlayConfigurations';

const MapControls: React.FC<MapControlsProps | any> = ({
  mapControlContext,
  setSearchCriteria,
  searchCriteria,
  searchResults,
  isFullScreen,
  setIsFullScreen,
  mapDivRef,
  setHoveredAircraft,
  setPopup,
  minZoom,
  setTailTagOpen,
  isTailTagOpen,
  setShowLargePopup,
  showLargePopup,
  searchBoxDisabled,
  disableTailTagConfig,
  canMinimize,
  setMinimize,
  boundList = [],
  selectedAircraftData,
  selectedAircraftNetwork,
  disableOverlayOptions
}) => {
  const map = useGoogleMap();
  const {store, dispatch} = useStore();
  const intl = useIntl();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const prevSearchOpen = usePrevious(searchOpen);
  const [recentSearches, setRecentSearches] = useState<string[]>(getAllStoredRecentSearches());
  const [keyboardHoverIndex, setKeyboardHoverIndex] = useState<number>(-1);
  const [showMapOverlayConfigurationPopup, setShowMapOverlayConfigurationPopup] = useState<boolean>(false);

  const {classes} = useStyles();
  const searchInputRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const {overlays, filteredData, view, selectedAircraftId} = store.fleetMap;
  const {viewAsCustomer} = store.init;
  const kaOverlayActive = overlays.showKaOverlay === 'true' ? true : false;
  const kuOverlayActive = overlays.showKuOverlay === 'true' ? true : false;
  const txInhibitZonesBelow10kFeetActive = overlays.showTxInhibitZoneBelow10kFt === 'true' ? true : false;
  const txInhibitZonesAtAllAltitudesActive = overlays.showTxInhibitZoneAllAltitudes === 'true' ? true : false;
  const kaSatelliteActive =
    overlays.selectedKaSatellites?.length > 0 &&
    !viewAsCustomer &&
    mapControlContext !== MapViewContext.CONNECTIVITY_OUTLOOK_MAP;
  const kuSatelliteActive =
    overlays.selectedKuSatellites?.length > 0 && mapControlContext !== MapViewContext.CONNECTIVITY_OUTLOOK_MAP;

  const kaRegionalServiceCoverageActive = overlays.kaRegionalServiceCoverage?.length > 0;

  const kuRegionalServiceCoverageActive = overlays.kuRegionalServiceCoverage?.length > 0;
  const connectedBeamsActive = overlays.showConnectedBeams === 'true' ? true : false;

  const isolateRoute = view.isolateRoute === 'true';
  const boundListValid = boundList && boundList.length > 0 && boundList.every((element) => Boolean(element));
  const mapHeight = mapDivRef.current ? mapDivRef.current.mapRef.clientHeight : document.body.clientHeight;

  const currentZoom = map?.getZoom();

  /**
   * Builds the tool tips props
   * @param text Tool Tip Text
   * @param disable Disable this tool tip
   * @returns Tool tip props
   */
  const toolTipProps = (text: string, xOffset?: number, disable?: boolean) => {
    return {
      arrow: true,
      enterDelay: 250,
      popperProps: {
        container: mapDivRef.current ? mapDivRef.current.mapRef : null,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, xOffset ? xOffset : 0]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              mainAxis: false // fixed popper boundaries for y-axis
            }
          }
        ]
      },
      title: disable ? (
        ''
      ) : (
        <React.Fragment>
          <div
            style={{
              lineHeight: '18px',
              margin: '6px 10px'
            }}
          >
            <BoldSpan>{text}</BoldSpan>
          </div>
        </React.Fragment>
      )
    };
  };

  const searchSelect = (aircraft: AircraftMapData) => {
    setHoveredAircraft(aircraft.aircraftId);
    dispatch({type: FleetMapAction.SET_SELECTED_AIRCRAFT, payload: InitialFleetMapState.selectedAircraftId});
    if (aircraft.status !== ConnectionStatus.OFFLINE) {
      map?.setCenter({lat: aircraft.lastLatitude, lng: aircraft.lastLongitude});
      setHoveredAircraft(undefined);
      setPopup(aircraft);
      dispatch({
        type: FleetMapAction.SET_SELECTED_AIRCRAFT,
        payload: aircraft.aircraftId
      });
    }

    setSearchOpen(false);
    setTimeout(() => {
      setSearchCriteria('');
      searchInputRef.current.value = '';
    }, 300);

    const viewCenter = JSON.stringify({
      lat: Number(aircraft.lastLatitude),
      lng: Number(aircraft.lastLongitude)
    });
    dispatch({type: FleetMapAction.SET_VIEW_CENTER, payload: viewCenter});
  };

  const HighlightedSpan = ({criteria, label, className, id}) => {
    const criteriaLower = criteria.toLowerCase();
    const regEx = new RegExp(`(${criteria})`, 'ig');

    return (
      <span className={className} id={id} key={id}>
        {label.split(regEx).map((t) =>
          t.toLowerCase() === criteriaLower ? (
            <BlackBoldSpan id={`${id}-bold`} key={`${id}-bold`}>
              {t}
            </BlackBoldSpan>
          ) : (
            t
          )
        )}
      </span>
    );
  };

  /**
   * Sets the most recent search
   * @param searchString Search String
   */
  const setRecentSearch = (searchString: string) => {
    if (searchString) {
      storeRecentSearch(searchString);
      setRecentSearches(getAllStoredRecentSearches());
      searchInputRef.current.value = searchString;
      setSearchCriteria(searchString);
    }
  };

  const listKeyDownHandler = (
    keyCode: number,
    listData: any[],
    currentIndex: number,
    hoverCallback: (hoverIndex: number) => void,
    selectionCallback: (selectedIndex: number) => void
  ) => {
    if ((keyCode === KEY_DOWN || keyCode === KEY_UP) && listData.length > 0) {
      if (keyCode === KEY_DOWN) {
        const incIdx = (currentIndex + 1) % listData.length;
        hoverCallback(incIdx);
      } else if (keyCode === KEY_UP && currentIndex > -1) {
        const decIdx = (currentIndex - 1 + listData.length) % listData.length;
        hoverCallback(decIdx);
      }
    }
    // if user hits enter
    else if (keyCode === KEY_RETURN && listData[currentIndex]) {
      selectionCallback(currentIndex);
    }
  };

  /**
   * Clears all recent searches
   */
  const clearRecentSearches = () => {
    clearAllStoredRecentSearches();
    setRecentSearches([]);
  };

  /**
   * Recenters the map in flight details, since boundListValid = true only for flight details
   */
  const recenterMap = useCallback(() => {
    if (boundListValid) {
      const bounds = new google.maps.LatLngBounds();
      boundList.forEach(({lat, lng}) => bounds.extend(new google.maps.LatLng(lat, lng)));
      map.fitBounds(bounds);
    } else {
      map?.setCenter(MAP_CENTER);
      map?.setZoom(MAP_DEFAULT_ZOOM);
    }
  }, [boundList, boundListValid, map]);

  useEffect(() => {
    recenterMap();
    // Recenter only when boundList updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundList.length]);

  const recentSearchesIdBase = getElementIdFragmentBase(
    MAP_ELID_SECTION,
    MAP_ELID_CONTROLS_SUBSECTION,
    'recentSearches'
  );
  const searchResultsIdBase = getElementIdFragmentBase(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'searchResults');

  const showCriteria = Boolean(searchCriteria) && searchCriteria.length >= CHAR_SEARCH_COUNT;
  const boxOpen = showCriteria && searchOpen;

  return (
    <>
      <div
        style={{
          top: '16px',
          left: '16px',
          width: `${MAP_CONTROL_WIDTH}px`,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {canMinimize ? (
          <RightDataToolTip {...toolTipProps('Minimize Map')}>
            <div>
              <MapControlsButton
                id="mapControls__minimize-button"
                Icon={MinimizeMapIcon}
                onClick={() => setMinimize()}
              />
            </div>
          </RightDataToolTip>
        ) : null}
      </div>
      <div
        style={{
          top: '16px',
          right: '16px',
          width: `${MAP_CONTROL_WIDTH}px`,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {searchBoxDisabled ? (
          <> </>
        ) : (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => {
              setRecentSearch(searchInputRef.current.value);
              setSearchOpen(false);
            }}
          >
            <div>
              <BorderSearchContainerWrapper
                searchOpen={searchOpen}
                prevSearchOpen={prevSearchOpen ? prevSearchOpen.toString() : undefined}
              >
                <LeftDataToolTip {...toolTipProps('Search', 0, searchOpen)}>
                  <div>
                    <div>
                      <SearchControlIconButton
                        searchOpen={searchOpen}
                        id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'search', 'button')}
                        prevsearchopen={prevSearchOpen ? prevSearchOpen.toString() : undefined}
                        onClick={() => {
                          setSearchOpen(true);
                          setTimeout(() => {
                            searchInputRef.current.focus();
                          }, 200);
                        }}
                        prevSearchOpen={undefined}
                      >
                        <SearchIcon className={classes.searchButtonIcon} />

                        <SearchInput
                          id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'search', 'input')}
                          ref={searchInputRef}
                          autoComplete="off"
                          searchOpen={searchOpen}
                          prevSearchOpen={prevSearchOpen}
                          disabled={!searchOpen}
                          onChange={($event) => setSearchCriteria($event.target.value)}
                          onKeyDown={($event: {keyCode: any}) =>
                            listKeyDownHandler(
                              $event.keyCode,
                              boxOpen ? searchResults : recentSearches,
                              keyboardHoverIndex,
                              (hoverIndex: number) => {
                                setKeyboardHoverIndex(hoverIndex);
                                if (boxOpen) {
                                  setHoveredAircraft(searchResults[hoverIndex].aircraftId);
                                }
                                if (scrollContainerRef && scrollContainerRef.current) {
                                  scrollContainerRef.current.scrollTop = SEARCH_ROW_HEIGHT * hoverIndex;
                                }
                              },
                              (selectedIndex: number) => {
                                if (boxOpen) {
                                  searchSelect(searchResults[selectedIndex]);
                                  setKeyboardHoverIndex(-1);
                                  setRecentSearch(searchInputRef.current.value);
                                } else {
                                  setKeyboardHoverIndex(-1);
                                  setRecentSearch(recentSearches[selectedIndex]);
                                }
                              }
                            )
                          }
                          placeholder="Search..."
                        />
                      </SearchControlIconButton>
                      <div style={{width: 0, height: 0}}>
                        <PopupCloseButton
                          id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'clearSearch', 'button')}
                          searchOpen={searchOpen}
                          searchCriteria={searchCriteria}
                          onClick={() => {
                            searchInputRef.current.value = '';
                            setSearchCriteria('');
                          }}
                        >
                          <Close />
                        </PopupCloseButton>
                      </div>
                    </div>
                  </div>
                </LeftDataToolTip>
              </BorderSearchContainerWrapper>
              {searchOpen && !boxOpen ? (
                <div
                  id={getElementIdFromFragmentBase(recentSearchesIdBase, '', 'container')}
                  style={{
                    width: 0,
                    height: 0,
                    zIndex: MAP_POPUP_Z_INDEX,
                    position: 'absolute',
                    right: 16,
                    paddingLeft: 4
                  }}
                >
                  <SearchBoxContainerWrapper
                    searchOpen={searchOpen && !boxOpen}
                    prevSearchOpen={prevSearchOpen ? prevSearchOpen.toString() : undefined}
                  >
                    <div className="box-container-wrapper">
                      {recentSearches.length > 0 ? (
                        <div className="box-container">
                          <SearchBoxHeader>
                            <span className="header">RECENT SEARCHES</span>
                            <span
                              id={getElementIdFromFragmentBase(recentSearchesIdBase, 'ClearAll', 'button')}
                              className="clickableHeader"
                              onClick={() => clearRecentSearches()}
                            >
                              CLEAR ALL
                            </span>
                          </SearchBoxHeader>
                          <SearchBoxBody
                            id={getElementIdFromFragmentBase(recentSearchesIdBase, '', 'listContainer')}
                            onMouseLeave={() => {
                              setKeyboardHoverIndex(-1);
                            }}
                          >
                            {recentSearches.map((search, idx) => {
                              const resultIdBase = `${recentSearchesIdBase}Result${idx}`;
                              return (
                                <SearchResult
                                  className={idx === keyboardHoverIndex ? 'hovered' : ''}
                                  key={getElementIdFromFragmentBase(resultIdBase, '', 'container')}
                                  id={getElementIdFromFragmentBase(resultIdBase, '', 'container')}
                                  onClick={() => setRecentSearch(search)}
                                  onMouseEnter={() => {
                                    setKeyboardHoverIndex(idx);
                                  }}
                                  onMouseLeave={() => {
                                    setKeyboardHoverIndex(-1);
                                  }}
                                >
                                  <div
                                    style={{display: 'flex', flexDirection: 'row'}}
                                    key={getElementIdFromFragmentBase(resultIdBase, '', 'dataContainer')}
                                    id={getElementIdFromFragmentBase(resultIdBase, '', 'dataContainer')}
                                  >
                                    <span
                                      className="detail"
                                      id={getElementIdFromFragmentBase(resultIdBase, '', 'label')}
                                      key={getElementIdFromFragmentBase(resultIdBase, '', 'label')}
                                    >
                                      {search}
                                    </span>
                                  </div>
                                  <span
                                    className="return-icon-container"
                                    key={getElementIdFromFragmentBase(resultIdBase, 'Return', 'icon')}
                                  >
                                    <ReturnIcon />
                                  </span>
                                </SearchResult>
                              );
                            })}
                          </SearchBoxBody>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </SearchBoxContainerWrapper>
                </div>
              ) : null}
              {boxOpen ? (
                <div
                  id={getElementIdFromFragmentBase(searchResultsIdBase, '', 'container')}
                  style={{
                    width: 0,
                    height: 0,
                    zIndex: MAP_POPUP_Z_INDEX,
                    position: 'absolute',
                    right: 16,
                    paddingLeft: 4
                  }}
                >
                  <SearchBoxContainerWrapper
                    searchOpen={boxOpen}
                    prevSearchOpen={prevSearchOpen ? prevSearchOpen.toString() : undefined}
                  >
                    <div className="box-container-wrapper">
                      {searchResults.length > 0 ? (
                        <div className="box-container">
                          <SearchBoxHeader>
                            <span className="header">RESULTS</span>
                            <span
                              id={getElementIdFromFragmentBase(searchResultsIdBase, 'MatchCount', 'label')}
                              className="header"
                            >
                              {searchResults.length} MATCH{searchResults.length > 1 ? 'ES' : ''}
                            </span>
                          </SearchBoxHeader>
                          <SearchBoxBody
                            id={getElementIdFromFragmentBase(searchResultsIdBase, '', 'listContainer')}
                            onMouseLeave={() => {
                              setHoveredAircraft(undefined);
                              setKeyboardHoverIndex(-1);
                            }}
                            ref={scrollContainerRef}
                          >
                            {searchResults.map((aircraft, idx) => {
                              const resultIdBase = `${searchResultsIdBase}Result${idx}`;
                              const aircraftAttributes = getAircraftStyleAttributes(aircraft.status);

                              return (
                                <SearchResult
                                  className={
                                    (idx === keyboardHoverIndex ? 'hovered' : '') +
                                    (selectedAircraftId === aircraft.aircraftId ? ' selected' : '')
                                  }
                                  key={getElementIdFromFragmentBase(resultIdBase, '', 'container')}
                                  id={getElementIdFromFragmentBase(resultIdBase, '', 'container')}
                                  onClick={() => {
                                    searchSelect(aircraft);
                                    if (isolateRoute && aircraft.status === 'Offline') {
                                      dispatch({type: FleetMapAction.SET_ISOLATE_ROUTE, payload: 'false'});
                                      dispatch({
                                        type: FleetMapAction.ISOLATE_MODE_EXITED_SNACK_BAR,
                                        payload: {intl}
                                      });
                                    }
                                    dispatch({
                                      type: FleetMapAction.SET_SELECTED_OFFLINE_AIRCRAFT,
                                      payload: aircraft.aircraftId
                                    });
                                  }}
                                  onMouseEnter={() => {
                                    setRecentSearch(searchInputRef.current.value);
                                    setHoveredAircraft(aircraft.aircraftId);
                                    setKeyboardHoverIndex(idx);
                                  }}
                                  onMouseLeave={() => {
                                    setKeyboardHoverIndex(-1);
                                  }}
                                >
                                  <AvailabilityDotContainer
                                    animation={
                                      selectedAircraftId === aircraft.aircraftId
                                        ? `${aircraftAttributes.pulse}Small 1.75s infinite`
                                        : null
                                    }
                                  >
                                    <AvailabilityDot
                                      id={`fleetMap--searchResult__${aircraft.tailId}-ifcAvailability-dot`}
                                      key={`fleetMap--searchResult__${aircraft.tailId}-ifcAvailability-dot`}
                                      sx={{color: getAvailabilityColor(aircraft.status), width: 17, height: 16}}
                                    />
                                  </AvailabilityDotContainer>
                                  <div
                                    style={{display: 'flex', flexDirection: 'column', marginLeft: 3, marginTop: 2}}
                                    key={`fleetMap--searchResult__${aircraft.tailId}-container`}
                                    id={`fleetMap--searchResult__${aircraft.tailId}-container`}
                                  >
                                    <HighlightedSpan
                                      id={`fleetMap--searchResult__${aircraft.tailId}-tailAndSerialNumberInfo`}
                                      key={`fleetMap--searchResult__${aircraft.tailId}-tailAndSerialNumberInfo-highlight`}
                                      className="detail"
                                      criteria={searchCriteria}
                                      label={`${aircraft.serialNumber}${aircraft.serialNumber.length > 0 ? ' / ' : ''}${
                                        aircraft.tailId
                                      }`}
                                    />
                                  </div>
                                  <span
                                    className="return-icon-container"
                                    key={`fleeMap--searchResult__${aircraft.tailId}-returnIcon`}
                                  >
                                    <ReturnIcon />
                                  </span>
                                </SearchResult>
                              );
                            })}
                          </SearchBoxBody>
                        </div>
                      ) : (
                        <div className="card box-container">
                          <SearchBoxCardLabel>No results found</SearchBoxCardLabel>
                          <SearchBoxCardSubtext>Try adjusting your search term or filters</SearchBoxCardSubtext>
                        </div>
                      )}
                    </div>
                  </SearchBoxContainerWrapper>
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        )}
        <MapControlsButtonContainer>
          {disableOverlayOptions ? (
            <></>
          ) : (
            <LeftDataToolTip {...toolTipProps('Map Overlays', -3)}>
              <div>
                <MapControlsButton
                  id="mapControls__overlay-button"
                  Icon={LayersIcon}
                  isDisabled={showMapOverlayConfigurationPopup}
                  useActiveColor={
                    isTailTagOpen ||
                    kaOverlayActive ||
                    kuOverlayActive ||
                    txInhibitZonesBelow10kFeetActive ||
                    txInhibitZonesAtAllAltitudesActive ||
                    kaSatelliteActive ||
                    kuSatelliteActive ||
                    kaRegionalServiceCoverageActive ||
                    kuRegionalServiceCoverageActive ||
                    connectedBeamsActive
                  }
                  onClick={() => {
                    if (!showMapOverlayConfigurationPopup) setShowMapOverlayConfigurationPopup(true);
                  }}
                />
              </div>
            </LeftDataToolTip>
          )}

          <MapZoomCenterControls
            getFullElementId={(name, type) => `mapControls__${name}-${type}`}
            mapDivRef={mapDivRef}
            hasBorders
            currentZoom={currentZoom}
            minZoom={minZoom}
            maxZoom={MAP_MAX_ZOOM}
            handleRecenterClick={() => {
              if (boundListValid) {
                const bounds = new google.maps.LatLngBounds();
                boundList.forEach(({lat, lng}) => bounds.extend(new google.maps.LatLng(lat, lng)));
                map.fitBounds(bounds);
              } else if (filteredData?.length > 0) {
                const bounds = new google.maps.LatLngBounds();
                forEach(filteredData, function (val: AircraftStatus) {
                  bounds.extend(new google.maps.LatLng(val.lastLatitude, val.lastLongitude));
                });
                map?.fitBounds(bounds);
              } else {
                map?.setCenter(MAP_CENTER);
                map?.setZoom(MAP_DEFAULT_ZOOM);
              }
            }}
          />
          <LeftDataToolTip {...toolTipProps(isFullScreen ? 'Exit Fullscreen' : 'Fullscreen', -3)}>
            <div>
              <MapControlsButton
                id="mapControls__fullscreen-button"
                Icon={isFullScreen ? FullscreenExitIcon : FullscreenIcon}
                onClick={() => {
                  isFullScreen ? setIsFullScreen(false) : setIsFullScreen(true);
                }}
              />
            </div>
          </LeftDataToolTip>
        </MapControlsButtonContainer>
      </div>
      {disableOverlayOptions ? (
        <></>
      ) : (
        <MapOverlayConfigurations
          mapOverlayContext={mapControlContext}
          map={map}
          showMapOverlayPopup={showMapOverlayConfigurationPopup}
          closeOverlayPopup={() => {
            if (showMapOverlayConfigurationPopup) setShowMapOverlayConfigurationPopup(false);
          }}
          mapHeight={mapHeight}
          searchBoxDisabled={searchBoxDisabled}
          setTailTagOpen={setTailTagOpen}
          isTailTagOpen={isTailTagOpen}
          setShowLargePopup={setShowLargePopup}
          showLargePopup={showLargePopup}
          disableTailTagConfig={disableTailTagConfig}
          selectedAircraftData={selectedAircraftData}
          selectedAircraftNetwork={selectedAircraftNetwork}
        />
      )}
    </>
  );
};

export default injectIntl(MapControls);
