/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Chart ToolTip Style
 */

import React from 'react';
import {ColorProperty} from 'csstype';

import SeriesPrefixIcon from '../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesLabel from '../../common/elements/chart/ToolTip/SeriesLabel';
import SeriesValue from '../../common/elements/chart/ToolTip/SeriesValue';
import ToolTipLabel from '../../common/elements/chart/ToolTip/ToolTipLabel';
import {FONT_WEIGHT_SEMIBOLD} from '../../../utils/constants';
import {dataUsageValueFormatter} from '../summaryMetricsUtil';

export interface DataUsageToolTipProps {
  prefixIconColor?: ColorProperty;
  dataPercentage: number;
  dataUsage: number;
  chartLabel: string;
  card: string;
}

const DataUsageToolTip: React.FC<DataUsageToolTipProps> = ({
  prefixIconColor,
  dataPercentage,
  dataUsage,
  chartLabel,
  card
}: DataUsageToolTipProps) => {
  return (
    <ToolTipLabel width={'215px'}>
      <div id={`${card}-tooltip-PercentageUsaged`}>
        <SeriesPrefixIcon color={prefixIconColor} width={'12px'} />
        <SeriesLabel label={chartLabel} width={'116px'} fontWeight={FONT_WEIGHT_SEMIBOLD} />
        <SeriesValue value={dataPercentage + ' %'} width={'44px'} textAlign={'right'} />
      </div>

      <div id={`${card}-tooltip-DataUsed`}>
        <span style={{width: '20px', display: 'inline-block'}} />
        <SeriesLabel label={'Data Used'} width={'60px'} />
        <SeriesValue value={dataUsageValueFormatter(dataUsage, true)} width={'104px'} textAlign={'right'} />
      </div>
    </ToolTipLabel>
  );
};

export default DataUsageToolTip;
