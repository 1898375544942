/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Generally Vanilla styled button
 */

import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import {
  TOOLBAR_BUTTON_DISABLED_TEXT_COLOR,
  TOOLBAR_BUTTON_INACTIVE_TEXT_COLOR,
  TOOLBAR_BUTTON_INACTIVE_HOVER_TEXT_COLOR,
  TOOLBAR_BUTTON_SELECTED_BACKGROUND_COLOR,
  TOOLBAR_BUTTON_POPULATED_TEXT_COLOR,
  TOOLBAR_BUTTON_POPULATED_BACKGROUND_COLOR,
  TOOLBAR_BUTTON_POPULATED_HOVER_TEXT_COLOR,
  TOOLBAR_BUTTON_POPULATED_HOVER_BACKGROUND_COLOR,
  TOOLBAR_BUTTON_POPULATED_BORDER_COLOR
} from './theme/Colors';

interface StyledButtonProps {
  id?: string;
  selected?: boolean;
  populated?: boolean;
  disabled?: boolean;
  onClick?: ($event: any) => void;
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
}

/**
 * Returns the text color given the provided button props/state
 * @param buttonProps the button properties
 * @param hover whether the button is being hovered over
 * @returns the text color string
 */
const getTextColor = (buttonProps: StyledButtonProps, hover: boolean): string => {
  if (buttonProps.disabled) {
    return TOOLBAR_BUTTON_DISABLED_TEXT_COLOR;
  } else if (buttonProps.populated) {
    return hover ? TOOLBAR_BUTTON_POPULATED_HOVER_TEXT_COLOR : TOOLBAR_BUTTON_POPULATED_TEXT_COLOR;
  } else {
    return hover ? TOOLBAR_BUTTON_INACTIVE_HOVER_TEXT_COLOR : TOOLBAR_BUTTON_INACTIVE_TEXT_COLOR;
  }
};

/**
 * Returns the background color CSS content given the provided button props/state
 * @param buttonProps the button properties
 * @param hover whether the button is being hovered over
 * @returns the background color CSS or an empty string if the default color should be used
 */
const getBackgroundColorCss = (buttonProps: StyledButtonProps, hover: boolean): string => {
  let bgColor = undefined;

  if (!buttonProps.disabled) {
    if (buttonProps.populated) {
      bgColor = hover ? TOOLBAR_BUTTON_POPULATED_HOVER_BACKGROUND_COLOR : TOOLBAR_BUTTON_POPULATED_BACKGROUND_COLOR;
    } else if (buttonProps.selected) {
      bgColor = TOOLBAR_BUTTON_SELECTED_BACKGROUND_COLOR;
    }
  }

  return bgColor ? `${bgColor}` : '';
};

/**
 * Returns the border CSS content given the provided button props/state
 * @param buttonProps the button properties
 * @returns the border CSS or an empty string if the default border should be used
 */
const getBorderCss = (buttonProps: StyledButtonProps): string =>
  !buttonProps.disabled && buttonProps.populated ? `1px solid ${TOOLBAR_BUTTON_POPULATED_BORDER_COLOR}` : '';

// Pull out any props we don't want attached to HTML DOM elements, eliminating the following warning:
//  'Warning: Received "true" for a non-boolean attribute'
// See: https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
const SanitizedButton = (props: StyledButtonProps) => <Button {...props}>{props.children}</Button>;

const StyledButton = styled(SanitizedButton)`
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: none;
  max-height: 32px;
  padding: 6px 12px;
  margin-left: 12px;

  color: ${(props: StyledButtonProps) => getTextColor(props, false)};

  svg {
    path {
      fill: ${(props: StyledButtonProps) => getTextColor(props, false)};
    }
  }

  background-color: ${(props: StyledButtonProps) => getBackgroundColorCss(props, false)};
  border: ${(props: StyledButtonProps) => getBorderCss(props)};

  &:hover {
    color: ${(props: StyledButtonProps) => getTextColor(props, true)};

    svg {
      path {
        fill: ${(props: StyledButtonProps) => getTextColor(props, true)};
      }
    }

    background-color: ${(props: StyledButtonProps) => getBackgroundColorCss(props, true)};
  }
`;

export default StyledButton;
