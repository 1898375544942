/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Trafic composition cumulative data usage card
 */

import React from 'react';
import AvailabilityDot from '../common/AvailabilityDot';
import {ChartWrapper} from '@viasat/insights-components';
import {ChartType} from '../common/elements/chart/types';
import {MAP_POPUP_DEFAULT_BORDER, TRAFFIC_COMPOSITION_CHART_PALETTE} from '../common/theme/Colors';
import {IntlShape, useIntl} from 'react-intl';
import PieChart from '../common/elements/chart/PieChart';
import {formatConfig} from '../common/elements/chart/chartUtils';
import {add, isNil} from 'lodash';
import {ITrafficCompositionCumulative} from '../../store/queries/summaryMetrics/trafficCompositionCumulativeQuery';
import {
  CardColumn,
  CardContainer,
  CardRow,
  CardRowContainter,
  CardRowTitle,
  CardRowValue,
  ChartContainer,
  ChartLegend,
  NoDataAvailable
} from './SummaryMetricsStyles';
import {convertUsageData, getTrafficCompositionChartData, tooltip} from './usageCards/utils';
import {ITotalDataUsage} from '../../store/queries/summaryMetrics/totalDataUsageQuery';
import {formatChartDateRange} from './summaryMetricsUtil';
import {ChartCard} from './SummaryMetricsPage';

export interface TrafficCompositionCumulativeCardProps {
  tcCumulativeData: ITrafficCompositionCumulative;
  isLoading: boolean;
  totalDataUsageData: ITotalDataUsage;
  startDate: string;
  endDate: string;
  draggableProps?: any;
}

enum TRAFFIC_CATEGORIES_LABEL_MAPPING {
  streamingGaming = 'Streaming & Gaming',
  browsingSocialNetworks = 'Browsing & Social Networks',
  productivityCloudServices = 'Productivity & Cloud Services',
  backgroundAppUpdates = 'Background & App Updates',
  otherSpeedTest = 'Other & Speed Test',
  vpn = 'VPN'
}

const TRAFFIC_CATEGORIES_COLOR_MAPPING = {
  streamingGaming: TRAFFIC_COMPOSITION_CHART_PALETTE[0],
  browsingSocialNetworks: TRAFFIC_COMPOSITION_CHART_PALETTE[1],
  productivityCloudServices: TRAFFIC_COMPOSITION_CHART_PALETTE[2],
  backgroundAppUpdates: TRAFFIC_COMPOSITION_CHART_PALETTE[3],
  otherSpeedTest: TRAFFIC_COMPOSITION_CHART_PALETTE[4],
  vpn: TRAFFIC_COMPOSITION_CHART_PALETTE[5]
};

/**
 * create chart config
 * @param trafficCompositionCumulativeConfig
 * @returns return chart configuration
 */
const formatChartConfig = (
  intl: IntlShape,
  totalTrafficCompValue: number,
  totalDataUsed: number,
  tcCumulativeData: any,
  isLoading: boolean
) => {
  const id = 'summaryMetrics.trafficCompositionCumulative.pieChart';
  const title = null;
  const chartType = ChartType.PIE;
  const palette = TRAFFIC_COMPOSITION_CHART_PALETTE;
  const highchartsIdBase = 'trafficCompositionCumulative';
  const chartSubTitle = '';
  const noDataChartTitle = 'No Data Available';
  const chartTitle = '';
  const height = 200;
  const size = 186;
  const hideLegend = true;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  let chartData = [];
  const trafficTotalDataUsage = !isNil(totalTrafficCompValue)
    ? Number(Object.values(tcCumulativeData).reduce(add, 0))
    : 0;
  const trafficCompositionValuesInPercentage = getTrafficCompositionChartData(totalTrafficCompValue, tcCumulativeData);
  const defaultChartPalette = [];
  if (totalTrafficCompValue > 0) {
    for (const trafficCategoryKey in trafficCompositionValuesInPercentage) {
      chartData.push({
        id: trafficCategoryKey,
        name: TRAFFIC_CATEGORIES_LABEL_MAPPING[trafficCategoryKey],
        y: Number(convertUsageData(totalDataUsed * (tcCumulativeData[trafficCategoryKey] / trafficTotalDataUsage))),
        percent: trafficCompositionValuesInPercentage[trafficCategoryKey],
        visible: true,
        color: TRAFFIC_CATEGORIES_COLOR_MAPPING[trafficCategoryKey]
      });
    }
  }

  const chartConfig = {
    id,
    defaultChartPalette,
    title,
    chartTitle,
    chartSubTitle,
    chartData,
    chartType,
    palette,
    tooltip,
    highchartsIdBase,
    noDataChartTitle,
    height,
    hideLegend,
    size
  };

  const formattedConfig = formatConfig(intl, chartConfig);
  return (
    <ChartContainer className="chart-pie">
      <ChartWrapper
        getFullElementId={(name, type) => `summaryMetrics--trafficCompositionCumulative--pieChart__${name}-${type}`}
        isLoading={isLoading}
        isError={false}
        chartHeight={195}
      >
        {Object.keys(formattedConfig).length === 0 ? <></> : <PieChart {...formattedConfig} />}
      </ChartWrapper>
    </ChartContainer>
  );
};

const TrafficCompositionCumulativeCard: React.FC<TrafficCompositionCumulativeCardProps> = ({
  tcCumulativeData,
  isLoading,
  totalDataUsageData,
  startDate,
  endDate,
  draggableProps
}) => {
  const intl = useIntl();
  const idPrefix = 'summaryMetrics_trafficComposition';
  const totalTrafficCompValue = tcCumulativeData && Number(Object.values(tcCumulativeData).reduce(add, 0));
  const trafficCompositionLegendPercent =
    tcCumulativeData && getTrafficCompositionChartData(totalTrafficCompValue, tcCumulativeData);
  const trafficCategories = tcCumulativeData && Object.keys(trafficCompositionLegendPercent);
  const combinedDataUsage =
    totalDataUsageData && !isNil(totalDataUsageData)
      ? totalDataUsageData.in_flight_usage + totalDataUsageData.on_ground_usage
      : null;
  const totalDataUsed = combinedDataUsage ? Number(combinedDataUsage) : 0;
  return (
    <ChartCard
      id="TrafficCompositionCumulativeCard"
      isLoading={isLoading}
      title="Traffic Composition"
      helpText={intl.formatMessage({id: 'summary_metrics.traffic_composition_chart.title_tooltip'})}
      borderColor={MAP_POPUP_DEFAULT_BORDER}
      draggableProps={draggableProps}
    >
      {!isNil(tcCumulativeData) && totalTrafficCompValue && totalDataUsed ? (
        <CardContainer>
          <CardColumn hasBorder>
            {formatChartConfig(intl, totalTrafficCompValue, totalDataUsed, tcCumulativeData, isLoading)}
            <ChartLegend id="summary-metrics-traffic-composition__legend">
              {formatChartDateRange(startDate)}
              {' - '}
              {formatChartDateRange(endDate)}
            </ChartLegend>
          </CardColumn>
          <CardColumn>
            <CardRowContainter>
              {trafficCategories?.map((trafficCategory, index) => {
                return (
                  <CardRow className={`${idPrefix}_row`}>
                    <CardRowTitle id={`${idPrefix}-${trafficCategory}__label`}>
                      <AvailabilityDot
                        id={`${idPrefix}-${trafficCategory}-dot`}
                        sx={{
                          fill: TRAFFIC_CATEGORIES_COLOR_MAPPING[trafficCategory],
                          width: 12,
                          height: 12,
                          marginRight: '6px'
                        }}
                      />
                      {TRAFFIC_CATEGORIES_LABEL_MAPPING[trafficCategory]}
                    </CardRowTitle>
                    <CardRowValue id={`${idPrefix}-${trafficCategory}__value`}>
                      {trafficCompositionLegendPercent[trafficCategory]}
                      {'%'}
                    </CardRowValue>
                  </CardRow>
                );
              })}
            </CardRowContainter>
          </CardColumn>
        </CardContainer>
      ) : (
        <NoDataAvailable>No data available.</NoDataAvailable>
      )}
    </ChartCard>
  );
};

export default TrafficCompositionCumulativeCard;
