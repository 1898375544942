/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tool tip utility functions
 *
 */
import React from 'react';

/**
 * Converts the INTL help text elements into formatted help text
 * @param nodes List of help text from the ID
 * @returns Formatted help text
 */
export const formatToolTipText = (...nodes: any[][]) => {
  return nodes[0]
    .map(node => node.split('\n').map((item: React.ReactNode, i: string) => <p key={i}>{item}</p>))
    .reduce((nodes, node) => nodes.concat(node), []);
};
