/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: hidden columns encode/decode bitmask methods
 */

import {FlightListColumnId} from '../lists/flight/flightListUtil';
import {FlightPlanListColumnId} from '../connectivityOutlook/ConnectivityOutlookUtils';
import {AircraftStatusListColumnId} from '../lists/aircraftStatus/util/aircraftStatusListUtil';
import {TailListColumnId} from './tail/util/tailListUtil';

// Column ID Type constants
export const MIN_COLUMN_ID_TYPE = 0;
export const MAX_COLUMN_ID_TYPE = 255;

export const HEX_STRING_BASE_16 = 16;
export const HEX_STRING_PAD_LENGTH = 2;
export const HEX_STRING_INVALID = '??';

export const UNKNOWN_COLUMNS_MSG = 'UNKNOWN Columns';

// Column ID Type definition
export type ColumnIdEnumType = typeof FlightListColumnId;
export type ColumnIdType = FlightListColumnId;
export type AircraftStatusListColumnIdEnumType = typeof AircraftStatusListColumnId;
export type TailListColumnIdEnumType = typeof TailListColumnId;

// Column ID Type definition for connectivity outlook planner columns

export type ConnectivityPlannerColumnEnumIdType = typeof FlightPlanListColumnId;
