import ITheme from './AppThemeInterface';

export default {
  defaultFontSize: '16px',
  appFontFamily: "'Source Sans Pro', sans-serif",
  primaryTextColor: '#000000',
  errorFormTextColor: '#ff3300',
  inputFooterColor: 'transparent',
  inputPlaceholderColor: '',
  primaryBackgroundColor: '#efefef',
  secondaryBackgroundColor: '#125986',
  tertiaryBackgroundColor: '#fafafa',
  primaryDarkBackgroundColor: '#2a2b39',
  primaryDarkTextColor: '#ffffff',
  primaryInputTextColor: 'rgba(0, 0, 0, 0.87)',
  primaryInputBackgroundColor: '#ffffff',
  primaryInputBackgroundOpacity: '0.55',
  appHeaderHeightSmall: '52px',
  appHeaderHeightLarge: '52px',
  appSliderMenuWidth: '52px',
  appNavDrawerWidth: '240px',
  primaryBorder: '1px solid #dadada'
} as any as ITheme;
