/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Satellite Beam Number Used ToolTip Style and Formatting
 */

import React from 'react';
import {ColorProperty} from 'csstype';
import styled from '@emotion/styled';
import SeriesPrefixIcon from '../../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesLabel from '../../../common/elements/chart/ToolTip/SeriesLabel';

const TitleContainer = styled.div`
  padding-top: 1px;
  padding-bottom: 4px;
  span {
    font-weight: 600 !important;
    padding-right: 1px;
  }
`;

const HandoverContainer = styled.span`
  font-weight: 400;
`;

const BeamStatusPrefixIcon = styled.span`
  display: inline-block;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  vertical-align: 10%;
  width: 16px;
  margin-right: 5px;
`;

export interface SatelliteHealthToolTipProps {
  idBase: string;
  color: ColorProperty;
  satellite: string;
  beamId: number;
  handoverLabel: string;
  isValueAddedReseller: boolean;
}

const SatelliteHealthToolTip: React.FC<SatelliteHealthToolTipProps> = ({
  idBase,
  color,
  satellite,
  beamId,
  handoverLabel,
  isValueAddedReseller
}) => {
  const label = !isValueAddedReseller ? `${satellite}: Beam ${beamId}` : `${satellite}: Connected`;
  return (
    <div id={idBase}>
      <TitleContainer>
        <SeriesPrefixIcon color={color} />
        <SeriesLabel label={label} width="125px" />
      </TitleContainer>
      {!isValueAddedReseller ? (
        <HandoverContainer>
          <BeamStatusPrefixIcon> &bull;</BeamStatusPrefixIcon>
          <SeriesLabel label={handoverLabel} width="100px" />
        </HandoverContainer>
      ) : null}
    </div>
  );
};

export default SatelliteHealthToolTip;
