/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Wrapper Component for Map
 */

import React, {useRef, useState} from 'react';
import styled from '@emotion/styled';
import {OverlayView} from '@react-google-maps/api';

import MapLegendWrapper from '../../mapView/legend/MapLegendWrapper';
import MapControls from '../../mapView/MapControls';
import MapAircraft from '../../mapView/MapAircraft';
import {FLIGHT_DETAILS_LIVE_BG_COLOR, FLIGHT_DETAILS_LIVE_BORDER_COLOR} from '../../common/theme/Colors';
import MapMarker from '../../mapView/MapMarker';
import LabTerminalMapMarker from '../../mapView/LabTerminalMapMarker';
import {
  SELECTED_AIRCRAFT_Z_INDEX,
  FLIGHT_DETAILS_MAP_MIN_ZOOM,
  MAP_CARD_HEIGHT,
  LIVE_FLIGHT_VIEW_NAME,
  MAP_DEFAULT_ZOOM,
  MAP_CENTER
} from '../../../utils/constants';
import MapFlightPath from '../../mapView/MapFlightPath';
import {MapViewContext, AircraftMapData} from '../../../utils/MapUtil';
import {FlightPathInfoDataProps} from '../../../store/queries/flightDetails/flightPathInfoQuery';
import MapStateContainer from '../../mapView/MapStateContainer';

const MapCardContainer = styled.div`
  background-color: ${FLIGHT_DETAILS_LIVE_BG_COLOR};
  display: flex;
  justify-content: row;
  margin: 16px 8px 0 16px;
  border: 1px solid ${FLIGHT_DETAILS_LIVE_BORDER_COLOR};
  border-radius: 4px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: text;
  height: ${MAP_CARD_HEIGHT}px;

  white-space: nowrap;
  overflow: hidden;
  .gm-style > div,
  .gm-style > div > div:last-child {
    overflow: hidden;
    border-radius: 4px;
  }
  @media print {
    height: 420px !important;
    #flightDetails__googleMap-container {
      height: 420px !important;
    }
  }
`;

export interface MapCardProps {
  isLoading: boolean;
  showAircraft: boolean;
  flightPathData: FlightPathInfoDataProps;
  aircraftData: AircraftMapData;
  setMinimize: () => void;
  hoverLineContainerRef: any;
  chartTimeSettings: any;
  liveFlightOffset: {startOffSet: number; unit: number};
  isMinimized: boolean;
  parentLeftOffset: number;
  flightDetected: boolean;
  isLiveFlight: boolean;
  origin: string;
  destination: string;
  airports: any;
}

const MapCard: React.FC<MapCardProps> = (mapCardProps) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(
    sessionStorage.curMapZoom ? Number(sessionStorage.curMapZoom) : MAP_DEFAULT_ZOOM
  );

  const mapDivRef = useRef(null);

  const flightPathDetails = mapCardProps?.flightPathData;
  const isLabTerminal = mapCardProps.aircraftData?.isLabTerminal;
  const initialBoundList = flightPathDetails
    ? [
        flightPathDetails.start,
        flightPathDetails.end,
        ...flightPathDetails.flightPath.map((p: any) => ({lat: p.lat, lng: p.lng})),
        ...flightPathDetails.futureFlightPath.map((p: any) => ({lat: p.lat, lng: p.lng}))
      ]
    : [];

  const contextOptions = {
    minZoom: FLIGHT_DETAILS_MAP_MIN_ZOOM
  };

  const pixelPositionOffset = {
    x: -12,
    y: -24
  };

  const historicalFlightOffset = {
    x: -12,
    y: -32
  };

  const labPositionOffset = {
    x: -15,
    y: -15
  };

  return (
    <MapCardContainer>
      <MapStateContainer
        getFullElementId={(name, type) => `flightDetails__${name}-${type}`}
        zoom={zoom}
        setZoom={setZoom}
        center={sessionStorage.curMapCenter ? JSON.parse(sessionStorage.curMapCenter) : MAP_CENTER}
        isLoading={mapCardProps.isLoading}
        mapDivRef={mapDivRef}
        mapOptions={contextOptions}
        mapContainerStyle={{width: '100%', height: '100%'}}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        initialBoundList={initialBoundList}
      >
        <MapControls
          mapControlContext={MapViewContext.FLIGHT_DETAILS_MAP}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          mapDivRef={mapDivRef}
          popupFaFlightId={null}
          setPopup={() => {}}
          setHoveredFaFlightId={null}
          minZoom={FLIGHT_DETAILS_MAP_MIN_ZOOM}
          boundList={initialBoundList}
          searchBoxDisabled={true}
          disableTailTagConfig={true}
          canMinimize={true}
          setMinimize={() => mapCardProps.setMinimize()}
          selectedAircraftData={mapCardProps.aircraftData}
          selectedAircraftNetwork={mapCardProps.aircraftData.networkCapability}
        />
        <MapLegendWrapper
          context={MapViewContext.FLIGHT_DETAILS_MAP}
          legendWrapperHeight="26px"
          viewName={LIVE_FLIGHT_VIEW_NAME}
          metricsData={[]}
          mapDivRef={mapDivRef}
        />
        {!isLabTerminal && flightPathDetails?.start && (
          <MapMarker
            context={
              mapCardProps.showAircraft ? MapViewContext.LIVE_FLIGHT_DETAILS_MAP : MapViewContext.FLIGHT_DETAILS_MAP
            }
            pixelPositionOffset={mapCardProps.showAircraft ? pixelPositionOffset : historicalFlightOffset}
            key={`${0}-start`}
            type="start"
            internal={false}
            mapDivRef={mapDivRef}
            location={flightPathDetails.start}
            flightDetected={mapCardProps?.flightDetected}
            airport={mapCardProps?.airports ? mapCardProps?.airports[mapCardProps?.origin] : undefined}
          />
        )}
        {!isLabTerminal && flightPathDetails?.end && !mapCardProps.showAircraft && (
          <MapMarker
            context={
              mapCardProps.showAircraft ? MapViewContext.LIVE_FLIGHT_DETAILS_MAP : MapViewContext.FLIGHT_DETAILS_MAP
            }
            pixelPositionOffset={mapCardProps.showAircraft ? pixelPositionOffset : historicalFlightOffset}
            key={`${0}-end`}
            type="end"
            internal={false}
            mapDivRef={mapDivRef}
            location={flightPathDetails.end}
            flightDetected={mapCardProps?.flightDetected}
            airport={mapCardProps?.airports ? mapCardProps?.airports[mapCardProps?.destination] : undefined}
          />
        )}
        {!isLabTerminal && flightPathDetails?.destination && (
          <MapMarker
            context={
              mapCardProps.showAircraft ? MapViewContext.LIVE_FLIGHT_DETAILS_MAP : MapViewContext.FLIGHT_DETAILS_MAP
            }
            pixelPositionOffset={mapCardProps.showAircraft ? pixelPositionOffset : historicalFlightOffset}
            key={`${0}-end`}
            type="end"
            internal={false}
            mapDivRef={mapDivRef}
            location={flightPathDetails.destination}
            flightDetected={mapCardProps?.flightDetected}
            airport={mapCardProps?.airports ? mapCardProps?.airports[mapCardProps?.destination] : undefined}
          />
        )}
        {isLabTerminal && flightPathDetails?.start && (
          <LabTerminalMapMarker
            pixelPositionOffset={labPositionOffset}
            key={`${0}-start`}
            type="start"
            internal={false}
            mapDivRef={mapDivRef}
            location={flightPathDetails.start}
            aircraftData={mapCardProps.aircraftData}
          />
        )}
        {isLabTerminal && flightPathDetails?.end && !mapCardProps.showAircraft && (
          <LabTerminalMapMarker
            pixelPositionOffset={labPositionOffset}
            key={`${0}-end`}
            type="end"
            internal={false}
            mapDivRef={mapDivRef}
            location={flightPathDetails.end}
            aircraftData={mapCardProps.aircraftData}
          />
        )}
        {flightPathDetails?.start && (
          <MapFlightPath
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            parentLeftOffset={mapCardProps.parentLeftOffset}
            flightPathData={flightPathDetails}
            isFlightPathLoading={mapCardProps.isLoading}
            hoverLineContainerRef={mapCardProps.hoverLineContainerRef}
            chartTimeSettings={mapCardProps.chartTimeSettings}
            liveFlightOffset={mapCardProps.liveFlightOffset}
            isMinimized={mapCardProps.isMinimized}
            mapViewContext={MapViewContext.FLIGHT_DETAILS_MAP}
            disablePopup={false}
            isFullScreen={isFullScreen}
            isLiveFlight={mapCardProps.isLiveFlight}
          />
        )}
        {mapCardProps.showAircraft && (
          <MapAircraft
            aircraft={mapCardProps.aircraftData}
            key="flightDetailsAircraft"
            hoveredAircraft={null}
            setHoveredAircraft={() => void 0}
            setPopup={() => void 0}
            isTailTagOpen={false}
            showLargePopup={false}
            onClick={() => {}}
            zIndexOverride={SELECTED_AIRCRAFT_Z_INDEX}
            mapDivRef={mapDivRef}
          />
        )}
      </MapStateContainer>
    </MapCardContainer>
  );
};
export default MapCard;
