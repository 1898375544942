/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: The popup shown when a satellite beam is selected on fleet map
 */

import {Close} from '@mui/icons-material';
import {isArray, isString} from 'lodash';
import React from 'react';
import styled from '@emotion/styled';
import {SatelliteIcon} from '@viasat/insights-components';
import {MAP_ELID_SECTION, NO_DATA_INDICATOR} from '../../../utils/constants';
import {getElementIdFromSectionBase, getElementIdSectionBase} from '../../../utils/ElementIdUtils';
import {MapPointType} from '../../../utils/MapUtil';
import {
  BLACK,
  FOURTEEN_PCT_TRANSPARENT_BLACK,
  GREY,
  MAP_BEAMS_ICON_SECONDARY_COLOR,
  MAP_BEAMS_ROW_IMPAIRED_COLOR,
  MAP_BEAMS_ROW_OUTAGE_COLOR,
  MAP_POPUP_ACCENT_COLOR,
  MAP_POPUP_DEFAULT_BORDER,
  MAP_POPUP_NETWORK_ACCENT_COLOR,
  MAP_POPUP_NETWORK_URL_COLOR,
  POPUP_TEXT_VALUE,
  TWELVE_PCT_TRANSPARENT_BLACK,
  TWENTY_PCT_TRANSPARENT_BLACK
} from '../../common/theme/Colors';
import {PopupNetworkTypeFragment} from '../aircraftPopups/AircraftPopupCommon';
import {PopupBody, PopupCloseButton, PopupHeader} from '../aircraftPopups/AircraftPopupStyles';
import {SatelliteBeamIncident} from '../../../store/queries/fleetMap/satelliteBeamIncidentEvents';
import {ESatBeamImpactType} from '../settings/MapHelper';

const POPUP_WIDTH = 180;
export const SAT_BEAM_POPUP_OFFSET_X = 20;
export const SAT_BEAM_POPUP_OFFSET_Y = -30;

const BEAM_DETAILS_NO_DATA = 'Data Not Available';

/**
 * Map impact to UI messaging
 * @param impact to map
 * @returns UI messaging of impact
 */
const getImpactMapping = (impact: string): string => {
  switch (impact) {
    case 'Outage':
      return 'Offline';
    case 'Impairment':
      return 'Impaired';
    default:
      return null;
  }
};

export enum ESatBeamProps {
  SAT_BEAM_ID = 'id',
  SATELLITE_ID = 'satelliteId',
  ABBREV = 'abbreviation',
  BEAM_ID = 'beamId',
  DETAILS = 'beamDetails',
  IMPACT = 'impact',
  INCIDENTS = 'incidents'
}

enum ESatBeamDetailsProps {
  SMTS = 'SMTS',
  MACD = 'MACD',
  CD = 'CD',
  SMAC = 'SMAC',
  NETWORK_REGION = 'Network Region',
  COMMON_HUB = 'Common Hub Name',
  MAP_BEAM = 'Map Beam Name'
}

export interface ISatBeamDetails {
  smts: Array<string> | undefined;
  macd: Array<string> | undefined;
  cd: Array<string> | undefined;
  smac: Array<string> | undefined;
  networkRegionCode: Array<string> | undefined;
  mapBeam: Array<string> | undefined;
  commonHub: Array<string> | undefined;
}

export const initialSatBeamDetails: ISatBeamDetails = {
  smts: undefined,
  macd: undefined,
  cd: undefined,
  smac: undefined,
  networkRegionCode: undefined,
  mapBeam: undefined,
  commonHub: undefined
};

export interface ISatBeamData {
  beamCenter?: MapPointType;
  satBeamId: string | undefined;
  satId: number | undefined;
  abbreviation: string | undefined;
  beamId: number | undefined;
  networkType?: string;
  details?: ISatBeamDetails;
  flightId?: string;
  satelliteName?: string;
  impact: string | undefined;
  incidents: SatelliteBeamIncident[] | undefined;
}

export const initialSatBeamData: ISatBeamData = {
  beamCenter: undefined,
  satBeamId: undefined,
  satId: undefined,
  abbreviation: undefined,
  beamId: undefined,
  networkType: undefined,
  details: undefined,
  flightId: undefined,
  satelliteName: undefined,
  impact: undefined,
  incidents: undefined
};

interface ISatBeamPopupProps {
  isInternal: Boolean;
  viewAsCustomer: Boolean;
  satBeamData: ISatBeamData;
  close: () => void;
}

/**
 * Convert Satellite Beam Data for Popup
 * @param beamCenter Beam Center Point of Satellite Beam Polygon, this is where the Left-Top of the Popup will render
 * @param properties Google Maps Data feature properties
 * @returns Satellite Beam Data as JSON
 */
export const convertSatBeamData = (
  beamCenter: any,
  feature: any,
  impact: ESatBeamImpactType,
  flightId: string | undefined,
  incidents?: SatelliteBeamIncident[]
): ISatBeamData => {
  // generate beam details object
  const properties = feature.properties;
  const beamDetails = properties.beamDetails;
  const networkType = properties.network;
  const details: ISatBeamDetails = {...initialSatBeamDetails};
  if (networkType === 'KuBeams' || networkType === 'ku-connected') {
    details.mapBeam = [properties.mapBeamName];
    details.commonHub = [properties.commonHubName];
    details.networkRegionCode = [properties.networkRegionCode];
  } else {
    for (const [key] of Object.entries(beamDetails)) {
      // Add formatting on first space as new line.
      let value = isString(beamDetails[key]) ? beamDetails[key]?.split(/ (.+)/).filter((x) => x) : beamDetails[key];
      details[key.toLowerCase()] = value.length > 0 ? value : NO_DATA_INDICATOR;
    }
  }

  // generate beam data json
  const json = {
    beamCenter: beamCenter,
    abbreviation: properties?.abbreviation ? properties.abbreviation : NO_DATA_INDICATOR,
    satBeamId: feature.id ? feature.id : undefined,
    satId: properties?.satelliteId ? properties.satelliteId : undefined,
    satelliteName: properties?.satelliteRegionName ? properties.satelliteRegionName : undefined,
    beamId: properties?.beamId ? properties.beamId : NO_DATA_INDICATOR,
    networkType: properties?.network ? properties.network : undefined,
    details: details.smts || details.smac || details.networkRegionCode ? details : undefined,
    flightId: flightId,
    impact: impact ? getImpactMapping(impact) : undefined,
    incidents: incidents ? incidents : undefined
  };

  return json;
};

const PopupContainer = styled.div<{
  position: MapPointType;
  width: number;
}>`
  position: absolute;
  cursor: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  width: ${(props) => props.width}px;
  border-radius: 4px;
  z-index: 9999;
  box-shadow: 0px 3px 4px 0px ${FOURTEEN_PCT_TRANSPARENT_BLACK}, 0px 3px 3px -2px ${TWELVE_PCT_TRANSPARENT_BLACK},
    0px 1px 8px 0px ${TWENTY_PCT_TRANSPARENT_BLACK};
`;

export const PopupRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-start;
`;

export const PopupDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  margin-bottom: 2px;
  padding: 2px 0px;
`;

export const PopupColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: baseline;
  & > div:last-child {
    margin-bottom: 0px;
  }
`;

interface PopupBeamInfoFragmentProps {
  primaryIdFontSize: number;
  secondaryIdFontSize: number;
  outerPadding: string;
  satBeamData: ISatBeamData;
  elementIdBase: string;
}
// height: 12px;
const PopupBeamInfoContainer = styled.div<{
  secondaryIdFontSize: number;
  outerPadding: string;
  primaryIdFontSize: number;
  valueFontSize?: number;
}>`
  font-size: ${(props) => props.secondaryIdFontSize}px;
  display: flex;
  align-items: baseline;
  padding: ${(props) => props.outerPadding};

  .title-container {
    display: flex;
    align-items: baseline;
    width: ${POPUP_WIDTH}px;

    color: ${MAP_POPUP_ACCENT_COLOR};
    .abbreviation {
      display: flex;
      font-size: ${(props) => props.primaryIdFontSize}px;
      font-weight: 600;
      margin-right: 4px;
      color: ${BLACK};
    }
  }

  .divider {
    display: flex;
    align-items: baseline;
    color: ${MAP_POPUP_ACCENT_COLOR};
    // padding: 0px 2px 0px 2px;
    height: 2px;
  }

  .incident-impact-status {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 110px;
    font-size: ${(props) => props.valueFontSize}px;
    font-weight: 600;
    color: ${POPUP_TEXT_VALUE};
  }
`;

const PopupBeamInfoFragment: React.FC<PopupBeamInfoFragmentProps> = (props) => {
  const {satBeamData, elementIdBase} = props;

  return (
    <PopupBeamInfoContainer
      primaryIdFontSize={props.primaryIdFontSize}
      secondaryIdFontSize={props.secondaryIdFontSize}
      outerPadding={props.outerPadding}
    >
      {satBeamData.networkType === 'KuBeams' || satBeamData.networkType === 'ku-connected' ? (
        <div className="title-container">
          <div className="abbreviation" id={getElementIdFromSectionBase(elementIdBase, 'satelliteName', 'label')}>
            {satBeamData.satelliteName}
          </div>
        </div>
      ) : (
        <div className="title-container">
          <div className="abbreviation" id={getElementIdFromSectionBase(elementIdBase, 'abbreviation', 'label')}>
            {satBeamData.abbreviation}
            {' /'}
          </div>
          <div className="beam-identifier" id={getElementIdFromSectionBase(elementIdBase, 'beamId', 'label')}>
            {satBeamData.beamId}
          </div>
        </div>
      )}
      {satBeamData.impact && satBeamData.impact !== ESatBeamImpactType.NONE ? (
        <div
          className="incident-impact-status"
          id={getElementIdFromSectionBase(elementIdBase, 'incidentImpact', 'label')}
        >
          <PopupColumn>{satBeamData.impact}</PopupColumn>
        </div>
      ) : undefined}
    </PopupBeamInfoContainer>
  );
};
interface PopupBeamDataFragmentProps {
  titleFontSize: number;
  labelFontSize: number;
  valueFontSize: number;
  outerPadding: string;
  beamDetails: ISatBeamDetails;
  elementIdBase: string;
  labelWidth: string;
  dividerWidth: string;
}

const PopupBeamDataContainer = styled.div<{
  outerPadding: string;
  titleFontSize: number;
  labelFontSize: number;
  valueFontSize: number;
  labelWidth: string;
  dividerWidth: string;
}>`
  width: ${POPUP_WIDTH};
  line-height: 16px;
  padding: ${(props) => props.outerPadding};

  .title-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 0 10px 0;
    color: ${MAP_POPUP_ACCENT_COLOR};

    .title {
      margin-right: 10px;
      font-size: ${(props) => props.titleFontSize}px;
      font-weight: 600;
      color: ${BLACK};
    }

    .divider {
      width: ${(props) => props.dividerWidth};
      height: 1px;
      color: ${MAP_POPUP_ACCENT_COLOR};
      padding: 0;
      border: 0;
      background: ${MAP_POPUP_DEFAULT_BORDER};
    }
  }

  .detail-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    width: ${(props) => props.labelWidth};
    margin-left: 10px;
    padding: 0;

    .detail-label {
      display: flex;
      justify-content: flex-start;
      font-size: ${(props) => props.labelFontSize}px;
      font-weight: 400;
      color: ${GREY};
    }

    .detail-value {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      width: 110px;
      font-size: ${(props) => props.valueFontSize}px;
      font-weight: 600;
      color: ${POPUP_TEXT_VALUE};
    }

    .detail-no-data {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;
      margin-right: 4px;
      font-size: ${(props) => props.labelFontSize}px;
      font-weight: 400;
      color: ${GREY};
    }
  }
`;

interface PopupBeamDetailRowFragmentProps {
  label: string;
  value?: string[];
  children?: any;
  elementIdBase: string;
}

/**
 * Generate PopupBeamDetailRowFragment
 * @param label Label on left-hand side
 * @param value Optional value string on right-hand side
 * @param children Optional children as value object on right-hand side
 * @param elementIdBase id base
 * @returns React Fragment Representing a Beam Detail Row
 */
const PopupBeamDetailRowFragment: React.FC<PopupBeamDetailRowFragmentProps> = ({
  label,
  value,
  children,
  elementIdBase
}) => {
  const labelLower = label.toLowerCase();
  return (
    <PopupDetailRow>
      <div className="detail-container" id={getElementIdFromSectionBase(elementIdBase, labelLower, 'container')}>
        <div className="detail-label" id={getElementIdFromSectionBase(elementIdBase, labelLower, 'label')}>
          {`${label}:`}
        </div>
        {value ? (
          <div className="detail-value" id={getElementIdFromSectionBase(elementIdBase, labelLower, 'value')}>
            {value.map((row, i) => (
              <React.Fragment key={getElementIdFromSectionBase(elementIdBase, labelLower, `row-${i}`)}>
                {row}
                <br />
              </React.Fragment>
            ))}
          </div>
        ) : null}
        {children ? children : null}
      </div>
    </PopupDetailRow>
  );
};

const PopupBeamDataFragment: React.FC<PopupBeamDataFragmentProps> = (props) => {
  const {beamDetails, elementIdBase} = props;

  return (
    <PopupBeamDataContainer
      titleFontSize={props.titleFontSize}
      labelFontSize={props.titleFontSize}
      valueFontSize={props.valueFontSize}
      outerPadding={props.outerPadding}
      labelWidth={props.labelWidth}
      dividerWidth={props.dividerWidth}
    >
      <PopupColumn>
        <div className="title-container">
          <PopupRow>
            <div className="title" id={getElementIdFromSectionBase(elementIdBase, 'title', 'label')}>
              {'Beam Detail'}
            </div>
            <div>
              <hr className="divider" />
            </div>
          </PopupRow>
        </div>

        {beamDetails && beamDetails.smts ? (
          <PopupBeamDetailRowFragment label={ESatBeamDetailsProps.SMTS} elementIdBase={elementIdBase}>
            <PopupColumn>
              {isArray(beamDetails.smts) && beamDetails.smts.length > 0
                ? beamDetails.smts.map((item, index) => {
                    return (
                      <div
                        className="detail-value"
                        id={getElementIdFromSectionBase(elementIdBase, `smts-${index}`, 'value')}
                        key={`smts-${index}`}
                      >
                        {item}
                      </div>
                    );
                  })
                : null}
            </PopupColumn>
          </PopupBeamDetailRowFragment>
        ) : null}

        {beamDetails && beamDetails.macd ? (
          <PopupBeamDetailRowFragment
            label={ESatBeamDetailsProps.MACD}
            value={isArray(beamDetails.macd) && beamDetails.macd}
            elementIdBase={elementIdBase}
          />
        ) : null}

        {beamDetails && beamDetails.cd ? (
          <PopupBeamDetailRowFragment
            label={ESatBeamDetailsProps.CD}
            value={isArray(beamDetails.cd) && beamDetails.cd}
            elementIdBase={elementIdBase}
          />
        ) : null}

        {beamDetails && beamDetails.smac ? (
          <PopupBeamDetailRowFragment
            label={ESatBeamDetailsProps.SMAC}
            value={isArray(beamDetails.smac) && beamDetails.smac}
            elementIdBase={elementIdBase}
          />
        ) : null}

        {beamDetails && beamDetails.networkRegionCode ? (
          <PopupBeamDetailRowFragment
            label={ESatBeamDetailsProps.NETWORK_REGION}
            value={isArray(beamDetails.networkRegionCode) && beamDetails.networkRegionCode}
            elementIdBase={elementIdBase}
          />
        ) : null}
        {beamDetails && beamDetails.mapBeam ? (
          <PopupBeamDetailRowFragment
            label={ESatBeamDetailsProps.MAP_BEAM}
            value={isArray(beamDetails.mapBeam) && beamDetails.mapBeam}
            elementIdBase={elementIdBase}
          />
        ) : null}
        {beamDetails && beamDetails.commonHub ? (
          <PopupBeamDetailRowFragment
            label={ESatBeamDetailsProps.COMMON_HUB}
            value={isArray(beamDetails.commonHub) && beamDetails.commonHub}
            elementIdBase={elementIdBase}
          />
        ) : null}

        {beamDetails === undefined ? (
          <PopupDetailRow>
            <div className="detail-container" id={getElementIdFromSectionBase(elementIdBase, 'no-data', 'container')}>
              <div className="detail-no-data" id={getElementIdFromSectionBase(elementIdBase, 'no-data', 'label')}>
                {BEAM_DETAILS_NO_DATA}
              </div>
            </div>
          </PopupDetailRow>
        ) : null}
      </PopupColumn>
    </PopupBeamDataContainer>
  );
};

interface PopupNetworkIncidentRowProps {
  cause: string; //ESatBeamCauseType;
  impact: string; //ESatBeamImpactType;
  link: string;
  children?: any;
  elementIdBase: string;
}

const PopupNetworkEventRow = styled.div<{
  impact: string;
}>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
  margin: 0px 6px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.impact === ESatBeamImpactType.OUTAGE ? MAP_BEAMS_ROW_OUTAGE_COLOR : MAP_BEAMS_ROW_IMPAIRED_COLOR};
  color: ${MAP_POPUP_NETWORK_ACCENT_COLOR};
  padding: 8px;
  svg {
    margin-right: 3px;
  }
  a {
    text-decoration: none;
    color: ${MAP_POPUP_NETWORK_URL_COLOR};
  }
  .detail-container {
    display: grid;
    grid-template-columns: 1fr 10fr;
  }
`;

/**
 * Generate PopupNetworkIncidentFragment
 * @param cause Cause of impact
 * @param impact Impact determines color / icon
 * @param link Link to status page
 * @param children Optional children as value object on right-hand side
 * @param elementIdBase id base
 * @returns React Fragment Representing a Beam Detail Row
 */
const PopupNetworkIncidentRow: React.FC<PopupNetworkIncidentRowProps> = ({cause, impact, link, elementIdBase}) => {
  const labelLower = impact.toLowerCase();
  return (
    <PopupDetailRow key={elementIdBase}>
      <PopupNetworkEventRow impact={impact} id={getElementIdFromSectionBase(elementIdBase, labelLower, 'container')}>
        <div className="detail-container">
          <SatelliteIcon id={'IncidentSatelliteBeamIcon'} sx={{color: MAP_BEAMS_ICON_SECONDARY_COLOR, fontSize: 16}} />
          <div className="detail-value" id={getElementIdFromSectionBase(elementIdBase, labelLower, 'value')}>
            Network:{' '}
            <a className="detail-value-url" href={link} target="_blank" rel="noreferrer">
              {cause}
            </a>
          </div>
        </div>
      </PopupNetworkEventRow>
    </PopupDetailRow>
  );
};

interface PopupNetworkIncidentFragmentProps {
  incidents: SatelliteBeamIncident[];
  elementIdBase: string;
}

/**
 * Generate PopupNetworkIncidentFragment
 * @param incidents List of Network Incident Events
 * @param elementIdBase id base
 * @returns React Fragment Representing a Beam Detail Row
 */
const PopupNetworkIncidentFragment: React.FC<PopupNetworkIncidentFragmentProps> = ({incidents, elementIdBase}) => {
  return (
    <div>
      {incidents.map((item, index) => {
        return (
          <React.Fragment key={getElementIdFromSectionBase(elementIdBase, item.IMPACT, `row-${index}`)}>
            <PopupNetworkIncidentRow
              cause={item.CAUSE}
              impact={item.IMPACT}
              link={item.LINK}
              elementIdBase={`${elementIdBase}-incident-${index}`}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

const SelectedSatBeamPopup: React.FC<ISatBeamPopupProps> = ({isInternal, viewAsCustomer, satBeamData, close}) => {
  if ((!isInternal || viewAsCustomer) && satBeamData?.networkType === 'internal' && satBeamData?.satId === undefined)
    return <></>;

  const network = satBeamData.networkType;
  const networkType = network === 'KuBeams' || network === 'ku-connected' ? 'Ku' : 'Ka';
  const elementIdBase =
    network === 'KuBeams' || network === 'ku-connected'
      ? getElementIdSectionBase(MAP_ELID_SECTION, `selectedSatBeamPopup-${satBeamData.satelliteName}`)
      : getElementIdSectionBase(
          MAP_ELID_SECTION,
          `selectedSatBeamPopup-${satBeamData.abbreviation}-${satBeamData.beamId}`
        );

  return (
    <PopupContainer
      id={getElementIdFromSectionBase(elementIdBase, 'popup', 'container')}
      // https://github.com/tomchentw/react-google-maps/issues/84
      ref={(ref) => ref && google.maps.OverlayView.preventMapHitsAndGesturesFrom(ref)}
      position={satBeamData.beamCenter}
      width={networkType === 'Ku' ? 250 : 180}
    >
      <PopupHeader font={14} borderRadius={'4px 4px 0px 0px'}>
        <PopupNetworkTypeFragment
          status="Online"
          networkType={networkType}
          elementIdBase={elementIdBase}
          iconSize={8}
          fontSize={10}
          height={10}
        />
        <div
          style={{
            height: 0,
            position: 'relative',
            cursor: 'pointer'
          }}
        >
          <PopupCloseButton
            id={getElementIdFromSectionBase(elementIdBase, 'close', 'button')}
            onClick={close}
            popupWidth={networkType === 'Ku' ? 250 : 180}
            iconSize={12}
            right={4}
            bottom={8}
          >
            <Close />
          </PopupCloseButton>
        </div>
        <PopupBeamInfoFragment
          primaryIdFontSize={14}
          secondaryIdFontSize={14}
          outerPadding={'14px 12px 10px'}
          satBeamData={satBeamData}
          elementIdBase={elementIdBase}
        />
      </PopupHeader>
      <PopupBody
        defaultFontSize={14}
        labelFontSize={12}
        valueFontSize={12}
        unitsFontSize={12}
        iconSize={12}
        outerPadding={undefined}
        innerPadding={'4px 2px'}
      >
        <PopupBeamDataFragment
          titleFontSize={12}
          labelFontSize={12}
          valueFontSize={12}
          outerPadding={'0px 12px 12px'}
          beamDetails={satBeamData.details}
          labelWidth={networkType === 'Ku' ? '220px' : '142px'}
          dividerWidth={networkType === 'Ku' ? '152px' : '80px'}
          elementIdBase={elementIdBase}
        ></PopupBeamDataFragment>
        {satBeamData.incidents && satBeamData.incidents.length > 0 ? (
          <PopupNetworkIncidentFragment incidents={satBeamData.incidents} elementIdBase={'network'} />
        ) : undefined}
      </PopupBody>
    </PopupContainer>
  );
};

export default SelectedSatBeamPopup;
