/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utility functions for use in chart components
 */

import moment from 'moment';
import iconRanking from '../../../../static/images/icon-ranking.png';
import hatchImg from '../../../../static/images/hatch-pattern.svg';
import hatchImgInternal from '../../../../static/images/hatch-pattern-internal.svg';
import {isNil} from 'lodash';

import {CHART_NO_DATA_TITLE_TEXT, CHART_NO_DATA_SUBTITLE_TEXT} from '../../theme/Colors';
import {
  NO_DATA_SUBTITLE_WIDTH_HIDDEN,
  NO_DATA_SUBTITLE_TEXT_HIDDEN,
  NO_DATA_GROUP_NAME,
  NO_DATA_IMAGE_WIDTH,
  NO_DATA_IMAGE_HEIGHT,
  NO_DATA_DEFAULT_OFFSETY,
  NO_DATA_TITLE_WIDTH,
  NO_DATA_SUBTITLE_WIDTH_NONE,
  NO_DATA_SUBTITLE_TEXT_NONE,
  NO_DATA_TITLE_LINE_HEIGHT,
  HATCH_OVERLAY_GROUP_NAME,
  HATCH_OVERLAY_VERTICAL_PAD,
  HATCH_OVERLAY_HORIZONTAL_PAD,
  HATCH_OVERLAY_WIDTH,
  CHART_NO_DATA_X_AXIS,
  CHART_NO_DATA_Y_AXIS,
  NO_DATA_TITLE_TEXT
} from '../../../../utils/constants';

/**
 * Interface for NoData Options for configuring charts
 *
 * @param enabled Enable check for rendering of NoData
 * @param offsetY (optional) vertical offset along Y-axis from top of chart to start render group:
 *  Adjust per chart to allow for whitespace (default=CHART_NO_DATA_DEFAULT_OFFSETY)
 * @param offsetX (optional) vertical offset along X-axis from top of chart to start render group:
 *  Adjust per chart to allow for whitespace (default=CHART_NO_DATA_DEFAULT_OFFSETY)
 * @param startX (optional) starting x-axis
 * @param showEmptyXAxis (optional) show chart X-axis when empty series data
 * @param showEmptyYAxis (optional) show chart Y-axis when empty series data
 */
export interface IChartOverlayOptions {
  enabled: boolean;
  internal?: boolean;
  offsetY?: number;
  offsetX?: number;
  startX?: number;
  showEmptyXAxis?: boolean;
  showEmptyYAxis?: boolean;
}

/**
 * Interface for NoData Render Options for Chart.SVGRenderer
 *
 * @param id Element Id
 * @param imageWidth Width of image
 * @param imageHeight Height of image
 * @param imageStartX Starting x position of image
 * @param imageStartY Starting y position of image
 * @param titleStartX Starting x position of title text
 * @param titleStartY Starting y position of title text
 * @param subtitleStartX Starting x position of subtitle text
 * @param subtitleStartY Starting y position of subtitle text
 * @param subtitleText Text to render for subtitle, dynamically changes based on series data
 * @param groupId SVG Element for created render group
 */
interface INoDataRenderOptions {
  id: string;
  groupId: string;
  imageWidth: number;
  imageHeight: number;
  imageStartX: number;
  imageStartY: number;
  titleStartX: number;
  titleStartY: number;
  subtitleStartX: number;
  subtitleStartY: number;
  subtitleText: string;
}

interface IHatchOverlayRenderOptions {
  id: string;
  internal: boolean;
  groupId: string;
  startX: number;
  startY: number;
  width: number;
  height: number;
}

export const renderHatchOverlay = (chart: Highcharts.Chart, id: string, hashOverlayOpts?: IChartOverlayOptions) => {
  // Only Render when enabled for chart

  // Configure Render Options for Image and Text to be centered on ChartCard

  const renderOpts = configureHatchOverlayRenderOptions(chart, id, hashOverlayOpts);

  // Prepare for Re-draw - Unique group element id var
  removeRenderGroup(renderOpts.groupId);
  renderHatchOverlayToDisplay(chart, renderOpts);
};

/**
 * No Data to Display Rendering in the following scenarios:
 * 1. 0 rows have been returned by the EventsTimeline look
 * 2. 1+ rows have been returned by the EventsTimeline look, but all legend items have been deselected
 *
 * @param Chart this instance of chart object:
 *  to query series data for rows/visibility and access SVG Renderer
 * @param id chart id to uniqely identify no data render group
 * @param noDataOpts NoData Options (refer to IChartOverlayOptions)
 */
export const renderCheckNoDataToDisplay = (
  chart: Highcharts.Chart,
  id: string,
  noDataOpts: IChartOverlayOptions,
  hasSeriesData: boolean
) => {
  // Only Render when enabled for chart
  if (noDataOpts === null || !noDataOpts?.enabled) return;

  // Configure Render Options for Image and Text to be centered on ChartCard
  const renderOpts = configureNoDataRenderOptions(chart, id, noDataOpts);

  // Prepare for Re-draw - Unique group element id var
  removeRenderGroup(renderOpts.groupId);

  // 1. Determine if valid series data
  // 2. Determine if legend series with valid series data are visible
  let haveSeriesData = true;
  let areLegendSeriesVisible = true;
  if (!hasSeriesData) {
    haveSeriesData = false;
  } else if (chart.series.length > 0) {
    haveSeriesData = false;
    areLegendSeriesVisible = false;
    chart.series.forEach((chartSeries) => {
      if (chartSeries.data.length > 0) {
        haveSeriesData = true;
      }
      areLegendSeriesVisible = areLegendSeriesVisible || (chartSeries.data.length > 0 && chartSeries.visible);
    });
  }

  // Set Appropriatesubtitle text appropriately if all legend series are hidden
  // Priority goes to No Series Data
  if (!haveSeriesData) {
    checkHideAxes(chart, noDataOpts);
    checkHideLegend(chart);
  } else if (!areLegendSeriesVisible) {
    renderOpts.subtitleStartX = chart.chartWidth / 2 - NO_DATA_SUBTITLE_WIDTH_HIDDEN / 2;
    renderOpts.subtitleText = NO_DATA_SUBTITLE_TEXT_HIDDEN;
  }

  // Render No Data Image + Title + SubTitle
  if (!haveSeriesData || !areLegendSeriesVisible) {
    renderNoDataToDisplay(chart, renderOpts);
  }
};

/**
 * Remove the Render Group to be used by Chart.SVGRenderer
 * Cleanup any existing Render group
 *
 * @param renderGroupName chart id to uniqely identify no data render group
 */
const removeRenderGroup = (renderGroupName: string) => {
  // Cleanup Render Group for redraw
  const element = document.getElementById(renderGroupName);
  if (element) {
    element.parentNode.removeChild(element);
  }
};

/**
 * Create the Render Group to be used by Chart.SVGRenderer
 *
 * @param Chart this instance of chart object:
 *  to query series data for rows/visibility and access SVG Renderer
 * @param renderGroupName chart id to uniqely identify no data render group
 *
 * @returns Highcharts.SVGElement - SVG Element of Render Group
 */
const createRenderGroup = (chart: Highcharts.Chart, renderGroupName: string): Highcharts.SVGElement => {
  // Setup Render Group
  const group = chart.renderer
    .g(renderGroupName)
    .attr({
      id: renderGroupName
    })
    .add();

  return group;
};

/**
 * Configure Render Options for Image, Title, & Subtitle to be used by Chart.SVGRenderer
 *
 * @param Chart this instance of chart object:
 *  to query series data for rows/visibility and access SVG Renderer
 * @param id chart id to uniqely identify no data render group
 * @param noDataOpts NoData Options (refer to IChartOverlayOptions)
 *
 * @returns INoDataRenderOptions - Render Options
 */
const configureNoDataRenderOptions = (
  chart: Highcharts.Chart,
  id: string,
  noDataOpts: IChartOverlayOptions
): INoDataRenderOptions => {
  // Configure Render Options for Image and Text to be centered on ChartCard
  const renderOpts = {
    id: id,
    groupId: id + NO_DATA_GROUP_NAME,
    imageWidth: NO_DATA_IMAGE_WIDTH,
    imageHeight: NO_DATA_IMAGE_HEIGHT,
    imageStartX: undefined,
    imageStartY: noDataOpts?.offsetY ? noDataOpts.offsetY : NO_DATA_DEFAULT_OFFSETY,
    titleStartX: chart.chartWidth / 2 - NO_DATA_TITLE_WIDTH / 2,
    titleStartY: undefined,
    subtitleStartX: chart.chartWidth / 2 - NO_DATA_SUBTITLE_WIDTH_NONE / 2,
    subtitleStartY: undefined,
    subtitleText: NO_DATA_SUBTITLE_TEXT_NONE
  };
  renderOpts.imageStartX = chart.chartWidth / 2 - renderOpts.imageWidth / 2;
  renderOpts.titleStartY = renderOpts.imageStartY + renderOpts.imageHeight + NO_DATA_TITLE_LINE_HEIGHT;
  renderOpts.subtitleStartY = renderOpts.titleStartY + NO_DATA_TITLE_LINE_HEIGHT;
  return renderOpts;
};

const configureHatchOverlayRenderOptions = (
  chart: Highcharts.Chart,
  id: string,
  hashOverlayOpts: IChartOverlayOptions
): IHatchOverlayRenderOptions => {
  // Configure Render Options for Image and Text to be centered on ChartCard
  return {
    id,
    internal: hashOverlayOpts && hashOverlayOpts.internal ? Boolean(hashOverlayOpts.internal) : false,
    groupId: id + HATCH_OVERLAY_GROUP_NAME,
    startX: chart.plotLeft + chart.plotWidth - HATCH_OVERLAY_HORIZONTAL_PAD,
    startY: chart.plotTop - HATCH_OVERLAY_VERTICAL_PAD,
    width: HATCH_OVERLAY_WIDTH,
    height: chart.plotHeight + 2 * HATCH_OVERLAY_VERTICAL_PAD
  };
};

/**
 * Check showEmpty options and Hide Axes
 * 1. Check x-axis option and hide axis
 * 2. Check y-axis option and hide axis
 *
 * @param Chart this instance of chart object:
 *  to query series data for rows/visibility and access SVG Renderer
 * @param noDataOpts NoData Options (refer to IChartOverlayOptions)
 */
const checkHideAxes = (chart: Highcharts.Chart, noDataOpts: IChartOverlayOptions) => {
  checkHideAxis(chart, noDataOpts, CHART_NO_DATA_X_AXIS);
  checkHideAxis(chart, noDataOpts, CHART_NO_DATA_Y_AXIS);
};

/**
 * Check showEmpty options and Hide Axis
 *
 * @param Chart this instance of chart object:
 *  to query series data for rows/visibility and access SVG Renderer
 * @param noDataOpts NoData Options (refer to IChartOverlayOptions)
 * @param axisType Axis Type to check
 */
const checkHideAxis = (chart: Highcharts.Chart, noDataOpts: IChartOverlayOptions, axisType: string) => {
  if (checkShowEmptyAxis(noDataOpts, axisType) === false) {
    let chartAxisType = undefined;
    switch (axisType) {
      case CHART_NO_DATA_X_AXIS:
        chartAxisType = chart.xAxis;
        break;
      case CHART_NO_DATA_Y_AXIS:
        chartAxisType = chart.yAxis;
        break;
      default:
        // undefined so return
        return;
    }
    // Hide axis and title, do not redraw
    chartAxisType.forEach((type) => {
      type.update({visible: false, title: {text: null}}, false);
    });
  }
};

/**
 * Check and Hide Series Legend
 * 1. Set Series Legend hidden
 *
 * @param Chart this instance of chart object:
 *  to query series data for rows/visibility and access SVG Renderer
 */
const checkHideLegend = (chart: Highcharts.Chart) => {
  const legend = chart.legend;
  if (legend && legend.group && legend.group.hide) {
    legend.group.hide();
  }
  if (legend && legend.box && legend.box.hide) {
    legend.box.hide();
  }
};

/**
 * Render No Data to Display Image, Title, & Subtitle using Charts.SVGRenderer
 *
 * @param Chart this instance of chart object:
 *  to query series data for rows/visibility and access SVG Renderer
 * @param renderOpts NoData Render Options (refer to INoDataRenderOptions)
 */
const renderNoDataToDisplay = (chart: Highcharts.Chart, renderOpts: INoDataRenderOptions) => {
  // Unique group element id var
  const group = createRenderGroup(chart, renderOpts.groupId);
  // Image
  chart.renderer
    .image(iconRanking, renderOpts.imageStartX, renderOpts.imageStartY, renderOpts.imageWidth, renderOpts.imageHeight)
    .attr({
      zIndex: 10
    })
    .add(group);
  // Title
  chart.renderer
    .text(NO_DATA_TITLE_TEXT, renderOpts.titleStartX, renderOpts.titleStartY)
    .css({
      color: CHART_NO_DATA_TITLE_TEXT,
      fontSize: '14px',
      fontWeight: '400px',
      zIndex: 10
    })
    .add(group);
  // Subtitle
  chart.renderer
    .text(renderOpts.subtitleText, renderOpts.subtitleStartX, renderOpts.subtitleStartY)
    .css({
      color: CHART_NO_DATA_SUBTITLE_TEXT,
      fontSize: '12px',
      fontWeight: '400px',
      zIndex: 10
    })
    .add(group);
};

const renderHatchOverlayToDisplay = (chart: Highcharts.Chart, renderOpts: IHatchOverlayRenderOptions) => {
  // Image
  chart.renderer
    .image(
      renderOpts.internal ? hatchImgInternal : hatchImg,
      renderOpts.startX,
      renderOpts.startY,
      renderOpts.width,
      renderOpts.height
    )
    .attr({
      id: renderOpts.groupId,
      preserveAspectRatio: 'xMidYMid slice',
      zIndex: 10
    })
    .add();
};

/**
 * Check if series data has valid data (non-null, non-undefined values)
 * these data points are hidden on a chart. Thus considered to have No Data.
 *
 * @param series series data to check for valid data
 *
 * @returns True if series has valid data, false otherwise.
 */
export const checkSeriesHasData = (series: any): boolean => {
  let hasData = false;
  series.forEach((item) => {
    const data = item.yData ? item.yData : item.data;
    hasData = hasData || checkDataIsValid(data);
  });
  return hasData;
};

/**
 * Check if data points have valid data (non-null, non-undefined values)
 * these data points are hidden on a chart. Thus considered to be invalid.
 *
 * @param data data to check for valid values
 *
 * @returns Boolean true if data is valid, false otherwise.
 */
const checkDataIsValid = (data: any[]): boolean => {
  return data.some((item) => !isNil(item));
};

/**
 * Check if NoData Options has showEmpty<axis>
 * @param noDataOpts NoData Options to query
 * @param axisType Specify Axis to check (CHART_NO_DATA_X_AXIS, CHART_NO_DATA_Y_AXIS)
 *
 * @returns Boolean or undefined - Indicating whether to showEmpty axis (default= undefined)
 */
export const checkShowEmptyAxis = (noDataOpts: IChartOverlayOptions, axisType: string): boolean => {
  let showEmpty = undefined;
  switch (axisType) {
    case CHART_NO_DATA_X_AXIS:
      if (noDataOpts?.showEmptyXAxis !== undefined) showEmpty = noDataOpts.showEmptyXAxis;
      break;
    case CHART_NO_DATA_Y_AXIS:
      if (noDataOpts?.showEmptyYAxis !== undefined) showEmpty = noDataOpts.showEmptyYAxis;
      break;
    default:
      break;
  }
  return showEmpty;
};

export const getLiveMask = (start: string, end: string, currentTime?: string) => {
  const nowStr = currentTime ? currentTime : moment.utc().format('YYYY-MM-DD HH:mm:ss');
  const totalChartMinutes = moment(end).diff(start, 'minutes');
  const elapsedChartMinutes = moment(nowStr).diff(start, 'minutes');
  const enabledLiveMask = elapsedChartMinutes < totalChartMinutes;
  return {
    totalChartMinutes,
    elapsedChartMinutes,
    enabledLiveMask
  };
};

export const getOpenMenuOffset = (width: number) => (width - 151) / 62;
