/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ChartWrapper Container for Combined Usage
 */

import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import {LEGEND_TEXT, CHART_AXIS_MINOR_TICK, DOWNLOAD_UPLOAD_USAGE_CHART_PALETTE} from '../../common/theme/Colors';
import {
  DATE_CONCISE_FORMAT,
  TIME_CONCISE_NO_PAD_FORMAT,
  stringAsMs,
  msAsString,
  areMsTimestampMinutesEqualTo
} from '../../../utils/DateTimeUtils';

import {ChartType} from '../../common/elements/chart/types';
import {ChartTimeSettings, adjustCategorizedChartData, getXAxisTickPositions} from '../flightDetailsUtil';

import ToolTipDateTimeLabel from '../../common/elements/chart/ToolTip/ToolTipDateTimeLabel';

import DownloadUploadCardToolTip from './tooltip/DownloadUploadCardToolTip';
import {DATA_USAGE_UNITS_MB} from '../../../utils/constants';
import {useIntl} from 'react-intl';
import {formatConfig} from '../../common/elements/chart/chartUtils';
import LineChart from '../../common/elements/chart/LineChart';

export interface ICombinedUsage {
  rl_usage: number;
  fl_usage: number;
  timestamp: string;
}
export type CombinedUsageList = ICombinedUsage[];

interface CombinedUsageProps {
  currentTime: string;
  isLoading: boolean;
  combinedUsage: CombinedUsageList;
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
}

const ChartContainer = styled.div`
  width: 100%;
  .highcharts-xaxis-grid path {
    stroke-width: 0;
  }
  .highcharts-crosshair,
  .highcharts-yaxis-grid,
  .highcharts-xaxis path {
    stroke-width: 1px;
    stroke: ${CHART_AXIS_MINOR_TICK};
  }
`;

const CombinedUsageChart: React.FC<CombinedUsageProps> = ({
  currentTime,
  isLoading,
  combinedUsage,
  hideAllLegendItemsOnLoad,
  legendItemsToHideOnLoad,
  chartTimeSettings,
  parentLeftOffset,
  liveMask
}: CombinedUsageProps) => {
  const [chartConfig, setChartConfig] = useState<any>(null);

  const intl = useIntl();

  useEffect(() => {
    if (!isLoading && chartTimeSettings.start && chartTimeSettings.end) {
      // General config
      const id = 'flightDetails--combinedUsage__timeSeries-chart';
      const highchartsIdBase = 'combinedUsage';
      const chartType = ChartType.LINE;
      const height = 215;
      const marginLeft = 75;
      const marginRight = 30;
      const defaultChartPalette = [];
      const palette = [];
      const seriesLabel = '';
      const connectNulls = false;
      const disableMarker = false;
      const combinedUsageData = combinedUsage ? combinedUsage : [];

      // X-axis
      let rawXAxisData = combinedUsageData.map((i) => stringAsMs(i.timestamp));
      const xAxisLabelPlotlines = [];
      const xAxisLabelXOffset = 0;
      const xAxisFormatter = (value: any) => {
        // Note: Minor ticks are not supported for category-based axes
        if (Number(value) < 1e12 || !areMsTimestampMinutesEqualTo(value, chartTimeSettings.labeledTickMinutesValues)) {
          return '';
        }

        const hourLabel = msAsString(value, TIME_CONCISE_NO_PAD_FORMAT);
        const dayLabel = msAsString(value, DATE_CONCISE_FORMAT);
        return hourLabel === '0:00' ? dayLabel : hourLabel;
      };

      // Y-axis
      const yAxis = [
        {
          label: `Download Usage (${DATA_USAGE_UNITS_MB})`,
          labelXOffset: -5,
          plotline: undefined,
          plotlineColor: undefined,
          tickInterval: undefined,
          tickPositions: undefined,
          titleOffset: 50,
          opposite: false
        },
        {
          label: `Upload Usage (${DATA_USAGE_UNITS_MB})`,
          labelXOffset: 0,
          plotline: undefined,
          plotlineColor: undefined,
          tickInterval: undefined,
          tickPositions: undefined,
          titleOffset: 22,
          opposite: true
        }
      ];

      const yAxisFormatter = (label: any) => label.value;

      // Series
      let dowloadUsage = combinedUsageData.map((row) => row.fl_usage);
      let uploadUsage = combinedUsageData.map((row) => row.rl_usage);
      let categorizedDownloadUsage = adjustCategorizedChartData(
        rawXAxisData,
        dowloadUsage,
        chartTimeSettings,
        5,
        chartTimeSettings.tickIntervalMinutes / 2
      );

      let categorizedUploadUsage = adjustCategorizedChartData(
        rawXAxisData,
        uploadUsage,
        chartTimeSettings,
        5,
        chartTimeSettings.tickIntervalMinutes / 2
      );

      const xAxisData = categorizedUploadUsage.xAxisData;

      const series = [
        {
          name: 'Download Usage (Left scale)',
          color: DOWNLOAD_UPLOAD_USAGE_CHART_PALETTE[0],
          marker: {
            symbol: 'circle'
          },
          data: categorizedDownloadUsage.yAxisData,
          yAxis: 0
        },
        {
          name: 'Upload Usage (Right scale)',
          color: DOWNLOAD_UPLOAD_USAGE_CHART_PALETTE[1],
          marker: {
            symbol: 'circle'
          },
          data: categorizedUploadUsage.yAxisData,
          yAxis: 1
        }
      ];

      // Set the x-axis tick mark positions based on the adjusted x-axis data
      const xAxisTickPositions = getXAxisTickPositions(xAxisData, chartTimeSettings);

      // Legend
      const legend = true;
      const legendItemStyle = {
        fontSize: '12px',
        fontWeight: '600',
        color: LEGEND_TEXT
      };

      // Tooltips
      const htmlTooltip = true;
      const sharedTooltip = true;
      const tooltip = (i18n: any) => (input: any) => {
        const {x, points} = input;
        return {
          label: {
            value: <ToolTipDateTimeLabel dateTime={x} />
          },
          points: points.map((pt) => {
            const {
              y,
              color,
              series: {name: seriesName}
            } = pt;
            return {
              value: <DownloadUploadCardToolTip prefixIconColor={color} rateType={seriesName} avgRate={y} />
            };
          }),
          color: false
        };
      };

      // Handle noData Options
      const noDataOptions = {
        enabled: true,
        showEmptyXAxis: false,
        showEmptyYAxis: false
      };

      setChartConfig({
        id,
        highchartsIdBase,
        chartType,
        height,
        marginLeft,
        marginRight,
        defaultChartPalette,
        palette,
        seriesLabel,
        connectNulls,
        disableMarker,
        xAxisData,
        xAxisLabelPlotlines,
        xAxisTickPositions,
        xAxisLabelXOffset,
        xAxisFormatter,
        yAxis,
        yAxisFormatter,
        series,
        legend,
        legendItemStyle,
        hideAllLegendItemsOnLoad,
        legendItemsToHideOnLoad,
        htmlTooltip,
        sharedTooltip,
        tooltip,
        liveMask,
        noDataOptions
      });
    }
    // eslint-disable-next-line
  }, [combinedUsage, chartTimeSettings]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer>
      {Object.keys(formattedConfig).length === 0 ? <></> : <LineChart {...formattedConfig} />}
    </ChartContainer>
  );
};

export default CombinedUsageChart;
