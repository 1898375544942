/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight Entry Query
 */
import {BYTES_PER_MEGABYTE, FORMAT_VALUE_USE_SEPARATOR} from '../../../utils/constants';
import {
  formatHMSecondsValue,
  formatValue,
  FORMAT_VALUE_DEFAULT_PRECISION,
  FORMAT_VALUE_DEFAULT_UNITS,
  msAsString
} from '../../../utils/DateTimeUtils';
import {Query} from '../types';

export interface FlightEntry {
  customer: string;
  aircraftId: string;
  flightId: string;
  serialNumber: string;
  tailId: string;
  aircraftManufacturer: string;
  isLabTerminal?: boolean;
  endUser?: string;
  aircraftType: string;
  networkCapability: string;
  flightStart: string;
  flightEnd: string;
  flightDuration: string;
  connectedStart: string;
  connectedEnd: string;
  connectedDuration: string;
  cruiseStart: string;
  cruiseEnd: string;
  dataUsage: string;
  startLatitude: number;
  startLongitude: number;
  lastLatitude: number;
  lastLongitude: number;
  lastHeading: number;
  lastNetwork: string;
  lastFlightPhase: string;
  last5MinutesConnectivity: number;
  flightDetected: boolean;
  pingsConnectedStart: string;
  pingsConnectedEnd: string;
  actualDepartureTimestamp?: string;
  filedDepartureTimestamp?: string;
  actualArrivalTimestamp?: string;
  estimatedArrivalTimestamp?: string;
  route?: string;
  origin?: string;
  destination?: string;
  faStatus?: string;
  isDark: boolean;
  faFlightDurationSeconds?: string;
  faFlightId?: string;
  kaTerminalSwVersion?: string;
  kuTerminalSwVersion?: string;
}

export interface FlightEntryRaw {
  customer: string;
  aircraftId: string;
  flightId: string;
  serialNumber: string;
  tailId: string;
  aircraftManufacturer: string;
  isLabTerminal?: boolean;
  endUser?: string;
  aircraftType: string;
  networkCapability: string;
  flightStartTimestamp: number;
  flightEndTimestamp: number;
  flightDurationSeconds: number;
  connectedStartTimestamp: number;
  connectedEndTimestamp: number;
  connectedDurationSeconds: number;
  cruiseStartTimestamp: number;
  cruiseEndTimestamp: number;
  dataUsageBytes: number;
  startLatitude: number;
  startLongitude: number;
  lastLatitude: number;
  lastLongitude: number;
  lastHeading: number;
  lastNetwork: string;
  lastFlightPhase: string;
  last5MinutesConnectivity: number;
  flightDetected: boolean;
  pingsConnectionStartTimestamp: number;
  pingsConnectionEndTimestamp: number;
  actualDepartureTimestamp?: number;
  filedDepartureTimestamp?: number;
  actualArrivalTimestamp?: number;
  estimatedArrivalTimestamp?: number;
  route?: string;
  origin?: string;
  destination?: string;
  faStatus?: string;
  isDark?: boolean;
  faFlightDurationSeconds?: number;
  faFlightId?: string;
  kaTerminalSwVersion?: string;
  kuTerminalSwVersion?: string;
}

/**
 * Transforms the given raw flight entry data into (regular) flight entry data
 * @param flightEntryRaw flightEntryRaw values from the api
 * @returns flightEntry Transformed flight entry
 */
const transformRawFlightEntry = (flightEntryRaw: FlightEntryRaw | null): FlightEntry | null => {
  if (!flightEntryRaw) return null;

  const flightEntry: FlightEntry = {
    customer: flightEntryRaw.customer,
    aircraftId: flightEntryRaw.aircraftId,
    flightId: flightEntryRaw.flightId,
    serialNumber: flightEntryRaw.serialNumber,
    tailId: flightEntryRaw.tailId,
    aircraftManufacturer: flightEntryRaw.aircraftManufacturer,
    isLabTerminal: flightEntryRaw?.isLabTerminal,
    endUser: flightEntryRaw?.endUser,
    aircraftType: flightEntryRaw.aircraftType,
    networkCapability: flightEntryRaw.networkCapability,
    flightStart: flightEntryRaw.flightStartTimestamp ? msAsString(flightEntryRaw.flightStartTimestamp) : null,
    flightEnd: flightEntryRaw.flightEndTimestamp ? msAsString(flightEntryRaw.flightEndTimestamp) : null,
    flightDuration: formatHMSecondsValue(flightEntryRaw.flightDurationSeconds),
    connectedStart: flightEntryRaw.connectedStartTimestamp ? msAsString(flightEntryRaw.connectedStartTimestamp) : null,
    connectedEnd: flightEntryRaw.connectedEndTimestamp ? msAsString(flightEntryRaw.connectedEndTimestamp) : null,
    connectedDuration: formatHMSecondsValue(flightEntryRaw.connectedDurationSeconds),
    cruiseStart: flightEntryRaw.cruiseStartTimestamp ? msAsString(flightEntryRaw.cruiseStartTimestamp) : null,
    cruiseEnd: flightEntryRaw.cruiseEndTimestamp ? msAsString(flightEntryRaw.cruiseEndTimestamp) : null,
    dataUsage: flightEntryRaw.dataUsageBytes
      ? formatValue(
          Math.floor(flightEntryRaw.dataUsageBytes / BYTES_PER_MEGABYTE),
          FORMAT_VALUE_DEFAULT_PRECISION,
          FORMAT_VALUE_DEFAULT_UNITS,
          FORMAT_VALUE_USE_SEPARATOR
        )
      : null,
    startLatitude: flightEntryRaw?.startLatitude,
    startLongitude: flightEntryRaw?.startLongitude,
    lastLatitude: flightEntryRaw?.lastLatitude,
    lastLongitude: flightEntryRaw?.lastLongitude,
    lastHeading: flightEntryRaw?.lastHeading,
    lastNetwork: flightEntryRaw?.lastNetwork,
    lastFlightPhase: flightEntryRaw?.lastFlightPhase,
    last5MinutesConnectivity: flightEntryRaw?.last5MinutesConnectivity,
    flightDetected: flightEntryRaw?.flightDetected,
    pingsConnectedStart: flightEntryRaw.pingsConnectionStartTimestamp
      ? msAsString(flightEntryRaw.pingsConnectionStartTimestamp)
      : null,
    pingsConnectedEnd: flightEntryRaw.pingsConnectionEndTimestamp
      ? msAsString(flightEntryRaw.pingsConnectionEndTimestamp)
      : null,
    origin: flightEntryRaw.origin ? flightEntryRaw.origin : null,
    destination: flightEntryRaw.destination ? flightEntryRaw.destination : null,
    isDark: flightEntryRaw.isDark ? flightEntryRaw.isDark : false,
    actualDepartureTimestamp: flightEntryRaw.actualDepartureTimestamp
      ? msAsString(flightEntryRaw.actualDepartureTimestamp)
      : null,
    filedDepartureTimestamp: flightEntryRaw.filedDepartureTimestamp
      ? msAsString(flightEntryRaw.filedDepartureTimestamp)
      : null,
    actualArrivalTimestamp: flightEntryRaw.actualArrivalTimestamp
      ? msAsString(flightEntryRaw.actualArrivalTimestamp)
      : null,
    estimatedArrivalTimestamp: flightEntryRaw.estimatedArrivalTimestamp
      ? msAsString(flightEntryRaw.estimatedArrivalTimestamp)
      : null,
    route: flightEntryRaw.route ? flightEntryRaw.route : null,
    faStatus: flightEntryRaw.faStatus ? flightEntryRaw.faStatus : null,
    faFlightDurationSeconds: flightEntryRaw.faFlightDurationSeconds
      ? formatHMSecondsValue(flightEntryRaw.faFlightDurationSeconds)
      : null,
    faFlightId: flightEntryRaw.faFlightId ? flightEntryRaw.faFlightId : null,
    kaTerminalSwVersion: flightEntryRaw.kaTerminalSwVersion ? flightEntryRaw.kaTerminalSwVersion : null,
    kuTerminalSwVersion: flightEntryRaw.kuTerminalSwVersion ? flightEntryRaw.kuTerminalSwVersion : null
  };

  return flightEntry;
};

export const useFlightEntryQuery: Query<FlightEntry> = {
  route: `flightDetails/flightEntry`,
  transform: transformRawFlightEntry
};

export default useFlightEntryQuery;
