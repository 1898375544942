/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utilities for working with dates and times
 */

import moment from 'moment';
import {isNil} from 'lodash';

// Default Locale is undefined, uses host default language
export const FORMAT_VALUE_DEFAULT_LOCALE = undefined;
export const FORMAT_VALUE_NULL_REPRESENTATION = '--';
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const TIME_WITH_SECONDS_FORMAT = 'HH:mm:ss';
export const TIME_WITH_HR_MIN_FORMAT = 'HH:mm';
// Default Separator is false, which does not display thousands separator
export const FORMAT_VALUE_DEFAULT_NO_SEPARATOR = false;
// Default PadStart is false, which does not display leading zeroes
export const FORMAT_VALUE_DEFAULT_NO_PAD_START_ZEROES = 0;
// Default Precision is 0
export const FORMAT_VALUE_DEFAULT_PRECISION = 0;
// Default Units is null, represents no units or empty string
export const FORMAT_VALUE_DEFAULT_UNITS = null;

export const DATE_CONCISE_FORMAT = 'D MMM';

export const TIME_CONCISE_NO_PAD_FORMAT = 'H:mm';

export const TIME_WITH_MINUTES_FORMAT = 'HH:mm';

export const DATE_FULL_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_LOCAL_SHORTENED_FORMAT = 'YYYYMMDDHHmm';
export const DATE_TIME_LOCAL_FORMAT = `${DATE_FULL_FORMAT} ${TIME_WITH_SECONDS_FORMAT}`;

export const DATE_VERBOSE_FULL_FORMAT = 'YYYY-MMM-DD';

export const DATE_TIME_VERBOSE_LOCAL_FORMAT = `${DATE_VERBOSE_FULL_FORMAT} ${TIME_WITH_SECONDS_FORMAT}`;
export const DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT = `${DATE_VERBOSE_FULL_FORMAT} ${TIME_WITH_MINUTES_FORMAT}`;

export const DATE_TIME_WITH_SECONDS_FORMAT = `${DATE_FULL_FORMAT} ${TIME_WITH_SECONDS_FORMAT}`;

export const MS_IN_MIN = 60 * 1000;

/** Offset from now in milliseconds to use as the maximum selectable time */
const OFFSET_FOR_MAX_DATE_IN_MILLISECONDS: number = 20 * 60 * 1000; // 20 minutes ago

export const TIME_WITH_SECONDS_NO_PAD_FORMAT = 'H:mm:ss';

export const DATE_MONTH_NAME_AND_DAY_FORMAT = 'D MMMM';

export const DATE_MONTH_NAME_AND_TIME = 'D MMM HH:mm';

export const DATE_MONTH_NAME_AND_TIME_COMMA_SEPARATED = 'D MMM, HH:mm';

export const MONTH_NAME_DATE_AND_DAY_FORMAT = 'MMM D';

export const DATE_TIME_LOCAL_FORMAT_MINUTES = `${DATE_FULL_FORMAT} ${TIME_WITH_MINUTES_FORMAT}`;

export const DATE_FORMAT = 'DD MMM, YYYY';

export const DATE_FORMAT_MM_DD_YYYY_HH_MM = 'MM-DD-YYYY HH:mm';

export const DATE_TIME_FORMAT_WITH_HR_MINS = 'YYYY-MM-DD HH:mm';

export const DATE_TIME_FORMAT_WITH_MILLISECONDS = 'YYYY-MM-DD HH:mm:ss.SSSS';

export const DATE_TIME_VERBOSE_FORMAT = `${DATE_VERBOSE_FULL_FORMAT} ${TIME_WITH_MINUTES_FORMAT}`;

/**
 * Returns the start of the day (as a string) for the given timestamp
 * @param date moment.MomentInput
 * @returns string representing the start of day
 */
export const startOfDayAsString = (date?: moment.MomentInput) =>
  moment.utc(date).startOf('day').format(DATE_TIME_LOCAL_FORMAT);

/**
 * Returns the start of the month (as a string) for the given timestamp
 * @param date moment.MomentInput
 * @returns string representing the start of month
 */
export const startOfMonthAsString = (date?: moment.MomentInput) =>
  moment.utc(date).startOf('month').format(DATE_TIME_LOCAL_FORMAT);

/**
 * Value formatter, defaults to `--` for null, NaN, or non-Finite values
 * ie. '98.7%', '0.654 MB', '3,210', '12', or '--'
 *
 * @param value Number to format
 * @param precision Number of digits after decimal point [0..20] (optional, default=0)
 * @param units Units for value, include spacing if appropriate ie. '%' or ' ms' (optional, default=null)
 * @param separator Use separator locale for thousands (optional, default=false)
 * @param padStartZeroes Number of leading zeroes to pad (optional, default=0)
 * @return Formatted value as a string
 */
export const formatValue = (
  value: number,
  precision: number = FORMAT_VALUE_DEFAULT_PRECISION,
  units: string = FORMAT_VALUE_DEFAULT_UNITS,
  separator: boolean = FORMAT_VALUE_DEFAULT_NO_SEPARATOR,
  padStartZeroes: number = FORMAT_VALUE_DEFAULT_NO_PAD_START_ZEROES
): string => {
  // Handle null, NaN, or !Finite values as '--'
  if (value === undefined || value === null || isNaN(value) || !isFinite(value))
    return FORMAT_VALUE_NULL_REPRESENTATION;

  // Validate precision [0..20]
  if (precision < 0) precision = 0;
  else if (precision > 20) precision = 20;

  // Format value with separator if specified and/or precision specified as a string
  // Locale options precision specifies the number of digits after decimal point
  const localeOptions = {minimumFractionDigits: precision, maximumFractionDigits: precision};
  let formattedValue = separator
    ? value.toLocaleString(FORMAT_VALUE_DEFAULT_LOCALE, localeOptions)
    : value.toFixed(precision);

  // Format value with padding leading zeroes
  formattedValue = padStartZeroes > 0 ? formattedValue.padStart(padStartZeroes, '0') : formattedValue;

  // Format value with units if specified
  const formattedUnits = units !== null ? units : '';

  return `${formattedValue}${formattedUnits}`;
};

/**
 * Formats an hours and minutes duration value
 * @param hours Hours
 * @param minutes Minutes
 * @param separator Use separator locale for thousands (optional, default=false)
 * @param minsPadStartZeroes Number of leading zeroes to pad Minutes (optional, default=0)
 * @returns Formatted hours and minutes duration value
 */
export const formatHMDurationValue = (
  hours: number,
  minutes: number,
  separator: boolean = FORMAT_VALUE_DEFAULT_NO_SEPARATOR,
  minsPadStartZeroes: number = FORMAT_VALUE_DEFAULT_NO_PAD_START_ZEROES
): string => {
  const hoursFormat = hours > 0 ? formatValue(hours, FORMAT_VALUE_DEFAULT_PRECISION, 'h ', separator) : '';
  const minsFormat = formatValue(
    minutes,
    FORMAT_VALUE_DEFAULT_PRECISION,
    'm',
    FORMAT_VALUE_DEFAULT_NO_SEPARATOR,
    minsPadStartZeroes
  );
  return `${hoursFormat}${minsFormat}`;
};

/**
 * Duration value formatter
 * ie. '1h 1m', or '--'
 *
 * @param value Duration in mins
 * @param separator Use separator locale for thousands (optional, default=false)
 * @param minsPadStartZeroes Number of leading zeroes to pad Minutes (optional, default=0)
 * @return Formatted duration value in the duration format
 */
export const formatDurationValue = (
  value: number,
  separator: boolean = FORMAT_VALUE_DEFAULT_NO_SEPARATOR,
  minsPadStartZeroes: number = FORMAT_VALUE_DEFAULT_NO_PAD_START_ZEROES
): string => {
  if (isNil(value) || isNaN(value)) {
    return FORMAT_VALUE_NULL_REPRESENTATION;
  }

  const roundedValue = Math.round(value);

  return formatHMDurationValue(Math.floor(roundedValue / 60), roundedValue % 60, separator, minsPadStartZeroes);
};

/**
 * Formats seconds to duration value
 * @param seconds Seconds
 * @param separator Use separator locale for thousands (optional, default=false)
 * @returns Formatted hours and minutes duration value
 */
export const formatHMSecondsValue = (seconds: number, separator: boolean = false): string => {
  const SECONDS_PER_MINUTE = 60;
  return seconds ? formatDurationValue(seconds / SECONDS_PER_MINUTE, separator) : null;
};

/**
 * Duration value formatter
 * ie. '1h 1m', or '--'
 *
 * @param start start date to calculate duration
 * @param end end date to calculate duration
 * @param separator Use separator locale for thousands (optional, default=false)
 * @return Formatted duration value in the duration format
 */
export const formatStartEndDurationValue = (
  start: string | moment.MomentInput,
  end: string | moment.MomentInput,
  separator: boolean = FORMAT_VALUE_DEFAULT_NO_SEPARATOR
): string => {
  if (typeof start === 'undefined' || typeof end === 'undefined') {
    return FORMAT_VALUE_NULL_REPRESENTATION;
  }
  const durationHr = moment(end).diff(start, 'hours');
  const durationMin = moment(end).diff(start, 'minutes') - durationHr * SECONDS_PER_MINUTE;
  return formatHMDurationValue(durationHr, durationMin, separator);
};

/**
 * Formats a date object into a UTC time with seconds string
 * @param date moment.MomentInput object
 * @returns Formatted time with seconds string in UTC
 */
export const formatMomentInputAsTimeWithSeconds = (date: moment.MomentInput) => {
  return formatMomentInput(date, TIME_WITH_SECONDS_FORMAT);
};

/**
 * Formats a date object into a UTC time with only hours and minutes string
 * "2021-04-08 16:51:00.000 +0000"  to "16:51"
 * @param date moment.MomentInput object
 * @returns Formatted time with only hours and minutes string in UTC
 */
export const formatMomentInputAsTimeWithHrMin = (date: moment.MomentInput) => {
  return formatMomentInput(date, TIME_WITH_HR_MIN_FORMAT);
};

/**
 * Builds a moment object from the given date/time string
 * @param localDate date/time string to be converted
 * @returns the moment object
 */
export const toLocalDate = (localDate: string, format: string = DATE_TIME_LOCAL_FORMAT) =>
  moment.utc(localDate, format);

/**
 * Converts the given date/time string to a timestamp in milliseconds
 * @param localDate date/time string to be converted
 * @returns the timestamp in milliseconds
 */
export const stringAsMs = (localDate: string, format: string = DATE_TIME_LOCAL_FORMAT) => {
  const asMoment = moment.utc(localDate, format);
  return Number(asMoment.format('x'));
};

/**
 * Formats a date object into a UTC string
 * @param date moment.MomentInput object
 * @param dateFormat string
 * @returns Formatted date string in UTC
 */
export const formatMomentInput = (date: moment.MomentInput, dateFormat: string) => {
  return date ? moment.utc(date).format(dateFormat) : null;
};

/**
 * Builds a moment object from the given timestamp in milliseconds
 * @param ms timestamp in milliseconds
 * @returns the moment object
 */
export const msAsMoment = (ms: number) => moment.utc(ms);

/**
 * Converts the given timestamp to a date/time string
 * @param ms the timestamp in milliseconds
 * @returns the converted date/time string
 */
export const msAsString = (ms: number, format: string = DATE_TIME_LOCAL_FORMAT) => {
  return msAsMoment(ms).format(format);
};

/**
 * Formats a date object into a UTC time with seconds with no padding string
 * @param date moment.MomentInput object
 * @returns Formatted time with seconds with no padding string in UTC
 */
export const formatMomentInputAsTimeWithSecondsNoPad = (date: moment.MomentInput) => {
  return formatMomentInput(date, TIME_WITH_SECONDS_NO_PAD_FORMAT);
};

/**
 * Formats a date object into a UTC local date string
 * @param date moment.MomentInput object
 * @returns Formatted local date string in UTC
 */
export const formatMomentInputAsLocal = (date: moment.MomentInput) => {
  return formatMomentInput(date, DATE_TIME_LOCAL_FORMAT);
};

/**
 * Formats a date object into a UTC concise date string
 * @param date moment.MomentInput object
 * @returns Formatted concise date string in UTC
 */
export const formatMomentInputAsDateConcise = (date: moment.MomentInput) => {
  return formatMomentInput(date, DATE_CONCISE_FORMAT);
};

/**
 * Formats a date object into a UTC full date string
 * @param date moment.MomentInput object
 * @returns Formatted full date string in UTC
 */
export const formatMomentInputAsDateFull = (date: moment.MomentInput) => {
  return formatMomentInput(date, DATE_FULL_FORMAT);
};

/**
 * Shortened local date formatter
 * @param date - date as a string
 * @returns date string YYMMDDHHmm
 */

/**
 * Takes in a date string and returns the equivalent shortened date format
 * @param dateString The date as a string
 * @returns The formatted date string
 */
export const getShortenedLocalDateFormat = (dateString: string) => {
  if (!dateString) {
    return '---';
  }
  return moment.utc(dateString).format(DATE_TIME_LOCAL_SHORTENED_FORMAT);
};

/**
 * Returns whether the minutes value for the given milliseconds timestamp is equal to the given value(s)
 * @param msTimestamp timestamp in milliseconds
 * @param minutesValues single minutes value or an array of minutes values to compare against
 * @returns true if the minutes value matches, false otherwise
 */
export const areMsTimestampMinutesEqualTo = (msTimestamp: number, minutesValues: number | number[]) => {
  const timestampMinutes = msAsMoment(msTimestamp).minutes();
  return Array.isArray(minutesValues) ? minutesValues.includes(timestampMinutes) : timestampMinutes === minutesValues;
};

/**
 * Formats String to Date object
 * @param dateAsString date as String
 * @returns moment.Moment object
 */
export const formatToMoment = (dateAsString: string): moment.Moment => {
  const formattedMoment = moment.utc(dateAsString, 'YYYY-MM-DD hh:mm');
  return formattedMoment.isValid() ? formattedMoment : null;
};

/**
 * Gets the maximum selectable time in milliseconds,
 * by doing NOW - OFFSET_FOR_MAX_DATE_IN_MILLISECONDS
 *
 * @returns number Maximum selectable time in milliseconds
 */
const getMaxMillis = (): moment.Moment => moment.utc().subtract(OFFSET_FOR_MAX_DATE_IN_MILLISECONDS, 'milliseconds');

/**
 * Gets the maximum selectable time as a Moment object,
 * by doing NOW - OFFSET_FOR_MAX_DATE_IN_MILLISECONDS
 *
 * @returns Moment Maximum selectable time in a Moment object
 */
export const getMaxMoment = (): moment.Moment => moment.utc(getMaxMillis());

/**
 * Gets the default start date for the date picker
 *
 * @returns start date string
 */
export const getDefaultStartDate = () => startOfDayAsString(getMaxMillis());

/**
 * Gets the default end date for the date picker
 *
 * @returns end date string
 */
export const getDefaultEndDate = () => msAsString(getMaxMillis().valueOf());

/**
 * Takes in an array of values that either can be converted to a Moment or are undefined, and returns an array of
 * timestamps for those items that were valid.
 * @param inputs Array of values to be filtered and converted
 * @returns Array of timestamps
 */
export const getValidTimestamps = (inputs: (string | number | moment.Moment | undefined)[]): number[] => {
  return inputs
    .filter((input) => Boolean(input))
    .map((input) => {
      return moment.utc(input).valueOf();
    });
};

/**
 * Takes in an array of values that either can be converted to a Moment or are undefined, and returns the timestamp of
 * the minimum (earliest) value
 * @param inputs Array of values from which to find the minimum
 * @returns Timestamp of the minimum value or undefined if there were no valid values
 */
export const getMinTimestamp = (...inputs: (string | number | moment.Moment | undefined)[]): number | undefined => {
  const validTimestamps = getValidTimestamps(inputs);
  return validTimestamps.length > 0 ? Math.min(...validTimestamps) : undefined;
};

/**
 * Takes in an array of values that either can be converted to a Moment or are undefined, and returns the timestamp of
 * the maximum (latest) value
 * @param inputs Array of values from which to find the minimum
 * @returns Timestamp of the maximum value or undefined if there were no valid values
 */
export const getMaxTimestamp = (...inputs: (string | number | moment.Moment | undefined)[]): number | undefined => {
  const validTimestamps = getValidTimestamps(inputs);
  return validTimestamps.length > 0 ? Math.max(...validTimestamps) : undefined;
};
