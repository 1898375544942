/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common Styles
 */

import styled from '@emotion/styled';


export const AlertContainer = styled.div<{width: number, bgColor?: string}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100vw - ${props => props.width}px);
  height: 56px;
  background: ${(props) => props.bgColor};
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0 2px 16px;
  padding: 0 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const AlertMessage = styled.span`
  padding: 15px;
  display: flex;
  .darkFlight {
    font-weight: 700;
  }
  .decommissionedBanner {
    margin-left: 8px;
  }
`;
