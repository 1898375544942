/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Percent Area Chart
 */
import React from 'react';
import Highcharts from 'highcharts';
import ReactDOMServer from 'react-dom/server';
import styled from '@emotion/styled';
import {isEqual} from 'lodash';

import {checkSeriesHasData, checkShowEmptyAxis, renderCheckNoDataToDisplay, renderHatchOverlay} from './RenderOverlay';
import {CHART_NO_DATA_X_AXIS, CHART_NO_DATA_Y_AXIS} from '../../../../utils/constants';
import {ChartType, IPercentChart, PlotLineType} from './types';
import {AXIS_TEXT, CHART_TOOLTIP_BG, CHART_TOOLTIP_BORDER, CHART_TOOLTIP_TEXT} from '../../theme/Colors';
import {getHighchartsId, setLegendItemsVisibility} from './chartUtils';
import ToolTip from './ToolTip/ToolTip';

export const PercentAreaChartContainer = styled.div`
  height: 100%;
  .highcharts-data-label tspan,
  .highcharts-plot-line-label tspan {
    white-space: nowrap;
    stroke: none;
  }
  .highcharts-data-label .highcharts-text-outline,
  .highcharts-plot-line-label .highcharts-text-outline {
    stroke: none;
    fill: none;
  }
  .column-legend-entry {
    color: ${AXIS_TEXT};
    font-family: 'Source Sans Pro';
    font-size: 14px;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 400;
    margin-top: -2px;
  }
  .highcharts-markers path.highcharts-halo {
    display: none;
  }
  .highcharts-markers path.highcharts-halo + path {
    stroke: white;
    stroke-width: 1px;
  }
  svg text,
  svg tspan {
    font-family: 'Source Sans Pro', sans-serif;
  }
  .highcharts-line-series:not(.highcharts-legend-item) {
    .highcharts-point:not(.highcharts-point-hover) {
      fill: none;
    }
  }
  .highcharts-legend {
    .highcharts-graph {
      stroke: none;
    }
  }
  .highcharts-xaxis-grid path {
    stroke-width: 1px;
    fill: transparent;
    stroke-opacity: 0;
  }
`;

class PercentAreaChart extends React.Component<IPercentChart, any> {
  ref: any = null;
  config = {};

  componentDidMount() {
    const {id, config} = this.generateChart(this.props);
    this.ref = Highcharts.chart(id, config);
    this.config = config.series;
  }

  render() {
    const {id} = this.props;
    const {config} = this.generateChart(this.props);

    if (!isEqual(this.config, config.series) && this.ref) {
      this.config = config.series;
      Highcharts.chart(id, config).update(config);
    }

    return (
      <PercentAreaChartContainer data-test="percent-area-chart" className="chart-container" id={id} />
    );
  }

  private generateChart = (props: IPercentChart) => {
    const {
      id,
      highchartsIdBase,
      height,
      defaultChartPalette,
      connectNulls,
      disableMarker,
      plotline,
      plotlineColor,
      plotlineType,
      xAxisTickPositions,
      xAxisTickInterval,
      xAxisLabelXOffset,
      xAxisData,
      xAxisFormatter,
      xAxisLabel,
      yAxis,
      yAxisFormatter,
      series,
      seriesFormatter,
      legend,
      legendItemStyle,
      hideAllLegendItemsOnLoad,
      legendItemsToHideOnLoad,
      htmlTooltip,
      sharedTooltip,
      tooltip,
      liveMask,
      noDataOptions,
      marginLeft,
      marginRight,
      spacingLeft,
      spacingRight
    } = props;

    const plotSeriesLabelStyle = {
      style: {
        fontWeight: '400',
        fontSize: '16px'
      }
    };
    const yAxisLabelFormat = {
      style: {
        fontSize: '12px',
        color: AXIS_TEXT,
        whiteSpace: 'nowrap',
        textOverflow: 'none'
      }
    };

    const plotlineConfig = (value, color) => ({
      value,
      color,
      width: 2,
      dashStyle: 'shortdash' as Highcharts.DashStyleValue,
      startOnTick: false,
      zIndex: 99
    });
    const chartConfig = {
      chart: {
        type: ChartType.AREA,
        height,
        marginLeft: marginLeft ? marginLeft : undefined,
        marginRight: marginRight ? marginRight : undefined,
        spacingLeft: spacingLeft ? spacingLeft : undefined,
        spacingRight: spacingRight ? spacingRight : undefined,
        spacingTop: 20,
        alignTicks: true,
        events: {
          load() {
            document.querySelectorAll('.highcharts-credits').forEach((el) => el.remove());
            setLegendItemsVisibility(this.series, hideAllLegendItemsOnLoad, legendItemsToHideOnLoad);
          },
          render() {
            const hasSeriesData = checkSeriesHasData(this.series);

            // Handle No Data to Display rendering
            renderCheckNoDataToDisplay(this, id, noDataOptions, hasSeriesData);

            if (liveMask && hasSeriesData) {
              renderHatchOverlay(this, id);
            }
          }
        }
      },
      title: {text: ''},
      legend: {enabled: legend, itemStyle: legendItemStyle ? legendItemStyle : undefined},
      exporting: {enabled: false},
      yAxis: yAxis.map(
        (axis) =>
          ({
            title: {
              text: axis.label,
              offset: axis.titleOffset,
              style: yAxisLabelFormat.style
            },
            plotLines: axis.plotline
              ? [plotlineConfig(axis.plotline, axis.plotlineColor) as Highcharts.YAxisPlotLinesOptions]
              : [],
            allowDecimals: false,
            tickPositions: axis.tickPositions,
            labels: yAxisFormatter
              ? {
                  formatter() {
                    return yAxisFormatter(this);
                  },
                  x: axis.labelXOffset,
                  ...yAxisLabelFormat
                }
              : {x: axis.labelXOffset, ...yAxisLabelFormat},

            gridLineWidth: plotlineType === PlotLineType.horizontal ? 1 : 0,
            tickInterval: axis.tickInterval,
            endOnTick: true,
            opposite: axis.opposite,
            tickPixelInterval: 20,
            showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_Y_AXIS),
            max: 100
          } as Highcharts.YAxisOptions)
      ),
      xAxis: {
        title: {
          text: xAxisLabel
        },
        plotLines:
          plotlineType === PlotLineType.vertical
            ? [plotlineConfig(plotline, plotlineColor) as Highcharts.XAxisPlotLinesOptions]
            : [],
        labels: {
          formatter: function () {
            if (xAxisFormatter) {
              return xAxisFormatter(this.value);
            } else {
              return undefined;
            }
          },
          x: xAxisLabelXOffset,
          y: 18,
          style: {
            fontSize: '10px',
            fontWeight: '600',
            color: AXIS_TEXT,
            whiteSpace: 'nowrap',
            textOverflow: 'none',
            textTransform: 'uppercase'
          }
        },
        tickInterval: xAxisTickInterval,
        tickmarkPlacement: 'on',
        startOnTick: false,
        endOnTick: false,
        tickPositions: xAxisTickPositions ? xAxisTickPositions : undefined,
        lineWidth: 0,
        gridLineWidth: 1,
        categories: xAxisData,
        tickWidth: xAxisTickInterval || xAxisTickPositions ? 1 : 0,
        tickLength: xAxisTickInterval || xAxisTickPositions ? 8 : 10,
        minorTicks: true,
        minorGridLineWidth: 0,
        minorTickInterval: xAxisTickInterval / 2,
        minorTickWidth: 1,
        minorTickLength: 4,
        showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_X_AXIS)
      } as Highcharts.XAxisOptions,
      tooltip: tooltip
        ? {
            outside: true,
            backgroundColor: CHART_TOOLTIP_BG,
            borderRadius: 4,
            borderColor: CHART_TOOLTIP_BORDER,
            shared: sharedTooltip,
            crosshairs: sharedTooltip,
            style: {
              color: CHART_TOOLTIP_TEXT
            },
            shape: 'none' as Highcharts.TooltipShapeValue,
            hideDelay: 0.2,
            useHTML: htmlTooltip,
            formatter() {
              return ReactDOMServer.renderToStaticMarkup(<ToolTip {...tooltip(this)} />);
            }
          }
        : undefined,
      plotOptions: {
        area: {
          marker: {
            enabled: false
          },
          stacking: 'normal' as Highcharts.OptionsStackingValue
        },
        series: {
          connectNulls: Boolean(connectNulls),
          color: defaultChartPalette[0],
          marker: {
            enabled: Boolean(disableMarker) === false,
            radius: 3
          },
          animation: false,
          dataLabels:
            seriesFormatter && plotlineType === PlotLineType.vertical
              ? {
                  enabled: true,
                  formatter() {
                    return seriesFormatter(this.y);
                  },
                  ...plotSeriesLabelStyle
                }
              : plotSeriesLabelStyle
        }
      },
      series: series as Highcharts.SeriesLineOptions[],
      responsive: this.prepareResponsiveObject()
    };

    // Force the IDs that Highcharts uses to be deterministic
    if (highchartsIdBase) {
      Highcharts.uniqueKey = getHighchartsId(highchartsIdBase);
    }
    Highcharts.setOptions({colors: defaultChartPalette});

    return {
      id,
      config: chartConfig
    };
  };

  private prepareResponsiveObject() {
    const {xAxisTickPositions, xAxisData} = this.props;
    const tickCount = typeof xAxisTickPositions === 'undefined' ? xAxisData.length : xAxisTickPositions.length - 2;

    return {
      rules: [
        {
          condition: {maxWidth: 102 * tickCount},
          chartOptions: {
            xAxis: {
              labels: {
                x: 0
              }
            }
          }
        }
      ]
    };
  }
}
export default PercentAreaChart;
