/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Traffic Composition Query
 */

import moment from 'moment';

import {Query} from '../types';
import {TrafficCompositionData} from '../../../components/flightDetails/charts/TrafficCompositionUtils';

/**
 * Transforms the given raw traffic composition data to be ready to display in charts, etc.
 * @param trafficCompositionRawData Traffic composition data from the API
 * @returns TrafficCompositionData Transformed traffic composition data
 */
const transformTrafficCompositionData = (
  trafficCompositionRawData: TrafficCompositionData[] | null
): TrafficCompositionData[] | null => {
  if (!trafficCompositionRawData) return null;
  const trafficCompositionData: TrafficCompositionData[] = trafficCompositionRawData
    .filter((trafficComposition) => trafficComposition.timestamp !== null)
    .map((trafficComposition) => ({
      ...trafficComposition,
      // Force timestamps to be in the expected format (moment with Firefox/Safari doesn't seem to handle " +0000")
      timestamp: moment.utc(trafficComposition.timestamp.replace(/ \+0000$/g, '')).toISOString()
    }));

  return trafficCompositionData;
};

export const useTrafficCompositionQuery: Query<TrafficCompositionData[]> = {
  route: `flightDetails/trafficComposition`,
  transform: transformTrafficCompositionData
};
export default useTrafficCompositionQuery;
