/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: App component
 */

import React from 'react';
import styled from '@emotion/styled';
import {Navigate, Route, Routes} from 'react-router-dom';
import moment from 'moment';
import {SliderMenu} from '@viasat/insights-components';

import useCustomerSelectorDisabler from './utils/useCustomerSelectorDisabler';
import SnackBars from './components/common/SnackBars';
import MapViewWrapper from './components/mapView/MapViewWrapper';
import ListViewPage from './components/lists/ListViewPage';
import FlightDetailsContainer from './components/flightDetails/FlightDetailsContainer';
import ConnectivityOutlookContainer from './components/connectivityOutlook/ConnectivityOutlookContainer';
import useHistoryBreadcrumbs from './utils/useHistoryBreadcrumbs';
import ConnectivityOutlookPage from './components/connectivityOutlook/ConnectivityOutlookPage';
import DashboardPage from './components/dashboard/DashboardPage';
import PAGES from './Pages';

const AppContainer = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  @media print {
    #sliderMenu__menu-wrapper {
      display: none;
    }
  }
`;

interface AppProps {
  windowLocation: string;
}

const App: React.FC<AppProps> = (props) => {
  const historyBreadcrumbs = useHistoryBreadcrumbs();
  // Disable the Customer Switch
  useCustomerSelectorDisabler();

  // Updates moment's relative time threshold (minutes) from 44 to 59
  // Reference: https://momentjs.com/docs/#/displaying/fromnow/
  moment.relativeTimeThreshold('m', 59);

  return (
    <AppContainer>
      <SliderMenu items={PAGES} getFullElementId={(name: string, type: string) => `sliderMenu__${name}-${type}`}>
        <Routes>
          <Route path="/" element={<Navigate to="fleet-map" />} />
          <Route path="/flight/details/" element={<Navigate to="/" />} />
          <Route path="/fleet-map" element={<MapViewWrapper />} />
          <Route key="lists" path="/lists/*" element={<ListViewPage />} />
          <Route key="dashboard" path="/dashboard/*" element={<DashboardPage />} />
          <Route
            path="/flight/details/:flightId"
            key="flight-details"
            element={<FlightDetailsContainer historyBreadcrumbs={historyBreadcrumbs} />}
          />
          <Route
            path="/connectivity-outlook-planner"
            key="connectivity-outlook-planner"
            element={<ConnectivityOutlookContainer />}
          />
          <Route
            path="/connectivity-outlook-page"
            key="connectivity-outlook-page"
            element={<ConnectivityOutlookPage />}
          />
        </Routes>
      </SliderMenu>
      <SnackBars />
    </AppContainer>
  );
};

export default App;
