/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Request Case Update Status Popup
 */
import React from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import 'react-base-table/styles.css';
import PopUpModal from '../../../common/Modal';
import {CLOSE_ICON_COLOR, SW_REVISIONS_MODAL_TITLE_COLOR} from '../../../common/theme/Colors';
import CustomerServiceRepIcon from '../../../common/theme/icons/CustomerServiceRepIcon';

const RequestUpdateStatusContainer = styled.div`
  width: 33.5vw;
  height: 34.5vh;
  display: flex;
`;

const SuccessResponse = styled.div`
  margin: 24px 40px 56px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    display: flex;
  }
  h1 {
    display: flex;
    font-size: 24px;
    font-weight: 800;
    margin: 0;
  }
  p {
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    color: #465967;
    span {
      font-weight: bold;
    }
  }
`;

const CloseModalContainer = styled.div`
  width: 7.5%;
  top: 17px;
  color: ${CLOSE_ICON_COLOR};
  cursor: pointer;
  position: absolute;
  right: 0px;
`;

interface RequestUpdateStatusPopupProps {
  setOpenModal: (openModal: boolean) => void;
  openModal: boolean;
}

const RequestUpdateStatusPopup: React.FC<RequestUpdateStatusPopupProps> = ({openModal, setOpenModal}) => {
  /**
   * method to close the modal popup
   */
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <PopUpModal
        id="requestCaseUpdateModal"
        open={openModal}
        title={
          <CloseModalContainer>
            <CloseIcon className="closeIcon" onClick={closeModal} />
          </CloseModalContainer>
        }
        titlecolor={SW_REVISIONS_MODAL_TITLE_COLOR}
        borderRadius="16px"
        padding={'0px'}
      >
        <RequestUpdateStatusContainer>
          <SuccessResponse>
            <CustomerServiceRepIcon id="requestCaseUpdateModalIcon" />
            <h1>Thank you for your inquiry.</h1>
            <p>
              Your request has been sent. Viasat support staff will reply by email as quickly as possible. For immediate
              assistance, please call: <br />
              <span>1-866-VSAT-AIR</span>
            </p>
          </SuccessResponse>
        </RequestUpdateStatusContainer>
      </PopUpModal>
    </div>
  );
};

export default RequestUpdateStatusPopup;
