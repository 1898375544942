/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Selected Flight leg utils
 */

import {FlightPlanEvents} from '../../store/queries/connectivityPlanner/flightEventsQuery';
import {IFlightPath, IFlightPathPoint} from '../../store/queries/connectivityPlanner/flightPathQuery';
import {FlightPlanInfo} from '../../store/queries/connectivityPlanner/flightPlansByIdQuery';
import {IMapMarker} from './ConnectivityOutlookMap';
import {ConnectivityOutlookStatus} from './ConnectivityOutlookUtils';
import {IFlightLegsEventsTimeLineData} from './EventsTimeline';

/**
 * gets the index of selected leg
 * @returns index of selectedleg
 */
export const getSelectedLeg = (selectedLegIdx) => {
  return selectedLegIdx === -1 ? 0 : selectedLegIdx;
};

/**
 * Generates a combined flight path by merging the flight paths of selected flight plans
 * @param flightPaths Flightpaths of selected flight plans
 * @param selectedLegIdx index of the selected leg
 * @returns A combined flight path
 */
export const getSelectedLegFlightPath = (flightPaths: IFlightPath[], selectedLegIdx: number): IFlightPath => {
  const flightPathInfo: IFlightPathPoint[] = [];
  const flightPathSet = selectedLegIdx === -1 ? flightPaths : [flightPaths[selectedLegIdx]];
  flightPathSet?.forEach((item) => item.flightPath.forEach((p: any) => flightPathInfo.push(p)));
  return {
    flightPath: flightPathInfo,
    start: flightPathSet[0].start,
    end: flightPathSet.length ? flightPathSet[flightPathSet.length - 1].end : null,
    futureFlightPath: []
  };
};

/**
 * Generates map markers by combining the origin and destination pair of selected flight plans
 * @param flightPaths Flightpaths of selected flight plans
 * @param flightPlans List of  selected flight plans
 * @param selectedLegIdx index of the selected leg
 * @returns A list of map markers
 */
export const getSelectedLegMapMarkers = (
  flightPaths: IFlightPath[],
  flightPlans: FlightPlanInfo[],
  selectedLegIdx: number
): IMapMarker[] => {
  const markers: IMapMarker[] = [];
  const flightPathSet = selectedLegIdx === -1 ? flightPaths : [flightPaths[selectedLegIdx]];
  flightPathSet?.forEach((item) =>
    markers.push({
      location: item.start,
      type: 'start',
      name: flightPlans?.find((airport) => item.id.toString() === airport.id.toString()).departureAirport
    })
  );
  if (flightPathSet && flightPathSet.length) {
    markers.push({
      location: flightPathSet[flightPathSet.length - 1].end,
      type: 'end',
      name: flightPlans?.find(
        (airport) => airport.id.toString() === flightPathSet[flightPathSet.length - 1].id.toString()
      ).destinationAirport
    });
  }
  return markers;
};
/**
 * Generates event markers by extracting the lat and lng of flight path point with availability other than connected
 * @param flightPaths Flightpaths of selected flight plans
 * @param selectedLegIdx index of the selected leg
 * @returns A list of event markers
 */
export const getSelectedLegEventMarkers = (flightPaths: IFlightPath[], selectedLegIdx: number): IMapMarker[] => {
  const markers: IMapMarker[] = [];
  const flightPathSet = selectedLegIdx === -1 ? flightPaths : [flightPaths[selectedLegIdx]];
  let eventId = 0;
  flightPathSet?.forEach((item) => {
    item.flightPath.forEach((flightPathPoint, index) => {
      if (
        (flightPathPoint.event.displayName === ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION ||
          flightPathPoint.event.displayName === ConnectivityOutlookStatus.SATELLITE_HANDOVER ||
          flightPathPoint.event.displayName === ConnectivityOutlookStatus.OUT_OF_COVERAGE ||
          flightPathPoint.event.displayName === ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS) &&
        item.flightPath[index - 1]?.availability !== flightPathPoint.availability
      ) {
        eventId += 1;
        markers.push({
          location: flightPathPoint,
          type: 'event',
          name: eventId.toString()
        });
      }
    });
  });
  return markers;
};

/**
 * Generates events list  by combining connectivity events of selected flight plans
 * @param flightEvents flightEvents of selected flight plans
 * @param selectedLegIdx index of the selected leg
 * @returns A list of connectivity events to display
 */
export const getSelectedLegEventsList = (
  flightEvents: FlightPlanEvents[],
  selectedLegIdx: number
): FlightPlanEvents[] => {
  const flightLegEventsData = [];
  let eventId = 0;
  selectedLegIdx === -1
    ? flightEvents?.forEach((row) => {
        row.events.forEach((eventItem) => {
          eventId += 1;
          eventItem.eventId = eventId;
        });
        flightLegEventsData.push(row);
      })
    : flightEvents?.forEach((row, index) => {
        if (index === selectedLegIdx) {
          row.events.forEach((eventItem) => {
            eventId += 1;
            eventItem.eventId = eventId;
          });
          flightLegEventsData.push(row);
        }
      });
  return flightLegEventsData;
};

/**
 * Calculate disconnected time of selected flight plans
 * @param eventsTimeLineData list of connectivity events for flight plans
 * @param selectedLegIdx index of the selected leg
 * @returns disconnected time.
 */
export const getSelectedLegDisconnectedTime = (
  eventsTimeLineData: IFlightLegsEventsTimeLineData[],
  selectedLegIdx: number
) => {
  let totalTime = 0;
  const eventsTimelineSet = selectedLegIdx === -1 ? eventsTimeLineData : [eventsTimeLineData[selectedLegIdx]];
  eventsTimelineSet.forEach((flightLeg) => {
    totalTime =
      totalTime +
      flightLeg.flightLegEvents?.filter((row) => row.status === ConnectivityOutlookStatus.OUT_OF_COVERAGE).length;
    totalTime =
      totalTime +
      flightLeg.flightLegEvents?.filter((row) => row.status === ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS)
        .length;
    totalTime =
      totalTime +
      flightLeg.flightLegEvents?.filter((row) => row.status === ConnectivityOutlookStatus.NO_SERVICE).length;
  });
  return totalTime;
};

/**
 * Generates eventsTimeline data of selected flight plans
 * @param eventsTimeline list of connectivity events of flight plans
 * @param selectedLegIdx index of the selected leg
 * @returns events time line data for selected flight plans
 */
export const getSelectedLegEventsTimeline = (
  eventsTimeline: IFlightLegsEventsTimeLineData[],
  selectedLegIdx: number
) => {
  const eventsTimelineSet = selectedLegIdx === -1 ? eventsTimeline : [eventsTimeline[selectedLegIdx]];
  const eventsTimelineToDisplay: IFlightLegsEventsTimeLineData[] = eventsTimelineSet.map(
    (flightLeg: IFlightLegsEventsTimeLineData, index): IFlightLegsEventsTimeLineData => {
      const flightLegEvents = flightLeg.flightLegEvents.map((event) => {
        return {
          eventDate: event.eventDate,
          status: event.status,
          eventName: event.eventName ? event.eventName : null,
          iconText: event.iconText ? event.iconText : null
        };
      });
      return {
        flightPlanId: flightLeg.flightPlanId,
        flightLegEvents: flightLegEvents,
        xAxis: index,
        yAxis: index
      };
    }
  );
  return eventsTimelineToDisplay;
};
