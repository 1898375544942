/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utilities for building element IDs that follow the naming convention established on this wiki page:
 * https://wiki.viasat.com/display/CMDevOps/Insights+Integration+Testing#InsightsIntegrationTesting-NamingConventions
 */

/**
 * Returns the properly-formatted element ID base built from the given section and subsection
 * @param section section of the UI (ex: ifcDashboard, fleetMap, etc.)
 * @param subSection the subsection of the UI (ex: customerIntelligence), can be left undefined
 * @returns the properly-formatted element ID base
 * @note All parameters should be in camelCase
 */
export const getElementIdSectionBase = (section: string, subSection?: string | undefined) =>
  subSection ? `${section}--${subSection}` : section;

/**
 * Returns the properly-formatted element ID built from the given ID base, name and type
 * @param idBase base ID as would be built by getElementIdBase
 * @param name the name of the element (ex: search, flightId, etc.)
 * @param type the type of the element (ex: button, label, etc.)
 * @returns the properly-formatted element ID
 * @note All parameters should be in camelCase
 */
export const getElementIdFromSectionBase = (sectionBase: string, name: string, type: string): string =>
  `${sectionBase}__${name}-${type}`;

/**
 * Returns the properly-formatted element ID built from the given ID sub-components
 * @param section section of the UI (ex: ifcDashboard, fleetMap, etc.)
 * @param subSection the subsection of the UI (ex: customerIntelligence), can be left undefined
 * @param name the name of the element (ex: search, flightId, etc.)
 * @param type the type of the element (ex: button, label, etc.)
 * @returns the properly-formatted element ID
 * @note All parameters should be in camelCase
 */
export const getElementId = (section: string, subSection: string | undefined, name: string, type: string): string =>
  getElementIdFromSectionBase(getElementIdSectionBase(section, subSection), name, type);

/**
 * Returns the properly-formatted element ID fragment base built from the given ID sub-components
 * @param section section of the UI (ex: ifcDashboard, fleetMap, etc.)
 * @param subSection the subsection of the UI (ex: customerIntelligence), can be left undefined
 * @param namePrefix the beginning of name of the element
 * @returns the properly-formatted element ID fragment base
 * @note All parameters should be in camelCase
 */
export const getElementIdFragmentBase = (section: string, subSection: string | undefined, namePrefix: string): string =>
  `${getElementIdSectionBase(section, subSection)}__${namePrefix}`;

/**
 * Returns the properly-formatted element ID from the given fragment base, name suffix, and type
 * @param fragmentBase the fragment base as built by getElementIdFragmentBase
 * @param nameSuffix the end of the name of the element, should be in PascalCase
 * @param type the type of the element (ex: button, label, etc.)
 * @returns the properly-formatted element ID
 * @note All parameters except nameSuffix should be in camelCase
 */
export const getElementIdFromFragmentBase = (fragmentBase: string, nameSuffix: string | number, type: string) =>
  `${fragmentBase}${nameSuffix}-${type}`;
