/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description:
 * Should be a Donut Chart... really?
 */

import Highcharts from 'highcharts';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from '@emotion/styled';

import {applyMediaQueriesForStyle} from '../../../../utils/StyledComponents';
import {ChartType, IPieChart} from './types';
import {getHighchartsId} from './chartUtils';
import ToolTip from './ToolTip/ToolTip';
import {checkSeriesHasData, renderCheckNoDataToDisplay} from './RenderOverlay';

import {CHART_TOOLTIP_BORDER} from '../../theme/Colors';

const StyledContainer = styled.div`
  .pie-legend-entry {
    margin: 0px 0px 10px 0px;
    .pie-legend-value {
      font-family: 'Source Sans Pro', sans-serif;
      color: #000000;
      font-weight: 600;
      letter-spacing: -0.12px;
      line-height: 28px;

      ${(props: any) => applyMediaQueriesForStyle('font-size', {sm: '16px', md: '20px', lg: '22px'}, props.theme)};
    }
    .pie-legend-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      color: #626262;
      font-size: 12px;
      letter-spacing: -0.09px;
      line-height: 14px;
    }
    .pie-legend-change {
      font-family: 'Source Sans Pro';
      font-size: 12px;
      letter-spacing: -0.07px;
      font-weight: 500;
      display: inline-flex;
      vertical-align: bottom;
      padding: 0 0 3px 5px;
      &.positive {
        color: ${({theme}: any) => theme.positiveTrendColor};
      }
      &.negative {
        color: ${({theme}: any) => theme.negativeTrendColor};
      }
      svg {
        ${(props: any) => applyMediaQueriesForStyle('height', {xs: '12px', md: '1rem'}, props.theme)};
        ${(props: any) => applyMediaQueriesForStyle('width', {xs: '12px', md: '1rem'}, props.theme)};
      }
    }
  }

  .pie-title {
    color: #000000;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-weight: 700;
  }
  .pie-sub-title {
    color: #000000;
    font-family: 'Source Sans Pro';
    font-size: 16px;
  }
  .pie-title-no-data {
    font-size: 16px;
  }
`;

const LegendElement = ({name}) => {
  return (
    <div className="pie-legend-entry">
      <span className="pie-legend-title">{name}</span>
      <br />
    </div>
  );
};

export const TitleElement = ({title, subTitle}) => {
  return (
    <>
      <div className="pie-title">{title}</div>
      <br />
      <div className="pie-sub-title">{subTitle}</div>
    </>
  );
};

const NoDataTitleElement = ({title}) => <div className="pie-title-no-data">{title}</div>;

class PieChart extends React.Component<IPieChart> {
  componentDidMount() {
    this.generateChart(this.props);
  }

  shouldComponentUpdate(nextProps) {
    let shouldUpdateComponent = false;

    if (nextProps.chartData && nextProps.chartData.length && Object.keys(this.props.chartData).length !== 0) {
      for (let index = 0; index < nextProps.chartData.length; index++) {
        if (nextProps.chartData[index].y !== this.props.chartData[index].y) {
          shouldUpdateComponent = true;
          break;
        }
      }
      return shouldUpdateComponent;
    } else if (nextProps.chartData && nextProps.chartData.length !== Object.keys(this.props.chartData).length) {
      return true;
    } else {
      return false;
    }
  }
  componentDidUpdate() {
    this.generateChart(this.props);
  }

  render() {
    const {id} = this.props;
    return <StyledContainer className="chart-container" data-test="pie-chart" id={id} />;
  }

  private generateChart = (props: any) => {
    const {
      id,
      chartData,
      palette,
      tooltip,
      highchartsIdBase,
      noDataOptions,
      chartTitle,
      chartSubTitle,
      onLegendClick,
      noDataChartTitle,
      height,
      size,
      hideLegend,
      width
    } = props;
    const hasChartData = chartData && chartData.length;

    const pieChartConfig = {
      chart: {
        type: ChartType.PIE,
        height: height ? height : 250,
        width: width ? width : undefined,
        spacingTop: 1,
        animation: false,
        events: {
          load() {
            document.querySelectorAll('.highcharts-credits').forEach((el) => el.remove());
          },
          render() {
            const hasSeriesData = checkSeriesHasData(this.series);
            
            // Handle No Data to Display rendering
            renderCheckNoDataToDisplay(this, id, noDataOptions, hasSeriesData);
          }
        }
      },
      title: {
        text: ReactDOMServer.renderToString(
          hasChartData ? (
            <TitleElement title={chartTitle} subTitle={chartSubTitle} />
          ) : (
            <NoDataTitleElement title={noDataChartTitle} />
          )
        ),
        verticalAlign: 'middle' as Highcharts.VerticalAlignValue,
        y: hasChartData ? -60 : 12
      },
      tooltip: tooltip
        ? {
            useHTML: true,
            outside: true,
            backgroundColor: '#FFF',
            borderRadius: 4,
            borderColor: CHART_TOOLTIP_BORDER,
            shape: 'none' as Highcharts.TooltipShapeValue,
            style: {
              color: '#000'
            },
            hideDelay: 0.2,
            formatter() {
              return ReactDOMServer.renderToStaticMarkup(<ToolTip {...tooltip(this)} />);
            }
          }
        : undefined,

      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {enabled: false},
          showInLegend: hideLegend ? false : true,
          size: size ? size : 250,
          point: {
            events: {
              legendItemClick: onLegendClick
            }
          }
        },
        series: {
          animation: false
        }
      },
      series: [
        {
          name: ' ',
          innerSize: '55%',
          data: chartData,
          states: {
            hover: {
              enabled: true,
              halo: {
                size: 0
              }
            }
          }
        } as Highcharts.SeriesPieOptions
      ],
      legend: this.prepareLegendObject(hideLegend),
      responsive: this.prepareResponsiveObject(hasChartData, height)
    };

    // Force the IDs that Highcharts uses to be deterministic
    if (highchartsIdBase) {
      Highcharts.uniqueKey = getHighchartsId(highchartsIdBase);
    }
    Highcharts.setOptions({colors: palette});
    Highcharts.chart(id, pieChartConfig);
  };

  private prepareLegendObject(hideLegend?: boolean) {
    return {
      enabled: hideLegend ? false : true,
      useHTML: true,
      layout: 'vertical' as Highcharts.OptionsLayoutValue,
      align: 'right' as Highcharts.AlignValue,
      verticalAlign: 'bottom' as Highcharts.VerticalAlignValue,
      width: 250,
      maxHeight: 450,
      itemWidth: 225,
      labelFormatter() {
        return ReactDOMServer.renderToString(<LegendElement {...this} />);
      }
    };
  }

  private prepareResponsiveObject(hasData, chartHeight?: number) {
    const legendObj = {
      layout: 'vertical' as Highcharts.OptionsLayoutValue,
      align: 'center' as Highcharts.AlignValue,
      verticalAlign: 'bottom' as Highcharts.VerticalAlignValue,
      maxHeight: 350,
      alignColumns: false,
      padding: 0,
      symbolPadding: 5,
      symbolHeight: 11
    };

    return {
      rules: [
        {
          condition: {maxWidth: 480},
          chartOptions: {
            chart: {
              height: hasData ? (chartHeight? chartHeight : 450) : (chartHeight? chartHeight : 280)
            },
            legend: {
              ...legendObj,
              itemWidth: 180
            }
          }
        },
        {
          condition: {maxWidth: 400},
          chartOptions: {
            chart: {
              height: hasData ? (chartHeight? chartHeight : 450) : (chartHeight? chartHeight : 280)
            },
            legend: {
              ...legendObj,
              itemWidth: 200
            }
          }
        }
      ]
    };
  }
}

export default PieChart;
