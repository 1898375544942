/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft Status List Query
 */

import {Query} from '../types';
import {
  DATE_TIME_VERBOSE_LOCAL_FORMAT,
  msAsString,
  formatValue,
  formatHMSecondsValue
} from '../../../utils/DateTimeUtils';
import {
  BYTES_PER_MEGABYTE,
  FORMAT_VALUE_DEFAULT_PRECISION,
  FORMAT_VALUE_DEFAULT_UNITS,
  FORMAT_VALUE_USE_SEPARATOR
} from '../../../utils/constants';
import {findIndex} from 'lodash';

export interface AircraftStatusInfo {
  favorite: boolean;
  customer: string;
  serialNumber: string;
  tailId: string;
  aircraftId: string;
  aircraftManufacturer: string;
  aircraftType: string;
  networkCapability: string;
  flightStart: string;
  flightEnd: string;
  flightDuration: string;
  connectedDuration: string;
  connectionStart: string;
  connectionEnd: string;
  dataUsage: string;
  dataUsageStatus: string;
  flightId: string;
  faFlightId: string;
  status: string;
  flightPhase: string;
  displayOrder: number;
  pingsConnectedStart: string;
  pingsConnectedEnd: string;
  lastNetwork: string;
  sortIdx?: number;
  openCases?: number;
  valueAddedReseller?: string;
  estimatedArrivalTstamp?: string;
  origin?: any;
  destination?: any;
  isDark?: boolean;
  kaVersions?: any;
  kuVersions?: any;
  kaTerminalSwVersion: string;
  kaTerminalSwVersionStatus: string;
  bdtVersion: string;
  bdtVersionStatus: string;
  kuTerminalSwVersion: string;
  kuTerminalSwVersionStatus: string;
  sedFileVersion: string;
  sedFileVersionStatus: string;
  sscfFileVersion: string;
  sscfFileVersionStatus: string;
}

interface AircraftStatusInfoRaw {
  customer: string;
  serialNumber: string;
  tailId: string;
  aircraftId: string;
  aircraftManufacturer: string;
  aircraftType: string;
  networkCapability: string;
  flightStartTimestamp: number;
  flightEndTimestamp: number;
  flightDuration: number;
  last5MinutesConnectivity: number;
  lastFlightPhase: string;
  isOnGround: boolean;
  connectedStartTimestamp: number;
  connectedEndTimestamp: number;
  connectionDurationSeconds: number;
  dataUsageBytes: number;
  dataUsageStatus: string;
  flightId: string;
  status: string;
  faFlightId: string;
  pingsConnectionStartTimestamp: number;
  pingsConnectionEndTimestamp: number;
  lastNetwork: string;
  valueAddedReseller?: string;
  statusSort?: number;
  estimatedArrivalTstamp?: number;
  actualDepartureTstamp?: number;
  origin?: string;
  destination?: string;
  isDark?: boolean;
  kaTerminalSwVersion: string;
  kaTerminalSwVersionStatus: string;
  bdtVersion: string;
  bdtVersionStatus: string;
  kuTerminalSwVersion: string;
  kuTerminalSwVersionStatus: string;
  sedFileVersion: string;
  sedFileVersionStatus: string;
  sscfFileVersion: string;
  sscfFileVersionStatus: string;
}

const getAircraftWatchListStatus = (aircraftId: string) => {
  const watchlistedAircraft = localStorage['watchlistAircraft'] ? JSON.parse(localStorage['watchlistAircraft']) : [];
  const isWatchlisted = findIndex(watchlistedAircraft, {aircraftId: aircraftId});
  return isWatchlisted > -1 ? true : false;
};

/**
 * Transforms the given raw aircraft status info data into (regular) aircraft info data
 * @param aircraftStatusInfoRawList aircraftStatusInfoRaw values from the api
 * @returns AircraftStatusInfo Transformed aircraft status info
 */
const transformRawAircraftStatusInfo = (
  aircraftStatusInfoRawList: AircraftStatusInfoRaw[] | null
): AircraftStatusInfo[] | null => {
  if (!aircraftStatusInfoRawList) return null;
  const aircraftStatusInfo: AircraftStatusInfo[] = aircraftStatusInfoRawList.map((aircraftStatusInfoRaw) => {
    return {
      favorite: getAircraftWatchListStatus(aircraftStatusInfoRaw.aircraftId),
      customer: aircraftStatusInfoRaw.customer,
      serialNumber: aircraftStatusInfoRaw.serialNumber,
      tailId: aircraftStatusInfoRaw.tailId,
      aircraftId: aircraftStatusInfoRaw.aircraftId,
      aircraftManufacturer: aircraftStatusInfoRaw.aircraftManufacturer,
      aircraftType: aircraftStatusInfoRaw.aircraftType,
      networkCapability: aircraftStatusInfoRaw.networkCapability,
      flightStart: aircraftStatusInfoRaw.flightStartTimestamp
        ? msAsString(aircraftStatusInfoRaw.flightStartTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : aircraftStatusInfoRaw.actualDepartureTstamp
        ? msAsString(aircraftStatusInfoRaw.actualDepartureTstamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : null,
      flightEnd: aircraftStatusInfoRaw.flightEndTimestamp
        ? msAsString(aircraftStatusInfoRaw.flightEndTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : aircraftStatusInfoRaw.estimatedArrivalTstamp
        ? msAsString(aircraftStatusInfoRaw.estimatedArrivalTstamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : null,
      flightDuration: formatHMSecondsValue(aircraftStatusInfoRaw.flightDuration),
      connectionStart: aircraftStatusInfoRaw.connectedStartTimestamp
        ? msAsString(aircraftStatusInfoRaw.connectedStartTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : null,
      connectionEnd: aircraftStatusInfoRaw.connectedEndTimestamp
        ? msAsString(aircraftStatusInfoRaw.connectedEndTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : null,
      status: aircraftStatusInfoRaw.status,
      openCases: 0,
      flightPhase: aircraftStatusInfoRaw.lastFlightPhase,
      connectedDuration: formatHMSecondsValue(aircraftStatusInfoRaw.connectionDurationSeconds),
      dataUsage: aircraftStatusInfoRaw.dataUsageBytes
        ? aircraftStatusInfoRaw.dataUsageBytes / BYTES_PER_MEGABYTE < 1 // Show '< 1' if usage is less than 1 MB
          ? '< 1'
          : formatValue(
              aircraftStatusInfoRaw.dataUsageBytes / BYTES_PER_MEGABYTE,
              FORMAT_VALUE_DEFAULT_PRECISION,
              FORMAT_VALUE_DEFAULT_UNITS,
              FORMAT_VALUE_USE_SEPARATOR
            )
        : null,
      flightId: aircraftStatusInfoRaw.flightId,
      faFlightId: aircraftStatusInfoRaw.faFlightId,
      displayOrder: aircraftStatusInfoRaw.statusSort,
      pingsConnectedStart: aircraftStatusInfoRaw.pingsConnectionStartTimestamp
        ? msAsString(aircraftStatusInfoRaw.pingsConnectionStartTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : null,
      pingsConnectedEnd: aircraftStatusInfoRaw.pingsConnectionEndTimestamp
        ? msAsString(aircraftStatusInfoRaw.pingsConnectionEndTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : null,
      lastNetwork: aircraftStatusInfoRaw.lastNetwork,
      estimatedArrivalTstamp: aircraftStatusInfoRaw.estimatedArrivalTstamp
        ? msAsString(aircraftStatusInfoRaw.estimatedArrivalTstamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : null,
      actualDepartureTstamp: aircraftStatusInfoRaw.actualDepartureTstamp
        ? msAsString(aircraftStatusInfoRaw.actualDepartureTstamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
        : null,
      origin: aircraftStatusInfoRaw.origin,
      destination: aircraftStatusInfoRaw.destination,
      isDark: aircraftStatusInfoRaw.isDark,
      valueAddedReseller: aircraftStatusInfoRaw?.valueAddedReseller ? aircraftStatusInfoRaw.valueAddedReseller : null,
      dataUsageStatus: aircraftStatusInfoRaw.dataUsageStatus,
      kaTerminalSwVersion: aircraftStatusInfoRaw.kaTerminalSwVersion,
      kaTerminalSwVersionStatus: aircraftStatusInfoRaw.kaTerminalSwVersionStatus,
      bdtVersion: aircraftStatusInfoRaw.bdtVersion,
      bdtVersionStatus: aircraftStatusInfoRaw.bdtVersionStatus,
      kuTerminalSwVersion: aircraftStatusInfoRaw.kuTerminalSwVersion,
      kuTerminalSwVersionStatus: aircraftStatusInfoRaw.kuTerminalSwVersionStatus,
      sedFileVersion: aircraftStatusInfoRaw.sedFileVersion,
      sedFileVersionStatus: aircraftStatusInfoRaw.sedFileVersionStatus,
      sscfFileVersion: aircraftStatusInfoRaw.sscfFileVersion,
      sscfFileVersionStatus: aircraftStatusInfoRaw.sscfFileVersionStatus
    };
  });
  const actualListData = aircraftStatusInfo?.sort((a, b) => {
    if (!a.favorite && b.favorite) return 1;
    if (a.favorite && !b.favorite) return -1;
    return 1;
  });
  actualListData.map((aircraftStatusInfo, idx) => {
    aircraftStatusInfo.sortIdx = idx + 1;
    return aircraftStatusInfo;
  });
  return actualListData;
};

const aircraftStatusListQuery: Query<AircraftStatusInfo[]> = {
  route: 'aircraftStatus/list',
  transform: transformRawAircraftStatusInfo
};

export default aircraftStatusListQuery;
