/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Monthly Usage data Query
 */
import {Query} from '../types';

export interface IMonthlyUsage {
  total_data_used: number;
  total_roaming_data_used: number;
  total_hours_used: any;
  total_hours_used_in_minutes: any;
  is_hourly_plan: any;
  total_hours_allowed: any;
  total_hours_allowed_in_minutes: any;
  total_data_allowed: any;
  usage_period_start: string;
  usage_period_end: string;
  monthly_connected_time: string;
  service_plan_name: string;
}

export const useMonthlyUsageQuery: Query<IMonthlyUsage> = {
  route: `flightDetails/totalUsage`,
  transform: null
};
export default useMonthlyUsageQuery;
