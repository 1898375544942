/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Departure icon
 */

import {createSvgIcon} from '@mui/material';

const DepartureIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.12 2.039c-1.804.181-3.413.768-4.84 1.767-3.542 2.48-5.102 6.953-3.858 11.059a9.762 9.762 0 0 0 2.517 4.196 9.792 9.792 0 0 0 3.333 2.217c3.362 1.332 7.041.802 9.928-1.433.437-.338 1.307-1.208 1.645-1.645C21.255 16.379 22 14.234 22 12c0-4.568-3.158-8.613-7.57-9.697-.984-.241-2.402-.355-3.31-.264m1.62 2.002A7.98 7.98 0 0 1 19.182 8.5a7.965 7.965 0 0 1-1.417 9.03A7.977 7.977 0 0 1 12 19.999c-3.765 0-7.052-2.668-7.823-6.351a7.995 7.995 0 0 1 4.324-8.831 7.919 7.919 0 0 1 4.239-.776M8.664 8.063a1.005 1.005 0 0 0-.533.448c-.095.161-.111.233-.111.489s.016.328.111.489c.061.103.17.234.243.289.287.219.321.222 2.34.222h1.864l-2.224 2.23c-2.011 2.017-2.23 2.249-2.289 2.427-.129.39-.044.766.234 1.044s.654.363 1.044.234c.178-.059.41-.278 2.427-2.289L14 11.422v1.859c0 1.157.016 1.915.042 2.008.061.22.273.473.489.585a.987.987 0 0 0 1.17-.173c.315-.315.302-.15.29-3.784L15.98 8.7l-.111-.189a1.038 1.038 0 0 0-.539-.454c-.229-.082-6.442-.076-6.666.006"
      fillRule="evenodd"
    />
  </svg>,
  'DepartureIcon'
);
export default DepartureIcon;
