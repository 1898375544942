/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: This component handles the tab components and any children
 *
 */

import {Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {LoadingSpinner} from '@viasat/insights-components';
import FlightListPage from './flight/FlightListPage';
import TailListPage from './tail/TailListPage';
import AircraftStatusPage from './aircraftStatus/AircraftStatusPage';
import tabDefinitions from './tabDefinitions';
import PageStyled from '../../components/common/PageStyled';

const getPageComponent = (tabName) =>
  ({
    flightList: FlightListPage,
    tailList: TailListPage,
    aircraftStatus: AircraftStatusPage
  }[tabName] || null);

const ListViewPage = (props) => {
  return (
    <PageStyled>
      <Suspense fallback={<LoadingSpinner id="list-view-loading-screen" />}>
        <Routes>
          {tabDefinitions.map((listViewPage) => {
            const {page, tabName} = listViewPage;
            const PageComponent = getPageComponent(tabName);
            return <Route key={page} path={`${page}`} element={<PageComponent {...props} view={page} />} />;
          })}
          <Route element={<Navigate to="flight" />} />
        </Routes>
      </Suspense>
    </PageStyled>
  );
};

export default ListViewPage;
