/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: This component handles the tab components and any children
 *
 */

import PageStyled from '../common/PageStyled';
import SummaryMetricsPage from './SummaryMetricsPage';

const DashboardPage = (props) => {
  return (
    <PageStyled>
      <SummaryMetricsPage />
    </PageStyled>
  );
};

export default DashboardPage;
