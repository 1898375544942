/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Cabin Connectivity per flight
 */

import React, {useState} from 'react';
import WifiIcon from '@mui/icons-material/Wifi';
import {isNil, last} from 'lodash';

import FlightDetailsExpansion, {AccordionBodyItemType, AccordionSection} from './FlightDetailsExpansion';
import LeftContainerCard from './LeftContainerCard';
import {NO_DATA_INDICATOR} from '../../utils/constants';
import {formatMomentInput} from '../../utils/DateTimeUtils';
import {splitString} from '../../utils/MapUtil';
import TotalDataUsage, {SelectedTotalDataUsageCategories} from './charts/TotalDataUsage';
import {DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT} from '../../utils/DateTimeUtils';
import {FlightDataUsageTimeline} from './charts/DataUsage';
import {IInFlightDataUsage} from '../../store/queries/flightDetails/inFlightUsageQuery';
import {convertUsageData} from '../dashboard/usageCards/utils';

interface CabinConnectivityProps {
  width: number;
  gutter: number;
  isLoading: boolean;
  isHistorical: boolean;
  flightDetected: boolean;
  connectedStart: string;
  connectedEnd: string;
  connectedTime: string;
  serialNumber: string;
  status: string;
  satNetwork: string;
  satNetworkRegionName: string;
  satNetworkRegionCode: string;
  dataUsage: any;
  totalUsage: FlightDataUsageTimeline[];
  isRegionalPlan: boolean;
  visibleCategories: SelectedTotalDataUsageCategories;
  setVisibleCategories: any;
  inFlightDataUsage: IInFlightDataUsage;
}

/**
 * Apply style to the time in the date format
 * @param date date
 * @returns Formatted date and time with bold text.
 */
const getTime = (date: string) => {
  return !isNil(date) ? (
    <>
      {date.split(' ')[0]} <b>{date.split(' ')[1]}</b>
    </>
  ) : (
    '--'
  );
};

const CabinConnectivity: React.FC<CabinConnectivityProps> = (props) => {
  const {
    width,
    gutter,
    isLoading,
    isHistorical,
    flightDetected,
    connectedStart,
    connectedEnd,
    connectedTime,
    status,
    satNetwork,
    satNetworkRegionName,
    satNetworkRegionCode,
    dataUsage,
    totalUsage,
    isRegionalPlan,
    visibleCategories,
    setVisibleCategories,
    inFlightDataUsage
  } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const totalUsageData = totalUsage?.length ? last(totalUsage).cumulative_usage : 0;
  const connectivityItems = isHistorical
    ? [
        {
          name: 'Connected Start',
          value: getTime(
            formatMomentInput(splitString(connectedStart, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
          )
        },
        {
          name: 'Connected End',
          value: getTime(
            formatMomentInput(splitString(connectedEnd, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
          )
        },
        {name: 'Connected Time', value: connectedTime}
      ]
    : [
        {name: 'Connectivity', value: status},
        {name: 'Connected Time', value: connectedTime},
        {
          name: 'Satellite Network',
          value: satNetworkRegionCode ? satNetworkRegionCode + '(' + satNetwork + ')' : NO_DATA_INDICATOR,
          tooltipMsg: satNetwork === 'Ku' ? satNetworkRegionName : null
        }
      ];
  
  const dataUsageValues =  [ { name: 'Plan Data Used', value: inFlightDataUsage ? `${convertUsageData(inFlightDataUsage.dataUsage, true, false)} MB` : '--' }, { name: 'Roaming Data Used', value: inFlightDataUsage ? `${convertUsageData(inFlightDataUsage.roamingDataUsage, true, false)} MB` : '--' }] 

  const CABIN_CONNECTIVITY_ITEMS: AccordionSection[] = [
    {
      header: 'Total Data Usage',
      type: AccordionBodyItemType.Element,
      items: [
        <TotalDataUsage
          key="total-usage-chart"
          dataUsageData={dataUsage}
          isHistoricalFlight={isHistorical}
          isLoading={isLoading}
          totalUsageData={totalUsageData}
          visibleCategories={visibleCategories}
          setVisibleCategories={setVisibleCategories}
        />
      ],
      columnsPerRow: 1
    }
  ];
  if (isRegionalPlan) {
    CABIN_CONNECTIVITY_ITEMS.unshift({
      header: 'In-Flight Data Usage',
      id: 'dataUsageInFlight',
      type: AccordionBodyItemType.Grid,
      items: dataUsageValues,
      columnsPerRow: 1
    })
  }
  if ((isHistorical && flightDetected) || !isHistorical) {
    CABIN_CONNECTIVITY_ITEMS.unshift({
      header: 'In-Flight Connectivity',
      id: 'cabinConnectivityInFlight',
      type: AccordionBodyItemType.Grid,
      items: connectivityItems,
      columnsPerRow: 1
  });

  }

  return (
    <LeftContainerCard id="cabin-connectivity" width={width} height={800} gutter={gutter} isLoading={isLoading}>
      <FlightDetailsExpansion
        panelId="cabinConnectivity"
        title="Cabin Connectivity"
        Icon={WifiIcon}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sections={CABIN_CONNECTIVITY_ITEMS}
      />
    </LeftContainerCard>
  );
};

export default CabinConnectivity;
