/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ChartWrapper Container for Ping Latency
 */

import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import {useIntl} from 'react-intl';

import {CHART_AXIS_MINOR_TICK, PING_LATENCY_LINE_COLOR, DEFAULT_PLOTLINE_COLOR} from '../../common/theme/Colors';
import {DATE_CONCISE_FORMAT, TIME_CONCISE_NO_PAD_FORMAT} from '../../../utils/DateTimeUtils';
import {ChartType} from '../../common/elements/chart/types';
import {stringAsMs, msAsString, areMsTimestampMinutesEqualTo} from '../../../utils/DateTimeUtils';
import {
  formatAvgPingLatencyValue,
  ChartTimeSettings,
  adjustCategorizedChartData,
  getXAxisTickPositions
} from '../flightDetailsUtil';
import ToolTipDateTimeLabel from '../../common/elements/chart/ToolTip/ToolTipDateTimeLabel';
import PingLatencyToolTip from './tooltip/PingLatencyToolTip';
import {FORMAT_VALUE_NO_UNITS, PING_LATENCY_SERIES_NAME} from '../../../utils/constants';
import {formatConfig} from '../../common/elements/chart/chartUtils';
import LineChart from '../../common/elements/chart/LineChart';

interface PingLatencyProps {
  currentTime: string;
  isLoading: boolean;
  pingLatency: any[];
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
}

const ChartContainer = styled.div`
  .highcharts-xaxis-grid path {
    stroke-width: 0;
  }
  .highcharts-xaxis path {
    stroke-width: 1px;
    stroke: ${CHART_AXIS_MINOR_TICK};
  }
`;

const PingLatency: React.FC<PingLatencyProps> = ({
  currentTime,
  isLoading,
  pingLatency,
  chartTimeSettings,
  parentLeftOffset,
  liveMask
}: PingLatencyProps) => {
  const [chartConfig, setChartConfig] = useState<any>(null);

  const intl = useIntl();

  useEffect(() => {
    if (!isLoading && pingLatency && chartTimeSettings.start && chartTimeSettings.end) {
      // General config
      const id = 'flightDetails--pingLatency__timeSeries-chart';
      const highchartsIdBase = 'pingLatency';
      const chartType = ChartType.LINE;
      const height = 160;
      const marginLeft = 75;
      const marginRight = 25;
      const defaultChartPalette = [PING_LATENCY_LINE_COLOR];
      const palette = [PING_LATENCY_LINE_COLOR];
      const connectNulls = false;
      const disableMarker = false;

      // Plotlines
      const plotline = 0;
      const plotlineType = 'horizontal';
      const plotlineColor = DEFAULT_PLOTLINE_COLOR;

      // X-axis
      let rawXAxisData = pingLatency.map((i) => stringAsMs(i.timestamp));
      const xAxisLabelPlotlines = [];
      const xAxisLabelXOffset = 0;
      const xAxisFormatter = (value: any) => {
        // Note: Minor ticks are not supported for category-based axes
        if (Number(value) < 1e12 || !areMsTimestampMinutesEqualTo(value, chartTimeSettings.labeledTickMinutesValues)) {
          return '';
        }

        const hourLabel = msAsString(value, TIME_CONCISE_NO_PAD_FORMAT);
        const dayLabel = msAsString(value, DATE_CONCISE_FORMAT);
        return hourLabel === '0:00' ? dayLabel : hourLabel;
      };

      // Y-axis
      const MAX_Y_TICKS = 9;
      const MIN_Y_TICK_SPACING = 200;
      const latencies = pingLatency.map((i) => i.average_latency);
      const yMaxValue = latencies.length > 0 ? Math.max(...latencies) : 0;
      const thresholdBuffer = 50;
      const pingLatencyTarget = 1400;
      const yAxisMax =
        yMaxValue > pingLatencyTarget + thresholdBuffer ? pingLatencyTarget + thresholdBuffer : undefined;
      const endOnTick = yAxisMax ? false : true;
      const yTickFactor =
        yMaxValue === 0 || yAxisMax === pingLatencyTarget + thresholdBuffer
          ? MIN_Y_TICK_SPACING
          : Math.ceil(yMaxValue / (MAX_Y_TICKS - 1) / MIN_Y_TICK_SPACING) * MIN_Y_TICK_SPACING;
      const yTickPositions = Array.from({length: MAX_Y_TICKS})
        .map((_, idx) => idx * yTickFactor)
        .filter((tick) => tick <= yMaxValue + yTickFactor);
      const yAxis = [
        {
          label: 'Latency (ms)',
          labelXOffset: -5,
          plotline,
          plotlineColor,
          tickPositions: yTickPositions,
          titleOffset: 50,
          opposite: false,
          yAxis: 0
        }
      ];

      const yAxisFormatter = (label: any) => {
        if (yAxisMax && label.value >= yAxisMax) {
          return ``;
        }
        return formatAvgPingLatencyValue(label.value, FORMAT_VALUE_NO_UNITS);
      };

      // Series
      let rawYAxisData = latencies.map((average_latency) => {
        return {
          y: yAxisMax && pingLatencyTarget < average_latency ? yAxisMax : average_latency,
          actual_latency: average_latency
        };
      });

      let {xAxisData, yAxisData} = adjustCategorizedChartData(
        rawXAxisData,
        rawYAxisData,
        chartTimeSettings,
        1,
        chartTimeSettings.tickIntervalMinutes / 2
      );

      const series = [
        {
          name: PING_LATENCY_SERIES_NAME,
          data: yAxisData
        }
      ];

      // Set the x-axis tick mark positions based on the adjusted x-axis data
      const xAxisTickPositions = getXAxisTickPositions(xAxisData, chartTimeSettings);

      // Tooltips
      const htmlTooltip = true;
      const tooltip = (i18n: any) => (input: any) => {
        const {
          x,
          point: {actual_latency}
        } = input;

        if (Number(x) < 1e12) {
          return null;
        }
        return {
          label: {
            value: <ToolTipDateTimeLabel dateTime={x} />
          },
          points: [
            {
              value: <PingLatencyToolTip prefixIconColor={PING_LATENCY_LINE_COLOR} avgPingLatency={actual_latency} />
            }
          ],
          color: false
        };
      };

      // Handle noData Options
      const noDataOptions = {
        enabled: true,
        showEmptyXAxis: false,
        showEmptyYAxis: false
      };

      setChartConfig({
        id,
        endOnTick,
        highchartsIdBase,
        chartType,
        height,
        marginLeft,
        marginRight,
        defaultChartPalette,
        palette,
        connectNulls,
        disableMarker,
        plotline,
        plotlineColor,
        plotlineType,
        xAxisData,
        xAxisLabelPlotlines,
        xAxisTickPositions,
        xAxisLabelXOffset,
        xAxisFormatter,
        yAxis,
        yAxisMax,
        yAxisFormatter,
        series,
        yAxisData,
        htmlTooltip,
        tooltip,
        liveMask,
        noDataOptions
      });
    }
    // eslint-disable-next-line
  }, [pingLatency, chartTimeSettings]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer id="pingLatency">
      {Object.keys(formattedConfig).length === 0 ? <></> : <LineChart {...formattedConfig} />}
    </ChartContainer>
  );
};

export default PingLatency;
