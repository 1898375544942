/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Support Request Popup
 */
import React, {useState} from 'react';
import PopUpModal from '../../../common/Modal';
import {SW_REVISIONS_MODAL_TITLE_COLOR} from '../../../common/theme/Colors';

import {getElementId} from '../../../../utils/ElementIdUtils';
import {WhoAmI} from '../../../../store/queries/common/whoAmIQuery';
import {bizavApiUrl} from '../../../../utils/config';
import ContactDetailsPopup from './ContactDetailsPopup';
import ExistingCasePopup from './ExistingCasePopup';
import IssueDetailsPopup from './IssueDetailsPopup';
import SupportPopup from './SupportPopUp';
import SupportReportPopup from './SupportReportPopup';

interface AircraftStatusSupportPopupProps {
  aircraftDetail: any;
  caseCount: number;
  openSupportModal: boolean;
  setOpenSupportModal: (openModal: boolean) => void;
  openAircraftCaseListModal: boolean;
  setOpenAircraftCaseListModal: (openModal: boolean) => void;
  userDetails: WhoAmI;
}

export interface IFormValues {
  subject: string;
  description: string;
  referenceLink: string;
  aircraftDetail: any;
  requestorPhoneNumber: string;
}

export const formDefaultValues: IFormValues = {
  subject: '',
  description: '',
  referenceLink: '',
  aircraftDetail: {},
  requestorPhoneNumber: ''
};

export interface IRequestStatus {
  isSubmitting: boolean;
  isCompleted: boolean;
  isFailed: boolean;
}

const AircraftStatusSupportPopup: React.FC<AircraftStatusSupportPopupProps> = ({
  aircraftDetail,
  openSupportModal,
  caseCount,
  setOpenSupportModal,
  openAircraftCaseListModal,
  setOpenAircraftCaseListModal,
  userDetails
}) => {
  const [openExistingCasePopUpModal, setExistingCasePopUpModal] = useState<boolean>(false);
  const [openReportedPopUpModal, setOpenReportedPopUpModal] = useState<boolean>(false);
  const [openAddIssuePopUpModal, setOpenAddIssuePopUpModal] = useState<boolean>(false);
  const [openAddContactDetailsPopUpModal, setOpenAddContactDetailsPopUpModal] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<IFormValues>(formDefaultValues);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({
    isSubmitting: false,
    isCompleted: false,
    isFailed: false
  });

  const handleExistingCasesClick = (rowData: any) => {
    if (caseCount === 0) {
      setExistingCasePopUpModal(true);
      setOpenAddContactDetailsPopUpModal(true);
    } else {
      setExistingCasePopUpModal(true);
    }
  };

  const handleNoExistingCasesClick = () => {
    setOpenAddContactDetailsPopUpModal(true);
  };

  const handleYesExistingCasesClick = () => {
    setExistingCasePopUpModal(false);
    setOpenAircraftCaseListModal(true);
  };

  const closeModal = () => {
    setOpenSupportModal(false);
  };
  const sendEmail = async () => {
    try {
      setRequestStatus({isSubmitting: true, isCompleted: false, isFailed: false});
      const userFormResponse = await fetch(`${bizavApiUrl}/aircraftStatus/requestSupport`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formValues
        })
      });

      const data = await userFormResponse.json();
      if (data) {
        setOpenReportedPopUpModal(true);
        setRequestStatus({isSubmitting: false, isCompleted: true, isFailed: false});
      }
    } catch (err) {
      setRequestStatus({isSubmitting: false, isCompleted: false, isFailed: true});
    }
  };

  return (
    <div>
      <PopUpModal
        id={getElementId('aircraft-status', 'list-view', 'support-popup', 'modal')}
        open={!openAircraftCaseListModal}
        title={null}
        titlecolor={SW_REVISIONS_MODAL_TITLE_COLOR}
        borderRadius="16px"
        padding="1px"
      >
        {openSupportModal && !openExistingCasePopUpModal && !openAircraftCaseListModal && (
          <SupportPopup
            caseCount={caseCount}
            setOpenAircraftCaseListModal={setOpenAircraftCaseListModal}
            handleExistingCasesClick={handleExistingCasesClick}
            closeModal={closeModal}
          />
        )}
        {openExistingCasePopUpModal && !openAddContactDetailsPopUpModal && (
          <ExistingCasePopup
            handleNoExistingCasesClick={handleNoExistingCasesClick}
            handleYesExistingCasesClick={handleYesExistingCasesClick}
            closeModal={closeModal}
          />
        )}
        {openAddContactDetailsPopUpModal && !openAddIssuePopUpModal && (
          <ContactDetailsPopup
            setOpenAddIssuePopUpModal={setOpenAddIssuePopUpModal}
            closeModal={closeModal}
            setPhoneNumber={setPhoneNumber}
            userDetails={userDetails}
          />
        )}
        {openAddIssuePopUpModal && !openReportedPopUpModal && (
          <IssueDetailsPopup
            aircraftDetail={aircraftDetail}
            phoneNumber={phoneNumber}
            closeModal={closeModal}
            setFormValues={setFormValues}
            formValues={formValues}
            requestStatus={requestStatus}
            sendEmail={sendEmail}
          />
        )}
        {openReportedPopUpModal && <SupportReportPopup closeModal={closeModal} formValues={formValues} />}
      </PopUpModal>
    </div>
  );
};
export default AircraftStatusSupportPopup;
