/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Component that contains the sort button and options
 */

import React from 'react';
import {useStore} from '../../store/Store';
import {ListGridColumn} from '@viasat/insights-components';
import {getActionTypeWithContext} from '../../../src/store/reducerUtils';
import {ColumnSortAction} from '../../../src/store/reducers/ColumnSortReducer';
import {SortSelector, SortSelectorDirection} from '@viasat/insights-components';

interface ListViewSortContainerProps {
  idPrefix: string;
  storeContext: string;
  columns: ListGridColumn[];
  showColumnGroups?: boolean;
  getQueryFieldForColumn: (column: string) => string;
  disabled: boolean;
}

const ListViewSortContainer: React.FC<ListViewSortContainerProps> = ({
  idPrefix,
  disabled,
  storeContext,
  columns,
  showColumnGroups,
  getQueryFieldForColumn
}: ListViewSortContainerProps) => {
  const {store, dispatch} = useStore();
  const {
    sort: {order, column}
  } = store[storeContext] || {sort: {}};

  const onSortChange = (sortColumnId: string, sortDirection: SortSelectorDirection) => {
    const queryField = getQueryFieldForColumn(sortColumnId);
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, storeContext),
      payload: {
        column: sortColumnId,
        order: sortDirection,
        queryField,
        validColumns: columns.map((col: ListGridColumn) => col.key),
        validQueryFields: columns.map((col: ListGridColumn) => getQueryFieldForColumn(col.key.toString()))
      }
    });
  };

  return (
    <SortSelector
      getFullElementId={(name, type) => `${idPrefix}--sortSelector__${name}-${type}`}
      columns={columns
        .filter((col) => col.sortable)
        ?.map((col) => ({
          id: col.key.toString(),
          showHideGroup: col.showHideGroup,
          title: col.title
        }))}
      showColumnGroups={showColumnGroups}
      isDisabled={disabled}
      sortDirection={order}
      sortColumnId={column}
      onSortChange={onSortChange}
    />
  );
};

export default ListViewSortContainer;
