/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Upload Usage Card on the Flight Details Page
 */

import React from 'react';
import {sumBy} from 'lodash';
import {useIntl} from 'react-intl';
import {ChartCard} from '@viasat/insights-components';
import CallMergeSharpIcon from '@mui/icons-material/CallMergeSharp';
import UploadIcon from '../../common/theme/icons/UploadIcon';
import {ChartTimeSettings, formatDataUsageValue} from '../flightDetailsUtil';
import UploadUsage, {UploadUsageData} from '../charts/UploadUsage';
import {useStore} from '../../../store/Store';
import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';

interface UploadUsageCardProps {
  currentTime: string;
  chartData: UploadUsageData[];
  isLoading: boolean;
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
  draggableProps?: any;
}

const UploadUsageCard = ({
  currentTime,
  chartData,
  isLoading,
  chartTimeSettings,
  parentLeftOffset,
  liveMask,
  draggableProps
}: UploadUsageCardProps) => {
  const intl = useIntl();

  // hide button click handler
  const {dispatch} = useStore();
  const onHideClick = (id) =>
    dispatch({
      type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY,
      payload: {
        hide: true,
        id
      }
    });
  const uploadUsage = !isLoading && chartData?.length ? sumBy(chartData, 'rl_usage') : 0;

  return (
    <div id="UploadUsageCardEvents">
      <ChartCard
        draggableProps={draggableProps}
        onHideClick={onHideClick}
        getFullElementId={(name, type) => `flightDetails--uploadUsage__${name}-${type}`}
        Icon={UploadIcon}
        title="Upload Usage"
        disableHelpIcon={false}
        helpTooltipMessage={intl.formatMessage({id: 'flight_details.upload_usage.title_tooltip'})}
        isLoading={isLoading}
        isError={false}
        chartHeight={175}
        summaries={[
          {
            getFullElementId: (name, type) => `flightDetails--uploadUsage__${name}-${type}`,
            key: 'data-usage-timeline-total-data-usage-uploaded',
            name: 'Uploaded',
            value: uploadUsage < 1 && uploadUsage > 0 ? '< 1 MB' : formatDataUsageValue(uploadUsage, false)
          }
        ]}
        customActions={[
          {
            id: 'mergeDataUsage',
            Icon: CallMergeSharpIcon,
            tooltipText: 'Merge Charts',
            customActionClick: (id: string) => {
              dispatch({
                type: FlightDetailsAction.MERGE_DATA_USAGE_CARD,
                payload: id
              });
            }
          }
        ]}
      >
        <UploadUsage
          currentTime={currentTime}
          isLoading={isLoading}
          uploadUsage={chartData}
          chartTimeSettings={chartTimeSettings}
          parentLeftOffset={parentLeftOffset}
          liveMask={liveMask}
        />
      </ChartCard>
    </div>
  );
};

export default UploadUsageCard;
