/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Combined Usage Card
 */

import ImportExportIcon from '@mui/icons-material/ImportExport';
import {ChartCard} from '@viasat/insights-components';
import {useIntl} from 'react-intl';
import CallSplitSharpIcon from '@mui/icons-material/CallSplitSharp';
import {ChartTimeSettings, formatDataUsageValue} from '../flightDetailsUtil';
import {useStore} from '../../../store/Store';
import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';
import CombinedUsageChart, {CombinedUsageList} from '../charts/CombinedDataUsage';
import {sumBy} from 'lodash';

interface CombinedUsageCardProps {
  currentTime: string;
  chartData: CombinedUsageList;
  isLoading: boolean;
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
  draggableProps?: any;
}

const CombinedUsageCard = ({
  currentTime,
  chartData,
  isLoading,
  chartTimeSettings,
  parentLeftOffset,
  liveMask,
  draggableProps
}: CombinedUsageCardProps) => {
  const intl = useIntl();

  // hide button click handler
  const {dispatch} = useStore();
  const onHideClick = (id) =>
    dispatch({
      type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY,
      payload: {
        hide: true,
        id
      }
    });

  const downloadUsage = !isLoading && chartData?.length ? sumBy(chartData, 'fl_usage') : 0;
  const uploadUsage = !isLoading && chartData?.length ? sumBy(chartData, 'rl_usage') : 0;

  return (
    <div id="CombinedUsageCardEvents">
      <ChartCard
        draggableProps={draggableProps}
        onHideClick={onHideClick}
        getFullElementId={(name, type) => `flightDetails--combinedUsage__${name}-${type}`}
        Icon={ImportExportIcon}
        title={'Data Usage'}
        disableHelpIcon={false}
        helpTooltipMessage={intl.formatMessage({id: 'flight_details.combined_usage.title_tooltip'})}
        isLoading={isLoading}
        isError={false}
        summaries={[
          {
            getFullElementId: (name, type) => `flightDetails--combinedUsage__${name}-${type}`,
            key: 'data-usage-timeline-total-data-usage',
            name: 'Downloaded',
            value: downloadUsage < 1 && downloadUsage > 0 ? '< 1 MB' : formatDataUsageValue(downloadUsage, false)
          },
          {
            getFullElementId: (name, type) => `flightDetails--combinedUsage__${name}-${type}`,
            key: 'data-usage-timeline-total-data-usage',
            name: 'Uploaded',
            value: uploadUsage < 1 && uploadUsage > 0 ? '< 1 MB' : formatDataUsageValue(uploadUsage, false)
          }
        ]}
        customActions={[
          {
            id: 'splitDataUsage',
            Icon: CallSplitSharpIcon,
            tooltipText: 'Split Chart',
            customActionClick: (id: string) => {
              dispatch({
                type: FlightDetailsAction.SPLIT_DATA_USAGE_CARD,
                payload: id
              });
            }
          }
        ]}
      >
        <CombinedUsageChart
          currentTime={currentTime}
          isLoading={isLoading}
          combinedUsage={chartData}
          chartTimeSettings={chartTimeSettings}
          parentLeftOffset={parentLeftOffset}
          liveMask={liveMask}
        />
      </ChartCard>
    </div>
  );
};

export default CombinedUsageCard;
