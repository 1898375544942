/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Download/Upload ToolTip Style and Formatting
 */

import React from 'react';
import {ColorProperty} from 'csstype';

import SeriesPrefixIcon from '../../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesLabel from '../../../common/elements/chart/ToolTip/SeriesLabel';
import SeriesValue from '../../../common/elements/chart/ToolTip/SeriesValue';

import {formatDataUsageValue} from '../../flightDetailsUtil';

export interface DownloadUploadCardToolTipProps {
  prefixIconColor?: ColorProperty;
  rateType: number | string;
  avgRate: number;
}

const DownloadUploadCardToolTip: React.FC<DownloadUploadCardToolTipProps> = ({
  prefixIconColor,
  rateType,
  avgRate
}: DownloadUploadCardToolTipProps): JSX.Element => {
  return (
    <>
      <SeriesPrefixIcon color={prefixIconColor} />
      <SeriesLabel label={rateType} width="88px" />
      <SeriesValue value={formatDataUsageValue(avgRate)} width="51px" />
    </>
  );
};

export default DownloadUploadCardToolTip;
