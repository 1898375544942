/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Data Usage Query and data transformation of raw data usage data
 */
import {Query} from '../types';

export interface IDataUsage {
  timestamp: string;
  cumulative_usage: number;
  fl_usage: number;
  rl_usage: number;
}

export interface IDataUsageRaw {
  cumulative_usage: number;
  end_timestamp: string;
  fl_usage: number;
  fl_usage_rate: number;
  rl_usage: number;
  rl_usage_rate: number;
  serial_number: string;
  timestamp: string;
}

/**
 * Transforms the given raw data Usage info data into (regular) data usage info data for chart
 * @param dataUsageRawData dataUsageRawData values from the api
 * @returns dataUsage Transformed dataUsage info
 */
const transformRawDataUsageInfo = (dataUsageRawData: IDataUsageRaw[] | null): IDataUsage[] | null => {
  if (!dataUsageRawData) return null;

  const dataUsageInfo: IDataUsage[] = dataUsageRawData.map((dataUsage) => ({
    timestamp: dataUsage.timestamp,
    cumulative_usage: dataUsage.cumulative_usage,
    fl_usage: dataUsage.fl_usage,
    rl_usage: dataUsage.rl_usage
  }));

  return dataUsageInfo;
};

export const useDataUsageQuery: Query<IDataUsage[]> = {
  route: `flightDetails/dataUsage`,
  transform: transformRawDataUsageInfo
};

export default useDataUsageQuery;
