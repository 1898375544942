/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tooltip Label used for the Title
 */

import React from 'react';
import {FontSizeProperty, FontWeightProperty, LineHeightProperty, TextAlignProperty, WidthProperty} from 'csstype';
import {FONT_WEIGHT_NORMAL, TOOLTIP_LABEL_FONT_SIZE, TOOLTIP_LABEL_LINE_HEIGHT} from '../../../../../utils/constants';

export interface ToolTipLabelProps {
  children?: any;
  fontSize?: FontSizeProperty<string>;
  fontWeight?: FontWeightProperty;
  lineHeight?: LineHeightProperty<string>;
  textAlign?: TextAlignProperty;
  width?: WidthProperty<string>;
}

const ToolTipLabel: React.FC<ToolTipLabelProps> = ({
  children = undefined,
  fontSize = TOOLTIP_LABEL_FONT_SIZE,
  fontWeight = FONT_WEIGHT_NORMAL,
  lineHeight = TOOLTIP_LABEL_LINE_HEIGHT,
  textAlign = 'left',
  width = undefined
}: ToolTipLabelProps): JSX.Element => {
  return (
    <div
      style={{
        display: 'inline-block',
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        textAlign: textAlign,
        overflow: 'hidden',
        width: width,
        verticalAlign: 'middle'
      }}
    >
      {children}
    </div>
  );
};

export default ToolTipLabel;
