/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Query utils
 */

import moment from 'moment';
import {DATE_TIME_FORMAT_WITH_MILLISECONDS} from '../../utils/DateTimeUtils';
import {useMemo, useState} from 'react';
import {isEqual} from 'lodash';

import {FilterType, distillFilters} from '../../utils/filterUtils';
import {FilterRow} from '../reducers/FiltersReducer';
import {BAND_KU} from '../../utils/constants';

/**
 * Builds Inputs w/ start and end date for a query
 * @param groupCode Group Code
 * @param startDate Start Date
 * @param endDate End Date
 * @returns Returns Inputs for the query
 */
export const useQueryInputsDateRange = (groupCode: string, startDate: string, endDate: string) =>
  useMemo(() => {
    if (!groupCode || !startDate || !endDate) return undefined;

    return {
      groupCode,
      startTstamp: moment.utc(startDate).toISOString(),
      endTstamp: moment.utc(endDate).toISOString()
    };
  }, [groupCode, startDate, endDate]);

/**
 * Adds sorting params into the params object
 * @param params Initial params object
 * @param sortBy queryField to sort by
 * @param sortOrder Sort order
 * @returns Returns Inputs for the query with sorting
 */
export const useSortingParams = (params: Object, sortBy: string, sortOrder: string) =>
  useMemo(() => {
    if (!params) {
      return undefined;
    }
    return {...params, sortBy, sortOrder};
  }, [params, sortBy, sortOrder]);

/**
 * Builds query params for tail and satellite info
 * @param aircraftId Aircraft identifier
 * @param groupCode Group Code
 * @param startTstamp Request Start time
 * @param endTstamp Request End time
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForTailAndSatelliteInfo = (
  aircraftId: string,
  groupCode: string,
  startTstamp: string,
  endTstamp: string,
  isDark: boolean,
  kaTerminalSwVersion: string,
  kuTerminalSwVersion: string
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTstamp || !endTstamp) return undefined;
    return {
      aircraftId,
      groupCode,
      startTstamp,
      endTstamp,
      isDark,
      kaTerminalSwVersion: kaTerminalSwVersion,
      kuTerminalSwVersion: kuTerminalSwVersion
    };
  }, [aircraftId, groupCode, startTstamp, endTstamp, isDark, kaTerminalSwVersion, kuTerminalSwVersion]);

/**
 * Builds Inputs w/ timestamp for a url param
 * @param groupCode Group Code
 * @param currentTstamp Current Timestamp
 * @returns Returns Inputs for the query
 */
export const useQueryInputsWithCurrentTimestamp = (groupCode: string, currentTstamp: string) =>
  useMemo(() => {
    if (!groupCode || !currentTstamp) return undefined;
    return {
      currentTstamp: currentTstamp,
      groupCode
    };
  }, [groupCode, currentTstamp]);

/**
 * Builds Inputs w/ serial number for a url param
 * @param groupCode Group Code
 * @returns Returns Inputs for the query
 */
export const useQueryInputsWithGroupCode = (groupCode: string) =>
  useMemo(() => {
    if (!groupCode) return undefined;
    return {
      groupCode
    };
  }, [groupCode]);

/**
 * Adds filtering params into the params object
 * @param params Initial params object
 * @param filterTypes Currently active filter types
 * @param filters Current filter values to be applied
 * @returns Inputs for the query with filtering
 */
export const useFilteringParams = (
  params: Object | undefined,
  filterTypes: FilterType[] | undefined,
  filters: FilterRow[] | undefined
) => {
  const [inputs, setInputs] = useState({params: undefined, filterTypes: undefined, filters: undefined});

  if (
    !isEqual(params, inputs.params) ||
    !isEqual(filterTypes, inputs.filterTypes) ||
    !isEqual(filters, inputs.filters)
  ) {
    setInputs({params, filterTypes, filters});
  }

  return useMemo(() => {
    if (!inputs.params || !inputs.filterTypes) {
      return undefined;
    }

    const distilledFilters = inputs.filters ? distillFilters(inputs.filterTypes, inputs.filters) : {};

    return {...inputs.params, filters: distilledFilters};
  }, [inputs.params, inputs.filterTypes, inputs.filters]);
};

/**
 * Builds Inputs w/ start time including group code for a query
 * @param groupCode Group Code
 * @param aircraftId Aircraft identifier
 * @param startTime Request Start time
 * @param endTime request end time
 * @param isLanded landed detection flag
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForPlAndTc = (
  groupCode: string,
  aircraftId: string,
  startTime: string,
  endTime: string,
  isLanded: boolean,
  isDark: boolean
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTime || !endTime) return undefined;

    return {
      groupCode,
      aircraftId,
      startTstamp: startTime,
      endTstamp: endTime,
      isLanded,
      isDark
    };
  }, [groupCode, aircraftId, startTime, endTime, isLanded, isDark]);

/**
 * Builds Inputs w/ start time including group code for a query
 * @param aircraftId Aircraft identifier
 * @param groupCode Group Code
 * @param startTime Request Start time
 * @param endTime request end time
 * @param isLanded landed detection flag
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForDataUsage = (
  aircraftId: string,
  startTime: string,
  endTime: string,
  isLanded: boolean,
  groupCode: string,
  isDark: boolean
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTime || !endTime) return undefined;

    return {
      aircraftId,
      startTstamp: moment.utc(startTime).format(DATE_TIME_FORMAT_WITH_MILLISECONDS).toString(),
      endTstamp: moment.utc(endTime).format(DATE_TIME_FORMAT_WITH_MILLISECONDS).toString(),
      isLanded,
      groupCode,
      isDark
    };
  }, [aircraftId, groupCode, startTime, endTime, isLanded, isDark]);

/**
 * Builds Inputs w/ flight path params for flight path query
 * @param groupCode group code
 * @param aircraftId Aircraft identifier
 * @param startTstamp start time
 * @param endTstamp end time
 * @param isLanded to find flight land
 * @param flightId to find future flight path
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForFlightPath = (
  groupCode: string,
  aircraftId: string,
  startTstamp: string,
  endTstamp: string,
  isLanded: boolean,
  flightId: string,
  origin: string,
  destination: string,
  isDark: boolean
) =>
  useMemo(() => {
    if (!groupCode || !aircraftId || !startTstamp || !endTstamp) return undefined;

    return {
      aircraftId,
      groupCode,
      startTstamp: startTstamp,
      endTstamp: endTstamp,
      isLanded,
      flightId,
      origin,
      destination,
      isDark
    };
  }, [groupCode, aircraftId, startTstamp, endTstamp, isLanded, flightId, origin, destination, isDark]);

/**
 * Adds sorting params into the params object
 * @param params Initial params object
 * @param limit Returned result limit
 * @returns Returns Inputs for the query with limit
 */
export const useLimitParam = (params: Object, limit: number) =>
  useMemo(() => {
    if (!params) {
      return undefined;
    }
    return {...params, limit};
  }, [params, limit]);

/**
 * Builds Inputs w/ start time including group code for a query
 * @param aircraftId Aircraft identifier
 * @param groupCode Group Code
 * @param startTstamp Request Start time
 * @param endTstamp request end time
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForMonthlyUsage = (
  groupCode: string,
  aircraftId: string,
  startTstamp: string,
  endTstamp: string
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTstamp || !endTstamp) return undefined;

    return {
      groupCode,
      aircraftId,
      startTstamp,
      endTstamp
    };
  }, [groupCode, aircraftId, startTstamp, endTstamp]);

/**
 * Builds Inputs w/ flight entry params for flight entry query
 * @param groupCode Group Code
 * @param flightId Flight Identifier
 * @returns Returns inputs for the query
 */
export const useQueryInputsForFlightEntry = (groupCode: string, flightId: string, nowTimestamp: string) =>
  useMemo(() => {
    if (!groupCode || !flightId || !nowTimestamp) return undefined;
    return {
      groupCode: groupCode,
      flightId: flightId,
      nowTimestamp: nowTimestamp
    };
  }, [groupCode, flightId, nowTimestamp]);

/**
 * Adds newly added/deleted flight plans count param into the params object
 * @param params Initial params object
 * @param newFlightPlansCount not an actual query field but used to update the store and refresh the list
 * @param deletedFlightPlansCount not an actual query field but used to update the store and refresh the list
 * @returns Returns Inputs for the query with new flight plans count
 */
export const useFlightsPlansCount = (
  params: Object,
  newFlightPlansCount: number,
  deletedFlightPlansCount: number,
  refreshFlightPlans: boolean
) =>
  useMemo(() => {
    if (!params) {
      return undefined;
    }
    return {...params, newFlightPlansCount, deletedFlightPlansCount, refreshFlightPlans};
  }, [params, newFlightPlansCount, deletedFlightPlansCount, refreshFlightPlans]);

/**
 * Builds Inputs w/ start and end time including group code for a query
 * @param aircraftId Aircraft identifier
 * @param groupCode Group Code
 * @param startTstamp Request Start time
 * @param endTstamp request end time
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForAntennaQueryInfo = (
  groupCode: string,
  aircraftId: string,
  startTstamp: string,
  endTstamp: string,
  isDark: boolean,
  networkCapability: string
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTstamp || !endTstamp || networkCapability === BAND_KU) return undefined;

    return {
      groupCode,
      aircraftId,
      startTstamp,
      endTstamp,
      isDark
    };
  }, [groupCode, aircraftId, startTstamp, endTstamp, isDark, networkCapability]);

/**
 * Builds Inputs w/ start time including group code for a query
 * @param aircraftId Aircraft identifier
 * @param groupCode Group Code
 * @param startTstamp Request Start time
 * @param endTstamp request end time
 * @param isLanded checks if flight is landed
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForInFlightUsage = (
  groupCode: string,
  aircraftId: string,
  startTstamp: string,
  endTstamp: string,
  isLanded: boolean,
  isRegionalPlan: boolean,
  isDark: boolean
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTstamp || !endTstamp || !isLanded || !isRegionalPlan) return undefined;

    return {
      groupCode,
      aircraftId,
      startTstamp,
      endTstamp,
      isLanded,
      isDark
    };
  }, [groupCode, aircraftId, startTstamp, endTstamp, isLanded, isRegionalPlan, isDark]);

/**
 * Builds query inputs for flight path roaming usage
 * @param aircraftId Aircraft identifier
 * @param groupCode Group Code
 * @param startTstamp Request Start time
 * @param endTstamp request end time
 * @param isLanded checks if flight is landed
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForFlightPathRoamingUsage = (
  groupCode: string,
  aircraftId: string,
  startTstamp: string,
  endTstamp: string,
  isLanded: boolean
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTstamp || !endTstamp || !isLanded) return undefined;

    return {
      groupCode,
      aircraftId,
      startTstamp,
      endTstamp,
      isLanded
    };
  }, [groupCode, aircraftId, startTstamp, endTstamp, isLanded]);

/**
 * Builds Inputs w/ start and end date for a query
 * @param groupCode Group Code
 * @param startDate Start Date
 * @param endDate End Date
 * @returns Returns Inputs for the query
 */
export const useQueryInputsDateRangeInternalUsers = (
  groupCode: string,
  startDate: string,
  endDate: string,
  isInternal: boolean
) =>
  useMemo(() => {
    if (!groupCode || !startDate || !endDate || !isInternal) return undefined;

    return {
      groupCode,
      startTstamp: moment.utc(startDate).toISOString(),
      endTstamp: moment.utc(endDate).toISOString()
    };
  }, [groupCode, startDate, endDate, isInternal]);

/**
 * Builds Inputs w/ start and end date Handover failure query
 * @param groupCode Group Code
 * @param aircraftId Aircraft identifier
 * @param tailId Tail Id
 * @param startTstamp Start Date
 * @param endTstamp End Date
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForHandoverFailures = (
  groupCode: string,
  aircraftId: string,
  startTstamp: string,
  endTstamp: string
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTstamp || !endTstamp) return undefined;

    return {
      groupCode,
      aircraftId,
      startTstamp: moment.utc(startTstamp).toISOString(),
      endTstamp: moment.utc(endTstamp).toISOString()
    };
  }, [groupCode, aircraftId, startTstamp, endTstamp]);

  /**
 * Builds Inputs w/ start and end date disconnected events query
 * @param groupCode Group Code
 * @param aircraftId Aircraft identifier
 * @param startTstamp Start Date
 * @param endTstamp End Date
 * @returns Returns Inputs for the query
 */
export const useQueryInputsForDisconnectedEvents = (
  groupCode: string,
  aircraftId: string,
  startTstamp: string,
  endTstamp: string
) =>
  useMemo(() => {
    if (!aircraftId || !groupCode || !startTstamp || !endTstamp) return undefined;

    return {
      groupCode,
      aircraftId,
      startTstamp: moment.utc(startTstamp).toISOString(),
      endTstamp: moment.utc(endTstamp).toISOString()
    };
  }, [groupCode, aircraftId, startTstamp, endTstamp]);
