/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Cancel Token reducer
 */

import {CancelTokenSource} from 'axios';
import {findIndex} from 'lodash';

import {delegateToReducers} from '../Reducer';

export const CANCEL_TOKEN_STORE_CONTEXT = 'cancelToken';

interface tokenInt {
  id?: number;
  token: CancelTokenSource;
}
export interface CancelTokenState {
  cancelTokenSource: tokenInt[];
}

export const InitialCancelTokenState: CancelTokenState = {
  cancelTokenSource: []
};

export enum CancelTokenAction {
  SET_CANCEL_TOKEN_SOURCE = 'SET_CANCEL_TOKEN_SOURCE',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  REMOVE_TOKEN = 'REMOVE_TOKEN'
}

export const CancelTokenStoreKeys: string[] = [];

/**
 * Cancel Token action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const CancelTokenReducer = (state: CancelTokenState, action: any): CancelTokenState => {
  switch (action.type) {
    case CancelTokenAction.SET_CANCEL_TOKEN_SOURCE:
      const tokenListIndex = findIndex(state.cancelTokenSource, {
        id: action.payload.fileId
      });
      if (tokenListIndex === -1) {
        state.cancelTokenSource.push(action.payload);
      }
      return state;

    case CancelTokenAction.CANCEL_REQUEST: {
        const tokenListIndex = findIndex(state.cancelTokenSource, {id: action.payload?.fileId});
        if (tokenListIndex > -1) {
          state.cancelTokenSource[tokenListIndex].token.cancel('cancel');
        }
      }
      return state;

    case CancelTokenAction.REMOVE_TOKEN:
      const tokenList = action.payload.emptyList
        ? []
        : state.cancelTokenSource.filter((token) => token.id !== action.payload.fileId);

      return {...state, cancelTokenSource: tokenList};

    default:
      break;
  }

  state = delegateToReducers(CANCEL_TOKEN_STORE_CONTEXT, state, action, {}, InitialCancelTokenState);

  return state;
};
