/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initializes a custom hook for a store provider
 */

import React, {createContext, useContext, useMemo, useReducer, useRef, useEffect} from 'react';
import {isEqual, get, set, has, isNil} from 'lodash';
import {IContext, IStore, IStoreProvider} from './types';
import {_getStoreDataPersistKey} from '../utils/StoreUtils';

import {INIT_STORE_CONTEXT, InitialInitState, InitSessionStoreKeys} from './reducers/InitReducer';
import {APP_STORE_CONTEXT, InitialAppState} from './reducers/AppReducer';
import {CUSTOMER_STORE_CONTEXT, InitialCustomerState, CustomerSessionStoreKeys} from './reducers/CustomerReducer';
import {
  DATE_PICKER_STORE_CONTEXT,
  InitialDatePickerState,
  DatePickerSessionStoreKeys
} from './reducers/DatePickerReducer';
import {FLEET_MAP_STORE_CONTEXT, InitialFleetMapState, FleetMapSessionStoreKeys} from './reducers/FleetMapReducer';
import {
  CONNECTIVITY_PLANNER_STORE_CONTEXT,
  InitialConnectivityPlannerState,
  ConnectivityPlannerSessionStoreKeys
} from './reducers/ConnectivityPlannerReducer';
import {
  FLIGHT_LIST_STORE_CONTEXT,
  InitialFlightListState,
  FlightListSessionStoreKeys,
  filterNotPersistedSessionStoreKeys
} from './reducers/FlightListReducer';
import {
  FLIGHT_DETAILS_STORE_CONTEXT,
  InitialFlightDetailsState,
  FlightDetailsSessionStoreKeys
} from './reducers/FlightDetailsReducer';
import {
  AircraftStatusListSessionStoreKeys,
  AIRCRAFT_STATUS_LIST_STORE_CONTEXT,
  InitialAircraftStatusListState
} from './reducers/AircraftStatusListReducer';
import {
  SummaryMetricsSessionStoreKeys,
  SUMMARY_METRICS_STORE_CONTEXT,
  InitialSummaryMetricsState
} from './reducers/SummaryMetricsReducer';
import {TAIL_LIST_STORE_CONTEXT, InitialTailListState, TailListSessionStoreKeys} from './reducers/TailListReducer';

interface IStoreItems {
  name: string;
  sessionKeys: string[];
  initialState: any;
  localDataMutate: (data: any) => string;
}

/**
 * Store Items Usage
 * localDataMutate: affects copy to Local Storage only
 */
const storeItems: IStoreItems[] = [
  {
    name: INIT_STORE_CONTEXT,
    sessionKeys: InitSessionStoreKeys,
    initialState: InitialInitState,
    localDataMutate: null
  },
  {
    name: APP_STORE_CONTEXT,
    sessionKeys: [],
    initialState: InitialAppState,
    localDataMutate: null
  },
  {
    name: CUSTOMER_STORE_CONTEXT,
    sessionKeys: CustomerSessionStoreKeys,
    initialState: InitialCustomerState,
    localDataMutate: null
  },
  {
    name: DATE_PICKER_STORE_CONTEXT,
    sessionKeys: DatePickerSessionStoreKeys,
    initialState: InitialDatePickerState,
    localDataMutate: null
  },
  {
    name: FLEET_MAP_STORE_CONTEXT,
    sessionKeys: FleetMapSessionStoreKeys,
    initialState: InitialFleetMapState,
    localDataMutate: null
  },
  {
    name: FLIGHT_LIST_STORE_CONTEXT,
    sessionKeys: FlightListSessionStoreKeys,
    initialState: InitialFlightListState,
    localDataMutate: (data: any) => filterNotPersistedSessionStoreKeys(data)
  },
  {
    name: FLIGHT_DETAILS_STORE_CONTEXT,
    sessionKeys: FlightDetailsSessionStoreKeys,
    initialState: InitialFlightDetailsState,
    localDataMutate: null
  },
  {
    name: CONNECTIVITY_PLANNER_STORE_CONTEXT,
    sessionKeys: ConnectivityPlannerSessionStoreKeys,
    initialState: InitialConnectivityPlannerState,
    localDataMutate: null
  },
  {
    name: AIRCRAFT_STATUS_LIST_STORE_CONTEXT,
    sessionKeys: AircraftStatusListSessionStoreKeys,
    initialState: InitialAircraftStatusListState,
    localDataMutate: (data: any) => filterNotPersistedSessionStoreKeys(data)
  },
  {
    name: SUMMARY_METRICS_STORE_CONTEXT,
    sessionKeys: SummaryMetricsSessionStoreKeys,
    initialState: InitialSummaryMetricsState,
    localDataMutate: null
  },
  {
    name: TAIL_LIST_STORE_CONTEXT,
    sessionKeys: TailListSessionStoreKeys,
    initialState: InitialTailListState,
    localDataMutate: (data: any) => filterNotPersistedSessionStoreKeys(data)
  }
];

/**
 * Loads up the session store keys
 * @param prevState Previous State
 * @param currentState Current State
 */
const setSessionStore = (prevState: any, currentState: any) =>
  storeItems.forEach((storeItem) =>
    setSessionStoreKeys(storeItem.name, storeItem.sessionKeys, prevState, currentState)
  );

/**
 * Gets a JSON value from session storage, else local storage or null
 * @param storeDataPersistKey Store Persist Key
 * @returns JSON value from session storage/local storage/null
 */
const getStorageData = (storeDataPersistKey: string) => {
  const sessionData = sessionStorage.getItem(storeDataPersistKey);
  if (!isNil(sessionData)) return JSON.parse(sessionData);

  const localData = localStorage.getItem(storeDataPersistKey);

  // Let's only mess with things if session storage is empty.
  if (sessionStorage.length === 0) {
    copyLocalToSessionStorage();
  }

  return isNil(localData) ? null : JSON.parse(localData);
};

/**
 * Copy the store from local storage to session storage. We only want
 * this to happen when you open a new window/tab.
 */
const copyLocalToSessionStorage = () => {
  storeItems.forEach((storeItem) => {
    const persistKey = _getStoreDataPersistKey(storeItem.name);
    sessionStorage.setItem(persistKey, localStorage.getItem(persistKey));
  });
};

/**
 * Sets the value in session storage and updates all of local storage
 * Excluding those on the local storage exception list
 * @param storeDataPersistKey Store Data Persist Key
 * @param value New value
 */
const setStorageData = (storeDataPersistKey: string, value: any) => {
  sessionStorage.setItem(storeDataPersistKey, JSON.stringify(value));

  // By only writing one thing into local storage, we were getting
  // different things from different windows when opening a new
  // window. So, I make it so we get everything from the current window
  // in the new window.
  storeItems.forEach((storeItem) => {
    const persistKey = _getStoreDataPersistKey(storeItem.name);
    const sessionData = sessionStorage.getItem(persistKey);
    const updateData = storeItem.localDataMutate ? storeItem.localDataMutate(sessionData) : sessionData;

    localStorage.setItem(persistKey, updateData);
  });
};

function createInitialReducerState<T extends object>(storeKey: string, saveKeys: string[], initialData: T): T {
  try {
    const initialClone = JSON.parse(JSON.stringify(initialData));
    const storeDataPersistKey = _getStoreDataPersistKey(storeKey);
    const sessionContextData = getStorageData(storeDataPersistKey);

    if (sessionContextData === null) {
      return initialClone;
    }
    return saveKeys.reduce(
      (memo: T, key: string) => (has(sessionContextData, key) ? set(memo, key, get(sessionContextData, key)) : memo),
      initialClone
    );
  } catch (error) {
    console.error(`Failed to create initial reducer state (${storeKey}): ${error.toString()}`);
  }
  return initialData;
}

const setSessionStoreKeys = (storeKey: string, sessionKeys: string[], prevState: any, currentState: any) => {
  let update = false;
  const newSessionState = sessionKeys.reduce((memo: any, sessionKey: string) => {
    const prevStateData = get(prevState[storeKey], sessionKey);
    const currStateData = get(currentState[storeKey], sessionKey);
    if (!isEqual(prevStateData, currStateData)) {
      update = true;
      return set(memo, sessionKey, currStateData);
    }
    return memo;
  }, {});

  if (update) {
    try {
      const storeDataPersistKey = _getStoreDataPersistKey(storeKey);
      const sessionContextData = getStorageData(storeDataPersistKey);
      const updateData = sessionKeys.reduce((memo: any, sessionKey: string) => {
        if (has(newSessionState, sessionKey)) {
          return set(memo, sessionKey, get(newSessionState, sessionKey));
        }
        return set(memo, sessionKey, get(sessionContextData, sessionKey));
      }, {});

      setStorageData(storeDataPersistKey, updateData);
    } catch (error) {
      console.error(`Failed to set session store keys (${storeKey}): ${error.toString()}`);
    }
  }
};

export const useSessionStoreReducer = (reducer, initialState) => {
  let prevState = useRef(initialState);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setSessionStore(prevState.current, state);
    prevState.current = state;
  }, [state]);

  return [state, dispatch];
};

const setInitialStateFromLocalStore = (): IStore => {
  return storeItems.reduce((sum, storeItem) => {
    return {
      ...sum,
      [storeItem.name]: createInitialReducerState(storeItem.name, storeItem.sessionKeys, storeItem.initialState)
    };
  }, {}) as IStore;
};

const StoreContext = createContext<IContext>({
  store: setInitialStateFromLocalStore(),
  dispatch: () => {}
});

export const StoreProvider = ({reducer, children}: IStoreProvider) => {
  const initialState: IStore = setInitialStateFromLocalStore();

  const reInit = (state: IStore, action: any) =>
    action.type === 'init' ? setInitialStateFromLocalStore() : reducer(state, action);
  const [store, dispatch] = useSessionStoreReducer(reInit, initialState);

  const value = useMemo(
    () => ({
      store,
      dispatch
    }),
    // eslint-disable-next-line
    [store]
  ) as IContext;

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export const useStore = (): IContext => useContext(StoreContext);
