/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: list utils
 */

import {AirportCodeTooltip, CellRenderer} from '@viasat/insights-components';
import {FilterOptionsType, convertObjectToFilterRangeOptions} from '../../utils/filterUtils';

export const UNKNOWN_COLUMN_ID_NAME = 'UNKNOWN_COLUMN_ID_NAME';

export interface CellMetadataProps {
  cellData: any;
  row: any;
  value?: any;
}

export interface IHandleSortProps {
  column: string;
  order: string;
}

/**
 * Get the Name of a Column Id Enum Key
 * @param columnIdEnum key to convert to name
 * @returns name of enum as string, otherwise UNKNOWN_COLUMN_ID_NAME
 */
export const getColumnIdEnumName = (enumType: any, columnIdEnum: any): string =>
  columnIdEnum.hasOwnProperty(enumType) ? columnIdEnum[enumType] : UNKNOWN_COLUMN_ID_NAME;

/**
 * Gets the full title for a column given a prefix and column title
 * @param fullTitlePrefix the full title prefix
 * @param title the column title
 * @returns the column's full title
 */
export const getColumnFullTitle = (fullTitlePrefix: string, title: string): string => `${fullTitlePrefix} ${title}`;

/**
 * Gets the HTML-formatted full title for a column given a prefix and a column title
 * @param fullTitlePrefix the full title prefix
 * @param title the column title
 * @returns the formatted full title for the column
 */
export const getColumnFormattedFullTitle = (fullTitlePrefix: string, title: string): JSX.Element => (
  <>
    {fullTitlePrefix}&nbsp;
    <b>{title}</b>
  </>
);

// Default Separator is false, which does not display thousands separator
export const FORMAT_VALUE_DEFAULT_NO_SEPARATOR = false;
// Default PadStart is false, which does not display leading zeroes
export const FORMAT_VALUE_DEFAULT_NO_PAD_START_ZEROES = 0;
// Use `--` to represent NoData (null, isNaN, !isFinite)
export const FORMAT_VALUE_NULL_REPRESENTATION = '--';
// Default Precision is 0
export const FORMAT_VALUE_DEFAULT_PRECISION = 0;
// Default Units is null, represents no units or empty string
export const FORMAT_VALUE_DEFAULT_UNITS = null;
// Default Locale is undefined, uses host default language
const FORMAT_VALUE_DEFAULT_LOCALE = undefined;

/**
 * Value formatter, defaults to `--` for null, NaN, or non-Finite values
 * ie. '98.7%', '0.654 MB', '3,210', '12', or '--'
 *
 * @param value Number to format
 * @param precision Number of digits after decimal point [0..20] (optional, default=0)
 * @param units Units for value, include spacing if appropriate ie. '%' or ' ms' (optional, default=null)
 * @param separator Use separator locale for thousands (optional, default=false)
 * @param padStartZeroes Number of leading zeroes to pad (optional, default=0)
 * @return Formatted value as a string
 */
export const formatValue = (
  value: number,
  precision: number = FORMAT_VALUE_DEFAULT_PRECISION,
  units: string = FORMAT_VALUE_DEFAULT_UNITS,
  separator: boolean = FORMAT_VALUE_DEFAULT_NO_SEPARATOR,
  padStartZeroes: number = FORMAT_VALUE_DEFAULT_NO_PAD_START_ZEROES
): string => {
  // Handle null, NaN, or !Finite values as '--'
  if (value === undefined || value === null || isNaN(value) || !isFinite(value))
    return FORMAT_VALUE_NULL_REPRESENTATION;

  // Validate precision [0..20]
  if (precision < 0) precision = 0;
  else if (precision > 20) precision = 20;

  // Format value with separator if specified and/or precision specified as a string
  // Locale options precision specifies the number of digits after decimal point
  const localeOptions = {minimumFractionDigits: precision, maximumFractionDigits: precision};
  let formattedValue = separator
    ? value.toLocaleString(FORMAT_VALUE_DEFAULT_LOCALE, localeOptions)
    : value.toFixed(precision);

  // Format value with padding leading zeroes
  formattedValue = padStartZeroes > 0 ? formattedValue.padStart(padStartZeroes, '0') : formattedValue;

  // Format value with units if specified
  const formattedUnits = units !== null ? units : '';

  return `${formattedValue}${formattedUnits}`;
};

/**
 * Render function for Airport Tooltip Cells
 * @param cellProps props for the cell renderer
 * @return AirportCodeTooltip component
 */
export const AirportTooltipCellRenderer: CellRenderer = ({cellData, rowData}) => {
  const {code, airportData} = cellData;
  const {isDisabled} = rowData;

  return (
    <>
      {airportData ? (
        <AirportCodeTooltip
          airportCode={airportData.iataCode}
          airport={airportData}
          getFullElementId={(name: string, type: string) => `${airportData.code}--airportTooltip__${name}-${type}`}
          position={'top'}
          yMargin={'-10px'}
          arrow={true}
          invertColors={false}
          disable={isDisabled}
        >
          {code}
        </AirportCodeTooltip>
      ) : code ? (
        code
      ) : (
        '--'
      )}
    </>
  );
};

/**
 * Converts the given dictionary of airport objects into filter range options
 * @param collection dictionary of airports to convert
 * @param fieldNameMap Field for name in the object of the collection
 * @param fieldValueMap Field for value in the object of the collection
 * @return array of filter range key/value pairs
 */
export const convertAirportValuesToFilterRangeOptions = (
  collection: any,
  fieldValueMap: string,
  fieldNameMap: string
): FilterOptionsType[] => {
  return Object.values(collection)
    .map((airport) => ({
      optionValue: `${airport[fieldValueMap]} - ${airport[fieldNameMap]}`,
      optionKey: airport[fieldValueMap]
    }))
    .sort((a: FilterOptionsType, b: FilterOptionsType): number => {
      return a.optionKey > b.optionKey ? 1 : -1;
    });
};

/**
 * Returns the range options for the origin/destination filter
 * @param props Filter container properties
 * @returns Array of locations Filter key/value pairs
 */
export const getLocationFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.airportsData ? convertAirportValuesToFilterRangeOptions(props.airportsData, 'code', 'name') : [];

/**
 * Returns the range options for the list of service plans filter
 * @param props Filter container properties
 * @returns Array of service plans filter key/value pairs
 */
export const getServicePlansFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.servicePlansData ? convertObjectToFilterRangeOptions(props.servicePlansData, 'servicePlanName') : [];

/**
 * Returns the range options for Customer filter
 * @param props Filter container properties
 * @returns Array of Customer Filter key/value pairs
 */
export const getCustomerFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.endUsersData ? convertObjectToFilterRangeOptions(props.endUsersData, 'name') : [];

/**
 * Returns the range options for the Serial Number filter
 * @param props Filter container properties
 * @returns Array of Serial Number Filter key/value pairs
 */
export const getSerialNumberFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'serialNumber') : [];

/**
 * Returns the range options for the Tail ID filter
 * @param props Filter container properties
 * @returns Array of Tail ID Filter key/value pairs
 */
export const getTailIdFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'tailId') : [];

/**
 * Returns the range options for the Aircraft Type filter
 * @param props Filter container properties
 * @returns Array of Aircraft Type Filter key/value pairs
 */
export const getAircraftTypeFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'aircraftType') : [];

/**
 * Returns the range options for the Network filter
 * @param props Filter container properties
 * @returns Array of Network Filter key/value pairs
 */
export const getNetworkFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'networkCapability') : [];
