/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft Status List component
 */

import React, {useMemo, useEffect, useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {
  DatePickerState,
  HiddenColumnSelector,
  HorizontalTabs,
  ListGridColumn,
  LabeledSwitch,
  LoadingButton
} from '@viasat/insights-components';
import {isNull, sortBy} from 'lodash';

import ListViewComponent from '../../lists/ListViewComponent';

import {TabDefinitions} from '../tabDefinitions';
import {API_REREQUEST_INTERVAL_MS, EID_FLIGHTLIST} from '../../../utils/constants';
import {useStore} from '../../../store/Store';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {
  AircraftStatusListAction,
  AIRCRAFT_STATUS_LIST_STORE_CONTEXT,
  VIEW_TYPE,
  VIEW_COLUMN_TYPE
} from '../../../store/reducers/AircraftStatusListReducer';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import aircraftStatusListQuery, {
  AircraftStatusInfo
} from '../../../store/queries/aircraftStatusList/aircraftStatusListQuery';
import totalOpenCasesQuery, {IOpenCases} from '../../../store/queries/aircraftStatusList/totalOpenCasesQuery';
import endUsersQuery, {EndUser} from '../../../store/queries/common/endUsersQuery';
import {
  useSortingParams,
  useFilteringParams,
  useQueryInputsDateRange,
  useQueryInputsWithCurrentTimestamp,
  useQueryInputsWithGroupCode
} from '../../../store/queries/queryUtils';
import useFetch, {QueryParams} from '../../../utils/useFetch';
import {
  AircraftStatusListColumnId,
  ASLV_DEFAULT_SORT_COLUMN,
  buildAircraftStatusListColumns,
  getAircraftStatusListFilters,
  getQueryFieldMapping,
  IAircraftStatusData,
  SORT_ASC,
  SORT_DESC
} from './util/aircraftStatusListUtil';
import tabDefinitions from '../tabDefinitions';
import tailInfoQuery, {TailInfo} from '../../../store/queries/flightList/tailInfoQuery';
import {getFilterOptions, translateFiltersArrayToObject} from '../../../utils/filterUtils';
import {FilterAction} from '../../../store/reducers/FiltersReducer';
import {DatePickerAction} from '../../../store/reducers/DatePickerReducer';
import {DATE_FORMAT_MM_DD_YYYY_HH_MM, DATE_TIME_FORMAT_WITH_MILLISECONDS} from '../../../utils/DateTimeUtils';
import FilterHookContainer from '../../common/elements/filterSelector/FilterHookContainer';
import ListViewSortContainer from '../../lists/ListViewSortContainer';
import {HideColumnsAction} from '../../../store/reducers/HideColumnsReducer';
import {liveTimeOverrideEnabled} from '../../../utils/config';
import LiveTimeEntry from '../../common/LiveTimeEntry';
import OpenCaseListPopup from './OpenCaseListPopup';
import SoftwareRevisionsPopup from './SoftwareRevisionsPopup';
import SwitchListCardView from '../SwitchListCardView';
import AircraftStatusSupportPopup from './requestSupport/AircraftStatusSupportPopup';
import WatchListSelector from './WatchListSelector';
import ShareButton from '../../common/elements/share/ShareButton';
import {translateHiddenColumnsArrayToObject} from '../hiddenColumnsUtils';
import {SummaryMetricsAction} from '../../../store/reducers/SummaryMetricsReducer';
import ActionItemsList from './ActionItemsList';
import whoAmIQuery, {WhoAmI} from '../../../store/queries/common/whoAmIQuery';
import RequestUpdateStatusPopup from './requestSupport/RequestUpdateStatusPopup';
import airportsQuery from '../../../store/queries/common/airportsQuery';
import {AppAction} from '../../../store/reducers/AppReducer';
import {AirportsType} from '../../../utils/MapUtil';
import SwitchCompactExpandedView from '../SwitchCompactExpandedView';
import {handleExportClick} from '../exportListCSV';
import ListSearch from '../ListSearch';

export interface AircraftStatusListPageProps {
  initialTabState: TabDefinitions;
  isLoading: boolean;
}

const MAX_AIRCRAFT_DISPLAYED = 3000;
// If the total aircraft associated with the logged-in user is less than 5 then show card view as the default one.
const MIN_AIRCRAFT_TO_SWITCH_DEFAULT_VIEW = 5;

const maxRowNotificationMessage = (
  <span>
    We've displayed the <strong>first {MAX_AIRCRAFT_DISPLAYED} aircraft</strong> on the list. You can refine your search
    with additional <strong>filters</strong> or download the full results as a <strong>CSV</strong> file.
  </span>
);

const AircraftStatusListPage: React.FC<AircraftStatusListPageProps> = () => {
  const [validTabs] = useState(tabDefinitions);
  const [selectedAircraft, setSelectedAircraft] = useState<any>(undefined);
  const [openAircraftSwRevisionsModal, setOpenAircraftSwRevisionsModal] = useState<boolean>(false);
  const [lastWatchListItem, setLastWatchListItem] = useState<number>(0);
  const {store, dispatch} = useStore();
  const navigate = useNavigate();
  const datePickerState = store.datePicker;
  const {
    sort,
    filters: {filters = []},
    hideColumns: {hiddenColumns, sharelinkHiddenColumns},
    showListNotification,
    aircraftTotal = 0,
    showCardView,
    showCompactView,
    favoriteAircrafts,
    autoRefresh,
    defaultView,
    defaultColumnView
  } = store.aircraftStatusList;
  const {airports} = store.app;
  const {isInternal} = store.init;

  const idPrefix = 'aircraftStatusList';

  const groupCode = store.customer.current.code ? store.customer.current.code : '';

  // if sharelink hidden columns are populated in session storage, then use, otherwise use hidden columns from local storage
  const currentHiddenColumns = sharelinkHiddenColumns ? sharelinkHiddenColumns : hiddenColumns;

  const [{timeOverrideEnable, timeOverrideValue}, setTimeOverride] = useState({
    timeOverrideEnable: sessionStorage.timeOverrideEnable === 'true' ? true : false,
    timeOverrideValue: sessionStorage.timeOverrideValue ? moment(sessionStorage.timeOverrideValue) : moment.utc()
  });

  const [refreshFromInterval, setRefreshFromInterval] = useState<boolean>(false);
  const [openAircraftCaseListModal, setOpenAircraftCaseListModal] = useState<boolean>(false);
  const [requestUpdateStatus, setRequestUpdateStatus] = useState<boolean>(false);
  const [selectedCaseCount, setSelectedCaseCount] = useState<number>(null);
  const [openActionItems, setOpenActionItems] = useState<boolean>(false);
  const [buttonAnchorEl, setButtonAnchorEl] = useState(null);
  const [openSupportRequestModal, setOpenSupportRequestModal] = useState<boolean>(false);
  const [isSortFilterOnRefresh, setIsSortFilterOnRefresh] = useState<boolean>(false);

  const [timeStamp, setTimestamp] = useState(
    timeOverrideEnable
      ? moment(timeOverrideValue).format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
      : moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
  );

  const updateTimeOverride = (timeOverrideEnable: boolean, timeOverrideValue: moment.Moment) => {
    setTimeOverride({timeOverrideEnable, timeOverrideValue});
    sessionStorage.timeOverrideEnable = timeOverrideEnable;
    sessionStorage.timeOverrideValue = timeOverrideValue;
    setTimestamp(
      timeOverrideEnable
        ? moment(timeOverrideValue).format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
    );
  };
  const [scrollTop, setScrollTop] = useState<number>(-1);

  const queryParams = useQueryInputsDateRange(
    store.customer.current.code,
    datePickerState.startDate,
    datePickerState.endDate
  );

  const emptyParams = useMemo(() => ({}), []);

  // Prerequisite queries

  // Airports
  const {data: airportsList, isLoading: isAirportsLoading} = useFetch<AirportsType>(airportsQuery, emptyParams);

  // End Users
  const {data: endUsersData, isLoading: isEndUsersLoading} = useFetch<EndUser[]>(endUsersQuery, queryParams);
  const isEndUser = endUsersData && endUsersData.length === 0;

  // Tail Info
  const {data: tailInfoData, isLoading: isTailInfoLoading} = useFetch<TailInfo[]>(tailInfoQuery, queryParams);

  const arePrereqsLoading = isEndUsersLoading || isTailInfoLoading; // Add other prereq loading states here

  // Main queries

  // To prevent extra queries, leave things as undefined until all prereqs are done
  const aircraftStatusListFilters = useMemo(
    () => (arePrereqsLoading ? undefined : getAircraftStatusListFilters(isEndUser, isInternal)),
    [arePrereqsLoading, isEndUser, isInternal]
  );
  const queryParamsWithCurrentTstamp = useQueryInputsWithCurrentTimestamp(groupCode, timeStamp);

  const queryParamsWithFiltering = useFilteringParams(queryParamsWithCurrentTstamp, aircraftStatusListFilters, filters);

  const queryParamsWithSorting = useSortingParams(queryParamsWithFiltering, sort.queryField, sort.order);

  const queryParamsWithGroupCode = useQueryInputsWithGroupCode(groupCode);

  const [aircraftStatusData, setAircraftStatusData] = useState<IAircraftStatusData[]>([]);
  const [aircraftStatusDataIndex, setAircraftStatusDataIndex] = useState<number>(-1);

  const canUseCacheWhileLoadingForNewUpdate = useCallback(
    (cacheQueryParams: QueryParams): boolean => {
      return cacheQueryParams.groupCode === groupCode;
    },
    [groupCode]
  );

  // Total open cases
  const {data: totalOpenCases, isLoading: isTotalOpenCasesLoading} = useFetch<IOpenCases[]>(
    totalOpenCasesQuery,
    queryParamsWithGroupCode
  );

  // Aircraft Status List
  const {data: aircraftStatusListData, isLoading: isAircraftStatusListLoading} = useFetch<AircraftStatusInfo[]>(
    aircraftStatusListQuery,
    queryParamsWithSorting,
    canUseCacheWhileLoadingForNewUpdate
  );

  // Get User Details
  const {data: userDetails, isLoading: isUserDetailsLoading} = useFetch<WhoAmI>(whoAmIQuery, queryParams);

  const isDataLoading =
    arePrereqsLoading || isAircraftStatusListLoading || isTotalOpenCasesLoading || isUserDetailsLoading;

  const isLoading = (isDataLoading && !refreshFromInterval) || isSortFilterOnRefresh;

  /**
   * event handler on click of support column
   * @param rowData Row information
   */
  const handleSupportRequestClick = (rowData: any) => {
    setOpenSupportRequestModal(true);
    setSelectedAircraft(rowData);
    setSelectedCaseCount(rowData.openCases);
    setOpenActionItems(false);
  };

  /**
   * event handler on click of open cases count
   * @param rowData Row information
   */
  const handleOpenCasesClick = (rowData: any) => {
    setSelectedAircraft(rowData);
    setSelectedCaseCount(rowData.openCases);
    setOpenAircraftCaseListModal(true);
    setOpenActionItems(false);
  };
  const [popupPosition, setPopupPosition] = useState<{positionX: number; positionY: number}>({
    positionX: 0,
    positionY: 0
  });

  const handleActionItemsClick = (rowData: any, $event: any) => {
    setSelectedAircraft(rowData);
    setOpenActionItems(true);
    document.addEventListener('click', (event) => {
      const YPosition = event.clientY + 20;
      const XPosition = event.clientX - 130;
      setPopupPosition({positionX: XPosition, positionY: YPosition});
    });
    setButtonAnchorEl($event.currentTarget);
  };
  /**
   * On click Event handler of the software revisions.
   * @param rowData Row information
   */
  const handleSoftwareRevisionsClick = (rowData: any) => {
    setSelectedAircraft(rowData);
    setOpenAircraftSwRevisionsModal(true);
  };

  /**
   * On click event handler of the tail summary column.
   * @param rowData Row information
   */
  const handleSummaryMetricsLinkClick = (rowData: any) => {
    dispatch({
      type: SummaryMetricsAction.FILTER_BY_TAIL_ID,
      payload: [rowData.tailId]
    });
    navigate('/dashboard/summary-metrics');
  };

  /**
   * On clic
   * @param rowData
   */
  const handleViewFlightDetailsClick = (rowData: any) => {
    navigate(`/flight/details/${rowData.flightId}`);
  };

  /**
   * event handler on click of the  watchlist icon.
   * @param rowData Row information
   */
  const handleWatchListClick = (rowData: any) => {
    rowData.favorite = !rowData.favorite;
    dispatch({
      type: AircraftStatusListAction.SET_FAVORITE_AIRCRAFTS,
      payload: [rowData]
    });
  };

  /**
   * Updates Auto Refresh label switch control state
   * @param autoRefresh Enable or disable auto refresh
   */
  const setAutoRefresh = (autoRefresh: boolean) => {
    dispatch({
      type: AircraftStatusListAction.SET_AUTO_REFRESH,
      payload: autoRefresh
    });
  };

  const aircraftStatusListColumns = buildAircraftStatusListColumns(
    isInternal,
    isEndUser,
    handleSoftwareRevisionsClick,
    handleOpenCasesClick,
    handleSummaryMetricsLinkClick,
    handleWatchListClick,
    handleSupportRequestClick,
    handleActionItemsClick,
    showCompactView
  );
  if (!isDataLoading && refreshFromInterval) {
    setRefreshFromInterval(false);
  }

  useEffect(() => {
    if (isAirportsLoading) return;
    if (airportsList && !isAirportsLoading) {
      dispatch({
        type: AppAction.SET_AIRPORTS,
        payload: {
          airports: airportsList
        }
      });
    }
  }, [dispatch, airportsList, isAirportsLoading]);

  //useEffect Purpose: Reset the flag when the data loaded successfully on sort and filter applied during auto refresh
  useEffect(() => {
    if (!isAircraftStatusListLoading) setIsSortFilterOnRefresh(false);
  }, [isAircraftStatusListLoading]);

  // useEffect Purpose: Update the aircraft status list data with the case count and update the software versions
  useEffect(() => {
    if (!isAircraftStatusListLoading && !isTotalOpenCasesLoading) {
      const aircraftStatusUpdatedData = aircraftStatusListData.map((aircraftStatus) => {
        let aircraftStatusCloned = {...aircraftStatus};
        let aircraftWithOpenCase = totalOpenCases.find((openCase) => openCase.aircraftId === aircraftStatus.aircraftId);
        if (aircraftWithOpenCase) {
          aircraftStatusCloned.openCases = aircraftWithOpenCase.openCaseCount;
        }
        aircraftStatusCloned.origin = {
          airportData: airports ? airports[aircraftStatus.origin] : undefined,
          code: aircraftStatus.origin
        };
        aircraftStatusCloned.destination = {
          airportData: airports ? airports[aircraftStatus.destination] : undefined,
          code: aircraftStatus.destination
        };
        return aircraftStatusCloned;
      });

      setAircraftStatusData(sortBy(aircraftStatusUpdatedData, 'sortIdx'));
    }
  }, [
    isAircraftStatusListLoading,
    showCompactView,
    isTotalOpenCasesLoading,
    aircraftStatusListData,
    totalOpenCases,
    airports,
    dispatch
  ]);

  // useEffect Purpose: Update the options available for filtering each of the filterable columns
  useEffect(() => {
    if (tailInfoData && endUsersData && aircraftStatusListFilters) {
      const {domainOptions, rangeOptions} = getFilterOptions(
        {tailInfoData, endUsersData, aircraftStatusData, airportsData: airports},
        aircraftStatusListFilters
      );

      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, AIRCRAFT_STATUS_LIST_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [tailInfoData, endUsersData, aircraftStatusData, dispatch, aircraftStatusListFilters, airports]);

  useEffect(() => {
    if (showCardView) {
      dispatch({type: AircraftStatusListAction.SET_DEFAULT_VIEW, payload: VIEW_TYPE.CARD_VIEW});
    } else {
      dispatch({type: AircraftStatusListAction.SET_DEFAULT_VIEW, payload: VIEW_TYPE.LIST_VIEW});
    }
  }, [showCardView, dispatch]);

  // Update default view if default view is not set
  useEffect(() => {
    if (isNull(defaultView) && tailInfoData) {
      if (tailInfoData.length > MIN_AIRCRAFT_TO_SWITCH_DEFAULT_VIEW) {
        dispatch({type: AircraftStatusListAction.SET_DEFAULT_VIEW, payload: VIEW_TYPE.LIST_VIEW});
        if (showCompactView) {
          dispatch({type: AircraftStatusListAction.SET_DEFAULT_COLUMN_VIEW, payload: VIEW_COLUMN_TYPE.COMPACT_VIEW});
        } else {
          dispatch({type: AircraftStatusListAction.SET_DEFAULT_COLUMN_VIEW, payload: VIEW_COLUMN_TYPE.EXPANDED_VIEW});
        }
      } else {
        dispatch({type: AircraftStatusListAction.SET_DEFAULT_VIEW, payload: VIEW_TYPE.CARD_VIEW});
      }
    }
  }, [tailInfoData, dispatch, showCompactView, defaultView]);

  // Validates/updates column sort fields - waits for end user status
  useEffect(() => {
    handleSortChange({column: sort.column, order: sort.order});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndUser]);

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    if (refreshFromInterval) setIsSortFilterOnRefresh(isAircraftStatusListLoading);
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, AIRCRAFT_STATUS_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(AircraftStatusListColumnId[column]),
        validColumns: aircraftStatusListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: aircraftStatusListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(AircraftStatusListColumnId[col.key])
        )
      }
    });
  };

  // Prevents flight max notification from disappearing and reappearing while sorting
  useEffect(() => {
    if (aircraftStatusListData !== null && aircraftStatusListData.length !== aircraftTotal) {
      dispatch({type: AircraftStatusListAction.SET_AIRCRAFT_STATUS_LIST_TOTAL, payload: aircraftStatusListData.length});
      if (!aircraftStatusListData.length) {
        setAircraftStatusData([]);
      }
    }
  }, [aircraftStatusListData, aircraftTotal, dispatch]);

  const setShowListNotification = (show: boolean) => {
    dispatch({
      type: AircraftStatusListAction.SET_SHOW_AIRCRAFT_STATUS_LIST_NOTIFICATION,
      payload: show
    });
  };

  //Sets the favorite aircrafts
  useEffect(() => {
    if (!isLoading && aircraftStatusData && favoriteAircrafts) {
      const favoriteAircraftIds = favoriteAircrafts.map((favoriteAircraft) => favoriteAircraft.aircraftId);
      aircraftStatusData.forEach((aircraft) => {
        aircraft.favorite = favoriteAircraftIds.includes(aircraft.aircraftId);
      });
      //sort by favorite property
      aircraftStatusData?.sort((a, b) => {
        if (!a.favorite && b.favorite) return 1;
        if (a.favorite && !b.favorite) return -1;
        if (sort.column === ASLV_DEFAULT_SORT_COLUMN && sort.order === SORT_DESC) {
          return a.displayOrder < b.displayOrder ? 1 : -1;
        }
        if (sort.column === ASLV_DEFAULT_SORT_COLUMN && sort.order === SORT_ASC) {
          return a.displayOrder > b.displayOrder ? 1 : -1;
        }
        return 1;
      });

      setLastWatchListItem(aircraftStatusData.findIndex((aircraft) => !aircraft.favorite) - 1);
    }
    //eslint-disable-next-line
  }, [isLoading, favoriteAircrafts, aircraftStatusData, lastWatchListItem, aircraftStatusListData]);

  useEffect(() => {
    let updatedData = aircraftStatusData;
    if (aircraftStatusDataIndex > -1) {
      updatedData.forEach((row) => {
        row.isRowHighlighted = false;
      });
      setAircraftStatusData(updatedData);

      updatedData[aircraftStatusDataIndex].isRowHighlighted = true;
      setScrollTop(aircraftStatusDataIndex);
    }
    setAircraftStatusData(updatedData);
    //eslint-disable-next-line
  }, [aircraftStatusDataIndex]);

  const showNotification = showListNotification && MAX_AIRCRAFT_DISPLAYED < aircraftTotal;

  const TabsComponent = (
    <HorizontalTabs
      getFullElementId={(name, type) => `${EID_FLIGHTLIST}--listTabs__${name}-${type}`}
      tabs={(validTabs || []).filter(({disabled}) => !disabled)}
      titleCount={isLoading ? undefined : aircraftTotal}
    />
  );

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(AircraftStatusListColumnId[columnId]);

  const [downloadProgressPercentage, setDownloadProgressPercentage] = useState(0);
  const clamp = (n: number, min: number, max: number) => Math.max(Math.min(n, max), min);
  const [timerIndex, setTimerIndex] = useState(-1);
  const clearIndex = () => {
    if (timerIndex > -1) {
      clearTimeout(timerIndex);
      setTimerIndex(-1);
    }
  };

  useEffect(() => {
    clearIndex();
    if (downloadProgressPercentage > 0 && downloadProgressPercentage < 100) {
      // using line equation : ms = 2.278 * row + 2975
      const update = (7.778 * aircraftTotal + 7975) / 50;
      const idx = window.setTimeout(() => {
        let amount = 0;
        const n = downloadProgressPercentage;
        if (n >= 0 && n < 20) {
          amount = 10; // 2  steps
        } else if (n >= 20 && n < 50) {
          amount = 4; // 9 steps
        } else if (n >= 50 && n < 80) {
          amount = 2; // 15 steps
        } else if (n >= 80 && n < 99) {
          amount = 0.5; // 40 steps
        }
        setDownloadProgressPercentage(clamp(n + amount, 0, 99.4));
      }, update);
      setTimerIndex(idx);
    }
    // eslint-disable-next-line
  }, [downloadProgressPercentage]);
  // The list of column IDs that we don't want included in the exported CSV download
  const excludedColumnIds = ['favorite', 'softwareRevisions', 'cases', 'tailSummary', 'actionItem', 'dataUsageStatus'];
  const handleExport = async (downloadSuccess, downloadFailure) => {
    handleExportClick(
      queryParamsWithSorting,
      aircraftStatusListColumns,
      currentHiddenColumns,
      downloadSuccess,
      setDownloadProgressPercentage,
      downloadFailure,
      AircraftStatusListColumnId,
      excludedColumnIds,
      'AircraftStatus',
      getQueryFieldMapping,
      'aircraftStatus/export',
      favoriteAircrafts
    );
  };

  const handleFilterChange = () => {
    if (refreshFromInterval) setIsSortFilterOnRefresh(isAircraftStatusListLoading);
    setShowListNotification(true); // allows notification to be shown again on filter change
  };

  const onDatePickerChange = (newState: DatePickerState) => {
    dispatch({type: DatePickerAction.SET_DATE_RANGE, payload: newState});
    setShowListNotification(true); // allows notification to be shown again on date change
  };

  /**
   * Updates the hidden columns in the state
   */
  const onHiddenColumnsChange = (hiddenColumns: string[]) => {
    dispatch({
      type: getActionTypeWithContext(HideColumnsAction.SET_HIDDEN_COLUMNS, AIRCRAFT_STATUS_LIST_STORE_CONTEXT),
      payload: {hiddenColumns}
    });
  };

  /**
   * Updates the current view type (list view/card view)
   * @param showCardView boolean value to change current view (list --> card or card --> list)
   */
  const onChangeView = (showCardView: boolean) => {
    dispatch({
      type: AircraftStatusListAction.CHANGE_VIEW,
      payload: showCardView
    });
  };

  /**
   * Updates the current view for columns (expanded view/compact view)
   * @param showCompactView boolean value to change current view (default --> compact or compact --> default)
   */
  const onChangeColumnView = (showCompactView: boolean) => {
    dispatch({
      type: AircraftStatusListAction.CHANGE_COLUMN_VIEW,
      payload: showCompactView
    });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (sessionStorage.timeOverrideEnable !== 'true' && autoRefresh) {
        setTimestamp(moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS));
        setRefreshFromInterval(true);
      }
    }, API_REREQUEST_INTERVAL_MS);

    // You want the changing of the inputs to reset the interval
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [autoRefresh]);

  return (
    <>
      {openActionItems && (
        <ActionItemsList
          selectedAircraft={selectedAircraft}
          openModal={openActionItems}
          setOpenModal={setOpenActionItems}
          popupPosition={popupPosition}
          buttonAnchorEl={buttonAnchorEl}
          handleViewFlightDetailsClick={handleViewFlightDetailsClick}
          handleSummaryMetricsLinkClick={handleSummaryMetricsLinkClick}
          handleOpenCasesClick={handleOpenCasesClick}
          handleSupportRequestClick={handleSupportRequestClick}
        />
      )}
      {openSupportRequestModal && (
        <AircraftStatusSupportPopup
          aircraftDetail={selectedAircraft}
          caseCount={selectedCaseCount}
          openSupportModal={openSupportRequestModal}
          setOpenSupportModal={setOpenSupportRequestModal}
          openAircraftCaseListModal={openAircraftCaseListModal}
          setOpenAircraftCaseListModal={setOpenAircraftCaseListModal}
          userDetails={userDetails}
        />
      )}
      {requestUpdateStatus && (
        <RequestUpdateStatusPopup openModal={requestUpdateStatus} setOpenModal={setRequestUpdateStatus} />
      )}
      {openAircraftCaseListModal && (
        <OpenCaseListPopup
          selectedAircraft={selectedAircraft}
          caseCount={selectedCaseCount}
          openModal={openAircraftCaseListModal}
          setOpenModal={setOpenAircraftCaseListModal}
          setRequestUpdateStatus={setRequestUpdateStatus}
        ></OpenCaseListPopup>
      )}
      {openAircraftSwRevisionsModal && (
        <SoftwareRevisionsPopup
          selectedAircraft={selectedAircraft}
          openModal={openAircraftSwRevisionsModal}
          setOpenModal={setOpenAircraftSwRevisionsModal}
        ></SoftwareRevisionsPopup>
      )}
      <ListViewComponent
        idPrefix={'aircraftStatusList'}
        listContext={'aircraftStatusList'}
        listSort={sort}
        tabs={TabsComponent}
        showGroupHeader={true}
        arePrereqsLoading={isLoading}
        isLoading={isLoading}
        listTotal={aircraftTotal}
        listColumns={aircraftStatusListColumns}
        hiddenColumns={currentHiddenColumns}
        listData={aircraftStatusData.map((aircraft, i) => ({
          ...aircraft,
          isRowUnderlined: i === lastWatchListItem
        }))}
        listFilters={filters}
        handleExport={handleExport}
        percentageComplete={downloadProgressPercentage}
        columnOverscan={10}
        handleSortChange={handleSortChange}
        getQueryFieldForColumn={getQueryFieldForColumn}
        notificationMessage={maxRowNotificationMessage}
        showNotification={showNotification}
        setShowNotification={setShowListNotification}
        currentDateRange={datePickerState}
        onSetDateRange={onDatePickerChange}
        handleFilterChange={handleFilterChange}
        fixedColumnCount={4}
        scrollTop={scrollTop}
        customLeftActions={[
          <FilterHookContainer
            key="filters"
            idPrefix={'aircraftStatusList'}
            storeContext={AIRCRAFT_STATUS_LIST_STORE_CONTEXT}
            handleFilterChange={handleFilterChange}
          />,
          <WatchListSelector
            getFullElementId={(name: string, type: string) => `watchListSelector__${name}-${type}`}
            listData={aircraftStatusData}
            disabled={isLoading}
          />,
          !showCardView && defaultView === VIEW_TYPE.LIST_VIEW ? (
            <ListViewSortContainer
              key="sorts"
              idPrefix={'aircraftStatusList'}
              disabled={isLoading}
              storeContext={AIRCRAFT_STATUS_LIST_STORE_CONTEXT}
              columns={aircraftStatusListColumns}
              showColumnGroups={true}
              getQueryFieldForColumn={getQueryFieldForColumn}
            />
          ) : (
            <React.Fragment key="aircraftStatusListSortEmpty"></React.Fragment>
          ),
          !showCardView && defaultView === VIEW_TYPE.LIST_VIEW ? (
            <HiddenColumnSelector
              key="columns"
              getFullElementId={(name, type) => `${'aircraftStatusList'}--columnSelector__${name}-${type}`}
              onHiddenColumnsChange={onHiddenColumnsChange}
              isDisabled={isLoading}
              columns={aircraftStatusListColumns
                .filter((col) => !col.frozen)
                ?.map((col) => ({
                  id: col.key.toString(),
                  showHideGroup: col.showHideGroup,
                  title: col.title
                }))}
              hiddenColumns={currentHiddenColumns}
            />
          ) : (
            <React.Fragment key="hiddenColumnsEmpty"></React.Fragment>
          ),
          <SwitchListCardView
            key="switchViews"
            cardView={showCardView && defaultView === VIEW_TYPE.CARD_VIEW ? true : false}
            onChangeView={onChangeView}
            isDisabled={isLoading}
          />,
          !showCardView ? (
            <SwitchCompactExpandedView
              key="switchColumnViews"
              compactView={showCompactView && defaultColumnView === VIEW_COLUMN_TYPE.COMPACT_VIEW ? true : false}
              onChangeColumnView={onChangeColumnView}
              isDisabled={isLoading}
            />
          ) : null,
          liveTimeOverrideEnabled ? (
            <div key="manual-date-time">
              <LiveTimeEntry
                idBase="aircraftStatus--container"
                enable={timeOverrideEnable}
                value={timeOverrideValue}
                onChange={updateTimeOverride}
              />
            </div>
          ) : (
            <></>
          )
        ]}
        customRightActions={[
          <LabeledSwitch
            getFullElementId={(name: string, type: string) => `aslv__autoRefresh${name}-${type}`}
            onChecked={(isChecked: boolean) => setAutoRefresh(isChecked)}
            subTitle={moment.utc(timeStamp).format(DATE_FORMAT_MM_DD_YYYY_HH_MM)}
            title="Auto Refresh"
            isLoading={isDataLoading}
            isChecked={autoRefresh}
          />,
          <ListSearch
            idPrefix={'AircraftStatusList'}
            data={aircraftStatusData}
            setAircraftStatusDataIndex={setAircraftStatusDataIndex}
            setScrollTop={setScrollTop}
          />,
          !showCardView ? (
            <LoadingButton
              getFullElementId={(name, type) => `${idPrefix}--exportButton__${name}-${type}`}
              key={`export${idPrefix}`}
              title="CSV"
              successMessage="Done"
              errorMessage="Error"
              messageDurationMs={5000}
              isDisabled={isLoading || aircraftTotal === 0 || aircraftStatusListColumns?.length === 0}
              onClick={handleExport}
              percentageComplete={downloadProgressPercentage}
            />
          ) : (
            <React.Fragment key="aircraftStatusListEmptyExport"></React.Fragment>
          ),
          <ShareButton
            idBase={idPrefix}
            key={`share${idPrefix}`}
            disabled={arePrereqsLoading}
            customFilters={{
              ...sort,
              ...translateFiltersArrayToObject(filters),
              ...translateHiddenColumnsArrayToObject(currentHiddenColumns, AIRCRAFT_STATUS_LIST_STORE_CONTEXT),
              showCardView: showCardView ? 'true' : 'false',
              showCompactView: showCompactView ? 'true' : 'false'
            }}
          />
        ]}
        showCardView={showCardView && defaultView === VIEW_TYPE.CARD_VIEW}
        showCompactView={showCompactView && defaultColumnView === VIEW_COLUMN_TYPE.COMPACT_VIEW}
        isEndUser={isEndUser}
        handleWatchListClick={handleWatchListClick}
        handleOpenCasesClick={handleOpenCasesClick}
        handleSummaryMetricsLinkClick={handleSummaryMetricsLinkClick}
        handleSupportRequestClick={handleSupportRequestClick}
      />
    </>
  );
};

export default AircraftStatusListPage;
