/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: The aircraft popup shown when an aircraft is clicked on in the fleet map
 */

import React, {useState, useRef, useEffect, useCallback} from 'react';
import moment from 'moment';
import LastHeardFromIcon from '@mui/icons-material/AccessTime';
import {Grow} from '@mui/material';
import {Close} from '@mui/icons-material';
import {useGoogleMap} from '@react-google-maps/api';
import {forEach, isNil} from 'lodash';

import {
  PopupNetworkTypeFragment,
  PopupTitleFragment,
  SelectedAircraftPopupProps,
  SelectedPopupTimeFragment
} from './AircraftPopupCommon';
import {
  PopupHeader,
  PopupBody,
  PopupContainer,
  PopupRow,
  Span,
  PopupCloseButton,
  PopupCloseButtonContainer,
  PopupIsolateRouteButton,
  PopupIsolateRouteText,
  PopupFlightDetailsButton,
  PopupFlightDetailsText
} from './AircraftPopupStyles';
import {
  Z_INDEX,
  NO_DATA_INDICATOR,
  SELECTED_POPUP_TRANSITION_IN_DURATION,
  SELECTED_POPUP_TRANSITION_OUT_DURATION,
  SELECTED_POPUP_WIDTH,
  MAP_ELID_SECTION,
  ConnectionStatus,
  MAP_DEFAULT_ZOOM
} from '../../../utils/constants';
import {useStore} from '../../../store/Store';
import {FleetMapAction} from '../../../store/reducers/FleetMapReducer';
import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';
import {getElementIdFromSectionBase, getElementIdSectionBase} from '../../../utils/ElementIdUtils';
import IsolateRouteIcon from '../../common/theme/icons/IsolateRouteIcon';
import {
  SIDEBAR_ICON_ACTIVE_COLOR,
  MODAL_FONT_COLOR,
  WHITE,
  ISOLATE_ROUTE_BUTTON_ACTIVE_BACKGROUND,
  MAP_POPUP_DEFAULT_BORDER
} from '../../common/theme/Colors';
import {MetricsIcon, CustomLink, CustomLinkStyle} from '@viasat/insights-components';

const ELEMENT_ID_BASE = getElementIdSectionBase(MAP_ELID_SECTION, 'selectedAircraftPopup');
const SelectedAircraftPopup: React.FC<SelectedAircraftPopupProps> = ({
  selectedAircraft,
  flightPath,
  onClose,
  mapDivRef,
  popupSerialNumber
}) => {
  const {store, dispatch} = useStore();
  const [transitionIn, setTransitionIn] = useState(false);
  const {airports} = store.app;
  const [isolate, setIsolate] = useState(false);
  const [exitIsolate, setExitIsolate] = useState(false);
  const [displayedAircraftId, setDisplayedAircraftId] = useState(undefined);
  const timeoutRef = useRef(null);
  const {view, aircraftStatusData, filters} = store.fleetMap;
  const isolateRoute = view.isolateRoute === 'true' ? true : false;
  const map = useGoogleMap();
  let startTimeStamp = moment.utc();
  const enableTimeOverride = sessionStorage.timeOverrideEnable;
  if (enableTimeOverride !== undefined && enableTimeOverride === 'true') {
    startTimeStamp = sessionStorage.timeOverrideValue;
  }

  /**
   * Method to handle isolate route click
   */
  const handleIsolateRouteClick = useCallback(() => {
    const newIsolate = !isolate;
    setIsolate(newIsolate);
    if (!newIsolate) {
      setExitIsolate(true);
    }
    dispatch({type: FleetMapAction.SET_ISOLATE_ROUTE, payload: newIsolate.toString()});
  }, [isolate, dispatch]);

  useEffect(() => {
    if (isolateRoute && flightPath?.flightPath?.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      forEach(flightPath.flightPath, function (val) {
        bounds.extend(new google.maps.LatLng(val.lat, val.lng));
      });
      map?.fitBounds(bounds);
    } else if (selectedAircraft) {
      if (exitIsolate && selectedAircraft.serialNumber) {
        map?.setCenter({lat: selectedAircraft.lastLatitude, lng: selectedAircraft.lastLongitude});
        map?.setZoom(MAP_DEFAULT_ZOOM);
        setExitIsolate(false);
      } else {
        if (isolateRoute && flightPath?.flightPath?.length === 0) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(new google.maps.LatLng(selectedAircraft.lastLatitude, selectedAircraft.lastLongitude));
          map?.fitBounds(bounds);
        }
      }
    }
  }, [aircraftStatusData, filters, flightPath, selectedAircraft, isolateRoute, map, exitIsolate]);

  useEffect(() => {
    if (Boolean(displayedAircraftId)) {
      setTransitionIn(false);
      timeoutRef.current = setTimeout(() => {
        setTransitionIn(Boolean(popupSerialNumber));
        setDisplayedAircraftId(popupSerialNumber);
      }, SELECTED_POPUP_TRANSITION_OUT_DURATION);
    } else {
      setTransitionIn(Boolean(popupSerialNumber));
      setDisplayedAircraftId(popupSerialNumber);
    }
  }, [displayedAircraftId, popupSerialNumber]);
  const aircraft = selectedAircraft ? selectedAircraft : null;
  const elementIdBase = `fleetMap--selectedAircraftPopup`;
  return (
    <Grow
      in={transitionIn}
      timeout={{appear: 0, enter: SELECTED_POPUP_TRANSITION_IN_DURATION, exit: SELECTED_POPUP_TRANSITION_OUT_DURATION}}
    >
      <Span
        style={{
          width: `${SELECTED_POPUP_WIDTH}px`,
          position: 'absolute'
        }}
      >
        <PopupContainer
          style={{left: '15px', top: '30px', minWidth: '287px', width: 'auto'}}
          id={`${elementIdBase}-container`}
          zIndex={Z_INDEX.HOVER - 1}
          height={aircraft?.status === ConnectionStatus.DISCONNECTED ? 112 : 100}
          display="inline-block"
        >
          {!aircraft ? null : (
            <>
              <PopupHeader
                font={18}
                borderRadius={
                  aircraft?.status === ConnectionStatus.CONNECTED || aircraft?.status === ConnectionStatus.IMPAIRED
                    ? '4px 4px 4px 4px'
                    : '4px 4px 0px 0px'
                }
              >
                <PopupNetworkTypeFragment
                  iconSize={12}
                  fontSize={14}
                  height={18}
                  elementIdBase={elementIdBase}
                  status={aircraft.status}
                  networkType={aircraft.lastNetwork}
                />
                <PopupCloseButtonContainer>
                  <PopupCloseButton
                    id={getElementIdFromSectionBase(ELEMENT_ID_BASE, 'popupClose', 'button')}
                    popupWidth={SELECTED_POPUP_WIDTH}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <Close />
                  </PopupCloseButton>
                </PopupCloseButtonContainer>
                <PopupTitleFragment
                  availDotSize={16}
                  primaryIdFontSize={18}
                  secondaryIdFontSize={14}
                  flightPhaseFontSize={12}
                  flightPhaseIconSize={18}
                  isLargePopup={true}
                  outerPadding={'18px 15px 7px 16px'}
                  status={aircraft.status}
                  tailId={aircraft.tailId}
                  serialNumber={aircraft.serialNumber}
                  elementIdBase={`popup-title-fragment-${elementIdBase}`}
                  flightPhase={aircraft.lastFlightPhase}
                  mapDivRef={mapDivRef}
                  isLabTerminal={selectedAircraft?.isLabTerminal}
                  endUser={selectedAircraft?.endUser}
                />
                <Span style={{borderTop: `1px solid ${MAP_POPUP_DEFAULT_BORDER}`}}></Span>
                <SelectedPopupTimeFragment
                  directionIconSize={14}
                  timeLabelFontSize={14}
                  timeFontSize={14}
                  utcFontSize={14}
                  elementIdBase={elementIdBase}
                  mapDivRef={mapDivRef}
                  aircraftInfo={aircraft}
                  padding={
                    selectedAircraft.status === ConnectionStatus.DISCONNECTED ? '0px 4px 10px 5px' : '0px 4px 14px 5px'
                  }
                  departureTstamp={
                    selectedAircraft?.isLabTerminal ? aircraft.connectedStartTimestamp : aircraft.flightStartTimestamp
                  }
                  arrivalTstamp={
                    selectedAircraft?.isLabTerminal ? aircraft.connectedEndTimestamp : aircraft.flightEndTimestamp
                  }
                  departureTstampLabel={selectedAircraft?.isLabTerminal ? 'Connection Start' : 'Flight Start'}
                  arrivalTstampLabel={selectedAircraft?.isLabTerminal ? 'Connection End' : 'Flight End'}
                  flightPhase={aircraft.lastFlightPhase}
                  flightStartHorizLine={aircraft.flightStartTimestamp ? 115 : aircraft.flightEndTimestamp ? 115 : 85}
                  flightNoStartHorizLine={115}
                  flightEndHorizLine={120}
                  airports={airports}
                />
              </PopupHeader>
              <PopupBody
                defaultFontSize={18}
                labelFontSize={14}
                valueFontSize={14}
                unitsFontSize={12}
                iconSize={14}
                outerPadding={undefined}
                innerPadding={'4px 2px'}
              >
                {aircraft?.status === ConnectionStatus.OFFLINE || aircraft?.status === ConnectionStatus.DISCONNECTED ? (
                  <PopupRow padding={'2px 10px 9px 13px'}>
                    <Span className="data-row-container top-border">
                      <LastHeardFromIcon width={'14px'} height={'14px'} />
                      <Span className="label">Last heard from</Span>
                      <Span className="value" id={`${elementIdBase}linkQuality-label`}>
                        {aircraft.connectedEndTimestamp
                          ? moment.utc(aircraft.connectedEndTimestamp).from(startTimeStamp)
                          : NO_DATA_INDICATOR}
                      </Span>
                    </Span>
                  </PopupRow>
                ) : (
                  <></>
                )}
              </PopupBody>
            </>
          )}
        </PopupContainer>

        {
          <>
            <PopupIsolateRouteButton
              id={getElementIdFromSectionBase(ELEMENT_ID_BASE, 'isolateRoute', 'button')}
              zIndex={Z_INDEX.HOVER - 1}
              top={
                aircraft?.isDark
                  ? 242
                  : (aircraft?.status === ConnectionStatus.OFFLINE ||
                      aircraft?.status === ConnectionStatus.DISCONNECTED) &&
                    !isNil(aircraft?.origin) &&
                    (!isNil(aircraft?.actualArrivalTstamp) || isNil(aircraft?.remainingTime))
                  ? 206
                  : (aircraft?.status === ConnectionStatus.OFFLINE ||
                      aircraft?.status === ConnectionStatus.DISCONNECTED) &&
                    !isNil(aircraft?.origin) &&
                    isNil(aircraft?.actualArrivalTstamp)
                  ? 232
                  : !isNil(aircraft?.origin) && !isNil(aircraft?.actualArrivalTstamp)
                  ? 172
                  : !isNil(aircraft?.origin)
                  ? 200
                  : aircraft?.status === ConnectionStatus.OFFLINE || aircraft?.status === ConnectionStatus.DISCONNECTED
                  ? 198
                  : 164
              }
              onClick={handleIsolateRouteClick}
              backgroundColor={isolateRoute ? ISOLATE_ROUTE_BUTTON_ACTIVE_BACKGROUND : WHITE}
              width={130}
            >
              <Span style={{marginRight: '2px'}}>
                <IsolateRouteIcon
                  sx={{color: isolateRoute ? SIDEBAR_ICON_ACTIVE_COLOR : MODAL_FONT_COLOR, width: 16, height: 16}}
                />
                <PopupIsolateRouteText
                  id={getElementIdFromSectionBase(ELEMENT_ID_BASE, 'isolateRoute', 'label')}
                  color={isolateRoute ? SIDEBAR_ICON_ACTIVE_COLOR : MODAL_FONT_COLOR}
                >
                  {isolateRoute ? 'UNISOLATE' : 'ISOLATE ROUTE'}
                </PopupIsolateRouteText>
              </Span>
            </PopupIsolateRouteButton>
            <CustomLink
              linkStyle={CustomLinkStyle.None}
              to={`/flight/details/${!isNil(aircraft?.faFlightId) ? aircraft?.faFlightId : aircraft?.flightId}`}
              color="inherit"
            >
              <PopupFlightDetailsButton
                id={getElementIdFromSectionBase(ELEMENT_ID_BASE, 'flightDetailsPopup', 'button')}
                onClick={() => dispatch({type: FlightDetailsAction.SET_HISTORICAL_CONNECTED_START, payload: null})}
                zIndex={Z_INDEX.HOVER - 1}
                top={
                  aircraft?.isDark
                    ? 207
                    : (aircraft?.status === ConnectionStatus.OFFLINE ||
                        aircraft?.status === ConnectionStatus.DISCONNECTED) &&
                      !isNil(aircraft?.origin) &&
                      (!isNil(aircraft?.actualArrivalTstamp) || isNil(aircraft?.remainingTime))
                    ? 170
                    : (aircraft?.status === ConnectionStatus.OFFLINE ||
                        aircraft?.status === ConnectionStatus.DISCONNECTED) &&
                      !isNil(aircraft?.origin)
                    ? 196
                    : !isNil(aircraft?.origin) && !isNil(aircraft?.actualArrivalTstamp)
                    ? 136
                    : !isNil(aircraft?.origin)
                    ? 164
                    : aircraft?.status === ConnectionStatus.OFFLINE ||
                      aircraft?.status === ConnectionStatus.DISCONNECTED
                    ? 162
                    : 128
                }
              >
                <MetricsIcon />
                <PopupFlightDetailsText
                  id={getElementIdFromSectionBase(ELEMENT_ID_BASE, 'flightDetailsPopup', 'label')}
                >
                  LIVE FLIGHT DETAILS
                </PopupFlightDetailsText>
              </PopupFlightDetailsButton>
            </CustomLink>
          </>
        }
      </Span>
    </Grow>
  );
};

export default SelectedAircraftPopup;
