/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tooltip DateTime Range Label used for the Title
 */

import React from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import {
  MONTH_NAME_DATE_AND_DAY_FORMAT,
  formatMomentInput,
  formatMomentInputAsTimeWithSecondsNoPad,
  formatStartEndDurationValue
} from '../../../../../utils/DateTimeUtils';
import BoldSpan from '../../../BoldSpan';
import ToolTipLabel from './ToolTipLabel';
import {MAP_POPUP_ACCENT_COLOR} from '../../../theme/Colors';

export interface ToolTipDataTimeRangeLabelProps {
  dtLow: moment.MomentInput;
  dtHigh: moment.MomentInput;
  timeDurationTooltip: boolean;
}

const UsageDuration = styled.div`
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
  color: ${MAP_POPUP_ACCENT_COLOR};
  display: inline-block;
`;

const ToolTipDateTimeRangeLabel: React.FC<ToolTipDataTimeRangeLabelProps> = ({
  dtLow,
  dtHigh,
  timeDurationTooltip
}: ToolTipDataTimeRangeLabelProps): JSX.Element => {
  const dtl = formatMomentInput(dtLow, `${MONTH_NAME_DATE_AND_DAY_FORMAT}, `);
  const dthp = formatMomentInput(dtHigh, `${MONTH_NAME_DATE_AND_DAY_FORMAT}, `);
  const dth = dtl === dthp ? '' : dthp;

  return (
    <div style={{marginBottom: '4px'}}>
      <ToolTipLabel>
        {dtl}
        <BoldSpan>{formatMomentInputAsTimeWithSecondsNoPad(dtLow)}</BoldSpan>&nbsp;{dtHigh ? <>-</> : null} {dth}
        <BoldSpan>{formatMomentInputAsTimeWithSecondsNoPad(dtHigh)}</BoldSpan>
        {timeDurationTooltip ? <UsageDuration>({formatStartEndDurationValue(dtLow, dtHigh)})</UsageDuration> : <></>}
      </ToolTipLabel>
    </div>
  );
};

export default ToolTipDateTimeRangeLabel;
