/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail Information Query
 */
import {Query} from '../types';

export interface TailInfoDataProps {
  serial_number: string;
  tail_id: string;
  aircraft_type: string;
  network: string;
  ka_sw_version: string;
  ku_sw_version: string;
  is_dual_band: boolean;
  ip_address: string;
  mac_address: string;
  customer: string;
  value_added_reseller: string;
  cpe_ip: string;
  is_regional_plan: boolean;
  is_decommissioned: boolean;
  decommissioned_tstamp: string;
}

export const useTailInfoQuery: Query<TailInfoDataProps> = {
  route: `flightDetails/tailInfo/aircraftInfo`,
  transform: null
};
export default useTailInfoQuery;
