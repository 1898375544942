/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Modal component
 */
import styled from '@emotion/styled';
import {Box, Modal} from '@mui/material';
import {MODAL_BACKGROUND} from './theme/Colors';

interface ModalProps {
  id: string;
  open: boolean;
  title: any;
  subTitle?: string;
  titlecolor: string;
  children?: any;
  borderRadius?: string;
  padding?: string;
}

const ModalContainer = styled(Modal)<{titlecolor: string}>`
  .modal-title {
     font-family: Source Sans Pro;
     font-style: normal;
     font-weight: bold;
     font-size: 24px;
     line-height: 36px;
     display: flex;
     align-items: flex-end;
     margin-top: 16px;
     color: ${(props) => props.titlecolor};
  }
  .modal-subtitle {
   font-size: 16px;
   line-height: 24px;
   margin-top: 8px;
   margin-bottom: 24px;
   color: ${(props) => props.titlecolor};
  }
 }
  `;

const PopUpModal = ({id, open, title, subTitle, titlecolor, children, borderRadius, padding}: ModalProps) => {
  const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: MODAL_BACKGROUND,
    borderRadius: borderRadius ? borderRadius : '4px',
    paddingLeft: padding ? padding : '16px',
    paddingBottom: padding ? padding : '16px',
    paddingRight: padding ? padding : '16px',
    paddingTop: '4px',
    outline: 'none'
  };

  return (
    <ModalContainer id={id} open={open} titlecolor={titlecolor}>
      <Box sx={style}>
        <div className="modal-title"> {title} </div>
        {subTitle && <div className="modal-subtitle">{subTitle}</div>}
        <div className="modal-description"> {children} </div>
      </Box>
    </ModalContainer>
  );
};

export default PopUpModal;
