/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: WatchListItemsList component
 */

import React, {useState, useEffect, useRef} from 'react';
import {AutoSizer, List} from 'react-virtualized';
import {KEY_RETURN, KEY_UP, KEY_DOWN} from 'keycode-js';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import {
  AUTO_COMPLETE_HOVER_BG_COLOR,
  AUTO_COMPLETE_HOVER_COLOR,
  BLACK,
  EIGHT_PCT_TRANSPARENT_BLACK,
  FILTER_ACTIVE_APPLY,
  FILTER_BORDER_COLOR,
  FILTER_INACTIVE_APPLY,
  FILTER_POPULATED_BORDER,
  GREY,
  MULTISELECT_CHECKBOX_INACTIVE_COLOR,
  NINE_PCT_TRANSPARENT_BLACK,
  TRANSPARENT,
  WATCHLIST_SELECTOR_ACTIVE_COLOR,
  WHITE
} from '../../common/theme/Colors';

export interface ListItem {
  key: string;
  value: string;
  title: string;
  checked?: boolean;
}

export interface WatchListItemsListProps {
  getFullElementId: (name: string, type: string) => string;
  disabled: boolean;
  selected: string | Array<string>;
  onChange: (optionsValue: string | Array<any>) => void;
  list: ListItem[];
}

const StyledWatchListItemsContainer = styled.div`
  width: 128px;
  color: ${GREY};
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  .left {
    right: 21px;
  }
`;

const StyledOption = styled.div<{hover: boolean; key?: string; value?: string}>`
  padding: 6px 12px;
  height: 18px;
  font-size: 14px;
  color: ${AUTO_COMPLETE_HOVER_COLOR};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${(props) => (props.hover ? EIGHT_PCT_TRANSPARENT_BLACK : TRANSPARENT)};
`;

const StyledListContainer = styled.div`
  position: absolute;
  background: ${WHITE};
  cursor: pointer;
  z-index: 1;
  width: 240px;
  margin-top: 2px;
  box-shadow: 2px 2px 6px 1px ${NINE_PCT_TRANSPARENT_BLACK};
  border-radius: 4px;
`;

const StyledInput = styled.input<{filter: string; onKeyUp: any}>`
  position: absolute;
  background: ${WHITE};
  width: 160px;
  margin: 4px 12px;
  padding: 6px 26px;
  border: 1px solid ${(props) => (props.filter && props.filter.length ? FILTER_POPULATED_BORDER : FILTER_BORDER_COLOR)};
  border-radius: 20px;
`;

const CloseButton = styled.div<{filter: string}>`
  display: ${(props) => (props.filter && props.filter.length ? 'block' : 'none')};
  position: absolute;
  height: 16px;
  width: 16px;
  top: 10px;
  right: 20px;
  border-radius: 8px;
  svg {
    height: 14px;
    width: 14px;
    margin: 1px;
  }
  &:hover {
    background-color: ${AUTO_COMPLETE_HOVER_BG_COLOR};
  }
`;

const SearchIconContainer = styled.div`
  position: absolute;
  height: 16px;
  width: 16px;
  top: 10px;
  left: 20px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const StyledChildContainer = styled.div<{height: number}>`
  height: ${(props) => props.height}px;
  margin-top: 40px;
  max-height: 200px;
`;

const StyledBorder = styled.div`
  background: ${FILTER_BORDER_COLOR};
  border-radius: 0px;
  height: 1px;
  width: 100%;
`;

const StyledApplyButtonContainer = styled.div`
  .MuiButtonBase-root:disabled {
    cursor: auto;
    pointer-events: auto;
  }
`;

const StyledApplyButton = styled(Button)`
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  height: 32px;
`;

const StyledApplyText = styled.div<{isDirty: boolean}>`
  color: ${(props) => (props.isDirty ? FILTER_ACTIVE_APPLY : FILTER_INACTIVE_APPLY)};
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: center;
  width: 39px;
`;

const StyledCheckbox = styled(Checkbox)`
  &:hover {
    background-color: 'transparent';
  }
`;

const WatchListItemsList: React.FC<WatchListItemsListProps> = ({
  getFullElementId,
  disabled,
  selected,
  onChange,
  list
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [hoverKeyIndex, setHoverKeyIndex] = useState<number>(-1);
  const [filter, setFilter] = useState<string>('');
  const [items, setItems] = useState(list);

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  // Recursive function to flatten any JSX element children into single string
  const normalizeTargetObject = (curr: any) =>
    typeof curr === 'object' && curr !== null && curr.props && Array.isArray(curr.props.children)
      ? curr.props.children.map((c: any) => normalizeTargetObject(c)).join('')
      : curr;

  const normalizeSearchString = (curr: string) =>
    String(normalizeTargetObject(curr || ''))
      .toLowerCase()
      .split('-')
      .join('')
      .replace('  ', ' ');

  const filterValue = (targetString: string, filterString: string) => {
    return normalizeSearchString(targetString).includes(normalizeSearchString(filterString));
  };

  const sortListAlpha = (a: any, b: any) => a.title.localeCompare(b.title);

  // Sort alphabetically with checked elements before the unchecked ones
  const setSortedItems = (list: any[]) => {
    const checked = list.filter((elem) => elem.checked);
    const unchecked = list.filter((elem) => !elem.checked);
    checked.sort(sortListAlpha);
    unchecked.sort(sortListAlpha);
    setItems(checked.concat(unchecked));
  };

  // Reconciles the state of "items" by retrieving already checked options from store and sorts them
  const setCheckedItemsFromStore = () => {
    if (selected) {
      const actualList = [...list.map((item) => ({...item, checked: false}))]; // Start off with everything unchecked

      for (const val of selected) {
        const index = actualList.findIndex(
          (elem: {value: string; title: string}) => elem.value === val || elem.title === val
        );

        if (index !== -1) actualList[index].checked = true;
      }
      setSortedItems(actualList);
    }
  };

  // Compares currently checked "items" to the store "selected" items, returning true if they differ
  const getDirtyStateFromStore = () => {
    const checkedItems = [...items.filter((item) => item.checked)];

    // Is dirty of selected count and checked count are not equal
    if (checkedItems.length !== selected.length) {
      return true;
    }

    // Checking if the selected items match the checked items is 1:1
    for (const selectedItem of selected) {
      const index = checkedItems.findIndex(
        (elem: {value: string; title: string}) => elem.value === selectedItem || elem.title === selectedItem
      );

      // Dirty if at least selected one item is not found
      if (index === -1) {
        return true;
      }

      checkedItems.splice(index, 1);
    }

    if (checkedItems.length === 0) {
      return false;
    }

    return true;
  };

  const toggleActive = (active: boolean) => {
    if (disabled) {
      return;
    }

    setIsOpen(active);

    if (active) {
      setFilter('');

      // If the options changes, then keep track of new set of options
      if (items !== list) {
        setItems(list);
      }
      // Gather already checked options from store since we're reopening from a closed the tab
      setCheckedItemsFromStore();
    }
  };

  const clearFilter = ($event: any) => {
    inputRef.current.value = '';
    setFilter('');
    inputRef.current.focus();
  };

  const filteredList = items
    .filter((node: {title: string}) => !filter || filterValue(node.title, filter))
    .map((n: any, i: any) => ({
      ...n,
      hoverIndex: i
    }));
  const hoverIndex = (
    filteredList.find(({hoverIndex}: {hoverIndex: number}) => hoverIndex === hoverKeyIndex) || {hoverIndex: -1}
  ).hoverIndex;

  const handleFilter = (e: any) => {
    const chLen = filteredList.length;
    const prevIndex = hoverIndex === -1 ? 0 : hoverIndex === 0 ? chLen - 1 : hoverIndex - 1;
    const nextIndex = hoverIndex === chLen - 1 ? 0 : hoverIndex + 1;
    const hoverVal = hoverIndex > -1 && hoverIndex < chLen ? (filteredList[hoverIndex] || {}).value : null;

    if (e.keyCode === KEY_DOWN || e.keyCode === KEY_UP) {
      // if user clicks up
      if (e.keyCode === KEY_DOWN) {
        setHoverKeyIndex(nextIndex);
      }
      // if user clicks down
      else if (e.keyCode === KEY_UP) {
        setHoverKeyIndex(prevIndex);
      }
    }
    // if user hits enter
    else if (e.keyCode === KEY_RETURN && hoverVal) {
      selectValue(hoverVal);
      setHoverKeyIndex(hoverIndex);
    } else {
      setFilter(e.target.value);
      setHoverKeyIndex(hoverIndex);
    }
  };

  const multiSelect = () => {
    const checkedFilters = items.filter((elem) => elem.checked).map((elem) => elem.value);
    onChange(checkedFilters);
    toggleActive(false);
  };

  const selectValue = (value: string) => {
    handleCheck(value);
    setHoverKeyIndex(-1);
  };

  const handleCheck = (value: string) => {
    const index = items.findIndex((elem: {value: any}) => elem.value === value);
    items[index].checked = !items[index].checked;
    setItems([...items]);
  };

  const optionRenderer = ({index, key, style}: {index: number; key: any; style: any}) => {
    const node = filteredList[index];
    const newStyles = {...style};
    newStyles.height -= 12;
    newStyles.width = '90%';

    if (!filteredList.length && index === 0) {
      return (
        <StyledOption key={key} style={newStyles} hover={false}>
          No items match this filter
        </StyledOption>
      );
    } else if (!node) {
      return null;
    }

    return (
      <StyledOption
        id={getFullElementId(`selectOption`, `${node.value}`)}
        key={key}
        style={newStyles}
        onClick={() => selectValue(node.value)}
        onMouseOver={() => setHoverKeyIndex(node.hoverIndex)}
        hover={node.hoverIndex === hoverIndex}
        value={node.value}
      >
        <StyledCheckbox
          disableRipple
          disableFocusRipple
          disableTouchRipple
          color={'primary'}
          icon={
            <StarOutlineIcon
              fontSize="small"
              style={{
                position: 'relative',
                color: MULTISELECT_CHECKBOX_INACTIVE_COLOR,
                bottom: '0.5px',
                height: '19px'
              }}
            />
          }
          checkedIcon={
            <StarIcon
              fontSize="small"
              style={{
                position: 'relative',
                color: WATCHLIST_SELECTOR_ACTIVE_COLOR,
                bottom: '0.5px',
                height: '19px'
              }}
            />
          }
          value={node.value}
          checked={node.checked || false}
          style={{padding: '0px 0px 0px 0px'}}
        />
        <span style={{position: 'relative', padding: '0px 0px 0px 5px', color: node.checked ? BLACK : 'inherit'}}>
          {node.title}
        </span>
      </StyledOption>
    );
  };

  const elementNodes = items && Array.isArray(items) ? items : [];
  const filteredElementNodes = elementNodes.filter((node: any) => !filter || filterValue(node.title, filter));
  const height = Math.max(filteredElementNodes.length, 1) * 30;

  // By adding a random name the browser won't know where to save the autocomplete info
  const name = `autocomplete-${Math.random().toString(36).substring(7)}`;

  // Close select container when outside click is registered
  const handleClickOutside = (event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      toggleActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selected) {
      setIsDirty(getDirtyStateFromStore());
    }
    // eslint-disable-next-line
  }, [items, isOpen]);

  return (
    <StyledWatchListItemsContainer ref={containerRef}>
      <>
        <StyledListContainer>
          <StyledInput
            id={getFullElementId('search', 'input')}
            ref={inputRef}
            autoFocus={true}
            autoComplete="off"
            name={name}
            filter={filter}
            placeholder=" Search by SN or Tail ID..."
            onKeyUp={(e: any) => handleFilter(e)}
            onChange={() => setSortedItems(items)}
          />
          <SearchIconContainer>
            <Search />
          </SearchIconContainer>
          <CloseButton
            id={getFullElementId('clearInput', 'button')}
            filter={filter}
            onClick={($event: any) => {
              clearFilter($event);
              setSortedItems(items);
              setHoverKeyIndex(-1);
            }}
          >
            <Close />
          </CloseButton>
          <StyledChildContainer height={height}>
            <AutoSizer>
              {({height}) => (
                <List
                  height={height ? height : 200}
                  rowCount={Math.max(filteredElementNodes.length, 1)}
                  rowHeight={30}
                  scrollToIndex={hoverIndex}
                  rowRenderer={(row: any) => optionRenderer(row)}
                  width={240}
                />
              )}
            </AutoSizer>
          </StyledChildContainer>

          <StyledBorder />
          <StyledApplyButtonContainer>
            <StyledApplyButton
              id={getFullElementId('apply', 'button')}
              disabled={!isDirty}
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => multiSelect()}
              style={{backgroundColor: 'transparent'}}
            >
              <StyledApplyText isDirty={isDirty}>Apply</StyledApplyText>
            </StyledApplyButton>
          </StyledApplyButtonContainer>
        </StyledListContainer>
      </>
    </StyledWatchListItemsContainer>
  );
};

export default WatchListItemsList;
