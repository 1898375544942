/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Types
 */

import {Dispatch} from 'react';

import {InitState} from './reducers/InitReducer';
import {AppState} from './reducers/AppReducer';
import {CustomerState} from './reducers/CustomerReducer';
import {DatePickerState} from './reducers/DatePickerReducer';
import {FleetMapState} from './reducers/FleetMapReducer';
import {FlightListState} from './reducers/FlightListReducer';
import {FlightDetailsState} from './reducers/FlightDetailsReducer';
import {ConnectivityPlannerState} from './reducers/ConnectivityPlannerReducer';
import {AircraftStatusListState} from './reducers/AircraftStatusListReducer';
import {SummaryMetricsState} from './reducers/SummaryMetricsReducer';
import {TailListState} from './reducers/TailListReducer';

export enum CommonAction {
  PROCESS_URL_PARAMS = 'PROCESS_URL_PARAMS'
}

export interface IStore {
  init: InitState;
  app: AppState;
  customer: CustomerState;
  datePicker: DatePickerState;
  fleetMap: FleetMapState;
  flightList: FlightListState;
  flightDetails: FlightDetailsState;
  connectivityPlanner: ConnectivityPlannerState;
  aircraftStatusList: AircraftStatusListState;
  summaryMetrics: SummaryMetricsState;
  tailList: TailListState;
}

export interface IAction {
  type: string;
  payload?: unknown;
}

export interface UrlParamsPayload {
  path: string;
  params: any;
}

export interface IContext {
  store: IStore;
  dispatch: Dispatch<IAction>;
}

export interface IStoreProvider {
  reducer: any;
  children: any;
}
