/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tooltip Series Label used to identify chart series name
 */

import React from 'react';
import {FontSizeProperty, FontWeightProperty, LineHeightProperty, TextAlignProperty, WidthProperty} from 'csstype';
import {
  TOOLTIP_SERIES_TEXT_FONT_SIZE,
  FONT_WEIGHT_NORMAL,
  TOOLTIP_SERIES_LINE_HEIGHT
} from '../../../../../utils/constants';

export interface SeriesLabelProps {
  label: number | string;
  fontSize?: FontSizeProperty<string>;
  fontWeight?: FontWeightProperty;
  lineHeight?: LineHeightProperty<string>;
  textAlign?: TextAlignProperty;
  width: WidthProperty<string>;
}

const SeriesLabel: React.FC<SeriesLabelProps> = ({
  label,
  fontSize = TOOLTIP_SERIES_TEXT_FONT_SIZE,
  fontWeight = FONT_WEIGHT_NORMAL,
  lineHeight = TOOLTIP_SERIES_LINE_HEIGHT,
  textAlign = 'left',
  width = undefined
}: SeriesLabelProps): JSX.Element => {
  return (
    <span
      style={{
        display: 'inline-block',
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        overflow: 'hidden',
        textAlign: textAlign,
        minWidth: width,
        width: 'auto'
      }}
    >
      {label}
    </span>
  );
};

export default SeriesLabel;
