/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Reducer for store management
 */

import {IAction, IStore} from './types';
import {actionTypeHasGivenContext, removeContextFromActionType} from './reducerUtils';
import {InitReducer, INIT_STORE_CONTEXT} from './reducers/InitReducer';
import {CUSTOMER_STORE_CONTEXT, CustomerReducer} from './reducers/CustomerReducer';
import {DATE_PICKER_STORE_CONTEXT, DatePickerReducer} from './reducers/DatePickerReducer';
import {FLEET_MAP_STORE_CONTEXT, FleetMapReducer} from './reducers/FleetMapReducer';
import {FLIGHT_LIST_STORE_CONTEXT, FlightListReducer} from './reducers/FlightListReducer';
import {FLIGHT_DETAILS_STORE_CONTEXT, FlightDetailsReducer} from './reducers/FlightDetailsReducer';
import {CONNECTIVITY_PLANNER_STORE_CONTEXT, ConnectivityPlannerReducer} from './reducers/ConnectivityPlannerReducer';
import {APP_STORE_CONTEXT, AppReducer} from './reducers/AppReducer';
import {AIRCRAFT_STATUS_LIST_STORE_CONTEXT, AircraftStatusListReducer} from './reducers/AircraftStatusListReducer';
import {SUMMARY_METRICS_STORE_CONTEXT, SummaryMetricsReducer} from './reducers/SummaryMetricsReducer';
import {TAIL_LIST_STORE_CONTEXT, TailListReducer} from './reducers/TailListReducer';

export const Reducer = (
  {
    init,
    app,
    customer,
    datePicker,
    fleetMap,
    flightList,
    flightDetails,
    connectivityPlanner,
    aircraftStatusList,
    summaryMetrics,
    tailList
  }: IStore,
  action: IAction
) => ({
  [INIT_STORE_CONTEXT]: InitReducer(init, action),
  [APP_STORE_CONTEXT]: AppReducer(app, action),
  [CUSTOMER_STORE_CONTEXT]: CustomerReducer(customer, action),
  [DATE_PICKER_STORE_CONTEXT]: DatePickerReducer(datePicker, action),
  [FLEET_MAP_STORE_CONTEXT]: FleetMapReducer(fleetMap, action),
  [FLIGHT_LIST_STORE_CONTEXT]: FlightListReducer(flightList, action),
  [FLIGHT_DETAILS_STORE_CONTEXT]: FlightDetailsReducer(flightDetails, action),
  [CONNECTIVITY_PLANNER_STORE_CONTEXT]: ConnectivityPlannerReducer(connectivityPlanner, action),
  [AIRCRAFT_STATUS_LIST_STORE_CONTEXT]: AircraftStatusListReducer(aircraftStatusList, action),
  [SUMMARY_METRICS_STORE_CONTEXT]: SummaryMetricsReducer(summaryMetrics, action),
  [TAIL_LIST_STORE_CONTEXT]: TailListReducer(tailList, action)
});

/**
 * Passes the given action down to the provided reducers and gives them each an opportunity to update the state
 * @param storeContext The store context to limit which actions should be processed
 * @param state The initial state
 * @param action The action to be processed
 * @param reducers The reducers to delegate processing to
 * @param defaultState The default state
 * @returns The updated state
 */
export const delegateToReducers = (
  storeContext: string,
  state: any,
  action: any,
  reducers: {[key: string]: (state: any, action: IAction, defaultState: any) => any},
  defaultState: any
) => {
  // Don't delegate to reducers if the action has a different context, but do allow actions without any context
  if (actionTypeHasGivenContext(action.type, storeContext, true)) {
    return Object.keys(reducers).reduce(
      (memo: any, reducerKey: any) => ({
        ...memo,
        ...reducers[reducerKey](
          memo,
          {
            ...action,
            type: removeContextFromActionType(action.type, storeContext)
          },
          defaultState
        )
      }),
      state
    );
  }
  return state;
};
