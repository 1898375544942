/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: The popup shown when an aircraft is hovered on flight map
 */

import moment from 'moment';
import React from 'react';
import LastHeardFromIcon from '@mui/icons-material/AccessTime';
import {LoadingSpinner} from '@viasat/insights-components';

import {
  PopupNetworkTypeFragment,
  PopupTitleFragment,
  PopupTimeFragment,
  HoveredAircraftPopupProps
} from './AircraftPopupCommon';
import {PopupBody, PopupContainer, PopupHeader, PopupRow, Span} from './AircraftPopupStyles';
import {LoadingFlightPathContainer, LoadingFlightPathCaptionProps} from '../MapStyles';
import {useStore} from '../../../store/Store';
import {NO_DATA_INDICATOR, ConnectionStatus} from '../../../utils/constants';
import {splitString} from '../../../utils/MapUtil';

/**
 * Show popup when an aircraft is hovered on flight map
 * @param params
 * @returns Popup container
 */
const HoveredAircraftPopup: React.FC<HoveredAircraftPopupProps> = ({
  status,
  aircraftId,
  tailId,
  serialNumber,
  endUser,
  lastHeard,
  networkType,
  mapDivRef,
  aircraftInfo,
  onMouseEnter,
  onMouseLeave,
  zIndex,
  border,
  highlightPopup,
  showLargePopup,
  flightPhase,
  departureTstamp,
  arrivalTstamp,
  departureTstampLabel,
  arrivalTstampLabel,
  isLabTerminal = false
}) => {
  const elementIdBase = `fleetMap--hoveredAircraftPopup`;
  const {store} = useStore();
  const {airports} = store.app;
  const {flightPathUnavailable, view, selectedAircraftId, selectedOfflineAircraft} = store.fleetMap;
  const isolateRoute = view.isolateRoute === 'true';

  let startTimeStamp = moment.utc();
  const enableTimeOverride = sessionStorage.timeOverrideEnable;
  if (enableTimeOverride !== undefined && enableTimeOverride === 'true') {
    startTimeStamp = sessionStorage.timeOverrideValue;
  }

  return (
    <PopupContainer
      id={`${elementIdBase}-container`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      zIndex={zIndex}
      showLargePopup={showLargePopup}
      border={border}
      highlightPopup={highlightPopup}
      display={
        selectedAircraftId
          ? selectedAircraftId === aircraftId
            ? 'none'
            : isolateRoute
            ? 'none'
            : 'inline-block'
          : 'inline-block'
      }
    >
      <>
        {flightPathUnavailable && status === ConnectionStatus.OFFLINE && selectedOfflineAircraft === aircraftId ? (
          <LoadingFlightPathContainer
            bottom={showLargePopup ? '100px' : '50px'}
            left={showLargePopup ? '10px' : '0px'}
            width={showLargePopup ? '150px' : '133px'}
            padding={showLargePopup ? '2px 10px 2px 25px' : '2px 4px 2px 4px'}
          >
            <LoadingSpinner
              id={`${elementIdBase}-loading-spinner`}
              direction="row"
              caption="Flight Path Unavailable"
              captionProps={LoadingFlightPathCaptionProps}
              size={0}
            />
          </LoadingFlightPathContainer>
        ) : (
          <></>
        )}
        <PopupHeader
          font={14}
          borderRadius={
            !showLargePopup || status === ConnectionStatus.CONNECTED || status === ConnectionStatus.IMPAIRED
              ? '4px 4px 4px 4px'
              : '4px 4px 0px 0px'
          }
        >
          <PopupNetworkTypeFragment
            iconSize={8}
            fontSize={10}
            height={10}
            elementIdBase={elementIdBase}
            status={status}
            networkType={networkType}
          />
          <PopupTitleFragment
            availDotSize={14}
            primaryIdFontSize={14}
            secondaryIdFontSize={12}
            flightPhaseFontSize={10}
            flightPhaseIconSize={14}
            outerPadding={'8px 10px 0 10px'}
            status={status}
            tailId={tailId}
            endUser={endUser}
            isLargePopup={showLargePopup}
            serialNumber={serialNumber}
            elementIdBase={`popup-title-fragment-${elementIdBase}`}
            flightPhase={flightPhase}
            mapDivRef={mapDivRef}
            isLabTerminal={isLabTerminal}
          />
          {showLargePopup ? (
            <PopupTimeFragment
              directionIconSize={12}
              timeLabelFontSize={12}
              timeFontSize={12}
              utcFontSize={10}
              elementIdBase={elementIdBase}
              mapDivRef={mapDivRef}
              padding={status === ConnectionStatus.DISCONNECTED ? '0px 0px 4px 0px' : '0px 0px 8px 0px'}
              departureTstamp={departureTstamp}
              arrivalTstamp={arrivalTstamp}
              flightPhase={flightPhase}
              departureTstampLabel={departureTstampLabel}
              arrivalTstampLabel={arrivalTstampLabel}
              aircraftInfo={aircraftInfo}
              airports={airports}
            />
          ) : (
            <></>
          )}
        </PopupHeader>
        {showLargePopup ? (
          <PopupBody
            defaultFontSize={14}
            labelFontSize={12}
            valueFontSize={12}
            unitsFontSize={12}
            iconSize={12}
            outerPadding={undefined}
            innerPadding={'4px 2px'}
          >
            {status === ConnectionStatus.OFFLINE || status === ConnectionStatus.DISCONNECTED ? (
              <>
                <PopupRow padding={'2px 10px 6px 10px'}>
                  <Span className="data-row-container top-border">
                    <LastHeardFromIcon style={{height: '14px', width: '14px'}} />
                    <Span className="label">Last heard from</Span>
                    <Span className="value" id={`${elementIdBase}linkQuality-label`}>
                      {lastHeard ? moment.utc(splitString(lastHeard, '.', 0)).from(startTimeStamp) : NO_DATA_INDICATOR}
                    </Span>
                  </Span>
                </PopupRow>
              </>
            ) : (
              <></>
            )}
          </PopupBody>
        ) : (
          <></>
        )}
      </>
    </PopupContainer>
  );
};

export default HoveredAircraftPopup;
