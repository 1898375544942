/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Reusable common popup component
 */

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {PopupBox, DialogAlertPopupProps} from './DialogAlertPopupStyles';
import {getElementIdFromSectionBase, getElementIdSectionBase} from '../../utils/ElementIdUtils';

export const DialogAlertPopup: React.FC<DialogAlertPopupProps> = ({
  idBase,
  trigger,
  heading,
  message,
  dialogAlertBoxActions,
  onClose,
  dialogBoxHeight,
  dialogColor,
  dialogMessageHeight,
  dialogAlertIcon
}: DialogAlertPopupProps) => {
  return trigger ? (
    <PopupBox
      key="popup-box"
      className="popup"
      boxHeight={dialogBoxHeight}
      messageHeight={dialogMessageHeight}
      boxColor={dialogColor}
    >
      <div className="popup-envelop">
        <div style={{display: 'flex'}}>
          {dialogAlertIcon}
          <span className="popup-heading">{heading}</span>
          {onClose ? <CloseIcon
            style={{cursor: 'pointer'}}
            id={getElementIdFromSectionBase(idBase, 'alert-close', '0')}
            onClick={onClose}
          /> : <></>}
        </div>
        <div id={getElementIdSectionBase(idBase, 'alert-message')} className="popup-message">{message}</div>
        {dialogAlertBoxActions ? <div className="popup-buttons">{dialogAlertBoxActions}</div> : <></>}
      </div>
    </PopupBox>
  ) : (
    <></>
  );
};
export default DialogAlertPopup;
