/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Open Case List Popup
 */
import React, {useEffect, useMemo, useState} from 'react';
import {isNil} from 'lodash';
import {useStore} from '../../../store/Store';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import 'react-base-table/styles.css';
import BaseTable, {AutoResizer} from 'react-base-table';
import PopUpModal from '../../common/Modal';
import useFetch from '../../../utils/useFetch';
import {LoadingBar, LoadingBarType, LoadingSpinner} from '@viasat/insights-components';
import {
  SW_REVISIONS_MODAL_TITLE_COLOR,
  POPUP_TEXT_COLOR,
  CLOSE_ICON_COLOR,
  GRID_CELL_LINK_COLOR,
  POPUP_BLOCK_BACKGROUND_COLOR,
  PROGRESS_BAR_BG_COLOR,
  SUPPORT_BORDER_COLOR,
  CASE_LIST_TEXT_COLOR,
  HEADER_BORDER_COLOR,
  NO_OPEN_CASE_ICON_COLOR,
  CASE_MODAL_SCROLL_COLOR,
  CASE_MODAL_SCROLL_THUMB
} from '../../common/theme/Colors';
import {DATE_TIME_LOCAL_FORMAT_MINUTES} from '../../../utils/DateTimeUtils';
import NoOpenCaseIcon from '../../common/theme/icons/NoOpenCaseIcon';
import openCaseListQuery, {IOpenCaseList} from '../../../store/queries/aircraftStatusList/openCaseListQuery';
import BoldSpan from '../../common/BoldSpan';
import requestCaseUpdateQuery from '../../../store/queries/aircraftStatusList/requestCaseUpdateQuery';

const OpenCaseListModalHeaderContainer = styled.div<{isCaseAvailable: any}>`
  width: ${(props) => (props.isCaseAvailable ? '50vw' : '30vw')};
  display: flex-inline;
  margin-left: 7px;
  margin-right: -16px;
  padding: 0px 0px 16px;
  margin-top: -5px;
  .headerText {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    display: flex;
    flex-direction: row;
    color: ${POPUP_TEXT_COLOR};
    visibility: ${(props) => (props.isCaseAvailable ? 'visible' : 'hidden')};
  }
  .closeIcon {
    width: 3%;
    margin-top: ${(props) => (props.isCaseAvailable ? '-31px' : '2px')};
    color: ${CLOSE_ICON_COLOR};
    cursor: pointer;
    position: absolute;
    right: ${(props) => (props.isCaseAvailable ? '10px' : '0px')};
  }
  .tableHeader {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: ${CASE_LIST_TEXT_COLOR};
  }
`;

const HeaderTitle = styled.div`
  width: 72%;
`;

const HeaderTitleLoading = styled.div`
  width: 10%;
`;

const CaseListTable = styled.div<{rows: number}>`
  width: 62vw;
  min-height: 14vh;
  height: ${(props) => `calc(10vh * ${props.rows})`};
  max-height: 52vh;
  padding: 0 16px 0px 16px;
  .BaseTable__header-row,
  .BaseTable__row {
    border-bottom: none;
    background-color: white;
  }
  .BaseTable__table-main {
    outline: none;
  }
  .BaseTable__header-row {
    border-bottom: 1px solid;
    border-bottom-color: ${HEADER_BORDER_COLOR};
  }
  .BaseTable__body {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${CASE_MODAL_SCROLL_COLOR};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: ${CASE_MODAL_SCROLL_THUMB};
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${CASE_MODAL_SCROLL_THUMB};
      border-radius: 4px;
    }
  }
`;

const RowDataStyle = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${POPUP_TEXT_COLOR};
`;

const StyledLink = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${GRID_CELL_LINK_COLOR};
  cursor: pointer;
`;

const NoOpenCaseModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 38vw;
  height: 25vh;
  textalign: center;
  align-items: center;
  gap: 8px;
  font-style: normal;
  text-align: center;
`;

const NoCaseText = styled.div`
  font-weight: 800;
  font-size: 24px;
  display: flex;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${POPUP_TEXT_COLOR};
`;

const ForAssistance = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${CASE_LIST_TEXT_COLOR};
  padding: 5px;
`;

const RequestSupport = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  gap: 16px;
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-top: 1px solid ${PROGRESS_BAR_BG_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 37vw;
  height: 5vh;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

const SupportButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 4px;
  border: 1px solid ${SUPPORT_BORDER_COLOR};
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const ButtonText = styled.span`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${POPUP_TEXT_COLOR};
`;

interface OpenCaseListPopupProps {
  selectedAircraft: any;
  caseCount: number;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  setRequestUpdateStatus: (openModal: boolean) => void;
}

const OpenCaseListPopup: React.FC<OpenCaseListPopupProps> = ({
  selectedAircraft,
  caseCount,
  openModal,
  setOpenModal,
  setRequestUpdateStatus
}) => {
  const {store} = useStore();
  const groupCode = store.customer.current.code ? store.customer.current.code : '';
  const [selectedCase, setSelectedCase] = useState(null);
  const queryParamsWithAircraftId = useMemo(
    () => ({aircraftId: selectedAircraft.aircraftId, groupCode: groupCode}),
    [selectedAircraft, groupCode]
  );

  const queryParamsWithCaseDetail = useMemo(() => {
    if (!selectedCase || !selectedAircraft) return;
    return {caseDetail: selectedCase, aircraftDetail: selectedAircraft};
  }, [selectedAircraft, selectedCase]);

  // Open Case List Details for the selected aircraft
  const {data: openCaseList, isLoading: isOpenCaseListLoading} = useFetch<IOpenCaseList[]>(
    openCaseListQuery,
    queryParamsWithAircraftId
  );

  const {data: requestCaseUpdate, isLoading: isRequesSubmitting} = useFetch<any>(
    requestCaseUpdateQuery,
    queryParamsWithCaseDetail
  );

  useEffect(() => {
    if (isRequesSubmitting) return;
    if (!isRequesSubmitting && requestCaseUpdate) {
      setSelectedCase(null);
      setOpenModal(false);
      setRequestUpdateStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCaseUpdate, isRequesSubmitting]);

  /**
   * method to close the modal popup
   */
  const closeModal = () => {
    setOpenModal(false);
  };

  /**
   * method to format the date and time stamp
   */
  const formatTimeStamp = (data) => {
    return moment.utc(data.cellData).format(DATE_TIME_LOCAL_FORMAT_MINUTES);
  };

  const headerRowStyle = {color: CASE_LIST_TEXT_COLOR, fontSize: '16px'};

  const caseColumns = [
    {
      key: 'caseNumber',
      dataKey: 'caseNumber',
      title: 'Case',
      resizable: true,
      width: 110,
      style: headerRowStyle
    },
    {
      key: 'caseType',
      dataKey: 'caseType',
      title: 'Type',
      resizable: true,
      width: 130,
      style: headerRowStyle
    },
    {
      key: 'caseSubject',
      dataKey: 'caseSubject',
      title: 'Subject',
      resizable: true,
      width: 320,
      style: headerRowStyle
    },
    {
      key: 'caseStatus',
      dataKey: 'caseStatus',
      title: 'Status',
      resizable: true,
      width: 170,
      style: headerRowStyle
    },
    {
      key: 'createdTstamp',
      dataKey: 'createdTstamp',
      title: 'Date/Time Opened',
      resizable: true,
      width: 200,
      style: headerRowStyle,
      cellRenderer: ({cellData}) => formatTimeStamp({cellData})
    },
    {
      key: 'action',
      width: 150,
      cellRenderer: ({rowData}) => (
        <StyledLink onClick={() => onRequestUpdateClick(rowData)}>Request an Update</StyledLink>
      )
    }
  ];

  /**
   * method for the Request on an Update click event
   * @returns null
   */
  const onRequestUpdateClick = (rowData) => {
    setSelectedCase(rowData);
  };

  /**
   * method to format the row data
   * @returns formatted cell for each row information
   */
  const Cell = ({columnIndex, cellData}) => {
    if (columnIndex === 0) {
      return <RowDataStyle style={{color: CASE_LIST_TEXT_COLOR}}>{cellData}</RowDataStyle>;
    }
    return <RowDataStyle>{cellData}</RowDataStyle>;
  };

  /**
   * method that return the header of the software revisions modal
   * @returns software revisions modal header
   */
  const OpenCaseListModalHeader = ({isLoading}) => {
    let isCaseAvailable = false;
    if (caseCount > 0) isCaseAvailable = true;
    return (
      <OpenCaseListModalHeaderContainer isCaseAvailable={isCaseAvailable}>
        <div className="headerText">
          <HeaderTitle>
            Open Cases of SN / Tail ID: {selectedAircraft.serialNumber} / {selectedAircraft.tailId}
          </HeaderTitle>

          {isLoading ? (
            <HeaderTitleLoading>
              <LoadingSpinner id="openCaseListModelHeaderLoading" size={20} />
            </HeaderTitleLoading>
          ) : (
            <></>
          )}
        </div>
        <CloseIcon className="closeIcon" onClick={closeModal} />
      </OpenCaseListModalHeaderContainer>
    );
  };

  return (
    <div>
      <PopUpModal
        id="openCaseListModal"
        open={openModal}
        title={<OpenCaseListModalHeader isLoading={isRequesSubmitting && !isNil(selectedCase)} />}
        titlecolor={SW_REVISIONS_MODAL_TITLE_COLOR}
        borderRadius="16px"
        padding={caseCount > 0 ? '16px' : '0px'}
      >
        {caseCount > 0 ? (
          <>
            {openCaseList && !isOpenCaseListLoading ? (
              <CaseListTable rows={caseCount}>
                <AutoResizer>
                  {({width, height}) => (
                    <BaseTable
                      fixed
                      columns={caseColumns}
                      data={openCaseList}
                      width={width}
                      height={height}
                      rowHeight={70}
                      scrollToTop={100}
                      style={{boxShadow: 'none'}}
                      forceUpdateTable
                      components={{
                        TableCell: Cell
                      }}
                    />
                  )}
                </AutoResizer>
              </CaseListTable>
            ) : (
              <CaseListTable rows={1}>
                <LoadingBar type={LoadingBarType.default} height={6} id="loading-bar" width={200} />
              </CaseListTable>
            )}
          </>
        ) : (
          <>
            <NoOpenCaseModal>
              <NoOpenCaseIcon id="noOpenCase" sx={{color: NO_OPEN_CASE_ICON_COLOR, width: 108, height: 88}} />
              <NoCaseText>
                No open cases found for - {selectedAircraft.serialNumber} / {selectedAircraft.tailId}.
              </NoCaseText>
              <ForAssistance>
                For immediate assistance, please call
                <BoldSpan>1-833-VSATAIR (U.S. Toll-Free) or +1-760-893-5713 (International)</BoldSpan>
              </ForAssistance>
            </NoOpenCaseModal>
            <RequestSupport>
              <SupportButton>
                <ButtonText>Request Support</ButtonText>
              </SupportButton>
            </RequestSupport>
          </>
        )}
      </PopUpModal>
    </div>
  );
};

export default OpenCaseListPopup;
