/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Service Plans query
 */
import {Query} from '../types';

export interface servicePlans {
  servicePlanName: string;
}

const servicePlansQuery: Query<servicePlans[] | null> = {
  route: 'common/servicePlans',
  transform: null
};

export default servicePlansQuery;
