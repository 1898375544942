/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description - Connectivity outlook events timeline  connectivity icon and tooltip
 */
import React from 'react';
import {formatMomentInputAsTimeWithSeconds, formatStartEndDurationValue} from '../../utils/DateTimeUtils';
import ConnectivityStatusIcon from '../common/theme/icons/ConnectivityStatusIcon';
import {SeriesDataPoint} from '../flightDetails/charts/EventsTimelineUtils';
import {ConnectivityOutlookIconTextColors, ConnectivityOutlookIconColors} from './ConnectivityOutlookUtils';

interface IEventsTimelineTooltipProps {
  status: string;
  point: SeriesDataPoint;
}

const EventsTimelineTooltip: React.FC<IEventsTimelineTooltipProps> = (props) => {
  const {status, point} = props;
  return (
    <div className="data-label-tooltip" style={{width: 20, height: 20, borderRadius: '50%', marginTop: -12}}>
      <div
        style={{width: 1, height: 8, marginLeft: 7, borderLeft: `1px solid ${ConnectivityOutlookIconColors[status]}`}}
      />
      <div className="tooltip tooltip-box">
        <div>
          <span className="time-range">
            {formatMomentInputAsTimeWithSeconds(point.low)} - {formatMomentInputAsTimeWithSeconds(point.high)}
          </span>
          <span className="duration">({formatStartEndDurationValue(point.low, point.high)})</span>
        </div>
        <div>
          <span className="default-border"></span>
        </div>
        <div className="event-status">
          <span className="status-dot" style={{background: `${ConnectivityOutlookIconColors[status]}`}}></span>
          <span>{point.eventName}</span>
        </div>
      </div>

      <div className="status-icon" style={{marginTop: -25, marginLeft: -4}}>
        <ConnectivityStatusIcon
          id="events-timeline-icons"
          width={20}
          height={20}
          text={point.iconText?.toString()}
          color={ConnectivityOutlookIconColors[status]}
          textColor={ConnectivityOutlookIconTextColors[status]}
          strokeWidth={1}
          textX="3"
          textY="0.5"
        />
      </div>
    </div>
  );
};
export default EventsTimelineTooltip;
