/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for datePicker reducer
 */

import moment from 'moment';
import {isEmpty} from 'lodash';
import {SnackBarState, createSnackBar, SnackBarReducer} from './SnackBarReducer';
import {Severity} from '@viasat/insights-components';
import {DATE_TIME_LOCAL_FORMAT, getDefaultStartDate, getDefaultEndDate, getMaxMoment} from '../../utils/DateTimeUtils';

import {delegateToReducers} from '../Reducer';
import {isValueChanged} from '../UrlMap';
import {CommonAction} from '../types';
import {datePickerGuardrailLimitMonths, removeDatePickerGuardrails} from '../../utils/config';

export const DATE_PICKER_STORE_CONTEXT = 'datePicker';

export interface DatePickerState {
  startDate: string;
  endDate: string;
  persistStartDate?: boolean;
  persistEndDate?: boolean;
  snackBar?: SnackBarState;
}

export const InitialDatePickerState: DatePickerState = {
  startDate: getDefaultStartDate(),
  endDate: getDefaultEndDate(),
  persistStartDate: false,
  persistEndDate: false,
  snackBar: []
};

export enum DatePickerAction {
  SET_DATE_RANGE = 'SET_DATE_RANGE'
}

export const DatePickerSessionStoreKeys: string[] = ['startDate', 'endDate', 'persistStartDate', 'persistEndDate'];

/**
 * Validate Date Picker State
 * @param state State of date picker
 * @returns Valid state
 */
export const validateDatePicker = (state: DatePickerState): DatePickerState => {
  const snackBar = [];
  const {persistStartDate, persistEndDate} = state;

  const startDate = persistStartDate ? state.startDate : getDefaultStartDate();
  const endDate = persistEndDate ? state.endDate : getDefaultEndDate();
  const startMoment = moment.utc(startDate);
  const endMoment = moment.utc(endDate);

  const maxMoment = getMaxMoment();
  const minMoment = removeDatePickerGuardrails
    ? moment.utc(0)
    : getMaxMoment().subtract(datePickerGuardrailLimitMonths, 'month');

  const reset = () => {
    state.startDate = getDefaultStartDate();
    state.endDate = getDefaultEndDate();
    state.persistEndDate = false;
    state.persistStartDate = false;
  };

  const msg = [];
  const dat = [];
  let errorFound = false;

  if (!startDate || !startMoment.isValid() || startMoment.isAfter(maxMoment) || startMoment.isBefore(minMoment)) {
    msg.push('Start');
    dat.push(`"${startDate}"`);
    errorFound = true;
  }

  if (!endDate || !endMoment.isValid() || endMoment.isAfter(maxMoment) || endMoment.isBefore(minMoment)) {
    msg.push('End');
    dat.push(`"${endDate}"`);
    errorFound = true;
  }

  // We know both the start date and end dates are valid now, lets finish checks
  if (!errorFound) {
    if (endMoment.isBefore(startMoment)) {
      snackBar.push(
        createSnackBar({
          severity: Severity.WARNING,
          message: `Invalid Date Range: ${startDate} to ${endDate}`,
          getFullElementId: (name, type) => `${name}-${type}`
        })
      );

      errorFound = true;
    } else {
      // Everything is finally good, just set it for the default start and end dates
      state = {
        ...state,
        startDate,
        endDate
      };
    }
  }

  if (msg.length > 0) {
    snackBar.push(
      createSnackBar({
        severity: Severity.WARNING,
        message: `Invalid ${msg.join('/')} Date: ${dat.join(' to ')}`,
        getFullElementId: (name, type) => `${name}-${type}`
      })
    );
  }

  if (errorFound) {
    reset();
  }

  return {
    ...state,
    snackBar
  };
};

/**
 * Date Picker action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const DatePickerReducer = (state: DatePickerState, action: any): DatePickerState => {
  switch (action.type) {
    case DatePickerAction.SET_DATE_RANGE:
      const {startDate, endDate, persistStartDate, persistEndDate, viewContext} = action.payload;

      if (!viewContext) {
        state = {
          ...state,
          startDate: moment.utc(startDate).format(DATE_TIME_LOCAL_FORMAT),
          endDate: moment.utc(endDate).format(DATE_TIME_LOCAL_FORMAT),
          persistStartDate,
          persistEndDate
        };
      }

      break;

    case CommonAction.PROCESS_URL_PARAMS:
      const updates: any = {};

      if (isValueChanged(state.startDate, action.payload.params.startDate)) {
        updates.startDate = action.payload.params.startDate;
        updates.persistStartDate = true;
      }

      if (isValueChanged(state.endDate, action.payload.params.endDate)) {
        updates.endDate = action.payload.params.endDate;
        updates.persistEndDate = true;
      }

      if (!isEmpty(updates)) {
        state = validateDatePicker({
          ...state,
          ...updates
        });
      }
      break;

    default:
      break;
  }

  state = delegateToReducers(
    DATE_PICKER_STORE_CONTEXT,
    state,
    action,
    {
      snackBar: SnackBarReducer
    },
    InitialDatePickerState
  );

  return state;
};
