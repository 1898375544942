/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connectivity Outlook Page Styles
 */

import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import * as Colors from '../common/theme/Colors';
import BoldSpan from '../common/BoldSpan';
import {MAP_SEARCH_RESULTS_TEXT_COLOR} from '../common/theme/Colors';

export const HeaderButton = styled.div<{disabled: boolean}>`
  display: flex;
  position: relative;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  margin: 4px 10px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => (props.disabled ? `${Colors.DISABLED_UPLOAD_BTN_COLOR}` : `${Colors.MODAL_FONT_COLOR}`)};
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

export const PrintText = styled.span`
  font-family: 'Source Sans Pro';
  padding-left: 8px;
  padding-top: 1px;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  @media only screen and (max-width: 875px) {
    display: none;
  }
`;

export const PrintHeader = styled.div<{top?: number}>`
  display: none;
  font-weight: 700;
  font-size: 22px;
  font-family: 'Source Sans Pro';
  color: ${Colors.POPUP_TEXT_COLOR};
  position: ${(props) => (props.top ? `absolute` : 'unset')};
  top: ${(props) => (props.top ? `${props.top}px` : 'unset')};
  .duration {
    font-weight: 400;
    left: 90%;
    position: absolute;
  }
  @media print {
    display: table-row;
    position: absolute;
    margin-bottom: 50px;
    width: 1310px;
    border-bottom: 1px solid black;
  }
`;

export const PrintFooter = styled.div`
  display: none;
  position: absolute;
  top: 2190px;
  left: 1110px;
  font-weight: 600;
  color: ${Colors.EXPANSIONPANEL_TEXT};
  position: absolute;
  direction: rtl;
  line-height: 2;
  @media print {
    display: grid;
  }
`;

export const ConnectivityOutlookBody = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  @media print {
    width: 1900px;
    height: 1980px;
    right: 150px;
    margin-top: 15px;
    -webkit-print-color-adjust: exact !important;
  }
`;

export const ConnectivityOutlookOverallBody = styled.div<{height: number}>`
  width: 100%;
  overflow: hidden;
  @media print {
    transform: translateX(5%) translateY(1%) scale(1);
    width: 1900px;
    height: ${(props) => props.height}px;
    right: 150px;
    margin-top: 15px;
    -webkit-print-color-adjust: exact !important;
    @page {
      size: A3;
      page-break-before: always;
    }
  }
`;

export const ContinueLabel = styled.div`
  position: absolute;
  top: 1200px;
  left: 30px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.POPUP_TEXT_COLOR};
  display: none;
  @media print {
    display: block;
  }
`;

export const EventsContainer = styled.div<{checkHeight: boolean}>`
  width: 380px;
  min-height: 188px;
  height: calc(100vh - 303px);
  overflow: hidden;
  position: absolute;
  top: 45px;
  z-index: 1;
  background: ${Colors.WHITE};
  margin: 9px 0 0 0px;
  font-weight: normal;
  @media print {
    display: block;
    top: ${(props) => (props.checkHeight ? '2600px' : '1060px')};
    filter: none;
    width: 975px;
    height: auto;
    position: absolute;
    page-break-inside: always;
    background: transparent;
  }
`;

export const NoEventsMessage = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${Colors.POPUP_TEXT_COLOR};
  padding: 18px 0 20px 66px;
`;

export const FlightPlanHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 16px;
  justify-content: space-between;
  width: auto;
  height: 24px;
  background: ${Colors.FLIGHT_PLAN_HEADER_BG};
  color: ${Colors.WHITE};
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  .serialNumber {
    display: flex;
    flex-direction: row;
    position: static;
    width: 211px;
    height: 24px;
    left: 85px;
    top: 0px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    justify-content: right;
  }
  @media print {
    -webkit-print-color-adjust: exact !important;
    display: none;
  }
`;

export const FlightPlanDepart = styled.div<{hideBorder: boolean; height: number}>`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  width: auto;
  height: ${(props) => props.height}px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-color: ${Colors.POPUP_TEXT_COLOR};
  align-items: center;
  border-bottom: ${(props) => !props.hideBorder && `1px solid ${Colors.EVENTS_CARD_BORDER_COLOR}`};
  span {
    left: 40px;
    display: flex;
    flex-direction: row;
    width: 171px;
    margin-left: 20px;
  }
  .printAirport {
    display: none;
  }
  .printStyle {
    display: contents;
  }
  .departureTime {
    margin-left: 0px !important;
    text-align: right;
    text-transform: uppercase;
    white-space: nowrap;
    justify-content: right;
    &.changed {
      align-items: center;
      width: 130px;
      right: 5px;
      margin-left: 98px;
      background: ${Colors.EDIT_DEPARTURE_TIME_BG};
      color: ${Colors.WHITE};
      border-radius: 4px;
      padding: 5px 5px 1px 5px;
      margin-top: -2px;
      span {
        margin-left: 5px;
      }
      .reset-departure-time {
        margin-top: 5px;
        display: inline;
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  @media print {
    font-weight: 700;
    span {
      width: 120px;
    }
    .printStyle {
      display: flex;
      flex-direction: row-reverse;
    }
    .printAirport {
      display: contents;
    }
  }
`;

export const OutlookHeading = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    font-size: 10px;
    line-height: 16px;
    color: ${Colors.TABLE_HEADER_BG};
  }
  .aircraft-info {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const BackButton = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  padding: 4px;
  cursor: pointer;
  border-radius: 6px;
  color: #626262;
  &:hover {
    background: ${Colors.FLIGHT_DETAILS_BACK_BUTTON_COLOR};
  }
`;

export const FlightPlanArrival = styled.div<{hideBorder: boolean; height: number}>`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  align-items: center;
  width: auto;
  height: ${(props) => props.height}px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  border-top: ${(props) => !props.hideBorder && `1px solid ${Colors.EVENTS_CARD_BORDER_COLOR}`};
  span {
    margin-left: 20px;
    width: 171px;
  }
  .printAirport {
    display: none;
  }
  .printStyle {
    display: contents;
  }
  .arrivalTime {
    margin-left: 0px !important;
    justify-content: right;
    text-align: right;
    text-transform: uppercase;
    white-space: nowrap;
  }
  @media print {
    font-weight: 700;
    span {
      width: 120px;
    }
    .printStyle {
      display: flex;
      flex-direction: row-reverse;
    }
    .printAirport {
      display: contents;
    }
  }
`;

export const FlightPlanLeg = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  position: static;
  height: 32px;
  right: 0px;
  bottom: 50px;
  width: auto;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border-bottom: 1px solid ${Colors.HEADER_ICON_BORDER_COLOR};
  span {
    width: 50%;
  }
  @media print {
    display: none;
  }
`;

export const LoadingEvents = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

export const EventsTimelineContainer = styled.div`
  width: calc(100vw - 54px);
  bottom: 0px;
  left: 34px;
  right: 73px;
  z-index: 1;
  background: ${Colors.TRANSPARENT};
  font-weight: normal;
  @media print {
    position: absolute;
    top: 818px;
    left: -120px;
    width: 1510px;
    height: 226px;
    transform: scale(0.85);
    margin: 0px 0 0 0px;
    box-shadow: none;
  }
`;

export const UploadButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${Colors.UPLOAD_BTN_BG};
  border-radius: 20px;
  width: 161px;
  height: 32px;
  border: none;
  color: ${Colors.WHITE};
  font-size: 14px;
  font-family: Source Sans Pro;
  cursor: pointer;
  margin-left: 15px;
  &:disabled {
    background: ${Colors.DISABLED_VIEW_OUTLOOK_BTN_BG};
    cursor: not-allowed;
  }
`;

export const DeleteButton = styled.button`
  border: none;
  background: none;
  color: ${Colors.DELETE_FLIGHT_PLAN_ICON_COLOR};
  margin: 3px 0px 0px 30px;
  border-left: 1px solid ${Colors.HEADER_ICON_BORDER_COLOR};
  padding-left: 27px;
  svg {
    cursor: pointer;
  }
  &:disabled {
    color: #ccc;
    svg {
      cursor: not-allowed;
    }
  }
`;

export const AircraftSelectorMainContainer = styled.div`
  width: 514px;
  margin: 80px 0px 0px 563px;
`;

export const AircraftSelectorHeading = styled.div`
  width: 100%;
  height: 40px;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  margin: 8px 0px;
`;

export const AircraftSelectorContent = styled.div`
  width: 100%;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 8px 0px;
`;

export const AircraftSelector = styled.div`
  width: 100%;
  height: 48px;
  margin: 24px 0px;
  border-radius: 4px;
  background: ${Colors.WHITE};
  cursor: pointer;
  svg {
    width: 20px;
    height: 28px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 5px;
    display: inline-block;
    float: right;
  }
`;

export const AircraftSelectorContinueButton = styled(Button)<{disabled: boolean; left: number}>`
  width: 511px;
  height: 40px;
  border-radius: 1000px;
  left: ${(props) => props.left}px;
  background-color: ${(props) =>
    props.disabled ? `${Colors.AIRCRAFT_SELECTOR_BG_COLOR}` : `${Colors.FLIGHT_PLAN_HEADER_BG}`};
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
  &:hover {
    background-color: ${(props) =>
      props.disabled ? `${Colors.AIRCRAFT_SELECTOR_BG_COLOR}` : `${Colors.FLIGHT_PLAN_HEADER_BG}`};
  }
`;

export const AircraftListContinueButton = styled(Button)<{disabled: boolean; left: number; width: number}>`
  width: ${(props) => props.width}px;
  height: 40px;
  border-radius: 1000px;
  top: 7px;
  left: ${(props) => props.left}px;
  background-color: ${(props) =>
    props.disabled ? `${Colors.AIRCRAFT_SELECTOR_BG_COLOR}` : `${Colors.FLIGHT_PLAN_HEADER_BG}`};
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
  &:hover {
    background-color: ${(props) =>
      props.disabled ? `${Colors.AIRCRAFT_SELECTOR_BG_COLOR}` : `${Colors.FLIGHT_PLAN_HEADER_BG}`};
  }
`;

export const UploadContinueButton = styled(Button)<{disabled: boolean; left: number; width: number}>`
  width: ${(props) => props.width}px;
  height: 40px;
  border-radius: 1000px;
  left: ${(props) => props.left}px;
  background-color: ${(props) =>
    props.disabled ? `${Colors.AIRCRAFT_SELECTOR_BG_COLOR}` : `${Colors.FLIGHT_PLAN_HEADER_BG}`};
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
  &:hover {
    background-color: ${(props) =>
      props.disabled ? `${Colors.AIRCRAFT_SELECTOR_BG_COLOR}` : `${Colors.FLIGHT_PLAN_HEADER_BG}`};
  }
`;

export const AircraftListBackButton = styled(Button)`
  width: 94px;
  height: 40px;
  top: 7px;
  border-radius: 1000px;
  background-color: ${Colors.UPLOAD_MODAL_CANCEL_BTN_BG};
  cursor: pointer;
  &:hover {
    background-color: ${Colors.UPLOAD_MODAL_CANCEL_BTN_BG};
  }
`;

export const CancelButton = styled(Button)`
  width: 94px;
  height: 40px;
  border-radius: 1000px;
  background-color: ${Colors.UPLOAD_MODAL_CANCEL_BTN_BG};
  cursor: pointer;
  &:hover {
    background-color: ${Colors.UPLOAD_MODAL_CANCEL_BTN_BG};
  }
`;

export const CancelButtonLabel = styled.span`
  width: 46px;
  height: 24px;
  font-family: Source Sans Pro;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.WHITE};
  margin-left: -6px;
  margin-top: -2px;
  font-weight: bold;
  text-transform: none;
`;

export const ContinueButtonLabel = styled.div<{disabled: boolean}>`
  width: 163px;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.disabled ? `${Colors.DISABLED_UPLOAD_BTN_COLOR}` : `${Colors.WHITE}`)};
  text-transform: none;
  font-family: Source Sans Pro;
`;

export const AircraftSelectorList = styled.div`
  max-height: 420px;
  min-width: 260px;
  overflow-y: auto;
  padding: 0px 16px;
  padding: 0px;
`;

export const RowContainer = styled.div<{noValueFound: boolean}>`
  color: rgb(68, 68, 68);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: normal;
  height: 32px;
  letter-spacing: 0px;
  line-height: 20px;
  padding-left: 16px;
  align-items: center;
  cursor: ${(props) => (props.noValueFound ? 'auto' : 'pointer')};
  display: flex;
  &:hover {
    background: ${(props) => (props.noValueFound ? `${Colors.WHITE}` : `${Colors.AIRCRAFT_SEARCH_LIST_HOVER}`)};
  }
`;

export const AircraftList = styled.div<{noResults: boolean}>`
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: ${(props) => (props.noResults ? 'none' : 'solid')};
  border-width: thin;
  border-top: none;
  border-color: ${Colors.AIRCRAFT_LIST_BORDER_COLOR};
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.MAP_POPUP_BACKGROUND_COLOR};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
  }
`;

export const AircraftListNoItemsLabel = styled.span`
  font-size: 16px;
  margin-bottom: 4px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SearchInputClearIconContainer = styled.span`
  position: absolute;
  width: 18px;
  height: 18px;
  right: 31px;
  top: 122px;
  .clear-icon {
    width: 25px;
    height: 25px;
    margin-top: -2px;
    cursor: pointer;
    color: ${Colors.AIRCRAFT_LIST_BORDER_COLOR};
  }
`;

export const RowContainerWithBorder = styled.div<{noValueFound: boolean; isSelected: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  position: static;
  width: 511px;
  height: 40px;
  border: solid;
  border-width: thin;
  border-top: none;
  border-color: ${Colors.AIRCRAFT_LIST_BORDER_COLOR};
  left: 0px;
  top: 40px;
  background: ${(props) => (props.isSelected ? `${Colors.AIRCRAFT_SEARCH_LIST_HOVER}` : `${Colors.WHITE}`)};
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12);
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  cursor: ${(props) => (props.noValueFound ? 'auto' : 'pointer')};
  display: flex;
  font-weight: 400;
  &:hover {
    background: ${(props) => (props.noValueFound ? `${Colors.WHITE}` : `${Colors.AIRCRAFT_SEARCH_LIST_HOVER}`)};
  }
`;

export const LabelContainer = styled.div`
  padding-left: 16px;
  display: inline-block;
`;

export const SearchInput = styled.input`
  font-size: 14px;
  outline: none;
  border: none;
  color: ${Colors.AIRCRAFT_SEARCH_INPUT_COLOR};
  line-height: 20px;
  width: 450px;
  height: 20px;
  font-family: Source Sans Pro;
  position: absolute;
  margin-left: 15px;
  margin-top: 10px;
`;

export const PopperContainer = styled.div`
  position: absolute;
  top: 0px;
`;

export const LoadingSpinnerContainer = styled.div`
  position: absolute;
  top: 120px;
  left: 80px;
`;

export const SpinnerLabelContainer = styled.div`
  padding-top: 8px;
  padding-left: 20px;
  span {
    letter-spacing: 0.75px;
  }
  display: inline-block;
`;

export const AircraftSearchBox = styled.div`
  height: 40px;
  width: 543px;
  left: 0px;
  top: 0px;
  border: solid;
  border-width: thin;
  border-color: ${Colors.AIRCRAFT_LIST_BORDER_COLOR};
  background: ${Colors.WHITE};
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-radius: 0px;
  svg {
    width: 17px;
    height: 17px;
    margin-top: 3px;
    margin-left: 3px;
  }
  .search-icon {
    width: 25px;
    height: 25px;
    margin-top: 8px;
    margin-left: 10px;
  }
`;

export const SelectOption = styled.span<{isSelected: boolean}>`
  position: relative;
  width: 216px;
  height: 24px;
  top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: ${(props) =>
    props.isSelected
      ? `${Colors.AIRCRAFT_LIST_SELECT_OPTION_COLOR}`
      : `${Colors.AIRCRAFT_LIST_OPTION_CONTAINER_COLOR}`};
  margin: 0px 0px;
`;

export const AircraftListItem = styled.span`
  position: relative;
  width: 73px;
  height: 24px;
  left: 16px;
  top: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${Colors.AIRCRAFT_LIST_ITEM_COLOR};
  flex: none;
  flex-grow: 0;
  margin: 0px 0px;
  .detail {
    font-size: 14px;
    color: ${MAP_SEARCH_RESULTS_TEXT_COLOR};
  }
`;

export const AircraftListItemAircraftType = styled.span`
  position: relative;
  width: 149px;
  height: 24px;
  left: 15px;
  top: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${Colors.AIRCRAFT_LIST_ITEM_COLOR};
  flex: none;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const BlackBoldSpan = styled(BoldSpan)`
  color: ${Colors.BLACK};
`;

export const FlightPlanSummaryContainer = styled.div`
  display: block;
  position: relative;
  width: 470px;
  height: 216px;
  left: 38px;
  margin-bottom: 20px;
  border: 1px solid ${Colors.DROP_ZONE_GREY_COLOR};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${Colors.FLIGHT_PLAN_SUMMARY_TXT_COLOR};
`;

export const FlightPlanList = styled.div`
  display: block;
  width: 460px;
  height: 130px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const FlightPlanListItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  width: 100%;
  margin-bottom: 16px;
`;

export const FlightPlanConnector = styled.div`
  display: flex;
  width: 132px;
  height: 3px;
  background: ${Colors.FLIGHT_PLAN_CONNECTOR_BAR_COLOR};
  margin-top: 46px;
  border-radius: 5px;
  margin-right: 12px;
`;

export const FlightIcon = styled.div`
  display: flex;
  width: 19px;
  height: 20px;
  margin-top: 36px;
  margin-right: 12px;
  margin-left: 6px;
  transform: rotate(90deg);
`;

export const FlightPlanBody = styled.div`
  min-height: 0px;
  height: calc(100vh - 491px);
  overflow-y: scroll;
  width: inherit;
  &::-webkit-scrollbar {
    width: 6px;
    margin: 10px 2px 0 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.POPUP_SCROLLBAR_BG};
    border-radius: 4px 4px 2px 2px;
  }
  @media print {
    min-height: unset;
    height: unset;
    overflow-y: unset;
    width: 460px;
  }
`;
export const EventListBody = styled.div<{minHeight: number}>`
  min-height: ${(props) => props.minHeight}px;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  user-select: text;
  @media print {
    height: auto;
    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;
export const EventListLeftBorder = styled.div<{height: number}>`
  margin-left: 25px;
  background-color: ${Colors.FLIGHT_PLAN_CONNECTOR_BAR_COLOR};
  flex-direction: row;
  width: 2px;
  display: flex;
  height: ${(props) => props.height}px;
  @media print {
    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;
export const EventListRow = styled.div<{showBorder: boolean}>`
  display: flex;
  padding: 0px 16px 16px 16px;
  flex-direction: row;
  max-height: 39px;
  width: 340px;
  border-bottom-color: ${Colors.EVENTS_CARD_BORDER_COLOR};
  border-bottom-width: 1px;
  border-bottom-style: ${(props) => props.showBorder && 'solid'};
  @media print {
    width: 410px;
    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;

export const EventIcon = styled.div`
  max-height: 24px;
  margin-top: 17px;
  width: 24px;
  @media print {
    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;

export const Event = styled.div`
  display: contents;
  max-height: 55px;
  width: 58%;
  @media print {
    display: contents;
    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;

export const EventName = styled.div`
  padding-top: 20px;
  height: 10px;
  color: ${Colors.DROP_ZONE_INNER_TEXT_COLOR};
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 400;
  width: 245px;
  @media print {
    font-size: 16px;
    width: 225px;
    padding-top: 20px;
  }
`;

export const EventTime = styled.div`
  width: inherit;
  padding-top: 16px;
  padding-left: 12px;
  height: 10px;
  color: ${Colors.DROP_ZONE_INNER_TEXT_COLOR};
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 700;
  @media print {
    width: 150px;
    font-weight: 400;
    padding-top: 18px;
  }
`;

export const EventDuration = styled.span`
  color: ${Colors.DROP_ZONE_INNER_TEXT_COLOR};
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  align-content: end;
  width: 30%;
  height: 24px;
  margin-top: 18px;
  text-align: right;
  @media print {
    margin-top: 21px;
  }
`;

export const EventListContent = styled.div`
  height: 39px;
  border-radius: 4px;
  background-color: ${Colors.POPUP_LEFT_BORDER};
  margin-bottom: 16px;
  margin-left: 11px;
`;

export const EditDepartureTime = styled.div`
  height: 22px;
  width: 218px;
  left: 8px;
  top: 8px;
  border-radius: 4px;
  padding: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  color: ${Colors.EDIT_DEPARTURE_TIME_LABEL_COLOR};
  &.departure-changed {
    background: ${Colors.EDIT_DEPARTURE_TIME_BG};
    color: white;
    border-radius: 36px;
    white-space: nowrap;
  }
  &.departure-shifted {
    background: ${Colors.EDIT_DEPARTURE_TIME_BG};
    color: white;
    border-radius: 36px;
    width: 190px;
  }
  svg {
    width: 20.28px;
    height: 12.35px;
  }
`;

export const ResetDepartureTime = styled.span`
  display: inline;
  cursor: pointer;
  margin-top: 4px;
  svg {
    width: 24px;
    height: 24px;
  }
`;
export const FlightLegSwitch = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: static;
  width: 50%;
  height: 24px;
  left: 85px;
  top: 0px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  justify-content: right;
`;

export const FlightLegTitle = styled.div`
  width: 77px;
`;

export const FlightPlanLegs = styled.div`
  position: absolute;
  height: 128px;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background: ${Colors.WHITE};
  box-shadow: 0px 2px 6px 1px rgb(0 0 0 / 12%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const FlightPlanLegListItem = styled.div`
  height: 24px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  &.active {
    background: ${Colors.COLUMN_SELECTOR_BUTTON_BG};
  }
  &:hover {
    background: ${Colors.COLUMN_SELECTOR_BUTTON_BG};
    cursor: pointer;
  }
  .combined-cell {
    background: ${Colors.ACTIVE_BUTTON_COLOR};
    padding: 1px 4px;
    color: ${Colors.WHITE};
    border-radius: 4px;
    margin-left: -10px;
    text-transform: none;
  }
`;

export const TotalDuration = styled.span`
  color: ${Colors.DROP_ZONE_INNER_TEXT_COLOR};
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
  align-content: end;
  position: static;
  width: 50%;
  height: 32px;
  left: 188px;
  top: 8px;
  text-align: right;
`;

export const FlightPlanBodyDivider = styled.hr<{hideDivider}>`
  position: sticky;
  left: 58px;
  width: 320px;
  background: ${Colors.EVENTS_CARD_BORDER_COLOR};
  color: ${Colors.EVENTS_CARD_BORDER_COLOR};
  border-style: solid;
  border-width: 0.5px;
  display: ${(props) => (!props.hideDivider ? 'block' : 'none')};
  @media print {
    width: 94%;
    position: unset;
  }
`;
export const FlightPlanLegListItemChild = styled.div``;
