/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Ping Latency Card on the Flight Details Page
 */

import React from 'react';
import {ChartCard, PulseIcon} from '@viasat/insights-components';

import {IPingLatency} from '../../../store/queries/flightDetails/pingLatencyQuery';
import {formatAvgPingLatencyValue, ChartTimeSettings} from '../flightDetailsUtil';
import PingLatency from '../charts/PingLatency';
import {useIntl} from 'react-intl';
import {useStore} from '../../../store/Store';
import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';

interface PingLatencyCardProps {
  chartData: IPingLatency[];
  currentTime?: string;
  isLoading?: boolean;
  chartTimeSettings?: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
  draggableProps?: any;
}

const PingLatencyCard = ({
  chartData,
  currentTime,
  isLoading: parentIsLoading,
  chartTimeSettings,
  parentLeftOffset,
  liveMask,
  draggableProps
}: PingLatencyCardProps) => {
  const intl = useIntl();

  const getaverageLatency = () => {
    const latencies = chartData.map((i) => i.average_latency).filter((i) => i !== null);
    const latencyTotal = latencies.reduce((a, i) => a + i, 0);
    return latencies.length === 0 ? null : latencyTotal === 0 ? 0 : latencyTotal / latencies.length;
  };
  const avgPingLatency = !chartData ? 0 : getaverageLatency();
  const pingLatency = !chartData ? [] : chartData.filter((row) => row.timestamp !== null);

  // hide button click handler
  const {dispatch} = useStore();
  const onHideClick = (id) =>
    dispatch({
      type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY,
      payload: {
        hide: true,
        id
      }
    });

  return (
    <div id="PingLatencyCardEvents">
      <ChartCard
        draggableProps={draggableProps}
        onHideClick={onHideClick}
        getFullElementId={(name, type) => `flightDetails--pingLatency__${name}-${type}`}
        Icon={PulseIcon}
        title="Ping Latency"
        disableHelpIcon={false}
        helpTooltipMessage={intl.formatMessage({id: 'flight_details.ping_latency.title_tooltip'})}
        summaries={[
          {
            getFullElementId: (name, type) => `flightDetails--pingLatency__${name}-${type}`,
            key: 'events-timeline-avg-ping-latency-badge',
            name: 'AVG LATENCY',
            value: formatAvgPingLatencyValue(avgPingLatency)
          }
        ]}
        isLoading={parentIsLoading}
        isError={false}
        chartHeight={175}
      >
        <PingLatency
          currentTime={currentTime}
          isLoading={parentIsLoading}
          pingLatency={pingLatency}
          chartTimeSettings={chartTimeSettings}
          parentLeftOffset={parentLeftOffset}
          liveMask={liveMask}
        />
      </ChartCard>
    </div>
  );
};

export default PingLatencyCard;
