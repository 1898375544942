/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Card Container to handle load state and style constraint
 */

import React from 'react';
import styled from '@emotion/styled';
import {LoadingBar} from '@viasat/insights-components';


const StyledLeftContainerCard = styled.div<{width: number; gutter: number}>`
  width: ${props => Math.max(Number(props.width) - props.gutter * 2, 0)}px;
  padding: ${props => props.gutter}px ${props => props.gutter}px 0;
  overflow: hidden;
  background-color: #ffffff00;
  .MuiExpansionPanelDetails-root {
    flex-direction: column;
  }
`;

export const LeftInnerContainerCard = styled.div`
  border-radius: 4px;
  background-color: white;
  display: flex;
  width: 100%;
  border: 1px solid #dadada;
`;

const LoadingInnerContainer = styled.div<{padding: number}>`
  padding: ${props => props.padding}px;
`;
const LoadingSpacer = styled.div<{height: number}>`
  height: ${props => props.height}px;
`;

interface LeftContainerCardProps {
  id: string;
  isLoading: boolean;
  width: number;
  height: number;
  gutter: number;
  children?: JSX.Element;
}

const LeftContainerCard: React.FC<LeftContainerCardProps> = ({
  id,
  isLoading,
  width,
  gutter,
  children
}: LeftContainerCardProps) => {
  const LoadingContainer = () => (
    <LeftInnerContainerCard>
      <LoadingInnerContainer padding={16}>
        <LoadingBar id={`left-container-loading-${id}-1`} width={width - 4 * gutter} height={gutter} />
        <LoadingSpacer height={gutter} />
        <LoadingBar id={`left-container-loading-${id}-2`} width={width - 4 * gutter} height={4 * gutter} />
      </LoadingInnerContainer>
    </LeftInnerContainerCard>
  );

  return (
    <StyledLeftContainerCard width={width} gutter={gutter}>
      {isLoading ? <LoadingContainer /> : children}
    </StyledLeftContainerCard>
  );
};

export default LeftContainerCard;
