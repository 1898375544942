/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Query to return the flight plan details by flightPlanId
 */

import {DepartureTimeChange} from '../../../components/connectivityOutlook/ConnectivityOutlookUtils';
import {Query} from '../types';

export interface FlightPlanInfo {
  flightPlanIdentifier: string;
  endUser: string;
  serialNumber: string;
  tailId: string;
  departureAirport: string;
  departureTime: string;
  departureTimeLocal: string;
  destinationAirport: string;
  arrivalTime: string;
  arrivalTimeLocal: string;
  totalElapsedTime: string;
  networkType: string;
  uploadedTstamp: string;
  id: string;
  isDepartureTimeChanged?: DepartureTimeChange;
}

const flightPlansByIdQuery: Query<FlightPlanInfo[]> = {
  route: 'connectivityPlanner/flightPlansById',
  transform: null
};

export default flightPlansByIdQuery;
