/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Ku Connected Beam Overlays Query
 */
 import {Query} from '../types';

 
 const KuConnectedSatelliteBeamsQuery: Query<string[]> = {
   route: 'beamOverlay/kuConnectedBeams',
   transform: null
 };
 
 export default KuConnectedSatelliteBeamsQuery;
