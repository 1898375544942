/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Constant values
 */

import {POPUP_HOVER_COLOR} from '../components/common/theme/Colors';

// Fleet Map
export const SELECTED_AIRCRAFT_Z_INDEX = 130;
export const MAP_ELID_SECTION = 'fleetMap';
export const MAP_ELID_MAP_SUBSECTION = 'map';
export const FLEET_MAP_FLIGHT_PATH = 'flightPath';
export const MAP_LOADING_TEXT_COLOR = '#444';
export const FLIGHT_PATH_Z_INDEX_FLOOR = 104;

export const MAP_BEAMS_OUTAGE_SELECTED_Z_INDEX = 28;
export const MAP_BEAMS_OUTAGE_HOVERED_Z_INDEX = 27;
export const MAP_BEAMS_OUTAGE_Z_INDEX = 26;
export const MAP_BEAMS_IMPAIRED_SELECTED_Z_INDEX = 25;
export const MAP_BEAMS_IMPAIRED_HOVERED_Z_INDEX = 24;
export const MAP_BEAMS_IMPAIRED_Z_INDEX = 23;
export const MAP_BEAMS_INTERNAL_SELECTED_Z_INDEX = 22;
export const MAP_BEAMS_INTERNAL_HOVERED_Z_INDEX = 21;
export const MAP_BEAMS_INTERNAL_Z_INDEX = 20;

// start should be one below the selected flight's popup
export const START_Z_INDEX = SELECTED_AIRCRAFT_Z_INDEX - 1;
export const VALID_FILTER_KEYS = [
  'groupCode',
  'selectedAircraftId',
  'kaOverlayChecked',
  'kuOverlayChecked',
  'isTailTagOpen',
  'isTagSizeLarge',
  'curMapCenter',
  'curMapZoom',
  'isolateRoute'
];
export const POPUP_CONTAINER_HOVER_BORDER = `1px solid ${POPUP_HOVER_COLOR}`;
export const POPUP_CONTAINER_BORDER = `1px solid transparent`;
export const INITIAL_FADE_TIME_MS = 5000;

export const AVAILABILITY_HIGH_THRESHOLD = 75;
export const AVAILABILITY_MIDDLE_THRESHOLD = 40;
export const CHAR_SEARCH_COUNT = 2;
export const SEARCH_SLIDE_TIME = '0.05s';
export const SEARCH_OPEN_WIDTH = 244;
export const SEARCH_OPEN_RIGHT = 104;
export const SEARCH_BUTTON_WIDTH = 40;
export const SEARCH_ROW_HEIGHT = 44;
export const MAP_DEFAULT_ZOOM = 1;
export const MAP_MAX_ZOOM = 15;
export const MAP_MIN_ZOOM = 3;
export const FLIGHT_DETAILS_MAP_MIN_ZOOM = 2;
export const FLIGHT_DETAILS_MINIMAP_MIN_ZOOM = 1;
export const FOCUSED_ZOOM = 5;
export const MAP_CONTROL_WIDTH = 32;
export const MAP_POPUP_Z_INDEX = 1;

export const MAP_ELID_CONTROLS_SUBSECTION = 'controls';
export const MAP_ELID_FLIGHTPATH_SUBSECTION = 'flightPath';
export const MAP_DETAILS_MINI_ELID_SECTION = 'flightDetailsMap';

export const CACHED_SEARCH_LIMIT = 5;
export const MAP_CENTER = {
  lat: 24.71522,
  lng: -14.66828
};

// FlightList
export const EID_FLIGHTLIST = 'flightList';
export const EID_FLIGHTLIST_TAB_FLIGHTS = 'flightsTab';
export const EID_HEADER = 'header';
export const EID_LISTGRID = 'listgrid';

export const NO_DATA_INDICATOR = '--';
export const POPUP_FLIGHT_NOT_STARTED = 'Not started';
export const NO_DATA_TIMESTAMP = '__:__';

export const Z_INDEX = {HOVER: 9999, OFFLINE: 32, DARK_FLIGHT: 33, CONNECTED: 34, IMPAIRED: 35, DISCONNECTED: 36};

export const API_REREQUEST_INTERVAL_MS = 60000;
export const HOVER_DELAY_MS = 300;
export const FADEOUT_DELAY_MS = 3000;

export const SERVICE_STATUS_FILTER_CONNECTED = 'Connected';
export const SERVICE_STATUS_FILTER_DISCONNECTED = 'Disconnected';
export const SERVICE_STATUS_FILTER_IMPAIRED = 'Impaired';
export const SERVICE_STATUS_FILTER_OFFLINE = 'Offline';
export const NETWORK_CAPABLE_FILTER_KA = 'Ka';
export const NETWORK_CAPABLE_FILTER_KU = 'Ku';
export const NETWORK_CAPABLE_FILTER_DUAL = 'Dual Band';

export const SELECTED_POPUP_WIDTH = 285;
export const SELECTED_POPUP_TRANSITION_IN_DURATION = 400;
export const SELECTED_POPUP_TRANSITION_OUT_DURATION = 200;
export const SNACKBAR_MIN_WIDTH = 170;
export const LIVE_FLIGHT_MAP_HEIGHT = '370px';
export const LIVE_FLIGHT_MINI_MAP_HEIGHT = '232px';

// Status Constants
export enum ConnectionStatus {
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
  IMPAIRED = 'Impaired',
  OFFLINE = 'Offline',
  DARK_FLIGHT = 'DarkFlight'
}

export enum SwVersionStatus {
  PRODUCTION = 'Production',
  PILOT = 'Pilot',
  NEEDS_UPGRADE = 'Needs Upgrade'
}

export enum FlightPhase {
  ASCENDING = 'Ascending',
  CRUISING = 'Cruising',
  DESCENDING = 'Descending',
  ON_GROUND = 'On-ground'
}

export enum NetworkCapability {
  KA = 'Ka',
  KU = 'Ku',
  DUAL_BAND = 'Dual Band (Ka & Ku)'
}

export interface sortOrderValueMap {
  [key: string]: number | undefined;
}

export const AIRCRAFT_SORT_ORDER: sortOrderValueMap = {
  Disconnected: 10,
  Impaired: 7,
  Connected: 5,
  Offline: 1
};

// Charts constants
export const TOOLTIP_RIGHT_ALIGN_FUDGE_FACTOR = 135;

// Chart hatching constants
export const HATCH_OVERLAY_GROUP_NAME = 'HatchOverlay';
export const HATCH_OVERLAY_VERTICAL_PAD = 1;
export const HATCH_OVERLAY_HORIZONTAL_PAD = 35;
export const HATCH_OVERLAY_WIDTH = 32;

// No Data to Display Constants
export const NO_DATA_GROUP_NAME = 'NoDataGroup';
export const NO_DATA_TITLE_TEXT = 'No Data to Display';
export const NO_DATA_SUBTITLE_TEXT_NONE = 'We have not collected any data for this flight';
export const NO_DATA_SUBTITLE_TEXT_HIDDEN = 'Select items from legend';
export const NO_DATA_DEFAULT_OFFSETY = 40;
export const NO_DATA_IMAGE_WIDTH = 40;
export const NO_DATA_IMAGE_HEIGHT = 35;
export const NO_DATA_TITLE_LINE_HEIGHT = 20;
export const NO_DATA_TITLE_WIDTH = 127;
export const NO_DATA_SUBTITLE_WIDTH_NONE = 260;
export const NO_DATA_SUBTITLE_WIDTH_HIDDEN = 145;

// Exported No Data to Display Constants
export const CHART_NO_DATA_X_AXIS = 'x-axis';
export const CHART_NO_DATA_Y_AXIS = 'y-axis';

//Tooltip Constants
export const TOOLTIP_SERIES_PREFIX_FONT_SIZE = '33px';
export const TOOLTIP_SERIES_PREFIX_LINE_HEIGHT = '16px';
export const TOOLTIP_SERIES_PREFIX_LINE_HEIGHT_SAFARI = '18px';
export const TOOLTIP_SERIES_PREFIX_VERTICAL_ALIGN = '10%';
export const TOOLTIP_SERIES_PREFIX_WIDTH = '10px';
export const TOOLTIP_SERIES_SEPARATOR_WIDTH = '8px';

export const FONT_WEIGHT_NORMAL = 400;
export const FONT_WEIGHT_SEMIBOLD = 600;
export const FONT_WEIGHT_BOLD = 700;

export const TOOLTIP_LABEL_FONT_SIZE = '14px';
export const TOOLTIP_LABEL_LINE_HEIGHT = '18px';

export const TOOLTIP_SERIES_TEXT_FONT_SIZE = '12px';
export const TOOLTIP_SERIES_LINE_HEIGHT = '14px';

//Flight Details
export const FLIGHT_DETAILS_ID_BASE = 'flightDetails';

export const MAP_CARD_HEIGHT = 350;

export const DATA_USAGE_UNITS_MB = 'MB';

export const CHART_CARD_PADDING = 48;

export const BAR_WIDTH = 23;

// Use `--` to represent NoData (null, isNaN, !isFinite)
export const FORMAT_VALUE_NULL_REPRESENTATION = '--';
// Default Precision is 0
export const FORMAT_VALUE_DEFAULT_PRECISION = 0;
// Default Units is null, represents no units or empty string
export const FORMAT_VALUE_DEFAULT_UNITS = null;

// Default Separator is false, which does not display thousands separator
export const FORMAT_VALUE_DEFAULT_NO_SEPARATOR = false;
// Use Separator, which will display thousands separator
export const FORMAT_VALUE_USE_SEPARATOR = !FORMAT_VALUE_DEFAULT_NO_SEPARATOR;

// Format Value Option: Precision with and no (used by axes labels)
export const FORMAT_VALUE_WITH_PRECISION = true;
export const FORMAT_VALUE_NO_PRECISION = !FORMAT_VALUE_WITH_PRECISION;
// Format Value Option: Units with and no (used by axes labels)
export const FORMAT_VALUE_WITH_UNITS = true;
export const FORMAT_VALUE_NO_UNITS = !FORMAT_VALUE_WITH_UNITS;

// Default PadStart is false, which does not display leading zeroes
export const FORMAT_VALUE_DEFAULT_NO_PAD_START_ZEROES = 0;
export const FORMAT_VALUE_USE_PAD_START_ZEROES_2 = 2;

export const SECONDS_PER_MINUTE = 60;

export const DATA_PACKAGE_PROGRESS_BAR_NAME = 'Progress bar of Data Package';
export const HOURLY_PLAN_PROGRESS_BAR_NAME = 'Progress bar of Hourly Plan';

// Live Flight View feature list
export const LIVE_FLIGHT_VIEW_NAME = 'liveFlightView';

// Events Timeline
export const MISSING_DATA_POINT_COUNT = 5;

// Network Bands
export const BAND_KA = 'Ka';
export const BAND_KU = 'Ku';

// Ping Latency constants used for customization in Line Chart
export const PING_LATENCY_SERIES_NAME = 'Ping Latency';
export const MAX_NUMBER_OF_GRID_LINES = 8;

// String length threshold
export const DEMO_OBFUSCATED_STR_LENGTH = 20;

// Data Usage
export const BYTES_PER_MEGABYTE = 1e6;

// Connectivity Outlook Constants
export const UPLOAD_MODAL_TITLE = 'Upload flight plans';
export const UPLOAD_MODAL_SUB_TITLE = 'You may upload up to 6 flight plans to use in generating connectivity outlooks.';
export const REQUEST_REJECTED_ERROR_CODE = 403;
export const INTERNAL_SERVER_ERROR_CODE = 500;
export const REQUEST_FLIGHT_PLAN_EXIST_CODE = 409;
export const AIRCRAFT_LIST_TITLE = 'Find the Tail ID';
export const AIRCRAFT_LIST_SUBTITILE = 'Select the correct Tail ID for the flight plan in question';
export const TWENTY_FOUR_HOURS = 24;
export const FLIGHT_PLAN_LIST_CONTEXT = 'flight-plan-list';
export const LIST_GRID_LINK_CONTEXT = 'list-grid-link';
export const FLIGHT_PLAN_LIST_BASE_ID = 'ConnectivityOutlookFlightPlanList';

//ASLV
export const SOFTWARE_REVISIONS_LABEL_WIDTH = 60;
export const SOFTWARE_REVISIONS_VALUE_WIDTH = 40;

// This value is arbitrary, to closely match width: 88% & text-overflow: ellipsis to show ToolTips
// does not account for variable width chars
export const LONG_TEXT_TRUNCATE_LENGTH = 34;
export const EMPTY_PLACEHOLDER_STATEMENT = 'No results found';
export const EMPTY_PLACEHOLDER_DESCRIPTION = 'Try adjusting your filter to find what you are looking for.';

export const ORIGIN_DESTINATION_LABELS = ['Origin', 'Destination'];

export const DATA_USAGE_AVAILABLE_TOOLTIP = `Normal data usage in the last 15m`;
export const NO_DATA_USAGE_TOOLTIP = (duration: string): string => `Low/No data usage in the last ${duration}`;

export const KA_REGIONAL_PLAN_SERVICE_COVERAGE = [{region: 'NAMER', name: 'North America'}, {region: 'EURMES', name: 'Europe / Middle East'}, {region:'BRAZIL', name: 'Brazil'}];
export const KU_REGIONAL_PLAN_SERVICE_COVERAGE = [{region:'CONUSCanada', name:'CONUS / Canada'}];

export const AIRCRAFT_STATUS_DECOMMISSIONED = 'Decommissioned';
