/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Page container component
 *
 */

import React from 'react';
import {MenuBar, MenuBarProps} from '@viasat/insights-components';
import styled from '@emotion/styled';

export const PageContent = styled.div`
  grid-area: flight-subroute;
  height: 100%;
  width: 100%;
  padding-top: 0px;
  overflow: hidden;
`;

interface PageContainerTemplateProps extends MenuBarProps {
  children: JSX.Element | JSX.Element[];
}

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media print {
    header {
      position: fixed;
      top: 0;
    }
    footer {
      position: fixed;
      bottom: 0;
    }
  }
`;

const PageContainerTemplate: React.FC<PageContainerTemplateProps> = ({
  children,
  getFullElementId,
  leftStack,
  rightStack,
  title,
  color,
  isSubtitleLoading,
  subtitle,
  tabs
}) => {
  return (
    <ContentContainer>
      <MenuBar
        getFullElementId={getFullElementId}
        title={title}
        subtitle={subtitle}
        leftStack={leftStack}
        rightStack={rightStack}
        color={color}
        isSubtitleLoading={isSubtitleLoading}
        tabs={tabs}
      />
      <PageContent id={getFullElementId('pageContent', 'container')}>{children}</PageContent>
    </ContentContainer>
  );
};

export default PageContainerTemplate;
