/*
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Styles, Interfaces & constants related to Map
 */

import styled from '@emotion/styled';
import {css, keyframes} from '@emotion/react';
import {createUseStyles} from 'react-jss';
import {makeStyles} from 'tss-react/mui';
import {LoadingContainerCaptionProps} from '@viasat/insights-components';

import {
  BLACK,
  BLACK_RGB,
  FIVE_PCT_TRANSPARENT_BLACK,
  GREY,
  MAP_CONTROL_ICON_HOVER_COLOR,
  MAP_POPUP_ACCENT_COLOR,
  MAP_POPUP_DEFAULT_BORDER,
  MAP_SEARCH_RESULTS_TEXT_COLOR,
  THIRTY_PCT_TRANSPARENT_BLACK,
  SELECTED_AIRCRAFT_BG_IN_SEARCH_RESULT,
  VIEW_LEGEND_BG,
  VIEW_LEGEND_TEXT,
  WHITE,
  MAP_POPUP_CLOSE_HOVER_COLOR,
  ABOVE_TARGET_RGB_COLOR,
  BELOW_LOWER_THRESHOLD_RGB_COLOR,
  IMPAIRED_RGB,
  OFFLINE_RGB,
  ORANGE
} from '../common/theme/Colors';
import BoldSpan from '../common/BoldSpan';
import {
  MAP_LOADING_TEXT_COLOR,
  SEARCH_BUTTON_WIDTH,
  SEARCH_OPEN_RIGHT,
  SEARCH_OPEN_WIDTH,
  SEARCH_SLIDE_TIME
} from '../../utils/constants';

/*****************MAP CONTROLS*****************/

export const SEARCH_ANIMATION_STATE = {
  NONE: 'none',
  OPENING: 'opening',
  CLOSING: 'closing'
};

export const useStyles = makeStyles()(() => {
  return {
    buttonIcon: {
      width: '20px!important',
      height: '20px!important'
    },
    searchButtonIcon: {
      width: '25px!important',
      height: '25px!important',
      '&:hover': {
        color: MAP_CONTROL_ICON_HOVER_COLOR
      }
    }
  };
});

export const SearchBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
  border-bottom: 1px solid ${MAP_POPUP_DEFAULT_BORDER};
  .header {
    font-size: 10px;
    font-weight: 600;
    color: ${MAP_POPUP_ACCENT_COLOR};
  }
  .clickableHeader {
    font-size: 10px;
    color: ${MAP_POPUP_ACCENT_COLOR};
    cursor: pointer;
  }
`;

export const SearchBoxBody = styled.div`
  border-radius: 6px;
  overflow-x: hidden;
  width: ${SEARCH_OPEN_WIDTH}px;
  max-height: calc(100vh - 275px);
  min-height: 280px;
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
  }
`;

export const SearchBoxCardLabel = styled.span`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const SearchBoxCardSubtext = styled.span`
  font-size: 12px;
  color: ${MAP_POPUP_ACCENT_COLOR};
`;

export const SearchResult = styled.div`
  display: flex;
  padding: 11px 16px 11px;
  border-bottom: 1px solid ${MAP_POPUP_DEFAULT_BORDER};
  .return-icon-container {
    display: none;
    svg {
      height: 12px;
      width: 12px;
      path {
        fill: ${THIRTY_PCT_TRANSPARENT_BLACK};
      }
    }
  }

  &.selected,
  &.hovered {
    cursor: pointer;
    background: ${FIVE_PCT_TRANSPARENT_BLACK};
  }
  &.selected {
    background: ${SELECTED_AIRCRAFT_BG_IN_SEARCH_RESULT};
  }
  &.hovered {
    .return-icon-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
    }
  }
  .detail,
  .org-dest {
    display: inline-block;
  }
  .detail {
    font-size: 14px;
    color: ${MAP_SEARCH_RESULTS_TEXT_COLOR};
  }
  .org-dest {
    color: ${MAP_POPUP_ACCENT_COLOR};
    font-size: 12px;
  }
`;

export const AvailabilityDotContainer = styled.div<{animation: string}>`
  width: 10px;
  height: 10px;
  border-radius: 12px;
  margin-right: 6px;
  margin-top: 2px;
  animation: ${(props) => props.animation};
  position: relative;
  top: 5px;
  svg {
    position: relative;
    bottom: 5px;
    right: 1px;
  }
`;

export const BlackBoldSpan = styled(BoldSpan)`
  color: ${BLACK};
`;

export const MapControlsButtonContainer = styled.div`
  > * {
    margin-bottom: 8px;
  }
`;

// I made the border-radius pretty high so it hides on the top and bottom
export const BorderIconContainerWrapper = styled.div<{marginTop?: number}>`
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12),
    0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: ${VIEW_LEGEND_BG};
  border-radius: 6px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
`;

export const hideoutKeyframes = keyframes`
  from {
    width: ${SEARCH_BUTTON_WIDTH}px;
    right: 0px;
  }
  to {
    width: ${SEARCH_OPEN_WIDTH}px;
    right: ${SEARCH_OPEN_RIGHT}px;
  }
`;

export const slideinKeyframes = keyframes`
  from {
    width: ${SEARCH_OPEN_WIDTH}px;
    right: ${SEARCH_OPEN_RIGHT}px;
  }
  to {
    width: ${SEARCH_BUTTON_WIDTH}px;
    right: 0px;
  }
`;
const slideoutKeyframes = keyframes`
  from {
    width: ${SEARCH_BUTTON_WIDTH}px;
    right: 0px;
  }
  to {
    width: ${SEARCH_OPEN_WIDTH}px;
    right: ${SEARCH_OPEN_RIGHT}px;
  }
`;

export const slideoutAnimation = css`
  animation: ${slideoutKeyframes} ${SEARCH_SLIDE_TIME} linear;
`;
export const slideinAnimation = css`
  animation: ${slideinKeyframes} ${SEARCH_SLIDE_TIME} linear;
`;

export const getSearchAnimationState = (searchOpen: boolean, prevSearchOpen: boolean): string => {
  if (searchOpen && !prevSearchOpen) {
    return SEARCH_ANIMATION_STATE.OPENING;
  } else if (!searchOpen && prevSearchOpen) {
    return SEARCH_ANIMATION_STATE.CLOSING;
  }
  return SEARCH_ANIMATION_STATE.NONE;
};

const SLIDE_ANIMATION_STATE_MAPPING = {
  [SEARCH_ANIMATION_STATE.NONE]: '',
  [SEARCH_ANIMATION_STATE.OPENING]: slideoutAnimation,
  [SEARCH_ANIMATION_STATE.CLOSING]: slideinAnimation
};

export const BorderSearchContainerWrapper = styled(({searchOpen, prevSearchOpen, ...props}) => (
  <BorderIconContainerWrapper {...props} />
))`
  border-radius: 20px;
  margin-bottom: ${(props) => (props.searchOpen ? '22px' : '24px')};
  position: relative;
  background-color: ${WHITE};

  ${(props) => SLIDE_ANIMATION_STATE_MAPPING[getSearchAnimationState(props.searchOpen, props.prevSearchOpen)]}

  width: ${(props) => (props.searchOpen ? `${SEARCH_OPEN_WIDTH}px` : `${SEARCH_BUTTON_WIDTH}px`)};
  right: ${(props) => (props.searchOpen ? `${SEARCH_OPEN_RIGHT}px` : '0px')};
  ${(props) => (props.searchOpen ? 'border: 1px solid rgb(55, 123, 255);' : '')}
`;

const slideoutButtonKeyframes = keyframes`
  from {
    width: ${SEARCH_BUTTON_WIDTH}px;
  }
  to {
    width: ${SEARCH_OPEN_WIDTH}px;
  }
`;

const slideinButtonKeyframes = keyframes`
  from {
    width: ${SEARCH_OPEN_WIDTH}px;
  }
  to {
    width: ${SEARCH_BUTTON_WIDTH}px;
  }
`;

const slideoutButtonAnimation = css`
  animation: ${slideoutButtonKeyframes} ${SEARCH_SLIDE_TIME} linear;
`;
const slideinButtonAnimation = css`
  animation: ${slideinButtonKeyframes} ${SEARCH_SLIDE_TIME} linear;
`;

const SLIDE_BUTTON_ANIMATION_STATE_MAPPING = {
  [SEARCH_ANIMATION_STATE.NONE]: '',
  [SEARCH_ANIMATION_STATE.OPENING]: slideoutButtonAnimation,
  [SEARCH_ANIMATION_STATE.CLOSING]: slideinButtonAnimation
};

const slideoutSearchKeyframes = keyframes`
  from {
    max-height: 0px;
    min-height: 0px;
  }
  to {
    max-height: calc(100vh - 243px);
    min-height: 280px;
  }
`;

const slideinSearchKeyframes = keyframes`
  from {
    max-height: calc(100vh - 243px);
    min-height: 280px;
  }
  to {
    max-height: 0px;
    min-height: 0px;
  }
`;

const slideoutSearchAnimation = css`
  animation: ${slideoutSearchKeyframes} ${SEARCH_SLIDE_TIME} linear;
`;

const slideinSearchAnimation = css`
  animation: ${slideinSearchKeyframes} ${SEARCH_SLIDE_TIME} linear;
`;

const SLIDE_SEARCH_ANIMATION_STATE_MAPPING = {
  [SEARCH_ANIMATION_STATE.NONE]: '',
  [SEARCH_ANIMATION_STATE.OPENING]: slideoutSearchAnimation,
  [SEARCH_ANIMATION_STATE.CLOSING]: slideinSearchAnimation
};

export const PopupCloseButton = styled.div<{searchOpen: Boolean; searchCriteria: string}>`
  height: 18px;
  border-radius: 8px;
  background-color: ${WHITE};
  position: relative;
  opacity: ${(props) => (props.searchOpen && Boolean(props.searchCriteria) ? '1' : '0')};
  width: ${(props) => (props.searchOpen && Boolean(props.searchCriteria) ? '18px' : '0px')};
  bottom: 28px;
  left: 216px;
  cursor: ${(props) => (props.searchOpen && Boolean(props.searchCriteria) ? 'pointer' : 'default')};
  &:hover {
    background-color: ${MAP_POPUP_CLOSE_HOVER_COLOR};
    color: ${BLACK};
  }
  svg {
    width: 16px;
    height: 16px;
    position: relative;
    left: 1px;
    top: 1px;
  }
`;

export const SearchBoxContainerWrapper = styled(({searchOpen, prevSearchOpen, ...props}) => (
  <BorderIconContainerWrapper {...props} />
))`
  position: relative;
  background-color: ${WHITE};
  width: ${SEARCH_OPEN_WIDTH}px;
  right: 228px;
  bottom: 14px;
  .box-container-wrapper {
    .box-container {
      ${(props) =>
        SLIDE_SEARCH_ANIMATION_STATE_MAPPING[getSearchAnimationState(props.searchOpen, props.prevSearchOpen)]}
      min-height: ${(props) => (props.searchOpen ? '72px' : '0px')};
      max-height: ${(props) => (props.searchOpen ? 'calc(100vh - 280px)' : '0px')};
      overflow: hidden;
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const SearchControlIconButton = styled(({searchOpen, prevSearchOpen, ...props}) => <button {...props} />)`
  border-radius: 20px;
  position: relative;

  width: ${SEARCH_BUTTON_WIDTH}px;
  height: ${SEARCH_BUTTON_WIDTH}px;

  background-color: ${VIEW_LEGEND_BG};
  border: none;
  cursor: ${(props) => (props.searchOpen ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  ${(props) => SLIDE_BUTTON_ANIMATION_STATE_MAPPING[getSearchAnimationState(props.searchOpen, props.prevSearchOpen)]}
  width: ${(props) => (props.searchOpen ? `${SEARCH_OPEN_WIDTH}px` : `${SEARCH_BUTTON_WIDTH}px`)};
  ${(props) => (props.searchOpen ? 'justify-content: start;' : '')}
  &>span {
    ${(props) =>
      props.searchOpen
        ? `width: ${SEARCH_BUTTON_WIDTH}px;
           height: ${SEARCH_BUTTON_WIDTH}px;`
        : ''}
  }
`;

const slideoutInputKeyframes = keyframes`
  from {
    width: 0px;
    margin: 0px;
    opacity: 0;
  }
  to {
    width: 145px;
    margin: 0 38px 0 16px;
    opacity: 1;
  }
`;

const slideinInputKeyframes = keyframes`
  from {
    width: 145px;
    margin: 0 42px 0 12px;
    opacity: 1;
    disply: block;
  }
  to {
    width: 0px;
    margin: 0px;
    opacity: 0;
    disply: none;
  }
`;

const slideoutInputAnimation = css`
  animation: ${slideoutInputKeyframes} ${SEARCH_SLIDE_TIME} cubic-bezier(1, 0, 1, 0);
`;
const slideinInputAnimation = css`
  animation: ${slideinInputKeyframes} ${SEARCH_SLIDE_TIME} linear;
`;

const SLIDE_INPUT_ANIMATION_STATE_MAPPING = {
  [SEARCH_ANIMATION_STATE.NONE]: '',
  [SEARCH_ANIMATION_STATE.OPENING]: slideoutInputAnimation,
  [SEARCH_ANIMATION_STATE.CLOSING]: slideinInputAnimation
};

export const SearchInput = styled.input<{searchOpen: boolean; prevSearchOpen: boolean}>`
  height: 32px;
  padding: ${(props) => (props.searchOpen ? '0 0 0 4px' : '0px')};
  border: 0;
  opacity: 0;
  ${(props) => SLIDE_INPUT_ANIMATION_STATE_MAPPING[getSearchAnimationState(props.searchOpen, props.prevSearchOpen)]}
  display: ${(props) => (props.searchOpen ? 'block' : 'none')};
  opacity: ${(props) => (props.searchOpen ? '1' : '0')};
  width: ${(props) => (props.searchOpen ? '145px' : '0px')};
  margin: ${(props) => (props.searchOpen ? '0 42px 0 12px;' : '0px')};
  background-color: ${WHITE};
  font-size: 14px;
  outline: none;
  position: relative;
`;

/*****************MAP AIRCRAFT*****************/

export const AirplaneContainer = styled.div<{zIndex: number; animation: string; display: string}>`
  background-color: ${WHITE};
  box-sizing: border-box;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid ${WHITE};
  box-shadow: 0px 0px 2px 0px rgba(${BLACK_RGB}, 0.14), 0px 0px 1px -2px rgba(${BLACK_RGB}, 0.12),
    0px 0px 5px 0px rgba(${BLACK_RGB}, 0.2);
  cursor: pointer;
  position: relative;
  z-index: ${(props) => props.zIndex};
  animation: ${(props) => props.animation};
  display: ${(props) => props.display};
`;

export const AirplaneHoverContainer = styled.div<{zIndex: number}>`
  box-sizing: border-box;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  opacity: 1;
  z-index: ${(props) => props.zIndex};
`;

export const AirplaneInnerContainer = styled.span`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  .darkFlightIcon {
    color: ${ORANGE};
    width: 16px;
    height: 16px;
    position: absolute;
    left: 15px;
    top: -7px;
    stroke: white;
  }
`;

export const HOVER_AIRCRAFT_Z_INDEX = 9999;
export const LOADING_FLIGHT_PATH_Z_INDEX = HOVER_AIRCRAFT_Z_INDEX + 2;
export const FOURTEEN_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.14)';
export const TWELVE_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.12)';
export const TWENTY_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.2)';

export const LoadingFlightPathContainer = styled.div<{left: string; bottom: string; width: string; padding: string}>`
  position: absolute;
  cursor: pointer;
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  width: ${(props) => props.width};
  height: 20px;
  padding: ${(props) => props.padding};
  border-radius: 20px;
  z-index: ${LOADING_FLIGHT_PATH_Z_INDEX};
  background-color: ${WHITE};
  box-shadow: 0px 3px 4px 0px ${FOURTEEN_PCT_TRANSPARENT_BLACK}, 0px 3px 3px -2px ${TWELVE_PCT_TRANSPARENT_BLACK},
    0px 1px 8px 0px ${TWENTY_PCT_TRANSPARENT_BLACK};
`;

export const LoadingFlightPathCaptionProps: LoadingContainerCaptionProps = {
  fontSize: '12px',
  fontWeight: 600,
  margin: '0px 0px 0px 5px',
  color: MAP_LOADING_TEXT_COLOR
};

export const selectedAircraftAnimation = createUseStyles({
  '@global': {
    '@keyframes aircraftConnectedPulse': {
      '0%': {
        boxShadow: `0 0 0 0 rgb(${ABOVE_TARGET_RGB_COLOR}, 0.7)`
      },
      '70%': {
        boxShadow: `0 0 0 16px rgb(${ABOVE_TARGET_RGB_COLOR}, 0)`
      },
      '100%': {
        boxShadow: `0 0 0 0 rgb(${ABOVE_TARGET_RGB_COLOR}, 0)`
      }
    },
    '@keyframes aircraftImpairedPulse': {
      '0%': {
        boxShadow: `0 0 0 0 rgb(${IMPAIRED_RGB}, 0.7)`
      },
      '70%': {
        boxShadow: `0 0 0 16px rgb(${IMPAIRED_RGB}, 0)`
      },
      '100%': {
        boxShadow: `0 0 0 0 rgb(${IMPAIRED_RGB}, 0)`
      }
    },
    '@keyframes aircraftDisconnectedPulse': {
      '0%': {
        boxShadow: `0 0 0 0 rgb(${BELOW_LOWER_THRESHOLD_RGB_COLOR}, 0.7)`
      },
      '70%': {
        boxShadow: `0 0 0 16px rgb(${BELOW_LOWER_THRESHOLD_RGB_COLOR}, 0)`
      },
      '100%': {
        boxShadow: `0 0 0 0 rgb(${BELOW_LOWER_THRESHOLD_RGB_COLOR}, 0)`
      }
    },
    '@keyframes aircraftOfflinePulse': {
      '0%': {
        boxShadow: `0 0 0 0 rgb(${OFFLINE_RGB}, 0.7)`
      },
      '70%': {
        boxShadow: `0 0 0 8px rgb(${OFFLINE_RGB}, 0)`
      },
      '100%': {
        boxShadow: `0 0 0 0 rgb(${OFFLINE_RGB}, 0)`
      }
    },
    '@keyframes aircraftConnectedPulseSmall': {
      '0%': {
        boxShadow: `0 0 0 0 rgb(${ABOVE_TARGET_RGB_COLOR}, 0.7)`
      },
      '70%': {
        boxShadow: `0 0 0 8px rgb(${ABOVE_TARGET_RGB_COLOR}, 0)`
      },
      '100%': {
        boxShadow: `0 0 0 0 rgb(${ABOVE_TARGET_RGB_COLOR}, 0)`
      }
    },
    '@keyframes aircraftImpairedPulseSmall': {
      '0%': {
        boxShadow: `0 0 0 0 rgb(${IMPAIRED_RGB}, 0.7)`
      },
      '70%': {
        boxShadow: `0 0 0 8px rgb(${IMPAIRED_RGB}, 0)`
      },
      '100%': {
        boxShadow: `0 0 0 0 rgb(${IMPAIRED_RGB}, 0)`
      }
    },
    '@keyframes aircraftDisconnectedPulseSmall': {
      '0%': {
        boxShadow: `0 0 0 0 rgb(${BELOW_LOWER_THRESHOLD_RGB_COLOR}, 0.7)`
      },
      '70%': {
        boxShadow: `0 0 0 8px rgb(${BELOW_LOWER_THRESHOLD_RGB_COLOR}, 0)`
      },
      '100%': {
        boxShadow: `0 0 0 0 rgb(${BELOW_LOWER_THRESHOLD_RGB_COLOR}, 0)`
      }
    },
    '@keyframes aircraftOfflinePulseSmall': {
      '0%': {
        boxShadow: `0 0 0 0 rgb(${OFFLINE_RGB}, 0.7)`
      },
      '70%': {
        boxShadow: `0 0 0 8px rgb(${OFFLINE_RGB}, 0)`
      },
      '100%': {
        boxShadow: `0 0 0 0 rgb(${OFFLINE_RGB}, 0)`
      }
    }
  }
});

/*****************MAP LEDGEND*****************/

export const ItemText = styled.div`
  color: ${GREY};
  display: inline-block;
  white-space: nowrap;
`;

export const CountWrapper = styled.div`
  display: inline-block;
  margin-right: 4px;
`;

export const TextWrapper = styled.div`
  height: 14px;
  margin-right: 16px;
  white-space: nowrap;
`;

export const StatusColor = styled.div<{backgroundColor: any}>`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background: ${(props) => props.backgroundColor};
`;

export const CountTextBottom = styled.div<{backgroundColor: any}>`
  display: block;
  height: 4px;
  border-radius: 1px;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
`;

export const CountText = styled.div`
  display: block;
  white-space: nowrap;
  color: ${BLACK};
  text-align: right;
  padding-left: 4px;
  padding-right: 4px;
`;

/*****************MAP LEGEND WRAPPER*****************/

export const LegendWrapper = styled.div<{height?: string}>`
  bottom: 34px;
  right: 16px;
  position: absolute;
  border-radius: 4px;
  z-index: 2;
  font-weight: 600;
  font-size: 12px;
  color: ${VIEW_LEGEND_TEXT};
  line-height: 14px;
  letter-spacing: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  height: ${(props) => props.height};
`;

/*****************FLIGHT PATH ******************/
export const PinIconContainer = styled.div<{zIndex: number}>`
  z-index: ${(props) => props.zIndex};
  cursor: default;
  position: relative;
`;

const boxShadow = `box-shadow: 0px 3px 4px 0px ${FOURTEEN_PCT_TRANSPARENT_BLACK}, 0px 3px 3px -2px ${TWELVE_PCT_TRANSPARENT_BLACK},
  0px 1px 8px 0px ${TWENTY_PCT_TRANSPARENT_BLACK};`;

export const PathPopup = styled.div`
  font-family: 'Source Sans Pro';
  position: absolute;
  bottom: 10px;
  left: -55px;
  background-color: ${WHITE};
  z-index: 10000;
  border-radius: 4px;
  ${boxShadow}

  .time-row {
    white-space: nowrap;
    display: flex;
    padding: 8px 0px 6px;
    margin: 0px 12px;
    border-bottom: 1px solid ${MAP_POPUP_DEFAULT_BORDER};

    align-items: center;
    div {
      font-size: 12px;
      font-weight: 600;
      height: 14px;
    }
    .duration {
      white-space: nowrap;
      font-weight: 600;
      margin-left: 3px;
      font-size: 10px;
      color: ${MAP_POPUP_ACCENT_COLOR};
      top: 1px;
      position: relative;
    }
  }
  .availability-row {
    white-space: nowrap;
    display: flex;
    padding: 4px 12px 10px 12px;
    font-size: 12px;
    font-weight: 600;
    svg {
      bottom: -1px;
      width: 11px;
      height: 11px;
      margin-right: 3px;
    }
  }
  .incidents-row {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    padding: 0px 12px 10px 12px;
    font-size: 12px;
    svg {
      bottom: -6px;
      width: 5px;
      height: 5px;
      margin-right: 4px;
      margin-left: 4px;
    }
    .incident-start-time {
      margin-left: 16px;
    }
  }
  .events-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 4px 0px 6px 22px;
    ul {
      list-style-type: none;
    }
    li {
      height: 14px;
      padding: 0 0 4px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div {
        white-space: nowrap;
        padding-right: 14px;
        display: inline-block;
        font-size: 12px;
      }
      .event-name {
        span {
          position: relative;
          right: 8px;
        }
      }
      .event-time {
        font-weight: 600;
      }
    }
  }
`;

export const HoverMarker = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;
