/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description:
 * Customized Column Range Chart
 */

import Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import {isEqual} from 'lodash';
import {FormattedMessage} from 'react-intl';
import {isNil} from 'lodash';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {DataToolTip} from '@viasat/insights-components';

import {ChartType, IColumnRangeChart, ILegendTooltip} from './types';
import ToolTip from './ToolTip/ToolTip';
import {formatToolTipText} from '../../../../utils/TooltipUtil';
import BoldSpan from '../../BoldSpan';
import {getHighchartsId, setLegendItemsVisibility} from './chartUtils';
import {checkSeriesHasData, checkShowEmptyAxis, renderCheckNoDataToDisplay, renderHatchOverlay} from './RenderOverlay';

import {CHART_TOOLTIP_BG, CHART_TOOLTIP_TEXT, LEGEND_TEXT, AXIS_TEXT, CHART_TOOLTIP_BORDER} from '../../theme/Colors';
import {
  CHART_NO_DATA_X_AXIS,
  CHART_NO_DATA_Y_AXIS,
  TOOLTIP_RIGHT_ALIGN_FUDGE_FACTOR
} from '../../../../utils/constants';
import {ColumnRangeStyledContainer} from './ChartStyles';

more(Highcharts);

class ColumnRangeChart extends React.Component<IColumnRangeChart, any> {
  ref = null;
  config = {};

  constructor(props) {
    super(props);
    this.state = {
      tooltipTitle: '',
      tooltipOpen: false,
      eventX: 0
    };
  }

  componentDidMount() {
    const {id, config} = this.generateChart(this.props);
    this.ref = Highcharts.chart(id, config);
    this.config = config.series;
  }

  render() {
    const {id} = this.props;
    const {config} = this.generateChart(this.props);
    if (!isEqual(this.config, config.series) && this.ref) {
      this.config = config.series;
      Highcharts.chart(id, config).update(config);
    }

    const validTooltip: boolean = !isNil(this.state.tooltipTitle) && this.state.tooltipTitle.length > 0;
    const currentTooltip: ILegendTooltip | undefined = validTooltip
      ? this.props.legendTooltips.find((tooltip) => tooltip.title === this.state.tooltipTitle)
      : undefined;
    const centerAlignArrow = currentTooltip ? this.state.eventX + 125 < window.innerWidth : true;
    const tooltipX = currentTooltip ? currentTooltip.x : 0;
    const tooltipY = currentTooltip ? currentTooltip.y : 0;
    const tooltipArrowX = currentTooltip ? currentTooltip.arrowX : 0;
    return (
      <DataToolTip
        arrowPadding={
          centerAlignArrow ? '0px' : `${TOOLTIP_RIGHT_ALIGN_FUDGE_FACTOR - (window.innerWidth - this.state.eventX)}px`
        }
        arrowXOffset={tooltipArrowX}
        id={`chart-tooltip`}
        placement="top"
        arrow={validTooltip}
        title={
          validTooltip ? (
            <div style={{lineHeight: '18px', margin: '11px 13px', maxWidth: '250px'}}>
              <BoldSpan>
                <FormattedMessage id={this.state.tooltipTitle}>{formatToolTipText}</FormattedMessage>
              </BoldSpan>
            </div>
          ) : (
            <></>
          )
        }
        open={this.state.tooltipOpen}
        popperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [tooltipX, tooltipY]
              }
            }
          ]
        }}
      >
        <ColumnRangeStyledContainer className="chart-container" data-test="column-range-chart" id={id} />
      </DataToolTip>
    );
  }

  private generateChart = (props: IColumnRangeChart) => {
    const {
      id,
      chartType,
      categories,
      series,
      customXAxis,
      xAxisLabelXOffset,
      xAxisFormatter,
      xAxisTickInterval,
      xAxisMinorTickInterval,
      xAxisLabelPadding,
      yAxisMin,
      yAxisMax,
      tooltip,
      htmlTooltip,
      tooltipDisabled,
      yAxisLabel,
      xAxisLabel,
      highchartsIdBase,
      legendTooltips,
      noDataOptions,
      hideAllLegendItemsOnLoad,
      legendItemsToHideOnLoad,
      positioner,
      liveMask,
      barWidth,
      totalDuration,
      setLiveFlightOffset,
      chartHeight,
      marginLeft,
      marginRight,
      spacingLeft,
      spacingRight,
      yAxisOffset,
      marginBottom,
      customYAxis,
      customLegendClick,
      spacingTop,
      patternDefinition
    } = props;

    const setTooltip = (tooltipOpen: boolean, eventX: number, tooltipTitle: string) => {
      this.setState({tooltipOpen, eventX, tooltipTitle});
    };

    const columnRangeChartConfig = {
      chart: {
        type: ChartType.COLUMN_RANGE,
        inverted: ChartType.RANGED_BAR === chartType,
        height: chartHeight ? chartHeight : 190,
        marginLeft: marginLeft ? marginLeft : undefined,
        marginRight: marginRight ? marginRight : undefined,
        marginBottom: marginBottom ? marginBottom : undefined,
        spacingLeft: spacingLeft ? spacingLeft : undefined,
        spacingRight: spacingRight ? spacingRight : undefined,
        spacingTop: spacingTop ? spacingTop : undefined,
        events: {
          load() {
            document.querySelectorAll('.highcharts-credits').forEach((el) => el.remove());
            setLegendItemsVisibility(this.series, hideAllLegendItemsOnLoad, legendItemsToHideOnLoad);
          },
          render() {
            const hasSeriesData = checkSeriesHasData(this.series);

            // Handle No Data to Display rendering
            renderCheckNoDataToDisplay(this, id, noDataOptions, hasSeriesData);

            if (liveMask && hasSeriesData) {
              renderHatchOverlay(this, id);
            }
            const tickBias = 75.5;
            const ticks = (this.yAxis[0].ticks || []) as object[];
            const tickValues = Object.values(ticks);
            if (setLiveFlightOffset && tickValues.length > 0) {
              const startOffSet = Number(tickValues[0]['gridLine'].d.split(' ')[1]);
              const tickOffset = startOffSet - tickBias;
              const unit = (this.yAxis[0].width - 2 * tickOffset) / totalDuration;

              setLiveFlightOffset({
                startOffSet,
                unit
              });
            }
            this.legend.allItems.forEach(({legendItem}, idx) =>
              legendItem
                .on('mouseover', (event) => {
                  if (legendTooltips[idx]) {
                    setTooltip(true, event.x, legendTooltips[idx].title);
                  }
                })
                .on('mouseout', () => {
                  if (legendTooltips[idx]) {
                    setTooltip(false, 0, '');
                  }
                })
            );
          }
        }
      },
      exporting: {enabled: false},
      title: {
        text: ''
      },
      defs: patternDefinition,
      xAxis: customXAxis
        ? customXAxis
        : {
            categories,
            title: {
              text: xAxisLabel,
              style: {
                fontSize: '12px'
              },
              margin: 17
            },
            lineWidth: 0,
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
              style: {
                fontSize: '12px',
                fontWeight: '500',
                transform: 'translate(-3px)',
                color: AXIS_TEXT
              },
              x: -2
            },
            showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_X_AXIS)
          },
      yAxis: customYAxis
        ? customYAxis
        : {
            type: 'datetime' as Highcharts.AxisTypeValue,
            title: {
              text: yAxisLabel
            },
            gridLineWidth: 0,
            tickInterval: xAxisTickInterval,
            minorTicks: true,
            startOnTick: false,
            endOnTick: false,
            labels: {
              formatter: function () {
                if (xAxisFormatter) return xAxisFormatter ? xAxisFormatter(this.value) : undefined;
              },
              x: xAxisLabelXOffset ? xAxisLabelXOffset : 0,
              y: 18,
              style: {
                fontSize: '10px',
                fontWeight: '600',
                color: AXIS_TEXT,
                whiteSpace: 'nowrap',
                textOverflow: 'none'
              },
              padding: xAxisLabelPadding ? xAxisLabelPadding : undefined
            },
            tickWidth: xAxisTickInterval ? 1 : 0,
            tickLength: xAxisTickInterval ? 8 : 10,
            minorGridLineWidth: xAxisMinorTickInterval ? 0 : undefined,
            minorTickInterval: xAxisMinorTickInterval ? xAxisMinorTickInterval : undefined,
            minorTickLength: xAxisMinorTickInterval ? 6 : undefined,
            minorTickWidth: xAxisMinorTickInterval ? 1 : undefined,
            min: yAxisMin,
            max: yAxisMax,
            showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_Y_AXIS),
            offset: yAxisOffset ? yAxisOffset : 0
          },
      series,
      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '12px',
          fontWeight: '600',
          color: LEGEND_TEXT
        }
      },
      tooltip: tooltip
        ? {
            enabled: !tooltipDisabled,
            outside: true,
            backgroundColor: CHART_TOOLTIP_BG,
            borderRadius: 4,
            borderColor: CHART_TOOLTIP_BORDER,
            style: {
              color: CHART_TOOLTIP_TEXT
            },
            useHTML: htmlTooltip,
            shape: 'none' as Highcharts.TooltipShapeValue,
            hideDelay: 0.2,
            formatter() {
              return ReactDOMServer.renderToStaticMarkup(<ToolTip {...tooltip(this)} />);
            },
            positioner: positioner
              ? function (boxWidth, size, point) {
                  return positioner(this.chart.container, point.plotX, point.plotY, boxWidth, size);
                }
              : undefined
          }
        : undefined,
      plotOptions: {
        columnrange: {
          grouping: false,
          pointRange: 1.5,
          pointWidth: barWidth
        },
        series: {
          animation: false,
          events: {
            legendItemClick: customLegendClick
              ? function (event) {
                  customLegendClick(event);
                }
              : undefined
          }
        }
      }
    };

    // Force the IDs that Highcharts uses to be deterministic
    if (highchartsIdBase) {
      Highcharts.uniqueKey = getHighchartsId(highchartsIdBase);
    }
    return {
      id,
      config: columnRangeChartConfig
    };
  };
}

export default ColumnRangeChart;
