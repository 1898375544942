/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List Util for handling the data cells
 */

import {DefaultCellRendererV2, ListGridColumn, HistoryCellRenderer} from '@viasat/insights-components';
import {
  getColumnIdEnumName,
  getColumnFullTitle,
  getColumnFormattedFullTitle,
  getCustomerFilterRangeOptions,
  getSerialNumberFilterRangeOptions,
  getTailIdFilterRangeOptions,
  getAircraftTypeFilterRangeOptions,
  getServicePlansFilterRangeOptions,
  getNetworkFilterRangeOptions
} from '../../listUtils';
import {DefaultedTextCellRenderer} from '../../../common/elements/listGridCells/ListGridDefaultedText';
import {FilterType} from '../../../../utils/filterUtils';
import {TRAFFIC_COMPOSITION_CHART_PALETTE} from '../../../common/theme/Colors';
import {ITrafficCompositionData} from '../../../../store/queries/tailList/trafficCompositionQuery';
import {ITrafficChartConfig, TailListInfo} from '../../../../store/queries/tailList/tailListQuery';
import {
  CasesCellRenderer,
  getSoftwareVersionsFilterOptions,
  SoftwareRevisionsExpandedCellRenderer
} from '../../aircraftStatus/util/aircraftStatusListUtil';
import {IConnectivityTimeMetrics} from '../../../../store/queries/tailList/connectivityTimeMetrics';
import {formatHMSecondsValue} from '../../../../utils/DateTimeUtils';
import {
  IconTextOrLinkCellRenderer,
  SoftwareRevisionsCellRenderer,
  TailListCellRenderer,
  TrafficCompositionCellRenderer,
  trafficCompositionTooltip,
  HandoverFailureHistoryCellRenderer,
  DisconnectedEventsHistoryCellRenderer
} from '../TailListCellRenderers';
import {SWVersionInfo} from '../../../../store/queries/tailList/swVersionQuery';
import {FilterRow} from '../../../../store/reducers/FiltersReducer';
import {EquipmentHistoryInfo} from '../../../../store/queries/tailList/equipmentHistoryQuery';
import {
  buildCustomerHistoryPopupProps,
  buildEquipmentHistoryPopupProps,
  buildServicePlanHistoryPopupProps,
  buildDisconnectedEventsHistoryPopupProps,
  buildHandoverFailureHistoryPopupProps
} from './tailHistoryUtil';
import {TailPlanHistoryInfo} from '../../../../store/queries/tailList/planHistoryQuery';
import {TailCustomerHistoryInfo} from '../../../../store/queries/tailList/customerHistoryQuery';
import {IOpenCases} from '../../../../store/queries/tailList/openCasesQuery';
import {IConnectivityUsageMetrics} from '../../../../store/queries/tailList/connectivityUsageMetrics';
import {IPerformanceMetrics} from '../../../../store/queries/tailList/performanceMetricsQuery';
import {isNil} from 'lodash';
import {NO_DATA_INDICATOR} from '../../../../utils/constants';

// Required: Enum name should match columnId name, case-sensitive
export enum TailListColumnId {
  serialNumber = -1,
  tailId = 0,
  customer,
  var,
  aircraftManufacturer,
  aircraftType,
  networkCapability,
  macAddress,
  ipAddress,
  totalFlights,
  totalFlightTime,
  averageFlightTime,
  totalConnectedTime,
  averageConnectedTime,
  groundSessions,
  servicePlanName,
  equipmentHistoryCount,
  totalDataUsed,
  flightDataUsed,
  groundDataUsed,
  roamingDataUsed,
  kaTerminalVersion,
  bdtVersion,
  kuTerminalVersion,
  sedVersion,
  sscfVersion,
  disconnectedEventCount,
  failedHandoverPercent,
  ifcAvailabilityPercent
}

export interface ITailListData {
  serialNumber: string;
  tailId: string;
  customer: string;
  var: string;
  aircraftManufacturer: string;
  aircraftType: string;
  networkCapability: string;
  macAddress: string;
  ipAddress: string;
  totalFlights: string;
  totalFlightTime: string;
  averageFlightTime: string;
  groundSessions: string;
  servicePlanName: string;
}

interface TransformedTimeMetrics {
  averageConnectedTime: string;
  totalConnectedTime: string;
  groundDataUsed: number;
  totalFlightTime: string;
  averageFlightTime: string;
}

export const SOFTWARE_VERSIONS_MAPPING = {
  kaTerminalVersion: 'Ka-Band Terminal',
  bdtVersion: 'Beam Data Table (Ka)',
  kuTerminalVersion: 'Ku-Band Terminal',
  sedVersion: 'SED Map File (Ku)',
  sscfVersion: 'SSCF Map File (Ku)'
};

export enum FilterId {
  customer = 'customer',
  serialNumber = 'serialNumber',
  tailId = 'tailId',
  aircraftType = 'aircraftType',
  networkCapability = 'networkCapability',
  servicePlanName = 'servicePlanName',
  kaTerminalVersion = 'kaTerminalSwVersionStatus',
  bdtVersion = 'bdtVersionStatus',
  kuTerminalVersion = 'kuTerminalSwVersionStatus',
  sedVersion = 'sedFileVersionStatus',
  sscfVersion = 'sscfFileVersionStatus'
}

export enum FilterTailListMapping {
  kaTerminalSwVersionStatus = 'kaTerminalVersionStatus',
  kuTerminalSwVersionStatus = 'kuTerminalVersionStatus',
  bdtVersionStatus = 'bdtVersionStatus',
  sedFileVersionStatus = 'sedVersionStatus',
  sscfFileVersionStatus = 'sscfVersionStatus',
  networkCapability = 'networkCapability',
  servicePlanName = 'servicePlanName'
}

/**
 * Get Tail List ColumnId Name
 * @param columnId columnId enum to convert to name
 * @returns name of columnId as string
 */
export const getColumnIdName = (columnId: TailListColumnId) => getColumnIdEnumName(columnId, TailListColumnId);

// Query Field Constants
export const QUERY_FIELD_MAPPING = {
  serialNumber: 'serialNumber',
  tailId: 'tailId',
  customer: 'customer',
  var: 'var',
  aircraftManufacturer: 'aircraftManufacturer',
  aircraftType: 'aircraftType',
  networkCapability: 'networkCapability',
  macAddress: 'macAddress',
  ipAddress: 'ipAddress',
  totalFlights: 'totalFlights',
  totalFlightTime: 'totalFlightTime',
  averageFlightTime: 'averageFlightTime',
  totalConnectedTime: 'totalConnectedTime',
  averageConnectedTime: 'averageConnectedTime',
  groundSessions: 'groundSessions',
  servicePlanName: 'servicePlanName',
  equipmentHistoryCount: 'equipmentHistoryCount',
  kaTerminalVersion: 'kaTerminalVersion',
  bdtVersion: 'bdtVersion',
  kuTerminalVersion: 'kuTerminalVersion',
  sedVersion: 'sedVersion',
  sscfVersion: 'sscfVersion',
  totalDataUsed: 'totalDataUsed',
  flightDataUsed: 'flightDataUsed',
  groundDataUsed: 'groundDataUsed',
  roamingDataUsed: 'roamingDataUsed',
  failedHandoverPercent: 'failedHandoverPercent',
  disconnectedEventCount: 'disconnectedEventCount',
  ifcAvailabilityPercent: 'ifcAvailabilityPercent'
};

/**
 * Get Tail List Query Field Mapping
 * @param columnId columnId enum to convert to query field
 * @param append append string to columnId name (default='')
 * @returns query field as string, otherwise undefined
 */
export const getQueryFieldMapping = (columnId: TailListColumnId, append: string = '') => {
  const columnIdName = getColumnIdName(columnId) + append;
  return columnIdName ? QUERY_FIELD_MAPPING[columnIdName] : undefined;
};

const setColumnPropsList = (columnPropsList: ListGridColumn[]) => {
  return columnPropsList.map((column: ListGridColumn) => {
    const updatedColumn = column.fullTitlePrefix
      ? {
          ...column,
          fullTitle: getColumnFullTitle(column.fullTitlePrefix, column.title),
          formattedFullTitle: getColumnFormattedFullTitle(column.fullTitlePrefix, column.title)
        }
      : column;

    return updatedColumn;
  });
};

/**
 * Returns a list of columns that should be included based on the given
 * @return list of ListGridColumnProps objects
 */
export const buildTailListColumns = (
  isInternal: boolean,
  isEndUser: boolean,
  viewAsCustomer: boolean,
  showCompactView: boolean,
  handleTotalFlightsClick: (rowData: any) => void,
  handleGroundSessionsClick: (rowData: any) => void,
  handleOpenCasesClick: (rowData: any) => void,
  handleSoftwareRevisionsClick: (rowData: any) => void
) => {
  const columnPropsList: ListGridColumn[] = [
    {
      key: 'serialNumber',
      dataKey: 'serialNumber',
      showHideGroup: '__static',
      title: 'SN',
      getColumnJsx: DefaultCellRendererV2,
      width: 110,
      frozen: true,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.serial_number.column.title'
    },
    {
      key: 'tailId',
      dataKey: 'tailId',
      showHideGroup: '__static',
      title: 'Tail ID',
      getColumnJsx: DefaultCellRendererV2,
      width: 130,
      frozen: true,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.tail_id.column.title'
    }
  ];

  if (isInternal && !viewAsCustomer) {
    columnPropsList.push(
      {
        key: 'customer',
        dataKey: 'customer',
        showHideGroup: 'Customer Info',
        title: 'Customer',
        getColumnJsx: HistoryCellRenderer,
        width: !showCompactView ? 280 : 200,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.customer.column.title'
      },
      {
        key: 'var',
        dataKey: 'var',
        showHideGroup: 'Customer Info',
        title: 'VAR',
        getColumnJsx: DefaultCellRendererV2,
        width: showCompactView ? 180 : 160,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.var.column.title'
      }
    );
  }

  columnPropsList.push(
    {
      key: 'aircraftType',
      dataKey: 'aircraftType',
      showHideGroup: 'Tail Info',
      title: 'Aircraft Model',
      getColumnJsx: DefaultedTextCellRenderer,
      width: !showCompactView ? 170 : 150,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.aircraft_type.column.title'
    },
    {
      key: 'networkCapability',
      dataKey: 'networkCapability',
      showHideGroup: 'Tail Info',
      displayGroup: 1,
      title: 'Network',
      defaultSort: 'desc',
      getColumnJsx: DefaultedTextCellRenderer,
      width: !showCompactView ? 170 : 150,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.network_type.column.title'
    },
    {
      key: 'macAddress',
      dataKey: 'macAddress',
      showHideGroup: 'Tail Info',
      title: 'Ka MAC Address',
      defaultSort: 'desc',
      getColumnJsx: DefaultCellRendererV2,
      width: 160,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.mac_address.column.title'
    },
    {
      key: 'ipAddress',
      dataKey: 'ipAddress',
      showHideGroup: 'Tail Info',
      title: 'Ku IP Address',
      defaultSort: 'desc',
      getColumnJsx: DefaultCellRendererV2,
      width: 150,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.ip_address.column.title'
    },
    {
      key: 'equipmentHistoryCount',
      dataKey: 'equipmentHistoryCount',
      showHideGroup: 'Tail Info',
      title: 'Eqpt History',
      defaultSort: 'desc',
      getColumnJsx: HistoryCellRenderer,
      width: !showCompactView ? 150 : 125,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.equipment_history_count.column.title'
    },
    {
      key: 'totalFlights',
      dataKey: 'totalFlights',
      showHideGroup: 'Flight Info',
      title: 'Flights',
      defaultSort: 'desc',
      getColumnJsx: IconTextOrLinkCellRenderer,
      handleClick: handleTotalFlightsClick,
      width: 100,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.flights.column.title'
    },
    {
      key: 'totalFlightTime',
      dataKey: 'totalFlightTime',
      showHideGroup: 'Flight Info',
      title: !showCompactView ? 'Total Flight Hrs' : 'TTL FLT Hrs',
      defaultSort: 'desc',
      getColumnJsx: DefaultCellRendererV2,
      width: !showCompactView ? 150 : 114,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.total_flight_hours.column.title'
    },
    {
      key: 'averageFlightTime',
      dataKey: 'averageFlightTime',
      showHideGroup: 'Flight Info',
      title: !showCompactView ? 'Avg. Flight Time' : 'Avg FLT Time',
      defaultSort: 'desc',
      getColumnJsx: DefaultCellRendererV2,
      width: !showCompactView ? 150 : 120,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.average_flight_hours.column.title'
    },
    {
      key: 'totalConnectedTime',
      dataKey: 'totalConnectedTime',
      showHideGroup: 'Flight Info',
      title: !showCompactView ? 'Total Conn. Time' : 'TTL Conn Time',
      defaultSort: 'desc',
      getColumnJsx: TailListCellRenderer,
      width: !showCompactView ? 150 : 133,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.total_connected_hours.column.title'
    },
    {
      key: 'averageConnectedTime',
      dataKey: 'averageConnectedTime',
      showHideGroup: 'Flight Info',
      title: !showCompactView ? 'Avg. Conn. Time' : 'Avg Conn Time',
      defaultSort: 'desc',
      getColumnJsx: TailListCellRenderer,
      width: 150,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.average_connected_hours.column.title'
    },
    {
      key: 'groundSessions',
      dataKey: 'groundSessions',
      showHideGroup: 'Flight Info',
      title: !showCompactView ? 'Ground Sessions' : 'GRND Sessions',
      defaultSort: 'desc',
      getColumnJsx: IconTextOrLinkCellRenderer,
      handleClick: handleGroundSessionsClick,
      width: !showCompactView ? 170 : 139,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.ground_sessions.column.title'
    },
    {
      key: 'servicePlanName',
      dataKey: 'servicePlanName',
      showHideGroup: 'Tail Info',
      title: 'Service Plan',
      defaultSort: 'desc',
      getColumnJsx: HistoryCellRenderer,
      width: !showCompactView ? 200 : 190,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.service_plan.column.title'
    },
    {
      key: 'totalDataUsed',
      dataKey: 'totalDataUsed',
      showHideGroup: 'Data Usage',
      title: !showCompactView ? 'Total Data Used (GB)' : 'TTL Data Used (GB)',
      defaultSort: 'desc',
      getColumnJsx: TailListCellRenderer,
      width: !showCompactView ? 175 : 161,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.total_data_used.column.title'
    },
    {
      key: 'flightDataUsed',
      dataKey: 'flightDataUsed',
      showHideGroup: 'Data Usage',
      title: !showCompactView ? 'Flight Data (GB)' : 'FLT Data (GB)',
      defaultSort: 'desc',
      getColumnJsx: TailListCellRenderer,
      width: !showCompactView ? 160 : 150,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.flight_data_used.column.title'
    },
    {
      key: 'groundDataUsed',
      dataKey: 'groundDataUsed',
      showHideGroup: 'Data Usage',
      title: !showCompactView ? 'Ground Data (GB)' : 'GRND Data (GB)',
      defaultSort: 'desc',
      getColumnJsx: TailListCellRenderer,
      width: !showCompactView ? 160 : 139,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.ground_data_used.column.title'
    },
    {
      key: 'roamingDataUsed',
      dataKey: 'roamingDataUsed',
      showHideGroup: 'Data Usage',
      title: 'Roaming Data(GB)',
      defaultSort: 'desc',
      getColumnJsx: TailListCellRenderer,
      width: 160,
      sortable: true,
      columnHeaderTooltipText: 'tail_list.roaming_data_used.column.title'
    },
    {
      key: 'trafficComposition',
      dataKey: 'trafficComposition',
      showHideGroup: 'Data Usage',
      title: 'Traffic Composition',
      getColumnJsx: TrafficCompositionCellRenderer,
      width: 170,
      sortable: false,
      columnHeaderTooltipText: 'tail_list.traffic_composition.column.title',
      align: 'center'
    }
  );
  if (isInternal && !viewAsCustomer) {
    columnPropsList.push(
      {
        key: 'ifcAvailabilityPercent',
        dataKey: 'ifcAvailabilityPercent',
        showHideGroup: 'Performance',
        title: 'IFC Availability',
        getColumnJsx: DefaultCellRendererV2,
        width: 150,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.ifc_availability.column.title'
      },
      {
        key: 'disconnectedEventCount',
        dataKey: 'disconnectedEventCount',
        showHideGroup: 'Performance',
        title: 'DISCONNECTED EVENTS',
        getColumnJsx: DisconnectedEventsHistoryCellRenderer,
        width: 200,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.disconnected_event_count.column.title'
      },
      {
        key: 'failedHandoverPercent',
        dataKey: 'failedHandoverPercentValue',
        showHideGroup: 'Performance',
        title: 'HANDOVER FAIL %',
        getColumnJsx: HandoverFailureHistoryCellRenderer,
        width: 151,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.failed_handover_percent.column.title'
      }
    );
  }
  if (!showCompactView) {
    columnPropsList.push(
      {
        key: 'kaTerminalVersion',
        dataKey: 'kaTerminalVersion',
        showHideGroup: 'Software',
        title: 'Ka-Band Terminal',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.ka_band_terminal.column.title'
      },
      {
        key: 'bdtVersion',
        dataKey: 'bdtVersion',
        showHideGroup: 'Software',
        title: 'Beam Data Table',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.beam_data_table.column.title'
      },
      {
        key: 'kuTerminalVersion',
        dataKey: 'kuTerminalVersion',
        showHideGroup: 'Software',
        title: 'Ku-Band Terminal',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.ku_band_terminal.column.title'
      },
      {
        key: 'sedVersion',
        dataKey: 'sedVersion',
        showHideGroup: 'Software',
        title: 'SED Map File (Ku)',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.sed_map_file.column.title'
      },
      {
        key: 'sscfVersion',
        dataKey: 'sscfVersion',
        showHideGroup: 'Software',
        title: 'SSCF Map File (Ku)',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'tail_list.sscf_map_file.column.title'
      }
    );
  } else {
    columnPropsList.push({
      key: 'softwareRevisions',
      dataKey: 'softwareRevisions',
      showHideGroup: 'Software',
      title: 'SW Revisions',
      getColumnJsx: SoftwareRevisionsCellRenderer,
      width: 127,
      sortable: false,
      handleClick: handleSoftwareRevisionsClick,
      columnHeaderTooltipText: 'aircraft_status_list.software_revisions.column.title'
    });
  }
  columnPropsList.push({
    key: 'cases',
    dataKey: 'cases',
    showHideGroup: 'Support',
    title: 'Cases',
    getColumnJsx: CasesCellRenderer,
    width: 100,
    sortable: false,
    handleClick: handleOpenCasesClick,
    removeSort: true
  });

  const labeledList = setColumnPropsList(columnPropsList);
  return labeledList;
};

export const TAIL_LIST_FILTERS: Array<FilterType> = [
  {
    title: 'Customer',
    id: FilterId.customer,
    getValues: getCustomerFilterRangeOptions
  },
  {
    title: 'Serial Number',
    id: FilterId.serialNumber,
    getValues: getSerialNumberFilterRangeOptions
  },
  {
    title: 'Tail ID',
    id: FilterId.tailId,
    getValues: getTailIdFilterRangeOptions
  },
  {
    title: 'Aircraft Type',
    id: FilterId.aircraftType,
    getValues: getAircraftTypeFilterRangeOptions
  },
  {
    title: 'Network',
    id: FilterId.networkCapability,
    getValues: getNetworkFilterRangeOptions
  },
  {
    title: 'Service Plan',
    id: FilterId.servicePlanName,
    getValues: getServicePlansFilterRangeOptions
  },
  {
    title: 'Ka-Band Terminal',
    id: FilterId.kaTerminalVersion,
    getValues: getSoftwareVersionsFilterOptions
  },
  {
    title: 'Beam Data Table',
    id: FilterId.bdtVersion,
    getValues: getSoftwareVersionsFilterOptions
  },
  {
    title: 'Ku-Band Terminal',
    id: FilterId.kuTerminalVersion,
    getValues: getSoftwareVersionsFilterOptions
  },
  {
    title: 'SED Map File (Ku)',
    id: FilterId.sedVersion,
    getValues: getSoftwareVersionsFilterOptions
  },
  {
    title: 'SSCF Map File (Ku)',
    id: FilterId.sscfVersion,
    getValues: getSoftwareVersionsFilterOptions
  }
];

/**
 * Performs a deep copy of the filter list and hides field(s) based on the
 * passed in parameters.
 * @param isEndUser End user flag
 * @returns A list of filters for tail list
 */
export const getTailListFilters = (isEndUser: boolean): Array<FilterType> => {
  const filterList = [
    ...(isEndUser ? TAIL_LIST_FILTERS.filter((filter) => filter.id !== FilterId.customer) : TAIL_LIST_FILTERS)
  ];

  return filterList.sort((a: FilterType, b: FilterType): number => {
    return a.title > b.title ? 1 : -1;
  });
};

/**
 * Convert data usage in MB to GB
 * @param value Data Usage in MB
 */
export const convertToGb = (dataInMb: number) => {
  if (isNil(dataInMb)) return 0;
  return Number((dataInMb / 1000).toFixed(1));
};
/**
 * Sort Tail list view based on selected column and sorting order
 * @param column Tail List to be sorted column
 * @param order Sorting order asc or desc
 * @returns Sorted tail list data
 */
export const sortTailList = (column: string, order: string, tailListData: TailListInfo[]): TailListInfo[] => {
  const sortingColumn = QUERY_FIELD_MAPPING[column];
  tailListData?.sort((a, b) => {
    if (a[sortingColumn] === null) return 1;
    if (b[sortingColumn] === null) return -1;
    if (order === 'desc') {
      if (sortingColumn.includes('Version')) {
        const statusValue = sortingColumn + 'StatusValue';
        if (a[statusValue] === b[statusValue]) {
          return a[sortingColumn] < b[sortingColumn] ? 1 : -1;
        }
        return a[statusValue] < b[statusValue] ? 1 : -1;
      } else {
        if (a[sortingColumn] === b[sortingColumn]) {
          return a.tailId < b.tailId ? 1 : -1;
        }
        return a[sortingColumn] < b[sortingColumn] ? 1 : -1;
      }
    }
    if (order === 'asc') {
      if (sortingColumn.includes('Version')) {
        const statusValue = sortingColumn + 'StatusValue';
        if (a[statusValue] === b[statusValue]) {
          return a[sortingColumn] > b[sortingColumn] ? 1 : -1;
        }
        return a[statusValue] > b[statusValue] ? 1 : -1;
      } else {
        if (a[sortingColumn] === b[sortingColumn]) {
          return a.tailId > b.tailId ? 1 : -1;
        }
        return a[sortingColumn] > b[sortingColumn] ? 1 : -1;
      }
    }
    return 1;
  });
  return tailListData;
};

/**
 * Generates config for traffic composition bar chart
 * @param tailsTrafficData Traffic categories
 * @returns Chart config
 */
export const generateTrafficChartConfig = (tailsTrafficData: ITrafficCompositionData): ITrafficChartConfig => {
  let trafficCompositionChartConfig: ITrafficChartConfig;
  if (tailsTrafficData) {
    const trafficSeries = [
      {
        name: 'Streaming & Gaming',
        color: TRAFFIC_COMPOSITION_CHART_PALETTE[0],
        data: [tailsTrafficData.audio + tailsTrafficData.video],
        showInLegend: false,
        pointWidth: 24
      },
      {
        name: 'Browsing & Social Networks',
        color: TRAFFIC_COMPOSITION_CHART_PALETTE[1],
        data: [tailsTrafficData.social + tailsTrafficData.browsing],
        showInLegend: false,
        pointWidth: 24
      },
      {
        name: 'Productivity & Cloud Services',
        color: TRAFFIC_COMPOSITION_CHART_PALETTE[2],
        data: [tailsTrafficData.downloads],
        showInLegend: false,
        pointWidth: 24
      },
      {
        name: 'Background & App Updates',
        color: TRAFFIC_COMPOSITION_CHART_PALETTE[3],
        data: [tailsTrafficData.backgroundUpdates],
        showInLegend: false,
        pointWidth: 24
      },
      {
        name: 'Other & Speed Test',
        color: TRAFFIC_COMPOSITION_CHART_PALETTE[4],
        data: [tailsTrafficData.other],
        showInLegend: false,
        pointWidth: 24
      },
      {
        name: 'VPN',
        color: TRAFFIC_COMPOSITION_CHART_PALETTE[5],
        data: [tailsTrafficData.vpn],
        showInLegend: false,
        pointWidth: 24
      }
    ];
    trafficCompositionChartConfig = {
      chartConfig: {
        chartWidth: 148,
        chartHeight: 48,
        customXAxis: {categories: ['2020/21'], visible: false},
        customYAxis: {visible: false},
        series: trafficSeries,
        id: tailsTrafficData.tailId,
        chartType: 'bar',
        plotOptions: {
          series: {
            stacking: 'percent' as Highcharts.OptionsStackingValue,
            animation: false,
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        }
      },
      toolTip: trafficCompositionTooltip,
      disable: false
    };
  }
  return trafficCompositionChartConfig;
};
/**
 * Transforms connectivity time metrics
 * @param tail tail data
 * @param timeMetrics connectivity metrics of the tail
 * @returns transformed connectivity time metrics
 */
export const transformConnectivityTimeMetrics = (
  tail: TailListInfo,
  timeMetrics: IConnectivityTimeMetrics
): TransformedTimeMetrics => {
  const connectedSessions = tail.groundSessions + tail.totalFlights;
  const averageConnectedTime =
    timeMetrics && timeMetrics.totalConnectedDuration
      ? formatHMSecondsValue(timeMetrics.totalConnectedDuration / connectedSessions)
      : null;
  const totalConnectedTime =
    timeMetrics && timeMetrics.totalConnectedDuration && connectedSessions !== 0
      ? formatHMSecondsValue(timeMetrics.totalConnectedDuration)
      : null;
  const groundDataUsed = timeMetrics && timeMetrics.groundUsage ? convertToGb(timeMetrics.groundUsage) : null;

  const totalFlightTime =
    tail.totalFlights !== 0
      ? formatHMSecondsValue(Number(tail.totalFlightTimeRaw) - timeMetrics.groundConnectedDuration)
      : null;
  const averageFlightTime =
    tail.totalFlights !== 0
      ? formatHMSecondsValue(
          (Number(tail.totalFlightTimeRaw) - timeMetrics.groundConnectedDuration) / tail.totalFlights
        )
      : null;
  return {averageConnectedTime, totalConnectedTime, groundDataUsed, totalFlightTime, averageFlightTime};
};

const SW_VERSION_KEYS = [
  'kaTerminalVersion',
  'kaTerminalVersionStatus',
  'kaTerminalVersionStatusValue',
  'bdtVersion',
  'bdtVersionStatus',
  'bdtVersionStatusValue',
  'kuTerminalVersion',
  'kuTerminalVersionStatus',
  'kuTerminalVersionStatusValue',
  'sedVersion',
  'sedVersionStatus',
  'sedVersionStatusValue',
  'sscfVersion',
  'sscfVersionStatus',
  'sscfVersionStatusValue',
  'latestKaTerminalVersion',
  'latestKuTerminalVersion',
  'latestBdtVersion',
  'latestSedVersion',
  'latestSscfVersion'
];

const USAGE_METRICS_KEYS = ['totalDataUsed', 'planDataUsed', 'roamingDataUsed'];

/**
 * Map tail list ife and details with tail list data
 * @param tailListData list of raw Tail data returned by Looker
 * @param tailInfo list of raw Tail Details data returned by looker
 * @param tailListIfe list of Ife content
 * @returns Mapped tail list data
 */
export const buildTailListData = (
  tailListData: TailListInfo[],
  trafficCompositionData: ITrafficCompositionData[],
  equipmentHistoryData: EquipmentHistoryInfo[],
  tailPlanHistoryData: TailPlanHistoryInfo[],
  tailCustomerHistoryData: TailCustomerHistoryInfo[],
  tailSWVersionsData: SWVersionInfo[],
  totalOpenCases: IOpenCases[],
  connectivityTimeMetrics: IConnectivityTimeMetrics[],
  connectivityUsageMetrics: IConnectivityUsageMetrics[],
  performanceMetricsData: IPerformanceMetrics[],
  tailListSort: {order: string; column: string},
  swFilters: FilterRow[]
) => {
  tailListData.forEach((tail) => {
    tail.equipmentHistoryCountHistory = buildEquipmentHistoryPopupProps(
      equipmentHistoryData.filter((equipmentHistory) => equipmentHistory.aircraftId === tail.aircraftId)
    );

    const planHistoryData = tailPlanHistoryData
      ? tailPlanHistoryData.filter((row) => row.aircraftId === tail.aircraftId)
      : [];
    //To remove active service plan from the history list
    planHistoryData?.shift();
    tail.servicePlanNameHistory = buildServicePlanHistoryPopupProps(planHistoryData);

    let tailsTrafficData = trafficCompositionData?.find((trafficData) => trafficData.aircraftId === tail.aircraftId);
    if (tailsTrafficData) {
      tail.trafficComposition = generateTrafficChartConfig(tailsTrafficData);
    } else {
      tail.trafficComposition = null;
    }

    const customerHistoryData = tailCustomerHistoryData
      ? tailCustomerHistoryData.filter((row) => row.aircraftId === tail.aircraftId)
      : [];
    tail.customerHistory = buildCustomerHistoryPopupProps(customerHistoryData, tail.customer);

    const swVersionDataList = tailSWVersionsData
      ? tailSWVersionsData.filter((row) => row.aircraftId === tail.aircraftId)
      : [];
    const swVersionData = swVersionDataList[0];
    SW_VERSION_KEYS.forEach((swKey) => {
      tail[swKey] = swVersionData ? swVersionData[swKey] : null;
    });

    const casesData = totalOpenCases ? totalOpenCases.filter((row) => row.aircraftId === tail.aircraftId) : [];
    tail.openCases = casesData[0] ? casesData[0].openCasesCount : 0;

    const usageMetrics = connectivityUsageMetrics?.filter(
      (usageMetrics) => usageMetrics.aircraftId === tail.aircraftId
    )[0];

    USAGE_METRICS_KEYS.forEach((usageKey) => {
      tail[usageKey] = usageMetrics ? usageMetrics[usageKey] : 0;
    });

    const timeMetrics = connectivityTimeMetrics?.filter((timeMetrics) => timeMetrics.aircraftId === tail.aircraftId)[0];
    const transformedMetrics = transformConnectivityTimeMetrics(tail, timeMetrics);
    Object.keys(transformedMetrics).forEach((timeMetricKey) => {
      tail[timeMetricKey] = transformedMetrics[timeMetricKey];
    });

    const flightUsage =
      tail.totalDataUsed && tail.groundDataUsed ? Number((tail.totalDataUsed - tail.groundDataUsed).toFixed(1)) : null;
    // Checking if the flight usage falls under 0 then return 0 instead of negative values
    tail.flightDataUsed = flightUsage < 0 ? 0 : flightUsage;

    // performance metrics
    const performanceMetric = performanceMetricsData?.filter((metric) => metric.aircraftId === tail.aircraftId)[0];
    if (performanceMetric) {
      tail.failedHandoverPercent = performanceMetric.failedHandoverPercent;
      tail.disconnectedEventCount = performanceMetric.disconnectedEventCount;
      tail.failedHandoverPercentValue = `${(100 * performanceMetric.failedHandoverPercent).toFixed(1)}%`;
      tail.disconnectedEventCountHistory = buildDisconnectedEventsHistoryPopupProps(
        performanceMetric.disconnectedEventCount
      );
      tail.ifcAvailabilityPercent = performanceMetric.ifcAvailabilityPercent
        ? `${performanceMetric.ifcAvailabilityPercent}%`
        : '--';
      tail.failedHandoverPercentHistory = buildHandoverFailureHistoryPopupProps(
        performanceMetric.failedHandoverPercent === 0 ? 0 : 1
      );
    }
  });
  if (swFilters && swFilters.length && tailSWVersionsData) {
    const filteredData = tailListData.filter((tRow) =>
      tailSWVersionsData.some((swRow) => swRow.aircraftId.includes(tRow.aircraftId))
    );
    return filteredData.sort(dynamicSort(tailListSort['column'], tailListSort['order']));
  } else {
    return tailListData.sort(dynamicSort(tailListSort['column'], tailListSort['order']));
  }
};

/**
 * Sorting tail list details column
 * @param column sorting column
 * @param order sorting order
 * @return sorted data
 */
export const dynamicSort = (column: string, order: string) => {
  // Treat null, undefined, and no data indicators the same
  const normalizeValue = (value) => (isNil(value) || value === NO_DATA_INDICATOR ? null : value);

  const sortOrder = order === 'desc' ? -1 : 1;
  return (obj1, obj2) => {
    const value1 = normalizeValue(obj1[column]);
    const value2 = normalizeValue(obj2[column]);

    return value1 === value2 ? 0 : (value1 === null ? -1 : value2 === null ? 1 : value1 < value2 ? -1 : 1) * sortOrder;
  };
};
