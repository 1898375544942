/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tooltip Series Prefix Icon used to identify chart series color
 */

import React from 'react';
import {ColorProperty, FontSizeProperty, LineHeightProperty, VerticalAlignProperty, WidthProperty} from 'csstype';
import {
  TOOLTIP_SERIES_PREFIX_WIDTH,
  TOOLTIP_SERIES_PREFIX_LINE_HEIGHT_SAFARI,
  TOOLTIP_SERIES_PREFIX_FONT_SIZE,
  TOOLTIP_SERIES_PREFIX_LINE_HEIGHT,
  TOOLTIP_SERIES_PREFIX_VERTICAL_ALIGN
} from '../../../../../utils/constants';

/**
 * Check Browser useragent for Safari
 * Hack: not safe, should do feature check instead
 * @return If browser is Safari variant
 */
const isSafari = (): boolean => {
  const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;
  const isEdge = navigator.userAgent.indexOf('Edge') !== -1;
  const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
  return isSafari && !isChrome && !isEdge && !isFirefox;
};

export interface SeriesPrefixIconProps {
  color?: ColorProperty;
  fontSize?: FontSizeProperty<string>;
  lineHeight?: LineHeightProperty<string>;
  verticalAlign?: VerticalAlignProperty<string>;
  width?: WidthProperty<string>;
}

const SeriesPrefixIcon: React.FC<SeriesPrefixIconProps> = ({
  color = undefined,
  fontSize = TOOLTIP_SERIES_PREFIX_FONT_SIZE,
  lineHeight = TOOLTIP_SERIES_PREFIX_LINE_HEIGHT,
  verticalAlign = '5%',
  width = TOOLTIP_SERIES_PREFIX_WIDTH
}: SeriesPrefixIconProps): JSX.Element => {
  // Adjust line height & vertical alignment specifically for Safari compatibility
  lineHeight =
    lineHeight === TOOLTIP_SERIES_PREFIX_LINE_HEIGHT
      ? isSafari()
        ? TOOLTIP_SERIES_PREFIX_LINE_HEIGHT_SAFARI
        : TOOLTIP_SERIES_PREFIX_LINE_HEIGHT
      : lineHeight;
  verticalAlign =
    verticalAlign === TOOLTIP_SERIES_PREFIX_VERTICAL_ALIGN
      ? isSafari()
        ? '-' + TOOLTIP_SERIES_PREFIX_VERTICAL_ALIGN
        : TOOLTIP_SERIES_PREFIX_VERTICAL_ALIGN
      : verticalAlign;

  return (
    <>
      <span
        style={{
          color: color,
          display: 'inline-block',
          fontSize: fontSize,
          lineHeight: lineHeight,
          overflow: 'hidden',
          textAlign: 'center',
          verticalAlign: verticalAlign,
          width: '18px',
          height: '16px'
        }}
      >
        <svg
          focusable="false"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          aria-hidden="true"
          role="presentation"
          style={{
            position: 'relative',
            verticalAlign: 'text-top'
          }}
        >
          <circle id={'tooltip-dot'} cx={6} cy={10} r={6} fill={color} />
        </svg>
      </span>
      <span
        style={{
          display: 'inline-block',
          lineHeight: TOOLTIP_SERIES_PREFIX_LINE_HEIGHT,
          width: '2px'
        }}
      />
    </>
  );
};

export default SeriesPrefixIcon;
