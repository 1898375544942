/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: chart component styles
 */

import styled from '@emotion/styled';
import {
  AXIS_TEXT,
  CHART_AXIS_MINOR_TICK,
  WHITE,
  CONNECTIVITY_OUTLOOK_EVENTS_TOOLTIP_COLOR,
  MAP_POPUP_DEFAULT_BORDER,
  TRANSPARENT,
  INTERNAL_AXIS_TEXT
} from '../../theme/Colors';

export const ColumnRangeStyledContainer = styled.div`
  .highcharts-series rect {
    stroke-width: 1px;
  }
  .highcharts-yaxis path {
    stroke-width: 1px;
    stroke: ${CHART_AXIS_MINOR_TICK};
  }
  .highcharts-grid.highcharts-xaxis-grid path:last-child {
    stroke: none;
  }
  .highcharts-data-labels {
    pointer-events: none;
    .data-label-tooltip {
      .tooltip {
        position: fixed;
        content: attr(data-tooltip);
        opacity: 0;
        transition: all 0.15s ease;
        min-width: 140px;
        height: 15px;
        background: ${WHITE};
        box-shadow: 0px 4px 12px 2px rgb(0 0 0 / 38%);
        border-radius: 4px;
        margin-top: -100px;
        z-index: 99999;
        padding: 12px 12px;
        margin-left: -50px;
        font-size: 12px;
        font-weight: normal;
        color: ${CONNECTIVITY_OUTLOOK_EVENTS_TOOLTIP_COLOR};
        span {
          display: inline-block;
          margin-right: 2px;
        }
        .time-range {
          font-family: Source Sans Pro;
          font-size: 14px;
          line-height: 20px;
        }
        .duration {
          height: 20px;
          font-family: Source Sans Pro;
          font-size: 14px;
          line-height: 20px;
          color: #465967;
          margin: 0px 4px;
        }
        .event-status {
          width: 116px;
          height: 16px;
          left: 16px;
          top: 0px;
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
        }
        .default-border {
          border-top: 1px solid ${MAP_POPUP_DEFAULT_BORDER};
          padding-top: 5px;
          width: 150px;
        }
        .status-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      .tooltip-box {
        margin-top: -90px;
        height: 58px;
        min-width: 164px;
        width: auto;
        padding: 8px 3px 0px 8px;
      }
      &:hover .tooltip {
        opacity: 1;
        cursor: pointer;
      }
      .status-icon {
        pointer-events: auto;
      }
      .status-icon:hover .tooltip {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
`;

export const LineChartStyledContainer = styled.div<{colors: any}>`
  height: 100%;
  .highcharts-data-label tspan,
  .highcharts-plot-line-label tspan {
    white-space: nowrap;
    stroke: none;
  }
  .highcharts-data-label .highcharts-text-outline,
  .highcharts-plot-line-label .highcharts-text-outline {
    stroke: none;
    fill: none;
  }
  .column-legend-entry {
    color: ${AXIS_TEXT};
    font-family: 'Source Sans Pro';
    font-size: 14px;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 400;
    margin-top: -2px;
  }

  .highcharts-markers path.highcharts-halo {
    display: none;
  }
  .highcharts-markers path.highcharts-halo + path {
    stroke: white;
    stroke-width: 1px;
  }

  svg text,
  svg tspan {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .highcharts-line-series:not(.highcharts-legend-item) {
    .highcharts-point:not(.highcharts-point-hover) {
      fill: none;
    }
  }

  .highcharts-legend {
    .highcharts-graph {
      stroke: none;
    }
    .highcharts-point {
      transform: translate(-8px, -11px) scale(2);
    }
  }
`;

export const StackedColumnChartStyledContainer = styled.div`
  height: 225px;
  .stacked-column-legend-entry {
    color: black;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 400;
    margin-top: -2px;
  }

  svg text,
  svg tspan {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .highcharts-legend {
    .highcharts-graph {
      stroke: none;
    }
    .highcharts-point {
      transform: translate(-1px, 1px);
    }
  }
`;

export const ScatterPlotChartStyledContatiner = styled.div<{internal: boolean}>`
  height: 100%;
  .highcharts-data-label tspan,
  .highcharts-plot-line-label tspan {
    stroke: none;
  }
  .highcharts-data-label .highcharts-text-outline,
  .highcharts-plot-line-label .highcharts-text-outline {
    stroke: none;
    fill: none;
  }

  .column-legend-entry {
    color: ${(props) => (props.internal ? INTERNAL_AXIS_TEXT : AXIS_TEXT)};
    font-family: 'Source Sans Pro';
    font-size: 14px;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 400;
    margin-top: -2px;
  }

  .highcharts-markers path.highcharts-halo {
    display: none;
  }
  .highcharts-markers path.highcharts-halo + path {
    stroke: white;
    stroke-width: 1px;
  }

  svg text,
  svg tspan {
    font-family: 'Source Sans Pro', sans-serif;
    color: ${(props) => (props.internal ? INTERNAL_AXIS_TEXT : AXIS_TEXT)} !important;
    fill: ${(props) => (props.internal ? INTERNAL_AXIS_TEXT : AXIS_TEXT)} !important;
  }

  .highcharts-line-series:not(.highcharts-legend-item) {
    .highcharts-point:not(.highcharts-point-hover) {
      fill: none;
    }
  }

  .highcharts-legend {
    .highcharts-graph {
      stroke: none;
    }
    .highcharts-point {
      transform: translate(-4px, -6px) scale(1.5);
    }
  }

  .highcharts-background {
    fill: ${TRANSPARENT};
  }
`;
