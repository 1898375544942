/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Line Chart with Plotlines
 */

import Highcharts from 'highcharts';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {isEqual} from 'lodash';

import {ILineChart, ChartType, PlotLineType} from './types';
import ToolTip from './ToolTip/ToolTip';
import {getHighchartsId, setLegendItemsVisibility} from './chartUtils';
import {
  checkSeriesHasData,
  checkShowEmptyAxis,
  renderCheckNoDataToDisplay,
  renderHatchOverlay
} from './RenderOverlay';

import {AXIS_TEXT, CHART_TOOLTIP_BG, CHART_TOOLTIP_TEXT, CHART_TOOLTIP_BORDER} from '../../theme/Colors';
import {CHART_NO_DATA_X_AXIS, CHART_NO_DATA_Y_AXIS} from '../../../../utils/constants';
import {LineChartStyledContainer} from './ChartStyles';

class LineChart extends React.Component<ILineChart, any> {
  ref = null;
  config = {};

  componentDidMount() {
    const {id, config} = this.generateChart(this.props);
    this.ref = Highcharts.chart(id, config);
    this.config = config.series;
  }

  render() {
    const {id, palette} = this.props;
    const {config} = this.generateChart(this.props);

    if (!isEqual(this.config, config.series) && this.ref) {
      this.config = config.series;
      Highcharts.chart(id, config).update(config);
    }

    return <LineChartStyledContainer data-test="line-chart" className="chart-container" id={id} colors={palette} />;
  }

  private generateChart = (props: ILineChart) => {
    const {
      id,
      highchartsIdBase,
      height,
      defaultChartPalette,
      connectNulls,
      disableMarker,
      plotline,
      plotlineColor,
      plotlineType,
      xAxisData,
      xAxisTickPositions,
      xAxisTickInterval,
      xAxisLabelXOffset,
      xAxisMinorTickInterval,
      xAxisFormatter,
      xAxisLabel,
      yAxis,
      yAxisFormatter,
      series,
      seriesFormatter,
      legend,
      legendItemStyle,
      hideAllLegendItemsOnLoad,
      legendItemsToHideOnLoad,
      htmlTooltip,
      sharedTooltip,
      tooltip,
      liveMask,
      noDataOptions,
      marginLeft,
      marginRight,
      spacingLeft,
      spacingRight,
      plotBands
    } = props;

    const plotSeriesLabelStyle = {
      style: {
        fontWeight: '400',
        fontSize: '16px'
      }
    };
    const xAxisLabelFormat = {
      x: xAxisLabelXOffset,
      y: 18,
      style: {
        fontSize: '10px',
        fontWeight: '600',
        color: AXIS_TEXT,
        whiteSpace: 'nowrap',
        textOverflow: 'none'
      }
    };
    const yAxisLabelFormat = {
      style: {
        fontSize: '12px',
        color: AXIS_TEXT,
        whiteSpace: 'nowrap',
        textOverflow: 'none'
      }
    };

    const plotlineConfig = (value, color) => ({
      value,
      color,
      width: 2,
      dashStyle: 'shortdash' as Highcharts.DashStyleValue,
      startOnTick: false,
      zIndex: 99
    });

    const lineChartConfig = {
      chart: {
        type: ChartType.LINE,
        height,
        marginLeft: marginLeft ? marginLeft : undefined,
        marginRight: marginRight ? marginRight : undefined,
        spacingLeft: spacingLeft ? spacingLeft : undefined,
        spacingRight: spacingRight ? spacingRight : undefined,
        spacingTop: 20,
        alignTicks: true,
        events: {
          load() {
            document.querySelectorAll('.highcharts-credits').forEach((el) => el.remove());
            setLegendItemsVisibility(this.series, hideAllLegendItemsOnLoad, legendItemsToHideOnLoad);
          },
          render() {
            const hasSeriesData = checkSeriesHasData(this.series);

            // Handle No Data to Display rendering
            renderCheckNoDataToDisplay(this, id, noDataOptions, hasSeriesData);

            if (liveMask && hasSeriesData) {
              renderHatchOverlay(this, id);
            }
          }
        }
      },
      title: {text: ''},
      legend: {enabled: legend, itemStyle: legendItemStyle ? legendItemStyle : undefined},
      exporting: {enabled: false},
      yAxis: yAxis.map(
        (axis) =>
          ({
            title: {
              text: axis.label,
              offset: axis.titleOffset,
              style: yAxisLabelFormat.style
            },
            plotLines: axis.plotline
              ? [plotlineConfig(axis.plotline, axis.plotlineColor) as Highcharts.YAxisPlotLinesOptions]
              : [],
            allowDecimals: false,
            tickPositions: axis.tickPositions,
            labels: yAxisFormatter
              ? {
                  formatter() {
                    return yAxisFormatter(this);
                  },
                  x: axis.labelXOffset,
                  ...yAxisLabelFormat
                }
              : {x: axis.labelXOffset, ...yAxisLabelFormat},

            gridLineWidth: plotlineType === PlotLineType.horizontal ? 1 : 0,
            tickInterval: axis.tickInterval,
            endOnTick: true,
            opposite: axis.opposite,
            tickPixelInterval: 20,
            showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_Y_AXIS)
          } as Highcharts.YAxisOptions)
      ),
      xAxis: {
        type: 'datetime',
        title: {
          text: xAxisLabel
        },
        plotLines:
          plotlineType === PlotLineType.vertical
            ? [plotlineConfig(plotline, plotlineColor) as Highcharts.XAxisPlotLinesOptions]
            : [],
        plotBands: plotBands ? plotBands : [],
        labels: {
          formatter: function () {
            if (xAxisFormatter) return xAxisFormatter ? xAxisFormatter(this.value) : undefined;
          },
          ...xAxisLabelFormat
        },
        tickInterval: xAxisTickInterval,
        tickmarkPlacement: 'on',
        startOnTick: false,
        endOnTick: false,
        tickPositions: xAxisTickPositions ? xAxisTickPositions : undefined,
        categories: xAxisData,
        lineWidth: 0,
        gridLineWidth: 1,
        tickWidth: xAxisTickInterval || xAxisTickPositions ? 1 : 0,
        tickLength: xAxisTickInterval || xAxisTickPositions ? 8 : 10,
        minorGridLineWidth: xAxisMinorTickInterval ? 0 : undefined,
        minorTickInterval: xAxisMinorTickInterval ? xAxisMinorTickInterval : undefined,
        minorTickLength: xAxisMinorTickInterval ? 6 : undefined,
        minorTickWidth: xAxisMinorTickInterval ? 1 : undefined,
        showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_X_AXIS)
      } as Highcharts.XAxisOptions,
      tooltip: tooltip
        ? {
            outside: true,
            backgroundColor: CHART_TOOLTIP_BG,
            borderRadius: 4,
            borderColor: CHART_TOOLTIP_BORDER,
            shared: sharedTooltip,
            crosshairs: sharedTooltip,
            style: {
              color: CHART_TOOLTIP_TEXT
            },
            shape: 'none' as Highcharts.TooltipShapeValue,
            hideDelay: 0.2,
            useHTML: htmlTooltip,
            formatter() {
              return ReactDOMServer.renderToStaticMarkup(<ToolTip {...tooltip(this)} />);
            }
          }
        : undefined,
      plotOptions: {
        series: {
          connectNulls: Boolean(connectNulls),
          color: defaultChartPalette[0],
          marker: {
            enabled: Boolean(disableMarker) === false,
            radius: 3
          },
          animation: false,
          dataLabels:
            seriesFormatter && plotlineType === PlotLineType.vertical
              ? {
                  enabled: true,
                  formatter() {
                    return seriesFormatter(this.y);
                  },
                  ...plotSeriesLabelStyle
                }
              : plotSeriesLabelStyle
        }
      },
      series: series as Highcharts.SeriesLineOptions[],
      responsive: this.prepareResponsiveObject()
    };

    // Force the IDs that Highcharts uses to be deterministic
    if (highchartsIdBase) {
      Highcharts.uniqueKey = getHighchartsId(highchartsIdBase);
    }
    Highcharts.setOptions({colors: defaultChartPalette});

    return {
      id,
      config: lineChartConfig
    };
  };

  private prepareResponsiveObject() {
    const {xAxisTickPositions, xAxisData} = this.props;
    const tickCount = typeof xAxisTickPositions === 'undefined' ? xAxisData.length : xAxisTickPositions.length - 2;

    return {
      rules: [
        {
          condition: {maxWidth: 102 * tickCount},
          chartOptions: {
            xAxis: {
              labels: {
                x: 0
              }
            }
          }
        }
      ]
    };
  }
}

export default LineChart;
