/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Map Legend Wrapper Component
 */

import React from 'react';
import styled from '@emotion/styled';
import {FormattedMessage} from 'react-intl';
import {ColorLegend, ColorLegendTitleMode} from '@viasat/insights-components';

import {
  ABOVE_HIGHER_THRESHOLD_COLOR,
  ABOVE_TARGET_COLOR,
  BELOW_LOWER_THRESHOLD_COLOR,
  GREY
} from '../../common/theme/Colors';

import {MapViewContext, MapLegendWrapperProps} from '../../../utils/MapUtil';
import {ConnectionStatus} from '../../../utils/constants';
import {formatToolTipText} from '../../../utils/TooltipUtil';

const LegendWrapperDiv = styled.div`
  bottom: 34px;
  right: 16px;
  position: absolute;
  z-index: 2;
`;

const MapLegendWrapper: React.FC<MapLegendWrapperProps> = ({
  context,
  viewName,
  legendWrapperHeight,
  metricsData,
  mapDivRef
}) => {
  const isInitiallyCollapsed = sessionStorage.isMapLegendCollapsed
    ? sessionStorage.isMapLegendCollapsed === 'true'
    : false;

  const aircraftMetricsCounts = metricsData?.reduce(
    (sum, aircraft) => {
      if (aircraft.status === ConnectionStatus.CONNECTED) {
        return {...sum, connected: sum.connected + 1};
      } else if (aircraft.status === ConnectionStatus.DISCONNECTED) {
        return {...sum, disconnected: sum.disconnected + 1};
      } else if (aircraft.status === ConnectionStatus.IMPAIRED) {
        return {...sum, impaired: sum.impaired + 1};
      }
      return {...sum, offline: sum.offline + 1};
    },
    {connected: 0, disconnected: 0, impaired: 0, offline: 0}
  );

  /**
   * Saves the new legend collapsed state to session storage
   * @param newCollapsedState The new collapsed state
   */
  const handleCollapsedChange = (newCollapsedState: boolean) => {
    sessionStorage.isMapLegendCollapsed = String(newCollapsedState);
  };

  /**
   * Callback to get the full element ID given the provided name and type values
   * @param name The name of the element
   * @param type The type of the element
   * @returns The full element ID
   */
  const getFullElementId = (name: string, type: string) => `${viewName}__${name}-${type}`;

  /**
   * Returns the JSX element(s) for the tooltip given the provided string ID
   * @param id String ID (should be in en.json)
   * @returns Tooltip JSX element
   */
  const getLegendItemTooltip = (id: string): JSX.Element => {
    return (
      <FormattedMessage id={id} values={{time: 10}}>
        {formatToolTipText}
      </FormattedMessage>
    );
  };

  /**
   * Generates the list of legend items based on the map view
   * @param context The map view context
   * @returns The list of legend items
   */
  const generateLegendItems = (context: MapViewContext) => {
    const legendItems = [
      {
        name: ConnectionStatus.DISCONNECTED,
        color: BELOW_LOWER_THRESHOLD_COLOR,
        count: aircraftMetricsCounts ? aircraftMetricsCounts.disconnected : 0,
        toolTipFragment: getLegendItemTooltip('fleet_map.legend.disconnected.tooltip')
      },
      {
        name: ConnectionStatus.IMPAIRED,
        color: ABOVE_HIGHER_THRESHOLD_COLOR,
        count: aircraftMetricsCounts ? aircraftMetricsCounts.impaired : 0,
        toolTipFragment: getLegendItemTooltip('fleet_map.legend.impaired.tooltip')
      },
      {
        name: ConnectionStatus.CONNECTED,
        color: ABOVE_TARGET_COLOR,
        count: aircraftMetricsCounts ? aircraftMetricsCounts.connected : 0,
        toolTipFragment: getLegendItemTooltip('fleet_map.legend.online.tooltip')
      }
    ];

    if (context !== MapViewContext.FLIGHT_DETAILS_MAP) {
      // Add the Offline legend
      legendItems.push({
        name: ConnectionStatus.OFFLINE,
        color: GREY,
        count: aircraftMetricsCounts ? aircraftMetricsCounts.offline : 0,
        toolTipFragment: getLegendItemTooltip('fleet_map.legend.offline.tooltip')
      });
    }

    return legendItems;
  };

  const useCompactMode = context === MapViewContext.FLIGHT_DETAILS_MAP;
  const titleMode = useCompactMode
    ? ColorLegendTitleMode.TITLE_MODE_ALWAYS_HIDE
    : ColorLegendTitleMode.TITLE_MODE_HIDE_ON_EXPAND;

  return (
    <LegendWrapperDiv>
      <ColorLegend
        tooltipContainer={mapDivRef.current ? mapDivRef.current.mapRef : null}
        height={legendWrapperHeight}
        isInitiallyCollapsed={isInitiallyCollapsed}
        titleMode={titleMode}
        showCounts={!useCompactMode}
        items={generateLegendItems(context)}
        handleCollapsedChange={handleCollapsedChange}
        getFullElementId={getFullElementId}
      />
    </LegendWrapperDiv>
  );
};

export default MapLegendWrapper;
