/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ChartWrapper Container for Events Timeline
 */

import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {isNil} from 'lodash';

import {
  areMsTimestampMinutesEqualTo,
  msAsString,
  TIME_CONCISE_NO_PAD_FORMAT,
  DATE_CONCISE_FORMAT,
  MS_IN_MIN
} from '../../../utils/DateTimeUtils';
import ToolTipDateTimeRangeLabel from '../../common/elements/chart/ToolTip/ToolTipDateTimeRangeLabel';
import {ABOVE_HIGHER_THRESHOLD_COLOR, ABOVE_TARGET_COLOR, BELOW_LOWER_THRESHOLD_COLOR} from '../../common/theme/Colors';
import {ChartTimeSettings} from '../flightDetailsUtil';
import {
  EventsTimelineData,
  EventsTimelineStatus,
  getFlightPhaseBuckets,
  getEventFlightPhase,
  FlightPhaseCategory,
  SeriesData,
  SeriesDataPoint,
  positioner,
  FlightPhaseData,
  mergePointsInSeries
} from './EventsTimelineUtils';
import EventsTimelineToolTip from './tooltip/EventsTimelineToolTip';
import {BAR_WIDTH} from '../../../utils/constants';
import ColumnRangeChart from '../../common/elements/chart/ColumnRangeChart';
import {formatConfig} from '../../common/elements/chart/chartUtils';
import {useIntl} from 'react-intl';
import {ChartType} from '../../common/elements/chart/types';

const ChartContainer = styled.div`
  svg text,
  svg tspan {
    font-family: 'Source Sans Pro', sans-serif;
  }
`;

interface EventsTimelineProps {
  currentTime: string;
  eventsTimeline: EventsTimelineData[];
  flightPhaseData: FlightPhaseData;
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  maxDate: any;
  isLiveFlight: boolean;
  lastFlightPhase: string;
  chartTimeSettings: ChartTimeSettings;
  isLoading: boolean;
  parentLeftOffset: number;
  liveMask: boolean;
  setLiveFlightOffset: (payload: {startOffSet: number; unit: number}) => void;
  liveFlightOffset: {
    startOffSet: number;
    unit: number;
  };
  scrollContainerRef: any;
}

const EventsTimeline: React.FC<EventsTimelineProps> = ({
  currentTime,
  isLoading,
  eventsTimeline,
  flightPhaseData,
  hideAllLegendItemsOnLoad,
  legendItemsToHideOnLoad,
  maxDate,
  isLiveFlight,
  lastFlightPhase,
  chartTimeSettings,
  parentLeftOffset,
  liveMask,
  setLiveFlightOffset,
  liveFlightOffset,
  scrollContainerRef
}: EventsTimelineProps) => {
  const theme: any = useTheme();
  const [chartConfig, setChartConfig] = useState<any>(null);

  const intl = useIntl();

  useEffect(() => {
    if (
      !isLoading &&
      eventsTimeline &&
      flightPhaseData &&
      chartTimeSettings.start &&
      chartTimeSettings.end &&
      (!isNaN(maxDate) || !isLiveFlight)
    ) {
      // We have to do a semi deep copy because the updatePreFilledEventsData messes with child objects.
      const eventsTimelineToDisplay: EventsTimelineData[] = eventsTimeline.map(
        (event: EventsTimelineData): EventsTimelineData => {
          return {
            eventDate: event.eventDate,
            status: event.status,
            isRoaming: event.isRoaming
          };
        }
      );

      // General config
      const id = 'flightDetails--eventsTimeline__timeSeries-chart';
      const highchartsIdBase = 'eventsTimeline';
      const chartType = ChartType.RANGED_BAR;
      const marginLeft = 75;
      const marginRight = 25;
      const defaultChartPalette = theme ? theme.defaultChartPalette : [];

      const toMs = (input: Date | string) => Number(moment.utc(input).format('x'));

      // Flight phase category buckets
      const categories = [
        FlightPhaseCategory.cruisingLabel,
        FlightPhaseCategory.ascDescLabel,
        FlightPhaseCategory.groundLabel
      ];

      const colors = {
        [EventsTimelineStatus.IMPAIRED]: ABOVE_HIGHER_THRESHOLD_COLOR,
        [EventsTimelineStatus.CONNECTED]: ABOVE_TARGET_COLOR,
        [EventsTimelineStatus.DISCONNECTED]: BELOW_LOWER_THRESHOLD_COLOR,
        [EventsTimelineStatus.CONNECTED_ROAMING]: 'url(#connectedRoamingPattern)',
        [EventsTimelineStatus.DISCONNECTED_ROAMING]: 'url(#disconnectedRoamingPattern)',
        [EventsTimelineStatus.IMPAIRED_ROAMING]: 'url(#impairedRoamingPattern)'
      };

      // X-axis
      const xAxisLabel = 'Flight Phase';

      const xAxisFormatter = (value: any) => {
        // Note: We're not using minor ticks to be consistent with other category-based charts (Minor ticks are not
        //       supported for category-based axes)
        if (Number(value) < 1e12 || !areMsTimestampMinutesEqualTo(value, chartTimeSettings.labeledTickMinutesValues)) {
          return '';
        }

        const hourLabel = msAsString(value, TIME_CONCISE_NO_PAD_FORMAT);
        const dayLabel = msAsString(value, DATE_CONCISE_FORMAT);
        return hourLabel === '0:00' ? dayLabel : hourLabel;
      };

      // This padding value will cause the x-axis labels to rotate at the same point as the other charts
      const xAxisLabelPadding = 5.7;

      // Y-axis
      const yAxisLabel = '';
      const PAD_IN_MS = (chartTimeSettings.tickIntervalMinutes / 2) * MS_IN_MIN;
      const yAxisMin = toMs(chartTimeSettings.start) - PAD_IN_MS;
      const yAxisMax = toMs(chartTimeSettings.end) + PAD_IN_MS;
      const chartStartPointLowThreshold = 1140000; // 19 minutes
      const chartStartPointHighThreshold = 1200000; // 20 minutes
      const emptyDataPointLow = toMs(chartTimeSettings.start) - chartStartPointLowThreshold;
      const emptyDataPointHigh = toMs(chartTimeSettings.start) - chartStartPointHighThreshold;
      const totalDuration = moment.utc(chartTimeSettings.end).diff(chartTimeSettings.start, 'minutes');

      // Calculate tick interval milliseconds
      const xAxisTickInterval = chartTimeSettings.tickIntervalMinutes * MS_IN_MIN;

      // Series
      const newSeries = (status: EventsTimelineStatus, legendIndex: Number): SeriesData => ({
        name: status,
        color: colors[status],
        borderColor: colors[status],
        data: [],
        legendIndex: legendIndex,
        showInLegend: !isNil(legendIndex)
      });

      const flightPhaseBuckets = getFlightPhaseBuckets(flightPhaseData, lastFlightPhase);

      const newPoint = (row: EventsTimelineData): SeriesDataPoint => ({
        x: getEventFlightPhase(row.eventDate, flightPhaseBuckets, categories),
        low: row.eventDate.valueOf(),
        high: row.eventDate.valueOf() + 60 * 1000
      });

      const initialSeries = {
        [EventsTimelineStatus.CONNECTED]: newSeries(EventsTimelineStatus.CONNECTED, 2),
        [EventsTimelineStatus.IMPAIRED]: newSeries(EventsTimelineStatus.IMPAIRED, 1),
        [EventsTimelineStatus.DISCONNECTED]: newSeries(EventsTimelineStatus.DISCONNECTED, 0),
        [EventsTimelineStatus.CONNECTED_ROAMING]: newSeries(EventsTimelineStatus.CONNECTED_ROAMING, null),
        [EventsTimelineStatus.IMPAIRED_ROAMING]: newSeries(EventsTimelineStatus.IMPAIRED_ROAMING, null),
        [EventsTimelineStatus.DISCONNECTED_ROAMING]: newSeries(EventsTimelineStatus.DISCONNECTED_ROAMING, null)
      };

      const seriesIndex = eventsTimelineToDisplay
        .filter((row) => Number(row.eventDate.format('x')) < maxDate)
        .reduce((memo: any, row: EventsTimelineData, index: number) => {
          const pt = newPoint(row);
          if (memo[row.status]) {
            if (row.isRoaming) {
              memo[row.status + ':Roaming'].data = [...memo[row.status + ':Roaming'].data, pt];
            } else {
              memo[row.status].data = [...memo[row.status].data, pt];
            }
          }
          return memo;
        }, initialSeries);

      let series = Object.keys(seriesIndex).map((key) => mergePointsInSeries(seriesIndex[key]));
      // Generating the first empty point to include Ground,
      // Asc/Desc, and Cruising label during flight start
      // even if we don't have the data for any of the phase
      if (series && series.length && eventsTimeline && eventsTimeline.length) {
        const firstPoint = {
          x: 2,
          low: emptyDataPointLow,
          high: emptyDataPointHigh
        };
        series[0].data.push(firstPoint);
        const ascendingDescendingPoint = {
          x: 0,
          low: emptyDataPointLow,
          high: emptyDataPointHigh
        };
        const cruisingPoint = {
          x: 1,
          low: emptyDataPointLow,
          high: emptyDataPointHigh
        };
        series[0].data.push(ascendingDescendingPoint);
        series[0].data.push(cruisingPoint);
      }

      // Make the data entries to be ordered by the x value to eliminate all of the "Highcharts error #15" messages
      // Note: .sort() is sorting in-place
      for (const singleSeries of series) {
        singleSeries.data.sort((a: SeriesDataPoint, b: SeriesDataPoint) => a.x - b.x);
      }
      // Tooltips
      const htmlTooltip = true;

      //Hatch Pattern Defintion
      const patternDefinition = {
        patterns: [
          {
            id: 'disconnectedRoamingPattern',
            path: {
              d: 'M0,0 l10,10 M9,11 l2,-2',
              stroke: 'white',
              strokeWidth: 1.5,
              fill: colors[EventsTimelineStatus.DISCONNECTED]
            },
            width: 10,
            height: 10
          },
          {
            id: 'connectedRoamingPattern',
            path: {
              d: 'M0,0 l10,10 M9,11 l2,-2',
              stroke: 'white',
              strokeWidth: 1.5,
              fill: colors[EventsTimelineStatus.CONNECTED]
            },
            width: 10,
            height: 10
          },
          {
            id: 'impairedRoamingPattern',
            path: {
              d: 'M0,0 l10,10 M9,11 l2,-2',
              stroke: 'white',
              strokeWidth: 1.5,
              fill: colors[EventsTimelineStatus.IMPAIRED]
            },
            width: 10,
            height: 10
          }
        ]
      };

      const tooltip = (i18n: any) => (input: any) => {
        const {
          point: {color, low, high},
          series: {name: seriesName}
        } = input;
        return {
          label: {
            value: <ToolTipDateTimeRangeLabel dtLow={low} dtHigh={high} timeDurationTooltip={false} />
          },
          points: [
            {
              value: <EventsTimelineToolTip eventName={seriesName} prefixIconColor={color} dtLow={low} dtHigh={high} />
            }
          ],
          color: false
        };
      };

      const legendTooltips = [
        {
          title: 'flight_details.events_timeline.disconnected.title_tooltip',
          x: -85,
          y: -160,
          arrowX: -85
        },
        {
          title: 'flight_details.events_timeline.impaired.title_tooltip',
          x: 13,
          y: -160,
          arrowX: 13
        },
        {
          title: 'flight_details.events_timeline.connected.title_tooltip',
          x: 108,
          y: -160,
          arrowX: 108
        }
      ];

      // Handle noData Options
      const noDataOptions = {
        enabled: true,
        showEmptyXAxis: false,
        showEmptyYAxis: false
      };

      setChartConfig({
        id,
        chartType,
        marginLeft,
        marginRight,
        categories,
        series,
        tooltip,
        positioner: positioner(scrollContainerRef.current, BAR_WIDTH),
        htmlTooltip,
        defaultChartPalette,
        xAxisTickInterval,
        xAxisLabelPadding,
        yAxisMin,
        yAxisMax,
        yAxisLabel,
        xAxisLabel,
        xAxisFormatter,
        highchartsIdBase,
        legendTooltips,
        noDataOptions,
        hideAllLegendItemsOnLoad,
        legendItemsToHideOnLoad,
        setLiveFlightOffset,
        liveMask,
        barWidth: BAR_WIDTH,
        totalDuration,
        patternDefinition
      });
    }
    // eslint-disable-next-line
  }, [eventsTimeline, chartTimeSettings.start, chartTimeSettings.end, isLoading, maxDate, flightPhaseData]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer>
      {Object.keys(formattedConfig).length === 0 ? <></> : <ColumnRangeChart {...formattedConfig} />}
    </ChartContainer>
  );
};
export default EventsTimeline;
