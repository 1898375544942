/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Map Utility
 */

import axios, {CancelTokenSource} from 'axios';
import {AscendingIcon, DescendingIcon, InFlightIcon, OnGroundIcon} from '@viasat/insights-components';
import {SvgIcon} from '@mui/material';

import {
  ABOVE_TARGET_COLOR,
  IMPAIRED,
  BELOW_LOWER_THRESHOLD_COLOR,
  OFFLINE,
  ORANGE,
  PILOT_SW_COLOR
} from '../components/common/theme/Colors';
import {
  CACHED_SEARCH_LIMIT,
  NETWORK_CAPABLE_FILTER_DUAL,
  NETWORK_CAPABLE_FILTER_KA,
  NETWORK_CAPABLE_FILTER_KU,
  SERVICE_STATUS_FILTER_CONNECTED,
  SERVICE_STATUS_FILTER_DISCONNECTED,
  SERVICE_STATUS_FILTER_IMPAIRED,
  SERVICE_STATUS_FILTER_OFFLINE,
  Z_INDEX,
  ConnectionStatus,
  SwVersionStatus
} from './constants';
import {FilterOptionsType, FilterType} from './filterUtils';
import {FlightPathInfoDataProps} from '../store/queries/fleetMap/flightPathInfoQuery';
import {AircraftStatus} from '../store/queries/fleetMap/aircraftStatusQuery';
import {ConnectivityOutlookStatus} from '../components/connectivityOutlook/ConnectivityOutlookUtils';

export enum MapViewContext {
  MAP_PAGE = 0,
  LIVE_FLIGHT_DETAILS_MAP,
  FLIGHT_DETAILS_MAP,
  FLIGHT_DETAILS_MINI_MAP,
  CONNECTIVITY_OUTLOOK_MAP
}

/**
 * Saves the most recent searched in localStorage
 * @param recentSearch Search String
 */
export const storeRecentSearch = (recentSearch: string) => {
  var recentSearches = getAllStoredRecentSearches();

  recentSearches = recentSearches.filter((r: string) => r !== recentSearch);
  recentSearches.unshift(recentSearch);

  if (recentSearches.length > CACHED_SEARCH_LIMIT) {
    recentSearches.pop();
  }

  localStorage['recentSearches'] = JSON.stringify(recentSearches);
};

/**
 * Clears all recent searches in localStorage
 */
export const clearAllStoredRecentSearches = () => {
  localStorage['recentSearches'] = JSON.stringify([]);
};

/**
 *  Get all stored recent searches
 */
export const getAllStoredRecentSearches = () => {
  return localStorage['recentSearches'] ? JSON.parse(localStorage['recentSearches']) : [];
};

/**
 * Get status availability color based on aircraft status
 * @param statusColor
 * @returns Aircraft status color
 */
export const getAvailabilityColor = (statusColor: any) => {
  const colorStatus =
    statusColor === ConnectionStatus.CONNECTED
      ? ABOVE_TARGET_COLOR
      : statusColor === ConnectionStatus.IMPAIRED
      ? IMPAIRED
      : statusColor === ConnectionStatus.DISCONNECTED
      ? BELOW_LOWER_THRESHOLD_COLOR
      : statusColor === ConnectionStatus.OFFLINE
      ? OFFLINE
      : ORANGE;
  return colorStatus;
};

/**
 * Get status availability color based on aircraft status
 * @param statusColor
 * @returns Aircraft status color
 */
export const getSwVersionStatusColor = (status: any) => {
  const colorStatus =
    status === SwVersionStatus.PRODUCTION
      ? ABOVE_TARGET_COLOR
      : status === SwVersionStatus.PILOT
      ? PILOT_SW_COLOR
      : IMPAIRED;
  return colorStatus;
};

export interface MapControlsProps {
  mapControlContext: number;
  setSearchCriteria: (s: string) => void;
  searchCriteria: string;
  searchResults: AircraftMapData[];
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  mapDivRef: React.MutableRefObject<any>;
  setHoveredAircraft: (tailId: string) => void;
  setPopup: (aircraft: AircraftMapData | undefined) => void;
  zoom: number;
  minZoom: number;
  setZoom: (zoom: number) => void;
  setTailTagOpen: (t: boolean) => void;
  isTailTagOpen: boolean;
  showLargePopup: boolean;
  setShowLargePopup: (t: boolean) => void;
  searchBoxDisabled: boolean;
  disableTailTagConfig: boolean;
  canMinimize?: boolean;
  setMinimize?: () => void;
  boundList?: {lat: number; lng: number}[];
  selectedAircraftData?: AircraftStatus;
  selectedAircraftNetwork?: string;
  disableOverlayOptions?: boolean;
}

export interface AircraftMapData {
  flightId?: string;
  faFlightId?: string;
  aircraftId: string;
  aircraftManufacturer: string;
  isLabTerminal?: boolean;
  aircraftType: string;
  endUser?: string;
  networkCapability: string;
  lastLatitude: number;
  lastLongitude: number;
  tailId: string;
  serialNumber: string;
  connectedEndTimestamp: string;
  connectedStartTimestamp: string;
  lastNetwork: string;
  lastFlightPhase: string;
  flightStartTimestamp: string;
  flightEndTimestamp: string;
  lastHeading: number;
  status: string;
  pingsConnectionStartTimestamp: string;
  pingsConnectionEndTimestamp: string;
  isDark?: boolean;
  origin?: string;
  destination?: string;
  actualDepartureTstamp?: string;
  actualArrivalTstamp?: string;
  estimatedArrivalTstamp?: string;
  remainingTime?: string;
}

export declare type MapAllAircraftProps = IMapAircraftMapper;

export interface FlightPathDataType {
  flightPath: FlightPathPointType[];
  start: MapPointType | null;
  end: MapPointType | null;
}

export interface FlightPathPointType extends MapPointType {
  events: any[];
  timestamp: string;
  availability: string | null;
}

export interface Airport {
  name: string;
  city: string;
  location: string;
  iataCode: string;
  timezone: string;
  latitude: number;
  longitude: number;
  code: string;
}

export type AirportsType = Record<string, Airport> | null;

export interface MapMarkerProps {
  type: string;
  internal?: boolean;
  mapDivRef: React.MutableRefObject<any>;
  location?: MapPointType;
  airport?: Airport;
  context: number;
  pixelPositionOffset: {x: number; y: number};
  flightDetected?: boolean;
}

export interface LabTerminalMapMarkerProps {
  type: string;
  internal?: boolean;
  mapDivRef: React.MutableRefObject<any>;
  location?: MapPointType;
  aircraftData?: AircraftMapData;
  pixelPositionOffset: {x: number; y: number};
}

export interface MapLegendWrapperProps {
  context: MapViewContext;
  viewName: string;
  metricsData: any;
  legendWrapperHeight: string;
  mapDivRef: React.MutableRefObject<any>;
}

export interface MapPointType {
  lat: number;
  lng: number;
}

export interface AircraftStyleAttributes {
  color: string;
  pulse: string;
}
export interface IMapAircraftMapper {
  isFullscreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  zoom: number;
  mapDivRef: React.MutableRefObject<any>;
  parentLeftOffset: number;
  flightPath: FlightPathInfoDataProps;
  isFlightPathLoading: boolean;
  aircraftStatusData: AircraftStatus[];
}

/**
 * Returns color and pulse for aircraft icon
 * @param status aircraft status
 * @returns object with color and pulse
 */
export const getAircraftStyleAttributes = (status: string): AircraftStyleAttributes => {
  if (status === ConnectionStatus.DISCONNECTED) {
    return {color: BELOW_LOWER_THRESHOLD_COLOR, pulse: 'aircraftDisconnectedPulse'};
  } else if (status === ConnectionStatus.IMPAIRED) {
    return {color: IMPAIRED, pulse: 'aircraftImpairedPulse'};
  } else if (status === ConnectionStatus.CONNECTED) {
    return {color: ABOVE_TARGET_COLOR, pulse: 'aircraftConnectedPulse'};
  }
  return {color: OFFLINE, pulse: 'aircraftOfflinePulse'};
};

/**
 * Returns Dynamic zIndex Value
 * @param status aircraft status
 * @returns zIndex values
 */
export const calculateZIndex = (status: any, isDark?: boolean) => {
  const zIndex =
    status === ConnectionStatus.CONNECTED
      ? Z_INDEX.CONNECTED
      : status === ConnectionStatus.IMPAIRED
      ? Z_INDEX.IMPAIRED
      : status === ConnectionStatus.DISCONNECTED
      ? Z_INDEX.DISCONNECTED
      : status === ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION ||
        status === ConnectivityOutlookStatus.SATELLITE_HANDOVER
      ? Z_INDEX.IMPAIRED
      : status === ConnectivityOutlookStatus.OUT_OF_COVERAGE ||
        status === ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS ||
        status === ConnectivityOutlookStatus.NO_SERVICE
      ? Z_INDEX.DISCONNECTED
      : status === ConnectionStatus.OFFLINE && isDark
      ? Z_INDEX.DARK_FLIGHT
      : status === ConnectionStatus.OFFLINE
      ? Z_INDEX.OFFLINE
      : 0;

  return zIndex;
};

/**
 * Converts the given list of values in filter range options
 * @param values list of values to convert
 * @return array of filter range key/value pairs for filter
 */
export const convertFilterValues = (values: string[]): Array<FilterOptionsType> => {
  return values.map((value: string) => ({
    optionKey: value,
    optionValue: value
  }));
};

/**
 * Returns the range options for Service Status filter
 * @param props filter container properties
 * @return array of serviceStatus filter range key/value pairs
 */
export const getServiceStatusFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertFilterValues([
    SERVICE_STATUS_FILTER_CONNECTED,
    SERVICE_STATUS_FILTER_DISCONNECTED,
    SERVICE_STATUS_FILTER_IMPAIRED,
    SERVICE_STATUS_FILTER_OFFLINE
  ]);

/**
 * Returns the range options for Network Capable filter
 * @param props filter container properties
 * @return array of networkCapable filter range key/value pairs
 */
export const getNetworkCapableFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertFilterValues([NETWORK_CAPABLE_FILTER_KA, NETWORK_CAPABLE_FILTER_KU, NETWORK_CAPABLE_FILTER_DUAL]);

/**
 * Generate new cancelToken to abort api call
 */
export const getCancelTokenSource = (): CancelTokenSource => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  return source;
};

/**
 * Fleet map filters
 */
export const FLEET_MAP_FILTERS: Array<FilterType> = [
  {
    title: 'Service Status',
    id: 'status',
    getValues: getServiceStatusFilterRangeOptions
  },
  {
    title: 'Network Capability',
    id: 'networkCapability',
    getValues: getNetworkCapableFilterRangeOptions
  }
];

/**
 * Splits string based on separators and index
 * @param str string
 * @param regex separator
 * @param index index
 * @returns Formatted time with only hours and minutes string in UTC
 */
export const splitString = (str: string, regex: string, index: number) => {
  return str?.split(regex)[index];
};

export const FLIGHT_PHASE_ASCENDING = 'Ascending';
export const FLIGHT_PHASE_CRUISE = 'Cruising';
export const FLIGHT_PHASE_DESCENDING = 'Descending';
export const FLIGHT_PHASE_GROUNDED = 'On-ground';

interface flightPhaseContent {
  text: string;
  idText: string;
  Icon: typeof SvgIcon;
}

export const flightPhaseContentLookup: Record<string, flightPhaseContent> = {
  [FLIGHT_PHASE_GROUNDED]: {
    text: 'On-ground',
    idText: 'Onground',
    Icon: OnGroundIcon
  },
  [FLIGHT_PHASE_ASCENDING]: {
    text: 'Ascending',
    idText: 'Ascending',
    Icon: AscendingIcon
  },
  [FLIGHT_PHASE_CRUISE]: {
    text: 'Cruising',
    idText: 'Cruising',
    Icon: InFlightIcon
  },
  [FLIGHT_PHASE_DESCENDING]: {
    text: 'Descending',
    idText: 'Descending',
    Icon: DescendingIcon
  }
};

export const degreesToRadians = degrees => degrees * (Math.PI / 180);

export const getDistance = (point1: MapPointType, point2: MapPointType): number => {
  const dLat = degreesToRadians(point2.lat - point1.lat);
  const dLng = degreesToRadians(point2.lng - point1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(point1.lat)) *
      Math.cos(degreesToRadians(point2.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  return Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * 2;
};
