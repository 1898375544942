/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Mini Map Component for Flight Details
 */

import React, {useRef} from 'react';
import {OverlayView} from '@react-google-maps/api';
import styled from '@emotion/styled';
import {ExpandMapIcon, MapControlsButton, RightDataToolTip} from '@viasat/insights-components';

import MapAircraft from '../../mapView/MapAircraft';
import MapFlightPath from '../../mapView/MapFlightPath';
import MapMarker from '../../mapView/MapMarker';
import LabTerminalMapMarker from '../../mapView/LabTerminalMapMarker';

import {
  MAP_DETAILS_MINI_ELID_SECTION,
  MAP_ELID_CONTROLS_SUBSECTION,
  SELECTED_AIRCRAFT_Z_INDEX,
  FLIGHT_DETAILS_MINIMAP_MIN_ZOOM
} from '../../../utils/constants';
import {getElementId} from '../../../utils/ElementIdUtils';

import {MapViewContext, AircraftMapData} from '../../../utils/MapUtil';
import {FlightPathInfoDataProps} from '../../../store/queries/flightDetails/flightPathInfoQuery';
import MapStateContainer from '../../mapView/MapStateContainer';

export interface MiniMapProps {
  showAircraft: boolean;
  setMinimize: () => void;
  isLoading: boolean;
  flightPathData: FlightPathInfoDataProps;
  aircraftData: AircraftMapData;
  hoverLineContainerRef: any;
  chartTimeSettings: any;
  liveFlightOffset: {startOffSet: number; unit: number};
  parentLeftOffset: number;
  flightDetected: boolean;
  isLiveFlight: boolean;
  origin: string;
  destination: string;
  airports: any;
}

const MiniMapContainer = styled.div`
  height: 210px;
  width: 352px;
`;

const MiniMap: React.FC<MiniMapProps> = (miniMapProps) => {
  const mapDivRef = useRef(null);

  const flightPathDetails = miniMapProps?.flightPathData;
  const isLabTerminal = miniMapProps.aircraftData?.isLabTerminal;
  const boundList = flightPathDetails
    ? [
        flightPathDetails.start,
        flightPathDetails.end,
        ...flightPathDetails.flightPath.map((p: any) => ({lat: p.lat, lng: p.lng})),
        ...flightPathDetails.futureFlightPath.map((p: any) => ({lat: p.lat, lng: p.lng}))
      ]
    : [];

  const pixelPositionOffset = {
    x: -12,
    y: -24
  };

  const historicalFlightOffset = {
    x: -12,
    y: -32
  };

  const labPositionOffset = {
    x: -15,
    y: -15
  };

  const mapOptions = {
    disableDoubleClickZoom: true,
    gestureHandling: 'none',
    minZoom: FLIGHT_DETAILS_MINIMAP_MIN_ZOOM
  };

  return (
    <MiniMapContainer>
      <MapStateContainer
        getFullElementId={(name, type) => `miniMap__${name}-${type}`}
        isLoading={miniMapProps.isLoading}
        initialBoundList={boundList}
        mapOptions={mapOptions}
        mapDivRef={mapDivRef}
      >
        <>
          <div
            style={{
              top: '16px',
              left: '16px',
              width: `40px`,
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <RightDataToolTip
              arrow={true}
              enterDelay={250}
              title={
                <div
                  style={{
                    lineHeight: '18px',
                    margin: '6px 10px',
                    fontWeight: 600
                  }}
                >
                  Expand Map
                </div>
              }
            >
              <div>
                <MapControlsButton
                  id={getElementId(MAP_DETAILS_MINI_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'minimize', 'button')}
                  Icon={ExpandMapIcon}
                  onClick={() => miniMapProps.setMinimize()}
                />
              </div>
            </RightDataToolTip>
          </div>
          {!isLabTerminal && flightPathDetails?.start && (
            <MapMarker
              context={
                miniMapProps.showAircraft ? MapViewContext.FLIGHT_DETAILS_MINI_MAP : MapViewContext.FLIGHT_DETAILS_MAP
              }
              pixelPositionOffset={miniMapProps.showAircraft ? pixelPositionOffset : historicalFlightOffset}
              key={`${0}-start`}
              type="start"
              internal={false}
              mapDivRef={mapDivRef}
              location={flightPathDetails.start}
              flightDetected={miniMapProps.flightDetected}
              airport={miniMapProps?.airports ? miniMapProps?.airports[miniMapProps?.origin] : undefined}
            />
          )}
          {!isLabTerminal && flightPathDetails?.end && !miniMapProps.showAircraft && (
            <MapMarker
              context={
                miniMapProps.showAircraft ? MapViewContext.FLIGHT_DETAILS_MINI_MAP : MapViewContext.FLIGHT_DETAILS_MAP
              }
              pixelPositionOffset={miniMapProps.showAircraft ? pixelPositionOffset : historicalFlightOffset}
              key={`${0}-end`}
              type="end"
              internal={false}
              mapDivRef={mapDivRef}
              location={flightPathDetails.end}
              flightDetected={miniMapProps.flightDetected}
              airport={miniMapProps?.airports ? miniMapProps?.airports[miniMapProps?.destination] : undefined}
            />
          )}
          {!isLabTerminal && flightPathDetails?.destination && (
            <MapMarker
              context={
                miniMapProps.showAircraft ? MapViewContext.FLIGHT_DETAILS_MINI_MAP : MapViewContext.FLIGHT_DETAILS_MAP
              }
              pixelPositionOffset={miniMapProps.showAircraft ? pixelPositionOffset : historicalFlightOffset}
              key={`${0}-end`}
              type="end"
              internal={false}
              mapDivRef={mapDivRef}
              location={flightPathDetails.destination}
              flightDetected={miniMapProps.flightDetected}
              airport={miniMapProps?.airports ? miniMapProps?.airports[miniMapProps?.destination] : undefined}
            />
          )}
          {isLabTerminal && flightPathDetails?.start && (
            <LabTerminalMapMarker
              pixelPositionOffset={labPositionOffset}
              key={`${0}-start`}
              type="start"
              internal={false}
              mapDivRef={mapDivRef}
              location={flightPathDetails.start}
              aircraftData={miniMapProps.aircraftData}
            />
          )}
          {isLabTerminal && flightPathDetails?.end && !miniMapProps.showAircraft && (
            <LabTerminalMapMarker
              pixelPositionOffset={labPositionOffset}
              key={`${0}-end`}
              type="end"
              internal={false}
              mapDivRef={mapDivRef}
              location={flightPathDetails.end}
              aircraftData={miniMapProps.aircraftData}
            />
          )}
          {flightPathDetails?.start && (
            <MapFlightPath
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              parentLeftOffset={miniMapProps.parentLeftOffset}
              flightPathData={flightPathDetails}
              isFlightPathLoading={miniMapProps.isLoading}
              mapViewContext={MapViewContext.FLIGHT_DETAILS_MINI_MAP}
              disablePopup={false}
              hoverLineContainerRef={miniMapProps.hoverLineContainerRef}
              chartTimeSettings={miniMapProps.chartTimeSettings}
              liveFlightOffset={miniMapProps.liveFlightOffset}
              isMinimized={true}
              isLiveFlight={miniMapProps.isLiveFlight}
            />
          )}
          {miniMapProps.showAircraft && (
            <MapAircraft
              aircraft={miniMapProps.aircraftData}
              mapDivRef={mapDivRef}
              key="flightDetailsAircraft"
              hoveredAircraft={null}
              setHoveredAircraft={() => void 0}
              setPopup={() => void 0}
              isTailTagOpen={false}
              showLargePopup={false}
              onClick={() => {}}
              zIndexOverride={SELECTED_AIRCRAFT_Z_INDEX}
            />
          )}
        </>
      </MapStateContainer>
    </MiniMapContainer>
  );
};
export default MiniMap;
