/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List - Disconnected Events Query
 */

import moment from 'moment';
import {Query} from '../types';
import {NO_DATA_INDICATOR} from '../../../utils/constants';
import {DATE_TIME_VERBOSE_FORMAT} from '../../../utils/DateTimeUtils';

export interface IDisconnectedEvents {
  aircraftId: string;
  flightId: string;
  flightStartTimestamp: string;
  disconnectedCount: number;
  durationSec: number;
}

const transformRawTailDisconnectedEventsInfo = (
  disconnectedEvents: IDisconnectedEvents[] | null
): IDisconnectedEvents[] | null => {
  if (!disconnectedEvents) return null;

  const customerHistoryInfo: IDisconnectedEvents[] = disconnectedEvents.map((historyInfo) => ({
    aircraftId: historyInfo.aircraftId,
    flightId: historyInfo.flightId,
    disconnectedCount: historyInfo.disconnectedCount,
    durationSec: historyInfo.durationSec,
    flightStartTimestamp: historyInfo.flightStartTimestamp
      ? moment.utc(new Date(historyInfo.flightStartTimestamp)).format(DATE_TIME_VERBOSE_FORMAT)
      : NO_DATA_INDICATOR
  }));

  return customerHistoryInfo;
};

const disconnectedEventsQuery: Query<IDisconnectedEvents[]> = {
  route: 'tailList/disconnectedEvents',
  transform: transformRawTailDisconnectedEventsInfo
};

export default disconnectedEventsQuery;
