/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Data Usage Times Card on the Flight Details Page
 */

import React from 'react';
import {last} from 'lodash';
import {useIntl} from 'react-intl';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import {ChartCard} from '@viasat/insights-components';

import DataUsage, {FlightDataUsageTimeline} from '../charts/DataUsage';
import {ChartTimeSettings, formatDataUsageValue} from '../flightDetailsUtil';
import {useStore} from '../../../store/Store';
import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';

interface DataUsageCardProps {
  currentTime: string;
  chartData: FlightDataUsageTimeline[];
  isLoading: boolean;
  chartTimeSettings?: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
  draggableProps?: any;
}

const DataUsageCard = ({
  currentTime,
  chartData,
  isLoading,
  chartTimeSettings,
  parentLeftOffset,
  liveMask,
  draggableProps
}: DataUsageCardProps) => {
  const intl = useIntl();
  const totalUsageData = !isLoading && chartData?.length ? last(chartData).cumulative_usage : 0;

  // hide button click handler
  const {dispatch} = useStore();
  const onHideClick = (id) =>
    dispatch({
      type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY,
      payload: {
        hide: true,
        id
      }
    });

  return (
    <div id="DataUsageCardEvents">
      <ChartCard
        draggableProps={draggableProps}
        onHideClick={onHideClick}
        getFullElementId={(name, type) => `flightDetails--dataUsage__${name}-${type}`}
        Icon={DataUsageIcon}
        title="Cumulative Data Usage"
        disableHelpIcon={false}
        helpTooltipMessage={intl.formatMessage({id: 'flight_details.date_usage.title_tooltip'})}
        summaries={[
          {
            getFullElementId: (name, type) => `flightDetails--dataUsage__${name}-${type}`,
            key: 'data-usage-timeline-total-data-usage',
            name: 'TOTAL DATA USAGE',
            value: totalUsageData < 1 && totalUsageData > 0 ? '< 1 MB' : formatDataUsageValue(totalUsageData, false)
          }
        ]}
        isLoading={isLoading}
        isError={false}
      >
        <DataUsage
          currentTime={currentTime}
          isLoading={isLoading}
          flightDataUsageTimeline={chartData}
          chartTimeSettings={chartTimeSettings}
          parentLeftOffset={parentLeftOffset}
          liveMask={liveMask}
        />
      </ChartCard>
    </div>
  );
};

export default DataUsageCard;
