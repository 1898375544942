/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Link-related utility functions.
 */

import {isEmpty, reduce} from 'lodash';
import {stringify} from 'qs';
import {CONNECTIVITY_PLANNER_STORE_CONTEXT} from '../store/reducers/ConnectivityPlannerReducer';
import {SUMMARY_METRICS_STORE_CONTEXT} from '../store/reducers/SummaryMetricsReducer';
import {TAIL_LIST_STORE_CONTEXT} from '../store/reducers/TailListReducer';

/**
 * Gets the current URL path for the app
 * @returns the path
 */
export const getCurrentUrlPath = () => {
  // Gen location (allow for port on localhost/tunnel)
  const href = sessionStorage.iframeLocation;
  const cleanedHref = href && href[href.length - 1] === '/' ? href.slice(0, -1) : href;

  // Path always needs to be for the current window, not the parent
  return `${cleanedHref}${window.location.pathname}`;
};

/**
 * Appends filters as a search string to the given URL path
 * @param urlPath the URL path to build on
 * @param urlFilters filters to be appended
 * @param store the store (used for retrieving current settings like dates and airline)
 * @param addCustomerFilters whether filters for the customer should be added
 * @param addDateFilters whether filters for the start and end dates should be added
 * @returns the full URL
 */
export const addFiltersToUrl = (
  urlPath: string,
  urlFilters: any,
  store: any,
  addCustomerFilters: boolean = true,
  addDateFilters: boolean = true,
  viewContext: string = undefined
): string => {
  const {
    init: {viewAsCustomer},
    datePicker: {startDate, endDate},
    customer: {
      current: {code: customerCode}
    },
    connectivityPlanner: {
      dateRange: {startDate: connectivityPlannerStartDate, endDate: connectivityPlannerEndDate}
    },
    summaryMetrics: {
      dateRange: {startDate: summaryMetricsStartDate, endDate: summaryMetricsEndDate}
    },
    tailList: {
      dateRange: {startDate: tailListStartDate, endDate: tailListEndDate}
    }
  } = store;

  // Connectivity Planner Outlook/Summary Metrics/Tail List has a separate dateRange in store.
  // Based on viewContext this specific date range should be used for Connectivity Planner Outlook/Summary Metrics/Tail List view(s).
  const filters = {
    ...(urlFilters ? urlFilters : {}),
    ...(addCustomerFilters ? {customerCode} : {}),
    ...(addDateFilters
      ? viewContext && viewContext === CONNECTIVITY_PLANNER_STORE_CONTEXT
        ? {connectivityPlannerStartDate, connectivityPlannerEndDate}
        : viewContext && viewContext === SUMMARY_METRICS_STORE_CONTEXT
        ? {summaryMetricsStartDate, summaryMetricsEndDate}
        : viewContext && viewContext === TAIL_LIST_STORE_CONTEXT
        ? {tailListStartDate, tailListEndDate}
        : {startDate, endDate}
      : {}),
    viewAsCustomer: encodeURIComponent(viewAsCustomer)
  };

  // Convert the filters to an object, removing any empty ones
  const usedFilters = reduce(
    {
      ...filters
    },
    (acc, value, key) => {
      if (isEmpty(value)) {
        return acc;
      }
      return {...acc, [key]: value};
    },
    {}
  );

  return isEmpty(usedFilters) ? urlPath : `${urlPath}?${stringify(usedFilters)}`;
};
