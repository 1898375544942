/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: chart component styles
 */

import styled from '@emotion/styled';
import {
  DROP_ZONE_BACKGROUND_COLOR,
  EVENTS_TIMELINE_DASHED_GRID_LINE_COLOR,
  EXPANSIONPANEL_TEXT,
  FILTER_BORDER_COLOR,
  FLIGHT_EVENT_COLOR,
  MAP_POPUP_DEFAULT_BORDER,
  POPUP_TEXT_COLOR,
  SW_REVISIONS_MODAL_TITLE_COLOR,
  VIASAT_LOGO_ICON_COLOR,
  AIRCRAFT_LIST_BORDER_COLOR
} from '../common/theme/Colors';
import {BROWSER_LIST} from '../../utils/browserUtils';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardColumn = styled.div<{hasBorder?: boolean}>`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  border-right: ${(props) => (props.hasBorder ? '1px solid #c3cdd5' : 'none')};
  height: 305px;
  margin-top: -10px;
`;

export const CardRowContainter = styled.div`
  margin: 40px;
`;

export const CardRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  @media only screen and (max-width: 1280px) {
    margin-top: 12px;
  }
`;

export const CardRowTitle = styled.div`
  width: 70%;
  height: 24px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: start;
  color: ${EVENTS_TIMELINE_DASHED_GRID_LINE_COLOR};
  align-self: stretch;
  @media only screen and (max-width: 1280px) {
    width: calc(100% - 10px);
  }
`;

export const CardRowValue = styled.div`
  width: 30%;
  height: 24px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${POPUP_TEXT_COLOR};
  @media only screen and (max-width: 1280px) {
    width: calc(100% - 50px);
  }
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${MAP_POPUP_DEFAULT_BORDER};
`;

export const ChartContainer = styled.div`
  height: auto;
  width: auto;
`;

export const ProgressBarRow = styled.div<{marginTop?: string}>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')}px;
`;

export const ProgressBarContainer = styled.span`
  .start {
    color: ${EXPANSIONPANEL_TEXT};
    float: left;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
  }
  .end {
    color: ${EXPANSIONPANEL_TEXT};
    float: right;
    text-align: right;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
  }
`;

export const NoDataAvailable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

export const DataUsageTrendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px;
`;

export const ChartLegend = styled.div`
  width: auto;
  height: 16px;
  text-align: center;
  margin-top: 13px;
  margin-bottom: 12px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${FLIGHT_EVENT_COLOR};
`;

export const TopSectionSummaryMetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const CardDataWithAverage = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardDataDivider = styled.div<{width?: string}>`
  height: 4px;
  width: ${(props) => (props.width ? props.width : '100')}%;
  border-bottom: 1px solid ${FILTER_BORDER_COLOR};
  display: flex;
  margin: 6px 0;
`;

export const DataSpace = styled.div`
  display: flex;
  width: 6px;
  height: auto;
`;

export const CardTitle = styled.div<{fontSize?: number; width?: number}>`
  font-size: 18px;
  font-weight: 600;
  color: ${SW_REVISIONS_MODAL_TITLE_COLOR};
  display: flex;
  min-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
`;

export const DataLabel = styled.div`
  font-size: 16px;
  color: ${SW_REVISIONS_MODAL_TITLE_COLOR};
  display: flex;
  width: calc(100% - 10px);
  margin-top: 4px;
`;

export const DataValue = styled.div<{fontSize?: number; width?: number}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : 32)}px;
  font-weight: 600;
  display: flex;
  width: 60%;
  justify-content: flex-end;
`;

export const Spinner = styled.div`
  display: inline-block;
  margin-top: 3px;
  margin-left: 3px;
`;
export const LoadingIndicator = styled.div`
  margin-top: -10px;
`;

export const MiddleSectionSummeryMetrics = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
`;

export const MiddleSectionSummeryMetricsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 16px;
  @media only screen and (max-width: 960px) {
    flex-wrap: wrap;
  }
  .chart-card {
    width: calc(50% - 24px);
    @media only screen and (max-width: 960px) {
      width: calc(100% - 32px);
    }
  }
  .chart-card: first-child {
    @media only screen and (max-width: 960px) {
      margin-bottom: 16px;
    }
  }
`;

export const ChartWrapper = styled.div<{borderColor: string}>`
  height: 364px;
  background: ${DROP_ZONE_BACKGROUND_COLOR};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 4px;
`;

export const ChartHeader = styled.div`
  width: 100%;
  height: 54px;
  box-sizing: border-box;
`;

export const ChartTitle = styled.div`
  width: auto;
  height: 24px;
  margin: 16px;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${POPUP_TEXT_COLOR};
  display: flex;
`;

export const ChartHeaderActions = styled.div`
  width: auto;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  margin-left: auto;
  margin-right: 8px;
  color: black;
  .selected {
    color: white;
    background: #444444;
    padding: 0px;
    margin: 0px;
  }
  .deSelected {
    padding: 0px;
    margin: 0px;
  }
`;

export const SummaryMetricsBody = styled.div<{browserType: string}>`
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 16px);
  overflow-y: scroll;
  @media print {
    ${(props) =>
      BROWSER_LIST.indexOf(props.browserType) > -1
        ? `
    transform: translateX(5%) translateY(-10%) scale(0.8) rotate(-90deg);
    width: 1900px;
    height: 1990px;
    max-height: 1990px;
    right: 50px;
    margin-top: 5px;`
        : `
    display: block;
    transform: translateX(-30%) translateY(45%) scale(0.8) rotate(-90deg);
    width: 1900px;
    left: 600px;
    position: absolute;
    top: -750px;  
      `}

    overflow-y: visible;
    -webkit-print-color-adjust: exact !important;
    @page {
      size: ${(props) => (BROWSER_LIST.indexOf(props.browserType) > -1 ? 'A3' : 'A4')};
      page-break-before: always;
    }
  }
`;

export const PrintHeader = styled.div`
  display: none;
  font-weight: 600;
  font-size: 26px;
  font-family: 'Source Sans Pro';
  color: ${POPUP_TEXT_COLOR};
  .duration {
    font-weight: 400;
    left: 90%;
    position: absolute;
  }
  @media print {
    display: table-row;
    position: absolute;
    top: -160px;
  }
`;
export const PrintFooter = styled.div`
  display: none;
  top: 950px;
  right: 80px;
  font-weight: 600;
  color: ${EXPANSIONPANEL_TEXT};
  position: absolute;
  direction: rtl;
  line-height: 2;
  @media print {
    display: grid;
  }
`;

export const DragContainer = styled.div`
  padding: 4px 0 0 8px;
  width: 24px;
  height: 24px;
`;

export const SummaryMetricsPrintHeader = styled.div<{browserType: string}>`
  display: none;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  color: ${POPUP_TEXT_COLOR};
  @media print {
    display: flex;
    position: absolute;
    margin-bottom: 50px;
    margin-left: 35px;
    margin-top: 90px;
    display: table-row;
    .title {
      display: flex;
      width: 995px;
      font-size: 22px;
      margin-bottom: -23px;
    }
    .count {
      font-size: 22px;
      font-weight: 700;
      margin-left: 10px;
      margin-right: 14px;
    }
    .headerLabel {
      font-family: 'Source Sans Pro';
      color: ${VIASAT_LOGO_ICON_COLOR};
      margin-top: 10px;
      position: absolute;
      font-size: 16px;
      font-weight: 600;
      width: 1120px;
      display: flex;
    }
    .bottomLineMulti {
      width: 1870px;
      color: ${VIASAT_LOGO_ICON_COLOR};
      border-bottom: 1px solid ${AIRCRAFT_LIST_BORDER_COLOR};
      position: absolute;
      top: ${(props) => (BROWSER_LIST.indexOf(props.browserType) > -1 ? '190px' : '170px')};
    }
  }
`;

export const SummaryMetricAircraftInfoListContainer = styled.div<{browserType: string}>`
  margin-top: ${(props) => (BROWSER_LIST.indexOf(props.browserType) > -1 ? '-550px' : '1900px')};
`;

export const LineBreak = styled.br``;

export const PrintHeaderTitle = styled.div`
  display: flex;
  margin-top: -150px;
  width: 995px;
`;
export const PrintHeaderTitleCount = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 14px;
`;

export const PrintHeaderLabel = styled.div`
  font-family: 'Source Sans Pro';
  color: ${VIASAT_LOGO_ICON_COLOR};
  margin-top: 10px;
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  width: 1120px;
  display: flex;
`;

export const PrintHeaderMultiBottomBorder = styled.div`
  width: 1870px;
  color: ${VIASAT_LOGO_ICON_COLOR};
  border-bottom: 1px solid ${AIRCRAFT_LIST_BORDER_COLOR};
  position: absolute;
  top: 25px;
`;

export const PrintHeaderSingleBottomBorder = styled.div`
  width: 1870px;
  color: ${VIASAT_LOGO_ICON_COLOR};
  border-bottom: 1px solid ${AIRCRAFT_LIST_BORDER_COLOR};
  position: absolute;
  top: 150px;
`;

export const LabelText = styled.div`
  width: 14%;
`;

export const ValueText = styled.div``;
export const AircraftInfoStackContainer = styled.div``;
export const AircraftDetailsContainer = styled.div<{browserType: string}>`
  display: none;
  flex-direction: row;
  page-break-always: always;
  margin-bottom: ${(props) => (BROWSER_LIST.indexOf(props.browserType) > -1 ? '-80px' : '50px')};
  .tableCell {
    border-bottom: none;
    color: #202e39;
    font-family: inherit;
  }

  @media print {
    display: flex;
    padding-top: 290px;
  }
`;
export const AircraftInfoPrintHeaderTitle = styled.div``;
export const AircraftInfoPrintHeaderTitleCount = styled.div``;
export const AircraftInfoPrintHeaderBottomBorder = styled.div``;
export const AircraftInfoPrintHeaderLabel = styled.div``;

export const PrintFilterContainer = styled.div`
  max-height: fit-content;
`;
