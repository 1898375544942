/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Icon depicting an aircraft that is a lab terminal
 */

import {createSvgIcon} from '@mui/material/utils';

const LabTerminalIcon = createSvgIcon(
  <svg viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.53149 10.4666C3.21558 12.1507 5.86391 12.2749 7.69499 10.8469L1.15116 4.3031C-0.276839 6.13419 -0.152589 8.78252 1.53149 10.4666ZM6.58141 6.29169C6.58141 5.80869 6.18941 5.41669 5.70641 5.41669C5.22283 5.41669 4.83141 5.80869 4.83141 6.29169C4.83141 6.77527 5.22283 7.16669 5.70641 7.16669C6.18941 7.16669 6.58141 6.77527 6.58141 6.29169ZM4.83141 2.50002V3.66669C6.76108 3.66669 8.33141 5.23702 8.33141 7.16669H9.49808C9.49808 4.58952 7.40858 2.50002 4.83141 2.50002ZM11.8314 7.16669H10.6647C10.6647 3.95019 8.04791 1.33335 4.83141 1.33335V0.166687C8.69716 0.166687 11.8314 3.30094 11.8314 7.16669Z" />
  </svg>,
  'OnGroundIcon'
);

export default LabTerminalIcon;
