/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Material Ui Expansion Panel for Flight Details
 */

import React, {useState} from 'react';
import {SvgIcon} from '@mui/material';
import {Accordion, AccordionDetails, AccordionSummary, ImageList, ImageListItem} from '@mui/material';
import styled from '@emotion/styled';
import {isNil} from 'lodash';
import {AirportCodeTooltip, DataToolTip, HeaderContainer} from '@viasat/insights-components';

import {
  EXPANSIONPANEL_TEXT,
  EXPANSIONPANEL_VALUE_TEXT,
  LEFT_PANEL_FLIGHT_PHASE_ICON_COLOR,
  HELP_ICON_COLOR
} from '../common/theme/Colors';
import LinearProgressBar from '../common/LinearProgressBar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpToolTip from '../common/elements/tooltip/HelpTooltip';
import AvailabilityDot from '../common/AvailabilityDot';
import {getAvailabilityColor, flightPhaseContentLookup, getSwVersionStatusColor} from '../../utils/MapUtil';
import {
  DATA_PACKAGE_PROGRESS_BAR_NAME,
  HOURLY_PLAN_PROGRESS_BAR_NAME,
  FlightPhase,
  SwVersionStatus,
  ORIGIN_DESTINATION_LABELS
} from '../../utils/constants';
import DiamondIcon from '../common/DiamondIcon';

const CELL_HEIGHT = 24;
const REGULAR_GRID_COLUMNS_PER_ROW = 2;
const TABLE_GRID_CELL_HEIGHT = 18;
const TABLE_GRID_COLUMNS_PER_ROW = 3;
const SIDEBAR_VALUE_MAX_LENGTH = 18;
const SIDEBAR_VALUE_SIDE_LENGTH = 7;
const PHASE_OF_FLIGHT_FIELD_NAME = 'Phase of Flight';

const HelpIconContainer = styled.div`
  position: relative;
  top: -2.5px;
  right: 7.5px;
`;

const SectionHeader = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  color: #125986;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
`;

const LineContainer = styled.div`
  flex-grow: 1;
  padding-left: 10px;
  hr {
    border: none;
    border-top: 1px solid #125986;
    opacity: 0.2;
  }
`;

const ExpansionItemName = styled.div`
  height: 15px;
  opacity: 0.6;
  color: ${EXPANSIONPANEL_TEXT};
  font-size: 16px;
  white-space: nowrap;
  display: inline-block;
`;

const ExpansionItemValue = styled.div<{color?: string}>`
  color: ${(props) => (props.color ? props.color : EXPANSIONPANEL_VALUE_TEXT)};
  font-size: 16px;
  max-width: max-content;
  display: inline-block;
  float: right;
`;

export const ElementsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const AccordionContainer = styled.div<{expanded: boolean}>`
  border: 1px solid #dadada;
  border-radius: 4px;
  width: 100%;

  .MuiAccordionSummary-root:hover:not(.Mui-disabled) {
    cursor: auto;
  }

  .MuiPaper-root {
    border-radius: 4px;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionDetails-root {
    flex-direction: column;
    ${(props) => (props.expanded ? 'padding-bottom: 16px;' : '')}
    padding-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .MuiAccordionSummary-root {
    padding: 0 16px 0 14px;
  }

  .MuiImageList-root {
    padding-bottom: 32px;
  }
  .top .MuiAccordionDetails-root {
    padding-bottom: 0;
  }
`;

const ProgressBarContainer = styled.span`
  .start {
    color: ${EXPANSIONPANEL_TEXT};
    float: left;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
  }
  .end {
    color: ${EXPANSIONPANEL_TEXT};
    float: right;
    text-align: right;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
  }
`;

const DisableSelect = styled.span`
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const HiddenSelect = styled.span`
  opacity: 0;
  white-space: pre;
  width: 0px;
  display: inline-block;
`;

const ExpandMoreLabel = styled.div`
  text-transform: uppercase;
  transform: rotate(180deg);
  font-size: 12px;
  position: absolute;
  left: 40px;
  top: 6px;
  font-weight: 600;
`;

const ExpandMoreContainer = styled.div<{expanded: boolean}>`
  display: none;
  position: relative;
  height: 24px;
  color: #125986;
  svg {
    ${(props) => (props.expanded ? '' : 'transform: rotate(180deg);')}
  }
`;

const GridTableTileStyle = styled(ImageListItem)`
  margin-bottom: 8px;
  padding-bottom: 0px !important;
`;

const FlightTailInfoContainer = styled.div`
  margin-left: 32px;
  margin-top: -8px;
`;

const FlightPhaseIcon = styled.span<{rightPosition: number}>`
  width: 13px;
  height: 7px;
  position: absolute;
  right: ${(props) => props.rightPosition}px;
  bottom: 20px;
`;

export enum AccordionBodyItemType {
  Grid = 'GRID',
  Table = 'TABLE',
  Element = 'ELEMENT'
}

type AccordionBodyItemValue = string | JSX.Element;

interface progressBarProps {
  start?: string;
  end?: string;
  currentProgress?: number;
  barColor?: string;
}
export interface AccordionBodyItem {
  name: string;
  value: AccordionBodyItemValue;
  progressBar?: progressBarProps;
  textColor?: string;
  titleTooltipMessage?: string;
  ellipsisPos?: string;
  tooltipMsg?: string;
  swStatus?: string;
  airport?: any;
}

interface AccordionBodyTableItems {
  columns: AccordionBodyItemValue[];
  rows: AccordionBodyTableRow[];
  columnWidth?: number[];
}

interface AccordionBodyTableRow {
  columns: AccordionBodyItemValue[];
  hover?: (boolean) => void;
}

export interface AccordionSection {
  header?: string;
  type: AccordionBodyItemType;
  items: AccordionBodyItem[] | AccordionBodyTableItems | JSX.Element[];
  columnsPerRow?: number;
  data?: any;
  id?: string | '';
}

interface FlightDetailsExpansionProps {
  panelId: string;
  title: string;
  Icon: typeof SvgIcon;
  sections: AccordionSection[];
  expanded: boolean;
  onChange: () => void;
  tooltipMessage?: string;
}

/**
 * Formats the text sidebar value
 * @param value Formats the text value on the side bar
 * @param idPrefix ID prefix
 * @param ellipsisPos Position of the ellipsis string
 * @param tooltipMessage Optional tooltip message for large texts
 */
const formatItemValue = (
  value: AccordionBodyItemValue,
  idPrefix: string,
  ellipsisPos: string,
  tooltipMessage?: string,
  isAirport?: boolean,
  airportData?: any
) => {
  if (typeof value === 'string' && value.length > SIDEBAR_VALUE_MAX_LENGTH) {
    return (
      <DataToolTip
        id={`${idPrefix}-tooltip-style`}
        arrow
        placement="top"
        enterDelay={1000}
        marginBottom={'-8px'}
        title={<span style={{padding: '2px 10px', lineHeight: 2, fontSize: 14, fontWeight: 600}}>{value}</span>}
      >
        <span style={{whiteSpace: 'nowrap'}}>
          {ellipsisPos === 'front' ? (
            <>
              <DisableSelect>...</DisableSelect>
              <HiddenSelect>{value.substring(0, value.length - 2 * SIDEBAR_VALUE_SIDE_LENGTH)}</HiddenSelect>
              {value.substring(value.length - 2 * SIDEBAR_VALUE_SIDE_LENGTH)}
            </>
          ) : null}
          {ellipsisPos === 'middle' ? (
            <>
              {value.substring(0, SIDEBAR_VALUE_SIDE_LENGTH)}
              <DisableSelect>...</DisableSelect>
              <HiddenSelect>
                {value.substring(SIDEBAR_VALUE_SIDE_LENGTH, value.length - SIDEBAR_VALUE_SIDE_LENGTH)}
              </HiddenSelect>
              {value.substring(value.length - SIDEBAR_VALUE_SIDE_LENGTH)}
            </>
          ) : null}
        </span>
      </DataToolTip>
    );
  } else if (tooltipMessage) {
    return (
      <DataToolTip
        id={`${idPrefix}-tooltip-style`}
        arrow
        placement="top"
        enterDelay={1000}
        marginBottom={'-8px'}
        title={
          <span style={{padding: '2px 10px', lineHeight: 2, fontSize: 14, fontWeight: 600}}>{tooltipMessage}</span>
        }
      >
        <span style={{whiteSpace: 'nowrap'}}>{value}</span>
      </DataToolTip>
    );
  } else if (isAirport && !isNil(airportData)) {
    return (
      <AirportCodeTooltip
        airportCode={value.toString()}
        airport={airportData}
        getFullElementId={(name: string, type: string) => `${value}--airportTooltip__${name}-${type}`}
        position={'top'}
        yMargin={'-10px'}
        arrow={true}
        invertColors={false}
        disable={isNil(value)}
      >
        {value}
      </AirportCodeTooltip>
    );
  } else {
    return <>{isNil(value) ? '--' : value}</>;
  }
};

/**
 * Renders the table grid side bar
 * @param section Section object
 * @param panelID Panel ID
 * @param idx Current Index
 */
const renderTableGrid = (section: AccordionSection, panelId: string, idx: string) => {
  const items = section.items as AccordionBodyTableItems;

  const getStyle = (colidx: number) => ({
    width: items.columnWidth ? `${items.columnWidth[colidx]}px` : `${100 / items.columns.length}%`
  });

  return (
    <div style={{height: (items.rows.length + 1) * 30, width: 310}}>
      <ImageList rowHeight={TABLE_GRID_CELL_HEIGHT} cols={TABLE_GRID_COLUMNS_PER_ROW}>
        {items.columns.map((gridTitle: string, colidx: number) => (
          <ImageListItem
            key={`flight-details-grid-list-title-${panelId}-${idx}-${colidx}`}
            className={`col-${colidx}`}
            sx={getStyle(colidx)}
          >
            <ExpansionItemName id={`flightDetails--${panelId}__expansionPanel-index${idx}-col${colidx}-itemName-label`}>
              {gridTitle}
            </ExpansionItemName>
          </ImageListItem>
        ))}
        {items.rows.map((row: AccordionBodyTableRow, rowidx: number) =>
          row.columns.map((item: AccordionBodyItemValue, colidx: number) => (
            <GridTableTileStyle
              key={`flight-details-grid-list-${panelId}-${idx}-${rowidx}-col${colidx}-content`}
              className={`row-${rowidx} col-${colidx}`}
              sx={getStyle(colidx)}
              onMouseEnter={() => (row.hover ? row.hover(true) : null)}
              onMouseLeave={() => (row.hover ? row.hover(false) : null)}
            >
              <ExpansionItemValue
                id={`flightDetails--${panelId}__expansionPanel-index${idx}-${rowidx}-col${colidx}-label`}
              >
                {formatItemValue(
                  item,
                  `flightDetails--${panelId}__expansionPanel-index${idx}-${rowidx}-col${colidx}`,
                  'middle'
                )}
              </ExpansionItemValue>
            </GridTableTileStyle>
          ))
        )}
      </ImageList>
    </div>
  );
};

/**
 * Renders the regular side bar grid
 * @param section Section object
 * @param panelId Panel ID
 * @param idx Current Index
 * @param showHelpIcon Boolean indicating whether or not to show the icon
 */
const renderRegularGrid = (section: AccordionSection, panelId: string, idx: string, showHelpIcon?: boolean) => {
  const items = section.items as AccordionBodyItem[];
  return (
    <ImageList
      rowHeight={CELL_HEIGHT}
      cols={section.columnsPerRow ? section.columnsPerRow : REGULAR_GRID_COLUMNS_PER_ROW}
      sx={{marginTop: 0, marginBottom: 'unset', paddingBottom: 4.5, overflowX: 'hidden'}}
    >
      {items.map((item: AccordionBodyItem, idx2: number) => {
        const flightPhaseContent =
          item.name === PHASE_OF_FLIGHT_FIELD_NAME ? flightPhaseContentLookup[item.value?.toString()] : null;
        return (
          <ImageListItem
            key={`expansion-${idx}-${idx2}`}
            sx={{display: 'block', position: 'relative', lineHeight: 'unset'}}
          >
            {item.name === HOURLY_PLAN_PROGRESS_BAR_NAME || item.name === DATA_PACKAGE_PROGRESS_BAR_NAME ? (
              <ProgressBarContainer>
                <LinearProgressBar value={item.progressBar.currentProgress} color={item.progressBar.barColor} />
                <div className="start">{item.progressBar.start}</div>
                <div className="end">{item.progressBar.end}</div>
              </ProgressBarContainer>
            ) : null}
            <ExpansionItemName id={`flightDetails--${panelId}__expansionPanel-index${idx}-${idx2}-itemName-label`}>
              {item.name !== HOURLY_PLAN_PROGRESS_BAR_NAME && item.name !== DATA_PACKAGE_PROGRESS_BAR_NAME
                ? item.name
                : null}
              {item.titleTooltipMessage && showHelpIcon ? (
                <HelpIconContainer>
                  <HelpToolTip message={item.titleTooltipMessage} iconFill={HELP_ICON_COLOR} />
                </HelpIconContainer>
              ) : null}
            </ExpansionItemName>
            <ExpansionItemValue
              className={item.name === PHASE_OF_FLIGHT_FIELD_NAME ? 'flightPhase' : ''}
              id={`flightDetails--${panelId}__expansionPanel-index${idx}-${idx2}-itemValue-label`}
              color={item.textColor ? item.textColor : null}
            >
              {item.name === 'Connectivity' ? (
                <>
                  <AvailabilityDot
                    id={'cabinConnectivity'}
                    sx={{color: getAvailabilityColor(item.value), width: 17, height: 16}}
                  />
                </>
              ) : null}

              {item.name.includes('Terminal SW') ? (
                <>
                  {item.value !== '--' &&
                    (item.swStatus === SwVersionStatus.PILOT ? (
                      <DataToolTip
                        id={`pilot-sw-version-tooltip-style`}
                        arrow
                        placement="top"
                        enterDelay={1000}
                        marginBottom={'-8px'}
                        title={
                          <span style={{padding: '2px 10px', lineHeight: 2, fontSize: 14, fontWeight: 600}}>
                            Running with Pilot version
                          </span>
                        }
                      >
                        <DiamondIcon
                          id={`swVersionStatus-${item.value}-${item.swStatus}`}
                          sx={{position: 'absolute', marginLeft: '-20px', marginTop: '2px'}}
                        />
                      </DataToolTip>
                    ) : (
                      <DataToolTip
                        id={`pilot-sw-version-tooltip-style`}
                        arrow
                        placement="top"
                        enterDelay={1000}
                        marginBottom={'-8px'}
                        title={
                          <span style={{padding: '2px 10px', lineHeight: 2, fontSize: 14, fontWeight: 600}}>
                            {item && item.value !== '--' && item.swStatus === SwVersionStatus.PRODUCTION
                              ? 'Current'
                              : 'Update available'}
                          </span>
                        }
                      >
                        <AvailabilityDot
                          id={`swVersionStatus-${item.value}-${item.swStatus}`}
                          sx={{color: getSwVersionStatusColor(item.swStatus), width: 17, height: 16}}
                        />
                      </DataToolTip>
                    ))}
                </>
              ) : null}
              {!isNil(item.value)
                ? formatItemValue(
                    item.value,
                    `flightDetails--${panelId}__expansionPanel-index${idx}-${idx2}`,
                    item.ellipsisPos ? item.ellipsisPos : 'middle',
                    item.tooltipMsg,
                    ORIGIN_DESTINATION_LABELS.includes(item.name),
                    item.airport
                  )
                : '--'}
              {item.name === PHASE_OF_FLIGHT_FIELD_NAME ? (
                <FlightPhaseIcon rightPosition={flightPhaseContent?.text === FlightPhase.CRUISING ? 72 : 92}>
                  {flightPhaseContent ? (
                    <flightPhaseContent.Icon
                      id={`flightDetails--flightPhaseCruising-icon`}
                      sx={{color: LEFT_PANEL_FLIGHT_PHASE_ICON_COLOR}}
                    />
                  ) : null}
                </FlightPhaseIcon>
              ) : null}
            </ExpansionItemValue>
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

const renderElements = (section: AccordionSection, panelId: string, idx: string) => {
  const items = section.items as JSX.Element[];
  return (
    <ElementsContainer>
      {items.map((item: JSX.Element, idx2: number) => {
        return item;
      })}
    </ElementsContainer>
  );
};

/**
 * Builds the section grid
 * @param section Section Data
 * @param panelId Panel ID
 * @param idx Panel Index
 * @param showHelpIcon Show Help Icon Flag
 * @returns Section Grid
 */
const renderSection = (section: AccordionSection, panelId: string, idx: string, showHelpIcon?: boolean) => {
  if (section.type === AccordionBodyItemType.Grid) {
    return renderRegularGrid(section, panelId, idx, showHelpIcon);
  } else if (section.type === AccordionBodyItemType.Table) {
    return renderTableGrid(section, panelId, idx);
  } else if (section.type === AccordionBodyItemType.Element) {
    return renderElements(section, panelId, idx);
  }
  return null;
};

const FlightDetailsExpansion = ({
  panelId,
  title,
  Icon,
  sections,
  expanded,
  onChange,
  tooltipMessage
}: FlightDetailsExpansionProps) => {
  const [showHelpIcon] = useState<boolean>(true);
  const renderSections = () =>
    sections.map((section: AccordionSection, idx: number) => (
      <FlightTailInfoContainer
        id={`flightDetails--${section.id ? section.id : panelId}__expansionPanel-container`}
        key={`section-container-${title.replace(' ', '-')}-${idx}`}
      >
        <span key={`section-key-${title.replace(' ', '-')}-${idx}`}>
          {section.header ? (
            <SectionHeader>
              <div style={{letterSpacing: 1}}>{section.header}</div>
              <LineContainer>
                <hr />
              </LineContainer>
            </SectionHeader>
          ) : null}
          {renderSection(section, panelId, idx.toString(), showHelpIcon)}
        </span>
      </FlightTailInfoContainer>
    ));

  return (
    <div id="flightDetailsExpansionEvent">
      <AccordionContainer expanded={expanded}>
        <span className="top">
          <Accordion
            id={`flightDetails--${panelId}__expansionPanel-top`}
            expanded={true}
            square
            elevation={0}
            onChange={onChange}
            sx={{position: 'relative', lineHeight: 'unset', borderRadius: 1}}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreContainer expanded={expanded}>
                  <ExpandMoreIcon id={`flightDetails--${panelId}__expansionPanel-toggle-button`} />
                  <ExpandMoreLabel>{expanded ? 'less' : 'more'}</ExpandMoreLabel>
                </ExpandMoreContainer>
              }
            >
              <HeaderContainer
                getFullElementId={(name, type) => `flightDetails--${panelId}_${name}-${type}`}
                title={title}
                Icon={Icon}
                helpTooltipMessage={tooltipMessage}
                showHelpIcon={showHelpIcon}
              />
            </AccordionSummary>
            <AccordionDetails sx={{paddingBottom: 0}}>{renderSections()}</AccordionDetails>
          </Accordion>
        </span>
      </AccordionContainer>
    </div>
  );
};

export default FlightDetailsExpansion;
