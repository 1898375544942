/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Open Case List query
 */

import {Query} from '../types';

export interface IOpenCaseList {
  aircraftId: string;
  caseId: string;
  caseNumber: number;
  caseType: string;
  caseSubject: string;
  caseStatus: string;
  createdTstamp: string;
  description: string;
  lastCaseComment: string;
}

const openCaseListQuery: Query<IOpenCaseList[]> = {
  route: 'aircraftStatus/openCaseList',
  transform: null
};

export default openCaseListQuery;
