/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Ping Latency Query and data transformation of raw ping latency data
 */
import {Query} from '../types';

export interface IPingLatency {
  timestamp: string;
  average_latency: number;
}

export interface IPingLatencyRaw {
  timestamp: string;
  average_latency: number;
}

/**
 * Transforms the given raw ping latency info data into (regular) ping latency info data for chart
 * @param pingLatencyRawData pingLatencyRawData values from the api
 * @returns PingLatency Transformed pingLatency info
 */
const transformRawPingLatencyInfo = (pingLatencyRawData: IPingLatencyRaw[] | null): IPingLatency[] | null => {
  if (!pingLatencyRawData) return null;
  const pingLatencyData: IPingLatency[] = pingLatencyRawData
    .filter((pingLatency) => pingLatency.average_latency !== null)
    .map((pingLatency) => ({
      timestamp: pingLatency?.timestamp,
      average_latency: pingLatency?.average_latency
    }));

  return pingLatencyData;
};

export const usePingLatencyQuery: Query<IPingLatency[]> = {
  route: `flightDetails/pingLatency`,
  transform: transformRawPingLatencyInfo
};
export default usePingLatencyQuery;
