/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utilities for Flight Details
 */

import {
  FORMAT_VALUE_DEFAULT_PRECISION,
  FORMAT_VALUE_DEFAULT_UNITS,
  FORMAT_VALUE_NULL_REPRESENTATION,
  FORMAT_VALUE_USE_SEPARATOR,
  FORMAT_VALUE_WITH_PRECISION,
  FORMAT_VALUE_WITH_UNITS,
  SwVersionStatus
} from '../../utils/constants';
import {
  formatValue,
  MS_IN_MIN,
  stringAsMs,
  DATE_TIME_LOCAL_FORMAT,
  msAsMoment,
  MINUTES_PER_HOUR
} from '../../utils/DateTimeUtils';
import moment from 'moment';
import {TRAFFIC_COMPOSITION_CHART_PALETTE, TRAFFIC_COMPOSITION_CHART_PATTERNS} from '../common/theme/Colors';
import {isNil} from 'lodash';
import {IVersionInfo} from '../../store/queries/flightDetails/swVersionsQuery';

// Used to synchronize the start and end times and the time-based axis' tick marks for all charts
export interface ChartTimeSettings {
  start: string;
  end: string;
  tickIntervalMinutes: number;
  labeledTickMinutesValues: number[];
  width?: number;
}

/**
 * Generic string formatter
 * ie. 'sample string', or '--'
 *
 * @param value String to format
 * @return Formatted string
 */
export const formatNullableString = (s: string) => {
  if (s === undefined || s === null || s.trim() === '') return FORMAT_VALUE_NULL_REPRESENTATION;
  return s;
};

export const formatSwVersionStatus = (swVersion: IVersionInfo): string => {
  return swVersion
    ? swVersion.isProduction
      ? SwVersionStatus.PRODUCTION
      : swVersion.isPilot
      ? SwVersionStatus.PILOT
      : SwVersionStatus.NEEDS_UPGRADE
    : '--';
};

/**
 * Avg Ping Latency value formatter
 * ie. '640 ms', '600', or '--'
 *
 * @param value Avg Ping Latency in msecs
 * @param withUnits Display with 'ms', otherwise omit (optional, default=true)
 * @return Formatted avg ping latency value as a string
 */
export const formatAvgPingLatencyValue = (value: number, withUnits: boolean = FORMAT_VALUE_WITH_UNITS): string => {
  const units = withUnits ? ' ms' : FORMAT_VALUE_DEFAULT_UNITS;
  return formatValue(value, FORMAT_VALUE_DEFAULT_PRECISION, units, FORMAT_VALUE_USE_SEPARATOR);
};

/**
 * Data Usage value formatter
 * ie. '9.8 MB', '0.5', '9', or '--'
 *
 * @param value Data Usage MB
 * @param withPrecision Display with precision 1, otherwise omit (optional, default=true)
 * @param withUnits Display with 'MB', otherwise omit (optional, default=true)
 * @return Formatted Data Usage value as a string
 */
export const formatDataUsageValue = (
  value: number,
  withPrecision: boolean = FORMAT_VALUE_WITH_PRECISION,
  withUnits: boolean = FORMAT_VALUE_WITH_UNITS
): string => {
  const precision = withPrecision ? 1 : FORMAT_VALUE_DEFAULT_PRECISION;
  const units = withUnits ? ' MB' : FORMAT_VALUE_DEFAULT_UNITS;
  return formatValue(value, precision, units, FORMAT_VALUE_USE_SEPARATOR);
};

/**
 * Total Traffic Composition Percentage formatter
 * ie. '100 %' or '100'
 *
 * @param value Total Traffic Composition percentage
 * @param withUnits Display with '%', otherwise omit (optional, default=true)
 * @return Formatted total data usage value as a string
 */
export const formatTrafficCompositionValue = (
  value: number,
  isDataUsageAvailable: boolean = false,
  withUnits: boolean = FORMAT_VALUE_WITH_UNITS
): string => {
  const units = withUnits ? '%' : FORMAT_VALUE_DEFAULT_UNITS;
  return isDataUsageAvailable
    ? `(${formatValue(value, FORMAT_VALUE_DEFAULT_PRECISION, units, FORMAT_VALUE_USE_SEPARATOR)})`
    : formatValue(value, FORMAT_VALUE_DEFAULT_PRECISION, units, FORMAT_VALUE_USE_SEPARATOR);
};

/**
 * Total Traffic Composition MB formatter
 * ie. '100 MB' or '--'
 *
 * @param value Total Traffic Composition data usage
 * @param withUnits Display with 'MB', otherwise omit (optional, default=true)
 * @return Formatted Traffic data usage value as a string
 */
export const formatTrafficCompositionMBValue = (
  value: number,
  withUnits: boolean = FORMAT_VALUE_WITH_UNITS
): string => {
  const units = withUnits ? ' MB' : FORMAT_VALUE_DEFAULT_UNITS;
  return formatValue(value, FORMAT_VALUE_DEFAULT_PRECISION, units, FORMAT_VALUE_USE_SEPARATOR);
};

// Defines each of the tick mark modes that are supported
export interface TickMarkMode {
  tickIntervalMinutes: number;
  labeledTickMinutesValues: number[];
  cutoffDurationMinutes: number | undefined;
}

// Tick mark mode definitions
// Note: These should remain in order from smallest cutoff duration to largest and then undefined for the final one
export const TICK_MODES: TickMarkMode[] = [
  {tickIntervalMinutes: 5, labeledTickMinutesValues: [0, 10, 20, 30, 40, 50], cutoffDurationMinutes: 120},
  {tickIntervalMinutes: 15, labeledTickMinutesValues: [0, 30], cutoffDurationMinutes: 480},
  {tickIntervalMinutes: 30, labeledTickMinutesValues: [0], cutoffDurationMinutes: undefined}
];

/**
 * Returns the charts' common time-oriented settings based on the given start and end timestamps
 * @param startTimestamp start timestamp
 * @param endTimestamp end timestamp
 * @returns ChartTimeSettings object containing chart start and end times
 */
export const getChartTimeSettingsFromMinMaxTimestamps = (
  startTimestamp: number,
  endTimestamp: number
): ChartTimeSettings => {
  const chartTimeSettings: ChartTimeSettings = {
    start: null,
    end: null,
    tickIntervalMinutes: 0,
    labeledTickMinutesValues: []
  };

  if (startTimestamp && endTimestamp) {
    const connectionStartTime = msAsMoment(startTimestamp);
    const connectionEndTime = msAsMoment(endTimestamp);

    // Note: We're ignoring seconds for this diff
    const totalDurationMinutes = connectionEndTime.diff(connectionStartTime, 'minutes');

    // Determine which tick mark mode we should be in
    // Note: Prefer the first one under the cutoff so we can have the highest granularity
    const tickMarkMode: TickMarkMode = TICK_MODES.filter(
      (tickMarkMode) =>
        !tickMarkMode.cutoffDurationMinutes || totalDurationMinutes <= tickMarkMode.cutoffDurationMinutes
    )[0];

    // Calculate the deltas to get to the nearest tick mark
    const startDeltaMinutes =
      connectionStartTime.minutes() -
      Math.floor(connectionStartTime.minutes() / tickMarkMode.tickIntervalMinutes) * tickMarkMode.tickIntervalMinutes;
    const endDeltaMinutes =
      Math.ceil(connectionEndTime.minutes() / tickMarkMode.tickIntervalMinutes) * tickMarkMode.tickIntervalMinutes -
      connectionEndTime.minutes();

    // Determine the nearest start and end times that land on a tick mark
    chartTimeSettings.start = connectionStartTime
      .seconds(0)
      .milliseconds(0)
      .subtract(startDeltaMinutes, 'm')
      .format(DATE_TIME_LOCAL_FORMAT);
    chartTimeSettings.end = connectionEndTime
      .seconds(0)
      .milliseconds(0)
      .add(endDeltaMinutes, 'm')
      .format(DATE_TIME_LOCAL_FORMAT);

    chartTimeSettings.tickIntervalMinutes = tickMarkMode.tickIntervalMinutes;
    chartTimeSettings.labeledTickMinutesValues = tickMarkMode.labeledTickMinutesValues;
  }

  return chartTimeSettings;
};

/**
 * Takes the given x-axis data, y-axis data, and chart start/end times, and returns data with the following adjustments:
 *  - If start/end times are valid, pads the data such that the returned data has the given start/end
 *  - If the given data has gaps, fills the data such that all data occurs at the specified interval
 * @param xAxisData Array of timestamps (in ms) associated with the y-axis data
 * @param yAxisData Array of y-axis data
 * @param chartTimeSettings Object containing the desired start and end times
 * @param dataPointIntervalMinutes The time interval (in min) between data points
 * @param targetPadMinutes The number of minutes (if any) to add pad data points before the start time and after the
 *                         end time. Used to align with other charts that have larger data point sizes.
 *                         Note: Automatic padding from the current chart's data point size will be subtracted from the
 *                               requested pad.
 * @returns Object consisting of the adjusted x-axis and y-axis data
 */
export const adjustCategorizedChartData = (
  xAxisData: number[],
  yAxisData: any[],
  chartTimeSettings: ChartTimeSettings,
  dataPointIntervalMinutes: number = 5,
  targetPadMinutes?: number
): {prependTimestamps: number[]; appendTimestamps: number[]; xAxisData: number[]; yAxisData: any[]} => {
  const dataPointIntervalMs = dataPointIntervalMinutes * MS_IN_MIN;
  const prependTimestamps: number[] = [];
  const prependPadTimestamps: number[] = [];
  let prependData: any[] = [];
  const appendTimestamps: number[] = [];
  const appendPadTimestamps: number[] = [];
  let appendData: any[] = [];
  const filledTimestamps: number[] = [];
  const filledData: any[] = [];

  if (chartTimeSettings.start && chartTimeSettings.end) {
    const desiredStartMs = stringAsMs(chartTimeSettings.start);
    const desiredEndMs = stringAsMs(chartTimeSettings.end);

    if (targetPadMinutes) {
      // By default HighCharts will add padding equal to half a data point's width on each end
      const existingPadMinutes = dataPointIntervalMinutes / 2;

      const remainingPadMinutes = targetPadMinutes - existingPadMinutes;

      if (remainingPadMinutes > 0) {
        const fractionalPadMinutes = remainingPadMinutes % dataPointIntervalMinutes;

        // TODO(CMDO-83868): Figure out how we can incorporate pad that's less than a whole data point's width

        const wholePadMinutes = remainingPadMinutes - fractionalPadMinutes;
        const wholePadMs = wholePadMinutes * MS_IN_MIN;

        for (let offsetMs = 0; offsetMs < wholePadMs; offsetMs += dataPointIntervalMs) {
          prependPadTimestamps.push(desiredStartMs - wholePadMs + offsetMs);
          appendPadTimestamps.push(desiredEndMs + dataPointIntervalMs + offsetMs);
        }
      }
    }

    // Add any pad to the prepend set
    if (prependPadTimestamps.length > 0) {
      prependTimestamps.push(...prependPadTimestamps);
    }

    // For timestamps to be prepended, start at the desired start timestamp and end before the first data timestamp
    for (let timestamp = desiredStartMs; timestamp < xAxisData?.[0]; timestamp += dataPointIntervalMs) {
      prependTimestamps.push(timestamp);
    }
    if (prependTimestamps.length > 0) {
      prependData = Array(prependTimestamps.length).fill(null);
    }

    // For timestamps to be appended, start after the last data timestamp and end on the desired end timestamp
    for (
      let timestamp = xAxisData?.[xAxisData.length - 1] + dataPointIntervalMs;
      timestamp <= desiredEndMs;
      timestamp += dataPointIntervalMs
    ) {
      appendTimestamps.push(timestamp);
    }

    // Add any pad to the append set
    if (appendPadTimestamps.length > 0) {
      appendTimestamps.push(...appendPadTimestamps);
    }

    if (appendTimestamps.length > 0) {
      appendData = Array(appendTimestamps.length).fill(null);
    }
  }

  // Ensure that there aren't any gaps in the provided x-axis data
  for (
    let timestamp = xAxisData?.[0], yDataIndex = 0;
    timestamp <= xAxisData?.[xAxisData.length - 1];
    timestamp += dataPointIntervalMs
  ) {
    filledTimestamps.push(timestamp);
    if (!xAxisData?.includes(timestamp)) {
      filledData.push(null);
    } else {
      filledData.push(yAxisData[yDataIndex++]);
    }
  }

  return {
    prependTimestamps: prependTimestamps,
    appendTimestamps: appendTimestamps,
    xAxisData: [...prependTimestamps, ...filledTimestamps, ...appendTimestamps],
    yAxisData: [...prependData, ...filledData, ...appendData]
  };
};

/**
 * Given the provided x-axis timestamp data and chart time settings, returns the appropriate x-axis tick positions
 * @param xAxisData Array of x-axis timestamp data
 * @param chartTimeSettings The desired chart settings
 * @returns Array of x-axis zero-based data point indices that should have tick marks
 */
export const getXAxisTickPositions = (xAxisData: number[], chartTimeSettings: ChartTimeSettings) =>
  xAxisData.reduce((positions: number[], value: number, index: number): number[] => {
    if (value % (chartTimeSettings.tickIntervalMinutes * MS_IN_MIN) === 0) {
      positions.push(index);
    }

    return positions;
  }, []);

/**
 * Calculates the time duration values such as Flight duration and Connected time duration
 * @param now Current time / last known time of the aircraft
 * @param then Start time  of the aircraft
 */
export const timeDuration = (now: string, then: string): string => {
  if (!now || !then) return '--';
  const timeDifference = moment(now).diff(moment(then));
  const duration = moment.duration(timeDifference);
  return duration.isValid() ? `${duration.hours() ? `${duration.hours()}h ` : ''}${duration.minutes()}m` : null;
};

export const patternDefinition = {
  patterns: [
    {
      id: 'browsingAndSocialNetworksPattern',
      path: {
        d: 'M 1 1 l 1 1',
        stroke: TRAFFIC_COMPOSITION_CHART_PATTERNS[0],
        strokeWidth: 1,
        fill: TRAFFIC_COMPOSITION_CHART_PALETTE[1]
      },
      width: 2,
      height: 2
    },
    {
      id: 'productivityAndCloudServicesPattern',
      path: {
        d: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
        stroke: TRAFFIC_COMPOSITION_CHART_PATTERNS[1],
        strokeWidth: 1,
        fill: TRAFFIC_COMPOSITION_CHART_PALETTE[2]
      },
      width: 4,
      height: 4
    }
  ]
};

/**
 * Determines whether a flight is Live or Historical using flightEnd, connectedEnd, and nowTimestamp
 * @param flightEnd Flight end timestamp
 * @param connectedEndTimestamp Connected end timestamp
 * @param nowTimestamp Current timestamp used for comparison
 * @returns True for Live Flights and false for Historical Flights
 */
export const determineIfLiveFlight = (
  flightStart: string,
  flightEnd: string,
  connectedEndTimestamp: string,
  nowTimestamp: string
): boolean => {
  const connectedEnd = moment.utc(connectedEndTimestamp);
  const now = moment.utc(nowTimestamp);

  const timeDifferenceInMinutes = now.diff(connectedEnd, 'minutes');
  // Determines the flight as Historical On ground only if Flight Start and End are null
  // And the time difference is >=30 minutes
  if (timeDifferenceInMinutes >= 30 && isNil(flightStart) && isNil(flightEnd)) {
    return false;
  }
  if ((flightEnd && timeDifferenceInMinutes >= 30) || timeDifferenceInMinutes >= 15 * MINUTES_PER_HOUR) {
    return false;
  }
  return true;
};
