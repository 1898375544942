/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connectivity Outlook Util methods
 */

import moment from 'moment';
import {forEach, isNil} from 'lodash';
import {IFlightPath, IFlightPathPoint} from '../../store/queries/connectivityPlanner/flightPathQuery';
import {
  DATE_TIME_FORMAT_WITH_HR_MINS,
  DATE_TIME_FORMAT_WITH_MILLISECONDS,
  DATE_TIME_LOCAL_FORMAT,
  DATE_TIME_LOCAL_FORMAT_MINUTES,
  formatMomentInput,
  msAsMoment
} from '../../utils/DateTimeUtils';
import {convertObjectToFilterRangeOptions, FilterOptionsType, FilterType} from '../../utils/filterUtils';
import {
  ListGridColumn,
  DefaultCellRendererV2,
  CustomLink,
  DateCellRendererV2,
  CellRenderer
} from '@viasat/insights-components';
import {AirportTooltipCellRenderer, getColumnIdEnumName} from '../lists/listUtils';
import {FlightEvent, FlightPlanEvents} from '../../store/queries/connectivityPlanner/flightEventsQuery';
import {ChartTimeSettings, TickMarkMode} from '../flightDetails/flightDetailsUtil';
import {FlightPlanInfo} from '../../store/queries/connectivityPlanner/flightPlansByIdQuery';
import {
  ABOVE_TARGET_COLOR,
  ABOVE_HIGHER_THRESHOLD_COLOR,
  BLACK,
  WHITE,
  NO_SERVICE_COLOR,
  DROP_ZONE_ACCEPT_COLOR,
  DROP_ZONE_REJECT_COLOR,
  DROP_ZONE_ACTIVE_COLOR,
  DROP_ZONE_GREY_COLOR
} from '../common/theme/Colors';
import {AirportsType} from '../../utils/MapUtil';
import {CheckboxCellRenderer} from '../common/elements/listGridCells/ListGridCheckbox';

/**
 * Converts the bytes size into Kb, Mb, Gb
 * @param bytes bytes value to be converted
 * @param decimals decimals to which the conversion should account for
 * @returns
 */
export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes';

  const kiloBytes = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(kiloBytes));

  return parseFloat((bytes / Math.pow(kiloBytes, i)).toFixed(dm)) + ' ' + sizes[i];
};

export enum ConnectivityOutlookStatus {
  CONNECTED = 'Connected',
  OUT_OF_COVERAGE = 'Out of coverage',
  SATELLITE_HANDOVER = 'Satellite handover',
  POSSIBLE_SERVICE_INTERRUPTION = 'Possible service interruption',
  REGULATORY_RESTRICTIONS = 'Regulatory Restrictions',
  NO_SERVICE = 'No Service'
}
export const ConnectivityOutlookIconColors = {
  [ConnectivityOutlookStatus.CONNECTED]: ABOVE_TARGET_COLOR,
  [ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION]: ABOVE_HIGHER_THRESHOLD_COLOR,
  [ConnectivityOutlookStatus.SATELLITE_HANDOVER]: ABOVE_HIGHER_THRESHOLD_COLOR,
  [ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS]: NO_SERVICE_COLOR,
  [ConnectivityOutlookStatus.OUT_OF_COVERAGE]: NO_SERVICE_COLOR,
  [ConnectivityOutlookStatus.NO_SERVICE]: NO_SERVICE_COLOR
};

export const ConnectivityOutlookIconTextColors = {
  [ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION]: BLACK,
  [ConnectivityOutlookStatus.SATELLITE_HANDOVER]: BLACK,
  [ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS]: WHITE,
  [ConnectivityOutlookStatus.OUT_OF_COVERAGE]: WHITE,
  [ConnectivityOutlookStatus.NO_SERVICE]: WHITE
};

export enum DepartureTimeChange {
  EDITED = 'edited',
  SHIFTED = 'shifted',
  NO_CHANGE = 'nochange'
}

// Flight Plan List Hidden Columns mappings
// Required: Enum name should match columnId name, case-sensitive
export enum FlightPlanListColumnId {
  checkbox = -1, // fixed column, cannot be hidden
  flightPlanIdentifier = 0, // fixed column, cannot be hidden
  endUser,
  serialNumber,
  tailId,
  departureAirport,
  departureTime,
  departureTimeLocal,
  destinationAirport,
  arrivalTime,
  arrivalTimeLocal,
  totalElapsedTime,
  networkType,
  uploadedTstamp,
  eventsCount
}

// Query Field Constants
export const QUERY_FIELD_MAPPING = {
  checkbox: 'checkbox',
  flightPlanIdentifier: 'flightPlanIdentifier',
  endUser: 'endUser',
  serialNumber: 'serialNumber',
  tailId: 'tailId',
  departureAirport: 'departureAirport',
  departureTime: 'departureTime',
  departureTimeLocal: 'departureTimeLocal',
  destinationAirport: 'destinationAirport',
  arrivalTime: 'arrivalTime',
  arrivalTimeLocal: 'arrivalTimeLocal',
  totalElapsedTime: 'totalElapsedTime',
  networkType: 'networkType',
  uploadedTstamp: 'uploadedTstamp',
  eventsCount: 'eventsCount'
};

/**
 * Get Flight Plan List Query Field Mapping
 * @param columnId columnId enum to convert to query field
 * @param append append string to columnId name (default='')
 * @returns query field as string, otherwise undefined
 */
export const getQueryFieldMapping = (columnId: FlightPlanListColumnId, append: string = '') => {
  const columnIdName = getColumnIdEnumName(columnId, FlightPlanListColumnId) + append;
  return columnIdName ? QUERY_FIELD_MAPPING[columnIdName] : undefined;
};

/**
 * Render function for Flight Plan links
 * @param cellProps props for the cell renderer
 * @return Flight Plan link component
 */
export const FlightPlanLinkRenderer: CellRenderer = ({cellData, rowData, column}) => {
  const {handleClick} = column;
  return (
    <CustomLink
      to={rowData && rowData.id ? `/connectivity-outlook-page` : null}
      handleClick={() => {
        if (handleClick) {
          handleClick(rowData);
        }
      }}
    >{`${cellData}${rowData?.copyCount ? ` (${rowData?.copyCount})` : ''}`}</CustomLink>
  );
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isEndUser whether we are an end user or not
 * @param airports metadata to render as tooltips for airport cells
 * @return list of ListGridColumnProps objects
 */
export const buildFlightPlanListColumns = (isEndUser: boolean, airports: AirportsType) => {
  const columnPropsList: ListGridColumn[] = [
    {
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    },
    {
      key: 'flightPlanIdentifier',
      dataKey: 'flightPlanIdentifier',
      showHideGroup: '__static',
      title: 'Flight Plan',
      getColumnJsx: FlightPlanLinkRenderer,
      width: 160,
      frozen: true,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.flight_plan_identifier.column.title'
    }
  ];
  if (!isEndUser) {
    columnPropsList.push({
      key: 'endUser',
      dataKey: 'endUser',
      showHideGroup: 'Tail Info',
      title: 'Customer',
      getColumnJsx: DefaultCellRendererV2,
      width: 240,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.customer.column.title'
    });
  }

  columnPropsList.push(
    {
      key: 'serialNumber',
      dataKey: 'serialNumber',
      showHideGroup: 'Tail Info',
      title: 'SN',
      getColumnJsx: DefaultCellRendererV2,
      width: 100,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.serial_number.column.title'
    },
    {
      key: 'tailId',
      dataKey: 'tailId',
      showHideGroup: 'Tail Info',
      title: 'Tail ID',
      getColumnJsx: DefaultCellRendererV2,
      width: 100,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.tail_id.column.title'
    },
    {
      key: 'networkType',
      dataKey: 'networkType',
      showHideGroup: 'Tail Info',
      title: 'Network',
      getColumnJsx: DefaultCellRendererV2,
      width: 180,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.network.column.title'
    },
    {
      key: 'departureAirport',
      dataKey: 'departureAirportData',
      showHideGroup: 'Flight Info',
      title: 'Origin',
      getColumnJsx: AirportTooltipCellRenderer,
      width: 95,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.origin.column.title'
    },
    {
      key: 'departureTime',
      dataKey: 'departureTime',
      showHideGroup: 'Flight Info',
      title: 'ETD',
      defaultSort: 'desc',
      getColumnJsx: DateCellRendererV2,
      width: 150,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.etd.column.title'
    },
    {
      key: 'departureTimeLocal',
      dataKey: 'departureTimeLocal',
      showHideGroup: 'Flight Info',
      title: 'ETD Local',
      getColumnJsx: DateCellRendererV2,
      width: 160,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.etd_local.column.title'
    },
    {
      key: 'destinationAirport',
      dataKey: 'destinationAirportData',
      showHideGroup: 'Flight Info',
      displayGroup: 1,
      title: 'Destination',
      getColumnJsx: AirportTooltipCellRenderer,
      width: 130,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.destination.column.title'
    },
    {
      key: 'arrivalTime',
      dataKey: 'arrivalTime',
      showHideGroup: 'Flight Info',
      displayGroup: 1,
      title: 'ETA',
      defaultSort: 'desc',
      getColumnJsx: DateCellRendererV2,
      width: 150,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.eta.column.title'
    },
    {
      key: 'arrivalTimeLocal',
      dataKey: 'arrivalTimeLocal',
      showHideGroup: 'Flight Info',
      title: 'ETA Local',
      getColumnJsx: DateCellRendererV2,
      width: 150,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.eta_local.column.title'
    },
    {
      key: 'totalElapsedTime',
      dataKey: 'totalElapsedTime',
      showHideGroup: 'Flight Info',
      title: 'Duration',
      getColumnJsx: DefaultCellRendererV2,
      width: 105,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.duration.column.title'
    },
    {
      key: 'eventsCount',
      dataKey: 'eventsCount',
      showHideGroup: 'Flight Info',
      title: 'Events',
      getColumnJsx: DefaultCellRendererV2,
      width: 100,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.events.column.title'
    },
    {
      key: 'uploadedTstamp',
      dataKey: 'uploadedTstamp',
      showHideGroup: 'Flight Info',
      title: 'Uploaded',
      getColumnJsx: DateCellRendererV2,
      width: 160,
      sortable: true,
      columnHeaderTooltipText: 'flight_plan_list.uploaded_date.column.title'
    }
  );

  return columnPropsList;
};

enum FilterId {
  departureAirport = 'departureAirport',
  destinationAirport = 'destinationAirport',
  serialNumber = 'serialNumber',
  tailId = 'tailId'
}

/**
 * Returns the range options for the Serial Number filter
 * @param props Filter container properties
 * @returns Array of Serial Number Filter key/value pairs
 */
const getSerialNumberFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.flightPlanData ? convertObjectToFilterRangeOptions(props.flightPlanData, 'serialNumber') : [];

/**
 * Returns the range options for the Tail ID filter
 * @param props Filter container properties
 * @returns Array of Tail ID Filter key/value pairs
 */
const getTailIdFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.flightPlanData ? convertObjectToFilterRangeOptions(props.flightPlanData, 'tailId') : [];

/**
 * Returns the range options for the Serial Number filter
 * @param props Filter container properties
 * @returns Array of Serial Number Filter key/value pairs
 */
const getDepartureAirportFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.flightPlanData ? convertObjectToFilterRangeOptions(props.flightPlanData, 'departureAirport') : [];

/**
 * Returns the range options for the Serial Number filter
 * @param props Filter container properties
 * @returns Array of Serial Number Filter key/value pairs
 */
const getDestinationAirportFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.flightPlanData ? convertObjectToFilterRangeOptions(props.flightPlanData, 'destinationAirport') : [];

export const FLIGHT_PLAN_LIST_FILTERS: Array<FilterType> = [
  {
    title: 'Serial Number',
    id: FilterId.serialNumber,
    getValues: getSerialNumberFilterRangeOptions
  },
  {
    title: 'Tail ID',
    id: FilterId.tailId,
    getValues: getTailIdFilterRangeOptions
  },
  {
    title: 'Origin',
    id: FilterId.departureAirport,
    getValues: getDepartureAirportFilterRangeOptions
  },
  {
    title: 'Destination',
    id: FilterId.destinationAirport,
    getValues: getDestinationAirportFilterRangeOptions
  }
];

/**
 * Performs a deep copy of the filter list and hides field(s) based on the
 * passed in parameters.
 * @returns A list of filters for flight plan list
 */
export const getFlightPlanListFilters = (): Array<FilterType> => {
  const filterList = [...FLIGHT_PLAN_LIST_FILTERS];

  return filterList.sort((a: FilterType, b: FilterType): number => {
    return a.title > b.title ? 1 : -1;
  });
};

/**
 * Generates connectivity events on each minute based on the flight path data
 * @param flightPath Flight path with events data
 * @returns Events timeline data
 */
export const generateEventsTimelineData = (flightPaths: IFlightPath[], selectedLegIdx: number) => {
  const flightLegEvents: FlightLegEventsData[] = [];
  let iconText = 0;
  flightPaths.map((flightPath) => {
    const flightPathEvents = generateDisconnectedEvents(
      moment.utc(flightPath.start?.timestamp),
      moment.utc(flightPath.end?.timestamp)
    );
    let selectedLegIconText = 0;
    let currentStatus: ConnectivityOutlookStatus = ConnectivityOutlookStatus.NO_SERVICE;
    let eventName: ConnectivityOutlookStatus = ConnectivityOutlookStatus.NO_SERVICE;
    let serviceInterruptionCount = -1;
    forEach(flightPathEvents, (flightPathEvent, idx) => {
      // TODO: we have different events for same timestamp which is causing mismatch in timeline
      // This needs to be fixed on API side
      const matchingFlightPathPoint = flightPath.flightPath.filter(
        (path) => path.timestamp === flightPathEvent.eventDate.format(DATE_TIME_LOCAL_FORMAT_MINUTES)
      );
      const matchingIdx = matchingFlightPathPoint.length === 1 ? 0 : matchingFlightPathPoint.length - 1;
      if (matchingFlightPathPoint.length) {
        currentStatus = matchingFlightPathPoint[matchingIdx].availability;
        flightPathEvent.status = matchingFlightPathPoint[matchingIdx].availability;
        flightPathEvent.eventName = matchingFlightPathPoint[matchingIdx].event.displayName;
        if (
          (currentStatus === ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION ||
            currentStatus === ConnectivityOutlookStatus.SATELLITE_HANDOVER ||
            currentStatus === ConnectivityOutlookStatus.NO_SERVICE ||
            currentStatus === ConnectivityOutlookStatus.OUT_OF_COVERAGE ||
            currentStatus === ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS) &&
          flightPathEvents[idx - 1]?.status !== currentStatus
        ) {
          iconText += 1;
          selectedLegIconText += 1;
        }
        flightPathEvent.iconText = selectedLegIdx === -1 ? iconText : selectedLegIconText;
      } else {
        flightPathEvent.status = currentStatus;
        flightPathEvent.eventName = eventName;
      }
      // Setting the data gap as connected after the Handover of 5 minutes.
      // Because, there is a possibility that the next event of Connected state will be detected few mins later
      // So consider the next point after handover end as connected
      // TODO: Discussions going on with Core Team on how to enhance this behavior in the future.
      if (serviceInterruptionCount === 5) {
        flightPathEvent.status = ConnectivityOutlookStatus.CONNECTED;
        flightPathEvent.eventName = ConnectivityOutlookStatus.CONNECTED;
        currentStatus = ConnectivityOutlookStatus.CONNECTED;
        eventName = ConnectivityOutlookStatus.CONNECTED;
      }
      if (currentStatus === ConnectivityOutlookStatus.SATELLITE_HANDOVER) {
        serviceInterruptionCount++;
      } else {
        serviceInterruptionCount = 0;
      }
    });
    flightLegEvents.push({flightPlanId: flightPath.id, flightLegEvents: flightPathEvents});
    return flightLegEvents;
  });
  return flightLegEvents;
};
export interface FlightLegEventsData {
  flightPlanId: string;
  flightLegEvents: FlightPathEventsData[];
}
export interface FlightLegChartSettings {
  flightPlanId: string;
  chartSettings: ChartTimeSettings;
}
export interface FlightPathEventsData {
  eventDate: moment.Moment;
  status: ConnectivityOutlookStatus;
  eventName: ConnectivityOutlookStatus;
  iconText: number;
}

/**
 * Generates the prefilled disconnected event on every minute based on the start and end
 * @param startDate Flight Connection Start Date time
 * @param endDate Flight Connection End Date time
 * @returns List of 1 minute interval disconnected events with datetime and status
 */
export const generateDisconnectedEvents = (
  startDate: moment.Moment,
  endDate: moment.Moment
): FlightPathEventsData[] => {
  const timeline: FlightPathEventsData[] = [];
  let date = moment.utc(startDate);
  while (date <= endDate) {
    timeline.push({
      eventDate: moment.utc(formatMomentInput(date, DATE_TIME_LOCAL_FORMAT_MINUTES)),
      status: ConnectivityOutlookStatus.NO_SERVICE,
      eventName: ConnectivityOutlookStatus.NO_SERVICE,
      iconText: 0
    });
    date = moment.utc(date).add(1, 'minute');
  }
  timeline.pop();
  return timeline;
};

/**
 * Checks the permission to allow delete flight plans for Internal and External users
 * @param isInternal Internal user check param
 * @param isEndUser End user check param
 * @param currentCustomerCode Currently selected customer code
 * @returns False by default and True if the logged in use is End user and Internal User (selected customer code is not ALL)
 */
export const disableDeleteFlightPlans = (
  isInternal: boolean,
  isEndUser: boolean,
  currentCustomerCode: string
): boolean => {
  if (!isNil(isEndUser) && isEndUser) {
    return false;
  } else if (isInternal && currentCustomerCode === 'ALL') {
    return true;
  } else if (!isInternal) {
    return true;
  }
  return false;
};

/**
 * Gets the Start of the day NOW - 7 days
 * @returns Default Start date for the DatePicker
 */
export const flightPlanListDefaultStartDate = (days: number) => {
  return moment.utc().subtract(days, 'days').startOf('day').format(DATE_TIME_LOCAL_FORMAT);
};

/**
 * Gets the End of the day NOW + 7 days
 * @returns Default End date for the DatePicker
 */
export const flightPlanListDefaultEndDate = (days: number) => {
  return moment.utc().add(days, 'days').endOf('day').format(DATE_TIME_LOCAL_FORMAT);
};

/**
 * Updates the original flight path with the new departure time
 * @param flightPathToModify Original flight path before changing the departure time
 * @param newDepartureTime new departure time
 */
const updateFlightPathDepartureTime = (flightPathToModify: IFlightPath, newDepartureTime: string) => {
  const updatedDepartureTime = moment.utc(newDepartureTime).format(DATE_TIME_FORMAT_WITH_HR_MINS);
  forEach(flightPathToModify.flightPath, (flightPath: IFlightPathPoint) => {
    flightPath.timestamp = moment
      .utc(updatedDepartureTime)
      .add(flightPath.relativeTime, 'minutes')
      .format(DATE_TIME_FORMAT_WITH_HR_MINS);
  });
  flightPathToModify.start = flightPathToModify.flightPath[0];
  flightPathToModify.end = flightPathToModify.flightPath[flightPathToModify.flightPath.length - 1];
};

/**
 * Updates the original flight events with the new departure time
 * @param flightEventsToModify Original flight events before changing the departure time
 * @param newDepartureTime new departure time
 */
const updateFlightEventsDepartureTime = (flightEventsToModify: FlightPlanEvents, newDepartureTime: string) => {
  const updatedDepartureTime = moment.utc(newDepartureTime).format(DATE_TIME_FORMAT_WITH_HR_MINS);
  forEach(flightEventsToModify.events, (flightEvent: FlightEvent) => {
    const timeToAdd = flightEvent.relativeTime ? flightEvent.relativeTime : 0;
    flightEvent.timestamp = moment
      .utc(updatedDepartureTime)
      .add(timeToAdd, 'minutes')
      .format(DATE_TIME_FORMAT_WITH_HR_MINS);
  });
};

/**
 * Updates the original flight plan with the new departure time
 * @param flightPlan Original Flight plan before changing the departure time
 * @param newDepartureTime Modified departure time from date picker
 * @param isDepartureTimeChanged departure time shited or edited or nochange
 */
const updateFlightPlanDepartureTime = (
  flightPlanToModify: FlightPlanInfo,
  newDepartureTime: string,
  isDepartureTimeChanged: DepartureTimeChange
) => {
  flightPlanToModify.departureTime = newDepartureTime;
  flightPlanToModify.arrivalTime = moment
    .utc(newDepartureTime)
    .add(flightPlanToModify.totalElapsedTime, 'minutes')
    .format(DATE_TIME_FORMAT_WITH_MILLISECONDS);
  flightPlanToModify.isDepartureTimeChanged = isDepartureTimeChanged;
};

/**
 * updates the succeeding flight plans if the departure time overlaps with new departure time
 * @param legIdx index of the flight plan for which the departure time is edited
 * @param  flightPlans list of flight plans
 * @param  flightPath list of flight path
 * @param  flightPath list of flight events
 */
const updateSucceedingFlightPlansOnOverlap = (
  legIdx: number,
  flightPlans: FlightPlanInfo[],
  flightPath: IFlightPath[],
  flightEvents: FlightPlanEvents[]
) => {
  //do nothing if the selected flight leg has no succeeding flight plans
  if (legIdx === flightPlans.length - 1) return;
  for (let i = legIdx; i < flightPlans.length - 1; i++) {
    if (moment.utc(flightPlans[i].arrivalTime).isAfter(moment.utc(flightPlans[i + 1].departureTime))) {
      const newDepartureTime = moment
        .utc(flightPlans[i].arrivalTime)
        .clone()
        .add(60, 'minutes')
        .format(DATE_TIME_FORMAT_WITH_HR_MINS);
      updateFlightPlanDepartureTime(flightPlans[i + 1], newDepartureTime, DepartureTimeChange.SHIFTED);
      updateFlightEventsDepartureTime(flightEvents[i + 1], newDepartureTime);
      updateFlightPathDepartureTime(flightPath[i + 1], newDepartureTime);
    } else {
      updateFlightPlanDepartureTime(
        flightPlans[i + 1],
        flightPlans[i + 1].departureTime,
        DepartureTimeChange.NO_CHANGE
      );
    }
  }
};

/**
 * updates the preceeding flight plans if the arrival time overlaps with new departure time
 * @param legIdx index of the flight plan for which the departure time is edited
 * @param  flightPlans list of flight plans
 * @param  flightPath list of flight path
 * @param  flightEvents list of flight events
 */
const updatePrecedingFlightPlansOnOverlap = (
  legIdx: number,
  flightPlans: FlightPlanInfo[],
  flightPath: IFlightPath[],
  flightEvents: FlightPlanEvents[]
) => {
  //do nothing if the selected flight leg has no preceding flight plans
  if (legIdx === 0) return;
  for (let i = legIdx; i > 0; i--) {
    if (moment.utc(flightPlans[i].departureTime).isBefore(moment.utc(flightPlans[i - 1].arrivalTime))) {
      const newArrivalTime = moment
        .utc(flightPlans[i].departureTime)
        .clone()
        .subtract(60, 'minutes')
        .format(DATE_TIME_FORMAT_WITH_HR_MINS);
      const newDepartureTime = moment
        .utc(newArrivalTime)
        .clone()
        .subtract(flightPlans[i - 1].totalElapsedTime, 'minutes')
        .format(DATE_TIME_FORMAT_WITH_HR_MINS);
      updateFlightPlanDepartureTime(flightPlans[i - 1], newDepartureTime, DepartureTimeChange.SHIFTED);
      updateFlightEventsDepartureTime(flightEvents[i - 1], newDepartureTime);
      updateFlightPathDepartureTime(flightPath[i - 1], newDepartureTime);
    } else {
      updateFlightPlanDepartureTime(
        flightPlans[i - 1],
        flightPlans[i - 1].departureTime,
        DepartureTimeChange.NO_CHANGE
      );
    }
  }
};

const isMultipleFlightPlanSelected = (noOfFlightPlans: number) => {
  return noOfFlightPlans > 1;
};

/**
 * modifies the original flight plan with new departure time and shifts the subsequent flight plans on overlap
 * @param flightPlans original flight plans
 * @param flightPaths original flight path
 * @param flightEvents original flight plan events
 * @param  newDepartureTime new departure time
 * @param  selectedLegIdx index of the flight plan which has to be updated with newDepartureTime
 * @returns modified flight plans.
 */
export const getUpdatedFlightPlans = (
  flightPlans: FlightPlanInfo[],
  flightPaths: IFlightPath[],
  flightEvents: FlightPlanEvents[],
  newDepartureTime: string,
  selectedLegIdx: number
) => {
  if (
    isNil(flightPlans) ||
    isNil(flightPaths) ||
    isNil(flightEvents) ||
    !flightPlans.length ||
    !flightPaths.length ||
    !flightEvents.length
  )
    return null;
  else {
    const modifiedFlightPlanData: FlightPlanInfo[] = [...flightPlans];
    const modifiedFlightPath: IFlightPath[] = [...flightPaths];
    const modifiedFlightEvents: FlightPlanEvents[] = [...flightEvents];

    const legIdx = selectedLegIdx === -1 ? 0 : selectedLegIdx;
    updateFlightPlanDepartureTime(modifiedFlightPlanData[legIdx], newDepartureTime, DepartureTimeChange.EDITED);
    updateFlightEventsDepartureTime(modifiedFlightEvents[legIdx], newDepartureTime);
    updateFlightPathDepartureTime(modifiedFlightPath[legIdx], newDepartureTime);
    if (isMultipleFlightPlanSelected(flightPlans.length)) {
      updateSucceedingFlightPlansOnOverlap(legIdx, modifiedFlightPlanData, modifiedFlightPath, modifiedFlightEvents);
      updatePrecedingFlightPlansOnOverlap(legIdx, modifiedFlightPlanData, modifiedFlightPath, modifiedFlightEvents);
    }

    return {
      flightPlans: modifiedFlightPlanData,
      flightPlanEvents: modifiedFlightEvents,
      flightPaths: modifiedFlightPath
    };
  }
};

/**
 * restores the modified flight plans with the original departure time
 * @param modifiedFlightPlanData modified flightplans
 * @param modifiedFlightPath modified flightpath
 * @param modifiedFlightPlanEvents modified flightplan events
 * @param  originalFlightPlanInfo original flightPlans before changing the departure time
 * @returns restored flight plans with original departure time
 */
export const resetFlightPlans = (
  modifiedFlightPlanData: FlightPlanInfo[],
  modifiedFlightPath: IFlightPath[],
  modifiedFlightPlanEvents: FlightPlanEvents[],
  originalFlightPlanInfo: FlightPlanInfo[]
) => {
  const restoredFlightPlans: FlightPlanInfo[] = [...modifiedFlightPlanData];
  const restoredFlightPath: IFlightPath[] = [...modifiedFlightPath];
  const restoredFlightPlanEvents: FlightPlanEvents[] = [...modifiedFlightPlanEvents];
  restoredFlightPlans.forEach((flightPlan, index) => {
    if (flightPlan.isDepartureTimeChanged !== DepartureTimeChange.NO_CHANGE) {
      updateFlightPlanDepartureTime(
        flightPlan,
        originalFlightPlanInfo[index].departureTime,
        DepartureTimeChange.NO_CHANGE
      );
      updateFlightEventsDepartureTime(restoredFlightPlanEvents[index], originalFlightPlanInfo[index].departureTime);
      updateFlightPathDepartureTime(restoredFlightPath[index], originalFlightPlanInfo[index].departureTime);
    }
  });
  return {
    flightPlans: restoredFlightPlans,
    flightPlanEvents: restoredFlightPlanEvents,
    flightPaths: restoredFlightPath
  };
};

const MULTIPLE_TICK_MODES: TickMarkMode[] = [
  {tickIntervalMinutes: 10, labeledTickMinutesValues: [0, 20, 40, 60], cutoffDurationMinutes: 120},
  {tickIntervalMinutes: 30, labeledTickMinutesValues: [0, 60], cutoffDurationMinutes: 480},
  {tickIntervalMinutes: 30, labeledTickMinutesValues: [0], cutoffDurationMinutes: undefined}
];

const PADDING_INTERVAL = [
  {paddingInterval: 2, cutoffDurationMinutes: 120},
  {paddingInterval: 10, cutoffDurationMinutes: 600},
  {paddingInterval: 15, cutoffDurationMinutes: 1800},
  {paddingInterval: 10, cutoffDurationMinutes: undefined}
];
/**
 * Update Tick Interval based on Flight Path start and end time
 * @param flightPathInfo Flight path details
 * @param flightPlanInfo For multiple flight legs it will be true and for single flight leg it will be false
 * @param selectedFlightLegIdx the index of the selected flight leg
 */
export const getChartSettingsForEventsTimeline = (
  flightPathInfo: IFlightPath[],
  flightPlanInfo: FlightPlanInfo[],
  selectedFlightLegIdx: number
): FlightLegChartSettings[] => {
  let flightChartSettings: FlightLegChartSettings[] = [];
  let totalFlightTime = totalFlightDuration(flightPlanInfo);
  const isCombinedView = selectedFlightLegIdx === -1 && isMultipleFlightPlanSelected(flightPlanInfo.length);

  const tickMarkMode = MULTIPLE_TICK_MODES.filter(
    (tickMarkMode) => !tickMarkMode.cutoffDurationMinutes || totalFlightTime <= tickMarkMode.cutoffDurationMinutes
  )[0];

  const paddingDuration = PADDING_INTERVAL.find(
    (paddingInterval) =>
      !paddingInterval.cutoffDurationMinutes || totalFlightTime <= paddingInterval.cutoffDurationMinutes
  ).paddingInterval;
  if (isCombinedView) totalFlightTime += paddingDuration * (flightPlanInfo.length - 1);

  flightPathInfo.forEach((flightPath, index) => {
    const connectionStartTime = msAsMoment(moment.utc(flightPath.start?.timestamp).valueOf());
    const connectionEndTime = msAsMoment(moment.utc(flightPath.end?.timestamp).valueOf());
    let totalDurationMinutes = connectionEndTime.diff(connectionStartTime, 'minutes');

    if (isCombinedView && index !== flightPlanInfo.length - 1) totalDurationMinutes += paddingDuration;

    let adjustedConnectionEndTime =
      isCombinedView && index !== flightPlanInfo.length - 1
        ? connectionEndTime.clone().add(paddingDuration, 'minutes')
        : connectionEndTime;
    flightChartSettings.push({
      flightPlanId: flightPlanInfo[index]?.id.toString(),
      chartSettings: {
        start: flightPath.start?.timestamp,
        end: adjustedConnectionEndTime.utc().toString(),
        tickIntervalMinutes: tickMarkMode.tickIntervalMinutes,
        labeledTickMinutesValues: tickMarkMode.labeledTickMinutesValues,
        width: isCombinedView ? (totalDurationMinutes / totalFlightTime) * 93.5 : 93.5
      }
    });
  });

  return flightChartSettings;
};

/**
 * calculates the total flight duration for combined outlook
 */
export const totalFlightDuration = (flightPlanInfo) => {
  let totalTime = 0;
  flightPlanInfo.forEach((flightPlan) => {
    totalTime += Number(flightPlan.totalElapsedTime);
  });
  return totalTime;
};

/**
 * formats a flight duration in minutes to hours and minutes
 * @param flightDuration flight leg duration in minutes
 */
export const formatFlightDuration = (flightDuration: number) => {
  const hours = Math.trunc(flightDuration / 60) ? Math.trunc(flightDuration / 60) + 'hr ' : '';
  const minutes = flightDuration % 60 ? (flightDuration % 60) + 'm' : '';
  return flightDuration === 0 ? '0m' : hours + minutes;
};

/**
 * Generates the FileUpload Container background color based on the event type
 * @param props Container Props
 * @returns Returns the background color of file upload container
 */
export const getFileUploadContainerColor = (props: any): string => {
  if (props.isDragAccept) {
    return DROP_ZONE_ACCEPT_COLOR;
  }
  if (props.isDragReject) {
    return DROP_ZONE_REJECT_COLOR;
  }
  if (props.isDragActive) {
    return DROP_ZONE_ACTIVE_COLOR;
  }
  return DROP_ZONE_GREY_COLOR;
};
