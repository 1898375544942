/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Query to return list of flight plans within the time duration
 */

import moment from 'moment';
import {TWENTY_FOUR_HOURS} from '../../../utils/constants';
import {Query} from '../types';

export interface FlightPlanRaw {
  id: string;
  flightPlanIdentifier: string;
  serialNumber: string;
  departureAirport: string;
  destinationAirport: string;
  tailId: string;
  model: string;
  make: string;
  departureTime: string;
  arrivalTime: string;
  departureTimeLocal: string;
  arrivalTimeLocal: string;
  route: string;
  totalElapsedTime: string;
  endUser: string;
  networkType: string;
  uploadedTstamp: string;
  eventsCount: number;
  copyCount?: number;
  isRowHighlighted?: boolean;
}

export interface FlightPlanInfo {
  flightPlanIdentifier: string;
  endUser: string;
  serialNumber: string;
  tailId: string;
  departureAirport: string;
  departureTime: string;
  departureTimeLocal: string;
  destinationAirport: string;
  arrivalTime: string;
  arrivalTimeLocal: string;
  totalElapsedTime: string;
  networkType: string;
  uploadedTstamp: string;
  id: string;
  eventsCount: number;
  copyCount?: number;
  isRowHighlighted?: boolean;
}

/**
 * Checks if the row is created/updated in last 24 hours
 * @param created_time created time of the row
 * @returns true/false
 */
export const last24HrsUploadCheck = (created_time: string) => {
  const nowDate = moment.utc();
  const msBetweenDates = Math.abs(moment.utc(created_time).valueOf() - nowDate.valueOf());
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
  if (hoursBetweenDates < TWENTY_FOUR_HOURS) {
    return true;
  } else {
    return false;
  }
};
/**
 * Transforms the given raw flight plan data into (required) flight plan info data
 * @param flightPlanRawList flightInfoRaw values from the api
 * @returns FlightPlanInfo Transformed flight info
 */
const transformRawFlightPlan = (flightPlanRawList: FlightPlanRaw[] | null): FlightPlanInfo[] | null => {
  if (!flightPlanRawList) return null;
  const FlightPlanInfo: FlightPlanInfo[] = flightPlanRawList.map((flightPlanRawRow) => ({
    flightPlanIdentifier: flightPlanRawRow.flightPlanIdentifier,
    endUser: flightPlanRawRow?.endUser,
    serialNumber: flightPlanRawRow.serialNumber,
    tailId: flightPlanRawRow.tailId,
    departureAirport: flightPlanRawRow.departureAirport,
    departureTime: flightPlanRawRow.departureTime,
    departureTimeLocal: flightPlanRawRow.departureTimeLocal,
    destinationAirport: flightPlanRawRow.destinationAirport,
    arrivalTime: flightPlanRawRow.arrivalTime,
    arrivalTimeLocal: flightPlanRawRow.arrivalTimeLocal,
    totalElapsedTime: convertMinutesToHourAndMinutes(flightPlanRawRow.totalElapsedTime),
    networkType: flightPlanRawRow.networkType,
    uploadedTstamp: flightPlanRawRow.uploadedTstamp,
    id: flightPlanRawRow.id,
    eventsCount: flightPlanRawRow.eventsCount,
    copyCount: flightPlanRawRow.copyCount,
    isRowHighlighted: last24HrsUploadCheck(flightPlanRawRow.uploadedTstamp)
  }));
  return FlightPlanInfo;
};

/**
 * Convert Minutes(72) to Hours and Minutes(1h 12m)
 * @param timeMinutes - Time in Minutes
 * @returns Time in Hour and Minutes
 */
const convertMinutesToHourAndMinutes = (timeMinutes: string): string => {
  if (Number(timeMinutes) !== 0) {
    const hours = Math.trunc(Number(timeMinutes) / 60);
    const minutes = Number(timeMinutes) % 60;
    let formattedTime = hours !== 0 ? hours + 'h' : '';
    formattedTime = minutes !== 0 ? formattedTime + ' ' + minutes + 'm' : formattedTime;
    return formattedTime;
  } else {
    return '--';
  }
};

const flightPlanQuery: Query<FlightPlanInfo[]> = {
  route: 'connectivityPlanner/flightPlans',
  transform: transformRawFlightPlan
};

export default flightPlanQuery;
