/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Satellite Beam Number Used Query
 */

import { splitString } from '../../../utils/MapUtil';
import {Query} from '../types';

export enum HandoverStatusCode {
  failure,
  success,
  connected
}

export interface ISatelliteHealthData {
  beam: number;
  satellite: string;
  tstamp: string;
  handoverStatus: string | HandoverStatusCode;
}

export const mapStatusCode = (statusStr: string | undefined | null): HandoverStatusCode | null => {
  switch (statusStr) {
    case 'Success':
      return HandoverStatusCode.success;
    case 'Failure':
      return HandoverStatusCode.failure;
    case undefined:
    case null:
      return HandoverStatusCode.connected;
  }
  return null;
};

const transformRawSatelliteHealthInfo = (
  satelliteHealthRawData: ISatelliteHealthData[] | null
): ISatelliteHealthData[] | null => {
  if (!satelliteHealthRawData) return null;

  const satelliteHealthData = satelliteHealthRawData.map((row: ISatelliteHealthData) => ({
    ...row,
    handoverStatus: mapStatusCode(row.handoverStatus as string),
    tstamp: splitString(row.tstamp, '.', 0)
  }));
  return satelliteHealthData;
};

export const useSatelliteHealthQuery: Query<ISatelliteHealthData[]> = {
  route: 'flightDetails/satelliteHealth',
  transform: transformRawSatelliteHealthInfo
};
