/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: FilterButton styles
 */

import React from 'react';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import styled from '@emotion/styled';
import StyledButton from '../../common/StyledButton';

export interface WatchListSelectorButtonProps {
  populated: boolean;
  getFullElementId: (name: string, type: string) => string;
  open: boolean;
  onOpen: (open: boolean, event: any) => void;
  disabled?: boolean;
}

const StyledLabel = styled.div`
  font-weight: 600;
  padding-left: 8px;
  white-space: nowrap;
  @media only screen and (max-width: 875px) {
    display: none;
  }
`;

const WatchListSelectorButton: React.FC<WatchListSelectorButtonProps> = ({
  populated,
  disabled,
  getFullElementId,
  onOpen,
  open
}) => {
  const handleClick = ($event: any) => {
    onOpen(!open, $event);
  };

  return (
    <StyledButton
      id={getFullElementId('togglePicker', 'button')}
      selected={open}
      populated={populated}
      disabled={disabled}
      onClick={($event) => handleClick($event)}
    >
      <>
        {open || populated ? <StarIcon sx={{fontSize: 24}} /> : <StarOutlineIcon />}
        <StyledLabel>Watchlist</StyledLabel>
      </>
    </StyledButton>
  );
};

export default WatchListSelectorButton;
