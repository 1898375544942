/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Global styles for the application
 */

import {css} from '@emotion/react';
import {keyframes} from '@mui/system';
import {ABOVE_TARGET_RGB_COLOR} from './Colors';

const toolTipAlignment = `
   div[role=tooltip] {
     z-index: 9999;
     opacity: 1 !important;
     &> div {
       padding: 0;
     }
   }
   div[data-popper-placement*="right"] {
    top: -2px !important;
    &> div {
      left: 0px;
    }
  }
   div[data-popper-placement*="left"] {
     top: -2px !important;
     &> div {
       right: 0px;
     }
   }
   div[data-popper-placement="bottom-start"] {
     &> div {
       right: 10px;
     }
   }
   div[data-popper-placement="bottom-end"] {
     &> div {
       left: 15px;
     }
   }
   div[data-popper-placement="top-start"] {
     &> div {
       right: 10px;
     }
   }
   div[data-popper-placement="top-end"] {
     &> div {
       left: 15px;
     }
   }
   .chart-pie {
     .highcharts-label-box.highcharts-tooltip-box {
       stroke: #000;
     }
   }
   .highcharts-label-box.highcharts-tooltip-box {
     stroke: none;
   }
 
   .highcharts-tooltip-container {
     z-index: 10 !important;
   }
 
   .highcharts-tooltip-container svg.highcharts-root {
     -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, .3));
     filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, .3));
   }
 `;

const aircraftConnectedPulse = keyframes`
0% {
  boxShadow: 0 0 0 0 rgb(${ABOVE_TARGET_RGB_COLOR}, 0.7);
}
70% {
  boxShadow: 0 0 0 16px rgb(${ABOVE_TARGET_RGB_COLOR}, 0);
}
100% {
  boxShadow: 0 0 0 0 rgb(${ABOVE_TARGET_RGB_COLOR}, 0);
}
`;
const aircraftConnectedPulseSmall = keyframes` 
  0% {
    boxShadow: 0 0 0 0 rgb(${ABOVE_TARGET_RGB_COLOR}, 0.7);
  }
  70% {
    boxShadow: 0 0 0 8px rgb(${ABOVE_TARGET_RGB_COLOR}, 0);
  }
  100% {
    boxShadow: 0 0 0 0 rgb(${ABOVE_TARGET_RGB_COLOR}, 0);
  }
`;

const globalStyle = css`
  body {
    -webkit-min-device-pixel-ratio: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    background-color: #efefef;
    -webkit-font-smoothing: antialiased;
    ${toolTipAlignment}
    ${aircraftConnectedPulse}
     ${aircraftConnectedPulseSmall}
  }
`;

export default globalStyle;
