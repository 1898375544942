/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Defines config items for List View Tabs
 *
 */
import {FlightIcon, FlightStatusIcon, AircraftIcon} from '@viasat/insights-components';
import {SvgIcon} from '@mui/material';
import {showAslvTab, showTailListTab} from '../../utils/config';
export interface TabDefinition {
  page: string;
  route: string;
  Icon: typeof SvgIcon;
  text: string;
  id: string;
  tabName: string;
  title: string;
  shown: boolean;
  disabled: boolean;
  internalOnly: boolean;
  hideTitleCount?: boolean;
}
export type TabDefinitions = TabDefinition[];
export type TabDefinitionsSetter = (TabDefinitions) => void;

const TLV_TAB_DEFINITION = {
  page: 'tail',
  route: '/lists/tail',
  Icon: AircraftIcon,
  text: 'tab.tails.link',
  id: 'listsTail',
  tabName: 'tailList',
  title: 'Tail List',
  shown: true,
  disabled: false,
  internalOnly: false,
  hideTitleCount: false
};

const ASLV_TAB_DEFINITION = {
  page: 'aircraft-status',
  route: '/lists/aircraft-status',
  Icon: FlightStatusIcon,
  text: 'tab.aircraft_status.link',
  id: 'aircraftStatus',
  tabName: 'aircraftStatus',
  title: 'Aircraft Status',
  shown: true,
  disabled: false,
  internalOnly: false,
  hideTitleCount: false
};

const tabDefinitions: TabDefinitions = [
  {
    page: 'flight',
    route: '/lists/flight',
    Icon: FlightIcon,
    text: 'tab.flights.link',
    id: 'listsFlight',
    tabName: 'flightList',
    title: 'Flight List',
    shown: true,
    disabled: false,
    internalOnly: false
  }
];
if (showTailListTab) {
  tabDefinitions.push(TLV_TAB_DEFINITION);
}

if (showAslvTab) {
  tabDefinitions.push(ASLV_TAB_DEFINITION);
}

export default tabDefinitions;
