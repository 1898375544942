/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 * Description: Save departure time styles and interfaces
 */

import styled from '@emotion/styled';

import {UPLOAD_SYNC_ICON_COLOR} from '../common/theme/Colors';

export const FileUploadIndicator = styled.div`
  position: relative;
  float: right;
  display: inline-flex;
  align-items: flex-end;
  right: 20px;
  margin-top: 2px;
  .file-upload-sync {
    color: ${UPLOAD_SYNC_ICON_COLOR};
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
`;

export interface SaveDepartureTimeProps {
  idBase: string;
  disabled?: boolean;
  isDepartureTimeChanged: boolean;
  setIsDepartureTimeChanged: (isChanged: boolean) => void;
}
