/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Card for the Flight History Map
 */

import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import styled from '@emotion/styled';
import {Polyline} from '@react-google-maps/api';
import {ABOVE_TARGET_COLOR, FLIGHT_DETAILS_LIVE_BG_COLOR, MAP_POPUP_DEFAULT_BORDER} from '../common/theme/Colors';
import {FLIGHT_DETAILS_MAP_MIN_ZOOM, MAP_CENTER, MAP_DEFAULT_ZOOM} from '../../utils/constants';
import MapControls from '../mapView/MapControls';
import {MapViewContext} from '../../utils/MapUtil';
import MapStateContainer from '../mapView/MapStateContainer';
import {ChartCard} from './SummaryMetricsPage';

const FlightHistoryMapContainer = styled.div`
  background-color: ${FLIGHT_DETAILS_LIVE_BG_COLOR};
  display: flex;
  justify-content: row;
  user-select: text;
  cursor: text;
  height: 303px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  margin-top: -10px;
  overflow: hidden;
  .gm-style > div,
  .gm-style > div > div:last-child {
    overflow: hidden;
  }
`;

export interface FlightHistoryMapProps {
  isLoading: boolean;
  flightPathData: any[];
  hoverLineContainerRef: any;
  chartTimeSettings: any;
  liveFlightOffset: {startOffSet: number; unit: number};
  parentLeftOffset: number;
  setMinimize: () => void;
  isMinimized: boolean;
  draggableProps?: any;
}

const FlightHistoryMapCard: React.FC<FlightHistoryMapProps> = (mapCardProps) => {
  const intl = useIntl();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(MAP_DEFAULT_ZOOM);

  const mapDivRef = useRef(null);

  const flightPathDetails = mapCardProps?.flightPathData;

  let initialBoundList;
  const contextOptions = {
    minZoom: 1.5
  };

  return (
    <ChartCard
      id="summaryMetricsFlightHistory"
      isLoading={mapCardProps.isLoading}
      title="Flight History"
      helpText={intl.formatMessage({id: 'summary_metrics.flight_path_history_card.title_tooltip'})}
      borderColor={MAP_POPUP_DEFAULT_BORDER}
      draggableProps={mapCardProps.draggableProps}
    >
      <FlightHistoryMapContainer>
        <MapStateContainer
          getFullElementId={(name, type) => `flightDetails__${name}-${type}`}
          zoom={zoom}
          setZoom={setZoom}
          center={MAP_CENTER}
          isLoading={mapCardProps.isLoading}
          mapDivRef={mapDivRef}
          mapOptions={contextOptions}
          mapContainerStyle={{width: '100%', height: '100%'}}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        >
          <MapControls
            mapControlContext={MapViewContext.FLIGHT_DETAILS_MAP}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            mapDivRef={mapDivRef}
            popupFaFlightId={null}
            setPopup={() => {}}
            setHoveredFaFlightId={null}
            minZoom={FLIGHT_DETAILS_MAP_MIN_ZOOM}
            boundList={initialBoundList}
            searchBoxDisabled={true}
            disableTailTagConfig={true}
            disableOverlayOptions={true}
            setMinimize={() => mapCardProps.setMinimize()}
          />
          {flightPathDetails?.map((flightPath, idx) => {
            return (
              <Polyline
              key={`${idx}-polyline`}
                path={[{lat: flightPath.startLatitude, lng: flightPath.startLongitude},
                  {lat: flightPath.lastLatitude, lng: flightPath.lastLongitude}]}
                options={{
                  strokeColor: ABOVE_TARGET_COLOR,
                  strokeWeight: 1,
                  geodesic: true
                }}
              />
            );
          })}
        </MapStateContainer>
      </FlightHistoryMapContainer>
    </ChartCard>
  );
};
export default FlightHistoryMapCard;
