/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utility functions for use in chart components
 */

import Highcharts from 'highcharts';

/**
 * Function that will be called by Highcharts to get element IDs for it to use
 * @param highchartsIdBase prefix upon which the ID will be built
 * @note See https://github.com/highcharts/highcharts-react/issues/157#issuecomment-526192060
 */
export const getHighchartsId = (highchartsIdBase: string) => {
  var idCounter = 0;
  return () => {
    return `highcharts-${highchartsIdBase}-${idCounter++}`;
  };
};

/**
 * Function that will be called by Highcharts load to set legend item series to hidden (deselected)
 *
 * @param series series data to check for visibility
 * @param boolean true will set all legend item series in the chart to hidden (deselected)
 * @param legendItemsToHideOnLoad it will set the legend item series in the chart to hidden (deselected) for any series in the array
 * @note if noDataOptions.Enabled, this will show RenderNoDataToDisplay w/NO_DATA_SUBTITLE_TEXT_HIDDEN
 */
export const setLegendItemsVisibility = (series: any[], hidden: boolean, legendItemsToHideOnLoad: string[] = []) => {
  series.forEach((chartSeries) => {
    if ((hidden || legendItemsToHideOnLoad.includes(chartSeries.name)) && chartSeries.visible) {
      chartSeries.hide();
    }
  });
};

/**
 * Function to create a tooltip hide closure to pass into a event handler
 *
 * @param id id string
 * @param chart chart instance
 * @returns function to clear out hanging tooltips
 */
export const createTooltipHider = (id: string, chart: any) => {
  const hider = () => chart && chart.tooltip?.hide();
  const target = document.getElementById(id);
  const cleanup = () => {
    if (target) {
      target.removeEventListener('mouseleave', hider);
    }
  };
  if (target) {
    target.addEventListener('mouseleave', hider);
  }
  return cleanup;
};

/**
 * Function to format chart data
 *
 * @param intl react intl object used for international translation
 * @param config chart configuration
 * @returns formatted chart configuration
 */
export const formatConfig = (intl: any, config: any) => {
  if (Array.isArray(config) || typeof config !== 'object') {
    return config;
  }
  return Object.keys(config || {}).reduce(
    (memo, key) =>
      key === 'tooltip'
        ? {...memo, [key]: config[key](formatI18n.bind(this))}
        : {...memo, [key]: formatI18n(intl, config[key])},
    {} as any
  );
};

/**
 * Function to internationalize/translate strings
 * @param intl react intl object used for international translation
 * @param value string of any language
 * @returns translated value
 */
const formatI18n = (intl: any, value: any) => {
  const re: RegExp = new RegExp(/#![_.a-zA-Z0-9]*/g);
  if (value && Array.isArray(value)) {
    return value.map((v) => formatConfig(intl, v));
  }
  if (value && value.length > 0 && typeof value === 'string') {
    return ((value.match(re) || []) as string[]).reduce(
      (memo, id) => memo.replace(id, intl.formatMessage({id: id.replace('#!', '')})),
      value
    );
  }
  return value;
};

/**
 * Reflows highcharts while printing
 * @param width chart width
 */
export const reflowChartCardsForPrint = (width: string) => {
  const chartCards = document.getElementsByClassName('chart-card');
  for (let i = 0; i < chartCards.length; i++) {
    chartCards[i].setAttribute('style', 'width:' + width);
  }
  if (Highcharts.charts) {
    Highcharts.charts.forEach((chart) => {
      chart?.reflow();
    });
  } else {
    Highcharts.charts.forEach((chart) => {
      chart?.reflow();
    });
  }
};
