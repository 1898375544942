/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Generic notification container.
 */

import React from 'react';
import styled from '@emotion/styled';
import Close from '@mui/icons-material/Close';
import NotificationIcon from '@mui/icons-material/ErrorOutline';

const NotificationStyleContainer = styled.div<{show: boolean}>`
  font-family: 'Source Sans Pro';
  background: #eaeaea;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: ${(props) => (props.show ? '40px' : '0px')};
  @media only screen and (max-width: 1178px) {
    height: ${(props) => (props.show ? '52px' : '0px')};
  }
  @media only screen and (max-width: 548px) {
    height: ${(props) => (props.show ? '68px' : '0px')};
  }
`;

const NotificationMessage = styled.div`
  position: relative;
  margin: 0 30px 0 14px;
  width: 100%;
`;

const NotificationStyleIcon = styled(NotificationIcon)`
  width: 16px;
  color: #444;
  height: 16px;
  float: left;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin: 16px 0px 16px 16px;
`;

const HideNotificationMessage = styled(Close)`
  margin-right: 16px;
  cursor: pointer;
  padding: 4px;
  width: 16px;
  height: 16px;
  font-size: 14px;
  display: block;
  align-self: center;
`;

interface NotificationProps {
  id: string;
  show: boolean;
  handleClick: () => void;
  message: any;
}

const NotificationContainer = ({message, id, handleClick, show}: NotificationProps) => (
  <NotificationStyleContainer show={show}>
    <NotificationStyleIcon />
    <NotificationMessage id={id}>{message}</NotificationMessage>
    <HideNotificationMessage onClick={() => handleClick()} />
  </NotificationStyleContainer>
);

export default NotificationContainer;
