/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description:
 * Stacked Column Chart
 */

import Highcharts from 'highcharts';
import HC_patternFill from 'highcharts-pattern-fill';

import React from 'react';
import ToolTip from './ToolTip/ToolTip';
import ReactDOMServer from 'react-dom/server';
import {isEqual} from 'lodash';
import {DataToolTip} from '@viasat/insights-components';

import {ChartType, ILegendTooltip, IStackedColumnChart} from './types';

import {getHighchartsId, setLegendItemsVisibility} from './chartUtils';
import {checkSeriesHasData, checkShowEmptyAxis, renderCheckNoDataToDisplay, renderHatchOverlay} from './RenderOverlay';

import {AXIS_TEXT, CHART_TOOLTIP_BG, CHART_TOOLTIP_TEXT, CHART_TOOLTIP_BORDER} from '../../theme/Colors';
import {StackedColumnChartStyledContainer} from './ChartStyles';
import {isNil} from 'lodash';
import {FormattedMessage} from 'react-intl';
import {
  CHART_NO_DATA_X_AXIS,
  CHART_NO_DATA_Y_AXIS,
  TOOLTIP_RIGHT_ALIGN_FUDGE_FACTOR
} from '../../../../utils/constants';
import {formatToolTipText} from '../../../../utils/TooltipUtil';
import BoldSpan from '../../BoldSpan';

HC_patternFill(Highcharts);

class StackedColumnChart extends React.Component<IStackedColumnChart, any> {
  ref = null;
  config = {};
  constructor(props) {
    super(props);
    this.state = {
      tooltipTitle: '',
      tooltipOpen: false,
      eventX: 0
    };
  }

  componentDidMount() {
    const {id, config} = this.generateChart(this.props);
    this.ref = Highcharts.chart(id, config);
    this.config = config.series;
  }
  render() {
    const {id} = this.props;
    const {config} = this.generateChart(this.props);

    if (!isEqual(this.config, config.series) && this.ref) {
      this.config = config.series;
      Highcharts.chart(id, config).update(config);
    }

    const validTooltip: boolean = !isNil(this.state.tooltipTitle) && this.state.tooltipTitle.length > 0;
    const currentTooltip: ILegendTooltip | undefined = validTooltip
      ? this.props.legendTooltips.find((tooltip) => tooltip.title === this.state.tooltipTitle)
      : undefined;
    const centerAlignArrow = currentTooltip ? this.state.eventX + 125 < window.innerWidth : true;
    const tooltipX = currentTooltip ? currentTooltip.x : 0;
    const tooltipY = currentTooltip ? currentTooltip.y : 0;
    const tooltipArrowX = currentTooltip ? currentTooltip.arrowX : 0;

    return (
      <DataToolTip
        arrowPadding={
          centerAlignArrow ? '0px' : `${TOOLTIP_RIGHT_ALIGN_FUDGE_FACTOR - (window.innerWidth - this.state.eventX)}px`
        }
        arrowXOffset={tooltipArrowX}
        id={`chart-tooltip`}
        placement="top"
        arrow={validTooltip}
        title={
          validTooltip ? (
            <div style={{lineHeight: '18px', margin: '11px 13px', maxWidth: '400px'}}>
              <BoldSpan>
                <FormattedMessage id={this.state.tooltipTitle}>{formatToolTipText}</FormattedMessage>
              </BoldSpan>
            </div>
          ) : (
            <></>
          )
        }
        open={this.state.tooltipOpen}
        popperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [tooltipX, tooltipY]
              }
            }
          ]
        }}
      >
        <StackedColumnChartStyledContainer className="chart-container" data-test="stacked-column-chart" id={id} />
      </DataToolTip>
    );
  }

  private generateChart = (props: IStackedColumnChart) => {
    const {
      id,
      palette,
      yAxisFormat,
      series,
      patternDefinition,
      legend,
      legendItemStyle,
      tooltip,
      xAxisTickInterval,
      xAxisFormatter,
      xAxisLabelXOffset,
      xAxisTickPositions,
      xAxisMinorTickInterval,
      xAxisLabel,
      xAxisMin,
      xAxisMax,
      yAxis,
      sharedTooltip,
      highchartsIdBase,
      noDataOptions,
      liveMask,
      legendTooltips,
      hideAllLegendItemsOnLoad,
      legendItemsToHideOnLoad,
      paddings,
      marginLeft,
      marginRight,
      spacingLeft,
      spacingRight,
      customXAxis,
      stackingOption,
      customLegend,
      customStackLabels,
      states,
      customLegendClick
    } = props;
    const setTooltip = (tooltipOpen: boolean, eventX: number, tooltipTitle: string) => {
      this.setState({tooltipOpen, eventX, tooltipTitle});
    };

    const xAxisLabelFormat = {
      x: xAxisLabelXOffset,
      y: 18,
      style: {
        fontSize: '10px',
        fontWeight: '600',
        color: AXIS_TEXT,
        whiteSpace: 'nowrap',
        textOverflow: 'none'
      }
    };

    const stackedColumnChartConfig = {
      chart: {
        type: ChartType.COLUMN,
        marginLeft: marginLeft ? marginLeft : undefined,
        marginRight: marginRight ? marginRight : undefined,
        spacingLeft: spacingLeft ? spacingLeft : undefined,
        spacingRight: spacingRight ? spacingRight : undefined,
        events: {
          load() {
            document.querySelectorAll('.highcharts-credits').forEach((el) => el.remove());
            setLegendItemsVisibility(this.series, hideAllLegendItemsOnLoad, legendItemsToHideOnLoad);
          },
          render() {
            const hasSeriesData = checkSeriesHasData(this.series);
            // Handle No Data to Display rendering
            renderCheckNoDataToDisplay(this, id, noDataOptions, hasSeriesData);

            if (liveMask && hasSeriesData) {
              renderHatchOverlay(this, id);
            }
            this.legend.allItems.forEach(({legendItem}, idx) =>
              legendItem
                .on('mouseover', (event) => {
                  if (legendTooltips[idx]) {
                    setTooltip(true, event.x, legendTooltips[idx].title);
                  }
                })
                .on('mouseout', () => {
                  if (legendTooltips[idx]) {
                    setTooltip(false, 0, '');
                  }
                })
            );
          }
        }
      },
      exporting: {enabled: false},
      title: {text: ''},
      xAxis: customXAxis
        ? customXAxis
        : ({
            type: 'datetime' as Highcharts.AxisTypeValue,
            title: {
              text: xAxisLabel
            },
            labels: {
              formatter: function () {
                if (xAxisFormatter) return xAxisFormatter ? xAxisFormatter(this.value) : undefined;
              },
              ...xAxisLabelFormat
            },
            tickInterval: xAxisTickInterval,
            minorTicks: true,
            startOnTick: false,
            endOnTick: false,
            tickPositions: xAxisTickPositions ? xAxisTickPositions : undefined,
            gridLineWidth: 1,
            tickWidth: xAxisTickInterval ? 1 : 0,
            tickLength: xAxisTickInterval || xAxisTickPositions ? 8 : 10,
            minorGridLineWidth: xAxisMinorTickInterval ? 0 : undefined,
            minorTickInterval: xAxisMinorTickInterval ? xAxisMinorTickInterval : undefined,
            minorTickLength: xAxisMinorTickInterval ? 6 : undefined,
            minorTickWidth: xAxisMinorTickInterval ? 1 : undefined,
            min: xAxisMin,
            max: xAxisMax,
            showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_X_AXIS)
          } as Highcharts.XAxisOptions),

      defs: patternDefinition,
      yAxis: yAxis.map(
        (axis) =>
          ({
            title: {
              text: axis.label
            },
            labels: axis.yAxisFormatter
              ? {
                  formatter() {
                    return axis.yAxisFormatter(this);
                  }
                }
              : {},
            stackLabels: customStackLabels
              ? customStackLabels
              : {
                  enabled: false
                },
            min: !Number.isNaN(axis.min) ? axis.min : null,
            max: yAxisFormat === 'percentage' ? 100 : null,
            showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_Y_AXIS),
            opposite: axis.opposite
          } as Highcharts.YAxisOptions)
      ),
      legend: {
        enabled: legend,
        itemStyle: legendItemStyle ? legendItemStyle : undefined,
        reversed: true,
        useHTML: true,
        labelFormatter: function () {
          const currentName = this.name;
          if (currentName in customLegend) {
            return customLegend[currentName];
          }
          return currentName;
        }
      },
      tooltip: tooltip
        ? {
            outside: true,
            backgroundColor: CHART_TOOLTIP_BG,
            borderRadius: 4,
            borderColor: CHART_TOOLTIP_BORDER,
            shared: sharedTooltip,
            shape: 'none' as Highcharts.TooltipShapeValue,
            style: {
              color: CHART_TOOLTIP_TEXT
            },
            hideDelay: 0.2,
            useHTML: true,
            formatter() {
              return ReactDOMServer.renderToStaticMarkup(<ToolTip {...tooltip(this)} />);
            }
          }
        : undefined,
      plotOptions: {
        column: {
          stacking: stackingOption ? stackingOption : ('percent' as Highcharts.OptionsStackingValue),
          borderColor: null,
          states: states
            ? states
            : {
                hover: {
                  brightness: 0.1
                },
                inactive: {
                  opacity: 0.2
                }
              }
        },
        series: {
          animation: false,
          pointPadding: paddings.pointPadding,
          groupPadding: paddings.groupPadding,
          events: {
            legendItemClick: customLegendClick
              ? function (event) {
                  customLegendClick(event);
                }
              : undefined
          }
        }
      },
      series: series,
      responsive: this.prepareResponsiveObject()
    };
    // Force the IDs that Highcharts uses to be deterministic
    if (highchartsIdBase) {
      Highcharts.uniqueKey = getHighchartsId(highchartsIdBase);
    }
    Highcharts.setOptions({colors: palette});

    return {
      id,
      config: stackedColumnChartConfig
    };
  };

  private prepareResponsiveObject() {
    return {
      rules: [
        {
          condition: {maxWidth: 480},
          chartOptions: {
            chart: {
              height: 410
            },
            legend: {
              align: 'right' as Highcharts.AlignValue,
              x: 0
            }
          }
        }
      ]
    };
  }
}

export default StackedColumnChart;
