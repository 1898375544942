/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Map helper functions
 */

import {FleetMapState} from '../../../store/reducers/FleetMapReducer.js';
import {IStore} from '../../../store/types.js';
import {
  MAP_TX_INHIBIT_OVERLAY_COLOR,
  MAP_BEAMS_OUTLINE_COLOR,
  MAP_BEAMS_SELECTED_OUTLINE_COLOR,
  MAP_BEAM_OVERLAY_COLOR,
  MAP_KA_OVERLAY_COLOR,
  MAP_KU_OVERLAY_COLOR,
  WHITE,
  MAP_KA_REGIONAL_COVERAGE_STROKE_COLOR,
  MAP_KU_REGIONAL_COVERAGE_STROKE_COLOR,
  MAP_BEAMS_OUTAGE_COLOR,
  MAP_BEAMS_IMPAIRED_COLOR
} from '../../common/theme/Colors';
import kuCoverage from '../overlays/ku.json';
import kaCoverage from '../overlays/ka_coverage_global.json';
import namerServicePlanRegion from '../overlays/Viasat_BizAv_Ka_Regional_NAM.json';
import eurmesServicePlanRegion from '../overlays/Viasat_BizAv_Ka_Regional_EURMES.json';
import brazilServicePlanRegion from '../overlays/Viasat_BizAv_Ka_Brazil.json';
import conusCanadaServicePlanRegion from '../overlays/Viasat_BizAv_Ku_CONUS_Canada_Regional_Coverage.json';
import {
  MAP_BEAMS_IMPAIRED_HOVERED_Z_INDEX,
  MAP_BEAMS_IMPAIRED_SELECTED_Z_INDEX,
  MAP_BEAMS_IMPAIRED_Z_INDEX,
  MAP_BEAMS_INTERNAL_HOVERED_Z_INDEX,
  MAP_BEAMS_INTERNAL_SELECTED_Z_INDEX,
  MAP_BEAMS_INTERNAL_Z_INDEX,
  MAP_BEAMS_OUTAGE_HOVERED_Z_INDEX,
  MAP_BEAMS_OUTAGE_SELECTED_Z_INDEX,
  MAP_BEAMS_OUTAGE_Z_INDEX
} from '../../../utils/constants';
import {
  SatelliteBeamIncident,
  SatelliteBeamIncidentEvent
} from '../../../store/queries/fleetMap/satelliteBeamIncidentEvents';
import {Feature} from 'geojson';

const BEAM_OVERLAY_NETWORK_TYPES = ['internal', 'KuBeams'];
const CONNECTED_NETWORK_TYPES = ['internal-connected', 'ku-connected'];

export enum ESatBeamImpactType {
  NONE = 'none',
  OUTAGE = 'Outage',
  IMPAIRED = 'Impairment'
}

export enum ESatBeamEventType {
  NONE = '',
  HOVERED = '-hovered',
  CLICKED = '-clicked'
}

export enum ESatBeamCauseType {
  WEATHER = 'Weather',
  HW_FAULT = 'Hardware Fault',
  SW_FAULT = 'Software Fault',
  CFG_FAULT = 'Configuration Fault',
  MAINTAENANCE = 'Maintenance',
  OTHER = 'Other'
}

export enum ENetworkStyle {
  Ka = 'ka',
  ViasatKa = 'viasatKa',
  GxKa = 'gxKa',
  Ku = 'ku',
  Ean = 'ean'
}

export enum ESatBeamStyle {
  Internal = 'internal',
  InternalHovered = 'internal-hovered',
  InternalClicked = 'internal-clicked',
  InternalImpaired = 'internal-impaired',
  InternalImpairedHovered = 'internal-impaired-hovered',
  InternalImpairedClicked = 'internal-impaired-clicked',
  InternalOutage = 'internal-outage',
  InternalOutageHovered = 'internal-outage-hovered',
  InternalOutageClicked = 'internal-outage-clicked'
}
const BEAM_OVERLAY_STYLE_MAPPING = {
  [ESatBeamStyle.InternalOutageClicked]: {
    fillColor: MAP_BEAMS_OUTAGE_COLOR,
    fillOpacity: 0.48,
    strokeColor: MAP_BEAMS_OUTAGE_COLOR,
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: false,
    zIndex: MAP_BEAMS_OUTAGE_SELECTED_Z_INDEX
  },
  [ESatBeamStyle.InternalOutageHovered]: {
    fillColor: MAP_BEAMS_OUTAGE_COLOR,
    fillOpacity: 0.48,
    strokeColor: MAP_BEAMS_OUTAGE_COLOR,
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    zIndex: MAP_BEAMS_OUTAGE_HOVERED_Z_INDEX
  },
  [ESatBeamStyle.InternalOutage]: {
    fillColor: MAP_BEAMS_OUTAGE_COLOR,
    fillOpacity: 0.16,
    strokeColor: MAP_BEAMS_OUTAGE_COLOR,
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    zIndex: MAP_BEAMS_OUTAGE_Z_INDEX
  },
  [ESatBeamStyle.InternalImpairedClicked]: {
    fillColor: MAP_BEAMS_IMPAIRED_COLOR,
    fillOpacity: 0.48,
    strokeColor: MAP_BEAMS_IMPAIRED_COLOR,
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: false,
    zIndex: MAP_BEAMS_IMPAIRED_SELECTED_Z_INDEX
  },
  [ESatBeamStyle.InternalImpairedHovered]: {
    fillColor: MAP_BEAMS_IMPAIRED_COLOR,
    fillOpacity: 0.48,
    strokeColor: MAP_BEAMS_IMPAIRED_COLOR,
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    zIndex: MAP_BEAMS_IMPAIRED_HOVERED_Z_INDEX
  },
  [ESatBeamStyle.InternalImpaired]: {
    fillColor: MAP_BEAMS_IMPAIRED_COLOR,
    fillOpacity: 0.16,
    strokeColor: MAP_BEAMS_IMPAIRED_COLOR,
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    zIndex: MAP_BEAMS_IMPAIRED_Z_INDEX
  },
  [ESatBeamStyle.InternalClicked]: {
    fillColor: WHITE,
    fillOpacity: 0.9,
    strokeColor: MAP_BEAMS_SELECTED_OUTLINE_COLOR,
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: false,
    zIndex: MAP_BEAMS_INTERNAL_SELECTED_Z_INDEX
  },
  [ESatBeamStyle.InternalHovered]: {
    fillColor: WHITE,
    fillOpacity: 0.9,
    strokeColor: MAP_BEAMS_OUTLINE_COLOR,
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    zIndex: MAP_BEAMS_INTERNAL_HOVERED_Z_INDEX
  },
  [ESatBeamStyle.Internal]: {
    fillColor: WHITE,
    fillOpacity: 0.3,
    strokeColor: MAP_BEAMS_OUTLINE_COLOR,
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    zIndex: MAP_BEAMS_INTERNAL_Z_INDEX
  }
};

/**
 * Map ImpactType to EventStyle
 */
const ImpactEventStyleMapping: Record<string, string> = {
  [ESatBeamImpactType.OUTAGE]: ESatBeamStyle.InternalOutage,
  [ESatBeamImpactType.IMPAIRED]: ESatBeamStyle.InternalImpaired,
  [ESatBeamImpactType.NONE]: ESatBeamStyle.Internal
};

/**
 * Gets a list of Ka Geo Json coordinates
 * @returns An array of geo json coordinates for Ka networks
 */
export const getKaOverlays = (): any => {
  return kaCoverage;
};

/**
 * Gets a list of Ku Geo Json coordinates
 * @returns An array of geo json coordinates for Ku networks
 */
export const getKuOverlays = (): any[] => {
  return [kuCoverage];
};

/**
 * Returns the service plan region based coverage
 * @param region Servie Plan Region
 * @returns Coverage Blob based on the region
 */
export const getServicePlanCoverage = (region: string): any => {
  switch (region) {
    case 'NAMER':
      return namerServicePlanRegion;
    case 'EURMES':
      return eurmesServicePlanRegion;
    case 'BRAZIL':
      return brazilServicePlanRegion;
    case 'CONUSCanada':
      return conusCanadaServicePlanRegion;
  }
};

/**
 * Gets the style configuration for an overlay network type
 * @param network Network type: ku or ka
 * @returns The overlay styling
 */
export const getNetworkOverlayStyle = (network: string): google.maps.Data.StyleOptions => {
  if (network === 'ka-regional') {
    return {
      fillColor: MAP_KA_OVERLAY_COLOR,
      strokeWeight: 1,
      strokeColor: MAP_KA_REGIONAL_COVERAGE_STROKE_COLOR,
      strokeOpacity: 1,
      fillOpacity: 0.2,
      clickable: false,
      zIndex: 0
    };
  }
  if (network === 'ku-regional') {
    return {
      fillColor: MAP_KU_OVERLAY_COLOR,
      strokeWeight: 1,
      strokeColor: MAP_KU_REGIONAL_COVERAGE_STROKE_COLOR,
      strokeOpacity: 1,
      fillOpacity: 0.2,
      clickable: false,
      zIndex: 0
    };
  }
  if (network === 'ku') {
    return {
      fillColor: MAP_KU_OVERLAY_COLOR,
      strokeWeight: 0,
      fillOpacity: 0.15,
      clickable: false,
      zIndex: 0
    };
  }

  if (network === 'ka') {
    return {
      fillColor: MAP_KA_OVERLAY_COLOR,
      strokeWeight: 0,
      fillOpacity: 0.2,
      clickable: false,
      zIndex: 0
    };
  }
  if (network === 'txInhibit') {
    return {
      fillColor: MAP_TX_INHIBIT_OVERLAY_COLOR,
      strokeWeight: 0,
      fillOpacity: 0.4,
      clickable: false,
      zIndex: 0
    };
  }
  // Ku beam overlays doesn't have network property and using the internal beam overlay color for the same
  if (BEAM_OVERLAY_NETWORK_TYPES.includes(network)) {
    return {
      fillColor: WHITE,
      strokeWeight: 1,
      strokeColor: MAP_BEAMS_OUTLINE_COLOR,
      strokeOpacity: 1,
      fillOpacity: 0.3,
      clickable: true,
      zIndex: 0
    };
  }
  if (CONNECTED_NETWORK_TYPES.includes(network)) {
    return {
      fillColor: WHITE,
      strokeWeight: 1,
      strokeColor: MAP_BEAMS_OUTLINE_COLOR,
      strokeOpacity: 1,
      fillOpacity: 0.3,
      clickable: true,
      zIndex: 0
    };
  }
  if (network === 'internal-clicked') {
    return {
      fillColor: WHITE,
      strokeWeight: 3,
      strokeColor: MAP_BEAMS_SELECTED_OUTLINE_COLOR,
      strokeOpacity: 1,
      fillOpacity: 0.9,
      clickable: true,
      zIndex: 10
    };
  }
  if (network === 'internal-hover') {
    return {
      fillColor: WHITE,
      strokeWeight: 1,
      strokeColor: MAP_BEAMS_OUTLINE_COLOR,
      strokeOpacity: 1,
      fillOpacity: 0.9,
      clickable: true,
      zIndex: 11
    };
  }

  return {
    fillColor: MAP_BEAM_OVERLAY_COLOR,
    strokeWeight: 0,
    fillOpacity: 0.2,
    clickable: false,
    zIndex: 0
  };
};

enum ESatBeamProps {
  SAT_BEAM_ID = 'id',
  SATELLITE_ID = 'satelliteId',
  ABBREV = 'abbreviation',
  BEAM_ID = 'beamId',
  DETAILS = 'beamDetails',
  IMPACT = 'impact',
  INCIDENTS = 'incidents'
}

const DEFAULT_BEAM_OVERLAY_STYLE = {
  fillColor: WHITE,
  fillOpacity: 0.3,
  strokeColor: MAP_BEAMS_OUTLINE_COLOR,
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  zIndex: MAP_BEAMS_INTERNAL_Z_INDEX
};
/**
  * Overrides styles for a feature if it is being hovered
  * @param map Map object
+ * @param selectedBeamsRef Ref object to retrieve state of satId/beamId
  * @param feature Feature object
+ * @param isHovered true if hovered, otherwise false for mouse-out (default=true)
  */
export const handleOverlayHover = (
  map: google.maps.Map,
  selectedBeamsRef: React.MutableRefObject<ISelectedSatBeam>,
  feature: any,
  isHovered: boolean = true
) => {
  if (['internal', 'internal-connected', 'KuBeams', 'ku-connected'].indexOf(feature.getProperty('network')) === -1)
    return;

  // Retrieve satId, beamId from ref
  const satId = selectedBeamsRef.current ? selectedBeamsRef.current.satelliteId : null;
  const beamId = selectedBeamsRef.current ? selectedBeamsRef.current.beamId : null;

  const activeBeam =
    Boolean(satId) &&
    satId === feature.getProperty(ESatBeamProps.SATELLITE_ID) &&
    Boolean(beamId) &&
    beamId === feature.getProperty(ESatBeamProps.BEAM_ID);

  const currentStyle = activeBeam
    ? getNetworkOverlayStyle('internal-clicked')
    : isHovered
    ? getNetworkOverlayStyle('internal-hover')
    : null;

  currentStyle ? map.data.overrideStyle(feature, currentStyle) : map.data.revertStyle(feature);
};

export interface ISelectedSatBeam {
  satelliteId?: number | undefined;
  beamId?: number | undefined;
  satelliteName?: string;
  networkType: string;
  id?: string;
}
/**
 * Loads the saved selected satellite beam id (if any) from store
 * @returns parsed selected satellite id/beam id, either the stored data or if none the default
 */
export const loadSelectedSatBeamFromStore = (store: IStore): ISelectedSatBeam => {
  const selectedSatBeam = store.fleetMap.view.satBeam;
  if (selectedSatBeam) {
    return selectedSatBeam;
  } else {
    return null;
  }
};

/**
 * Checks for selected satellite beam match on satId & beamId
 * @param feature Google Maps Feature props
 * @param satId satellite Id to match
 * @param beamId beam Id to match
 * @returns true if matches, otherwise false
 */
export const matchesSelectedSatBeamFromFeature = (feature: any, selectedSatDet: ISelectedSatBeam): boolean => {
  const networkType = selectedSatDet.networkType;
  if (networkType === 'internal' || networkType === 'internal-connected') {
    return (
      feature?.properties &&
      feature.properties.satelliteId === selectedSatDet.satelliteId &&
      feature.properties.beamId === selectedSatDet.beamId
    );
  } else {
    return feature?.properties && feature.properties.satelliteName === selectedSatDet.satelliteName;
  }
};

/**
 * Checks for selected satellite beam match on satId & beamId
 * @param state FleetMap state object
 * @param satId satellite Id to match
 * @param beamId beam Id to match
 * @returns true if not undefined and matches, otherwise false
 */
export const matchesSelectedSatBeamFromState = (state: FleetMapState, selectedSatDet: ISelectedSatBeam): boolean => {
  const selectedSatBeam = loadSelectedSatBeamFromState(state);
  return selectedSatDet.id === selectedSatBeam.id;
};

/**
 * Loads the saved selected satellite beam id (if any) from state
 * @param state FleetMap state object
 * @returns parsed selected satellite id/beam id, either the stored data or if none the default
 */
export const loadSelectedSatBeamFromState = (state: any): ISelectedSatBeam => {
  const selectedSatBeam = state.view.satBeam;
  if (selectedSatBeam) {
    return selectedSatBeam;
  } else {
    return null;
  }
};

/**
 * Gets the style configuration for an overlay network type
 * @param satBeam SatBeam type: internal, outage, impaired / default, hovered, clicked
 * @returns The overlay styling
 */
export const getSatBeamOverlayStyle = (satBeam: string): google.maps.Data.StyleOptions =>
  BEAM_OVERLAY_STYLE_MAPPING[satBeam] ? BEAM_OVERLAY_STYLE_MAPPING[satBeam] : DEFAULT_BEAM_OVERLAY_STYLE;

/**
 * Get the appropriate Impact Event Style
 * @param impactType Beam Incident Event impact type
 * @param eventType Mouse event type [default=NONE]
 * @returns Overlay Style Options
 */
export const getImpactEventStyle = (
  impactType: ESatBeamImpactType,
  eventType: ESatBeamEventType = ESatBeamEventType.NONE
): google.maps.Data.StyleOptions => {
  const currentStyle = ImpactEventStyleMapping[impactType] + eventType;
  return getSatBeamOverlayStyle(currentStyle);
};

/**
 * Set Feature Property Impact & Incidents
 * @param feature Feature to modify
 * @param impact Impact Type
 */
export const setFeaturePropertyImpactIncidents = (
  feature: any,
  impact: ESatBeamImpactType,
  incidents: SatelliteBeamIncident[] = []
) =>
  (feature.properties = {
    ...feature.properties,
    impact: impact === ESatBeamImpactType.NONE ? null : impact,
    incidents: incidents
  });

/**
 * Add Impact Incident Data or Override Style for a feature
 * @param map Map object
 * @param satelliteBeamIncidentEvents Beam Incident Events object
 * @param feature Feature to apply impact incident data or style to
 * @param selectedBeamsRef Ref object to retrieve state of satId/beamId
 * @param setData Flag if true will set the Feature properties with Impact Incident Data, otherwise false will override the style of the Feature
 */
export const handleBeamOverlayImpactIncident = (
  map: google.maps.Map,
  satelliteBeamIncidentEvents: SatelliteBeamIncidentEvent[],
  feature: Feature,
  selectedSatBeamRef: React.MutableRefObject<ISelectedSatBeam>,
  setData: boolean = false
) => {
  var currentImpact = ESatBeamImpactType.NONE;
  var currentIncidents = [];
  const satBeamId = selectedSatBeamRef.current ? selectedSatBeamRef.current.id : null;
  var currentEvent = feature.id === satBeamId ? ESatBeamEventType.CLICKED : ESatBeamEventType.NONE;
  // Check for incidents, and apply impact and incidents to feature properties and style
  const beamIncident = satelliteBeamIncidentEvents?.find((incident) => incident.SAT_BEAM_ID === feature.id);
  if (beamIncident) {
    if (beamIncident.INCIDENTS.some((incident) => incident.IMPACT === ESatBeamImpactType.OUTAGE)) {
      currentImpact = ESatBeamImpactType.OUTAGE;
      currentIncidents = beamIncident.INCIDENTS;
    } else if (beamIncident.INCIDENTS.some((incident) => incident.IMPACT === ESatBeamImpactType.IMPAIRED)) {
      currentImpact = ESatBeamImpactType.IMPAIRED;
      currentIncidents = beamIncident.INCIDENTS;
    }
  }
  setData
    ? setFeaturePropertyImpactIncidents(feature, currentImpact, currentIncidents)
    : map.data.overrideStyle(map.data.getFeatureById(feature.id), getImpactEventStyle(currentImpact, currentEvent));
};

/**
 * Overrides styles for a feature if it is being hovered
 * @param map Map object
 * @param selectedBeamsRef Ref object to retrieve state of satId/beamId
 * @param feature Feature object
 * @param isHovered true if hovered, otherwise false for mouse-out (default=true)
 * @param shouldIgnoreImpact true if mapCard to ignore network incident impact (default=false)
 */
export const handleBeamOverlayHover = (
  map: google.maps.Map,
  selectedBeamsRef: React.MutableRefObject<ISelectedSatBeam>,
  feature: any,
  isHovered: boolean = true,
  shouldIgnoreImpact: boolean = false
) => {
  // Ignore hover on not Internal Sat Beams
  if (feature.getProperty('network') !== 'internal') return;
  const satBeamId = selectedBeamsRef.current ? selectedBeamsRef.current.id : null;

  // Ignore hover on selected beam
  const featureId = feature.getId();
  if (featureId === satBeamId) return;

  // Update style of hovered / non-hovered beam
  const currentFeature = map.data.getFeatureById(featureId);
  const beamImpact: ESatBeamImpactType = !shouldIgnoreImpact
    ? currentFeature?.getProperty(ESatBeamProps.IMPACT)
    : ESatBeamImpactType.NONE;
  const currentImpact: ESatBeamImpactType = beamImpact ? beamImpact : ESatBeamImpactType.NONE;
  const currentEvent: ESatBeamEventType = isHovered ? ESatBeamEventType.HOVERED : ESatBeamEventType.NONE;
  const style = getImpactEventStyle(currentImpact, currentEvent);
  map.data.overrideStyle(feature, style);
};

/**
 * Handle styles and popup for a feature being clicked
 * @param map Map object
 * @param selectedBeamsRef Ref object to retrieve state of satId/beamId
 * @param feature Feature object
 * @param setSelectedSatBeamCallback Callback to store selected beam
 * @param shouldIgnoreImpact true if mapCard to ignore network incident impact (default=false)
 */
export const handleBeamOverlayClick = (
  map: google.maps.Map,
  selectedSatBeamRef: React.MutableRefObject<ISelectedSatBeam>,
  feature: any,
  setSelectedSatBeamCallback: any,
  shouldIgnoreImpact: boolean = false
) => {
  const satelliteNetwork = feature.getProperty('network');
  if (
    satelliteNetwork !== 'internal-connected' &&
    satelliteNetwork !== 'internal' &&
    satelliteNetwork !== 'ku-connected' &&
    satelliteNetwork !== 'KuBeams'
  )
    return;

  const satBeamId = selectedSatBeamRef.current ? selectedSatBeamRef.current.id : null;

  // Clear highlight on prior selected sat beam
  const priorFeature = satBeamId ? map.data.getFeatureById(satBeamId) : null;
  if (priorFeature) {
    const priorBeamImpact: string = !shouldIgnoreImpact
      ? priorFeature?.getProperty(ESatBeamProps.IMPACT)
      : ESatBeamImpactType.NONE;
    const priorImpact = priorBeamImpact ? (priorBeamImpact as ESatBeamImpactType) : ESatBeamImpactType.NONE;
    map.data.overrideStyle(priorFeature, getImpactEventStyle(priorImpact));
  }

  // Set Satellite Beam Id in store
  if (satelliteNetwork === 'internal' || satelliteNetwork === 'internal-connected') {
    setSelectedSatBeamCallback({
      satelliteId: feature.getProperty(ESatBeamProps.SATELLITE_ID),
      beamId: feature.getProperty(ESatBeamProps.BEAM_ID),
      networkType: satelliteNetwork,
      id: feature.getId()
    });
  } else {
    setSelectedSatBeamCallback({
      satelliteName: feature.getProperty('satelliteName'),
      networkType: satelliteNetwork,
      id: feature.getId()
    });
  }

  // Determine satellite beam incident event impact
  const currentFeature = map.data.getFeatureById(feature.getId());
  const beamImpact: ESatBeamImpactType = !shouldIgnoreImpact
    ? currentFeature?.getProperty(ESatBeamProps.IMPACT)
    : ESatBeamImpactType.NONE;

  // highlight selected beam
  const currentImpact = beamImpact ? beamImpact : ESatBeamImpactType.NONE;
  map.data.overrideStyle(feature, getImpactEventStyle(currentImpact, ESatBeamEventType.CLICKED));
};

/**
 * Handle styles for feature being closed
 * @param map Map object
 * @param selectedBeamsRef Ref object to retrieve state of satId/beamId
 * @param shouldIgnoreImpact true if mapCard to ignore network incident impact (default=false)
 */
export const handleBeamOverlayClose = (
  map: google.maps.Map,
  selectedSatBeamRef: React.MutableRefObject<ISelectedSatBeam>,
  shouldIgnoreImpact: boolean = false
) => {
  const satBeamId = selectedSatBeamRef.current ? selectedSatBeamRef.current.id : null;
  const priorFeature = satBeamId ? map.data.getFeatureById(satBeamId) : null;
  if (priorFeature) {
    // Determine satellite beam incident event impact
    const beamImpact: ESatBeamImpactType = !shouldIgnoreImpact
      ? priorFeature?.getProperty(ESatBeamProps.IMPACT)
      : ESatBeamImpactType.NONE;

    // revert selected beam back to original style
    const priorStyle = getImpactEventStyle(beamImpact);
    priorStyle ? map.data.overrideStyle(priorFeature, priorStyle) : map.data.revertStyle(priorFeature);
  }
};
