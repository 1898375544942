/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common component colors
 */

const hexToRgb = (hex: any) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : '';
};

// Common color definitions
export const ORANGE = '#EC7424';
export const WHITE = '#FFF';
export const GREY = '#626262';
export const BLACK = '#000';
export const GREEN = '#5ea215';
export const BLACK_RGB = '0, 0, 0';
export const BLUE = '#125986';
export const LIGHT_GREY = '#AAAAAA';
export const TRANSPARENT = '#00000000';
export const OFFLINE = '#7C8B8B';
export const OFFLINE_RGB = hexToRgb(OFFLINE);
export const IMPAIRED = '#FFA625';
export const PILOT_SW_COLOR = '#111920';
export const IMPAIRED_RGB = hexToRgb(IMPAIRED);
export const ALERT_DIALOG_LEFT_BORDER = GREEN;
export const POPUP_TEXT_COLOR = '#202e39';
export const POPUP_TEXT_VALUE = '#040404';

export const BOLD_BLUE = '#185C87';

export const FIVE_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.05)';
export const TWELVE_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.12)';
export const FOURTEEN_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.14)';
export const TWENTY_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.2)';
export const THIRTY_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.3)';
export const DRAG_BUTTON_BACKGROUND = '#F2F5F8';

// Layout Colors
export const SIDEBAR_ICON_COLOR = '#AAAAAA';
export const SIDEBAR_ICON_ACTIVE_COLOR = '#00819D';

export const HELP_TOOLTIP_COLOR = '#AAAAAA';
export const HELP_ICON_COLOR = GREY;
export const HELP_TOOLTIP_BG = WHITE;
export const HELP_TOOLTIP_TEXT = BLACK;
export const CHART_TOOLTIP_BG = WHITE;
export const CHART_TOOLTIP_TEXT = BLACK;
export const CHART_NO_DATA_TITLE_TEXT = BLACK;
export const CHART_NO_DATA_SUBTITLE_TEXT = '#989898';
export const CHART_TOOLTIP_BORDER = TRANSPARENT;

export const MODAL_BG = WHITE;
export const MODAL_FONT_COLOR = '#444444';
export const PRINT_BUTTON_COLOR = '#444';

export const NO_DATA_COLOR = '#7C8B8B';

export const NO_OPEN_CASE_ICON_COLOR = '#00819D';
export const VIASAT_LOGO_ICON_COLOR = '#465967';

// Button Section
export const ACTIVE_BUTTON_BACKGROUND = '#444444';

export const ISOLATE_ROUTE_BUTTON_ACTIVE_BACKGROUND = '#E2F0F8';

// Modal colors
export const MODAL_BACKGROUND = WHITE;

// Legend
export const VIEW_LEGEND_BG = WHITE;
export const VIEW_LEGEND_TEXT = '#849095';

// Vertical Tabs Container Menu
export const COLUMN_SELECTOR_BUTTON_BG = '#F2F2F2';
export const COLUMN_SELECTOR_BUTTON_BG_HOVER = '#EAEAEA';

export const EXPANSIONPANEL_TEXT = '#465967';
export const EXPANSIONPANEL_VALUE_TEXT = '#202E39';

export const SELECTED_ICON_COLOR = '#226394';
export const BODY_MESSAGE_TEXT = '#333';

// Popups
export const POPUP_SCROLLBAR_BG = '#DFDFDF';
export const POPUP_LEFT_BORDER = '#F4F4F4';

export const EIGHT_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.08)';
export const OPEN_BUTTON_BACKGROUND = '#DFDFDF';
export const OPEN_BUTTON_FOREGROUND = '#E6E6E6';

// Toolbar Button
export const TOOLBAR_BUTTON_DISABLED_TEXT_COLOR = '#C5C5C5';
export const TOOLBAR_BUTTON_INACTIVE_TEXT_COLOR = '#444444';
export const TOOLBAR_BUTTON_INACTIVE_HOVER_TEXT_COLOR = '#626262';
export const TOOLBAR_BUTTON_SELECTED_BACKGROUND_COLOR = '#0000000A';
export const TOOLBAR_BUTTON_POPULATED_TEXT_COLOR = '#295A86';
export const TOOLBAR_BUTTON_POPULATED_BACKGROUND_COLOR = '#E3F0F8';
export const TOOLBAR_BUTTON_POPULATED_HOVER_TEXT_COLOR = '#295A86';
export const TOOLBAR_BUTTON_POPULATED_HOVER_BACKGROUND_COLOR = '#E5EFF6';
export const TOOLBAR_BUTTON_POPULATED_BORDER_COLOR = '#D0DEE7';

// Map
export const MAP_CONTROL_ICON_HOVER_COLOR = '#939393';
export const MAP_POPUP_BACKGROUND_COLOR = '#F1F5F8';
export const MAP_POPUP_CLOSE_HOVER_COLOR = '#EFEFEF';
export const MAP_POPUP_DEFAULT_BORDER = '#DADADA';
export const MAP_POPUP_ACCENT_COLOR = '#626262';
export const MAP_SEARCH_RESULTS_TEXT_COLOR = '#444';
export const MAP_FUTURE_PATH_COLOR = '#125986';
export const SELECTED_AIRCRAFT_BG_IN_SEARCH_RESULT = '#E3F0F9';
export const MAP_KA_OVERLAY_COLOR = '#18ECFC';
export const MAP_BEAM_OVERLAY_COLOR = '#70C9F9';
export const MAP_KU_OVERLAY_COLOR = '#AD258B';
export const MAP_KA_REGIONAL_COVERAGE_STROKE_COLOR = '#0DA4E5';
export const MAP_KU_REGIONAL_COVERAGE_STROKE_COLOR = '#f1b8b6';
export const CONFIG_DIVIDER_BG = '#DADADA';
export const POPUP_HOVER_COLOR = '#367BFF';
export const POPUP_AIRCRAFT_ICON_COLOR = BLACK;
export const MAP_TX_INHIBIT_OVERLAY_COLOR = '#F06C6C';
export const MAP_BEAMS_OUTLINE_COLOR = '#677A89';
export const MAP_BEAMS_SELECTED_OUTLINE_COLOR = '#465967';

export const MAP_BEAMS_IMPAIRED_COLOR = '#FFA625';
export const MAP_BEAMS_OUTAGE_COLOR = '#D43C33';
export const MAP_BEAMS_ICON_PRIMARY_COLOR = '#050505';
export const MAP_BEAMS_ICON_SECONDARY_COLOR = '#465967';
export const MAP_BEAMS_ROW_OUTAGE_COLOR = '#FA72724D';
export const MAP_BEAMS_ROW_IMPAIRED_COLOR = '#FDC3614D';
export const MAP_POPUP_NETWORK_ACCENT_COLOR = '#202E39';
export const MAP_POPUP_NETWORK_URL_COLOR = '#006EAD';

// Flight Details
export const FLIGHT_DETAILS_PRIMARY_BLUE = '#125986';
export const FLIGHT_DETAILS_PRIMARY_BLUE_DARK = '#236792';
export const FLIGHT_DETAILS_LIVE_BG_COLOR = '#E3F0F8';
export const FLIGHT_DETAILS_LIVE_BORDER_COLOR = '#D0DEE7';
export const FLIGHT_DETAILS_SECONDARY_GREY = '#EFEFEF';
export const FLIGHT_DETAILS_BACKGROUND = WHITE;
export const FLIGHT_DETAILS_BACKGROUND_DARK = '#F7F7F7';
export const FLIGHT_DETAILS_BORDER = '#DADADA';
export const FLIGHT_DETAILS_BACK_BUTTON_COLOR = '#EFEFEF';
export const FLIGHT_DETAILS_SCROLLBAR_THUMB = '#C7C7C7';
export const FLIGHT_DETAILS_DECOMMISSIONED_BANNER_BG_COLOR= '#FDC36154';

// Flight Information
export const BELOW_LOWER_THRESHOLD_COLOR = '#D43C33';
export const BELOW_LOWER_THRESHOLD_RGB_COLOR = hexToRgb(BELOW_LOWER_THRESHOLD_COLOR);
export const ABOVE_HIGHER_THRESHOLD_COLOR = '#FFA625';
export const ABOVE_TARGET_COLOR = '#20A465';
export const ABOVE_TARGET_RGB_COLOR = hexToRgb(ABOVE_TARGET_COLOR);
export const LEFT_PANEL_FLIGHT_PHASE_ICON_COLOR = '#C3CDD5';

// Charts
export const DEFAULT_PLOTLINE_COLOR = '#62C31B';
export const CHART_AXIS_MINOR_TICK = '#E6E6E6';
export const LEGEND_TEXT = GREY;
export const AXIS_TEXT = GREY;
export const INTERNAL_AXIS_TEXT = BLACK;
export const INTERNAL_CHART_GRIDLINE = '#C4CDD3';

// Avg Data Rate
export const DOWNLOAD_UPLOAD_USAGE_CHART_PALETTE = ['#133A62', '#0EB8A8'];

// Antenna Encoder
export const ANTENNA_ENCODER_CHART_PALETTE = ['#133A62', '#4AABE8', 'rgba(253, 195, 97, 0.6)'];
export const TAIL_BLOCKAGE_WARNING = '#CD3209';

// Data Usage
export const DATA_USAGE_CHART_PALETTE = ['#4AABE8', '#BDCFDA', '#133A62'];

// Ping Latency
export const PING_LATENCY_LINE_COLOR = '#4AABE8';

export const BOX_SHADOW_HELP_TOOLTIP_COLOR = '#00000099';

// Traffic Composition
export const TRAFFIC_COMPOSITION_CHART_PALETTE = ['#3C51A3', '#4AABE8', '#FFBC25', '#EC7424', '#979797', '#0EB8A8'];
export const TRAFFIC_COMPOSITION_CHART_PATTERNS = ['#A67B18', '#88ACC3'];

// Total DataUsage Donut Chart
export const TOTAL_DATA_USAGE_CHART_PALETTE = ['#3C51A3', '#4AABE8', '#FFBC25', '#EC7424', '#979797', '#0EB8A8'];

// Total DataUsage Donut Chart in summery metrics
export const SUMMERY_METRICS_TOTAL_DATA_USAGE_CHART_PALETTE = ['#9BE1FF', '#006EAD'];

// Tail Information - Monthly Data Usage section
export const PROGRESS_BAR_BG_COLOR = '#DEE4E8';
export const MONTHLY_USAGE_BAR_COLORS = ['#20A465', '#FFA625', '#D43C33'];
export const USAGE_EXCEEDED_TEXT = '#B72025';

// Flight Details - Total Usage Donut Chart
export const DATA_USAGE_TIME_FILTER_COLOR = '#9FAFBC';

// Flight Details - satellite health
export const BEAM_CONNECTED = '#20A465';
export const HANDOVER_SUCCESS = '#125986';
export const HANDOVER_FAILURE = '#D43C33';

// Connectivity Outlook Colors
export const UPLOAD_BTN_BG = SIDEBAR_ICON_ACTIVE_COLOR;
export const DISABLED_UPLOAD_BTN_COLOR = DATA_USAGE_TIME_FILTER_COLOR;
export const FLIGHT_EVENT_COLOR = '#677a89';
export const FLIGHT_PLAN_HEADER_BG = SIDEBAR_ICON_ACTIVE_COLOR;
export const MODAL_TITLE_COLOR = '#202E39';
export const AIRCRAFT_SELECTOR_BG_COLOR = PROGRESS_BAR_BG_COLOR;
export const AIRCRAFT_SEARCH_INPUT_COLOR = EXPANSIONPANEL_VALUE_TEXT;
export const AIRCRAFT_SEARCH_LIST_HOVER = ISOLATE_ROUTE_BUTTON_ACTIVE_BACKGROUND;
export const AIRCRAFT_LIST_BORDER_COLOR = '#DADADA';
export const AIRCRAFT_LIST_SELECT_OPTION_COLOR = '#B72025';
export const AIRCRAFT_LIST_ITEM_COLOR = MODAL_TITLE_COLOR;
export const AIRCRAFT_LIST_OPTION_CONTAINER_COLOR = '#006EAD';
export const CHECKBOX_ENABLED_COLOR = '#00819D';
export const EVENTS_TIMELINE_GRID_LINE_COLOR = '#DEE4E8';
export const EVENTS_TIMELINE_DASHED_GRID_LINE_COLOR = '#465967';
export const EDIT_DEPARTURE_TIME_LABEL_COLOR = MODAL_TITLE_COLOR;
export const DISABLED_VIEW_OUTLOOK_BTN_BG = '#CCC';
export const EVENTS_CARD_BORDER_COLOR = '#DADADA';
export const EVENTS_CARD_FLIGHT_ICON_COLOR = '#9FAFBC';

// File Upload Component colors
export const DROP_ZONE_ACCEPT_COLOR = '#00E676';
export const DROP_ZONE_REJECT_COLOR = '#FF1744';
export const DROP_ZONE_ACTIVE_COLOR = '#2196F3';
export const DROP_ZONE_GREY_COLOR = '#DADADA';
export const DROP_ZONE_BACKGROUND_COLOR = '#FFFFFF';
export const DROP_ZONE_BROWSE_FILES_COLOR = '#006EAD';
export const DROP_ZONE_UPLOAD_ICON_COLOR = '#9FAFBC';
export const DROP_ZONE_OUTER_TEXT_COLOR = '#465967';
export const DROP_ZONE_INNER_TEXT_COLOR = '#000000';
export const UPLOAD_PROGRESS_BAR_BG_COLOR = '#F2F5F8';
export const UPLOAD_PROGRESS_BAR_COLOR = '#5EA215';
export const IMPORT_FILE_LIST_FILE_NAME = EXPANSIONPANEL_VALUE_TEXT;
export const UPLOAD_ERROR_ICON_COLOR = '#CD3209';
export const UPLOAD_SUCCESS_ICON_COLOR = '#5EA215';
export const UPLOAD_SYNC_ICON_COLOR = FLIGHT_EVENT_COLOR;
export const UPLOAD_MODAL_CANCEL_BTN_BG = DATA_USAGE_TIME_FILTER_COLOR;
export const FLIGHT_PLAN_SUMMARY_TXT_COLOR = MODAL_TITLE_COLOR;
export const FLIGHT_PLAN_CONNECTOR_BAR_COLOR = '#20A465';
export const DELETE_FLIGHT_PLAN_ICON_COLOR = EXPANSIONPANEL_TEXT;
export const CONNECTIVITY_OUTLOOK_EVENTS_TOOLTIP_COLOR = MODAL_TITLE_COLOR;
export const EDIT_DEPARTURE_TIME_BG = '#00819D';
export const NO_SERVICE_COLOR = '#465967';
export const ACTIVE_BUTTON_COLOR = '#00819D';
export const DIALOG_ALERT_ERROR_COLOR = '#E73737';
export const DIALOG_ALERT_WARNING_COLOR = '#F27400';
export const DIALOG_ALERT_WARNING_ICON_COLOR = '#CD3209';

// List Grid colors
export const GRID_CELL_TEXT_ACTIVE_COLOR = '#000000';
export const GRID_CELL_LINK_COLOR = '#006EAD';
export const GRID_CELL_TEXT_DURATION_COLOR = '#9FAFBC';
export const GRID_CELL_DEFAULTED_TEXT_COLOR = '#9FAFBC';
export const HEADER_ICON_BORDER_COLOR = '#DADADA';
export const TABLE_HEADER_BG = '#444444';
export const WATCHLIST_ICON_COLOR = '#FFA625';
export const NON_WATCHLIST_ICON_COLOR = '#DADADA';
export const FLIGHT_LIST_WARNING = '#CD3209';

// ASLV - Software Revisions
export const SW_REVISIONS_MODAL_TITLE_COLOR = '#202E39';
export const SW_REVISIONS_HARDWARE_COLOR = '#465967';
export const SW_REVISIONS_ICON_COLOR = '#AAAAAA';
export const SW_REVISIONS_TITLE_BORDER_COLOR = ' #DADADA';

export const SWITCH_VIEW_ICON_COLOR = '#202E39';
export const CLOSE_ICON_COLOR = '#32424E';
export const POPUP_BLOCK_BACKGROUND_COLOR = '#F2F5F8';
export const SUPPORT_BORDER_COLOR = '#111920';
export const CASE_LIST_TEXT_COLOR = '#465967';
export const HEADER_BORDER_COLOR = '#DEE4E8';

//Support Popup
export const POPUP_BACKGROUND_COLOR = '#FFFFFF';
export const POPUP_SERVICE_REQUEST_NUMBER_COLOR = '#266705';
export const AUTO_COMPLETE_HOVER_COLOR = GREY;
export const AUTO_COMPLETE_HOVER_BG_COLOR = '#EFEFEF';
export const AUTO_COMPLETE_BORDER_COLOR = '#DDD';
export const NINE_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.09)';
export const WATCHLIST_SELECTOR_ACTIVE_COLOR = '#FFBC25';

export const FILTER_ACTIVE_APPLY = BLUE;
export const FILTER_INACTIVE_APPLY = '#D8D8D8';
export const FILTER_POPULATED_BORDER = '#377BFF';
export const FILTER_BORDER_COLOR = '#DADADA';
export const MULTISELECT_CHECKBOX_INACTIVE_COLOR = LIGHT_GREY;

export const WHITE_COLOR = DROP_ZONE_BACKGROUND_COLOR;

//Summary Metrics - Data Usage Trend Chart
export const DATA_USAGE_TREND_CHART_BORDER = '#DADADA';
export const DATA_USAGE_TREND_CHART_ACTIONS_COLOR = '#444444';
export const DATA_USAGE_TREND_CHART_HATCH_COLOR = '#DADADA';
export const DATA_USAGE_TREND_CHART_ROLLING_AVERAGE_COLOR = '#62C31B';
export const DATA_USAGE_TREND_CHART_AIRCRAFT_COUNT_COLOR = '#4AABE8';

// Dark Flights
export const DARK_FLIGHT_ALERT_COLOR = '#FFE1DB';
export const DARK_FLIGHT_ALERT_ICON_COLOR = '#CD3209';

export const PRINT_AIRCRAFT_LIST_UNASSIGNED_COLOR = '#3e5566';

//Case modal table scroll
export const CASE_MODAL_SCROLL_COLOR = '##F1F1F1';
export const CASE_MODAL_SCROLL_THUMB = '#D8D8D8';

//Tail list - Equipment history popup
export const BUILD_ICON_COLOR = '#B72025';
export const BUILD_ICON_BG_COLOR = '#FFE1DB';
export const HISTORY_ROW_LIGHT_TEXT_COLOR = '#465967';
