/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Data usage card content row item
 */

import React from 'react';
import {CardRow, CardRowTitle, CardRowValue} from '../SummaryMetricsStyles';
import AvailabilityDot from '../../common/AvailabilityDot';

const CardRowItem: React.FC<any> = ({rowData}) => {
  return (
    <CardRow>
      <CardRowTitle id={`${rowData.id}__label`}>
        {rowData.includeAvailabilityDot ? (
          <AvailabilityDot
            id={`${rowData.id}__dot`}
            sx={{
              color: rowData.availabilityDotColor,
              width: 12,
              height: 12,
              marginRight: '6px'
            }}
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {rowData.title}
      </CardRowTitle>
      <CardRowValue id={`${rowData.id}__value`}>{rowData.value}</CardRowValue>
    </CardRow>
  );
};

export default CardRowItem;
