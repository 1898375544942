/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Request Sent Popup
 */

import React from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  CLOSE_ICON_COLOR,
  CASE_LIST_TEXT_COLOR,
  POPUP_BACKGROUND_COLOR,
  EXPANSIONPANEL_TEXT,
  POPUP_TEXT_COLOR,
  POPUP_SERVICE_REQUEST_NUMBER_COLOR,
  HEADER_BORDER_COLOR,
  GRID_CELL_LINK_COLOR
} from '../../../common/theme/Colors';
import {IFormValues} from './AircraftStatusSupportPopup';

const SupportReportPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px;
  gap: 32px;
  background: ${POPUP_BACKGROUND_COLOR};
  position: relative;
  width: 600px;
  border-radius: 16px;
  margin-bottom: 24px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 552px;
  flex: none;
  order: 0;
  flex-grow: 0;
  .head-text {
    padding: 0px 0px;
    align-items: flex-start;
    letter-spacing: 0.2px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${POPUP_TEXT_COLOR};
  }
  .close-icon {
    width: 10%;
    margin-top: 2px;
    margin-right: 10px;
    color: ${CLOSE_ICON_COLOR};
    cursor: pointer;
    position: absolute;
    right: 0px;
  }
  .frame-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    width: 536px;
    color: ${CASE_LIST_TEXT_COLOR};
    flex: none;
    order: 0;
    flex-grow: 1;
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0px;
  width: 552px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border-top: 1px solid ${HEADER_BORDER_COLOR};
  .bottom-head-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    color: ${EXPANSIONPANEL_TEXT};
    opacity: 0.6;
    margin-top: 24px;
  }
  .service-number-text {
    display: flex;
    align-items: center;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${POPUP_SERVICE_REQUEST_NUMBER_COLOR};
    height: 32px;
  }
  .text {
    display: flex;
    align-items: center;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${POPUP_TEXT_COLOR};
    height: 32px;
  }
  .description-text {
    height: 60px;
    display: flex;
    align-items: center;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${POPUP_TEXT_COLOR};
  }
  .link-text {
    height: 20px;
    color: ${GRID_CELL_LINK_COLOR};
    text-decoration-line: underline;
    cursor: pointer;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
  }
`;
interface SupportReportPopupProps {
  closeModal: () => void;
  formValues: IFormValues;
}

const SupportReportPopup: React.FC<SupportReportPopupProps> = ({closeModal, formValues}) => {
  return (
    <SupportReportPopupContainer>
      <Top>
        <CloseIcon className="close-icon" onClick={closeModal} />
        <div className="head-text">Your request has been received</div>
        <div className="frame-text">
          A case has been sent to Viasat MCC. Viasat support staff will reply via email as quickly as possible.
        </div>
      </Top>
      <Bottom>
        {/* <div className="bottom-head-text">Service Request Number</div>
        <div className="service-number-text">--</div> */}
        <div className="bottom-head-text">Subject</div>
        <div className="text">{formValues.subject}</div>
        <div className="bottom-head-text">Description</div>
        <div className="description-text">{formValues.description}</div>
        <div className="bottom-head-text">Reference Link</div>
        <div className="link-text">{formValues.referenceLink}</div>
      </Bottom>
    </SupportReportPopupContainer>
  );
};

export default SupportReportPopup;
