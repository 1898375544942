/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List History Util
 */

import {CustomLink, LoadingBar, LoadingBarType, LongText} from '@viasat/insights-components';
import {
  PlanHistoryBlock,
  CustomerHistoryBlock,
  HistoryDateContainer,
  PlanHistoryNameContainer,
  CustomerHistoryNameContainer,
  EquipmentHistoryBlock,
  EquipmentHistoryConfigurationTypeContainer,
  EquipmentHistoryDateContainer,
  EquipmentHistoryDetailsRow,
  EquipmentHistoryDetailsContainer,
  EquipmentHistorySerialNumberContainer,
  BuildIconContainer,
  HistoryRowBoldText,
  HistoryBlock,
  HistoryRowLightText,
  HistoryRowLinkText,
  HistoryRowInnerContainer,
  HistoryRowLoadingContainer,
  HistoryRowLoaderWrapper
} from '../TailListStyle';
import BuildIcon from '@mui/icons-material/Build';
import {BUILD_ICON_BG_COLOR, BUILD_ICON_COLOR, GRID_CELL_LINK_COLOR} from '../../../common/theme/Colors';
import {EquipmentHistoryInfo} from '../../../../store/queries/tailList/equipmentHistoryQuery';
import {HistoryPopupProps} from '../../../../store/queries/tailList/tailListQuery';
import {TailPlanHistoryInfo} from '../../../../store/queries/tailList/planHistoryQuery';
import {TailCustomerHistoryInfo} from '../../../../store/queries/tailList/customerHistoryQuery';
import {IDisconnectedEvents} from '../../../../store/queries/tailList/disconnectedEvents';
import {formatHMSecondsValue} from '../../../../utils/DateTimeUtils';
import {IHandoverFailures} from '../../../../store/queries/tailList/handoverFailureHistoryQuery';

const TAIL_LIST_HISTORY_ID_PREFIX = 'TailListContainer--HistoryPopUp';

export const servicePlanHistoryContent = (historyDetails: TailPlanHistoryInfo): JSX.Element => {
  return (
    <PlanHistoryBlock>
      <HistoryDateContainer id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyDate`}>
        {historyDetails.deactivatedTime}
      </HistoryDateContainer>
      <PlanHistoryNameContainer id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyContent`}>
        <LongText fullTitle={historyDetails.planName} maxLength={15} />
        &nbsp;(Removed)
      </PlanHistoryNameContainer>
    </PlanHistoryBlock>
  );
};

export const equipmentHistoryContent = (historyDetails: EquipmentHistoryInfo): JSX.Element => {
  return (
    <EquipmentHistoryBlock>
      <BuildIconContainer>
        <BuildIcon
          style={{
            width: '8.26px',
            height: '8.21px',
            color: BUILD_ICON_COLOR,
            background: BUILD_ICON_BG_COLOR,
            borderRadius: '300px',
            padding: '3px'
          }}
        />
      </BuildIconContainer>
      <EquipmentHistoryDateContainer id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyDate`}>
        {historyDetails.lastModifiedDate}
      </EquipmentHistoryDateContainer>
      <EquipmentHistoryDetailsContainer id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyContent`}>
        <EquipmentHistoryDetailsRow>
          <EquipmentHistoryConfigurationTypeContainer>
            {historyDetails.configurationType} {historyDetails.macAddress ? 'Serial Number' : ''}{' '}
            {historyDetails.isRemoved ? `(Removed)` : `(Active)`}
          </EquipmentHistoryConfigurationTypeContainer>
          <EquipmentHistorySerialNumberContainer>{historyDetails.serialNumber}</EquipmentHistorySerialNumberContainer>
        </EquipmentHistoryDetailsRow>
        {historyDetails.macAddress ? (
          <EquipmentHistoryDetailsRow>
            <EquipmentHistoryConfigurationTypeContainer>
              Mac Address {historyDetails.isRemoved ? `(Removed)` : null}
            </EquipmentHistoryConfigurationTypeContainer>
            <EquipmentHistorySerialNumberContainer>{historyDetails.macAddress}</EquipmentHistorySerialNumberContainer>
          </EquipmentHistoryDetailsRow>
        ) : (
          <></>
        )}
      </EquipmentHistoryDetailsContainer>
    </EquipmentHistoryBlock>
  );
};

/**
 * To build customer history popup content
 * @param historyDetails customer history details
 */
export const customerHistoryContent = (historyDetails: TailCustomerHistoryInfo): JSX.Element => {
  return (
    <CustomerHistoryBlock>
      <HistoryDateContainer id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyDate`}>
        {historyDetails.deactivatedDate}
      </HistoryDateContainer>
      <CustomerHistoryNameContainer id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyContent`}>
        <LongText fullTitle={historyDetails.customerName} maxLength={25} />
        &nbsp;(Removed)
      </CustomerHistoryNameContainer>
    </CustomerHistoryBlock>
  );
};

export const buildEquipmentHistoryPopupProps = (historyDetails: EquipmentHistoryInfo[]): HistoryPopupProps => {
  return {
    popupWidth: 390,
    popupTitle: 'Equipment History',
    historyDetails: historyDetails,
    historyRowContent: equipmentHistoryContent
  };
};

export const buildServicePlanHistoryPopupProps = (historyDetails: TailPlanHistoryInfo[]): HistoryPopupProps => {
  const planHistory = historyDetails.filter((planDetail) => planDetail.planName !== null);
  return {
    popupWidth: 314,
    popupTitle: 'Service Plan History',
    historyDetails: planHistory,
    historyRowContent: servicePlanHistoryContent
  };
};

/**
 * To build customer history popup properties
 * @param historyDetails customer history details returned from api
 * @returns popup properties - popupWidth, popupTitle, historyDetails, historyRowContent
 */
export const buildCustomerHistoryPopupProps = (
  historyDetails: TailCustomerHistoryInfo[],
  currentCustomer: string
): HistoryPopupProps => {
  const customerHistory = historyDetails.filter((customerDetail) => customerDetail.customerName !== null);
  return {
    popupWidth: 350,
    popupTitle: 'Customer Change',
    historyDetails: customerHistory,
    historyRowContent: customerHistoryContent,
    disableCellText: currentCustomer === 'Unassigned'
  };
};

/**
 * To build disconnected events history popup content
 * @param historyDetails disconnected events history details
 */
export const disconnectedEventHistoryContent = (historyDetails: IDisconnectedEvents) => {
  return (
    <HistoryBlock width={251} justifyContent={'space-between'}>
      <HistoryRowInnerContainer paddingRight={8} id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyDate`}>
        <HistoryRowLinkText>
          <CustomLink to={`/flight/details/${historyDetails.flightId}`} color={GRID_CELL_LINK_COLOR}>
            {historyDetails.flightStartTimestamp}
          </CustomLink>
        </HistoryRowLinkText>
      </HistoryRowInnerContainer>
      <HistoryRowInnerContainer id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyContent`}>
        <HistoryRowBoldText textAlign="right">
          {historyDetails.disconnectedCount} Disconnects
          <HistoryRowLightText> &nbsp;{`(${formatHMSecondsValue(historyDetails.durationSec)})`}</HistoryRowLightText>
        </HistoryRowBoldText>
      </HistoryRowInnerContainer>
    </HistoryBlock>
  );
};

/**
 * To build disconnected events history popup loader
 * @param index index for the rendering loader
 * @param cellIdBase Base Id for the component
 */
export const historyLoaderContent = (index: number, cellIdBase: string) => {
  return (
    <HistoryRowLoadingContainer width={275}>
      <HistoryRowLoaderWrapper>
        <LoadingBar
          type={LoadingBarType.default}
          height={12}
          width={95}
          id={`${cellIdBase}__historyRow-loadingBar-1-${index}`}
        />
      </HistoryRowLoaderWrapper>
      <HistoryRowLoaderWrapper>
        <LoadingBar
          type={LoadingBarType.default}
          height={12}
          width={71}
          id={`${cellIdBase}__historyRow-loadingBar-2-${index}`}
        />
        <LoadingBar
          type={LoadingBarType.default}
          height={12}
          width={45}
          id={`${cellIdBase}__historyRow-loadingBar-3-${index}`}
        />
      </HistoryRowLoaderWrapper>
    </HistoryRowLoadingContainer>
  );
};

/**
 * To build disconnected events history popup properties
 * @param historyDetails disconnected history details returned from api
 * @returns popup properties - popupWidth, popupTitle, historyDetails, historyRowContent
 */
export const buildDisconnectedEventsHistoryPopupProps = (currentEventCount: number): HistoryPopupProps => {
  return {
    popupWidth: 303,
    popupTitle: 'Disconnected Events',
    historyDetails: currentEventCount ? [{}] : [],
    historyRowContent: disconnectedEventHistoryContent,
    disableCellText: false,
    showHistoryLoader: true,
    historyLoaderCount: 2,
    headerTotalValue: currentEventCount,
    tooltipText: 'View Disconnected Events',
    historyLoaderContent: historyLoaderContent
  };
};

/**
 * To build handover failure history popup properties
 * @param failureCount  - Total failure count
 * @returns History popup properties
 */
export const buildHandoverFailureHistoryPopupProps = (failureCount: number): HistoryPopupProps => {
  return {
    popupWidth: 303,
    popupTitle: 'Handover Failures',
    historyDetails: failureCount ? [{}] : [],
    historyRowContent: handoverFailureHistoryContent,
    disableCellText: false,
    showHistoryLoader: true,
    historyLoaderCount: 2,
    headerTotalValue: failureCount,
    tooltipText: 'View Handover Failures',
    historyLoaderContent: historyLoaderContent
  };
};

/**
 * To form Handover failure popup content
 * @param historyDetails - Handover failures events from API
 * @returns Handover failure history content
 */
export const handoverFailureHistoryContent = (historyDetails: IHandoverFailures) => {
  return (
    <HistoryBlock width={251} justifyContent={'space-between'}>
      <HistoryRowInnerContainer paddingRight={8} id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyDate`}>
        <HistoryRowLinkText>
          <CustomLink to={`/flight/details/${historyDetails.flightId}`} color={GRID_CELL_LINK_COLOR}>
            {historyDetails.flightStartTstamp}
          </CustomLink>
        </HistoryRowLinkText>
      </HistoryRowInnerContainer>
      <HistoryRowInnerContainer id={`${TAIL_LIST_HISTORY_ID_PREFIX}-historyContent`}>
        <HistoryRowBoldText textAlign="right">{historyDetails.handoverFailuresCount}</HistoryRowBoldText>
      </HistoryRowInnerContainer>
    </HistoryBlock>
  );
};
