/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Total Data usage query
 */
 
import {Query} from '../types';

export interface ITotalDataUsage {
    in_flight_usage: number;
    on_ground_usage: number;
    plan_name: string;
    total_hours_allowed: string;
    coverage: string;
    vus: string;
    total_data_allowed: string;
    usage_percentage: number;
    isRegionalPlan: boolean;
}

const totalDataUsageQuery: Query<ITotalDataUsage> = {
    route: 'summaryMetrics/totalDataUsage',
    transform: null
};

export default totalDataUsageQuery;
