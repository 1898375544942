/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Ellipsis text used for larger file names
 */
import React from 'react';
import styled from '@emotion/styled';
import {DataToolTip} from '@viasat/insights-components';

const HiddenSelect = styled.span`
  opacity: 0;
  white-space: pre;
  width: 0px;
  display: inline-block;
  vertical-align: text-top;
  postion: relative;
  z-index: 0;
`;

const ShownSelect = styled.span`
  postion: relative;
  z-index: 1;
`;

const DisableSelect = styled.span`
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const NoTruncate = styled.div`
  display: inline-block;
  vertical-align: text-top;
`;

const WrapSpan = styled.span`
  white-space: wrap;
`;

export enum EllipsisPosition {
  front = 'front',
  middle = 'middle',
  end = 'end'
}

const EllipsisText: React.FC<{
  fullText: string;
  idBase: string;
  maxLength: number;
  ellipsisPosition?: EllipsisPosition;
}> = ({fullText, idBase, maxLength, ellipsisPosition = EllipsisPosition.end}) => {
  const overflow = fullText.length - maxLength;
  const halfLength = Math.floor(maxLength / 2);

  return overflow > 0 ? (
    <DataToolTip
      arrow
      placement="top"
      enterDelay={250}
      title={
        <>
          <div style={{lineHeight: '18px', margin: '11px 13px'}}>
            <div>
              <span style={{fontWeight: 600}}>{fullText}</span>
            </div>
          </div>
        </>
      }
    >
      <span>
        {' '}
        <span id={`${idBase}-label`} style={{display: 'none'}}>
          {fullText}
        </span>
        {ellipsisPosition === EllipsisPosition.front ? (
          <WrapSpan>
            <DisableSelect>...</DisableSelect>
            <HiddenSelect>{fullText.substring(0, overflow)}</HiddenSelect>
            <ShownSelect>{fullText.substring(overflow)}</ShownSelect>
          </WrapSpan>
        ) : (
          <></>
        )}
        {ellipsisPosition === EllipsisPosition.middle ? (
          <WrapSpan>
            <ShownSelect>{fullText.substring(0, halfLength)}</ShownSelect>
            <HiddenSelect>{fullText.substring(halfLength, halfLength + overflow)}</HiddenSelect>
            <DisableSelect>...</DisableSelect>
            {fullText.substring(halfLength + overflow)}
          </WrapSpan>
        ) : (
          <></>
        )}
        {ellipsisPosition === EllipsisPosition.end ? (
          <WrapSpan>
            <ShownSelect>{fullText.substring(0, maxLength)}</ShownSelect>
            <HiddenSelect>{fullText.substring(maxLength)}</HiddenSelect>
            <DisableSelect>...</DisableSelect>
          </WrapSpan>
        ) : (
          <></>
        )}
      </span>
    </DataToolTip>
  ) : (
    <NoTruncate id={`${idBase}-label`}>{fullText}</NoTruncate>
  );
};

export default EllipsisText;
