/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft Status Card view page component
 */

import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {EmptyPlaceHolder, LoadingBar} from '@viasat/insights-components';
import React from 'react';
import {EMPTY_PLACEHOLDER_DESCRIPTION, EMPTY_PLACEHOLDER_STATEMENT} from '../../../utils/constants';
import {getElementIdFragmentBase} from '../../../utils/ElementIdUtils';
import {EVENTS_CARD_BORDER_COLOR, SW_REVISIONS_HARDWARE_COLOR} from '../../common/theme/Colors';
import CardList from './CardList';

const HeaderDivider = styled.div`
  display: flex;
  border-bottom: 1px solid ${EVENTS_CARD_BORDER_COLOR};
  margin: 5px 0px;
`;
const CardListHolder = styled.div`
  overflow: auto;
  height: calc(100% - 130px);
  width: 97%;
  position: absolute;
`;

const CardListHeading = styled.div`
  display: flex;
  flex-direction: row;
`;

const CardListTitle = styled.div`
  font-weight: 400;
  line-height: 24px;
  display: flex;
  margin-left: 16px;
  width: 168px;
`;

const CardListHeadingGroupings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 88px 0px 68px;
`;

const DataGroupItem = styled.div<{width: number}>`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  color: ${SW_REVISIONS_HARDWARE_COLOR};
  font-style: normal;
  width: ${(props) => props.width}px;
  margin-top: 5px;
`;

interface CardListProps {
  listData: any;
  isLoading: boolean;
  isEndUser: boolean;
  isInternal: boolean;
  handleWatchListClick: (selectedRow: any) => void;
  handleSummaryMetricsLinkClick: (selectedRow: any) => void;
  handleOpenCasesClick: (selectedRow: any) => void;
  handleSupportRequestClick: (selectedRow: any) => void;
}

const CardViewComponent: React.FC<CardListProps> = ({
  listData,
  isLoading,
  isEndUser,
  isInternal,
  handleWatchListClick,
  handleSummaryMetricsLinkClick,
  handleOpenCasesClick,
  handleSupportRequestClick
}) => {
  const watchList = listData && listData.length ? listData.filter((aircraftStatus) => aircraftStatus.favorite) : [];
  const nonWatchList = listData && listData.length ? listData.filter((aircraftStatus) => !aircraftStatus.favorite) : [];
  const idElemBase = 'aircraftStatus';
  const idSubSection = 'cardView';

  return (
    <>
      <HeaderDivider />
      <CardListHolder>
        {watchList && watchList.length ? (
          <CardListHeading>
            <CardListTitle id={getElementIdFragmentBase(idElemBase, idSubSection, 'watchListHeading')}>
              Watchlist ({watchList.length})
            </CardListTitle>
            <CardListHeadingGroupings>
              <DataGroupItem width={216}>Tail Info</DataGroupItem>
              <DataGroupItem width={638}>Flight Info</DataGroupItem>
            </CardListHeadingGroupings>
          </CardListHeading>
        ) : (
          <></>
        )}
        {isLoading ? (
          <>
            <Card sx={{height: isInternal ? 165 : 140, margin: 2}} key="aircraftStatusCardViewLoading">
              <CardContent sx={{padding: 5, display: 'flex'}}>
                <LoadingBar id={`aircraftStatusCardView-loading`} width={200} height={20} />
              </CardContent>
            </Card>
          </>
        ) : watchList && watchList.length ? (
          <CardList
            listName="watchList"
            handleWatchListClick={handleWatchListClick}
            handleSummaryMetricsLinkClick={handleSummaryMetricsLinkClick}
            handleOpenCasesClick={handleOpenCasesClick}
            handleSupportRequestClick={handleSupportRequestClick}
            isEndUser={isEndUser}
            isInternal={isInternal}
            listData={watchList}
          />
        ) : (
          <></>
        )}

        {nonWatchList && nonWatchList.length ? (
          <CardListHeading>
            <CardListTitle id={getElementIdFragmentBase(idElemBase, idSubSection, 'nonWatchListHeading')}>
              Aircraft Status ({nonWatchList.length})
            </CardListTitle>
            <CardListHeadingGroupings>
              <DataGroupItem width={271}>Tail Info</DataGroupItem>
              <DataGroupItem width={638}>Flight Info</DataGroupItem>
            </CardListHeadingGroupings>
          </CardListHeading>
        ) : (
          <></>
        )}
        {!isLoading && nonWatchList && nonWatchList.length ? (
          <CardList
            listName="nonWatchList"
            handleWatchListClick={handleWatchListClick}
            handleSummaryMetricsLinkClick={handleSummaryMetricsLinkClick}
            handleOpenCasesClick={handleOpenCasesClick}
            handleSupportRequestClick={handleSupportRequestClick}
            isEndUser={isEndUser}
            isInternal={isInternal}
            listData={nonWatchList}
          />
        ) : isLoading ? (
          <>
            <Card sx={{height: isInternal ? 165 : 140, margin: 2}} key="aircraftStatusCardViewLoading">
              <CardContent sx={{padding: 5, display: 'flex'}}>
                <LoadingBar id={`aircraftStatusCardView-loading`} width={200} height={20} />
              </CardContent>
            </Card>
          </>
        ) : watchList && watchList.length ? (
          <></>
        ) : (
          <EmptyPlaceHolder
            getFullElementId={(name: string, type: string) => `cardList__${name}-${type}`}
            statement={EMPTY_PLACEHOLDER_STATEMENT}
            description={EMPTY_PLACEHOLDER_DESCRIPTION}
          />
        )}
      </CardListHolder>
    </>
  );
};

export default CardViewComponent;
