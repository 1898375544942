/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: To display Total Data Used by aircraft
 *
 */

import React, {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import ReactDOMServer from 'react-dom/server';
import {useIntl} from 'react-intl';
import {ChartWrapper} from '@viasat/insights-components';

import {ChartType} from '../../common/elements/chart/types';
import {TOTAL_DATA_USAGE_CHART_PALETTE} from '../../common/theme/Colors';
import {formatDataUsageValue, patternDefinition} from '../flightDetailsUtil';
import TotalDataUsageToolTip from './tooltip/TotalDataUsageToolTip';
import StyledButton from '../../common/StyledButton';
import * as colors from '../../common/theme/Colors';
import {useStore} from '../../../store/Store';
import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';
import PieChart, {TitleElement} from '../../common/elements/chart/PieChart';
import {FORMAT_VALUE_NULL_REPRESENTATION} from '../../../utils/constants';
import {formatConfig} from '../../common/elements/chart/chartUtils';

interface TotalDataUsageProps {
  dataUsageData: any;
  isHistoricalFlight: boolean;
  isLoading: boolean;
  totalUsageData: number;
  visibleCategories: SelectedTotalDataUsageCategories;
  setVisibleCategories: any;
}

export interface SelectedTotalDataUsageCategories {
  streamingGamingUsage: Boolean;
  bsSocialNwUsage: Boolean;
  pdClServicesUsage: Boolean;
  bgAppUpdatesUsage: Boolean;
  othrSpdTestUsage: Boolean;
  vpnUsage: Boolean;
}

const getAggregatedUsage = (usedData, aggData) => {
  aggData.bgAppUpdatesUsage += usedData['backgroundAppUpdates'];
  aggData.bsSocialNwUsage += usedData['browsingSocialNetworks'];
  aggData.pdClServicesUsage += usedData['productivityCloudServices'];
  aggData.streamingGamingUsage += usedData['streamingGaming'];
  aggData.vpnUsage += usedData['vpn'];
  aggData.othrSpdTestUsage += usedData['otherSpeedTest'];
  return aggData;
};

const formatPercent = (val: number, totalVal: number) => ((val / totalVal) * 100).toFixed(0).toLocaleString();

const formatTotalDataUsageValue = (val: number) => {
  if (val === null) {
    return `${FORMAT_VALUE_NULL_REPRESENTATION} MB`;
  }

  if (val < 1 && val > 0) {
    return '< 1 MB';
  }

  return formatDataUsageValue(val, false, true);
};

const ChartContainer = styled.div`
  height: auto;
  width: auto;
  button {
    margin: 0px;
    padding: 6px 8px;
    width: 124px;
    font-weight: 400;
    border: 1px solid #9fafbc;
    margin-top: 16px;
    background-color: ${colors.WHITE};
    color: ${colors.DATA_USAGE_TIME_FILTER_COLOR};
  }
  button#totalFlight {
    border-right-style: none !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  button#last30mins {
    border-left-style: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  button.enabled {
    background-color: ${colors.SIDEBAR_ICON_ACTIVE_COLOR};
    color: ${colors.WHITE};
  }
  @media print {
    button {
      margin-top: 0px;
    }
  }
`;
const DataUsageFilter = ({isFilterEnabled, applyTimeFilter}) => (
  <div>
    <StyledButton id="totalFlight" className={!isFilterEnabled ? 'enabled' : ''} onClick={() => applyTimeFilter(false)}>
      Total Flight
    </StyledButton>
    <StyledButton id="last30mins" className={isFilterEnabled ? 'enabled' : ''} onClick={() => applyTimeFilter(true)}>
      Last 30 Minutes
    </StyledButton>
  </div>
);

const TotalDataUsage: React.FC<TotalDataUsageProps> = ({
  dataUsageData,
  isHistoricalFlight,
  isLoading,
  totalUsageData,
  visibleCategories,
  setVisibleCategories
}: TotalDataUsageProps) => {
  const {store, dispatch} = useStore();
  const {timeOverride} = store.flightDetails;
  const {dataUsageTimeFilter} = store.flightDetails;
  const [chartConfig, setChartConfig] = useState<any>(null);
  const isDataUsageTimeFilterEnabled = dataUsageTimeFilter.enabled && !isHistoricalFlight;

  const id = 'liveFlightView.totalDataUsage.id';
  const title = null;
  const chartType = ChartType.PIE;
  const palette = TOTAL_DATA_USAGE_CHART_PALETTE;
  const highchartsIdBase = 'totalDataUsage';
  const chartSubTitle = isDataUsageTimeFilterEnabled ? 'Used Last 30m' : 'Used This Flight';
  const noDataChartTitle = 'No Data Available';

  const intl = useIntl();

  const setDataUsageTimeFilter = useCallback(
    (enabled: boolean) => {
      dispatch({type: FlightDetailsAction.SET_DATA_USAGE_TIME_FILTER, payload: enabled});
    },
    [dispatch]
  );

  const getChartData = () => {
    var chartData = {
      bgAppUpdatesUsage: 0.0,
      bsSocialNwUsage: 0.0,
      pdClServicesUsage: 0.0,
      streamingGamingUsage: 0.0,
      vpnUsage: 0.0,
      othrSpdTestUsage: 0.0
    };

    if (dataUsageData && dataUsageData.length) {
      if (!isDataUsageTimeFilterEnabled) {
        dataUsageData.forEach((usedData) => {
          chartData = getAggregatedUsage(usedData, chartData);
        });
      } else {
        let currentTimestamp = timeOverride.enabled
          ? moment.utc(timeOverride.value, 'YYYY-MM-DD HH:mm:ss')
          : moment.utc();
        dataUsageData.forEach((usedData) => {
          if (currentTimestamp.diff(moment.utc(usedData.timestamp, 'YYYY-MM-DD HH:mm:ss'), 'minutes') <= 30) {
            chartData = getAggregatedUsage(usedData, chartData);
          }
        });
      }
    }
    return chartData;
  };

  const defaultChartPalette = [];

  const tooltip = (i18n: any) => (input: any) => {
    const {x, y, percentage, color, key} = input;

    return {
      label: {
        value: x
      },
      points: [
        {
          value: (
            <TotalDataUsageToolTip
              prefixIconColor={color}
              chartLabel={key}
              dataPercentage={percentage.toFixed(1)}
              dataUsage={y}
            />
          )
        }
      ],
      color: false
    };
  };

  function onLegendClick(e) {
    // Allows for chart to be updated before callback funciton completes
    e.preventDefault();
    this.setVisible();

    // Get sum, count, and state the of visible pie sections
    let sum = 0;
    let visibleCount = 0;
    let visibleCategories = {};
    for (const data of this.series.options.data) {
      if (data['visible'] !== false) {
        sum += data['y'];
        visibleCount++;
        visibleCategories[data['id']] = true;
      } else {
        visibleCategories[data['id']] = false;
      }
    }

    // Displays '--' when no category is selected
    sum = visibleCount !== 0 ? sum : null;

    // Update chart with new value
    this.series.chart.setTitle({
      text: ReactDOMServer.renderToString(
        <TitleElement title={formatTotalDataUsageValue(sum)} subTitle={chartSubTitle} />
      )
    });

    // Persist category visibility data
    setVisibleCategories(visibleCategories);
  }

  useEffect(() => {
    if (!isLoading) {
      const chartDataValue = getChartData();

      const trafficTotalDataUsage = Object.values(chartDataValue).reduce((a, b) => a + b);
      const {streamingGamingUsage, bsSocialNwUsage, pdClServicesUsage, bgAppUpdatesUsage, othrSpdTestUsage, vpnUsage} =
        chartDataValue;

      const totalUsedForCalculations = isDataUsageTimeFilterEnabled ? trafficTotalDataUsage : totalUsageData;

      let chartData = [];
      let visibleTotalDataUsage = 0;
      let visibleCount = 0;

      let hasData = trafficTotalDataUsage > 0;

      if (chartDataValue && hasData) {
        chartData = [
          {
            id: 'streamingGamingUsage',
            name: 'Streaming & Gaming',
            y: totalUsedForCalculations * (streamingGamingUsage / trafficTotalDataUsage),
            percent: formatPercent(streamingGamingUsage, trafficTotalDataUsage),
            visible: visibleCategories.streamingGamingUsage
          },
          {
            id: 'bsSocialNwUsage',
            name: 'Browsing & Social Network',
            y: totalUsedForCalculations * (bsSocialNwUsage / trafficTotalDataUsage),
            percent: formatPercent(bsSocialNwUsage, trafficTotalDataUsage),
            visible: visibleCategories.bsSocialNwUsage
          },
          {
            id: 'pdClServicesUsage',
            name: 'Productivity & Cloud Services',
            y: totalUsedForCalculations * (pdClServicesUsage / trafficTotalDataUsage),
            percent: formatPercent(pdClServicesUsage, trafficTotalDataUsage),
            visible: visibleCategories.pdClServicesUsage
          },
          {
            id: 'bgAppUpdatesUsage',
            name: 'Background & App Updates',
            y: totalUsedForCalculations * (bgAppUpdatesUsage / trafficTotalDataUsage),
            percent: formatPercent(bgAppUpdatesUsage, trafficTotalDataUsage),
            visible: visibleCategories.bgAppUpdatesUsage
          },
          {
            id: 'othrSpdTestUsage',
            name: 'Other & Speed Tests',
            y: totalUsedForCalculations * (othrSpdTestUsage / trafficTotalDataUsage),
            percent: formatPercent(othrSpdTestUsage, trafficTotalDataUsage),
            visible: visibleCategories.othrSpdTestUsage
          },
          {
            id: 'vpnUsage',
            name: 'VPN',
            y: totalUsedForCalculations * (vpnUsage / trafficTotalDataUsage),
            percent: formatPercent(vpnUsage, trafficTotalDataUsage),
            visible: visibleCategories.vpnUsage
          }
        ];

        for (const data of chartData) {
          if (visibleCategories[data.id]) {
            visibleTotalDataUsage += data.y;
            visibleCount++;
          }
        }
      }

      const chartTitle = formatTotalDataUsageValue(visibleCount !== 0 ? visibleTotalDataUsage : null);

      setChartConfig({
        id,
        defaultChartPalette,
        title,
        chartTitle,
        chartSubTitle,
        chartData,
        chartType,
        palette,
        tooltip,
        highchartsIdBase,
        patternDefinition,
        onLegendClick,
        noDataChartTitle
      });
    }
    // eslint-disable-next-line
  }, [totalUsageData, dataUsageData, visibleCategories, dataUsageTimeFilter]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer className="chart-pie">
      {!isHistoricalFlight ? (
        <DataUsageFilter isFilterEnabled={dataUsageTimeFilter.enabled} applyTimeFilter={setDataUsageTimeFilter} />
      ) : (
        <></>
      )}
      <ChartWrapper
        getFullElementId={(name, type) => `flightDetails--totalDataUsage__${name}-${type}`}
        isLoading={isLoading}
        isError={false}
        chartHeight={dataUsageData && dataUsageData.length ? 480 : 300}
      >
        {Object.keys(formattedConfig).length === 0 ? <></> : <PieChart {...formattedConfig} />}
      </ChartWrapper>
    </ChartContainer>
  );
};

export default TotalDataUsage;
