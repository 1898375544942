/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Class that displays the tooltip on List Grid Data for Connection Status
 *
 */
import React from 'react';
import {DataToolTip as ListGridConnectionStatusTooltipStyle} from '@viasat/insights-components';
import BoldSpan from '../../BoldSpan';
import {GRID_CELL_TEXT_DURATION_COLOR} from '../../theme/Colors';

interface ListGridConnectionStatusTooltipProps {
  idPrefix: string;
  tooltipText: string;
  tooltipExtendedText?: string;
  icon: any;
  placement?: string;
  hide?: boolean;
}

class ListGridConnectionStatusTooltip extends React.Component<ListGridConnectionStatusTooltipProps> {
  /**
   * Renders the list grid data tooltip with availability dot
   */
  render() {
    const {idPrefix, icon, tooltipText, placement, children, hide, tooltipExtendedText} = this.props;
    return !hide ? (
      <ListGridConnectionStatusTooltipStyle
        id={`${idPrefix}-tooltip-style`}
        arrow
        placement={placement ? placement : 'top'}
        enterDelay={1000}
        top={'5px'}
        title={
          <>
            <div style={{lineHeight: '18px', margin: '11px 13px', maxWidth: '330px'}}>
              {icon}
              <BoldSpan>{tooltipText}</BoldSpan>
              {tooltipExtendedText ? <BoldSpan style={{color: `${GRID_CELL_TEXT_DURATION_COLOR}`, paddingLeft: '16px'}}>{tooltipExtendedText}</BoldSpan> : null}
            </div>
          </>
        }
      >
        <span>{children}</span>
      </ListGridConnectionStatusTooltipStyle>
    ) : (
      <>
        <span>{children}</span>
      </>
    );
  }
}

export default ListGridConnectionStatusTooltip;
