/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Total Data Usage ToolTip Style
 */

import React from 'react';
import {ColorProperty} from 'csstype';

import SeriesPrefixIcon from '../../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesLabel from '../../../common/elements/chart/ToolTip/SeriesLabel';
import SeriesValue from '../../../common/elements/chart/ToolTip/SeriesValue';
import ToolTipLabel from '../../../common/elements/chart/ToolTip/ToolTipLabel';
import {FONT_WEIGHT_SEMIBOLD} from '../../../../utils/constants';
import {formatDataUsageValue} from '../../flightDetailsUtil';

export interface TotalDataUsageToolTipProps {
  prefixIconColor?: ColorProperty;
  dataPercentage: number;
  dataUsage: number;
  chartLabel: string;
}

const TotalDataUsageToolTip: React.FC<TotalDataUsageToolTipProps> = ({
  prefixIconColor,
  dataPercentage,
  dataUsage,
  chartLabel
}: TotalDataUsageToolTipProps) => {
  return (
    <ToolTipLabel width={'244px'}>
      <SeriesPrefixIcon color={prefixIconColor} width={'12px'} />
      <SeriesLabel label={chartLabel} width={'140px'} fontWeight={FONT_WEIGHT_SEMIBOLD} />
      <SeriesValue value={dataPercentage + ' %'} width={'62px'} textAlign={'right'} />
      <SeriesPrefixIcon color={'#ffffff00'} width={'12px'} />
      <SeriesLabel label={'Data Used'} width={'60px'} />
      <SeriesValue value={formatDataUsageValue(dataUsage, true)} width="142px" textAlign={'right'} />
    </ToolTipLabel>
  );
};

export default TotalDataUsageToolTip;
