/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: FilterUtil methods
 */

import {FilterRow} from '../store/reducers/FiltersReducer';
import {NetworkCapability} from './constants';

export interface FilterOptionsType {
  optionValue: string;
  optionKey: string;
}

export interface FilterType {
  id: string;
  title: string;
  getValues?: any;
  getFilterStrings?: any;
  cleanFilterString?: any;
}

/**
 * Util function to determine valid filter set
 * @param filters list of filters
 * @return list of filters without invalid filters
 */
export const pruneInvalidFilters = (filters: any) => {
  return filters.filter(
    (filter: any) => filter.domainOptionsKey && filter.rangeOptionsKeys && filter.rangeOptionsKeys.length
  );
};

/**
 * Distills the given current set of filters into just valid filters and values, combining multiple instances of filter
 * values for single filter fields
 * @param filterTypes array of FilterTypes (eg. filter type definitions, containing domainOptionsKey)
 * @param currentFilters current set of filter properties (eg. current filter values, containing rangeOptionsKeys)
 * @returns object where the keys are filter field names and the values are arrays of specified filter values
 */
export const distillFilters = (filterTypes: Array<FilterType>, currentFilters: Array<FilterRow>) => {
  // Only use the valid filters
  const validFilters = pruneInvalidFilters(currentFilters);

  // Group the filters by type
  return filterTypes.reduce((distilledFilters: Record<string, Array<Array<string>>>, filterType: FilterType) => {
    validFilters
      // Only look at the filter values for this filter type
      ?.filter((filterProp) => filterProp.domainOptionsKey === filterType.id)
      // Loop through each filter value for this filter type
      .forEach((filterProp) => {
        // Get the filter value by using cleanFilterString if it's defined, otherwise just use the rangeOptionsKeys as is.
        const filterValue = filterType.cleanFilterString
          ? filterProp.rangeOptionsKeys.map((key) => filterType.cleanFilterString(key))
          : filterProp.rangeOptionsKeys;

        // Add the filter value to the list of filters for this filter type
        if (distilledFilters[filterProp.domainOptionsKey]) {
          // Prune out any duplicates before appending the new values
          distilledFilters[filterProp.domainOptionsKey].push(
            ...filterValue.filter(
              (singleValue) => distilledFilters[filterProp.domainOptionsKey].indexOf(singleValue) === -1
            )
          );
        } else {
          distilledFilters[filterProp.domainOptionsKey] = [...filterValue];
        }
      });
    return distilledFilters;
  }, {});
};

/**
 * Filters the items with using the provided filters.
 *
 * Note: The items must contain the domainOptionsKey as a key in order for this to work.
 *
 * @param items items to filter (ie. flights)
 * @param filterTypes array of FilterTypes (eg. filter type definitions, containing domainOptionsKey)
 * @param currentFilters current set of filter properties (eg. current filter values, containing rangeOptionsKeys)
 * @returns array of items that satisfy the filter criteria
 */
export const getFilteredItems = (items: any[], filterTypes: Array<FilterType>, currentFilters: Array<FilterRow>) => {
  // Group the filters by type
  const filters = distillFilters(filterTypes, currentFilters);

  // Apply the filters on the items
  return items.filter((item: any) => {
    // Go through each filter type (eg. domainOptionsKey)
    for (const domainOptionsKey in filters) {
      const rangeOptionsKeys = [].concat.apply([], filters[domainOptionsKey]);
      // If the item's field does not match any of the filter values for this filter type, filter it out by returning null. Otherwise, include the item by returning it.
      if (rangeOptionsKeys.indexOf('Dual Band') > -1 && item['networkCapability'] === NetworkCapability.DUAL_BAND) {
        return item;
      } else if (!rangeOptionsKeys.includes(item[domainOptionsKey])) {
        return null;
      }
    }
    return item;
  });
};

/**
 * Updates the options for filtering the various filterable columns/fields
 */
export const getFilterOptions = (filterValues: any, filterTypes: FilterType[]) => {
  const domainOptions: any[] = [];
  const rangeOptions = {};
  filterTypes.forEach((filterType: FilterType) => {
    domainOptions.push({
      optionKey: filterType.id,
      optionValue: filterType.title
    });

    rangeOptions[filterType.id] = filterType.getValues(filterValues);
  });

  return {domainOptions, rangeOptions};
};

/**
 * Converts a given list of objects into a filter range options
 * @param collection Collection of objects with string values to convert to a filter range option
 * @param fieldName Field name in the object of the collection
 * @returns Array of filter range key/value pairs
 */
export const convertObjectToFilterRangeOptions = (
  collection: any,
  fieldName: string,
  fieldValueMap?: string
): Array<FilterOptionsType> =>
  collection
    .filter((item: any) => Boolean(item[fieldName]))
    .map((item: any) => ({
      optionKey: fieldValueMap ? item[fieldValueMap] : item[fieldName],
      optionValue: item[fieldName]
    }))
    .sort((a: FilterOptionsType, b: FilterOptionsType): number => {
      return a.optionKey > b.optionKey ? 1 : -1;
    })
    .filter((curOption, i, options) => !i || curOption.optionKey !== options[i - 1].optionKey);

/**
 * Translate filters from array of objects to simple object
 * @param filters List of selected Filters
 * @return filters in key value format
 */
export const translateFiltersArrayToObject = (filters: FilterRow[]) =>
  filters.reduce(
    (acc: Record<string, any>, filter: FilterRow) =>
      filter.domainOptionsKey && filter.rangeOptionsKeys
        ? {
            ...acc,
            [`${filter.filterId}-${filter.domainOptionsKey}`]: filter.rangeOptionsKeys
          }
        : acc,
    {}
  );

/**
 * Gives only applied filters data for print header
 * @param tailIdFilter List of selected tailId filter values
 * @param customerFilters List of selected customer filter values
 * @param serialNumberFilters List of selected serial number filter values
 * @param networkFilters List of selected network filter values
 * @param aircraftTypeFilters List of selected Aircraft Type filter values
 * @return applied filters in key pair form
 */
export const printHeaderFilters = (
  tailIdFilter: string[],
  customerFilters: string[],
  serialNumberFilters: string[],
  networkFilters: string[],
  aircraftTypeFilters: string[]
) => {
  let allFilters = {};
  if (tailIdFilter.length > 0) {
    allFilters['Tail Id'] = tailIdFilter;
  }
  if (customerFilters.length > 0) {
    allFilters['Customer'] = customerFilters;
  }
  if (serialNumberFilters.length > 0) {
    allFilters['Serial Number'] = serialNumberFilters;
  }
  if (networkFilters.length > 0) {
    allFilters['Network'] = networkFilters;
  }
  if (aircraftTypeFilters.length > 0) {
    allFilters['Aircraft Type'] = aircraftTypeFilters;
  }
  return allFilters;
};
