/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: View Graphs Side Panel on flight details page
 */

import React from 'react';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import {ChartTitle, ViewGraphsIcon} from '@viasat/insights-components';

import * as colors from '../common/theme/Colors';
import {useStore} from '../../store/Store';
import StyledButton from '../common/StyledButton';
import {getElementIdFromSectionBase} from '../../utils/ElementIdUtils';
import {FlightDetailsAction} from '../../store/reducers/FlightDetailsReducer';

const TopBorder = styled.div<{width: number; gutter: number}>`
  height: 0px;
  width: ${(props) => Math.max(Number(props.width) - 5 - props.gutter * 2, 0)}px;
  border: 3px solid ${colors.FLIGHT_DETAILS_PRIMARY_BLUE};
  background-color: ${colors.FLIGHT_DETAILS_PRIMARY_BLUE};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: absolute;
  z-index: 1;
`;

const RightContainerCard = styled.div<{width: number; gutter: number}>`
  width: ${(props) => Math.max(Number(props.width) - props.gutter * 2, 0)}px;
  padding: ${(props) => props.gutter}px ${(props) => props.gutter}px 0;
  overflow: hidden;
  background-color: #ffffff00;
  .MuiExpansionPanelDetails-root {
    flex-direction: column;
  }
`;

export const RightInnerContainerCard = styled.div`
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dadada;
  position: relative;
`;

export const CardDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const ChartCardTitleContainer = styled.span`
  margin: 0 0 12px 10px;
`;

export const CardDisplayRowContainer = styled.div`
  padding: 8px 8px 8px 12px;
  cursor: pointer;
  width: calc(100% - 20px);
  &:hover {
    background: ${colors.FLIGHT_DETAILS_BACKGROUND_DARK};
  }

  .MuiCheckbox-colorPrimary {
    color: ${colors.FLIGHT_DETAILS_PRIMARY_BLUE};
  }

  .MuiCheckbox-root {
    margin-right: 8px;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const TitleContainer = styled.span`
  position: relative;
  top: 2px;
`;

export const ButtonTray = styled.div`
  padding: 8px 0 10px 0;
  button {
    margin-right: 12px;
    width: 221px;
  }
`;

interface ViewGraphsProps {
  width: number;
  gutter?: number;
  isLoading?: boolean;
  cards: any[];
}

const ViewGraphs: React.FC<ViewGraphsProps> = ({width, gutter, cards}: ViewGraphsProps) => {
  const {
    dispatch,
    store: {
      flightDetails: {hiddenCards}
    }
  } = useStore();

  const setDisplay = (hide: boolean, id: string) =>
    dispatch({
      type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY,
      payload: {
        hide,
        id
      }
    });

  const reset = () =>
    dispatch({
      type: FlightDetailsAction.RESET_FLIGHT_DETAILS_VIEW_GRAPHS,
      payload: {}
    });

  return (
    <RightContainerCard id="viewGraphs--container__card" width={width} gutter={gutter}>
      <RightInnerContainerCard>
        <TopBorder width={width} gutter={gutter} />
        <CardDisplayContainer>
          <ChartCardTitleContainer>
            <ChartTitle
              getFullElementId={(name, type) => `viewGraphs--chartTitle__${name}-${type}`}
              Icon={ViewGraphsIcon}
              iconStyles={{sx: {width: 18, height: 18}}}
              title={'View Graphs'}
              showHelpIcon={false}
            />
          </ChartCardTitleContainer>
          {cards
            .filter((card) => !card.disabled)
            .map(({id, hash, title}) => (
              <CardDisplayRowContainer key={hash} onClick={() => setDisplay(!hiddenCards.includes(hash), hash)}>
                <Checkbox
                  id={`${id}-checkbox`}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  color={'primary'}
                  value={hash}
                  checked={!hiddenCards.includes(hash)}
                  style={{padding: '0px 8px 0px 0px'}}
                />
                <TitleContainer>{title}</TitleContainer>
              </CardDisplayRowContainer>
            ))}
        </CardDisplayContainer>
        <ButtonTray>
          <StyledButton id={getElementIdFromSectionBase('viewGraphs', 'reset', 'button')} onClick={() => reset()}>
            Reset
          </StyledButton>
        </ButtonTray>
      </RightInnerContainerCard>
    </RightContainerCard>
  );
};

export default ViewGraphs;
