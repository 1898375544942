/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Query to return list of flight path points
 */
import {Query} from '../types';
import {forEach} from 'lodash';
import {ConnectivityOutlookStatus} from '../../../components/connectivityOutlook/ConnectivityOutlookUtils';
import {backFillConnectivityEvent} from '../../../components/connectivityOutlook/FlightPathEventUtil';

interface IEvent {
  eventType: string;
  eventName: string;
  availability: ConnectivityOutlookStatus;
  displayName: ConnectivityOutlookStatus;
}

export interface IFlightPlanEvents {
  flightPlanId: string;
  events: IFlightPathPoint[];
}

export interface IFlightPathPoint {
  event: IEvent;
  events: any[];
  lat: number;
  lng: number;
  timestamp: string;
  availability: ConnectivityOutlookStatus;
  behindZoneId: number;
  aheadZoneId: number;
  relativeTime: number;
}

export interface IFlightPath {
  id?: string;
  flightPath: IFlightPathPoint[];
  start: IFlightPathPoint;
  end: IFlightPathPoint;
  futureFlightPath?: IFlightPathPoint[];
}
/**
 * Generates list of flight path points for a given flight plan
 * @param flightPlanEvents raw flight plans from the api
 * @returns list of flight path points
 */
const getFlightPathForFlightPlan = (flightPlanEvents: IFlightPlanEvents): IFlightPathPoint[] => {
  const fightPathInfo: IFlightPathPoint[] = [];
  let serviceInterruptionDetected = false;
  const backFilledFlightPlan = backFillConnectivityEvent(flightPlanEvents);
  forEach(backFilledFlightPlan.events, (flightPath) => {
    if (
      flightPath.availability === ConnectivityOutlookStatus.SATELLITE_HANDOVER &&
      flightPath.event.eventType === 'BEGIN_BAD_CONNECTION'
    ) {
      serviceInterruptionDetected = true;
    }
    if (serviceInterruptionDetected && flightPath.event.eventType === 'NO_CHANGE') {
      return;
    }
    if (flightPath.event.eventType !== 'END_BAD_CONNECTION') {
      fightPathInfo.push({
        event: flightPath.event,
        events: [],
        availability: flightPath.availability,
        lat: flightPath.lat,
        lng: flightPath.lng,
        behindZoneId: flightPath.behindZoneId,
        aheadZoneId: flightPath.aheadZoneId,
        relativeTime: flightPath.relativeTime,
        timestamp: flightPath.timestamp
      });
    }
    if (flightPath.event.eventType === 'END_BAD_CONNECTION') {
      serviceInterruptionDetected = false;
    }
  });
  return fightPathInfo;
};
/**
 * Transforms the given raw flight path data into (required) flight path info data
 * @param flightPlans list of flight plans with flight path
 * @returns Transformed flight path info for each flight plan
 */
const transformRawFlightPath = (flightPlans: IFlightPlanEvents[]): IFlightPath[] | null => {
  if (!flightPlans) return null;
  const actualFlightPath: IFlightPath[] = [];
  forEach(flightPlans, (flightPlanRaw) => {
    const flightPathInfo = getFlightPathForFlightPlan(flightPlanRaw);
    actualFlightPath.push({
      id: flightPlanRaw.flightPlanId,
      flightPath: flightPathInfo,
      start: flightPathInfo[0],
      end: flightPathInfo[flightPathInfo.length - 1],
      futureFlightPath: []
    });
  });
  return actualFlightPath;
};

const flightPathQuery: Query<IFlightPath[]> = {
  route: 'connectivityPlanner/flightPath',
  transform: transformRawFlightPath
};

export default flightPathQuery;
