/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * This is a mouseover listener attached to the whole
 * window that returns the current client x/y.
 *
 * Also included is the logic to calculate a bounding box
 */

import {useState, useEffect} from 'react';
import moment from 'moment';
import {isNil} from 'lodash';

import {getElementId} from '../../utils/ElementIdUtils';
import {MAP_ELID_SECTION, MAP_ELID_MAP_SUBSECTION} from './../../utils/constants';

const SCROLL_Y_OFFSET = 0;
const SCROLL_Y_OFFSET_MAP_HEIGHT = 370;
const Y_GUTTER = 18;

const MAP_LAYOUT_OFFSET = 34;
const TIMELINE_LAYOUT_OFFSET = 32.5;

/**
 * Listens for flight path hover events on the map and if one is found returns the x and y coordinates and the
 * associated start time of the flight path segment.
 * @returns Object consisting of the flight path segment start time, and the x and y mouse coordinates
 */
export function useMouseOverEvent() {
  const [mousePosition, setMousePosition] = useState({x: null, y: null, start: null});
  const mapId = getElementId(MAP_ELID_SECTION, MAP_ELID_MAP_SUBSECTION, 'googleMap', 'container');

  const setPosition = ($event: any) => {
    const targetStart = $event.target?.getAttribute('data-start');
    const containsMap = $event.path ? $event.path.some(({id}) => id === mapId) : false; // make sure we are in the map
    const start = isNil(targetStart) || !containsMap ? null : targetStart;

    setMousePosition({
      start,
      x: $event.clientX,
      y: $event.clientY
    });
  };
  useEffect(() => {
    window.addEventListener('mousemove', setPosition);
    return () => window.removeEventListener('mousemove', setPosition);
    // eslint-disable-next-line
  }, []);

  return mousePosition;
}

/**
 * Returns the left edge of the hover line when the mouse is hovering over the map
 * @param segmentStart The start of the hovered map segment
 * @param chartStart The start of the time series charts
 * @param parentLeftOffset The left offset of the parent element
 * @param flightStartOffset The distance from the start of the Events Timeline to the first rendered data point
 * @param flightMinuteUnit The width of a minute's worth of data in the Events Timeline
 * @returns The left edge of the hover line
 */
export const getMapHoverLineLeft = (
  segmentStart: string,
  chartStart: string,
  parentLeftOffset: number,
  flightStartOffset: number,
  flightMinuteUnit: number
): number => {
  const layoutOffset = MAP_LAYOUT_OFFSET;
  const chartStartDiff = moment.utc(segmentStart).diff(chartStart, 'minutes');
  const chartStartOffset = chartStartDiff * flightMinuteUnit;
  return layoutOffset + flightStartOffset + chartStartOffset;
};

/**
 * Returns the left edge of the hover line when the mouse is hovering over the time series charts
 * @param x The existing x coordinate
 * @param parentLeftOffset The left offset of the parent element
 * @returns The left edge of the hover line
 */
export const getTimelineHoverLeft = (x: number, parentLeftOffset: number): number => {
  return x - parentLeftOffset;
};

/**
 * Returns the time (as a string, floored to the minute) associated with the given x offset on the Events Timeline
 * @param x The x offset on the events timeline
 * @param parentLeftOffset The left offset of the parent element
 * @param chartStart The start time of the chart
 * @param flightStartOffset The distance from the start of the Events Timeline to the first rendered data point
 * @param flightMinuteUnit The width of a minute's worth of data in the Events Timeline
 * @returns The associated time as a string
 */
export const getTimelineHoverDate = (
  x: number,
  parentLeftOffset: number,
  chartStart: string,
  flightStartOffset: number,
  flightMinuteUnit: number
): string => {
  const layoutOffset = parentLeftOffset + TIMELINE_LAYOUT_OFFSET;
  const xStartOffset = flightStartOffset + layoutOffset;
  const minutesElapsed = (x - xStartOffset) / flightMinuteUnit;
  return moment.utc(chartStart).add(minutesElapsed, 'minutes').startOf('minute').toString();
};

/**
 * Returns whether the given y coordinate is inside the charts area
 * @param y The y coordinate
 * @param offsetTop The top of the charts area
 * @param scrollTop The vertical scroll distance in the charts area
 * @param isMapMinimized Whether the map is minimized
 * @returns true if the y coordinate is in the charts area, false otherwise
 */
export const getTimelineHoverInside = (y: number, offsetTop: number, scrollTop: number, isMapMinimized: boolean) => {
  return offsetTop + (SCROLL_Y_OFFSET + (isMapMinimized ? 0 : SCROLL_Y_OFFSET_MAP_HEIGHT)) - scrollTop < y;
};

/**
 * Returns the desired hoverline height
 * @param offsetTop The top of the charts area
 * @returns The desired hoverline height
 */
export const getTimelineHoverLineHeight = (offsetTop: number) => {
  return offsetTop + SCROLL_Y_OFFSET - Y_GUTTER;
};
