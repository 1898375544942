/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Query to return list of flight plans within the time duration
 */
import {orderBy} from 'lodash';
import {Query} from '../types';

interface IEvent {
  eventType: string;
  eventName: string;
  availability: string;
  displayName: string;
}

export interface FlightPlanEvents {
  flightPlanId: string;
  events: FlightEvent[];
}
export interface FlightEvent {
  event: IEvent;
  lat: number;
  lng: number;
  duration: number;
  timestamp: string;
  availability: string;
  behindZoneId: number;
  aheadZoneId: number;
  relativeTime: number;
  eventId?: number;
}

/**
 * Transforms the given raw flight plans data into (required) flight plan info data
 * @param flightPlanRaw raw flight plans from api
 * @returns sorted flight plan events data.
 */
const transformRawFlightEvent = (flightPlanEvents: FlightPlanEvents[]): FlightPlanEvents[] | null => {
  if (!flightPlanEvents) return null;
  return orderBy(flightPlanEvents, 'relativeTime');
};

const flightEventsQuery: Query<FlightPlanEvents[]> = {
  route: 'connectivityPlanner/connectivityEvents',
  transform: transformRawFlightEvent
};

export default flightEventsQuery;
