/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connetivity Usage Metrics Query
 */

import {convertToGb} from '../../../components/lists/tail/util/tailListUtil';
import {Query} from '../types';

export interface IConnectivityUsageMetrics {
  aircraftId: string;
  tailId: string;
  macAddress: string;
  ipAddress: string;
  roamingDataUsed: number;
  planDataUsed: number;
  totalDataUsed: number;
}

interface ConnectivityUsageMetricsRaw {
  aircraftId: string;
  tailId: string;
  macAddress: string;
  ipAddress: string;
  roamingUsage: number;
  inRegion: number;
  totalUsage: number;
  aircraftCreatedDate: string;
  aircraftDeactivatedDate: string;
}

const transformConnecticityUsageMetrics = (
  rawConnectivityUsageMetrics: ConnectivityUsageMetricsRaw[] | null
): IConnectivityUsageMetrics[] | null => {
  if (!rawConnectivityUsageMetrics) return null;
  const connectivityUsageMetrics: IConnectivityUsageMetrics[] = rawConnectivityUsageMetrics.map((usageMetrics) => {
    return {
      aircraftId: usageMetrics.aircraftId,
      tailId: usageMetrics.tailId,
      macAddress: usageMetrics.macAddress,
      ipAddress: usageMetrics.ipAddress,
      roamingDataUsed: convertToGb(usageMetrics.roamingUsage),
      planDataUsed: convertToGb(usageMetrics.inRegion),
      totalDataUsed: convertToGb(usageMetrics.totalUsage),
      aircraftCreatedDate: usageMetrics.aircraftCreatedDate,
      aircraftDeactivatedDate: usageMetrics.aircraftDeactivatedDate
    };
  });
  return connectivityUsageMetrics;
};

const connectivityUsageMetricsQuery: Query<IConnectivityUsageMetrics[]> = {
  route: 'tailList/connectivityUsageMetrics',
  transform: transformConnecticityUsageMetrics
};

export default connectivityUsageMetricsQuery;
 