/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Data usage card wrapper
 */

import React from 'react';
import {isNil} from 'lodash';
import {ITotalDataUsage} from '../../store/queries/summaryMetrics/totalDataUsageQuery';
import {ITotalConnectedTime} from '../../store/queries/summaryMetrics/totalConnectedTimesQuery';
import {ITotalRawDataUsage} from '../../store/queries/summaryMetrics/totalRawDataUsageQuery';
import LinearProgressBar from '../common/LinearProgressBar';
import {ChartWrapper} from '@viasat/insights-components';
import {ChartType} from '../common/elements/chart/types';
import {MAP_POPUP_DEFAULT_BORDER, SUMMERY_METRICS_TOTAL_DATA_USAGE_CHART_PALETTE} from '../common/theme/Colors';
import {IntlShape, useIntl} from 'react-intl';
import PieChart from '../common/elements/chart/PieChart';
import {formatConfig} from '../common/elements/chart/chartUtils';
import {
  CardContainer,
  CardColumn,
  CardRowContainter,
  HorizontalDivider,
  ChartContainer,
  ProgressBarRow,
  ProgressBarContainer,
  NoDataAvailable,
  ChartLegend
} from './SummaryMetricsStyles';
import CardRowItem from './usageCards/CardRowItem';
import {convertUsageData, getChartData, prepareProgressBarConfig, tooltip} from './usageCards/utils';
import {formatChartDateRange} from './summaryMetricsUtil';
import {ChartCard} from './SummaryMetricsPage';

export interface DataUsageCardProps {
  totalDataUsageData: ITotalDataUsage;
  totalRawDataUsageData: ITotalRawDataUsage;
  totalConnectedTime: ITotalConnectedTime;
  isLoading: boolean;
  startDate: string;
  endDate: string;
  draggableProps?: any;
}

interface IChartData {
  in_flight_usage: number;
  on_ground_usage: number;
}

/**
 * create chart config
 * @param totalDataUsageConfig
 * @returns return chart configuration
 */
const formatChartConfig = (intl: IntlShape, cardData: IChartData, isLoading: boolean) => {
  const inFlightUsage: number = cardData.in_flight_usage;
  const onGroundUsage: number = cardData.on_ground_usage;
  const id = 'summaryMetrics.totalDataUsage.pieChart';
  const title = null;
  const chartType = ChartType.PIE;
  const palette = SUMMERY_METRICS_TOTAL_DATA_USAGE_CHART_PALETTE;
  const highchartsIdBase = 'totalDataUsage';
  const chartSubTitle = '';
  const noDataChartTitle = 'No Data Available';
  const chartTitle = '';
  const height = 200;
  const size = 186;
  const hideLegend = true;

  let chartData = [];
  const chartDataValues = getChartData(inFlightUsage, onGroundUsage);
  const defaultChartPalette = [];
  if (chartDataValues && chartDataValues?.totalDataUsage) {
    chartData = [
      {
        id: 'inFlightUsage',
        name: 'In-flight',
        y: chartDataValues?.inFlightUsageData,
        percent: chartDataValues?.inFlightUsagePercentage,
        visible: true
      },
      {
        id: 'onGroundUsage',
        name: 'On-ground',
        y: chartDataValues?.onGroundUsageData,
        percent: chartDataValues?.onGroundUsagePercentage,
        visible: true
      }
    ];
  }
  const chartConfig = {
    id,
    defaultChartPalette,
    title,
    chartTitle,
    chartSubTitle,
    chartData,
    chartType,
    palette,
    tooltip,
    highchartsIdBase,
    noDataChartTitle,
    height,
    hideLegend,
    size
  };

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer className="chart-pie">
      <ChartWrapper
        getFullElementId={(name, type) => `summaryMetrics--totalDataUsage--pieChart__${name}-${type}`}
        isLoading={isLoading}
        isError={false}
        chartHeight={195}
      >
        {Object.keys(formattedConfig).length === 0 ? <></> : <PieChart {...formattedConfig} />}
      </ChartWrapper>
    </ChartContainer>
  );
};

const DataUsageCard: React.FC<DataUsageCardProps> = ({
  totalDataUsageData,
  totalRawDataUsageData,
  totalConnectedTime,
  isLoading,
  startDate,
  endDate,
  draggableProps
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  const idPrefix = 'summaryMetrics-totalDataUsage';
  const hasUsagePercentage = !isNil(totalDataUsageData?.usage_percentage);
  const progressBar = hasUsagePercentage
    ? prepareProgressBarConfig(
        Number(totalDataUsageData?.usage_percentage),
        Number(totalDataUsageData?.total_data_allowed)
      )
    : null;

  // convert total raw data usage from gb to mb
  const inFlightUsage = totalRawDataUsageData?.dataUsage * 1000 - totalDataUsageData?.on_ground_usage;

  const chartData = {
    in_flight_usage: inFlightUsage,
    on_ground_usage: totalDataUsageData?.on_ground_usage
  };

  const dataUsedTotal = totalRawDataUsageData?.dataUsage;
  const planUsage = (totalRawDataUsageData?.dataUsage - totalRawDataUsageData?.roamingDataUsage);
  const roamingDataUsage = totalRawDataUsageData?.roamingDataUsage;

  const averageDataPerHour =
    totalRawDataUsageData && totalConnectedTime
      ? (totalRawDataUsageData?.dataUsage) / (totalConnectedTime.totalConnectedTime / 60)
      : null;

  return (
    <ChartCard
      id="summaryMetrics-totalDataUsage"
      isLoading={isLoading}
      title="Data Usage"
      helpText={intl.formatMessage({id: 'summary_metrics.total_data_usage_chart.title_tooltip'})}
      borderColor={MAP_POPUP_DEFAULT_BORDER}
      draggableProps={draggableProps}
    >
      <>
        {!isNil(totalDataUsageData) && dataUsedTotal ? (
          <CardContainer>
            <CardColumn hasBorder>
              <CardRowContainter>
                {hasUsagePercentage && (
                  <>
                    <CardRowItem
                      rowData={{
                        id: `${idPrefix}-totalDataUsed`,
                        title: 'Total Data Used',
                        value: `${convertUsageData(dataUsedTotal, true, false)} GB`
                      }}
                    />
                    <ProgressBarRow marginTop="8" />
                    <ProgressBarContainer id={`${idPrefix}-progressBar__container`}>
                      <LinearProgressBar
                        value={progressBar.currentProgress >= 100 ? 100 : progressBar.currentProgress}
                        color={progressBar.barColor}
                      />
                      <div id={`${idPrefix}-progressBar__start`} className="start">
                        {progressBar.start}
                      </div>
                      <div id={`${idPrefix}-progressBar__end`} className="end">
                        {progressBar.end}
                      </div>
                    </ProgressBarContainer>
                    <ProgressBarRow marginTop="40" />
                  </>
                )}
                {!hasUsagePercentage && (
                  <>
                    <CardRowItem
                      rowData={{
                        id: `${idPrefix}-totalDataUsed`,
                        title: 'Total Data Used',
                        value: `${convertUsageData(dataUsedTotal, true, false)} GB`
                      }}
                    />
                    <ProgressBarRow marginTop="8" />
                  </>
                )}
                {hasUsagePercentage && (
                  <>
                    <CardRowItem
                      rowData={{
                        id: `${idPrefix}-percentageUsed`,
                        title: 'Percentage Used',
                        value: `${Math.ceil(totalDataUsageData?.usage_percentage)} %`
                      }}
                    />
                  </>
                )}
                {totalDataUsageData?.isRegionalPlan || isNil(totalDataUsageData?.isRegionalPlan) ? (
                  <>
                    <HorizontalDivider />
                    <CardRowItem
                      rowData={{
                        id: `${idPrefix}-regionalData`,
                        title: 'Regional Data',
                        value: `${convertUsageData(planUsage, true, false)} GB`
                      }}
                    />
                    <CardRowItem
                      rowData={{
                        id: `${idPrefix}-roamingData`,
                        title: 'Roaming Data',
                        value: `${convertUsageData(roamingDataUsage, true, false)} GB`
                      }}
                    />
                    <CardRowItem
                      rowData={{
                        id: `${idPrefix}-averageData`,
                        title: 'Average Data Used Per Hour',
                        value: averageDataPerHour ? `${convertUsageData(averageDataPerHour, true, false)} GB` : '--'
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}

                <ProgressBarRow marginTop="16" />
                <HorizontalDivider />
                <CardRowItem
                  rowData={{
                    id: `${idPrefix}-inFlightUsage`,
                    title: 'In-flight',
                    value: `${convertUsageData(inFlightUsage, true)} GB`,
                    includeAvailabilityDot: true,
                    availabilityDotColor: SUMMERY_METRICS_TOTAL_DATA_USAGE_CHART_PALETTE[0]
                  }}
                />

                <CardRowItem
                  rowData={{
                    id: `${idPrefix}-onGroundUsage`,
                    title: 'On-ground',
                    value: `${convertUsageData(totalDataUsageData?.on_ground_usage, true)} GB`,
                    includeAvailabilityDot: true,
                    availabilityDotColor: SUMMERY_METRICS_TOTAL_DATA_USAGE_CHART_PALETTE[1]
                  }}
                />
              </CardRowContainter>
            </CardColumn>
            <CardColumn>
              {formatChartConfig(intl, chartData, isLoading)}
              <ChartLegend id="summary-metrics-data-usage__legend">
                {formatChartDateRange(startDate)}
                {' - '}
                {formatChartDateRange(endDate)}
              </ChartLegend>
            </CardColumn>
          </CardContainer>
        ) : (
          <NoDataAvailable>No data available.</NoDataAvailable>
        )}
      </>
    </ChartCard>
  );
};

export default DataUsageCard;
