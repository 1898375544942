/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Data Usage Trend query
 */

import {Query} from '../types';

export interface IMonthlyUsage {
  inFlightUsage: number;
  onGroundUsage: number;
  connectedTime: number;
  month: string;
  year: string;
}

export const dataUsageTrendQuery1: Query<IMonthlyUsage[]> = {
  route: 'summaryMetrics/dataUsageTrend/first',
  transform: null
};

export const dataUsageTrendQuery2: Query<IMonthlyUsage[]> = {
  route: 'summaryMetrics/dataUsageTrend/second',
  transform: null
};

export const dataUsageTrendQuery3: Query<IMonthlyUsage[]> = {
  route: 'summaryMetrics/dataUsageTrend/third',
  transform: null
};

export const dataUsageTrendQuery4: Query<IMonthlyUsage[]> = {
  route: 'summaryMetrics/dataUsageTrend/fourth',
  transform: null
};

export const dataUsageTrendQuery5: Query<IMonthlyUsage[]> = {
  route: 'summaryMetrics/dataUsageTrend/fifth',
  transform: null
};

export const dataUsageTrendQuery6: Query<IMonthlyUsage[]> = {
  route: 'summaryMetrics/dataUsageTrend/sixth',
  transform: null
};
