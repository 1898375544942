/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Satellite Info Query
 */
import {Query} from '../types';

export interface SatelliteInfoData {
  network_region_code: string;
  network_region_name: string;
  network: string;
}

export const useSatelliteInfoQuery: Query<SatelliteInfoData> = {
  route: `flightDetails/satelliteInfo`,
  transform: null
};

export default useSatelliteInfoQuery;
