/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: hidden columns encode/decode hexadecimal string methods
 */

import {
  MIN_COLUMN_ID_TYPE,
  MAX_COLUMN_ID_TYPE,
  HEX_STRING_BASE_16,
  HEX_STRING_PAD_LENGTH,
  HEX_STRING_INVALID,
  UNKNOWN_COLUMNS_MSG,
  ColumnIdEnumType,
  ColumnIdType,
  ConnectivityPlannerColumnEnumIdType,
  AircraftStatusListColumnIdEnumType,
  TailListColumnIdEnumType
} from './hiddenColumnsConstants';
import {FlightListColumnId} from './flight/flightListUtil';
import {FLIGHT_LIST_STORE_CONTEXT} from '../../store/reducers/FlightListReducer';
import {TailListColumnId} from './tail/util/tailListUtil';
import {TAIL_LIST_STORE_CONTEXT} from '../../store/reducers/TailListReducer';
import {FlightPlanListColumnId} from '../connectivityOutlook/ConnectivityOutlookUtils';
import {CONNECTIVITY_PLANNER_STORE_CONTEXT} from '../../store/reducers/ConnectivityPlannerReducer';
import {UNKNOWN_COLUMN_ID_NAME, getColumnIdEnumName} from './listUtils';
import {AIRCRAFT_STATUS_LIST_STORE_CONTEXT} from '../../store/reducers/AircraftStatusListReducer';
import {AircraftStatusListColumnId} from '../lists/aircraftStatus/util/aircraftStatusListUtil';

/**
 * Convert Column Id Name to Column Id Enum
 * @param columnId column id name as string
 * @param columnIdEnum ColumnIdType to get names from
 * @returns EnumType as ColumnIdType, otherwise undefined
 */
const convertColumnIdToEnumType = (columnId: string, columnIdEnum: any): ColumnIdType => {
  return columnId && columnId.length > 0 ? (columnIdEnum[columnId] as typeof columnIdEnum) : undefined;
};

/**
 * Convert Array of Column Id Names to Column Id Enums
 * Filters 'undefined' enum types and pushes column id name onto invalidColumnIds array for logging
 * @param columnIds array of column id names as string
 * @param columnIdEnum ColumnIdType to get names from
 * @returns JSON object { Array of EnumType as ColumnIdType, Array of InvalidColumnIds as string }
 */
export const convertColumnIdsToEnumTypes = (
  columnIds: string[],
  columnIdEnum:
    | ColumnIdEnumType
    | ConnectivityPlannerColumnEnumIdType
    | AircraftStatusListColumnIdEnumType
    | TailListColumnIdEnumType
): {enumTypes: ColumnIdType[]; invalidColumnIds: string[]} => {
  return columnIds && columnIds.length > 0
    ? columnIds.reduce(
        (result, id) => {
          const enumType = convertColumnIdToEnumType(id, columnIdEnum);

          if (enumType !== undefined) {
            return {...result, enumTypes: [...result.enumTypes, enumType]};
          }

          return {...result, invalidColumnIds: [...result.invalidColumnIds, id]};
        },
        {enumTypes: [], invalidColumnIds: []}
      )
    : {enumTypes: [], invalidColumnIds: []};
};

/**
 * Check for valid ColumnIdType enum value is numeric and between MIN/MAX
 * @param columnIdType ColumnIdType enum to validate
 * @returns true if valid, otherwise false
 */
export const isValidColumnIdType = (columnIdType: ColumnIdType): boolean => {
  return (
    columnIdType !== undefined &&
    !isNaN(columnIdType) &&
    columnIdType >= MIN_COLUMN_ID_TYPE &&
    columnIdType <= MAX_COLUMN_ID_TYPE
  );
};

/**
 * Convert Column Id Type Enum to Hex (2-char, lowercase, leading 0 padding)
 * ie. '00', '0a', '0f', 'a0', 'ff', '??', etc.
 * @param columnIdType ColumnIdType enum to convert
 * @returns hexadecimal string if valid, otherwise INVALID_HEX_STRING
 */
export const convertColumnIdTypeToHex = (columnIdType: ColumnIdType): string => {
  return isValidColumnIdType(columnIdType)
    ? columnIdType.toString(HEX_STRING_BASE_16).padStart(HEX_STRING_PAD_LENGTH, '0')
    : HEX_STRING_INVALID;
};

/**
 * Convert Array of Column Id Names to Array of Hexes (2-char, lowercase, leading 0 padding)
 * @param columnIds array of ColumnId names to convert
 * @param columnIdEnum ColumnIdType to get names from
 * @returns JSON object { Array of Hexes as string, Array of InvalidColumnIds as string }
 */
export const convertColumnIdsToHexes = (
  columnIds: string[],
  columnIdEnum:
    | ColumnIdEnumType
    | ConnectivityPlannerColumnEnumIdType
    | AircraftStatusListColumnIdEnumType
    | TailListColumnIdEnumType
): {hexes: string[]; invalidColumnIds: string[]} => {
  const {enumTypes: columnIdTypes, invalidColumnIds} = convertColumnIdsToEnumTypes(columnIds, columnIdEnum);
  const hexes = columnIdTypes.map((type) => convertColumnIdTypeToHex(type));
  return {hexes: hexes, invalidColumnIds: invalidColumnIds};
};

/**
 * Encode Column Id Names to a Hex String (concatenated, from left(MIN_COLUMN_ID_TYPE) to right(MAX_COLUMN_ID_TYPE))
 * @param columnIds array of ColumnId names to encode
 * @param columnIdEnum ColumnIdType to get names from
 * @returns  JSON object { Hexadecimal string, Array of InvalidColumnIds as string }
 */
export const encodeColumnIdsToHexString = (
  columnIds: string[],
  columnIdEnum:
    | ColumnIdEnumType
    | ConnectivityPlannerColumnEnumIdType
    | AircraftStatusListColumnIdEnumType
    | TailListColumnIdEnumType
): {encodedHexString: string; invalidColumnIds: string[]} => {
  const {hexes, invalidColumnIds} = convertColumnIdsToHexes(columnIds, columnIdEnum);
  return {encodedHexString: hexes.join(''), invalidColumnIds: invalidColumnIds};
};

/**
 * Convert Hex to ColumnId name
 * @param hex string to convert
 * @param columnIdEnum ColumnId Enum Type to convert to
 * @returns ColumnId name, otherwise undefined
 */
export const convertToHexColumnId = (hex: string, columnIdEnum: any): string => {
  if (hex && hex.length > 0) {
    const value = parseInt(hex, HEX_STRING_BASE_16);
    const columnId = !isNaN(value) ? getColumnIdEnumName(value, columnIdEnum) : undefined;
    return columnId && columnId !== UNKNOWN_COLUMN_ID_NAME ? columnId : undefined;
  }
};

/**
 * Convert Array of Hexes (2-char, lowercase, leading 0 padding) to Array of ColumnId names
 * @param hexes array of hex values to convert
 * @param columnIdEnum ColumnId Enum Type to convert to
 * @returns JSON object { Array of ColumnIds as string, Array of InvalidColumnIds as string }
 */
export const convertHexesToColumnIds = (
  hexes: string[],
  columnIdEnum: any
): {columnIds: string[]; invalidColumnIds: string[]} => {
  if (hexes && hexes.length > 0) {
    const rawColumnIds = hexes.map((hex) => convertToHexColumnId(hex, columnIdEnum));
    return rawColumnIds.reduce(
      (result, id) => {
        if (id) {
          return {...result, columnIds: [...result.columnIds, id]};
        }

        if (!result.invalidColumnIds.includes(UNKNOWN_COLUMNS_MSG)) {
          return {...result, invalidColumnIds: [...result.invalidColumnIds, UNKNOWN_COLUMNS_MSG]};
        }

        return result;
      },
      {columnIds: [], invalidColumnIds: []}
    );
  }

  return {columnIds: [], invalidColumnIds: [UNKNOWN_COLUMNS_MSG]};
};

/**
 * Decode Hexadecimal String (N of 2-char, lowercase, leading 0 padding) to Array of Column Ids
 * Generates an Array of Invalid Column Ids for logging or snackbar notification
 * @param encodedHexString hexadecimal string to decode
 * @param columnIdEnum columnId enum type to decode as
 * @returns JSON object { Array of ColumnIds as string, Array of InvalidColumnIds as string }
 */
export const decodeHexStringToColumnIds = (
  encodedHexString: string,
  columnIdEnum:
    | ColumnIdEnumType
    | ConnectivityPlannerColumnEnumIdType
    | AircraftStatusListColumnIdEnumType
    | TailListColumnIdEnumType
): {columnIds: string[]; invalidColumnIds: string[]} => {
  if (encodedHexString && encodedHexString.length > 0) {
    // Regex to split each 2-char hex value
    const hexes = encodedHexString.match(new RegExp(`(?:[0-9a-f]{${HEX_STRING_PAD_LENGTH}})`, 'g'));
    const {columnIds, invalidColumnIds} = convertHexesToColumnIds(hexes, columnIdEnum);
    // Check for values that didn't regex match and push UNKNOWN_COLUMNS_MSG
    if (hexes && hexes.length * 2 !== encodedHexString.length && !invalidColumnIds.includes(UNKNOWN_COLUMNS_MSG))
      invalidColumnIds.push(UNKNOWN_COLUMNS_MSG);
    return {columnIds: columnIds, invalidColumnIds: invalidColumnIds};
  }

  return {columnIds: [], invalidColumnIds: []};
};

/**
 * Convert List Context to equivalent ColumnIdEnumType
 * ie. 'flightList' -> FlightListColumnId
 * @param listContext string
 * @returns ColumnIdEnumType, otherwise undefined
 */
export const convertListContextToColumnIdEnumType = (
  listContext: string
):
  | ColumnIdEnumType
  | ConnectivityPlannerColumnEnumIdType
  | AircraftStatusListColumnIdEnumType
  | TailListColumnIdEnumType => {
  switch (listContext) {
    case FLIGHT_LIST_STORE_CONTEXT:
      return FlightListColumnId;
    case TAIL_LIST_STORE_CONTEXT:
      return TailListColumnId;
    case CONNECTIVITY_PLANNER_STORE_CONTEXT:
      return FlightPlanListColumnId;
    case AIRCRAFT_STATUS_LIST_STORE_CONTEXT:
      return AircraftStatusListColumnId;
    default:
      console.error(`Invalid List Context: '${listContext}'`);
      return undefined;
  }
};

/**
 * Translate hidden columns from array of objects to simple object
 * @param hiddenColumns List of Hidden Columns
 * @return JSON object: hidden columns in reduced format
 */
export const translateHiddenColumnsArrayToObject = (hiddenColumns: string[], listContext: string): Object => {
  const columnIdEnum = convertListContextToColumnIdEnumType(listContext);
  if (columnIdEnum === undefined) {
    console.error(`Invalid columnIdEnum for '${listContext}', nothing to translate.`);
    return undefined;
  }

  // Handle no hidden columns
  if (hiddenColumns && hiddenColumns.length <= 0) {
    return undefined;
  }

  const {encodedHexString, invalidColumnIds} = encodeColumnIdsToHexString(hiddenColumns, columnIdEnum);
  const hiddenColumnsObj = {
    hiddenColumns: encodedHexString
  };
  if (invalidColumnIds?.length > 0)
    console.error(
      `Cannot translate INVALID IDs [${invalidColumnIds.toString()}]\nPlease check that new IDs are added to '${typeof columnIdEnum}' ColumnId Enum Types...`
    );
  return encodedHexString.length > 0 ? hiddenColumnsObj : undefined;
};

/**
 * Translate Hidden Columns Hexadecimal string to Column Ids
 * @param encodedHexString encoded hexadecimal string of hidden columns
 * @param listContext list store context name to decode as
 * @return  JSON object { Array of ColumnIds as string, Array of InvalidColumnIds as string }
 */
export const translateHiddenColumnsStringToColumnIds = (
  encodedHexString: string,
  listContext: string
): {columnIds: string[]; invalidColumnIds: string[]} => {
  return decodeHexStringToColumnIds(encodedHexString, convertListContextToColumnIdEnumType(listContext));
};
