/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Existing Case Popup
 */
import React from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  POPUP_TEXT_COLOR,
  CLOSE_ICON_COLOR,
  POPUP_BLOCK_BACKGROUND_COLOR,
  PROGRESS_BAR_BG_COLOR,
  SUPPORT_BORDER_COLOR,
  POPUP_BACKGROUND_COLOR,
  ACTIVE_BUTTON_COLOR
} from '../../../common/theme/Colors';

const ExistingCasePopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px;
  gap: 32px;
  position: relative;
  width: 600px;
  border-radius: 16px;
  background: ${POPUP_BACKGROUND_COLOR};
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 552px;
  flex: none;
  order: 0;
  flex-grow: 0;
  .Head-text {
    position: absolute;
    width: 512px;
    height: 32px;
    left: 8px;
    top: 0px;
    padding: 0px 16px;
    align-items: flex-start;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
  }
  .close-icon {
    width: 10%;
    margin-top: 0px;
    color: ${CLOSE_ICON_COLOR};
    cursor: pointer;
    position: absolute;
    right: 0px;
  }
  .text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px;
    gap: 8px;
    width: 552px;
    height: 24px;
    align-self: stretch;
  }
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 0px 0px 0px;
  gap: 16px;
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-top: 1px solid ${PROGRESS_BAR_BG_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 600px;
  height: 88px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  .no-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 4px;
    border: 1px solid ${SUPPORT_BORDER_COLOR};
    background: ${POPUP_BLOCK_BACKGROUND_COLOR};
    border-radius: 1000px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px;
    cursor: pointer;
  }
  .yes-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 4px;
    border-radius: 1000px;
    position: relative;
    height: 40px;
    right: 0px;
    top: 0px;
    cursor: pointer;
    margin-right: 20px;
    background: ${ACTIVE_BUTTON_COLOR};
    border: 1px solid ${POPUP_BLOCK_BACKGROUND_COLOR};
  }
  .yes-button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_BACKGROUND_COLOR};
  }
  .button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_TEXT_COLOR};
  }
`;
interface ExistingCasePopUpProps {
  handleNoExistingCasesClick: () => void;
  handleYesExistingCasesClick: () => void;
  closeModal: () => void;
}

const ExistingCasePopup: React.FC<ExistingCasePopUpProps> = ({
  handleNoExistingCasesClick,
  handleYesExistingCasesClick,
  closeModal
}) => {
  return (
    <ExistingCasePopupContainer>
      <Top>
        <CloseIcon className="close-icon" onClick={closeModal} />
        <div className="Head-text">Is this for an existing case?</div>
        <br />
        <div className="text">Are you requesting support for an existing case?</div>
      </Top>
      <Actions>
        <button className="no-button" onClick={handleNoExistingCasesClick}>
          <span className="button-text">No</span>
        </button>
        <button className="yes-button" onClick={handleYesExistingCasesClick}>
          <span className="yes-button-text">Yes</span>
        </button>
      </Actions>
    </ExistingCasePopupContainer>
  );
};
export default ExistingCasePopup;
