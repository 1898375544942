/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List styled component
 */
import styled from '@emotion/styled';
import {GRID_CELL_LINK_COLOR, HISTORY_ROW_LIGHT_TEXT_COLOR} from '../../common/theme/Colors';

export const PlanHistoryBlock = styled.div`
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  width: 260px;
  margin: 8px 12px;
`;

export const PlanHistoryNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  span {
    margin-right: -16px;
  }
`;

export const CustomerHistoryBlock = styled.div`
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  width: 300px;
  margin: 8px 12px;
`;

export const CustomerHistoryNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  span {
    margin-right: -16px;
  }
`;

export const HistoryDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  padding-right: 8px;
`;

export const EquipmentHistoryBlock = styled.div`
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  width: 330px;
  margin: 8px 12px;
`;

export const BuildIconContainer = styled.span`
  width: 5%;
  margin: 0px 4px 0px 0px;
`;

export const EquipmentHistoryDateContainer = styled.div`
  margin: 0px 4px 0px 4px;
  font-weight: 600;
  width: 25%;
  display: flex;
  justify-content: center;
`;

export const EquipmentHistoryDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

export const EquipmentHistoryDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EquipmentHistoryConfigurationTypeContainer = styled.div`
  font-weight: 400;
  width: 65%;
  margin: 0px 4px 0px 4px;
  display: flex;
  justify-content: left;
`;

export const EquipmentHistorySerialNumberContainer = styled.div`
  font-weight: 400;
  width: 35%;
  display: flex;
  justify-content: right;
`;

export const HistoryBlock = styled.div<{width: number; justifyContent?: string}>`
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'unset')};
  width: ${(props) => props.width}px;
  margin: 8px 12px;
`;

export const HistoryRowInnerContainer = styled.div<{width?: number, paddingRight?: number}>`
  display: flex;
  flex-direction: row;
  padding-right: ${(props) => (props.paddingRight ? `${props.paddingRight}px` : 'unset')};
  width: ${(props) => (props.width ? `${props.width}%` : 'unset')};
  overflow: hidden;
`;

export const HistoryRowBoldText = styled.span<{textAlign?: string}>`
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'unset')};
`;

export const HistoryRowLightText = styled.span`
  display: inline-block;  
  color: var(--Text-Light-Mode-Subtle, ${HISTORY_ROW_LIGHT_TEXT_COLOR});
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const HistoryRowLinkText = styled.span`
  color: var(--Text-Light-Mode-Subtle, ${GRID_CELL_LINK_COLOR});
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
`;

export const HistoryRowLoadingContainer = styled.div<{width: number}>`
  height: 32px;
  width: ${(props) => props.width}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HistoryRowLoaderWrapper = styled.div`
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
  div {
    margin-right: 4px;
  }
`;
