/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: styles for fleet map aircraft popups
 */

import styled from '@emotion/styled';
import {MAP_LOADING_TEXT_COLOR} from '../../../utils/constants';
import BoldSpan from '../../common/BoldSpan';
import {
  BLACK,
  FLIGHT_DETAILS_PRIMARY_BLUE,
  ISOLATE_ROUTE_BUTTON_ACTIVE_BACKGROUND,
  MAP_POPUP_ACCENT_COLOR,
  MAP_POPUP_CLOSE_HOVER_COLOR,
  MAP_POPUP_DEFAULT_BORDER,
  POPUP_HOVER_COLOR,
  WHITE,
  DARK_FLIGHT_ALERT_COLOR
} from '../../common/theme/Colors';
import {FOURTEEN_PCT_TRANSPARENT_BLACK, TWELVE_PCT_TRANSPARENT_BLACK, TWENTY_PCT_TRANSPARENT_BLACK} from '../MapStyles';

export const Span = styled.span``;

export const TooltipContent = styled.div`
  margin: 6px;
  max-width: 68px;
`;

export const PopupNetworkTypeContainer = styled.span`
  width: 0;
  height: 0;
`;
export const PopupNetworkTypeChip = styled.div<{
  iconSize: number;
  fontSize: number;
  height: number;
}>`
  display: flex;
  align-items: center;
  position: relative;
  bottom: ${(props) => props.iconSize}px;
  left: 8px;
  width: min-content;
  height: ${(props) => props.height}px;
  border: 1px solid ${MAP_POPUP_DEFAULT_BORDER};
  border-radius: 4px;
  background-color: ${WHITE};
  color: ${MAP_POPUP_ACCENT_COLOR};
  font-size: ${(props) => props.fontSize}px;
  font-weight: 600;
  padding: 2px 4px;
  svg {
    width: ${(props) => props.iconSize}px;
    height: ${(props) => props.iconSize}px;
    margin-right: 3px;
  }
`;

export const PopupHeader = styled.div<{font: number; borderRadius: string}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: ${WHITE};
  color: ${BLACK};
  font-family: 'Source Sans Pro';
  font-size: ${(props) => props.font}px;
  letter-spacing: -0.08px;
  line-height: 18px;
  border-radius: ${(props) => props.borderRadius};
`;

export const PopupTitleContainer = styled.div<{
  availDotSize: number;
  primaryIdFontSize: number;
  secondaryIdFontSize: number;
  flightPhaseFontSize: number;
  flightPhaseIconSize: number;
  outerPadding: string;
  isLargePopup: boolean;
}>`
  height: 22px;
  font-size: ${(props) => props.secondaryIdFontSize}px;
  display: flex;
  align-items: baseline;
  padding: ${(props) => props.outerPadding};
  flex: auto;
  flext-direction: row;
  .availability-container {
    display: flex;
    align-items: baseline;
    width: ${(props) => (props.isLargePopup ? 8 : 11)}%;
    svg {
      width: ${(props) => props.availDotSize + 1}px;
      height: ${(props) => props.availDotSize}px;
    }
  }
  .title-container {
    display: flex;
    align-items: baseline;
    width: 45%;
    white-space: nowrap;
    color: ${MAP_POPUP_ACCENT_COLOR};
    .serial-number {
      font-size: ${(props) => props.primaryIdFontSize}px;
      font-weight: 600;
      margin-right: 4px;
      color: ${BLACK};
    }
  }
  .title-divider {
    display: flex;
    align-items: baseline;
    width: 3%;
    white-space: nowrap;
  }
  .flight-phase-container {
    width: 45%;
    padding: 0px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: stretch;

    .flight-phase-name {
      font-size: ${(props) => props.flightPhaseFontSize}px;
      text-align: right;
      white-space: nowrap;
      color: ${MAP_POPUP_ACCENT_COLOR};
    }
    .flight-phase-icon {
      padding: 0px 0px 0px 4px;
      svg {
        width: ${(props) => props.flightPhaseIconSize}px;
        height: ${(props) => props.flightPhaseIconSize}px;
      }
      path {
        fill: ${BLACK};
      }
    }
  }
`;

export const PopupTimeContainer = styled.div<{
  directionIconSize: number;
  timeLabelFontSize: number;
  timeFontSize: number;
  utcFontSize: number;
  padding: string;
}>`
  padding: ${(props) => props.padding};
  margin: 6px 10px;
  .time-container {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: flex;
      align-items: center;
    }
    svg {
      height: ${(props) => props.directionIconSize}px;
      width: ${(props) => props.directionIconSize}px;
      position: relative;
    }
    .time-label {
      padding: 0px 2px;
      font-size: ${(props) => props.timeLabelFontSize}px;
      white-space: nowrap;
    }
    // my style
    .time {
      font-weight: normal;
      font-size: ${(props) => props.timeFontSize}px;
      color: ${MAP_POPUP_ACCENT_COLOR};
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
    }
    .time-departure-wrapper {
      margin-right: 10px;
    }

    .utc-indicator {
      font-weight: normal;
      font-size: ${(props) => props.utcFontSize}px;
      background-color: ${BLACK};
      color: ${WHITE};
      padding-left: 2px;
      margin: 10px;
      padding: 10px;
    }
  }
`;

export const SelectedPopupTimeContainer = styled.div<{
  directionIconSize: number;
  timeLabelFontSize: number;
  timeFontSize: number;
  utcFontSize: number;
  padding: string;
  flightStartHorizLine?: number;
  flightEndHorizLine?: number;
  flightNoStartHorizLine?: number;
}>`
  padding: ${(props) => props.padding};
  margin: 0 10px;
  .time-container {
    font-weight: 600;
    float: left;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0px;
    span {
      display: flex;
      align-items: center;
      margin: 2px;
    }
    svg {
      height: ${(props) => props.directionIconSize}px;
      width: ${(props) => props.directionIconSize}px;
      position: relative;
    }
    .time-label {
      padding: 0px 5px;
      font-size: ${(props) => props.timeLabelFontSize}px;
      white-space: nowrap;
    }
    .time {
      font-weight: normal;
      font-size: ${(props) => props.timeFontSize}px;
      color: ${MAP_POPUP_ACCENT_COLOR};
      padding: 0px 0px 0px 2px;
    }
    .utc-indicator {
      font-weight: normal;
      font-size: ${(props) => props.utcFontSize}px;
      background-color: ${BLACK};
      color: ${WHITE};
      padding-left: 2px;
      margin: 10px;
      padding: 10px;
    }
    .flight_dotted_line {
      border-bottom: 1px dotted ${MAP_POPUP_DEFAULT_BORDER};
      display: flex;
      flex: 1;
    }
    .flight_no_start_dotted_line {
      border-bottom: 1px dotted ${MAP_POPUP_DEFAULT_BORDER};
      width: ${(props) => (props.flightNoStartHorizLine ? props.flightNoStartHorizLine : 200)}px;
    }
  }
`;

export const PopupBody = styled.div<{
  outerPadding: string | undefined;
  defaultFontSize: number;
  labelFontSize: number;
  valueFontSize: number;
  unitsFontSize: number;
  iconSize: number;
  innerPadding: string;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: ${(props) => props.outerPadding};

  background-color: ${WHITE};
  font-family: 'Source Sans Pro';
  font-size: ${(props) => props.defaultFontSize}px;
  letter-spacing: -0.08px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .data-row-container {
    display: flex;
    flex-wrap: nowrap;
    color: ${MAP_POPUP_ACCENT_COLOR};
    width: 100%;
    padding: ${(props) => props.innerPadding};
    align-items: center;
    svg {
      color: ${BLACK};
      margin-right: 4px;
      width: ${(props) => props.iconSize}px;
      height: ${(props) => props.iconSize}px;
    }
    .label {
      color: ${BLACK};
      font-weight: 600;
      font-size: ${(props) => props.labelFontSize}px;
      margin-right: 4px;
    }
    .value {
      font-size: ${(props) => props.valueFontSize}px;
    }
    .units {
      margin-left: 2px;
      font-size: ${(props) => props.unitsFontSize}px;
    }

    &.left-align {
      justify-content: flex-start;
    }
    &.right-align {
      justify-content: flex-end;
    }

    &.half {
      width: 50%;
      margin-right: 1px;
    }

    &.left-border {
      border-left: 1px solid ${MAP_POPUP_DEFAULT_BORDER};
    }
    &.right-border {
      border-right: 1px solid ${MAP_POPUP_DEFAULT_BORDER};
    }
    &.top-border {
      border-top: 1px solid ${MAP_POPUP_DEFAULT_BORDER};
    }
  }
`;

export const PopupContainer = styled.div<{
  zIndex: number;
  showLargePopup?: boolean;
  height?: number;
  border?: string;
  highlightPopup?: boolean;
  display: string;
}>`
  position: absolute;
  cursor: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  left: ${(props) => (props.showLargePopup ? '-108px' : '-60px')};
  bottom: 22px;
  width: auto;
  display: ${(props) => props.display};
  min-width: ${(props) => (props.showLargePopup ? '250px' : '140px')};
  border-radius: 4px;
  z-index: ${(props) => props.zIndex};
  box-shadow: ${(props) =>
    props.highlightPopup
      ? `0px 0px 5px 0px ${POPUP_HOVER_COLOR}`
      : `0px 3px 4px 0px ${FOURTEEN_PCT_TRANSPARENT_BLACK},  0px 3px 3px -2px ${TWELVE_PCT_TRANSPARENT_BLACK},0px 4px 8px 0px ${TWENTY_PCT_TRANSPARENT_BLACK}`};
  height: ${(props) => props.height}px;
  border: ${(props) => props.border};
`;

export const PopupRow = styled.div<{padding: string}>`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  padding: ${(props) => props.padding};
`;

export const PopupCloseButton = styled.div<{popupWidth: number; bottom?: number; iconSize?: number; right?: number}>`
  cursor: pointer;
  position: relative;
  bottom: ${(props) => (props.bottom ? props.bottom : 12)}px;
  left: ${(props) =>
    props.right && props.iconSize
      ? props.popupWidth - (props.iconSize + 2) * 1.5 - props.right
      : props.popupWidth - 35}px;
  width: ${(props) => (props.iconSize ? props.iconSize + 2 : 22)}px;
  height: ${(props) => (props.iconSize ? props.iconSize + 2 : 22)}px;
  border-radius: 12px;
  background-color: ${WHITE};
  border: 1px solid ${MAP_POPUP_DEFAULT_BORDER};
  svg {
    width: ${(props) => (props.iconSize ? props.iconSize : 18)}px;
    height: ${(props) => (props.iconSize ? props.iconSize : 18)}px;
    stroke-width: 2;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
  }
  &:hover {
    background-color: ${MAP_POPUP_CLOSE_HOVER_COLOR};
  }
`;

export const PopupCloseButtonContainer = styled.div`
  height: 0;
  position: relative;
  cursor: pointer;
`;

export const PopupIsolateRouteButton = styled.div<{
  zIndex: number;
  backgroundColor: string;
  top: number;
  width: number;
}>`
  top: ${(props) => props.top}px;
  left: 15px;
  position: relative;
  user-select: none;
  cursor: pointer;
  border-radius: 4px;
  width: ${(props) => props.width}px;
  height: 36px;
  line-height: 32px;
  background-color: ${(props) => props.backgroundColor};
  z-index: ${(props) => props.zIndex};
  text-align: center;
  color: ${MAP_LOADING_TEXT_COLOR};
  box-shadow: 0px 3px 4px 0px ${FOURTEEN_PCT_TRANSPARENT_BLACK}, 0px 3px 3px -2px ${TWELVE_PCT_TRANSPARENT_BLACK},
    0px 4px 8px 0px ${TWENTY_PCT_TRANSPARENT_BLACK};

  &:hover {
    background-color: ${MAP_POPUP_CLOSE_HOVER_COLOR};

    svg {
      .background {
        fill: ${MAP_POPUP_CLOSE_HOVER_COLOR};
      }

      .foreground {
        fill: ${FLIGHT_DETAILS_PRIMARY_BLUE};
      }
    }
  }
  &:selected {
    background-color: ${ISOLATE_ROUTE_BUTTON_ACTIVE_BACKGROUND};

    svg {
      .background {
        fill: ${MAP_POPUP_CLOSE_HOVER_COLOR};
      }

      .foreground {
        fill: ${FLIGHT_DETAILS_PRIMARY_BLUE};
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;
    top: 4px;
    position: relative;

    .background {
      fill: ${WHITE};
    }

    .foreground {
      fill: #125986;
    }
  }
`;

export const PopupIsolateRouteText = styled(BoldSpan)<{color?: string}>`
  font-size: 12px;
  margin-left: 4px;
  color: ${(props) => props.color};
`;

export const PopupFlightDetailsButton = styled.div<{zIndex: number; top: number}>`
  top: ${(props) => props.top}px;
  left: 152px;
  position: relative;
  user-select: none;
  cursor: pointer;
  border-radius: 4px;
  width: 150px;
  height: 36px;
  line-height: 32px;
  background-color: ${WHITE};
  z-index: ${(props) => props.zIndex};
  text-align: center;
  color: ${MAP_LOADING_TEXT_COLOR};
  box-shadow: 0px 3px 4px 0px ${FOURTEEN_PCT_TRANSPARENT_BLACK}, 0px 3px 3px -2px ${TWELVE_PCT_TRANSPARENT_BLACK},
    0px 4px 8px 0px ${TWENTY_PCT_TRANSPARENT_BLACK};

  &:hover {
    background-color: ${MAP_POPUP_CLOSE_HOVER_COLOR};

    svg {
      .background {
        fill: ${MAP_POPUP_CLOSE_HOVER_COLOR};
      }

      .foreground {
        fill: ${FLIGHT_DETAILS_PRIMARY_BLUE};
      }
    }
  }
  &:selected {
    background-color: ${ISOLATE_ROUTE_BUTTON_ACTIVE_BACKGROUND};

    svg {
      .background {
        fill: ${MAP_POPUP_CLOSE_HOVER_COLOR};
      }

      .foreground {
        fill: ${FLIGHT_DETAILS_PRIMARY_BLUE};
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;
    top: 4px;
    position: relative;

    .background {
      fill: ${WHITE};
    }

    .foreground {
      fill: #125986;
    }
  }
`;

export const PopupFlightDetailsText = styled(BoldSpan)<{color?: string}>`
  font-size: 12px;
  margin-left: 8px;
  color: ${(props) => props.color};
`;

export const DisableSelect = styled.span`
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const HiddenSelect = styled.span`
  opacity: 0;
  white-space: pre;
  width: 0px;
  display: inline-block;
`;

export const DarkFlightInfo = styled.div`
  display: flex;
  background: ${DARK_FLIGHT_ALERT_COLOR};
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  line-height: 16px;
  margin-top: 10px;
`;

export const InfoMessage = styled.span`
  padding: 8px;
  display: flex;
  font-size: 12px;
  .darkFlight {
    font-weight: 700;
  }
`;
