/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Tail List
 */
import moment from 'moment';
import {isEmpty} from 'lodash';
import {CustomerAction} from './CustomerReducer';
import {ColumnSortReducer, ColumnSortState} from './ColumnSortReducer';
import {SnackBarState, SnackBarReducer} from './SnackBarReducer';
import {HideColumnsState, HideColumnsReducer, InitialHideColumnsState} from './HideColumnsReducer';
import {delegateToReducers} from '../Reducer';
import {FiltersReducer, FiltersState} from './FiltersReducer';
import {DATE_TIME_LOCAL_FORMAT, getDefaultEndDate} from '../../utils/DateTimeUtils';
import {summaryMetricsDefaultStartDate} from '../../components/dashboard/summaryMetricsUtil';
import {isValueChanged, setFilters, setOrderColumnQuery, setSharelinkHiddenColumns} from '../UrlMap';
import {DatePickerState, validateDatePicker} from './DatePickerReducer';
import {CommonAction} from '../types';

export const TAIL_LIST_STORE_CONTEXT = 'tailList';
export const TAIL_LIST_ROUTE = '/lists/tail';

export enum VIEW_COLUMN_TYPE {
  COMPACT_VIEW = 'COMPACT_VIEW',
  EXPANDED_VIEW = 'EXPANDED_VIEW'
}

export const TailListSessionStoreKeys: string[] = [
  'sort.column',
  'sort.order',
  'sort.queryField',
  'filters.filters',
  'hideColumns.hiddenColumns',
  'hideColumns.sharelinkHiddenColumns',
  'showCompactView',
  'dateRange'
];

const InitialDatePickerState: DatePickerState = {
  startDate: summaryMetricsDefaultStartDate(0),
  endDate: getDefaultEndDate(),
  persistStartDate: false,
  persistEndDate: false,
  snackBar: []
};

export interface TailListState {
  filters: FiltersState;
  sort: ColumnSortState;
  hideColumns: HideColumnsState;
  dateRange: DatePickerState;
  snackBar: SnackBarState;
  showTailListNotification: boolean;
  showCompactView: boolean;
}

export const InitialTailListState: TailListState = {
  filters: {
    filters: [],
    domainOptions: [],
    rangeOptions: {}
  },
  sort: {
    options: undefined,
    column: 'tailId',
    order: 'desc',
    queryField: 'tailId'
  },
  hideColumns: InitialHideColumnsState,
  dateRange: InitialDatePickerState,
  snackBar: [],
  showTailListNotification: true,
  showCompactView: false
};

export enum TailListAction {
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  SET_SHOW_TAIL_LIST_NOTIFICATION = 'SET_SHOW_TAIL_LIST_NOTIFICATION',
  CHANGE_TAIL_LIST_COLUMN_VIEW = 'CHANGE_TAIL_LIST_COLUMN_VIEW'
}

/**
 * Tail List action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const TailListReducer = (state: TailListState, action: any): TailListState => {
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      if (action.payload.reset) {
        const newState = {
          ...InitialTailListState
        };
        // reducer should always return a 'new' object
        state = JSON.parse(JSON.stringify(newState));
      }
      break;

    case CommonAction.PROCESS_URL_PARAMS:
      const updates: any = {};

      if (action.payload.path === TAIL_LIST_ROUTE) {
        if (isValueChanged(state.dateRange.startDate, action.payload.params.tailListStartDate)) {
          updates.startDate = action.payload.params.tailListStartDate;
          updates.persistStartDate = true;
        }

        if (isValueChanged(state.dateRange.endDate, action.payload.params.tailListEndDate)) {
          updates.endDate = action.payload.params.tailListEndDate;
          updates.persistEndDate = true;
        }

        if (!isEmpty(updates)) {
          state.dateRange = validateDatePicker({
            ...state,
            ...updates
          });
        }
        state = setOrderColumnQuery(state, action.payload);
        state = setFilters(state, action.payload);
        state = setSharelinkHiddenColumns(state, action.payload, TAIL_LIST_STORE_CONTEXT);
      }
      break;

    case TailListAction.SET_SHOW_TAIL_LIST_NOTIFICATION:
      state = {...state, showTailListNotification: action.payload};
      break;

    case TailListAction.SET_DATE_RANGE:
      const {startDate, endDate, persistStartDate, persistEndDate, viewContext} = action.payload;
      const tailListDateRange = {
        startDate: moment.utc(startDate).format(DATE_TIME_LOCAL_FORMAT),
        endDate: moment.utc(endDate).format(DATE_TIME_LOCAL_FORMAT),
        persistStartDate,
        persistEndDate
      };
      if (viewContext && viewContext === TAIL_LIST_STORE_CONTEXT) {
        state = {
          ...state,
          dateRange: tailListDateRange
        };
      }
      break;
      case TailListAction.CHANGE_TAIL_LIST_COLUMN_VIEW:
        state = {
          ...state,
          showCompactView: action.payload,
        };
        break;  

    default:
      state = {
        ...state
      };
      break;
  }
  state = delegateToReducers(
    TAIL_LIST_STORE_CONTEXT,
    state,
    action,
    {
      sort: ColumnSortReducer,
      filters: FiltersReducer,
      hideColumns: HideColumnsReducer,
      snackBar: SnackBarReducer
    },
    InitialTailListState
  );
  return state;
};
