/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: List Search
 */
import {useState} from 'react';
import {Search, SearchRow} from '@viasat/insights-components';
import {AIRCRAFT_SORT_ORDER, BAND_KA, BAND_KU} from '../../utils/constants';
import {getAllStoredRecentSearches, getAvailabilityColor} from '../../utils/MapUtil';

export const CACHED_SEARCH_LIMIT = 5;
export const CHAR_SEARCH_COUNT = 2;

interface ListSearchProps {
  idPrefix: string;
  data: any[] | null;
  setAircraftStatusDataIndex: (id: number) => void;
  setScrollTop: (top: number) => void;
  showListSearchStatusIcon?: boolean;
  showSearchSecondaryLabel?: boolean;
}

const ListSearch = ({
  idPrefix,
  data,
  setAircraftStatusDataIndex,
  showListSearchStatusIcon = true,
  showSearchSecondaryLabel = false
}: ListSearchProps) => {
  const [recentSearches, setRecentSearches] = useState<string[]>(getAllStoredRecentSearches());
  return (
    <Search
      getFullElementId={(name, type) => `${idPrefix}--listSearch__${name}-${type}`}
      isDisabled={!data || data.length === 0}
      charSearchCount={CHAR_SEARCH_COUNT}
      data={data || []}
      dataRowIdKey="aircraftId"
      selectedRowId={null}
      onRowClick={(row) => {
        const selectedItem = data.findIndex(
          (obj) => obj.serialNumber === row.serialNumber || obj.tailId === row.tailId
        );
        if (selectedItem > -1) {
          setAircraftStatusDataIndex(selectedItem);
        }
      }}
      onRowHover={() => {}}
      recentSearches={recentSearches}
      onRecentSearchAdd={(newRecentSearch) => {
        // Add insert recent search to the top
        // Remove it too if it previously existed in the array
        const updatedRecentSearches = [
          newRecentSearch,
          ...recentSearches.filter((search: string) => search !== newRecentSearch)
        ];

        if (updatedRecentSearches.length > CACHED_SEARCH_LIMIT) {
          updatedRecentSearches.pop();
        }
        setRecentSearches(updatedRecentSearches);
      }}
      onRecentSearchesClearAll={() => setRecentSearches([])}
      filterDataCallback={(data, searchCriteria) => {
        const searchCriteriaLower = searchCriteria.toLowerCase();
        const searchResults = data
          .filter(
            (row) =>
              (row.tailId && row.tailId.toLowerCase().includes(searchCriteriaLower)) ||
              (row.serialNumber && row.serialNumber.toLowerCase().includes(searchCriteriaLower)) ||
              (row.macAddress && row.macAddress.toLowerCase().includes(searchCriteriaLower)) ||
              (row.ipAddress && row.ipAddress.toLowerCase().includes(searchCriteriaLower))
          )
          .reverse();
        searchResults?.forEach((tailData) => {
          tailData['sort_order_value'] = AIRCRAFT_SORT_ORDER[tailData.status];
          return tailData;
        });
        searchResults?.sort((a, b) => (a.sort_order_value < b.sort_order_value ? 1 : -1));
        return searchResults;
      }}
      renderRow={(rowData, searchCriteria, resultIdBase, isSelected, isHovered) => {
        const secondarySearchLabel =
          rowData.networkCapability === BAND_KA
            ? `${rowData.macAddress}`
            : rowData.networkCapability === BAND_KU
            ? `${rowData.ipAddress}`
            : `${rowData.macAddress}/${rowData.ipAddress}`;
        return (
          <div style={{padding: '5px 0px 5px', display: 'flex'}}>
            <SearchRow
              getFullElementId={(name, type) => `searchRow--${resultIdBase}__${name}-${type}`}
              isPulsing={isSelected}
              color={showListSearchStatusIcon ? getAvailabilityColor(rowData.status) : undefined}
              primaryLabel={`${rowData.serialNumber}/${rowData.tailId}`}
              secondaryLabel={showSearchSecondaryLabel ? secondarySearchLabel : undefined}
              searchCriteria={searchCriteria}
              showReturnIcon={isHovered}
            />
          </div>
        );
      }}
    />
  );
};

export default ListSearch;
