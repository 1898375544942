/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: SnackBar component
 */

import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {get} from 'lodash';
import {SnackbarOrigin} from '@mui/material/Snackbar';
import {SnackBar} from '@viasat/insights-components';

import {useStore} from '../../store/Store';
import {getActionTypeWithContext} from '../../store/reducerUtils';
import {SnackBarAction, SnackBar as SnackBarData} from '../../store/reducers/SnackBarReducer';

const INITIAL_FADE_TIME_MS = 5000;
const SNACK_BAR_HEIGHT_PADDING = 65;

const SnackBars: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const {store, dispatch} = useStore();
  const snackBarMap = {};
  const snacks = Object.keys(store).reduce((memo: any, storeKey: string) => {
    const s = get(store, `${storeKey}.snackBar`, []);
    s.forEach((snack) => {
      snackBarMap[snack.id] = storeKey;
    });

    return [...memo, ...s];
  }, []);

  const hideSnackBar = (id: string) =>
    dispatch({type: getActionTypeWithContext(SnackBarAction.HIDE_SNACK_BAR, snackBarMap[id]), payload: {id}});

  const position: SnackbarOrigin = {
    horizontal: 'left',
    vertical: 'bottom'
  };

  const {redirectUrl} =
    snacks.find((snack: SnackBarData) => typeof snack.redirectUrl === 'string' && snack.redirectUrl.length > 0) || {};

  useEffect(() => {
    if (redirectUrl) {
      setTimeout(() => {
        navigate(redirectUrl);
      }, INITIAL_FADE_TIME_MS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl]);

  return (
    <>
      {snacks.map((snack: SnackBarData, index: number) => {
        return (
          <SnackBar
            getFullElementId={(name, type) => `${name}-${type}`}
            severity={snack.severity}
            message={snack.message}
            durationMs={INITIAL_FADE_TIME_MS}
            position={snack.position ? snack.position : position}
            isOpen={snack.isOpen}
            marginBottom={index * SNACK_BAR_HEIGHT_PADDING}
            onClose={() => hideSnackBar(snack.id)}
          />
        );
      })}
    </>
  );
};

export default SnackBars;
