/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: AntennaEncoder chart
 */
import React, {useState, useEffect} from 'react';
import {sortBy} from 'lodash';
import styled from '@emotion/styled';
import {useIntl} from 'react-intl';

import {ChartType} from '../../common/elements/chart/types';
import LineChart from '../../common/elements/chart/LineChart';
import ToolTipDateTimeLabel from '../../common/elements/chart/ToolTip/ToolTipDateTimeLabel';
import {formatConfig} from '../../common/elements/chart/chartUtils';

import {IAntennaEncoderInfo} from '../../../store/queries/flightDetails/antennaEncoderAngleQuery';
import AntennaEncoderToolTip from './tooltip/AntennaEncoderTooltip';

import {LEGEND_TEXT, CHART_AXIS_MINOR_TICK, ANTENNA_ENCODER_CHART_PALETTE} from '../../common/theme/Colors';
import {
  DATE_CONCISE_FORMAT,
  TIME_CONCISE_NO_PAD_FORMAT,
  stringAsMs,
  msAsString,
  areMsTimestampMinutesEqualTo,
  DATE_TIME_FORMAT_WITH_HR_MINS
} from '../../../utils/DateTimeUtils';

import {ChartTimeSettings, adjustCategorizedChartData, getXAxisTickPositions} from '../flightDetailsUtil';

interface IAntennaEncoderChartProps {
  currentTime: string;
  isLoading: boolean;
  antennaEncoderData: IAntennaEncoderInfo[];
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
}

const ChartContainer = styled.div`
  width: 100%;
  .highcharts-xaxis-grid path {
    stroke-width: 0;
  }
  .highcharts-crosshair,
  .highcharts-yaxis-grid,
  .highcharts-xaxis path {
    stroke-width: 1px;
    stroke: ${CHART_AXIS_MINOR_TICK};
  }
`;

const AntennaEncoderChart: React.FC<IAntennaEncoderChartProps> = ({
  currentTime,
  isLoading,
  antennaEncoderData,
  hideAllLegendItemsOnLoad,
  legendItemsToHideOnLoad,
  chartTimeSettings,
  parentLeftOffset,
  liveMask
}: IAntennaEncoderChartProps) => {
  const [chartConfig, setChartConfig] = useState<any>(null);

  const intl = useIntl();

  useEffect(() => {
    if (!isLoading && chartTimeSettings.start && chartTimeSettings.end) {
      // General config
      const id = 'flightDetails--antennaEncoder__timeSeries-chart';
      const highchartsIdBase = 'antennaEncoder';
      const chartType = ChartType.LINE;
      const height = 215;
      const marginLeft = 75;
      const marginRight = 30;
      const defaultChartPalette = [];
      const palette = [];
      const seriesLabel = '';
      const connectNulls = true;
      const disableMarker = false;
      const antennaEncoderInfo = antennaEncoderData ? sortBy(antennaEncoderData, 'timestamp') : [];

      // X-axis

      let rawXAxisData = antennaEncoderInfo.map((i) => stringAsMs(i.timestamp, DATE_TIME_FORMAT_WITH_HR_MINS));
      const xAxisLabelPlotlines = [];
      const xAxisLabelXOffset = 0;
      const xAxisFormatter = (value: any) => {
        // Note: Minor ticks are not supported for category-based axes
        if (Number(value) < 1e12 || !areMsTimestampMinutesEqualTo(value, chartTimeSettings.labeledTickMinutesValues)) {
          return '';
        }

        const hourLabel = msAsString(value, TIME_CONCISE_NO_PAD_FORMAT);
        const dayLabel = msAsString(value, DATE_CONCISE_FORMAT);
        return hourLabel === '0:00' ? dayLabel : hourLabel;
      };

      // Y-axis
      const yAxis = [
        {
          label: `Azimuth (deg)`,
          labelXOffset: -5,
          plotline: undefined,
          plotlineColor: undefined,
          tickInterval: undefined,
          tickPositions: [0, 90, 180, 270, 360],
          titleOffset: 50,
          min: 0,
          max: 360,
          opposite: false,
          endOnTick: true
        },
        {
          label: `Elevation (deg)`,
          labelXOffset: 0,
          plotline: undefined,
          plotlineColor: undefined,
          tickInterval: undefined,
          tickPositions: [0, 45, 90],
          titleOffset: 22,
          min: 0,
          max: 360,
          opposite: true,
          endOnTick: true
        }
      ];

      const yAxisFormatter = (label: any) => `${label.value}°`;

      // Series
      let elevationAngle = antennaEncoderInfo.map((row) => row.elevation_deg);
      let azimuthAngle = antennaEncoderInfo.map((row) => row.azimuth_deg);

      let categorizedElevationAngle = adjustCategorizedChartData(
        rawXAxisData,
        elevationAngle,
        chartTimeSettings,
        1,
        chartTimeSettings.tickIntervalMinutes / 2
      );
      let categorizedAzimuthAngle = adjustCategorizedChartData(
        rawXAxisData,
        azimuthAngle,
        chartTimeSettings,
        1,
        chartTimeSettings.tickIntervalMinutes / 2
      );

      const xAxisData = categorizedElevationAngle.xAxisData;

      const findTailBlockedRanges = (antennaEncoderInfo) => {
        let tailBlockage = {start: -1, end: -1};
        let plotBands = [];
        for (let i = 0; i < antennaEncoderInfo.length; i++) {
          if (antennaEncoderInfo[i]?.isTailBlocked) {
            if (tailBlockage.start === -1) {
              tailBlockage.start = i;
            } else if (!antennaEncoderInfo[i + 1]?.isTailBlocked) {
              tailBlockage.end = i;
              plotBands.push({
                color: ANTENNA_ENCODER_CHART_PALETTE[2],
                from: xAxisData.findIndex(
                  (xAxis) =>
                    stringAsMs(antennaEncoderInfo[tailBlockage.start].timestamp, DATE_TIME_FORMAT_WITH_HR_MINS) ===
                    xAxis
                ),
                to: xAxisData.findIndex(
                  (xAxis) =>
                    stringAsMs(antennaEncoderInfo[tailBlockage.end].timestamp, DATE_TIME_FORMAT_WITH_HR_MINS) === xAxis
                )
              });
              tailBlockage.start = -1;
              tailBlockage.end = -1;
            }
          }
        }
        return plotBands;
      };

      const plotBands = findTailBlockedRanges(antennaEncoderInfo);

      const series = [
        {
          name: 'Azimuth (Left Scale)',
          color: ANTENNA_ENCODER_CHART_PALETTE[0],
          marker: {
            symbol: 'circle'
          },
          data: categorizedAzimuthAngle.yAxisData,
          yAxis: 0
        },
        {
          name: 'Elevation (Right Scale)',
          color: ANTENNA_ENCODER_CHART_PALETTE[1],
          marker: {
            symbol: 'circle'
          },
          data: categorizedElevationAngle.yAxisData,
          yAxis: 1
        }
      ];

      if (plotBands && plotBands.length > 0) {
        series.push({
          name: 'Possible Tail Blockage',
          color: ANTENNA_ENCODER_CHART_PALETTE[2],
          marker: {
            symbol: 'circle'
          },
          data: [],
          yAxis: 1
        });
      }

      // Set the x-axis tick mark positions based on the adjusted x-axis data
      const xAxisTickPositions = getXAxisTickPositions(xAxisData, chartTimeSettings);

      // Legend
      const legend = true;
      const legendItemStyle = {
        fontSize: '12px',
        fontWeight: '600',
        color: LEGEND_TEXT
      };

      // Tooltips
      const htmlTooltip = true;
      const sharedTooltip = true;
      const tooltip = (i18n: any) => (input: any) => {
        const {x, points} = input;
        return {
          label: {
            value: <ToolTipDateTimeLabel dateTime={x} />
          },
          points: points.map((pt) => {
            const {
              x,
              y,
              color,
              series: {name: seriesName}
            } = pt;
            return {
              value: (
                <AntennaEncoderToolTip
                  prefixIconColor={color}
                  angleType={seriesName}
                  angle={y}
                  isTailBlocked={
                    antennaEncoderInfo.filter(
                      (encoderInfo) =>
                        stringAsMs(encoderInfo.timestamp, DATE_TIME_FORMAT_WITH_HR_MINS) === x &&
                        encoderInfo.elevation_deg === y
                    )[0]?.isTailBlocked
                  }
                />
              )
            };
          }),
          color: false
        };
      };

      // Handle noData Options
      const noDataOptions = {
        enabled: true,
        showEmptyXAxis: false,
        showEmptyYAxis: false
      };

      setChartConfig({
        id,
        highchartsIdBase,
        chartType,
        height,
        marginLeft,
        marginRight,
        defaultChartPalette,
        palette,
        seriesLabel,
        connectNulls,
        disableMarker,
        xAxisData,
        xAxisLabelPlotlines,
        xAxisTickPositions,
        xAxisLabelXOffset,
        xAxisFormatter,
        yAxis,
        yAxisFormatter,
        series,
        legend,
        legendItemStyle,
        hideAllLegendItemsOnLoad,
        legendItemsToHideOnLoad,
        htmlTooltip,
        sharedTooltip,
        tooltip,
        liveMask,
        noDataOptions,
        plotBands
      });
    }
    // eslint-disable-next-line
  }, [antennaEncoderData, chartTimeSettings]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer>
      {Object.keys(formattedConfig).length === 0 ? <></> : <LineChart {...formattedConfig} />}
    </ChartContainer>
  );
};

export default AntennaEncoderChart;
