/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Render all the individual aircraft on the map
 */

import React, {useState, useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {OverlayView} from '@react-google-maps/api';
import {useGoogleMap} from '@react-google-maps/api';
import {forEach} from 'lodash';
import {isNil} from 'lodash';
import moment from 'moment';

import {useStore} from '../../store/Store';
import {getActionTypeWithContext} from '../../store/reducerUtils';
import {FLEET_MAP_STORE_CONTEXT, FleetMapAction, InitialFleetMapState} from '../../store/reducers/FleetMapReducer';
import {FilterAction} from '../../store/reducers/FiltersReducer';
import MapControls from './MapControls';
import MapLegendWrapper from './legend/MapLegendWrapper';
import MapAircraft from './MapAircraft';
import MapMarker from './MapMarker';
import LabTerminalMapMarker from './LabTerminalMapMarker';
import MapFlightPath from './MapFlightPath';
import {MapViewContext, FLEET_MAP_FILTERS, MapAllAircraftProps} from '../../utils/MapUtil';
import {
  MAP_MIN_ZOOM,
  CHAR_SEARCH_COUNT,
  MAP_CENTER,
  MAP_DEFAULT_ZOOM,
  AIRCRAFT_SORT_ORDER
} from '../../utils/constants';
import {AircraftStatus} from '../../store/queries/fleetMap/aircraftStatusQuery';

import SelectedAircraftPopup from './aircraftPopups/SelectedAircraftPopup';
import {selectedAircraftAnimation} from './MapStyles';
import {getFilteredItems, getFilterOptions} from '../../utils/filterUtils';
import {determineIfLiveFlight} from '../flightDetails/flightDetailsUtil';
import {DATE_TIME_FORMAT_WITH_MILLISECONDS} from '../../utils/DateTimeUtils';

const MapAllAircraft: React.FC<MapAllAircraftProps> = ({
  parentLeftOffset,
  isFullscreen,
  setIsFullScreen,
  mapDivRef,
  flightPath,
  isFlightPathLoading,
  aircraftStatusData
}) => {
  selectedAircraftAnimation(); // This will set aircraft pulse animation globally
  const map = useGoogleMap();
  const {store, dispatch} = useStore();
  const {airports} = store.app;
  const intl = useIntl();
  const {filters, filteredData, view, tags, selectedAircraftId} = store.fleetMap;
  const isolateRoute = view.isolateRoute === 'true' ? true : false;
  const [searchCriteria, setSearchCriteria] = useState<string>('');
  const isTailTagOpen = tags.tailTagsOpen === 'true' ? true : false;
  const showLargePopup = tags.tagSizeLarge === 'false' ? false : true;
  const [hoveredAircraft, setHoveredAircraft] = useState<string | undefined>(undefined);
  const selectedAircraftData = aircraftStatusData
    ? aircraftStatusData.find((aircraftStatus) => aircraftStatus.aircraftId === selectedAircraftId)
    : undefined;
  const isLabTerminal = selectedAircraftData?.isLabTerminal;
  const flightConnectionEnd = !isNil(selectedAircraftData?.connectedEndTimestamp)
    ? selectedAircraftData?.connectedEndTimestamp
    : !isNil(selectedAircraftData?.actualArrivalTstamp)
    ? selectedAircraftData?.actualArrivalTstamp
    : selectedAircraftData?.estimatedArrivalTstamp;

  const timeOverrideEnable = sessionStorage.timeOverrideEnable;
  const timeOverrideValue = sessionStorage.timeOverrideValue;
  const isLiveFlight = determineIfLiveFlight(
    selectedAircraftData?.flightStartTimestamp,
    selectedAircraftData?.flightEndTimestamp,
    flightConnectionEnd,
    timeOverrideEnable && timeOverrideValue
      ? moment(timeOverrideValue).format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
      : moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
  );
  const searchCriteriaLower = searchCriteria.toLowerCase();
  const searchResults =
    searchCriteria.length < CHAR_SEARCH_COUNT || !filteredData
      ? []
      : filteredData
          .filter(
            (d: AircraftStatus) =>
              (d.tailId && d.tailId.toLowerCase().includes(searchCriteriaLower)) ||
              (d.serialNumber && d.serialNumber.toLowerCase().includes(searchCriteriaLower))
          )
          .map((d: any, index: any) => ({...d, index}))
          .reverse();

  const filteredAircraftData: AircraftStatus[] = aircraftStatusData
    ? getFilteredItems(aircraftStatusData, FLEET_MAP_FILTERS, filters.filters)
    : null;

  /**
   * Sets if we want tail tags to be open
   * @param open true/false
   */
  const setTailTagOpen = useCallback(
    (open: boolean) => {
      dispatch({type: FleetMapAction.SET_TAIL_TAG_OPEN, payload: open.toString()});
    },
    [dispatch]
  );

  /**
   * Sets if we want tail tags to be large
   * @param large true/false
   */
  const setTagSizeLarge = useCallback(
    (large: boolean) => {
      dispatch({type: FleetMapAction.SET_TAG_SIZE_LARGE, payload: large.toString()});
    },
    [dispatch]
  );

  /**
   * Callback that sets the selected aircraft in the state
   * @param aircraft Aircraft data or undefined if un-setting
   */
  const setSelectedAircraftCallback = useCallback(
    (aircraft: AircraftStatus | undefined) => {
      dispatch({
        type: FleetMapAction.SET_SELECTED_AIRCRAFT,
        payload: !isNil(aircraft) ? aircraft.aircraftId : InitialFleetMapState.selectedAircraftId
      });
    },
    [dispatch]
  );

  searchResults?.forEach((aircraft) => {
    aircraft['sort_order_value'] = AIRCRAFT_SORT_ORDER[aircraft.status];
    return aircraft;
  });
  searchResults?.sort((a, b) => (a.sort_order_value < b.sort_order_value ? 1 : -1));

  useEffect(() => {
    if (aircraftStatusData) {
      const {domainOptions, rangeOptions} = getFilterOptions({aircraftStatusData}, FLEET_MAP_FILTERS);
      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, FLEET_MAP_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [aircraftStatusData, dispatch]);

  useEffect(() => {
    if (aircraftStatusData) {
      dispatch({
        type: FleetMapAction.SET_FILTERED_DATA,
        payload: {
          filteredData: filteredAircraftData
        }
      });
    }
    //eslint-disable-next-line
  }, [aircraftStatusData, filters, dispatch]);

  useEffect(() => {
    if (aircraftStatusData) {
      if (filteredAircraftData.length > 0) {
        let isAircraftAvailableFilter = true;
        if (selectedAircraftId) {
          const selectedFilteredAircraft = filteredAircraftData.find(
            (aircraft) => aircraft.aircraftId === selectedAircraftId
          );
          if (isNil(selectedFilteredAircraft)) {
            isAircraftAvailableFilter = false;
            setSelectedAircraftCallback(undefined);
            dispatch({type: FleetMapAction.SET_ISOLATE_ROUTE, payload: 'false'});
            dispatch({
              type: FleetMapAction.SELECTED_AIRCRAFT_NOT_AVAILABLE_SNACK_BAR,
              payload: {intl, aircraftId: selectedAircraftId}
            });
          }
        }
        if (!selectedAircraftId || !isAircraftAvailableFilter) {
          if (filteredAircraftData) {
            const bounds = new google.maps.LatLngBounds();
            forEach(filteredAircraftData, function (val) {
              bounds.extend(new google.maps.LatLng(val.lastLatitude, val.lastLongitude));
            });
            if (!sessionStorage.curMapZoom || sessionStorage.curMapZoom === MAP_DEFAULT_ZOOM) {
              map?.fitBounds(bounds);
            } else if (sessionStorage.resetMap === 'true') {
              map?.fitBounds(bounds);
              google.maps.event.addListener(map, 'bounds_changed', () => {
                sessionStorage.resetMap = false;
              });
            }
          } else {
            map?.setCenter(MAP_CENTER);
            map?.setZoom(MAP_DEFAULT_ZOOM);
          }
        }
      } else {
        // When the selected filter doesn't have any data
        if (selectedAircraftId) {
          setSelectedAircraftCallback(undefined);
          dispatch({type: FleetMapAction.SET_ISOLATE_ROUTE, payload: 'false'});
          dispatch({
            type: FleetMapAction.SELECTED_AIRCRAFT_NOT_AVAILABLE_SNACK_BAR,
            payload: {intl, aircraftId: selectedAircraftId}
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAircraftId, filteredData, dispatch, filters, isolateRoute, map]);

  const pixelPositionOffset = {
    x: -12,
    y: -24
  };

  const labPositionOffset = {
    x: -15,
    y: -15
  };

  return (
    <>
      <MapControls
        mapControlContext={MapViewContext.MAP_PAGE}
        setSearchCriteria={setSearchCriteria}
        searchCriteria={searchCriteria}
        searchResults={searchResults}
        isFullScreen={isFullscreen}
        setIsFullScreen={setIsFullScreen}
        mapDivRef={mapDivRef}
        setHoveredAircraft={setHoveredAircraft}
        setPopup={setSelectedAircraftCallback}
        minZoom={MAP_MIN_ZOOM}
        setTailTagOpen={setTailTagOpen}
        isTailTagOpen={isTailTagOpen}
        setShowLargePopup={setTagSizeLarge}
        showLargePopup={showLargePopup}
        searchBoxDisabled={false}
        disableTailTagConfig={false}
        selectedAircraftData={selectedAircraftData}
        selectedAircraftNetwork={selectedAircraftData?.networkCapability}
      />
      {selectedAircraftData ? (
        <SelectedAircraftPopup
          mapDivRef={mapDivRef}
          selectedAircraft={selectedAircraftData}
          flightPath={flightPath}
          popupSerialNumber={selectedAircraftData.serialNumber}
          onClose={() => {
            setSelectedAircraftCallback(undefined);
            dispatch({type: FleetMapAction.SET_ISOLATE_ROUTE, payload: 'false'});
            if (sessionStorage.userMapCenter) {
              const mapCoords = sessionStorage.userMapCenter.replace(/[{()}]/g, '');
              const latLongStr = mapCoords.split(',', 2);
              const mapLat = parseFloat(latLongStr[0]);
              const mapLong = parseFloat(latLongStr[1]);
              const userCenter = {
                lat: mapLat,
                lng: mapLong
              };
              map?.setCenter(userCenter);
              map?.setZoom(Number(sessionStorage.userMapZoom));
              sessionStorage.removeItem('userMapCenter');
              sessionStorage.removeItem('userMapZoom');
            }
          }}
        />
      ) : (
        <></>
      )}
      <MapLegendWrapper
        context={MapViewContext.MAP_PAGE}
        legendWrapperHeight="40px"
        viewName="fleet_map"
        metricsData={filteredData}
        mapDivRef={mapDivRef}
      />
      {isLabTerminal && flightPath?.start && selectedAircraftId && (
        <LabTerminalMapMarker
          pixelPositionOffset={labPositionOffset}
          key={`${0}-start`}
          type="start"
          internal={false}
          mapDivRef={mapDivRef}
          location={flightPath.start}
          aircraftData={selectedAircraftData}
        />
      )}
      {!isLabTerminal && flightPath?.start && selectedAircraftId ? (
        <MapMarker
          context={MapViewContext.MAP_PAGE}
          pixelPositionOffset={pixelPositionOffset}
          key={`${0}-start`}
          type="start"
          internal={false}
          mapDivRef={mapDivRef}
          location={flightPath.start}
          airport={
            !isNil(selectedAircraftData) && !isNil(selectedAircraftData.origin)
              ? airports[selectedAircraftData.origin]
              : null
          }
        />
      ) : (
        <> </>
      )}
      {!isLabTerminal && flightPath?.destination && selectedAircraftId ? (
        <MapMarker
          context={MapViewContext.MAP_PAGE}
          pixelPositionOffset={pixelPositionOffset}
          key={`${0}-end`}
          type="end"
          internal={false}
          mapDivRef={mapDivRef}
          location={flightPath.destination}
          airport={
            !isNil(selectedAircraftData) && !isNil(selectedAircraftData.destination)
              ? airports[selectedAircraftData.destination]
              : null
          }
        />
      ) : (
        <> </>
      )}
      {flightPath?.start && selectedAircraftId ? (
        <MapFlightPath
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          parentLeftOffset={parentLeftOffset}
          flightPathData={flightPath}
          isFlightPathLoading={isFlightPathLoading}
          mapViewContext={MapViewContext.MAP_PAGE}
          disablePopup={false}
          isLiveFlight={isLiveFlight}
        />
      ) : (
        <> </>
      )}
      {filteredData || aircraftStatusData
        ? (filteredData ? filteredData : aircraftStatusData).map((aircraft: AircraftStatus, idx: number) => (
            <MapAircraft
              aircraft={aircraft}
              mapDivRef={mapDivRef}
              key={`${idx}-aircraft`}
              hoveredAircraft={hoveredAircraft}
              setHoveredAircraft={setHoveredAircraft}
              setPopup={setSelectedAircraftCallback}
              isTailTagOpen={isTailTagOpen}
              showLargePopup={showLargePopup}
              isFlightPathLoading={isFlightPathLoading}
            />
          ))
        : null}
    </>
  );
};

export default MapAllAircraft;
