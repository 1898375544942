/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail Information per flight
 */

import React, {useState} from 'react';
import Flight from '@mui/icons-material/Flight';
import {isNil} from 'lodash';
import FlightDetailsExpansion, {
  AccordionBodyItem,
  AccordionBodyItemType,
  AccordionSection
} from './FlightDetailsExpansion';
import LeftContainerCard from './LeftContainerCard';
import {MONTHLY_USAGE_BAR_COLORS, USAGE_EXCEEDED_TEXT} from '../common/theme/Colors';
import {BAND_KU, DATA_PACKAGE_PROGRESS_BAR_NAME, HOURLY_PLAN_PROGRESS_BAR_NAME} from '../../utils/constants';
import {IMonthlyUsage} from '../../store/queries/flightDetails/monthlyUsageQuery';
import {formatNullableString, formatSwVersionStatus} from './flightDetailsUtil';
import {IVersionInfo} from '../../store/queries/flightDetails/swVersionsQuery';
import {convertUsageData} from '../dashboard/usageCards/utils';

export interface TailInformationProps {
  width: number;
  gutter: number;
  isLoading: boolean;
  serialNumber: string;
  tailId: string;
  aircraftType: string;
  endUser?: string;
  networkCapability: string;
  isDualBand: boolean;
  kaSwVersion?: string;
  kuSwVersion?: string;
  kaSwVersionStatus?: IVersionInfo;
  kuSwVersionStatus?: IVersionInfo;
  ipAddress: string;
  macAddress: string;
  showMonthlyDataUsage: boolean;
  monthlyDataUsage: IMonthlyUsage;
  isRegionalPlan: boolean;
  customer: string;
  valueAddedReseller: string;
  displayInternal: boolean;
  displayValueAddedReseller: boolean;
  isLabTerminal?: boolean;
  cpeIpAddress: string;
}

const TailInformation: React.FC<TailInformationProps> = (props) => {
  const {
    width,
    gutter,
    isLoading,
    serialNumber,
    endUser,
    tailId,
    aircraftType,
    networkCapability,
    isDualBand,
    kaSwVersion,
    kuSwVersion,
    kaSwVersionStatus,
    kuSwVersionStatus,
    ipAddress,
    macAddress,
    showMonthlyDataUsage,
    monthlyDataUsage,
    isRegionalPlan,
    valueAddedReseller,
    customer,
    displayInternal,
    displayValueAddedReseller,
    isLabTerminal,
    cpeIpAddress
  } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  /**
   * Determines the network & software version
   * @param networkCapability (ex. Ka, Ku or Dual-Band)
   * @param kaSwVersion Ka software version
   * @param kuSwVersion Ku software version
   * @returns hardware software config List
   */
  const getHardwareSoftwareSection = (
    networkCapability: string,
    ipAddress: string,
    macAddress: string,
    kaSwVersion?: string,
    kuSwVersion?: string,
    cpeIpAddress?: string
  ) => {
    const sectionContent: AccordionBodyItem[] = [{name: 'Network', value: networkCapability}];
    if (!isNil(ipAddress)) {
      sectionContent.push({name: 'IP Address', value: formatNullableString(ipAddress)});
    }
    if (!isNil(macAddress)) {
      sectionContent.push({name: 'MAC Address', value: formatNullableString(macAddress)});
    }
    if (isDualBand) {
      sectionContent.push(
        {
          name: 'Ka Terminal SW',
          value: formatNullableString(kaSwVersion),
          swStatus: formatSwVersionStatus(kaSwVersionStatus)
        },
        {
          name: 'Ku Terminal SW',
          value: formatNullableString(kuSwVersion),
          swStatus: formatSwVersionStatus(kaSwVersionStatus)
        }
      );
    } else {
      sectionContent.push({
        name: 'Terminal SW',
        value: formatNullableString(kaSwVersion || kuSwVersion),
        swStatus: formatSwVersionStatus(
          kaSwVersionStatus?.version ? kaSwVersionStatus : null || kuSwVersionStatus?.version ? kuSwVersionStatus : null
        )
      });
    }
    if (networkCapability !== BAND_KU) {
      sectionContent.push({
        name: 'Ka-Band IP Address',
        value: formatNullableString(cpeIpAddress)
      });
    }

    return sectionContent;
  };

  const getTailInfoSection = (tailInfo: any) => {
    const sectionContent = [];
    tailInfo.aircraftType = tailInfo?.isLabTerminal ? tailInfo?.endUser : tailInfo.aircraftType;
    if (tailInfo.displayInternal) {
      // Show Customer Name and VAR for Internal user
      sectionContent.push(
        {name: 'Serial Number', value: tailInfo.serialNumber},
        {name: 'Tail ID', value: tailInfo.tailId},
        {name: 'Aircraft', value: tailInfo.aircraftType},
        {name: 'Customer', value: tailInfo.customer},
        {name: 'VAR', value: tailInfo.valueAddedReseller}
      );
    } else if (tailInfo.displayValueAddedReseller) {
      // Show Customer Name for VAR user
      sectionContent.push(
        {name: 'Serial Number', value: tailInfo.serialNumber},
        {name: 'Tail ID', value: tailInfo.tailId},
        {name: 'Aircraft', value: tailInfo.aircraftType},
        {name: 'Customer', value: tailInfo.customer}
      );
    } else {
      sectionContent.push(
        {name: 'Serial Number', value: tailInfo.serialNumber},
        {name: 'Tail ID', value: tailInfo.tailId},
        {name: 'Aircraft', value: tailInfo.aircraftType}
      );
    }
    return sectionContent;
  };

  /**
   * Formats data usage string as needed
   * @param usage Data used
   * @param totalUsage Total data allowed
   * @param units Units GB or h
   * @param usageExceeded Flag to indicate whether usage has exceeded allowed usage
   * @returns Formatted string
   */
  const formatMonthlyDataUsage = (usage: any, totalUsage: any, units: string, usageExceeded: Boolean) => {
    if (usageExceeded) {
      return (
        <>
          <b>
            {usage} of {totalUsage}
            {units}
          </b>
        </>
      );
    } else {
      return (
        <>
          <b>{usage ? usage : 0}</b> of {totalUsage}
          {units}
        </>
      );
    }
  };

  /**
   * Calculates the percentage of data usage
   * @param usage Data used
   * @param totalUsage Total data allowed
   * @returns percentage of data usage
   */
  const monthlyUsagePercentage = (usage: number, totalUsage: number) => {
    if (usage > totalUsage) {
      return 100;
    } else {
      const value = (usage / totalUsage) * 100;
      return value;
    }
  };

  interface IMonthlyUsageItems {
    name: string;
    value: any;
    textColor?: string;
    progressBar?: any;
  }
  let monthlyUsageItems : IMonthlyUsageItems[];
  /**
   * Prepares data for data usage sub section
   * @param monthlyUsage Data usage from API
   * @returns data for data usage sub section
   */
  const monthlyUsageConfigs = (monthlyUsage: IMonthlyUsage) => {
    monthlyUsageItems = [
      {
        name: 'Service Plan',
        value: monthlyUsage?.service_plan_name
      },
      {
        name: 'Monthly Connected Time',
        value: monthlyUsage?.monthly_connected_time
      }
    ]
    if(isRegionalPlan){
      monthlyUsageItems.unshift({
        name: 'Roaming Data Used',
        value: monthlyUsage?.total_roaming_data_used >=0 ? `${convertUsageData(monthlyUsage?.total_roaming_data_used, true, false)} GB` : '--'
      })
    }
    if (!monthlyUsage?.total_data_allowed && !monthlyUsage?.is_hourly_plan) {
      monthlyUsageItems.unshift(
        {
          name: 'Monthly Data Usage',
          value: monthlyUsage?.total_data_used ? monthlyUsage.total_data_used + ' GB' : null
        })
      return {
        header: 'Monthly Usage',
        type: AccordionBodyItemType.Grid,
        items: monthlyUsageItems,
        columnsPerRow: 1
      };
    } else if (!monthlyUsage?.is_hourly_plan && monthlyUsage?.total_data_allowed) {
      const monthlyUsagePercent = monthlyUsagePercentage(
        Number(monthlyUsage?.total_data_used),
        Number(monthlyUsage?.total_data_allowed)
      );
      const usageExceeded = Number(monthlyUsage?.total_data_used) > Number(monthlyUsage?.total_data_allowed);
      const progressBar = {
        barColor:
          monthlyUsagePercent <= 50
            ? MONTHLY_USAGE_BAR_COLORS[0]
            : monthlyUsagePercent <= 75
            ? MONTHLY_USAGE_BAR_COLORS[1]
            : MONTHLY_USAGE_BAR_COLORS[2],
        currentProgress: monthlyUsagePercent,
        start: '0 GB',
        end: monthlyUsage?.total_data_allowed + ' GB'
      };
      monthlyUsageItems.unshift(
        {
          name: 'Data used this month',
          value: formatMonthlyDataUsage(
            monthlyUsage?.total_data_used,
            monthlyUsage?.total_data_allowed,
            'GB',
            usageExceeded
          ),
          textColor: usageExceeded ? USAGE_EXCEEDED_TEXT : null
        },
        {
          name: DATA_PACKAGE_PROGRESS_BAR_NAME,
          value: '',
          progressBar: progressBar
        }
      );
      return {
        header: 'Monthly Usage',
        type: AccordionBodyItemType.Grid,
        items: monthlyUsageItems,
        columnsPerRow: 1
      };
    } else if (!monthlyUsage?.total_data_allowed && monthlyUsage?.is_hourly_plan) {
      const monthlyUsagePercent = monthlyUsagePercentage(
        Number(monthlyUsage?.total_hours_used_in_minutes),
        Number(monthlyUsage?.total_hours_allowed_in_minutes)
      );
      const usageExceeded =
        Number(monthlyUsage?.total_hours_used_in_minutes) > Number(monthlyUsage?.total_hours_allowed_in_minutes);
      const progressBar = {
        barColor:
          monthlyUsagePercent <= 50
            ? MONTHLY_USAGE_BAR_COLORS[0]
            : monthlyUsagePercent <= 75
            ? MONTHLY_USAGE_BAR_COLORS[1]
            : MONTHLY_USAGE_BAR_COLORS[2],
        currentProgress: monthlyUsagePercent,
        start: '0 hrs',
        end: monthlyUsage?.total_hours_allowed + ' hrs'
      };
      const hourUsageItems = [
        {
          name: 'Hours used this month',
          value: formatMonthlyDataUsage(
            monthlyUsage?.total_hours_used,
            monthlyUsage?.total_hours_allowed,
            'h',
            usageExceeded
          ),
          textColor: usageExceeded ? USAGE_EXCEEDED_TEXT : null
        },
        {
          name: HOURLY_PLAN_PROGRESS_BAR_NAME,
          value: '',
          progressBar: progressBar
        },
        {
          name: 'Monthly Data Usage',
          value: monthlyUsage?.total_data_used ? monthlyUsage.total_data_used + ' GB' : null
        }
      ]
      if (isRegionalPlan) {
        hourUsageItems.push({
          name: 'Roaming Data Used',
          value: monthlyUsage?.total_roaming_data_used >=0 ? `${convertUsageData(monthlyUsage?.total_roaming_data_used, true, false)} GB` : '--'
        })
      }
      return {
        header: 'Monthly Connected Time',
        type: AccordionBodyItemType.Grid,
        items: hourUsageItems,
        columnsPerRow: 1
      };
    }
  };

  let cardSections: AccordionSection[] = [
    {
      header: 'Identification',
      type: AccordionBodyItemType.Grid,
      items: getTailInfoSection({
        displayInternal,
        displayValueAddedReseller,
        serialNumber,
        tailId,
        aircraftType,
        customer,
        endUser,
        valueAddedReseller,
        isLabTerminal
      }),
      columnsPerRow: 1
    },
    {
      header: 'Hardware & Software',
      type: AccordionBodyItemType.Grid,
      items: getHardwareSoftwareSection(
        networkCapability,
        ipAddress,
        macAddress,
        kaSwVersion,
        kuSwVersion,
        cpeIpAddress
      ),
      columnsPerRow: 1
    }
  ];

  if (showMonthlyDataUsage) {
    cardSections.push(monthlyUsageConfigs(monthlyDataUsage));
  }

  return (
    <LeftContainerCard id="tail-information" width={width} height={528} gutter={gutter} isLoading={isLoading}>
      <FlightDetailsExpansion
        panelId="tailInformation"
        title="Tail Information"
        Icon={Flight}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sections={cardSections}
      />
    </LeftContainerCard>
  );
};

export default TailInformation;
