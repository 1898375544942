/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description:
 * Seemingly Universal Tooltip Component
 */
import React from 'react';
import BoldSpan from '../../../BoldSpan';



interface ToolTipDataRow {
  prefix?: string;
  value: any;
}

export interface ToolTipData {
  label: string | ToolTipDataRow;
  points: ToolTipDataRow[] | ToolTipDataRow;
  color: string;
}

/**
 * Format Data Label to layout & style prefix, value, and margin for Tooltip Row data
 *
 * @param label The Row label data
 * @param addBreak If true, include <br /> after label (for legacy IFCDashboards),
 *           otherwise false (for FlightDetails)
 * @return Formatted JSX.Element of Data Label and <br /> or Margin to next element.
 */
const formatDataLabel = (label: ToolTipDataRow, addBreak: boolean): JSX.Element =>
  addBreak ? (
    <>
      <BoldSpan>{label.prefix || ''}</BoldSpan>
      {label.value}
      <br />
    </>
  ) : (
    <>
      <BoldSpan>{label.prefix || ''}</BoldSpan>
      {label.value}
      <div style={{display: 'block', margin: '-2px 0 0 0'}} />
    </>
  );

/**
 * Format ToolTip Row Data with layout & style
 *
 * @param value The Row Value data
 * @param addBreak If true, include <br /> after label (for legacy IFCDashboards),
 *           otherwise false (for FlightDetails)
 * @param index The array index if applicable
 * @return Formatted JSX.Element of Row Value Data
 */
const formatToolTipRow = (value: ToolTipDataRow, addBreak: boolean, index: number): JSX.Element =>
  addBreak ? (
    <span key={`tool-tip-${index}`}>
      {value.prefix || ''}
      <BoldSpan>{value.value}</BoldSpan>
      <br />
    </span>
  ) : (
    <span key={`tool-tip-${index}`}>
      {value.prefix || ''}
      <BoldSpan>{value.value}</BoldSpan>
    </span>
  );

/**
 * ToolTip Formatter
 *
 * @param tooltipData The Data to format for the Tooltip
 * @return The ToolTip Layout and Content as JSX.Element
 *
 */
const ToolTip = (tooltipData: ToolTipData): JSX.Element => {
  const {label, points, color} = tooltipData;

  const style = {
    color,
    fontSize: '16px',
    display: color ? 'inline' : 'none'
  };

  const toolTipContainerStyle = {
    display: color ? 'none' : 'inline-block',
    fontFamily: '"Source Sans Pro", sans-serif',
    padding: '4px 8px'
  };

  const formatToolTipArrayRow = (v: ToolTipDataRow, i: number, o: ToolTipDataRow[]): any =>
    formatToolTipRow(v, o.length - 1 !== i, i);

  const val: any = Array.isArray(points) ? points.map(formatToolTipArrayRow) : formatToolTipRow(points, false, 0);

  const lab = typeof label === 'string' ? label : color ? formatDataLabel(label, true) : formatDataLabel(label, false);

  return (
    <div className="chart-tooltip-container" style={toolTipContainerStyle}>
      <span style={style}>● &nbsp;</span>
      {lab}
      <span style={style}>
        <br />
      </span>
      {val}
      <span style={style}>
        <br />
      </span>
    </div>
  );
};

export default ToolTip;
