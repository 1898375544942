/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Help Tool Tip React Component
 *
 */
import React from 'react';
import {Tooltip, TooltipProps} from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {FormattedMessage} from 'react-intl';
import styled from '@emotion/styled';
import {
  HELP_TOOLTIP_BG,
  HELP_TOOLTIP_TEXT,
  HELP_TOOLTIP_COLOR,
  BOX_SHADOW_HELP_TOOLTIP_COLOR
} from '../../theme/Colors';
import {formatToolTipText} from '../../../../utils/TooltipUtil';

const helpTooltipProps: TooltipProps = {
  title: '',
  children: <></>,
  componentsProps: {
    tooltip: {
      sx: {
        backgroundColor: HELP_TOOLTIP_BG,
        color: HELP_TOOLTIP_TEXT,
        fontSize: '14px',
        fontFamily: '"Source Sans Pro", sans-serif',
        border: `1px solid ${HELP_TOOLTIP_BG}`,
        lineHeight: '18px',
        maxWidth: '420px',
        marginBottom: '-10px',
        marginLeft: '2px',
        boxShadow: `1px 1px 20px 0px ${BOX_SHADOW_HELP_TOOLTIP_COLOR}`,
        top: '3px'
      }
    },
    arrow: {
      sx: {
        color: HELP_TOOLTIP_BG
      }
    }
  }
};

interface HelpToolTipProps {
  message: string;
  iconFill?: string;
  marginOverride?: string;
}

const InfoOutlinedStyle = styled(({iconFill, marginOverride, ...props}) => <InfoOutlined {...props} />)`
  color: ${HELP_TOOLTIP_COLOR};
  font-size: 18px;
  margin: ${(props) => (props.marginOverride ? props.marginOverride : `0 5px 0px 10px`)};
  ${(props) => (props.iconFill ? `fill: ${props.iconFill};` : '')}
`;

/**
 * Render the help tool tips
 * @param props Help tool tip properties
 * @returns Help Tool Tip
 */
const HelpToolTip: React.FC<HelpToolTipProps> = ({message, iconFill, marginOverride}) => {
  return (
    <Tooltip
      {...helpTooltipProps}
      arrow
      placement="top"
      enterDelay={250}
      title={
        <React.Fragment>
          <div style={{lineHeight: '16px', margin: '11px 13px'}}>
            <FormattedMessage id={message}>{formatToolTipText}</FormattedMessage>
          </div>
        </React.Fragment>
      }
    >
      <span>
        <InfoOutlinedStyle sx={{width: 17, height: 17}} iconFill={iconFill} marginOverride={marginOverride} />
      </span>
    </Tooltip>
  );
};

export default HelpToolTip;
