/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight List Util for handling the data cells
 */

import {
  ListGridColumn,
  DefaultCellRendererV2,
  DateCellRendererV2,
  CustomLink,
  CellRenderer
} from '@viasat/insights-components';
import {
  getColumnIdEnumName,
  getColumnFullTitle,
  getColumnFormattedFullTitle,
  AirportTooltipCellRenderer,
  getLocationFilterRangeOptions,
  getServicePlansFilterRangeOptions
} from '../listUtils';
import {convertObjectToFilterRangeOptions, FilterOptionsType, FilterType} from '../../../utils/filterUtils';
import {DefaultedTextCellRenderer} from '../../common/elements/listGridCells/ListGridDefaultedText';
import WarningIcon from '@mui/icons-material/WarningSharp';
import ListGridIconTextOrLink from '../../common/elements/listGridCells/ListGridIconTextOrLink';
import moment from 'moment';
import {DATE_VERBOSE_FULL_FORMAT} from '../../../utils/DateTimeUtils';
import {AIRCRAFT_STATUS_DECOMMISSIONED} from '../../../utils/constants';
import {FLIGHT_LIST_WARNING} from '../../common/theme/Colors';

// Flight List Hidden Columns mappings for encoding/decoding sharelink
// Required: Enum name should match columnId name, case-sensitive
export enum FlightListColumnId {
  flight = -1, // fixed column, cannot be hidden
  customer = 0,
  serialNumber,
  tailId,
  aircraftType,
  networkCapability,
  macAddress,
  ipAddress,
  origin,
  destination,
  flightStart,
  flightEnd,
  flightDuration,
  connectedStart,
  connectedEnd,
  connectedDuration,
  dataUsage,
  servicePlanName
  // Above Column Ids should remain unchanged for backwards-compatibility.
  // Validated by src/components/lists/__tests__/hiddenColumnsBackwardsCompatibility.spec.ts
  // New Column Ids should be added after this line
}

/**
 * Get Flight List ColumnId Name
 * @param columnId columnId enum to convert to name
 * @returns name of columnId as string
 */
export const getColumnIdName = (columnId: FlightListColumnId) => getColumnIdEnumName(columnId, FlightListColumnId);

// Query Field Constants
export const QUERY_FIELD_MAPPING = {
  customer: 'customer',
  serialNumber: 'serialNumber',
  tailId: 'tailId',
  flight: 'flight',
  aircraftType: 'aircraftType',
  networkCapability: 'networkCapability',
  origin: 'origin',
  destination: 'destination',
  flightStart: 'flightStartTimestamp',
  flightEnd: 'flightEndTimestamp',
  flightDuration: 'flightDurationSeconds',
  connectedStart: 'connectedStartTimestamp',
  connectedEnd: 'connectedEndTimestamp',
  connectedDuration: 'connectedDurationSeconds',
  dataUsage: 'dataUsageBytes',
  macAddress: 'macAddress',
  ipAddress: 'ipAddress',
  servicePlanName: 'servicePlanName'
};

/**
 * Get Flight List Query Field Mapping
 * @param columnId columnId enum to convert to query field
 * @param append append string to columnId name (default='')
 * @returns query field as string, otherwise undefined
 */
export const getQueryFieldMapping = (columnId: FlightListColumnId, append: string = '') => {
  const columnIdName = getColumnIdName(columnId) + append;
  return columnIdName ? QUERY_FIELD_MAPPING[columnIdName] : undefined;
};

const setColumnPropsList = (columnPropsList: ListGridColumn[]) => {
  return columnPropsList.map((column: ListGridColumn) => {
    const updatedColumn = column.fullTitlePrefix
      ? {
          ...column,
          fullTitle: getColumnFullTitle(column.fullTitlePrefix, column.title),
          formattedFullTitle: getColumnFormattedFullTitle(column.fullTitlePrefix, column.title)
        }
      : column;

    return updatedColumn;
  });
};

/**
 * Render function for Flight Details links
 * @param cellProps props for the cell renderer
 * @return Flight Details link component
 */
export const FlightDetailsLinkRenderer: CellRenderer = ({cellData, rowData}) => {
  let linkText =
    !rowData.isDark && (rowData.origin?.code || rowData.destination?.code)
      ? 'Flight Details'
      : rowData.flightDetected === true
      ? 'Flight Details'
      : 'Ground Session';
  if (rowData.isDark) {
    linkText = 'Dark Flight';
  }
  return (
    <>
      {rowData.isDark && <WarningIcon sx={{color: '#EC7424', width: 17, height: 23}} />}
      &nbsp;<CustomLink to={`/flight/details/${cellData || rowData.faFlightId}`}>{linkText}</CustomLink>
    </>
  );
};

export const ServicePlanCellRenderer: CellRenderer = ({rowData, cellIdBase, cellData}) => {
  if (cellData && rowData.status === AIRCRAFT_STATUS_DECOMMISSIONED) {
    return (
      <ListGridIconTextOrLink
        cellIdBase={cellIdBase}
        icon={<WarningIcon sx={{width: '24px', height: '24px', color: FLIGHT_LIST_WARNING}} />}
        text={cellData}
        tooltipText={`${AIRCRAFT_STATUS_DECOMMISSIONED} on ${moment
          .utc(rowData.siteCreatedTstamp)
          .format(DATE_VERBOSE_FULL_FORMAT)}`}
        marginLeft={15}
        rowData={rowData}
      />
    );
  } else {
    return <DefaultCellRendererV2 cellIdBase={cellIdBase} cellData={cellData} rowData={rowData} />;
  }
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isEndUser flag for hiding the customer column
 * @return list of ListGridColumnProps objects
 */
export const buildFlightListColumns = (isEndUser: boolean) => {
  const columns: ListGridColumn[] = [
    {
      key: 'flight',
      dataKey: 'flightId',
      showHideGroup: '__static',
      title: 'Details',
      getColumnJsx: FlightDetailsLinkRenderer,
      align: 'left',
      width: 150,
      frozen: true
    }
  ];

  if (!isEndUser) {
    columns.push({
      key: 'customer',
      dataKey: 'customer',
      showHideGroup: '__groupless',
      title: 'Customer',
      getColumnJsx: DefaultedTextCellRenderer,
      width: 280,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.customer.column.title'
    });
  }

  columns.push(
    {
      key: 'serialNumber',
      dataKey: 'serialNumber',
      showHideGroup: 'Tail Info',
      title: 'SN',
      getColumnJsx: DefaultCellRendererV2,
      width: 110,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.serial_number.column.title'
    },
    {
      key: 'tailId',
      dataKey: 'tailId',
      showHideGroup: 'Tail Info',
      title: 'Tail ID',
      getColumnJsx: DefaultCellRendererV2,
      width: 100,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.tail_id.column.title'
    },
    {
      key: 'aircraftType',
      dataKey: 'aircraftType',
      showHideGroup: 'Tail Info',
      title: 'Aircraft Type',
      getColumnJsx: DefaultCellRendererV2,
      width: 160,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.aircraft_type.column.title'
    },
    {
      key: 'networkCapability',
      dataKey: 'networkCapability',
      showHideGroup: 'Tail Info',
      displayGroup: 1,
      title: 'Network',
      getColumnJsx: DefaultedTextCellRenderer,
      width: 120,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.network_capability.column.title'
    },
    {
      key: 'macAddress',
      dataKey: 'macAddress',
      showHideGroup: 'Tail Info',
      displayGroup: 1,
      title: 'MAC Address',
      getColumnJsx: DefaultCellRendererV2,
      width: 180,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.mac_address.column.title'
    },
    {
      key: 'ipAddress',
      dataKey: 'ipAddress',
      showHideGroup: 'Tail Info',
      displayGroup: 1,
      title: 'IP Address',
      getColumnJsx: DefaultCellRendererV2,
      width: 180,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.ip_address.column.title'
    }
  );

  columns.push(
    {
      key: 'origin',
      dataKey: 'origin',
      showHideGroup: 'Flight Info',
      title: 'Origin',
      defaultSort: 'desc',
      getColumnJsx: AirportTooltipCellRenderer,
      width: 120,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.origin.column.title'
    },
    {
      key: 'destination',
      dataKey: 'destination',
      showHideGroup: 'Flight Info',
      title: 'Destination',
      defaultSort: 'desc',
      getColumnJsx: AirportTooltipCellRenderer,
      width: 140,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.destination.column.title'
    }
  );

  columns.push(
    {
      key: 'flightStart',
      dataKey: 'flightStart',
      showHideGroup: 'Flight Info',
      title: 'Flight Start',
      defaultSort: 'desc',
      getColumnJsx: DateCellRendererV2,
      width: 170,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.flight_start.column.title'
    },
    {
      key: 'flightEnd',
      dataKey: 'flightEnd',
      showHideGroup: 'Flight Info',
      title: 'Flight End',
      getColumnJsx: DateCellRendererV2,
      width: 170,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.flight_end.column.title'
    },
    {
      key: 'flightDuration',
      dataKey: 'flightDuration',
      showHideGroup: 'Flight Info',
      title: 'Flight Duration',
      getColumnJsx: DefaultCellRendererV2,
      width: 160,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.flight_duration.column.title'
    },
    {
      key: 'connectedStart',
      dataKey: 'connectedStart',
      showHideGroup: 'Connection Info',
      title: 'Connected Start',
      defaultSort: 'desc',
      getColumnJsx: DateCellRendererV2,
      width: 170,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.connected_start.column.title'
    },
    {
      key: 'connectedEnd',
      dataKey: 'connectedEnd',
      showHideGroup: 'Connection Info',
      title: 'Connected End',
      getColumnJsx: DateCellRendererV2,
      width: 170,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.connected_end.column.title'
    },
    {
      key: 'connectedDuration',
      dataKey: 'connectedDuration',
      showHideGroup: 'Connection Info',
      title: 'Connected Time',
      getColumnJsx: DefaultCellRendererV2,
      width: 150,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.connected_time.column.title'
    },
    {
      key: 'dataUsage',
      dataKey: 'dataUsage',
      showHideGroup: 'Connection Info',
      title: 'Data Usage (MB)',
      getColumnJsx: DefaultCellRendererV2,
      width: 160,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.data_usage.column.title'
    },
    {
      key: 'servicePlanName',
      dataKey: 'servicePlanName',
      showHideGroup: 'Connection Info',
      title: 'Service Plan',
      getColumnJsx: ServicePlanCellRenderer,
      width: 180,
      sortable: true,
      columnHeaderTooltipText: 'flight_list.service_plan_name.column.title'
    }
  );

  const labeledList = setColumnPropsList(columns);
  return labeledList;
};

export enum FilterId {
  customer = 'customer',
  serialNumber = 'serialNumber',
  tailId = 'tailId',
  aircraftType = 'aircraftType',
  networkCapability = 'networkCapability',
  flightDetected = 'flightDetected',
  origin = 'origin',
  destination = 'destination',
  servicePlanName = 'servicePlanName',
  aircraftStatus = 'aircraftStatus'
}

/**
 * Returns the range options for Customer filter
 * @param props Filter container properties
 * @returns Array of Customer Filter key/value pairs
 */
export const getCustomerFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.endUsersData ? convertObjectToFilterRangeOptions(props.endUsersData, 'name') : [];

/**
 * Returns the range options for the Serial Number filter
 * @param props Filter container properties
 * @returns Array of Serial Number Filter key/value pairs
 */
export const getSerialNumberFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'serialNumber') : [];

/**
 * Returns the range options for the Tail ID filter
 * @param props Filter container properties
 * @returns Array of Tail ID Filter key/value pairs
 */
export const getTailIdFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'tailId') : [];

/**
 * Returns the range options for the Aircraft Type filter
 * @param props Filter container properties
 * @returns Array of Aircraft Type Filter key/value pairs
 */
export const getAircraftTypeFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'aircraftType') : [];

/**
 * Returns the range options for the Network filter
 * @param props Filter container properties
 * @returns Array of Network Filter key/value pairs
 */
export const getNetworkFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'networkCapability') : [];

const FLIGHT_TYPES = [
  {
    flightDetected: 'Details',
    flightDetectedValue: 'TRUE'
  },
  {
    flightDetected: 'Ground Session',
    flightDetectedValue: 'FALSE'
  },
  {
    flightDetected: 'Dark Flight',
    flightDetectedValue: 'isDark'
  }
];

const AIRCRAFT_STATUS_OPTIONS = [
  {
    aircraftStatus: 'Active'
  },
  {
    aircraftStatus: 'Decommissioned'
  }
];
/**
 * Returns the range options for the flight details type filter
 * @param props Filter container properties
 * @returns Array of Flight details type filter key/value pairs
 */
export const getFlightTypeFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertObjectToFilterRangeOptions(FLIGHT_TYPES, 'flightDetected', 'flightDetectedValue');

/**
 * Returns options for aircraft status filter
 * @returns Array of Aircraft status filter key/value pairs
 */
export const getAircraftStatusFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertObjectToFilterRangeOptions(AIRCRAFT_STATUS_OPTIONS, 'aircraftStatus');

export const FLIGHT_LIST_FILTERS: Array<FilterType> = [
  {
    title: 'Customer',
    id: FilterId.customer,
    getValues: getCustomerFilterRangeOptions
  },
  {
    title: 'Serial Number',
    id: FilterId.serialNumber,
    getValues: getSerialNumberFilterRangeOptions
  },
  {
    title: 'Tail ID',
    id: FilterId.tailId,
    getValues: getTailIdFilterRangeOptions
  },
  {
    title: 'Aircraft Type',
    id: FilterId.aircraftType,
    getValues: getAircraftTypeFilterRangeOptions
  },
  {
    title: 'Network',
    id: FilterId.networkCapability,
    getValues: getNetworkFilterRangeOptions
  },
  {
    title: 'Details',
    id: FilterId.flightDetected,
    getValues: getFlightTypeFilterRangeOptions
  },
  {
    title: 'Origin',
    id: FilterId.origin,
    getValues: getLocationFilterRangeOptions
  },
  {
    title: 'Destination',
    id: FilterId.destination,
    getValues: getLocationFilterRangeOptions
  },
  {
    title: 'Service Plan',
    id: FilterId.servicePlanName,
    getValues: getServicePlansFilterRangeOptions
  },
  {
    title: 'Aircraft Status',
    id: FilterId.aircraftStatus,
    getValues: getAircraftStatusFilterRangeOptions
  }
];

/**
 * Performs a deep copy of the filter list and hides field(s) based on the
 * passed in parameters.
 * @param isEndUser End user flag
 * @param showAircraftStatusFilter Show Aircraft Status filter for Internal and VAR User flag
 * @returns A list of filters for flight list
 */
export const getFlightListFilters = (isEndUser: boolean, showAircraftStatusFilter: boolean): Array<FilterType> => {
  const userBasedFilters = showAircraftStatusFilter
    ? FLIGHT_LIST_FILTERS
    : FLIGHT_LIST_FILTERS.filter((filter) => filter.id !== FilterId.aircraftStatus);
  const filterList = isEndUser
    ? userBasedFilters.filter((filter) => filter.id !== FilterId.customer)
    : userBasedFilters;

  return filterList.sort((a: FilterType, b: FilterType): number => {
    return a.title > b.title ? 1 : -1;
  });
};
