/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Use card display hook
 */

import {useCallback, useMemo} from 'react';
import DataUsageCard from './DatausageCard';
import FlightHistoryMapCard from './FlightHistoryMapCard';
import TrafficCompositionCumulativeCard from './TrafficCompositionCumulativeCard';
import DataUsageTrendCard from './DataUsageTrendCard';
import {SummaryMetricsAction} from '../../store/reducers/SummaryMetricsReducer';

interface CardInterface {
  id: string;
  hash: string;
  component: (any) => JSX.Element;
}

interface CardRow {
  id: string;
  cards: CardInterface[];
}

const cardRowMapping = {
  row00: 0,
  row01: 1,
  row10: 2,
  row11: 3
} as const;

/**
 * Reorders a list of items by moving 1 item from an index to another index
 * @param list List
 * @param startIndex Start index of item
 * @param endIndex End index of item
 * @returns New list
 */

const newOrderOfCards = <Type>(list: Type[], startIndex, endIndex): Type[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Creates a unique 4 char string from the input text
 * based on djb2 hash http://www.cse.yorku.ca/~oz/hash.html
 * @param plainText string
 * @returns 4 char code
 */
const hashGenerator = (plainText: string) =>
  plainText
    .split('')
    .reduce((hash, ch) => (hash << 5) + hash + ch.charCodeAt(0), 5381)
    .toString(16)
    .slice(-4);

const initialCardDetails: CardInterface[] = [
  {
    id: 'dataUsageCard',
    component: DataUsageCard
  },
  {
    id: 'flightPathHistoryCard',
    component: FlightHistoryMapCard
  },
  {
    id: 'trafficCompositionCard',
    component: TrafficCompositionCumulativeCard
  },
  {
    id: 'dataUsageTrendCard',
    component: DataUsageTrendCard
  }
].map((row) => ({
  ...row,
  hash: hashGenerator(row.id)
}));

const useMetricsCardDisplay = (
  cardOverallOrder: string[],
  dispatch: (IAction) => void
): {onDragEnd: (result: any) => void; cardRows: CardRow[]} => {
  const {cardRows, cardOrder} = useMemo(() => {
    const displayCards = initialCardDetails.map((card: CardInterface) => ({
      ...card
    }));
    const cardOrder =
      cardOverallOrder &&
      cardOverallOrder.length === displayCards.length &&
      displayCards.every(({hash}) => cardOverallOrder.includes(hash))
        ? cardOverallOrder.map((hash) => displayCards.findIndex((card) => card.hash === hash))
        : Array.from({length: displayCards.length}, (x, i) => i);

    const cards = cardOrder.map((cardIndex) => displayCards[cardIndex]);
    const cardRows: CardRow[] = [
      {id: 'row0', cards: cards.slice(0, 2)},
      {id: 'row1', cards: cards.slice(2, 4)}
    ];

    return {
      cardRows,
      cardOrder
    };
  }, [cardOverallOrder]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const startIndex = cardRowMapping[result.source.droppableId + result.source.index];
      const endIndex = cardRowMapping[result.destination.droppableId + result.destination.index];
      const newOrder = newOrderOfCards<number>(cardOrder, startIndex, endIndex);

      dispatch({
        type: SummaryMetricsAction.SET_SUMMARY_METRICS_CARD_ORDER,
        payload: newOrder.map((index) => initialCardDetails[index].hash)
      });
    },

    [cardOrder, dispatch]
  );

  return {
    onDragEnd,
    cardRows
  };
};

export default useMetricsCardDisplay;
