/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Wrapper for the Outer Pieces of the Application
 */
import React, {useState, useEffect, useMemo} from 'react';
import {spaComs} from '@viasat/insights-spa-package';
import {BrowserRouter} from 'react-router-dom';
import * as FullStory from '@fullstory/browser';
import App from './App';
import {useStore} from './store/Store';
import {CustomerAction} from './store/reducers/CustomerReducer';
import {localLogout} from './utils/app-utils';
import {getElementId} from './utils/ElementIdUtils';
import useUrlParams from './utils/useUrlParams';
import useFetchV2 from './utils/useFetchV2';
import {InitData, initQuery} from './store/queries/initQuery';
import {InitAction} from './store/reducers/InitReducer';

interface OuterAppWrapperProps {
  hotelComsCompleteOverride?: boolean;
  disableTime?: boolean;
}

interface GroupCode {
  code: string;
  reset: boolean;
}

// Note: This component is needed because it appears we can't use useStore in the OuterAppWrapper itself,
// presumably because it is a parent to the whole application.
const GroupUpdater: React.FC<{groupCode: GroupCode; initData: InitData}> = ({groupCode, initData}) => {
  const {store, dispatch} = useStore();
  const {code, reset} = groupCode;

  useEffect(() => {
    if (!initData) return;
    FullStory.identify(initData.uid, {email: initData.email, displayName: initData.fullName});
    dispatch({type: InitAction.SET_INIT, payload: initData});
  }, [initData, dispatch]);

  useEffect(() => {
    if (code) {
      const prevCode = store.customer.previous?.code;
      dispatch({
        type: CustomerAction.SET_CUSTOMER,
        payload: {code, reset: prevCode !== code ? (!prevCode ? reset : true) : reset}
      });
    }
    // eslint-disable-next-line
  }, [dispatch, code, reset]);

  useUrlParams();

  return (
    <div
      id={getElementId('outerApp', 'groupUpdater', 'airlineCode', 'input')}
      data-current-airline-code={code}
      style={{display: 'none'}}
    />
  );
};

const OuterAppWrapper: React.FC<OuterAppWrapperProps> = ({hotelComsCompleteOverride = false, disableTime = false}) => {
  const [hotelComsComplete, setHotelComsComplete] = useState<boolean>(hotelComsCompleteOverride);
  const [groupCode, setGroupCode] = useState<GroupCode>({
    code: null,
    reset: false
  });

  const fetchParams = useMemo(() => {
    return hotelComsComplete ? {} : null;
  }, [hotelComsComplete]);
  const {data: initData} = useFetchV2({route: initQuery.route, params: fetchParams}, initQuery.transform);

  const setInitGroupCode = (code: string) => setGroupCode({code, reset: false});
  const setResetGroupCode = (code: string) => setGroupCode({code, reset: true});

  const spaInitCallback = (token: string, groupCode: string, location: string) => {
    sessionStorage.iframeLocation = location; // This location should be used for links, including share links and opening a new tab/window.

    if (localStorage.token && localStorage.token !== token) {
      localLogout();
    }

    localStorage.token = token;
    setInitGroupCode(groupCode);
    setHotelComsComplete(true);
  };

  spaComs.init(spaInitCallback, setResetGroupCode);

  if (hotelComsComplete && groupCode) {
    sessionStorage.groupCode = groupCode.code;
  }

  return (
    <>
      {hotelComsComplete ? (
        <>
          <BrowserRouter>
            <App windowLocation={document.location.search} />
            <GroupUpdater groupCode={groupCode} initData={initData} />
          </BrowserRouter>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default OuterAppWrapper;
