/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ListGrid text cell
 *
 */

import React from 'react';
import {isNil} from 'lodash';
import styled from '@emotion/styled';
import {CellRenderer} from '@viasat/insights-components';
import {GRID_CELL_DEFAULTED_TEXT_COLOR} from '../../theme/Colors';

interface ListGridTextCellProps {
  cellText: string;
}

const DefaultedSpan = styled.span<{isDefaulted: boolean; width?: number}>`
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  color: ${(props) => props.isDefaulted && GRID_CELL_DEFAULTED_TEXT_COLOR};
  width: 54px;
  justify-content: center;
  padding-left: 10px;
`;

export const ListGridText: React.FC<ListGridTextCellProps> = ({cellText}: ListGridTextCellProps) => {
  return <DefaultedSpan isDefaulted={false}>{!isNil(cellText) ? cellText : '--'}</DefaultedSpan>;
};

export const TextCellRenderer: CellRenderer = ({cellData}) => {
  return <ListGridText cellText={cellData} />;
};

export default TextCellRenderer;
