/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ChartWrapper Container for Traffic Composition
 */

import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import {useIntl} from 'react-intl';

import {LEGEND_TEXT, CHART_AXIS_MINOR_TICK, TRAFFIC_COMPOSITION_CHART_PALETTE} from '../../common/theme/Colors';
import {ChartType} from '../../common/elements/chart/types';
import {
  formatTrafficCompositionValue,
  ChartTimeSettings,
  adjustCategorizedChartData,
  getXAxisTickPositions
} from '../flightDetailsUtil';
import TrafficCompositionToolTip from './tooltip/TrafficCompositionToolTip';
import {
  DATE_CONCISE_FORMAT,
  TIME_CONCISE_NO_PAD_FORMAT,
  MS_IN_MIN,
  msAsString,
  areMsTimestampMinutesEqualTo,
  stringAsMs,
  DATE_TIME_FORMAT_WITH_HR_MINS
} from '../../../utils/DateTimeUtils';
import {TrafficCompositionData, IDataUsageInfo} from './TrafficCompositionUtils';
import {formatConfig} from '../../common/elements/chart/chartUtils';
import PercentAreaChart from '../../common/elements/chart/PercentAreaChart';
import ToolTipDateTimeLabel from '../../common/elements/chart/ToolTip/ToolTipDateTimeLabel';

const ChartContainer = styled.div`
  .highcharts-xaxis-grid path {
    stroke-width: 0;
  }
  .highcharts-crosshair,
  .highcharts-yaxis-grid,
  .highcharts-xaxis path {
    stroke-width: 1px;
    stroke: ${CHART_AXIS_MINOR_TICK};
  }
`;

export interface TrafficCompositionProps {
  currentTime: string;
  isLoading: boolean;
  trafficCompositionData: TrafficCompositionData[];
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
  dataUsage: IDataUsageInfo;
}

const TrafficComposition: React.FC<TrafficCompositionProps> = ({
  isLoading,
  trafficCompositionData,
  chartTimeSettings,
  liveMask,
  parentLeftOffset,
  dataUsage
}: TrafficCompositionProps) => {
  const [chartConfig, setChartConfig] = useState<any>(null);
  const intl = useIntl();

  useEffect(() => {
    if (!isLoading && chartTimeSettings.start && chartTimeSettings.end) {
      // General config
      const id = 'flightDetails--TrafficComposition-chart';
      const highchartsIdBase = 'TrafficComposition';
      const chartType = ChartType.AREA;
      const palette = [];
      const defaultChartPalette = [];
      let rawXAxisData = trafficCompositionData?.map((i) => stringAsMs(i.timestamp, DATE_TIME_FORMAT_WITH_HR_MINS));
      // X-axis
      let trafficCompositionDataInPercent = [];
      trafficCompositionData?.forEach((row) => {
        const totalUsage =
          row.streamingGaming +
          row.browsingSocialNetworks +
          row.productivityCloudServices +
          row.backgroundAppUpdates +
          row.otherSpeedTest +
          row.vpn;
        trafficCompositionDataInPercent.push({
          streamingGaming: totalUsage > 0 ? (row.streamingGaming / totalUsage) * 100 : 0,
          browsingSocialNetworks: totalUsage > 0 ? (row.browsingSocialNetworks / totalUsage) * 100 : 0,
          productivityCloudServices: totalUsage > 0 ? (row.productivityCloudServices / totalUsage) * 100 : 0,
          backgroundAppUpdates: totalUsage > 0 ? (row.backgroundAppUpdates / totalUsage) * 100 : 0,
          otherSpeedTest: totalUsage > 0 ? (row.otherSpeedTest / totalUsage) * 100 : 0,
          vpn: totalUsage > 0 ? (row.vpn / totalUsage) * 100 : 0
        });
      });

      let {xAxisData, yAxisData} = adjustCategorizedChartData(
        rawXAxisData,
        trafficCompositionDataInPercent,
        chartTimeSettings,
        5,
        chartTimeSettings.tickIntervalMinutes / 2
      );

      // Set the x-axis tick mark positions based on the adjusted x-axis data
      const xAxisTickPositions = getXAxisTickPositions(xAxisData, chartTimeSettings);

      const xAxisTickInterval = chartTimeSettings.tickIntervalMinutes * MS_IN_MIN;
      const xAxisLabelXOffset = 0;
      const xAxisFormatter = (value: any) => {
        // Note: Minor ticks are not supported for category-based axes
        if (Number(value) < 1e12 || !areMsTimestampMinutesEqualTo(value, chartTimeSettings.labeledTickMinutesValues)) {
          return '';
        }

        const hourLabel = msAsString(value, TIME_CONCISE_NO_PAD_FORMAT);
        const dayLabel = msAsString(value, DATE_CONCISE_FORMAT);
        return hourLabel === '0:00' ? dayLabel : hourLabel;
      };

      const yAxisFormatter = (label: any) => formatTrafficCompositionValue(label.value, false, true);

      const tooltip = (i18n: any) => (input: any) => {
        const {x, points} = input;

        return {
          label: {
            value: <ToolTipDateTimeLabel dateTime={x} />
          },
          points: points.map((pt: any) => {
            let {
              point,
              color,
              series: {name: seriesName}
            } = pt;

            const dataInMB = (point.y / 100) * (dataUsage[pt.x]?.fl_usage + dataUsage[pt.x]?.rl_usage);
            return {
              value: (
                <TrafficCompositionToolTip
                  prefixIconColor={color}
                  trafficType={seriesName}
                  trafficUsage={point.y}
                  dataUsage={Math.round(dataInMB)}
                />
              )
            };
          }),
          color: false
        };
      };
      const series = [
        {
          name: 'Streaming & Gaming',
          color: TRAFFIC_COMPOSITION_CHART_PALETTE[0],
          marker: {
            symbol: 'circle'
          },
          data: yAxisData.map((data) => (data ? data.streamingGaming : null)),
          fillOpacity: 0.2
        },
        {
          name: 'Browsing & Social Networks',
          color: TRAFFIC_COMPOSITION_CHART_PALETTE[1],
          marker: {
            symbol: 'circle'
          },
          data: yAxisData.map((data) => (data ? data.browsingSocialNetworks : null)),
          fillOpacity: 0.2
        },
        {
          name: 'Productivity & Cloud Services',
          color: TRAFFIC_COMPOSITION_CHART_PALETTE[2],
          marker: {
            symbol: 'circle'
          },
          data: yAxisData.map((data) => (data ? data.productivityCloudServices : null)),
          fillOpacity: 0.2
        },

        {
          name: 'Background & App Updates',
          color: TRAFFIC_COMPOSITION_CHART_PALETTE[3],
          marker: {
            symbol: 'circle'
          },
          data: yAxisData.map((data) => (data ? data.backgroundAppUpdates : null)),
          fillOpacity: 0.2
        },
        {
          name: 'Other & Speed Test',
          color: TRAFFIC_COMPOSITION_CHART_PALETTE[4],
          marker: {
            symbol: 'circle'
          },
          data: yAxisData.map((data) => (data ? data.otherSpeedTest : null)),
          fillOpacity: 0.2
        },
        {
          name: 'VPN',
          color: TRAFFIC_COMPOSITION_CHART_PALETTE[5],
          marker: {
            symbol: 'circle'
          },
          data: yAxisData.map((data) => (data ? data.vpn : null)),
          fillOpacity: 0.2
        }
      ];
      // Legend
      const legend = true;
      const legendItemStyle = {
        fontSize: '12px',
        fontWeight: '600',
        fontFamily: 'Source Sans Pro',
        color: LEGEND_TEXT,
        verticalAlign: 'bottom'
      };

      const xAxisLabel = '';
      const yAxisLabel = '% of Data Consumed';

      // Tooltips
      const htmlTooltip = true;
      const sharedTooltip = true;

      // Handle noData Options
      const noDataOptions = {
        enabled: true,
        showEmptyXAxis: false,
        showEmptyYAxis: false
      };
      const yAxisFormat = 'percentage';

      // Padding object specific to chart
      const paddings = {
        pointPadding: 0.09,
        groupPadding: 0.01
      };

      const marginLeft = 75;
      const marginRight = 25;

      const legendTooltips = [
        {
          title: 'flight_details.traffic_composition.streaming_gaming.legend_tooltip',
          x: -270,
          y: -195,
          arrowX: -300
        },

        {
          title: 'flight_details.traffic_composition.browsing_social_networks.legend_tooltip',
          x: -200,
          y: -195,
          arrowX: -210
        },
        {
          title: 'flight_details.traffic_composition.productivity_cloud_services.legend_tooltip',
          x: -30,
          y: -195,
          arrowX: -30
        },
        {
          title: 'flight_details.traffic_composition.background_app_updates.legend_tooltip',
          x: 170,
          y: -195,
          arrowX: 170
        },

        {
          title: 'flight_details.traffic_composition.other.legend_tooltip',
          x: 285,
          y: -195,
          arrowX: 245
        },
        {
          title: 'flight_details.traffic_composition.vpn.legend_tooltip',
          x: 285,
          y: -195,
          arrowX: 345
        }
      ];
      setChartConfig({
        id,
        palette,
        yAxisFormat,
        xAxisLabel,
        yAxisLabel,
        series,
        tooltip,
        legend,
        legendItemStyle,
        xAxisData,
        xAxisLabelXOffset,
        xAxisTickPositions,
        xAxisTickInterval,
        xAxisFormatter,
        yAxisFormatter,
        htmlTooltip,
        sharedTooltip,
        chartType,
        highchartsIdBase,
        noDataOptions,
        liveMask,
        legendTooltips,
        paddings,
        marginLeft,
        marginRight,
        height: 220,
        connectNulls: false,
        disableMarker: false,
        yAxis: [
          {
            label: '% of Data Consumed',
            labelXOffset: -5,
            plotline: undefined,
            plotlineColor: undefined,
            tickInterval: undefined,
            tickPositions: undefined,
            titleOffset: 50,
            opposite: false
          }
        ],
        defaultChartPalette
      });
    }
    // eslint-disable-next-line
  }, [trafficCompositionData, chartTimeSettings]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer>
      {Object.keys(formattedConfig).length === 0 ? <></> : <PercentAreaChart {...formattedConfig} />}
    </ChartContainer>
  );
};

export default TrafficComposition;
