/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Usage card utils
 */

import {isNil, isNaN} from 'lodash';
import {MONTHLY_USAGE_BAR_COLORS} from '../../common/theme/Colors';
import DataUsageToolTip from '../tooltip/summaryMetricsChartTooltip';

/**
 * To calculate percentage
 * @param val
 * @param totalVal
 * @returns percentage value
 */
const calculatePercentage = (val: number, totalVal: number) => {
  if (isNil(val) || isNil(totalVal) || isNaN(val) || isNaN(totalVal) || !val || !totalVal) return 0;
  return ((val / totalVal) * 100).toFixed(0).toLocaleString();
};

/**
 * To prepare progresbar configuration
 * @param usagePercentage
 * @param totalDataAllowed
 * @returns progressbar configuration
 */
export const prepareProgressBarConfig = (usagePercentage: number, totalDataAllowed: number) => {
  return {
    barColor:
      usagePercentage <= 50
        ? MONTHLY_USAGE_BAR_COLORS[0]
        : usagePercentage <= 75
        ? MONTHLY_USAGE_BAR_COLORS[1]
        : MONTHLY_USAGE_BAR_COLORS[2],
    currentProgress: usagePercentage,
    start: '0 GB',
    end: totalDataAllowed + ' GB'
  };
};

/**
 * Convert Data into GB
 * @param value
 * @returns converted GB value
 */
export const convertUsageData = (value: number, separator: boolean = false, valueToGB: boolean = true) => {
  let formattedUsageValue;
  if (value === 0) {
    formattedUsageValue = 0;
  } else {
    formattedUsageValue = valueToGB ? Number(value / 1000).toFixed(1) : Number(value).toFixed(1);
  }
  return separator ? Number(formattedUsageValue).toLocaleString() : formattedUsageValue;
};
/**
 * Get Chart data
 * @param inFlightUsage
 * @param onGroundUsage
 * @returns return formatted chart data object
 */
export const getChartData = (inFlightUsage: number, onGroundUsage: number) => {
  let chartData = {
    totalDataUsage: 0.0,
    inFlightUsageData: 0.0,
    inFlightUsagePercentage: 0,
    onGroundUsageData: 0.0,
    onGroundUsagePercentage: 0
  };
  if (inFlightUsage || onGroundUsage) {
    chartData = {
      totalDataUsage: Number(convertUsageData(inFlightUsage + onGroundUsage)),
      inFlightUsageData: Number(convertUsageData(inFlightUsage)),
      inFlightUsagePercentage: Number(calculatePercentage(inFlightUsage, inFlightUsage + onGroundUsage)),
      onGroundUsageData: Number(convertUsageData(onGroundUsage)),
      onGroundUsagePercentage: Number(calculatePercentage(onGroundUsage, inFlightUsage + onGroundUsage))
    };
  }
  return chartData;
};

/**
 * Get chart tooltip
 * @param i18n
 * @returns return tooltip component
 */
export const tooltip = (i18n: any) => (input: any) => {
  const {x, y, percentage, color, key, point} = input;
  return {
    label: {
      value: x
    },
    points: [
      {
        value: (
          <DataUsageToolTip
            prefixIconColor={color}
            chartLabel={key}
            dataPercentage={percentage.toFixed(0)}
            dataUsage={y}
            card={point.id}
          />
        )
      }
    ],
    color: false
  };
};

/**
 * Get Chart data
 * @param tcCumulativeData
 * @returns return formatted chart data object
 */
export const getTrafficCompositionChartData = (totalTrafficCompValue: number, tcCumulativeData: any) => {
  const tcChartData = {};
  for (const tcDataKey in tcCumulativeData) {
    tcChartData[tcDataKey] = Number(calculatePercentage(tcCumulativeData[tcDataKey], totalTrafficCompValue));
  }
  return tcChartData;
};
