/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Ping Latency ToolTip Style and Formatting
 */

import React from 'react';
import {ColorProperty} from 'csstype';

import SeriesPrefixIcon from '../../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesLabel from '../../../common/elements/chart/ToolTip/SeriesLabel';
import SeriesValue from '../../../common/elements/chart/ToolTip/SeriesValue';

import {formatAvgPingLatencyValue} from '../../flightDetailsUtil';

export interface PingLatencyToolTipProps {
  prefixIconColor?: ColorProperty;
  avgPingLatency: number;
}

const PingLatencyToolTip: React.FC<PingLatencyToolTipProps> = ({
  prefixIconColor,
  avgPingLatency
}: PingLatencyToolTipProps) => {
  return (
    <>
      <SeriesPrefixIcon color={prefixIconColor} />
      <SeriesLabel label="Ping Latency" width="70px" />
      <SeriesValue value={formatAvgPingLatencyValue(avgPingLatency)} width="54px" />
    </>
  );
};

export default PingLatencyToolTip;
