/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Basic Fetch Hook
 */
import {useEffect, useReducer, createContext, useContext} from 'react';
import {isEqual} from 'lodash';

import {bizavApiUrl} from './config';
import {logout} from '../utils/app-utils';
import {Query} from '../store/queries/types';


interface Action {
  type: string;
  payload: any;
}

export interface StateInterface<Type> {
  isLoading: boolean;
  hasError: boolean;
  data: Type | null;
}

export type QueryParams = Record<string, any> | undefined;

interface CacheDataInterface {
  queryParams: QueryParams;
  data: Record<string, any>;
}

const cacheContext = createContext<{[key: string]: any}>({});

enum FetchReducerAction {
  Loading = 'loading',
  LoadingWithCache = 'loading_with_cache',
  Success = 'success',
  Error = 'error'
}

/**
 * Action reducer for the fetch hook
 * @param state Current state
 * @param action Action that was dispatched
 * @returns New state
 */
const createReducer =
  <Type>() =>
  (state: StateInterface<Type>, action: Action): StateInterface<Type> => {
    switch (action.type) {
      case FetchReducerAction.Loading:
        if (!state.isLoading) {
          state = {...state, isLoading: true, data: null, hasError: false};
        }
        break;
      case FetchReducerAction.LoadingWithCache:
        if (!state.isLoading) {
          state = {...state, isLoading: true, data: action.payload, hasError: false};
        }
        break;
      case FetchReducerAction.Success:
        state = {...state, isLoading: false, hasError: false, data: action.payload};
        break;
      case FetchReducerAction.Error:
        state = {...state, isLoading: false, hasError: true, data: null};
        break;
    }

    return state;
  };

/**
 * Creates a Fetch Hook
 * @param query Query path
 * @param queryParams Query parameters
 * @param canUseCacheWhileLoading Callback that determines whether the current cached data should be returned while
 *                                loading. If undefined, null data will be returned while loading.
 * @returns Basic hook data
 */
const useFetch = <Type>(
  query: Query<Type>,
  queryParams: QueryParams,
  canUseCacheWhileLoading: (cacheQueryParams: QueryParams) => boolean = undefined
): StateInterface<Type> => {
  const cache = useContext<{[key: string]: CacheDataInterface}>(cacheContext);
  const [state, dispatch] = useReducer(createReducer<Type>(), {
    isLoading: true,
    hasError: false,
    data: null
  });

  useEffect(() => {
    let abortController: AbortController | null = null;

    if (!query || !queryParams) return;

    const cacheData = cache[query.route];
    if (cacheData && isEqual(cacheData.queryParams, queryParams)) {
      dispatch({type: FetchReducerAction.Success, payload: cacheData.data});
      return;
    }

    const abort = () => {
      if (abortController && abortController.abort) {
        const abortTemp = abortController;
        // Set this to null so that we know it was an abort
        abortController = null;
        abortTemp.abort();
      }
    };

    (async () => {
      abortController = new AbortController();

      try {
        if (canUseCacheWhileLoading && cacheData && canUseCacheWhileLoading(cacheData.queryParams)) {
          dispatch({
            type: FetchReducerAction.LoadingWithCache,
            payload: cacheData.data
          });
        } else {
          dispatch({
            type: FetchReducerAction.Loading,
            payload: null
          });
        }

        // Uncomment below lines to report the sending of a query to the console log
        // console.info(
        //   `useFetch(${query.route}): Initiating fetch for query with queryParams=${JSON.stringify(queryParams)}`
        // );

        const response = await fetch(`${bizavApiUrl}/${query.route}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.token}`
          },
          body: queryParams ? JSON.stringify(queryParams) : undefined,
          signal: abortController.signal
        });

        if (response.status === 401) {
          console.error(`Logging out: ${response.status}`);
          logout();
          return;
        } else if (response.status >= 300) {
          console.error(`Received response status: ${response.status}`);
          dispatch({type: FetchReducerAction.Error, payload: null});
          return;
        }

        const responseData = await response.json();
        const transformedData = query.transform ? query.transform(responseData) : responseData;
        cache[query.route] = {
          queryParams,
          data: transformedData
        };
        dispatch({type: FetchReducerAction.Success, payload: transformedData});
      } catch (error) {
        // This exception is thrown when we abort, so we can ignore that case
        if (abortController != null) {
          console.error(`Exception ('${error.toString()}') while handling route: '${query.route}'`);
          dispatch({type: FetchReducerAction.Error, payload: null});
        } else {
          // Uncomment below lines to report the cancelling of a query to the console log
          // console.info(
          //   `useFetch(${query.route}): Cancelled fetch for query with queryParams=${JSON.stringify(queryParams)}`
          // );
        }
      } finally {
        abortController = null;
      }
    })();

    return () => {
      abort();
    };
  }, [cache, query, queryParams, canUseCacheWhileLoading]);

  return state;
};

export default useFetch;
