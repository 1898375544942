/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common browser utilities
 */
const ROUTE_LOOKUP_TABLE = {
  '/fleet-map': 'Fleet Map',
  '/lists/flight': 'Flight List',
  '/lists/aircraft-status': 'Aircraft Status',
  '/flight/details/': 'Flight Details',
  '/connectivity-outlook-planner': 'Connectivity Outlook Planner'
};

export const BROWSER_LIST = ['Safari', 'Firefox'];

/**
 * Returns data as string from a blob object
 * @param blobData - blob data to convert
 * @returns utf decoded csv text
 */
export function getRawDataArrayFromBlob(blobData: any) {
  const enc = new TextDecoder('utf-8');
  return enc.decode(new Uint8Array(blobData));
}

/**
 * Converts text into a Binary Large Object
 * @param data - desired data to download
 * @returns csv Blob
 */
export function getCsvBlobDataToDownload(data: any) {
  return new Blob([data], {
    type: 'text/csv'
  });
}

/**
 * Downloads data client side of the browser
 * @param data - desired data to download
 * @param filename - name of saved file
 */
export const csvDownload = async (data: any, filename: any) => {
  const csvData = getCsvBlobDataToDownload(data);
  const url = window.URL.createObjectURL(csvData);
  const elem = document.createElement('a');
  elem.setAttribute('hidden', '');
  elem.setAttribute('href', url);
  elem.setAttribute('download', filename);
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

/**
 * Helper function to use the hardcoded route lookup table
 */
export const lookupTitle = (pathName: string) =>
  Object.keys(ROUTE_LOOKUP_TABLE).reduce(
    (candidate: string, key: string) =>
      pathName.includes(key) && candidate.length === 0 ? ROUTE_LOOKUP_TABLE[key] : candidate,
    ''
  );

/**
 * Sets title based on the pathname
 */
export const setTitle = () => {
  const defaultTitle = 'Viasat Insights';
  const titleSuffix = lookupTitle(document.location.pathname);
  const separator = titleSuffix.length > 0 ? ' - ' : '';
  document.title = `${defaultTitle}${separator}${titleSuffix}`;
};

/**
 * Checks type of browser
 */
export const getBrowserName = () => {
  var userAgent = navigator.userAgent;

  switch (true) {
    case userAgent.indexOf('Edge') > -1:
      return 'Edge';
    case userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1:
      return 'Opera';
    case userAgent.indexOf('Chrome') > -1:
      return 'Chrome';
    case userAgent.indexOf('Safari') > -1:
      return 'Safari';
    case userAgent.indexOf('Firefox') > -1:
      return 'Firefox';
    default:
      return 'unknown';
  }
};
