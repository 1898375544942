/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Map state container
 */

import React, {useCallback, useRef} from 'react';
import {MapView} from '@viasat/insights-components';
import {MAP_CENTER} from '../../utils/constants';
import {
  ESatBeamStyle,
  getNetworkOverlayStyle,
  getSatBeamOverlayStyle,
  handleBeamOverlayClick,
  handleBeamOverlayHover,
  ISelectedSatBeam
} from '../mapView/settings/MapHelper';
import {useStore} from '../../store/Store';
import {FleetMapAction} from '../../store/reducers/FleetMapReducer';
import {googleMapsId} from '../../utils/config';

export interface MapStateContainerProp {
  getFullElementId: (name: string, type: string) => string;
  isLoading: boolean;
  loadingTitle?: string;
  initialBoundList?: google.maps.LatLngLiteral[];
  zoom?: number;
  setZoom?: (zoom: number) => void;
  center?: google.maps.LatLngLiteral;
  isFullScreen?: boolean;
  setIsFullScreen?: (isFullScreen: boolean) => void;
  mapDivRef?: any;
  mapContainerStyle?: React.CSSProperties;
  mapOptions?: google.maps.MapOptions;
  children?: any;
}

const MapStateContainer: React.FC<MapStateContainerProp> = ({
  getFullElementId,
  zoom,
  setZoom = () => {},
  mapDivRef,
  isLoading,
  loadingTitle = 'Loading Map...',
  isFullScreen,
  setIsFullScreen,
  initialBoundList,
  mapContainerStyle,
  mapOptions,
  center,
  children
}) => {
  const {store, dispatch} = useStore();

  const selectedSatBeamRef = useRef<ISelectedSatBeam | undefined>(undefined);

  const boundListValid =
    initialBoundList && initialBoundList.length > 0 && initialBoundList.every((element) => Boolean(element));

  selectedSatBeamRef.current = store.fleetMap.view.satBeam;

  /**
   * Sets the selected satellite beam id in the component store and session storage
   * @param satBeamId satId-beamId as string
   */
  const storeSelectedSatBeam = useCallback(
    (satBeamId: ISelectedSatBeam) => {
      dispatch({
        type: FleetMapAction.SET_SATELLITE_BEAMS,
        payload: satBeamId
      });
    },
    [dispatch]
  );

  /**
   * Callback that sets the selected satellite beam id in the state and session storage
   * @param satBeamId satId-beamId as string
   */
  const setSelectedSatBeamCallback = useCallback(
    (satBeamId: ISelectedSatBeam) => {
      storeSelectedSatBeam(satBeamId);
    },
    [storeSelectedSatBeam]
  );

  const mapOnLoad = (map: google.maps.Map) => {
    (() => {
      if (boundListValid) {
        const bounds = new google.maps.LatLngBounds();
        initialBoundList.forEach(({lat, lng}) => bounds.extend(new google.maps.LatLng(lat, lng)));
        map.fitBounds(bounds, 50); // set bounds with 50 pixel padding
      }
    })();

    google.maps.event.addListener(map, 'center_changed', () => {
      const center = map.getCenter();
      const MAX_LAT_PAN = 82,
        MIN_LAT_PAN = -68;

      const latitude = center.lat();
      const longitude = center.lng();

      // Google maps doesn't block pan into nowhere, so we do.
      if (latitude > MAX_LAT_PAN) {
        map.panTo({lat: MAX_LAT_PAN, lng: longitude});
        return;
      } else if (latitude < MIN_LAT_PAN) {
        map.panTo({lat: MIN_LAT_PAN, lng: longitude});
        return;
      }

      sessionStorage.curMapCenter = JSON.stringify({
        lat: latitude,
        lng: longitude
      });
    });

    google.maps.event.addListener(map, 'zoom_changed', () => {
      const curZoom = map.getZoom();
      sessionStorage.curMapZoom = curZoom;
      setZoom(curZoom);
    });

    map.data.setStyle((feature: google.maps.Data.Feature): google.maps.Data.StyleOptions => {
      const network = feature.getProperty('network');
      return network === ESatBeamStyle.Internal ? getSatBeamOverlayStyle(network) : getNetworkOverlayStyle(network);
    });

    map.data.addListener('mouseover', (event) => {
      handleBeamOverlayHover(map, selectedSatBeamRef, event.feature, true);
    });

    map.data.addListener('mouseout', (event) => {
      handleBeamOverlayHover(map, selectedSatBeamRef, event.feature, false);
    });

    map.data.addListener('click', (event) => {
      handleBeamOverlayClick(map, selectedSatBeamRef, event.feature, setSelectedSatBeamCallback);
    });
  };

  return (
    <MapView
      getFullElementId={getFullElementId}
      onLoad={mapOnLoad}
      zoom={zoom}
      center={center ? center : sessionStorage.curMapCenter ? JSON.parse(sessionStorage.curMapCenter) : MAP_CENTER}
      googleMapApiKey={googleMapsId}
      isLoading={isLoading}
      mapDivRef={mapDivRef}
      mapOptions={mapOptions}
      mapContainerStyle={mapContainerStyle}
      isFullScreen={isFullScreen}
      setIsFullScreen={setIsFullScreen}
      loadingTitle={loadingTitle}
    >
      {children}
    </MapView>
  );
};
export default MapStateContainer;
