/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight List Query
 */

import {Query} from '../types';
import {
  DATE_TIME_VERBOSE_LOCAL_FORMAT,
  msAsString,
  formatValue,
  formatHMSecondsValue
} from '../../../utils/DateTimeUtils';
import {
  BYTES_PER_MEGABYTE,
  FORMAT_VALUE_DEFAULT_PRECISION,
  FORMAT_VALUE_DEFAULT_UNITS,
  FORMAT_VALUE_USE_SEPARATOR
} from '../../../utils/constants';

export interface FlightInfo {
  customer: string;
  serialNumber: string;
  tailId: string;
  ipAddress: string;
  macAddress: string;
  aircraftManufacturer: string;
  aircraftType: string;
  networkCapability: string;
  flightStart: string;
  flightEnd: string;
  flightDuration: string;
  connectedStart: string;
  connectedEnd: string;
  connectedDuration: string;
  dataUsage: string;
  flightId: string;
  flightDetected: boolean;
  origin?: string;
  destination?: string;
  isDark?: boolean;
  faFlightId?: string;
  servicePlanName: string;
  siteCreatedTstamp: string;
  status: string;
}

interface FlightInfoRaw {
  customer: string;
  serialNumber: string;
  tailId: string;
  ipAddress: string;
  macAddress: string;
  aircraftManufacturer: string;
  aircraftType: string;
  networkCapability: string;
  flightStartTimestamp: number;
  flightEndTimestamp: number;
  flightDurationSeconds: number;
  connectedStartTimestamp: number;
  connectedEndTimestamp: number;
  connectedDurationSeconds: number;
  dataUsageBytes: number;
  flightId: string;
  flightDetected: boolean;
  origin?: string;
  destination?: string;
  isDark?: boolean;
  faFlightId?: string;
  servicePlanName: string;
  siteCreatedTstamp: string;
  status: string;
}

/**
 * Transforms the given raw flight info data into (regular) flight info data
 * @param flightInfoRawList flightInfoRaw values from the api
 * @returns FlightInfo Transformed flight info
 */
const transformRawFlightInfo = (flightInfoRawList: FlightInfoRaw[] | null): FlightInfo[] | null => {
  if (!flightInfoRawList) return null;

  const flightInfo: FlightInfo[] = flightInfoRawList.map((flightInfoRaw) => ({
    customer: flightInfoRaw.customer,
    serialNumber: flightInfoRaw.serialNumber,
    tailId: flightInfoRaw.tailId,
    ipAddress: flightInfoRaw.ipAddress,
    macAddress: flightInfoRaw.macAddress,
    aircraftManufacturer: flightInfoRaw.aircraftManufacturer,
    aircraftType: flightInfoRaw.aircraftType,
    networkCapability: flightInfoRaw.networkCapability,
    flightStart: flightInfoRaw.flightStartTimestamp
      ? msAsString(flightInfoRaw.flightStartTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
      : null,
    flightEnd: flightInfoRaw.flightEndTimestamp
      ? msAsString(flightInfoRaw.flightEndTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
      : null,
    flightDuration: formatHMSecondsValue(flightInfoRaw.flightDurationSeconds),
    connectedStart: flightInfoRaw.connectedStartTimestamp
      ? msAsString(flightInfoRaw.connectedStartTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
      : null,
    connectedEnd: flightInfoRaw.connectedEndTimestamp
      ? msAsString(flightInfoRaw.connectedEndTimestamp, DATE_TIME_VERBOSE_LOCAL_FORMAT)
      : null,
    connectedDuration: formatHMSecondsValue(flightInfoRaw.connectedDurationSeconds),
    dataUsage: flightInfoRaw.dataUsageBytes
      ? flightInfoRaw.dataUsageBytes / BYTES_PER_MEGABYTE < 1 // Show '< 1' if usage is less than 1 MB
        ? '< 1'
        : formatValue(
            flightInfoRaw.dataUsageBytes / BYTES_PER_MEGABYTE,
            FORMAT_VALUE_DEFAULT_PRECISION,
            FORMAT_VALUE_DEFAULT_UNITS,
            FORMAT_VALUE_USE_SEPARATOR
          )
      : null,
    flightId: flightInfoRaw.flightId,
    flightDetected: flightInfoRaw.flightDetected,
    origin: flightInfoRaw.origin ? flightInfoRaw.origin : null,
    destination: flightInfoRaw.destination ? flightInfoRaw.destination : null,
    isDark: flightInfoRaw.isDark ? flightInfoRaw.isDark : false,
    faFlightId: flightInfoRaw.faFlightId,
    servicePlanName: flightInfoRaw.servicePlanName,
    siteCreatedTstamp: flightInfoRaw.siteCreatedTstamp,
    status: flightInfoRaw.status
  }));

  return flightInfo;
};

const flightListQuery: Query<FlightInfo[]> = {
  route: 'flightList/flights',
  transform: transformRawFlightInfo
};

export default flightListQuery;
