/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connectivity Outlook Flight Plan List page
 */

import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {forEach} from 'lodash';
import DELETE_ICON from '@mui/icons-material/Delete';
import ToastIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import request from 'superagent';
import {
  RightDataToolTip,
  DatePicker,
  DatePickerState,
  HiddenColumnSelector,
  ListGridColumn
} from '@viasat/insights-components';
import {useStore} from '../../store/Store';
import {ConnectivityPlannerAction, IAircraftList} from '../../store/reducers/ConnectivityPlannerReducer';
import {
  buildFlightPlanListColumns,
  getFlightPlanListFilters,
  disableDeleteFlightPlans,
  flightPlanListDefaultStartDate,
  flightPlanListDefaultEndDate,
  getQueryFieldMapping,
  FlightPlanListColumnId
} from './ConnectivityOutlookUtils';
import ListViewComponent from '../lists/ListViewComponent';
import {getFilterOptions} from '../../utils/filterUtils';
import {getActionTypeWithContext} from '../../store/reducerUtils';
import {FilterAction} from '../../store/reducers/FiltersReducer';
import {CONNECTIVITY_PLANNER_STORE_CONTEXT} from '../../store/reducers/ConnectivityPlannerReducer';
import {ColumnSortAction} from '../../store/reducers/ColumnSortReducer';
import FlightPlanUploader from './FlightPlanUploader';
import {getElementIdFromSectionBase, getElementIdSectionBase} from '../../utils/ElementIdUtils';
import * as StyledElms from './ConnectivityOutlookStyles';
import FilterHookContainer from '../common/elements/filterSelector/FilterHookContainer';
import ListViewSortContainer from '../lists/ListViewSortContainer';
import {
  useSortingParams,
  useFilteringParams,
  useQueryInputsDateRange,
  useFlightsPlansCount
} from '../../store/queries/queryUtils';
import flightPlanQuery, {FlightPlanInfo} from '../../store/queries/connectivityPlanner/flightPlanQuery';
import ShareButton from '../common/elements/share/ShareButton';
import {translateFiltersArrayToObject} from '../../utils/filterUtils';
import {translateHiddenColumnsArrayToObject} from '../lists/hiddenColumnsUtils';
import useFetch from '../../utils/useFetch';
import TelescopeIcon from '../common/theme/icons/TelescopeIcon';
import {ALERT_DIALOG_LEFT_BORDER, DIALOG_ALERT_ERROR_COLOR, WHITE} from '../common/theme/Colors';
import airportsQuery from '../../store/queries/common/airportsQuery';
import {Airport, AirportsType} from '../../utils/MapUtil';
import ConfirmationDialog from '../common/elements/dialog/ConfirmationDialog';
import {bizavApiUrl, removeDatePickerGuardrails} from '../../utils/config';
import endUsersQuery, {EndUser} from '../../store/queries/common/endUsersQuery';
import BoldSpan from '../common/BoldSpan';
import moment from 'moment';
import aircraftListQuery from '../../store/queries/connectivityPlanner/aircraftListQuery';
import DialogAlertPopup from '../common/DialogAlertPopup';
import {HideColumnsAction} from '../../store/reducers/HideColumnsReducer';
import {AppAction} from '../../store/reducers/AppReducer';
import {IHandleSortProps} from '../lists/listUtils';

export interface flightPlanListprops {
  idPrefix: string;
}

interface IDisableViewOutlook {
  alertTitle: string;
  alertMessage: string;
}

interface FlightPlanListRow extends FlightPlanInfo {
  isSelected?: boolean;
  isDisabled?: boolean;
  departureAirportData?: {
    airportData?: Airport;
    code?: string;
  };
  destinationAirportData: {
    airportData?: Airport;
    code?: string;
  };
}

const MAX_FLIGHT_PLANS_DISPLAYED = 3000;

const maxRowNotificationMessage = (
  <span>
    We've displayed the <strong>first {MAX_FLIGHT_PLANS_DISPLAYED} flight plans</strong> on the list. You can refine
    your search with additional <strong>filters.</strong>
  </span>
);

const EMPTY_PLACE_HOLDER_STATEMENT = 'No Flight Plans found';
const EMPTY_PLACE_HOLDER_DESCRIPTION = 'Upload new Flight Plans';
const DATE_PICKER_PRESETS_LAST_NEXT = [
  {
    id: 'dateRangeLastNext7Days',
    className: 'last_next_2_weeks',
    dateRange: [-7, 7],
    label: 'Last & Next 7 Days'
  },
  {
    id: 'dateRangeLastNext14Days',
    className: 'last_next_4_weeks',
    dateRange: [-14, 14],
    label: 'Last & Next 14 Days'
  },
  {
    id: 'dateRangeLastNext28Days',
    className: 'last_next_8_weeks',
    dateRange: [-28, 28],
    label: 'Last & Next 28 Days'
  }
];

const FlightPlanListPage: React.FC<any> = (props: flightPlanListprops) => {
  const {idPrefix} = props;
  const {store, dispatch} = useStore();
  const datePickerState = store.connectivityPlanner.dateRange;
  const {
    sort,
    filters: {filters = []},
    hideColumns: {hiddenColumns, sharelinkHiddenColumns},
    selectedFlightPlans,
    showFlightPlanListNotification,
    newFlightPlansCount,
    deletedFlightPlansCount,
    isAlertEnabled,
    flightPlanOverlap,
    refreshFlightPlans
  } = store.connectivityPlanner;
  const {airports} = store.app;
  const intl = useIntl();
  const navigate = useNavigate();
  const groupCode = store.customer.current.code;
  const flightPlanDisabledTooltipText =
    intl.formatMessage({id: 'flight_plan_list.row_disabled_tooltip'}) +
    (selectedFlightPlans && selectedFlightPlans.length ? selectedFlightPlans[0].tailId : '');
  const {isInternal} = store.init;

  const [showDialog, setShowDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState<string>('');

  // if sharelink hidden columns are populated in session storage, then use, otherwise use hidden columns from local storage
  const currentHiddenColumns = sharelinkHiddenColumns ? sharelinkHiddenColumns : hiddenColumns;

  const queryParams = useQueryInputsDateRange(
    store.customer.current.code,
    datePickerState.startDate,
    datePickerState.endDate
  );

  // End Users
  const {data: endUsersData} = useFetch<EndUser[]>(endUsersQuery, queryParams);
  const isEndUser = endUsersData && endUsersData.length === 0;
  const disableDelete = disableDeleteFlightPlans(isInternal, isEndUser, groupCode);
  const flightPlanListFilters = useMemo(() => getFlightPlanListFilters(), []);

  const queryParamsWithFiltering = useFilteringParams(queryParams, flightPlanListFilters, filters);

  const queryParamsWithSorting = useSortingParams(queryParamsWithFiltering, sort.queryField, sort.order);
  const queryParamsWithFlightPlansCount = useFlightsPlansCount(
    queryParamsWithSorting,
    newFlightPlansCount,
    deletedFlightPlansCount,
    refreshFlightPlans
  );

  const emptyParams = useMemo(() => ({}), []);

  // Flight Plan List
  const {data: flightPlanData, isLoading: isFlightPlanLoading} = useFetch<FlightPlanInfo[]>(
    flightPlanQuery,
    queryParamsWithFlightPlansCount
  );

  const {data: aircraftList} = useFetch<IAircraftList[]>(aircraftListQuery, queryParams);

  useEffect(() => {
    if (aircraftList) {
      aircraftList.forEach((aircraftListItem) => {
        aircraftListItem.isSelected = false;
      });
      dispatch({
        type: ConnectivityPlannerAction.SET_AIRCRAFT_LIST,
        payload: aircraftList
      });
    }
  }, [dispatch, aircraftList]);

  let filteredAircraftList;
  if (searchCriteria.length > 0) {
    filteredAircraftList = aircraftList.filter((aircraftListItem) => {
      return (
        aircraftListItem.tailId.toLowerCase().includes(searchCriteria.toLowerCase()) ||
        aircraftListItem.serialNumber.toLowerCase().includes(searchCriteria.toLowerCase()) ||
        aircraftListItem.aircraftType.toLowerCase().includes(searchCriteria.toLowerCase())
      );
    });
  }

  // Airports
  let {data: airportsList, isLoading: isAirportsLoading} = useFetch<AirportsType>(airportsQuery, emptyParams);

  const flightPlanTotal = useMemo(
    () => (isFlightPlanLoading ? 0 : flightPlanData?.length),
    [flightPlanData, isFlightPlanLoading]
  );

  const handleFlightPlanSelection = (selectedFlightPlanData: any) => {
    dispatch({
      type: ConnectivityPlannerAction.SET_VIEW_OUTLOOK_OVERLAP_ALERTS,
      payload: false
    });
    dispatch({type: ConnectivityPlannerAction.SET_SELECTED_FLIGHT_PLANS, payload: selectedFlightPlanData});
  };
  const handleFlightPlanDeselection = (removedFlightPlanData: any) => {
    dispatch({
      type: ConnectivityPlannerAction.SET_VIEW_OUTLOOK_OVERLAP_ALERTS,
      payload: false
    });
    dispatch({type: ConnectivityPlannerAction.REMOVE_FLIGHT_PLAN_FROM_LIST, payload: removedFlightPlanData});
  };

  const flightPlanListColumns = buildFlightPlanListColumns(isEndUser, airports).map((col) => {
    // Add row selection/deselection handlers for checkbox
    if (col.key === 'id') {
      col.handleRowSelection = handleFlightPlanSelection;
      col.handleRowDeselection = handleFlightPlanDeselection;
    }

    // Add click handler for flight plan link
    if (col.key === 'flightPlanIdentifier') {
      col.handleClick = (row) => {
        dispatch({type: ConnectivityPlannerAction.SET_LINK_SELECTED_FLIGHT_PLAN, payload: row});
      };
    }

    return col;
  });
  
  /**
   * Handles the Sorting actions on the list view
   * @param param0 IHandleSortProps with column to sort and the respective sort order - asc/desc
   */
  const handleSortChange = ({column, order}: IHandleSortProps) => {
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, CONNECTIVITY_PLANNER_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(FlightPlanListColumnId[column]),
        validColumns: flightPlanListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: flightPlanListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(FlightPlanListColumnId[col.key])
        )
      }
    });
  };

  const showNotification = showFlightPlanListNotification && MAX_FLIGHT_PLANS_DISPLAYED < flightPlanTotal;
  const setShowFlightPlanListNotification = (show: boolean) => {
    dispatch({
      type: ConnectivityPlannerAction.SET_SHOW_FLIGHT_PLAN_LIST_NOTIFICATION,
      payload: show
    });
  };

  useEffect(() => {
    if (flightPlanData && flightPlanListFilters) {
      const {domainOptions, rangeOptions} = getFilterOptions({flightPlanData}, flightPlanListFilters);

      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, CONNECTIVITY_PLANNER_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [dispatch, flightPlanListFilters, flightPlanData]);

  useEffect(() => {
    if (airportsList) {
      dispatch({
        type: AppAction.SET_AIRPORTS,
        payload: {
          airports: airportsList
        }
      });
    }
  }, [dispatch, airportsList]);

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(FlightPlanListColumnId[columnId]);

  const handleFilterChange = () => {
    setShowFlightPlanListNotification(true); // allows notification to be shown again on filter change
  };
  const onDatePickerChange = (newState: DatePickerState) => {
    dispatch({
      type: ConnectivityPlannerAction.SET_DATE_RANGE,
      payload: {...newState, viewContext: CONNECTIVITY_PLANNER_STORE_CONTEXT}
    });
  };

  const selectedFlightPlanCount = selectedFlightPlans.length;
  useEffect(() => {
    if (refreshFlightPlans) {
      setTimeout(() => {
        dispatch({
          type: ConnectivityPlannerAction.REFRESH_FLIGHT_PLAN_LIST,
          payload: false
        });
      }, 3000); // The API call takes 1s or 1.5s or 2s sometimes and inconsistent with 500ms.
    }
  }, [refreshFlightPlans, dispatch]);

  const disableGenerateOutlook = (): IDisableViewOutlook => {
    let disable: IDisableViewOutlook = {alertTitle: null, alertMessage: null};
    //disable if users selectes more than six flight plans
    if (selectedFlightPlanCount > 3) {
      return (disable = {
        alertTitle: intl.formatMessage({id: 'dialog_alert_popup.limit_exceeded.title'}),
        alertMessage: intl.formatMessage({id: 'dialog_alert_popup.limit_exceeded.message'})
      });
    } else {
      for (let index = 0; index < selectedFlightPlanCount; index++) {
        // disable if destination airport and departure ariport of subsequent plan is not equal.
        if (
          index + 1 < selectedFlightPlanCount &&
          selectedFlightPlans[index].destinationAirport !== selectedFlightPlans[index + 1].departureAirport
        ) {
          return (disable = {
            alertTitle: intl.formatMessage({id: 'dialog_alert_popup.not_connected_plans.title'}),
            alertMessage: intl.formatMessage({id: 'dialog_alert_popup.not_connected_plans.message'})
          });
        }
        // disable if arrival time is after the  departure time of subsequent plan.
        else if (
          index + 1 < selectedFlightPlanCount &&
          moment(selectedFlightPlans[index].arrivalTime)
            .utc()
            .isAfter(moment(selectedFlightPlans[index + 1].departureTime).utc())
        ) {
          return (disable = {
            alertTitle: intl.formatMessage({id: 'dialog_alert_popup.overlap_timings.title'}),
            alertMessage: intl.formatMessage({id: 'dialog_alert_popup.overlap_timings.message'})
          });
        } else {
          return disable;
        }
      }
    }
  };

  const disableViewOutlook = disableGenerateOutlook();

  const deleteFlightPlan = () => {
    setDeleteErrorMessage(null);
    if (disableDelete) return;
    setShowDialog(true);
  };

  const proceedToDelete = async (confirm: boolean) => {
    if (confirm) {
      const flightPlansToDelete = [];
      forEach(selectedFlightPlans, (flightPlan) => {
        flightPlansToDelete.push({
          flightPlanId: flightPlan.id,
          tailId: flightPlan.tailId,
          serialNumber: flightPlan.serialNumber
        });
      });
      setIsDeleting(true);
      const deleteFlightPlans: request.Response = await request
        .delete(`${bizavApiUrl}/connectivityPlanner/deleteFlightPlan`)
        .send({groupCode, flightPlans: flightPlansToDelete})
        .set('Authorization', `Bearer ${localStorage.token}`)
        .catch((error) => {
          console.error(`Failed to delete flight plans: ${error.response.text}`);
          setIsDeleting(false);
          setDeleteErrorMessage(error?.response?.text);
          dispatch({
            type: ConnectivityPlannerAction.RESET_DELETED_FLIGHT_PLAN_COUNT,
            payload: 0
          });
        });

      if (deleteFlightPlans) {
        setIsDeleting(false);
        setShowDialog(false);
        dispatch({
          type: ConnectivityPlannerAction.SET_DELETED_FLIGHT_PLAN_COUNT,
          payload: deleteFlightPlans?.body?.deleted
        });
      }
    } else {
      setShowDialog(false);
    }
  };
  /**
   * This will resets applied filters, changed sorting column to uploaded descending order and change date to +/-28 days
   */
  const onDialogAlertViewOutlookClick = () => {
    dispatch({type: FilterAction.REMOVE_ALL_FILTERS, payload: {}});
    dispatch({
      type: ConnectivityPlannerAction.REFRESH_FLIGHT_PLAN_LIST,
      payload: true
    });
    dispatch({
      type: ColumnSortAction.SET_COLUMN_SORT,
      payload: {
        order: 'desc',
        column: 'uploadedTstamp',
        queryField: 'uploadedTstamp',
        validColumns: flightPlanListColumns.map((col: ListGridColumn) => col.key.toString()),
        validQueryFields: flightPlanListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(FlightPlanListColumnId[col.key])
        )
      }
    });
    dispatch({
      type: ConnectivityPlannerAction.SET_DATE_RANGE,
      payload: {
        startDate: flightPlanListDefaultStartDate(28),
        endDate: flightPlanListDefaultEndDate(28),
        persistStartDate: false,
        persistEndDate: false,
        viewContext: 'cop'
      }
    });
    dispatch({
      type: ConnectivityPlannerAction.SET_NEW_UPLOADED_PLANS_ALERT,
      payload: false
    });
  };

  /**
   * This will return Alert box buttons to perform some actions like view outlooks
   */
  const DialogAlertBoxActions = () => {
    return (
      <button
        id={getElementIdFromSectionBase('flight-plan-list', 'view-outlooks', '0')}
        className="left-button"
        onClick={onDialogAlertViewOutlookClick}
      >
        Review New Plans
      </button>
    );
  };

  /**
   * Closes the dialog alert after any action taken by the user
   */
  const onDialogAlertClose = () => {
    dispatch({
      type: ConnectivityPlannerAction.RESET_NEW_FLIGHT_PLAN_COUNT,
      payload: 0
    });
    dispatch({
      type: ConnectivityPlannerAction.SET_NEW_UPLOADED_PLANS_ALERT,
      payload: false
    });
  };
  /**
   * Closes the dialog alert after any action taken by the user
   */
  const onOverlapAlertClose = () => {
    dispatch({
      type: ConnectivityPlannerAction.SET_VIEW_OUTLOOK_OVERLAP_ALERTS,
      payload: false
    });
  };

  /**
   * Updates the hidden columns in the state
   */
  const onHiddenColumnsChange = (hiddenColumns: string[]) => {
    dispatch({
      type: getActionTypeWithContext(HideColumnsAction.SET_HIDDEN_COLUMNS, CONNECTIVITY_PLANNER_STORE_CONTEXT),
      payload: {hiddenColumns}
    });
  };

  /**
   * Navigate to the connectivity outlook planner with the selected flight plans
   */
  const onViewOutlookClick = () => {
    if (disableViewOutlook.alertTitle !== null) {
      dispatch({
        type: ConnectivityPlannerAction.SET_VIEW_OUTLOOK_OVERLAP_ALERTS,
        payload: true
      });
      return;
    }

    navigate('/connectivity-outlook-page');
  };

  // Adds additional data keys to the flight plan list data for table consumption
  const flightPlanDataWithSelections: FlightPlanListRow[] = flightPlanData
    ? [...flightPlanData].map((row) => {
        const isDisabled = selectedFlightPlans.length > 0 ? selectedFlightPlans[0].tailId !== row.tailId : false;
        return {
          ...row,
          isSelected: selectedFlightPlans.some(
            (selectedFlightPlan: FlightPlanInfo) => selectedFlightPlan.id === row.id
          ),
          isDisabled: isDisabled,
          disabledTooltipText: isDisabled ? flightPlanDisabledTooltipText : '',
          departureAirportData: {
            airportData: airports ? airports[row.departureAirport] : undefined,
            code: row.departureAirport
          },
          destinationAirportData: {
            airportData: airports ? airports[row.destinationAirport] : undefined,
            code: row.destinationAirport
          }
        };
      })
    : null;

  return (
    <>
      <ConfirmationDialog
        confirmationTitle={'Are you sure to want to delete?'}
        confirmationContent={'This action would delete the flight plans permanently.'}
        errorMessage={deleteErrorMessage}
        handleClose={proceedToDelete}
        isLoading={isDeleting}
        showDialog={showDialog}
      />
      {newFlightPlansCount > 0 ? (
        <DialogAlertPopup
          idBase="flightPlanList"
          id={getElementIdFromSectionBase('flightPlanList', 'review-plans', '0')}
          trigger={isAlertEnabled}
          heading={intl.formatMessage({id: 'dialog_alert_popup.new_flight_plan_added.title'})}
          message={newFlightPlansCount + intl.formatMessage({id: 'dialog_alert_popup.new_flight_plan_added.message'})}
          dialogAlertBoxActions={<DialogAlertBoxActions />}
          onClose={() => onDialogAlertClose()}
          dialogBoxHeight={195}
          dialogMessageHeight={65}
          dialogColor={ALERT_DIALOG_LEFT_BORDER}
          dialogAlertIcon={<ToastIcon style={{color: ALERT_DIALOG_LEFT_BORDER, paddingRight: '5px'}} />}
        />
      ) : (
        <></>
      )}
      {flightPlanOverlap ? (
        <DialogAlertPopup
          idBase={'flightPlanList'}
          id={getElementIdFromSectionBase('flightPlanList', 'overlap-time', '0')}
          trigger={flightPlanOverlap}
          heading={disableViewOutlook.alertTitle}
          message={disableViewOutlook.alertMessage}
          dialogAlertBoxActions={null}
          onClose={() => onOverlapAlertClose()}
          dialogBoxHeight={168}
          dialogMessageHeight={96}
          dialogColor={DIALOG_ALERT_ERROR_COLOR}
          dialogAlertIcon={<ErrorIcon style={{color: DIALOG_ALERT_ERROR_COLOR, paddingRight: '5px'}} />}
        />
      ) : (
        <></>
      )}
      <ListViewComponent
        idPrefix={idPrefix}
        isLoading={isFlightPlanLoading}
        tabs={undefined}
        subTitle={!selectedFlightPlanCount && !isFlightPlanLoading ? `(${flightPlanTotal})` : null}
        subTitleLoading={isFlightPlanLoading}
        listTitle={!selectedFlightPlanCount ? 'Flight Plans' : `${selectedFlightPlanCount} Selected`}
        customLeftActions={[
          !selectedFlightPlanCount ? (
            <DatePicker
              key="datePicker"
              getFullElementId={(name: string, type: string) =>
                `${getElementIdSectionBase(idPrefix, 'header') + 'DatePicker'}__${name}-${type}`
              }
              currentDateRange={datePickerState}
              onSetDateRange={onDatePickerChange}
              allowFutureDate={true}
              removeDatePickerGuardrails={removeDatePickerGuardrails}
              datePickerPresets={DATE_PICKER_PRESETS_LAST_NEXT}
            />
          ) : (
            <React.Fragment key="datePickerEmpty"></React.Fragment>
          ),
          !selectedFlightPlanCount ? (
            <FilterHookContainer
              key="filters"
              idPrefix={idPrefix}
              storeContext={CONNECTIVITY_PLANNER_STORE_CONTEXT}
              handleFilterChange={handleFilterChange}
            />
          ) : (
            <React.Fragment key="filtersEmpty"></React.Fragment>
          ),
          !selectedFlightPlanCount ? (
            <ListViewSortContainer
              key="sorts"
              idPrefix={idPrefix}
              disabled={isFlightPlanLoading}
              storeContext={CONNECTIVITY_PLANNER_STORE_CONTEXT}
              columns={flightPlanListColumns}
              showColumnGroups={true}
              getQueryFieldForColumn={getQueryFieldForColumn}
            />
          ) : (
            <React.Fragment key="sortsEmpty"></React.Fragment>
          ),
          !selectedFlightPlanCount ? (
            <HiddenColumnSelector
              key="columns"
              getFullElementId={(name, type) => `${idPrefix}--columnSelector__${name}-${type}`}
              onHiddenColumnsChange={onHiddenColumnsChange}
              isDisabled={isFlightPlanLoading}
              columns={flightPlanListColumns.map((col) => ({
                id: col.key.toString(),
                showHideGroup: col.showHideGroup,
                title: col.title
              }))}
              hiddenColumns={currentHiddenColumns}
            />
          ) : (
            <React.Fragment key="columnsEmpty"></React.Fragment>
          ),
          selectedFlightPlanCount ? (
            <>
              <StyledElms.UploadButton
                id={getElementIdFromSectionBase('Connectivity-Outlook', 'ConnectivityOutlook', 'button')}
                key={`${idPrefix}-view-outlook-button`}
                onClick={onViewOutlookClick}
              >
                <div style={{display: 'flex'}}>
                  <span>
                    <TelescopeIcon sx={{paddingTop: '4px', width: 20, height: 20}} htmlColor={WHITE} />
                  </span>
                  <span style={{margin: '6px 0px 0px 5px'}}>View Outlook</span>
                </div>
              </StyledElms.UploadButton>
              <StyledElms.DeleteButton
                id={`${idPrefix}-delete-button`}
                key={`${idPrefix}-delete-button`}
                disabled={disableDelete}
                onClick={() => deleteFlightPlan()}
              >
                {disableDelete ? (
                  <RightDataToolTip
                    id={`flight-plans-delete-tooltip`}
                    arrow
                    placement="right"
                    enterDelay={300}
                    title={
                      <div style={{lineHeight: '18px', fontSize: 14, margin: '6px 10px'}}>
                        <BoldSpan>Delete is not allowed for non-end users</BoldSpan>
                      </div>
                    }
                  >
                    <DELETE_ICON />
                  </RightDataToolTip>
                ) : (
                  <DELETE_ICON />
                )}
              </StyledElms.DeleteButton>
            </>
          ) : (
            <React.Fragment key="viewOutlookEmpty"></React.Fragment>
          )
        ]}
        customRightActions={[
          <ShareButton
            idBase={idPrefix}
            key={`share${idPrefix}`}
            disabled={isFlightPlanLoading}
            customFilters={{
              ...sort,
              ...translateFiltersArrayToObject(filters),
              ...translateHiddenColumnsArrayToObject(currentHiddenColumns, CONNECTIVITY_PLANNER_STORE_CONTEXT)
            }}
            viewContext={CONNECTIVITY_PLANNER_STORE_CONTEXT}
          />,
          !selectedFlightPlanCount ? (
            <FlightPlanUploader
              key="uploadFlightPlan"
              idBase={idPrefix}
              initialModalState={false}
              filteredAircraftList={filteredAircraftList}
              searchCriteria={searchCriteria}
              setSearchCriteria={setSearchCriteria}
            />
          ) : (
            <React.Fragment key="uploadFlightPlanEmpty"></React.Fragment>
          )
        ]}
        listContext={CONNECTIVITY_PLANNER_STORE_CONTEXT}
        listColumns={flightPlanListColumns}
        hiddenColumns={currentHiddenColumns}
        listSort={sort}
        listData={flightPlanDataWithSelections}
        listTotal={flightPlanTotal}
        listFilters={filters}
        listContainerHeight={'55px'}
        arePrereqsLoading={isFlightPlanLoading && isAirportsLoading}
        handleSortChange={handleSortChange}
        columnOverscan={10}
        notificationMessage={maxRowNotificationMessage}
        showNotification={showNotification}
        setShowNotification={setShowFlightPlanListNotification}
        emptyPlaceHolderStatement={EMPTY_PLACE_HOLDER_STATEMENT}
        emptyPlaceHolderDescription={EMPTY_PLACE_HOLDER_DESCRIPTION}
        handleRowSelection={handleFlightPlanSelection}
        handleRowDeselection={handleFlightPlanDeselection}
        rowSelectionKey={'id'}
        rowDisableKey={'tailId'}
        fixedColumnCount={2}
        selectedRows={selectedFlightPlans}
        disabledToolipText={flightPlanDisabledTooltipText}
      />
    </>
  );
};

export default FlightPlanListPage;
