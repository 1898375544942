/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Live time entry component, currently intended for use in testing
 */

import React, {useState, useCallback} from 'react';
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment-timezone';
import {BOLD_BLUE} from './theme/Colors';

const LiveTimeEntryContainer = styled.div`
  border: 1px dotted ${BOLD_BLUE};
  border-radius: 5px;
  padding: 2px;
  display: flex;
  align-items: stretch;
`;

const StyledInput = styled.input`
  background: white;
  margin: 4px;
  border: 1px solid ${BOLD_BLUE};
  font-size: 16px;
  font-family: 'Source Sans Pro';
  color: ${BOLD_BLUE};
`;

moment.tz.setDefault('UTC');

interface LiveTimeEntryProps {
  idBase: string;
  enable: boolean;
  value: moment.Moment;
  onChange: (timeOverrideEnable: boolean, timeOverrideValue: moment.Moment) => void;
  format?: string;
  mask?: string;
}

export const LiveTimeEntry = ({idBase, enable, value, onChange, format}: LiveTimeEntryProps) => {
  const [internalValue, setInternalValue] = useState(value);
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, internalValue);
  };
  const formatString = format ? format : 'MM/DD/yyyy HH:mm:ss';

  const handleOverrideValueChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      try {
        const parsedMoment = moment.utc(newValue, formatString);
        setInternalValue(parsedMoment);
        if (enable) {
          onChange(enable, parsedMoment);
        }
      } catch {
        // Do nothing
      }
    },
    [enable, onChange, formatString]
  );

  return (
    <LiveTimeEntryContainer id={`${idBase}__timeOverride-container`} data-override-enabled={enable}>
      <FormControlLabel
        sx={{color: BOLD_BLUE}}
        control={
          <Checkbox
            id={`${idBase}__timeOverride-enable`}
            checked={enable}
            onChange={handleCheckChange}
            sx={{color: BOLD_BLUE}}
          />
        }
        label="Time Override"
      />
      <StyledInput
        id={`${idBase}__timeOverride-value`}
        type="text"
        defaultValue={value.format(formatString)}
        onChange={handleOverrideValueChange}
      />
    </LiveTimeEntryContainer>
  );
};

export default LiveTimeEntry;
