/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: The Google Map marker for Lab Terminal
 */

import React from 'react';
import {OverlayView} from '@react-google-maps/api';

import {getElementId} from '../../utils/ElementIdUtils';
import {PinIconContainer} from './MapStyles';
import {START_Z_INDEX, MAP_ELID_SECTION} from '../../utils/constants';
import {LabTerminalMapMarkerProps} from '../../utils/MapUtil';

import {AirplaneInnerContainer, AirplaneContainer} from './MapStyles';
import {getAvailabilityColor} from '../../utils/MapUtil';
import DotMapIcon from '../common/theme/icons/DotMapIcon';
import LabTerminalIcon from '../common/theme/icons/LabTerminalIcon';
import {WHITE} from '../common/theme/Colors';

/**
 * The Google Map marker for LabTerminal
 * @param props Map Aircraft Props
 * @returns Map overlayview
 */
const LabTerminalMapMarker: React.FC<LabTerminalMapMarkerProps> = (props) => {
  const {type, internal, location, pixelPositionOffset, aircraftData} = props;

  const renderedMapMarker = () => {
    if (type === 'start' || type === 'end') {
      return (
        <>
          {type === 'start' && (
            <PinIconContainer zIndex={START_Z_INDEX}>
              <DotMapIcon id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')} />
            </PinIconContainer>
          )}
          {type === 'end' && (
            <AirplaneContainer
              id={getElementId(MAP_ELID_SECTION, `aircraft-${aircraftData?.serialNumber}`, 'airplane', 'container')}
              data-latitude={location?.lat}
              data-longitude={location?.lng}
              display={'inline-block'}
              zIndex={START_Z_INDEX}
              animation={null}
              onClick={() => {}}
              onMouseEnter={() => {}}
              onMouseLeave={() => {}}
            >
              <AirplaneInnerContainer
                style={{
                  opacity: 1,
                  background: `${getAvailabilityColor(aircraftData?.status)}`
                }}
              >
                <LabTerminalIcon
                  id={getElementId(MAP_ELID_SECTION, `${type}`, 'pin', 'icon')}
                  sx={{color: WHITE, width: 24, height: 15}}
                />
              </AirplaneInnerContainer>
            </AirplaneContainer>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  return internal ? (
    renderedMapMarker()
  ) : (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={{lat: location?.lat, lng: location?.lng}}
      getPixelPositionOffset={(x, y) => pixelPositionOffset}
    >
      {renderedMapMarker()}
    </OverlayView>
  );
};

export default LabTerminalMapMarker;
