/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Flight Details
 */

import {isNil} from 'lodash';
import {Severity} from '@viasat/insights-components';
import {SnackBarState, SnackBarReducer, createSnackBar} from './SnackBarReducer';
import {delegateToReducers} from '../Reducer';
import {CommonAction} from '../types';
import {setFilters, setHistoricalConnectedStart} from '../UrlMap';

export const FLIGHT_DETAILS_STORE_CONTEXT = 'flightDetails';
export const FLIGHT_DETAILS_ROUTE = '/flight/details';
export const COMBINED_USAGE_CHART_HASH = '8eb5';
export const DOWNLOAD_USAGE_CHART_HASH = 'ed54';
export const UPLOAD_USAGE_CHART_HASH = 'afc7';

export const FlightDetailsSessionStoreKeys: string[] = [
  'overlays.showKaOverlay',
  'overlays.showKuOverlay',
  'overlays.showTxInhibitZoneBelow10kFt',
  'overlays.showTxInhibitZoneAllAltitudes',
  'timeOverride.enabled',
  'timeOverride.value',
  'dataUsageTimeFilter.enabled',
  'historicalConnectedStart',
  'cardOrder',
  'hiddenCards',
  'dataUsageCardView'
];

export interface DataUsageTimeFilterState {
  enabled: boolean;
}

export const InitialDataUsageTimeFilterState: DataUsageTimeFilterState = {
  enabled: undefined
};

interface IFlightInfo {
  departure_time: string;
  arrival_time: string;
  flight_phase: string;
  connection_start_time: string;
  connection_end_time: string;
}

interface IFlightTimelineData {
  connectionStart: string;
  connectionEnd: string;
  doorsClose: string;
  doorsOpen: string;
  departure: string;
  arrival: string;
  ascendTime: string;
  cruisingTime: string;
  descendTime: string;
}

interface IFlightDetailsTimeOverride {
  enabled: boolean;
  value: string;
}

export const enum DataUsageCardView {
  MERGE_CHART = 'MERGE_CHART',
  SPLIT_CHART = 'SPLIT_CHART'
}

export interface FlightDetailsState {
  snackBar: SnackBarState;
  tailInfo: any;
  flightInfo: IFlightInfo;
  dataUsageInfo: any;
  flightPathData: any;
  flightTimelineData: IFlightTimelineData;
  pingLatencyInfo: any;
  flightPathEvents: any;
  trafficCompositionInfo: any;
  cabinConnectivityInfo: any;
  monthlyUsageInfo: any;
  dataUsageTimeFilter: DataUsageTimeFilterState;
  timeOverride: IFlightDetailsTimeOverride;
  historicalConnectedStart: string;
  cardOrder: string[];
  hiddenCards: string[];
  dataUsageCardView: DataUsageCardView;
}

export const InitialFlightDetailsState: FlightDetailsState = {
  snackBar: [],
  tailInfo: null,
  flightInfo: {
    departure_time: null,
    arrival_time: null,
    flight_phase: null,
    connection_start_time: null,
    connection_end_time: null
  },
  dataUsageInfo: null,
  flightPathData: null,
  flightTimelineData: null,
  pingLatencyInfo: null,
  flightPathEvents: null,
  trafficCompositionInfo: null,
  cabinConnectivityInfo: null,
  monthlyUsageInfo: null,
  dataUsageTimeFilter: {
    enabled: false
  },
  timeOverride: {
    enabled: false,
    value: undefined
  },
  historicalConnectedStart: null,
  cardOrder: null,
  hiddenCards: [],
  dataUsageCardView: null
};

export enum FlightDetailsAction {
  SET_DATA_USAGE_TIME_FILTER = 'SET_DATA_USAGE_TIME_FILTER',
  SET_TIME_OVERRIDE = 'SET_TIME_OVERRIDE',
  SET_HISTORICAL_CONNECTED_START = 'SET_HISTORICAL_CONNECTED_START',
  RESET_TIME_OVERRIDE = 'RESET_TIME_OVERRIDE',
  INVALID_SERIAL_NUMBER = 'INVALID_SERIAL_NUMBER',
  INVALID_FLIGHT_IDENTIFIER = 'INVALID_FLIGHT_IDENTIFIER',
  RESET_FLIGHT_DETAILS_VIEW_GRAPHS = 'RESET_FLIGHT_DETAILS_VIEW_GRAPHS',
  SET_FLIGHT_DETAILS_CARD_ORDER = 'SET_FLIGHT_DETAILS_CARD_ORDER',
  SET_FLIGHT_DETAILS_CARD_DISPLAY = 'SET_FLIGHT_DETAILS_CARD_DISPLAY',
  MERGE_DATA_USAGE_CARD = 'MERGE_DATA_USAGE_CARD',
  SPLIT_DATA_USAGE_CARD = 'SPLIT_DATA_USAGE_CARD'
}

/**
 * Flight Details action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const FlightDetailsReducer = (state: FlightDetailsState, action: any): FlightDetailsState => {
  const snackBars = state.snackBar ? state.snackBar : [];
  switch (action.type) {
    case CommonAction.PROCESS_URL_PARAMS:
      if (action.payload.path.startsWith(FLIGHT_DETAILS_ROUTE)) {
        state = setFilters(state, action.payload);
        const {cardOrder, hiddenCards} = action.payload.params;
        state = {
          ...state,
          cardOrder: cardOrder ? cardOrder : [],
          hiddenCards: hiddenCards ? hiddenCards : []
        };
        state = setHistoricalConnectedStart(state, action.payload);
      }
      break;

    case FlightDetailsAction.SET_TIME_OVERRIDE:
      state = {...state, timeOverride: {enabled: true, value: action.payload}};
      break;

    case FlightDetailsAction.RESET_TIME_OVERRIDE:
      state = {...state, timeOverride: {enabled: false, value: action.payload}};
      break;

    case FlightDetailsAction.SET_DATA_USAGE_TIME_FILTER:
      state = {
        ...state,
        dataUsageTimeFilter: {enabled: action.payload}
      };
      break;

    case FlightDetailsAction.SET_HISTORICAL_CONNECTED_START:
      state = {
        ...state,
        historicalConnectedStart: action.payload
      };
      break;

    case FlightDetailsAction.INVALID_SERIAL_NUMBER: {
      const intl = action.payload.intl;
      const invalidSerialNumberMsg = intl.formatMessage({
        id: 'flight_details.invalid_serial_number.snackMsg'
      });

      // Prevents duplicate snack bar
      if (snackBars.length === 0) {
        snackBars.push(
          createSnackBar({
            message: invalidSerialNumberMsg,
            severity: Severity.WARNING,
            getFullElementId: (name, type) => `${name}-${type}`
          })
        );
      }
      state = {
        ...state,
        snackBar: snackBars
      };
      break;
    }

    case FlightDetailsAction.INVALID_FLIGHT_IDENTIFIER: {
      const intl = action.payload.intl;
      const invalidFlightIdentiferMsg = intl.formatMessage({
        id: 'flight_details.invalid_flight_identifier.snackMsg'
      });

      // Prevents duplicate snack bar
      if (snackBars.length === 0) {
        snackBars.push(
          createSnackBar({
            message: invalidFlightIdentiferMsg,
            severity: Severity.WARNING,
            getFullElementId: (name, type) => `${name}-${type}`
          })
        );
      }

      state = {
        ...state,
        snackBar: snackBars
      };
      break;
    }

    case FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_ORDER:
      state = {
        ...state,
        cardOrder: action.payload
      };
      break;

    case FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY:
      const hiddenCards = [...state.hiddenCards];
      if (action.payload.hide) {
        hiddenCards.push(action.payload.id);
      } else if (hiddenCards.includes(action.payload.id)) {
        const idx = hiddenCards.findIndex((id) => id === action.payload.id);
        if (idx > -1) {
          hiddenCards.splice(idx, 1);
        }
      }
      state = {
        ...state,
        hiddenCards
      };
      break;
    case FlightDetailsAction.RESET_FLIGHT_DETAILS_VIEW_GRAPHS:
      state = {
        ...state,
        hiddenCards: [],
        cardOrder: []
      };
      break;
    case FlightDetailsAction.MERGE_DATA_USAGE_CARD:
      const defaultCardHashOrder = !isNil(state.cardOrder) ? state.cardOrder : [];
      const cardHashOrder = [...defaultCardHashOrder];
      cardHashOrder.splice(cardHashOrder.indexOf(COMBINED_USAGE_CHART_HASH), 1);
      const usageCardId = cardHashOrder.findIndex((cardHash) => cardHash === action.payload);
      cardHashOrder.splice(usageCardId + 1, 0, COMBINED_USAGE_CHART_HASH);
      state = {
        ...state,
        dataUsageCardView: DataUsageCardView.MERGE_CHART,
        cardOrder: cardHashOrder
      };
      break;
    case FlightDetailsAction.SPLIT_DATA_USAGE_CARD:
      const defaultCardOrder = !isNil(state.cardOrder) ? state.cardOrder : [];
      const cardOrder = [...defaultCardOrder];
      cardOrder.splice(cardOrder.indexOf(DOWNLOAD_USAGE_CHART_HASH), 1);
      cardOrder.splice(cardOrder.indexOf(UPLOAD_USAGE_CHART_HASH), 1);
      const combinedUsageCardIdx = cardOrder.findIndex((cardHash) => cardHash === action.payload);
      cardOrder.splice(combinedUsageCardIdx + 1, 0, DOWNLOAD_USAGE_CHART_HASH, UPLOAD_USAGE_CHART_HASH);
      state = {
        ...state,
        dataUsageCardView: DataUsageCardView.SPLIT_CHART,
        cardOrder
      };
      break;
    default:
      break;
  }
  state = delegateToReducers(
    FLIGHT_DETAILS_STORE_CONTEXT,
    state,
    action,
    {
      snackBar: SnackBarReducer
    },
    InitialFlightDetailsState
  );
  return state;
};
