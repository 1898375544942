/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Circular Dot to demonstrate availability target
 */
import {createSvgIcon} from '@mui/material';

/**
 * Circular dot to demonstrate availability target
 * returns Diamond DOT SVG
 */
const DiamondIcon = createSvgIcon(
  <svg
    focusable="false"
    aria-hidden="true"
    role="presentation"
    style={{
      position: 'relative',
      verticalAlign: 'text-top'
    }}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6868 8.35357L14.0404 8.00002L13.6868 7.64647L8.35351 2.31313L7.99996 1.95958L7.64641 2.31313L2.31307 7.64647L1.95952 8.00002L2.31307 8.35357L7.64641 13.6869L7.99996 14.0405L8.35351 13.6869L13.6868 8.35357Z"
      fill="#111920"
      stroke="#111920"
    />
  </svg>,

  'DiamondIcon'
);

export default DiamondIcon;
