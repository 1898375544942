/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: File Upload Component
 */

import React, {useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from '@emotion/styled';
import axios from 'axios';
import {find} from 'lodash';
import FormData from 'form-data';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorIcon from '@mui/icons-material/Error';
import {bizavApiUrl} from '../../utils/config';
import {formatBytes, getFileUploadContainerColor} from './ConnectivityOutlookUtils';
import LinearProgressBar from '../common/LinearProgressBar';
import {Span} from '../mapView/aircraftPopups/AircraftPopupStyles';
import {
  DROP_ZONE_BACKGROUND_COLOR,
  DROP_ZONE_BROWSE_FILES_COLOR,
  DROP_ZONE_UPLOAD_ICON_COLOR,
  DROP_ZONE_OUTER_TEXT_COLOR,
  DROP_ZONE_INNER_TEXT_COLOR,
  UPLOAD_PROGRESS_BAR_BG_COLOR,
  UPLOAD_PROGRESS_BAR_COLOR,
  IMPORT_FILE_LIST_FILE_NAME,
  UPLOAD_SUCCESS_ICON_COLOR,
  UPLOAD_ERROR_ICON_COLOR,
  UPLOAD_SYNC_ICON_COLOR,
  AIRCRAFT_LIST_ITEM_COLOR,
  AIRCRAFT_LIST_OPTION_CONTAINER_COLOR
} from '../common/theme/Colors';
import {useStore} from '../../store/Store';
import {ConnectivityPlannerAction} from '../../store/reducers/ConnectivityPlannerReducer';
import {CancelTokenAction} from '../../store/reducers/CancelTokenReducer';
import {getElementIdFromSectionBase} from '../../utils/ElementIdUtils';
import {
  INTERNAL_SERVER_ERROR_CODE,
  REQUEST_REJECTED_ERROR_CODE,
  REQUEST_FLIGHT_PLAN_EXIST_CODE
} from '../../utils/constants';
import EllipsisText, {EllipsisPosition} from '../common/EllipsisText';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getFileUploadContainerColor(props)};
  border-style: dashed;
  background-color: ${DROP_ZONE_BACKGROUND_COLOR};
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 543px;
  height: 128px;
`;

const InnerDropZoneContent = styled.div`
  margin-top: 10px;
  color: ${DROP_ZONE_INNER_TEXT_COLOR};
  font: Uni Neue;
  font-size: 18px;
  line-height: 21.09px;
  text-align: center;
`;

const OuterDropZoneContent = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
  color: ${DROP_ZONE_OUTER_TEXT_COLOR};
  font: Source Sans Pro;
  font-size: 14px;
  line-height: 20px;
`;

const FileListContainer = styled.div`
  max-height: 153px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 24px;
`;
const FileListItem = styled.div`
  width: 543px;
  height: 76px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 8px 0;
  .upload-success {
    color: ${UPLOAD_SUCCESS_ICON_COLOR};
    position: relative;
    float: right;
    display: inline-flex;
    align-items: flex-end;
    right: 20px;
    margin-top: 5px;
  }
  .upload-error {
    color: ${UPLOAD_ERROR_ICON_COLOR};
    position: relative;
    float: right;
    display: inline-flex;
    align-items: flex-end;
    right: 15px;
    margin-top: 5px;
  }
`;

const FilUploadIndicator = styled.div`
  position: relative;
  float: right;
  display: inline-flex;
  align-items: flex-end;
  right: 20px;
  margin-top: 2px;
  .file-upload-sync {
    color: ${UPLOAD_SYNC_ICON_COLOR};
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
`;

const FileName = styled.div`
  width: 80px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${IMPORT_FILE_LIST_FILE_NAME};
  position: relative;
  margin-left: 16px;
  margin-top: 14px;
  display: inline;
`;

const FileSize = styled.div`
  width: 76px;
  height: 24px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${DROP_ZONE_OUTER_TEXT_COLOR};
  display: inline;
  margin-left: 8px;
`;

const FileProgressBar = styled.div`
  width: 511px;
  margin: 8px 16px 0px 16px;
`;

const FileUploadComment = styled.div`
  font-size: 14px;
  color: ${UPLOAD_ERROR_ICON_COLOR};
  display: inline-flex;
  margin: 4px 16px 4px 16px;
  &.overwrite-save-copy {
    color: ${DROP_ZONE_BROWSE_FILES_COLOR};
    margin: 4px 0px 4px -12px;
  }
  .splitter {
    color: ${DROP_ZONE_INNER_TEXT_COLOR};
    margin-right: 7px;
  }
  .flightPlanInfoLight {
    color: ${DROP_ZONE_OUTER_TEXT_COLOR};
  }
  .flightPlanInfoBlack {
    color: ${DROP_ZONE_INNER_TEXT_COLOR};
    margin-right: 4px;
  }
`;

const Splitter = styled.span<{left: number}>`
  position: absolute;
  width: 4px;
  height: 20px;
  top: 3px;
  left: ${(props) => props.left}px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  color: ${AIRCRAFT_LIST_ITEM_COLOR};
  flex: none;
  flex-grow: 0;
  margin: 0px 17px;
`;

const FileOptionsContainer = styled.div`
  position: relative;
  top: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: inline;
  float: right;
  text-align: right;
  color: ${AIRCRAFT_LIST_OPTION_CONTAINER_COLOR};
  flex: none;
  flex-grow: 0;
  margin: 0px -16px;
  &.overwrite {
    left: -50px;
  }
`;

const RemoveFile = styled.div`
  color: ${DROP_ZONE_BROWSE_FILES_COLOR};
  position: relative;
  display: inline;
  float: right;
  width: 120px;
  text-align: right;
  font-size: 16px;
  right: 30px;
  margin-top: 5px;
  cursor: pointer;
`;

interface FileUploadProps {
  idBase: string;
  setIsAircraftListDisplay: (boolean) => void;
  setSelectedFlightPlanId: (number) => void;
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const {idBase, setIsAircraftListDisplay, setSelectedFlightPlanId} = props;
  const {dispatch, store} = useStore();
  const groupCode = store.customer.current.code ? store.customer.current.code : '';
  const {flightPlanImportFiles, cancelTokenSource} = store.connectivityPlanner;
  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open, acceptedFiles} = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 6,
    maxSize: 10000000,
    accept:
      'application/pdf, .txt, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg'
  });

  useEffect(() => {
    dispatch({
      type: ConnectivityPlannerAction.SET_SELECTED_FILES,
      payload: acceptedFiles
    });
  }, [dispatch, acceptedFiles]);
  const uploadFile = useCallback(
    async (flightPlanImportFile) => {
      dispatch({
        type: ConnectivityPlannerAction.SET_UPLOAD_PROGRESS,
        payload: {fileId: flightPlanImportFile.uniqueId, progress: 0}
      });
      dispatch({
        type: ConnectivityPlannerAction.SET_IS_UPLOAD,
        payload: {fileId: flightPlanImportFile.uniqueId, isUploading: true}
      });

      const formData = new FormData();
      const sourceOfCancelToken = axios.CancelToken.source();
      dispatch({
        type: CancelTokenAction.SET_CANCEL_TOKEN_SOURCE,
        payload: {id: flightPlanImportFile.uniqueId, token: sourceOfCancelToken}
      });
      formData.append('file', flightPlanImportFile);
      formData.append('groupCode', groupCode);
      const config = {
        headers: {Authorization: `Bearer ${localStorage.token}`},
        onUploadProgress: function (e) {
          const {loaded, total} = e;
          dispatch({
            type: ConnectivityPlannerAction.SET_UPLOAD_PROGRESS,
            payload: {fileId: flightPlanImportFile.uniqueId, progress: Math.round((loaded * 100) / total)}
          });
          dispatch({
            type: ConnectivityPlannerAction.SET_IS_UPLOAD,
            payload: {fileId: flightPlanImportFile.uniqueId, isUploading: true}
          });
        },
        cancelToken: sourceOfCancelToken.token
      };

      try {
        const uploadResponse = await axios.post(
          `${bizavApiUrl}/connectivityPlanner/uploadFlightPlan`,
          formData,
          config
        );
        if (uploadResponse) {
          dispatch({
            type: ConnectivityPlannerAction.SET_IS_UPLOAD,
            payload: {fileId: flightPlanImportFile.uniqueId, isUploading: false}
          });
          uploadResponse.data.flightPlan.uniqueId = flightPlanImportFile.uniqueId;
          dispatch({
            type: ConnectivityPlannerAction.SET_SERIAL_NUMBER_TAIL_ID,
            payload: {
              fileId: flightPlanImportFile.uniqueId,
              serialNumber: uploadResponse.data.flightPlan.serialNumber,
              tailId: uploadResponse.data.flightPlan.tailId
            }
          });
          dispatch({
            type: ConnectivityPlannerAction.SET_FLIGHT_PLAN_CONTENT,
            payload: uploadResponse.data
          });
        }
      } catch (error) {
        flightPlanImportFile.isUploading = false;
        dispatch({
          type: ConnectivityPlannerAction.SET_IS_UPLOAD,
          payload: {fileId: flightPlanImportFile.uniqueId, isUploading: false}
        });
        if (error?.message === 'cancel') {
          return;
        } else {
          if (error?.response?.status === REQUEST_REJECTED_ERROR_CODE) {
            flightPlanImportFile.error = 'Error: Unable to process the flight plan.';
            dispatch({
              type: ConnectivityPlannerAction.SET_ERROR_MESSAGE,
              payload: {fileId: flightPlanImportFile.uniqueId, error: 'Error: Unable to process the flight plan.'}
            });
          } else if (error?.response?.status === REQUEST_FLIGHT_PLAN_EXIST_CODE) {
            flightPlanImportFile.error = error.response.data.error;
            error.response.data.flightPlan.uniqueId = flightPlanImportFile.uniqueId;
            dispatch({
              type: ConnectivityPlannerAction.SET_ERROR_MESSAGE,
              payload: {
                fileId: flightPlanImportFile.uniqueId,
                error: error.response.data.error,
                content: error.response.data.flightPlan
              }
            });
          } else if (error?.response) {
            if (error.response?.data?.error?.includes('Tail ID')) {
              flightPlanImportFile.error = error.response.data.error;
              error.response.data.flightPlanContent.uniqueId = flightPlanImportFile.uniqueId;
              dispatch({
                type: ConnectivityPlannerAction.SET_ERROR_MESSAGE,
                payload: {
                  fileId: flightPlanImportFile.uniqueId,
                  error: error.response.data.error,
                  content: error.response.data.flightPlanContent
                }
              });
            } else if (error.response.status === INTERNAL_SERVER_ERROR_CODE) {
              flightPlanImportFile.error = 'File does not contain ICAO flight plan.';
              dispatch({
                type: ConnectivityPlannerAction.SET_ERROR_MESSAGE,
                payload: {fileId: flightPlanImportFile.uniqueId, error: 'File does not contain ICAO flight plan.'}
              });
            } else {
              flightPlanImportFile.error = error.response.data;
              dispatch({
                type: ConnectivityPlannerAction.SET_ERROR_MESSAGE,
                payload: {fileId: flightPlanImportFile.uniqueId, error: error.response.data}
              });
            }
          }
          return;
        }
      }
    },
    [dispatch, groupCode]
  );

  /**
   * This will update the flightplan content with overwrite flag
   * @param uniqueId unique id of uploaded flight plan
   */
  const overwriteFile = (uniqueId: string) => {
    setSelectedFlightPlanId(uniqueId);
    dispatch({
      type: ConnectivityPlannerAction.OVERWRITE_FLIGHT_PLAN,
      payload: {
        flightPlan: flightPlanImportFiles,
        selectedFlightPlanId: uniqueId
      }
    });
  };

  /**
   * This will update the flightplan content with saveCopy flag
   * @param uniqueId unique id of uploaded flight plan
   */
  const saveAsCopy = (uniqueId: string) => {
    setSelectedFlightPlanId(uniqueId);
    dispatch({
      type: ConnectivityPlannerAction.SAVE_AS_COPY_FLIGHT_PLAN,
      payload: {
        flightPlan: flightPlanImportFiles,
        selectedFlightPlanId: uniqueId
      }
    });
  };
  useEffect(() => {
    if (flightPlanImportFiles && flightPlanImportFiles.length) {
      flightPlanImportFiles.forEach((flightPlanImportFile) => {
        if (!flightPlanImportFile.isAlreadyProcessed) {
          uploadFile(flightPlanImportFile);
        }
        // Ignores the already processed file for uploading
        flightPlanImportFile.isAlreadyProcessed = true;
      });
    }
  }, [flightPlanImportFiles, uploadFile]);

  const browseFiles = (
    //eslint-disable-next-line
    <a style={{color: DROP_ZONE_BROWSE_FILES_COLOR, cursor: 'pointer'}} onClick={open}>
      browse files
    </a>
  );

  const updateAircraftListState = (selectedFileId) => {
    setIsAircraftListDisplay(true);
    setSelectedFlightPlanId(selectedFileId);
  };

  const removeFile = (fileIndex, action = 'remove') => {
    if (action === 'cancel') {
      dispatch({
        type: ConnectivityPlannerAction.SET_IS_UPLOAD,
        payload: {fileId: fileIndex, isUploading: false}
      });
      const findToken = find(cancelTokenSource, {id: fileIndex});

      if (findToken && typeof findToken['token'] != typeof undefined) {
        dispatch({
          type: CancelTokenAction.CANCEL_REQUEST,
          payload: {fileId: fileIndex}
        });
        dispatch({
          type: CancelTokenAction.REMOVE_TOKEN,
          payload: {fileId: fileIndex}
        });
      }
    }

    dispatch({
      type: ConnectivityPlannerAction.REMOVE_FILE_FROM_LIST,
      payload: fileIndex
    });

    dispatch({
      type: ConnectivityPlannerAction.REMOVE_FLIGHT_PLAN_CONTENT,
      payload: fileIndex
    });
  };
  return (
    <div className="container">
      {flightPlanImportFiles.length <= 6 ? (
        <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
          <input
            id={getElementIdFromSectionBase(idBase, 'upload', 'input')}
            style={{display: 'block'}}
            {...getInputProps()}
          />

          <Span style={{marginTop: 10}}>
            <CloudUploadIcon style={{fill: DROP_ZONE_UPLOAD_ICON_COLOR, fontSize: 40}} />
          </Span>
          <InnerDropZoneContent>
            Drag and drop flight plan here,
            <br />
            or {browseFiles}.
          </InnerDropZoneContent>
        </Container>
      ) : (
        <></>
      )}
      <OuterDropZoneContent>
        Accepted file types: .txt, .docx , .jpg, .png and PDF only. Max. file size limit is 10MB.
      </OuterDropZoneContent>
      {flightPlanImportFiles.length ? (
        <FileListContainer>
          {flightPlanImportFiles.map((fileItem, index) => {
            return (
              <div key={`file-item-${index}`}>
                <FileListItem key={`file-${index}`}>
                  <FileName>
                    <EllipsisText
                      fullText={fileItem.name}
                      idBase={`uploaded-file-name-${index}`}
                      maxLength={18}
                      ellipsisPosition={EllipsisPosition.middle}
                    />
                  </FileName>
                  <FileSize>({formatBytes(fileItem.size)})</FileSize>
                  {fileItem.isUploading ? (
                    <>
                      <FilUploadIndicator>
                        <SyncIcon className="file-upload-sync" />
                      </FilUploadIndicator>
                      <RemoveFile
                        id={getElementIdFromSectionBase(idBase, 'remove', 'file')}
                        onClick={() => removeFile(fileItem.uniqueId, 'cancel')}
                      >
                        Cancel
                      </RemoveFile>
                    </>
                  ) : (
                    <>
                      {!fileItem.error ? (
                        <CheckCircleIcon
                          id={getElementIdFromSectionBase(idBase, 'upload-success', 'icon')}
                          className="upload-success"
                        />
                      ) : (
                        <ErrorIcon
                          id={getElementIdFromSectionBase(idBase, 'upload-error', 'icon')}
                          className="upload-error"
                        />
                      )}
                      <RemoveFile
                        id={getElementIdFromSectionBase(idBase, 'remove-file', index)}
                        onClick={() => removeFile(fileItem.uniqueId)}
                      >
                        Remove
                      </RemoveFile>
                      {fileItem?.error?.includes('Tail ID') ? (
                        <FileOptionsContainer onClick={() => updateAircraftListState(fileItem.uniqueId)}>
                          {' '}
                          Find the Tail ID <Splitter left={90}> | </Splitter>{' '}
                        </FileOptionsContainer>
                      ) : (
                        <></>
                      )}
                      {/* Condition has to be changed with isDuplicate flag which will be part of API response CMDO-85262  */}
                      {fileItem?.error?.includes('Warning') ? (
                        <>
                          <FileOptionsContainer
                            id={getElementIdFromSectionBase(idBase, 'save-copy', index)}
                            onClick={() => saveAsCopy(fileItem.uniqueId)}
                          >
                            {' '}
                            Save Copy <Splitter left={60}> | </Splitter>{' '}
                          </FileOptionsContainer>
                          <FileOptionsContainer
                            id={getElementIdFromSectionBase(idBase, 'overwrite', index)}
                            className="overwrite"
                            onClick={() => overwriteFile(fileItem.uniqueId)}
                          >
                            {' '}
                            Overwrite <Splitter left={55}> | </Splitter>{' '}
                          </FileOptionsContainer>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {!fileItem.error ? (
                    <>
                      <FileProgressBar>
                        <LinearProgressBar
                          barBorderRadius={0}
                          barHeight={4}
                          progressBgColor={UPLOAD_PROGRESS_BAR_BG_COLOR}
                          value={fileItem.progress}
                          color={UPLOAD_PROGRESS_BAR_COLOR}
                        ></LinearProgressBar>
                      </FileProgressBar>
                      {fileItem.content?.serialNumber && fileItem.content?.tailId ? (
                        <FileUploadComment>
                          <span>
                            <span className="flightPlanInfoLight"> SN: </span>
                            <span
                              id={getElementIdFromSectionBase(idBase, 'serial-number-text', index)}
                              className="flightPlanInfoBlack"
                            >
                              {fileItem.content?.serialNumber}
                            </span>
                            <span className="flightPlanInfoLight"> Tail ID: </span>
                            <span
                              id={getElementIdFromSectionBase(idBase, 'tail-id-text', index)}
                              className="flightPlanInfoBlack"
                            >
                              {fileItem.content?.tailId}
                            </span>
                          </span>
                        </FileUploadComment>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <FileUploadComment id={getElementIdFromSectionBase(idBase, 'error', 'text')}>
                      {fileItem.error}
                    </FileUploadComment>
                  )}
                  {fileItem.content?.isOverwrite ? (
                    <FileUploadComment
                      id={getElementIdFromSectionBase(idBase, 'overWrite', 'text')}
                      className="overwrite-save-copy"
                    >
                      <span className="splitter"> - </span> Flight plan will be overwritten.
                    </FileUploadComment>
                  ) : fileItem.content?.isSaveCopy ? (
                    <FileUploadComment
                      id={getElementIdFromSectionBase(idBase, 'saveCopy', 'text')}
                      className="overwrite-save-copy"
                    >
                      <span className="splitter"> - </span> Flight plan will be uploaded as a copy.
                    </FileUploadComment>
                  ) : (
                    <></>
                  )}
                </FileListItem>
              </div>
            );
          })}
        </FileListContainer>
      ) : null}
    </div>
  );
};

export default FileUpload;
