/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ListGrid checkbox cell
 *
 */
import React, {useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import {BLACK, CHECKBOX_ENABLED_COLOR} from '../../theme/Colors';
import {CellRenderer, ListGridColumnTooltip} from '@viasat/insights-components';

interface ListGridCheckboxProps {
  cellIdBase: string;
  row: any;
  isSelected?: boolean;
  disable?: boolean;
  handleRowSelection: (selectedRow: any, rowIndex: number) => void;
  handleRowDeselection: (removedRow: any, rowIndex: number) => void;
  rowIndex?: number;
}

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)`
  color: ${BLACK};
  padding: 0;
  &.mui-checked {
    color: ${CHECKBOX_ENABLED_COLOR};
  }
`;

const ListGridCheckbox = (props: ListGridCheckboxProps) => {
  const {cellIdBase, row, handleRowSelection, handleRowDeselection, isSelected, disable, rowIndex} = props;
  const [isChecked, setIsChecked] = useState(isSelected);

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);

    if (event.target.checked) {
      handleRowSelection(row, rowIndex);
    } else {
      handleRowDeselection(row, rowIndex);
    }
  };

  return (
    <CheckboxContainer>
      <StyledCheckbox
        id={`${cellIdBase}__selector`}
        checked={isChecked}
        onChange={handleSelection}
        disabled={disable}
      />
    </CheckboxContainer>
  );
};

// Cell Renderer for ListGridV2
export const CheckboxCellRenderer: CellRenderer = ({column, rowIndex, rowData, cellIdBase}) => {
  const {handleRowSelection, handleRowDeselection} = column;
  const {isSelected, isDisabled, disabledTooltipText} = rowData;

  return (
    <>
      {isDisabled ? (
        <ListGridColumnTooltip
          tooltipText={disabledTooltipText}
          getFullElementId={(name: string, type: string) => `${cellIdBase}__${name}-${type}`}
          placement="top-start"
        >
          <ListGridCheckbox
            cellIdBase={cellIdBase}
            handleRowSelection={handleRowSelection}
            handleRowDeselection={handleRowDeselection}
            row={rowData}
            rowIndex={rowIndex}
            isSelected={isSelected}
            disable={isDisabled}
          ></ListGridCheckbox>
        </ListGridColumnTooltip>
      ) : (
        <ListGridCheckbox
          cellIdBase={cellIdBase}
          handleRowSelection={handleRowSelection}
          handleRowDeselection={handleRowDeselection}
          rowIndex={rowIndex}
          row={rowData}
          isSelected={isSelected}
        ></ListGridCheckbox>
      )}
    </>
  );
};

export default CheckboxCellRenderer;
