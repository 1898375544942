/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Adding Issue with Description Popup
 */
import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {Lang, useFormInputValidation} from 'react-form-input-validation';
import {
  POPUP_TEXT_COLOR,
  CLOSE_ICON_COLOR,
  POPUP_BLOCK_BACKGROUND_COLOR,
  PROGRESS_BAR_BG_COLOR,
  SUPPORT_BORDER_COLOR,
  CASE_LIST_TEXT_COLOR,
  POPUP_BACKGROUND_COLOR,
  ACTIVE_BUTTON_COLOR,
  USAGE_EXCEEDED_TEXT,
  EXPANSIONPANEL_TEXT,
  LEFT_PANEL_FLIGHT_PHASE_ICON_COLOR
} from '../../../common/theme/Colors';

import {CircularProgress, MenuItem, OutlinedInput, Select, SelectChangeEvent, useTheme} from '@mui/material';
import {IFormValues, IRequestStatus} from './AircraftStatusSupportPopup';

const IssueDetailsPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px;
  gap: 32px;
  background: ${POPUP_BACKGROUND_COLOR};
  position: relative;
  width: 600px;
  border-radius: 16px;
  .subject-menu {
    font-size: 16px;
    color: ${POPUP_TEXT_COLOR};
    font-family: 'Source Sans Pro';
    background: ${POPUP_BLOCK_BACKGROUND_COLOR};
    border-radius: 4px;
    width: 97%;
    height: 40px;
    padding-left: 4px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
  gap: 14px;
  width: 552px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  padding-bottom: 10px;
  .input-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: ${EXPANSIONPANEL_TEXT};
  }
  .error {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${USAGE_EXCEEDED_TEXT};
  }
`;
const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 536px;
  height: 34px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 552px;
  height: 20px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  .label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${EXPANSIONPANEL_TEXT};
    position: relative;
    width: 65px;
    height: 20px;
    left: 0px;
    top: 0px;
    width: 48%;
  }
`;
const StyledInput = styled.input`
  border: 1px solid ${LEFT_PANEL_FLIGHT_PHASE_ICON_COLOR};
  font-size: 16px;
  font-family: 'Source Sans Pro';
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-radius: 4px;
  width: 94%;
  height: 40px;
  top: 12px;
  padding-left: 16px;
  color: ${EXPANSIONPANEL_TEXT};
`;

const StyledInputDesc = styled.textarea`
  border: 1px solid ${LEFT_PANEL_FLIGHT_PHASE_ICON_COLOR};
  font-size: 16px;
  font-family: 'Source Sans Pro';
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  color: ${EXPANSIONPANEL_TEXT};
  border-radius: 4px;
  width: 91%;
  height: 40px;
  top: 12px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  height: 72px;
`;
const PopupInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 552px;
  flex: none;
  order: 0;
  flex-grow: 0;
  .head-text {
    padding: 0px 0px;
    align-items: flex-start;
    letter-spacing: 0.2px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${POPUP_TEXT_COLOR};
  }
  .close-icon {
    width: 10%;
    margin-top: 2px;
    margin-right: 10px;
    color: ${CLOSE_ICON_COLOR};
    cursor: pointer;
    position: absolute;
    right: 0px;
  }
  .frameText {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    width: 536px;
    color: ${CASE_LIST_TEXT_COLOR};
    flex: none;
    order: 0;
    flex-grow: 1;
  }
`;

const FormActions = styled.div`
  left: -24px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 0px 0px 0px;
  gap: 16px;
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-top: 1px solid ${PROGRESS_BAR_BG_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 600px;
  height: 88px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  .action-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${EXPANSIONPANEL_TEXT};
    width: 310px;
    height: 40px;
    text-align: center;
  }
  .cancel-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 4px;
    border: 1px solid ${SUPPORT_BORDER_COLOR};
    background: ${POPUP_BLOCK_BACKGROUND_COLOR};
    border-radius: 1000px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px;
    cursor: pointer;
  }
  .submit-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 99px;
    gap: 4px;
    border-radius: 1000px;
    position: relative;
    height: 40px;
    right: 0px;
    top: 0px;
    cursor: pointer;
    margin-right: 20px;
    background: ${ACTIVE_BUTTON_COLOR};
    border: 1px solid ${POPUP_BLOCK_BACKGROUND_COLOR};
  }
  .submit-button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_BACKGROUND_COLOR};
  }
  .nextArrow {
    color: ${POPUP_BACKGROUND_COLOR};
    width: 17.33px;
    height: 17.33px;
  }
  .buttonText {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_TEXT_COLOR};
  }
`;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: EXPANSIONPANEL_TEXT
    }
  }
};
const subjectList = [
  'Request Update / Information',
  'Connectivity Service Problem',
  'Equipment Problem / Failure',
  'Software Update',
  'Fault Log Review',
  'On-Wing Technical Support',
  'Remote Technical Support',
  'Antenna Peaking Support',
  'Antenna Calibration Support'
];

interface IssueDetailsPopupProps {
  aircraftDetail: any;
  phoneNumber: string;
  closeModal: () => void;
  formValues: IFormValues;
  setFormValues: (data: IFormValues) => void;
  requestStatus: IRequestStatus;
  sendEmail: () => void;
}

const IssueDetailsPopup: React.FC<IssueDetailsPopupProps> = ({
  phoneNumber,
  aircraftDetail,
  closeModal,
  setFormValues,
  sendEmail,
  requestStatus
}) => {
  const theme = useTheme();
  const [subjectString, setSubjectString] = useState<string>('');
  const [submitReq, setSubmitReq] = useState<boolean>(false);
  const [refLink, setRefLink] = useState<string>('');
  const [validateSubject, setValidateSubject] = useState<boolean>(true);
  const handleSubjectChange = (event: SelectChangeEvent<string>) => {
    const {
      target: {value}
    } = event;
    setSubjectString(value);
    setValidateSubject(true);
  };

  const handleRefLinkInput = (e: any) => {
    const {value} = e.target;
    setRefLink(value);
  };
  const [fields, errors, form] = useFormInputValidation(
    {
      description: '',
      ref_link: ''
    },
    {
      description: 'required',
      ref_link: 'optional'
    }
  );
  form.useLang(Lang.en);

  const handleSubmitClick = async (event) => {
    const isValid = await form.validate(event);
    subjectString ? setValidateSubject(true) : setValidateSubject(false);
    if (isValid && validateSubject) {
      setFormValues({
        aircraftDetail: aircraftDetail,
        subject: subjectString,
        description: fields['description'],
        referenceLink: refLink,
        requestorPhoneNumber: phoneNumber
      });
      setSubmitReq(true);
    }
  };
  useEffect(() => {
    if (submitReq) {
      sendEmail();
      setTimeout(() => {
        setSubmitReq(false);
      }, 500);
    }
    //eslint-disable-next-line
  }, [submitReq]);

  return (
    <IssueDetailsPopupContainer>
      <PopupInnerContainer>
        <CloseIcon className="close-icon" onClick={closeModal} />
        <div className="head-text">Request Service from Viasat MCC</div>
        <Form id="form" onSubmit={handleSubmitClick}>
          <Row>
            <div className="label">Aircraft SN *</div>
            <div className="label">Tail ID *</div>
          </Row>
          <InputRow>
            <StyledInput
              id="serial-number"
              type="text"
              name="serialNumber"
              onBlur={form.handleBlurEvent}
              onChange={form.handleChangeEvent}
              defaultValue={aircraftDetail.serialNumber}
              readOnly={true}
            />
            <StyledInput
              id="tail-id"
              type="text"
              name="tailId"
              onBlur={form.handleBlurEvent}
              onChange={form.handleChangeEvent}
              defaultValue={aircraftDetail.tailId}
              readOnly={true}
            />
          </InputRow>
          <div className="input-text">Subject *</div>
          <Select
            className="subject-menu"
            name="subject"
            displayEmpty
            value={subjectString}
            onChange={handleSubjectChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <div>Select Options</div>;
              }

              return selected;
            }}
            MenuProps={MenuProps}
            inputProps={{'aria-label': 'Without label'}}
          >
            {subjectList.map((subject) => (
              <MenuItem
                key={subject}
                value={subject}
                style={{
                  fontWeight:
                    subjectString === subject ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
                }}
              >
                {subject}
              </MenuItem>
            ))}
          </Select>
          <label className="error">{!validateSubject ? 'The Subject is required' : ''}</label>
          <div className="input-text">Description *</div>
          <StyledInputDesc
            id="description"
            placeholder="Please add a detailed description of the issue to assist the MCC in trobleshooting. Including specific flight information (time, date, departure and arrival locations)."
            onBlur={form.handleBlurEvent}
            onChange={form.handleChangeEvent}
            name="description"
            value={fields['description']}
          />
          <label className="error">{errors['description'] ? errors['description'] : ''}</label>
          <div className="input-text">Reference Link</div>
          <StyledInput
            id="reference-link"
            type="text"
            name="refLink"
            placeholder="If applicable, please insert share link for the affected flight here."
            defaultValue={`https://insights.viasat.com/bizav/flight/details/${aircraftDetail.flightId}`}
            onChange={(e) => handleRefLinkInput(e)}
          />
          <FormActions>
            <div className="action-text">
              For immediate assistance, please call Viasat MCC @
              <span style={{fontWeight: 'bold'}}> 1-866-VSAT-AIR</span>
            </div>
            <button className="cancel-button" onClick={closeModal}>
              <span className="buttonText">Cancel</span>
            </button>
            <button className="submit-button" type="submit">
              <span className="submit-button-text">
                Submit &nbsp; {requestStatus.isSubmitting ? <CircularProgress size={16} color="inherit" /> : <></>}
              </span>
            </button>
          </FormActions>
        </Form>
      </PopupInnerContainer>
    </IssueDetailsPopupContainer>
  );
};

export default IssueDetailsPopup;
