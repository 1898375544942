/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Save Departure Time component
 */

import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import SyncIcon from '@mui/icons-material/Sync';
import ToastIcon from '@mui/icons-material/CheckCircleOutline';
import {isNil} from 'lodash';
import {useStore} from '../../store/Store';
import * as StyledElms from './ConnectivityOutlookStyles';
import {bizavApiUrl} from '../../utils/config';
import {CancelTokenAction} from '../../store/reducers/CancelTokenReducer';
import {ConnectivityPlannerAction} from '../../store/reducers/ConnectivityPlannerReducer';
import {SaveDepartureTimeProps, FileUploadIndicator} from './SaveDepartureTimeStyles';
import DialogAlertPopup from '../common/DialogAlertPopup';
import {getElementIdFromSectionBase} from '../../utils/ElementIdUtils';
import {ALERT_DIALOG_LEFT_BORDER} from '../common/theme/Colors';
import {DepartureTimeChange} from './ConnectivityOutlookUtils';
import StyledButton from '../common/StyledButton';

export const SaveDepartureTime: React.FC<SaveDepartureTimeProps> = ({
  disabled,
  idBase,
  isDepartureTimeChanged,
  setIsDepartureTimeChanged
}: SaveDepartureTimeProps) => {
  const {store, dispatch} = useStore();
  const {flightPlanInfo, isShowSaveModal} = store.connectivityPlanner;
  const groupCode = store.customer.current.code;
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(disabled);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const intl = useIntl();

  interface FlightPlanDetails {
    flightPlanNumber: string;
    endUser?: string;
    serialNumber: string;
    tailId: string;
    departureAirport: string;
    departureTime: string;
    departureTimeLocal: string;
    destinationAirport: string;
    arrivalTime: string;
    arrivalTimeLocal: string;
    totalElapsedTime: string;
    networkType: string;
    uploadedTstamp: string;
    id: string;
    eventsCount: number;
    isOverwrite: boolean;
  }
  /**
   * Get the value for internalization of the content shown
   * @param text Value from the en json to identify the corresponding text
   */
  const getIntlContent = (text: string) => {
    const initialSubject = 'dialog_alert_popup.save_departure_time.';
    return intl.formatMessage({id: initialSubject + text});
  };
  /**
   * Sets the button to open to show the save popup
   * @param open Modified Departure time from the date picker
   */
  const openSavePopup = (open: boolean, $event: any) => {
    setIsSaveDialogOpen(open);
    setIsSaveDisabled(true);
  };
  /**
   * Closes the save popup after any action taken by the user
   */
  const closeSavePopup = (isSaved: boolean, isSaving: boolean) => {
    setIsSaveDialogOpen(false);
    dispatch({
      type: ConnectivityPlannerAction.SHOW_SAVE_MODAL,
      payload: false
    });
    setIsSaveDisabled(isSaved);
    setIsSaving(isSaving);
  };
  /**
   * On save/overwrite construct the request for the flight details to be saved
   * @param overwrite Flag to check whether the operation is update or insert in DB
   */
  const OnSaveFlightPlan = (overwrite: boolean) => {
    if (!isNil(flightPlanInfo) && flightPlanInfo.length) {
      setIsSaving(true);
      closeSavePopup(true, true);
      const flightPlansToSave = flightPlanInfo.filter(
        (flightPlan) =>
          flightPlan.isDepartureTimeChanged && flightPlan.isDepartureTimeChanged !== DepartureTimeChange.NO_CHANGE
      );
      const flightPlanDetails: FlightPlanDetails[] = JSON.parse(JSON.stringify(flightPlansToSave));
      flightPlanDetails.forEach((flightPlan, index) => {
        flightPlan['flightPlanNumber'] = flightPlan['flightPlanIdentifier'];
        delete flightPlan['flightPlanIdentifier'];
        flightPlan['routes'] = flightPlan['route'];
        delete flightPlan['route'];
        flightPlan['flightPlanId'] = flightPlan['id'];
        flightPlan['isOverwrite'] = overwrite;
        const copyCountCounter = flightPlanInfo[index] ? flightPlanInfo[index]['copyCount'] : 0;
        flightPlan['copyCount'] = copyCountCounter + 1;
      });
      saveFlightPlan(flightPlanDetails);
    }
  };
  /**
   * Save the edited departure time to the db based on the option(overwrite or savecopy)
   * @param flightPlanDetails The data to be inserted/updated in the db
   */
  const saveFlightPlan = async (flightPlanDetails) => {
    if (!isNil(flightPlanDetails)) {
      const sourceOfCancelToken = axios.CancelToken.source();
      dispatch({
        type: CancelTokenAction.SET_CANCEL_TOKEN_SOURCE,
        payload: {id: 0, token: sourceOfCancelToken}
      });
      const data = {flightPlans: flightPlanDetails, groupCode: groupCode};
      const config = {
        headers: {Authorization: `Bearer ${localStorage.token}`},
        cancelToken: sourceOfCancelToken.token
      };
      try {
        const addFlightPlanResponse = await axios.post(
          `${bizavApiUrl}/connectivityPlanner/addFlightPlan`,
          data,
          config
        );
        if (addFlightPlanResponse) {
          dispatch({
            type: ConnectivityPlannerAction.SAVE_FLIGHT_PLAN_SUCCESS,
            payload: 'Flight plan(s) saved.'
          });
          dispatch({
            type: ConnectivityPlannerAction.REFRESH_FLIGHT_PLAN_LIST,
            payload: true
          });
          flightPlanInfo.map((flightPlan) => (flightPlan.isDepartureTimeChanged = DepartureTimeChange.NO_CHANGE));
          dispatch({
            type: ConnectivityPlannerAction.SET_FLIGHT_PLAN_DATA,
            payload: flightPlanInfo
          });
          setIsDepartureTimeChanged(false);
          closeSavePopup(true, false);
          return true;
        }
      } catch (error) {
        if (error?.message !== 'cancel') {
          dispatch({
            type: ConnectivityPlannerAction.SAVE_FLIGHT_PLAN_ERROR,
            payload: 'Unable to save the flight plan.'
          });
          setIsDepartureTimeChanged(true);
          closeSavePopup(false, false);
          return false;
        }
        closeSavePopup(false, false);
        return;
      }
    }
  };
  /**
   * To enable/disable the save button based on the departure time changed in parent
   */
  useEffect(() => {
    setIsSaveDisabled(disabled);
  }, [disabled]);

  /**
   * To set the departure time change to reflect the modification in the parent component
   */
  useEffect(() => {
    setIsDepartureTimeChanged(isDepartureTimeChanged);
  }, [isDepartureTimeChanged, setIsDepartureTimeChanged]);

  /**
   * To enable/disable the loading button based on the departure time saved in parent
   */
  useEffect(() => {
    setIsSaving(isSaving);
  }, [isSaving]);

  /**
   * To auto show the save popup based on the user navigation
   */
  useEffect(() => {
    setIsSaveDialogOpen(isShowSaveModal);
  }, [isShowSaveModal]);

  /**
   * This will return Alert box buttons to perform some actions like overwrite/save copy
   */
  const DialogAlertBoxActions = () => {
    return (
      <>
        <button
          id={getElementIdFromSectionBase(idBase, 'overwrite', '0')}
          className="left-button"
          onClick={() => OnSaveFlightPlan(true)}
        >
          {getIntlContent('overwrite_button_text')}
        </button>
        <button
          id={getElementIdFromSectionBase(idBase, 'save-copy', '0')}
          className="right-button"
          onClick={() => OnSaveFlightPlan(false)}
        >
          {getIntlContent('save_copy_button_text')}
        </button>
      </>
    );
  };
  return (
    <>
      <StyledButton
        key="save-button"
        id={getElementIdFromSectionBase(idBase, 'save-button', '0')}
        onClick={($event: any) => {
          if (!isSaveDisabled) openSavePopup(!isSaveDialogOpen, $event);
        }}
        disabled={isSaveDisabled || isSaving}
      >
        {isSaving ? (
          <FileUploadIndicator key="loading-indicator-1">
            <SyncIcon className="file-upload-sync" />
          </FileUploadIndicator>
        ) : (
          <></>
        )}
        <SaveIcon sx={{fontSize: 24}} />
        <StyledElms.PrintText>Save</StyledElms.PrintText>
      </StyledButton>
      <DialogAlertPopup
        idBase={idBase}
        id={getElementIdFromSectionBase(idBase, 'save-modal', '0')}
        trigger={isSaveDialogOpen}
        heading={getIntlContent('alert_heading')}
        message={getIntlContent('alert_message')}
        onClose={() => closeSavePopup(false, false)}
        dialogAlertBoxActions={<DialogAlertBoxActions />}
        dialogBoxHeight={180}
        dialogMessageHeight={48}
        dialogColor={ALERT_DIALOG_LEFT_BORDER}
        dialogAlertIcon={<ToastIcon style={{color: ALERT_DIALOG_LEFT_BORDER, paddingRight: '5px'}} />}
      />
    </>
  );
};
export default SaveDepartureTime;
