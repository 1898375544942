/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Action Items List component
 */

import styled from '@emotion/styled';
import {InsertChartOutlined, SupportAgent} from '@mui/icons-material';
import PageviewIcon from '@mui/icons-material/Pageview';
import {PopperProps} from '@mui/material';
import {PopperWrapper} from '@viasat/insights-components';
import React from 'react';
import {showTailSummaryColumn} from '../../../utils/config';
import {FLIGHT_EVENT_COLOR, POPUP_BACKGROUND_COLOR, POPUP_TEXT_COLOR} from '../../common/theme/Colors';

const ActionItemsListContainer = styled.div<{top: string; bottom: string; right: number}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  position: fixed;
  width: 200px;
  min-height: 110px;
  height: auto;
  right: ${(props) => -props.right - 150}px;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  background: ${POPUP_BACKGROUND_COLOR};
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

const StyledActionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left:16px;
  gap: 8px;
  width: 140px;
  height: 36px;
  background: ${POPUP_BACKGROUND_COLOR};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const StyledItemText = styled.div`
  width: 140px;
  height: 20px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${POPUP_TEXT_COLOR};
  flex: none;
  order: 1;
  flex-grow: 1;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
/**
 * This is used to get icon click position and check the popup is fully visible on the viewport
 *
 * @param clientY px number
 * @returns popup visibility
 */
export const getPopupVisibility = (clientY: number) => {
  const isInViewPort = clientY + 208 < window.innerHeight;
  return isInViewPort;
};

interface ActionItemsListProps {
  selectedAircraft: any;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  popupPosition: {positionX: number; positionY: number};
  buttonAnchorEl: PopperProps['anchorEl'];
  handleViewFlightDetailsClick: (rowData: any) => void;
  handleSummaryMetricsLinkClick: (rowData: any) => void;
  handleOpenCasesClick: (rowData: any) => void;
  handleSupportRequestClick: (rowData: any) => void;
}
const ActionItemsList: React.FC<ActionItemsListProps> = ({
  selectedAircraft,
  openModal,
  setOpenModal,
  popupPosition,
  buttonAnchorEl,
  handleViewFlightDetailsClick,
  handleSummaryMetricsLinkClick,
  handleOpenCasesClick,
  handleSupportRequestClick
}) => {
  const resetPopUp = () => {
    setOpenModal(false);
  };
  const isActionPopupVisible = getPopupVisibility(popupPosition.positionY);
  return (
    <>
      {openModal ? (
        <PopperWrapper
          open={openModal}
          placement="bottom-end"
          anchorElement={buttonAnchorEl}
          onExternalClick={($event: any) => resetPopUp()}
          transition
        >
          <ActionItemsListContainer
            id="ActionListContainer"
            top={isActionPopupVisible ? `${popupPosition.positionY}px` : 'unset'}
            bottom={!isActionPopupVisible ? `-${popupPosition.positionY - 40}px` : 'unset'}
            right={popupPosition.positionX}
          >
            <StyledActionItem
              id="ActionListContainer__viewFlightDetails"
              onClick={() => handleViewFlightDetailsClick(selectedAircraft)}
            >
              <PageviewIcon sx={{color: FLIGHT_EVENT_COLOR, width: '20px', height: '20px'}} />
              <StyledItemText>View Flight Details</StyledItemText>
            </StyledActionItem>
            {showTailSummaryColumn ? (
              <StyledActionItem
                id="ActionListContainer__viewSummaryMetrics"
                onClick={() => handleSummaryMetricsLinkClick(selectedAircraft)}
              >
                <InsertChartOutlined sx={{color: FLIGHT_EVENT_COLOR, width: '20px', height: '20px'}} />
                <StyledItemText>View Summary Metrics</StyledItemText>{' '}
              </StyledActionItem>
            ) : (
              <></>
            )}
            <StyledActionItem
              id="ActionListContainer__viewRequestSupport"
              onClick={() => handleSupportRequestClick(selectedAircraft)}
            >
              <SupportAgent sx={{color: FLIGHT_EVENT_COLOR, width: '20px', height: '20px'}} />
              <StyledItemText>Request Support</StyledItemText>{' '}
            </StyledActionItem>
          </ActionItemsListContainer>
        </PopperWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActionItemsList;
