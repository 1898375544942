/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight Information
 */

import React, {useState} from 'react';
import styled from '@emotion/styled';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {isNil} from 'lodash';

import FlightDetailsExpansion, {AccordionBodyItemType, AccordionSection} from './FlightDetailsExpansion';
import LeftContainerCard from './LeftContainerCard';
import {
  formatMomentInput,
  TIME_WITH_HR_MIN_FORMAT,
  DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT
} from '../../utils/DateTimeUtils';
import {timeDuration} from './flightDetailsUtil';
import {splitString} from '../../utils/MapUtil';
import {FlightPhase} from '../../utils/constants';

const LastUpdatedTimeContainer = styled.span`
  color: #006ead;
`;
/**
 * Apply style to the time in the date format
 * @param date date
 * @returns Formatted date and time with bold text.
 */
const getTime = (date: string) => {
  return !isNil(date) ? (
    <>
      {date.split(' ')[0]} <b>{date.split(' ')[1]}</b>
    </>
  ) : (
    '--'
  );
};

/**
 * Formats the last updated time to match the UX style
 * @param time Last updated time for styling
 */
const formatLastUpdatedTime = (time: string) => {
  return time ? (
    <>
      <LastUpdatedTimeContainer>{time}</LastUpdatedTimeContainer>
    </>
  ) : null;
};

export interface FlightInformationProps {
  width: number;
  gutter: number;
  isLoading: boolean;
  origin: string;
  destination: string;
  flightStart: string;
  flightEnd: string;
  flightDuration: string;
  flightPhase: string;
  connectedStartTime: string;
  connectedEndTime: string;
  isHistorical: boolean;
  flightDetected: boolean;
  connectedTime: string;
  airports: any;
  isDark: boolean;
}

const FlightInformation: React.FC<FlightInformationProps> = (props) => {
  const {
    width,
    gutter,
    isLoading,
    origin,
    destination,
    flightStart,
    flightEnd,
    flightDuration,
    flightPhase = FlightPhase.ON_GROUND,
    connectedStartTime,
    connectedEndTime,
    isHistorical,
    flightDetected,
    connectedTime,
    airports,
    isDark
  } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const cardSections: AccordionSection[] = isDark
    ? [
        {
          type: AccordionBodyItemType.Grid,
          items: [
            {
              name: 'Origin',
              value: origin ? origin : '--',
              airport: origin ? airports?.[origin] : null
            },
            {
              name: 'Destination',
              value: destination ? destination : '--',
              airport: destination ? airports?.[destination] : null
            },
            {
              name: 'Flight Start',
              value: getTime(
                formatMomentInput(splitString(flightStart, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            },
            {
              name: 'Flight End',
              value: getTime(
                formatMomentInput(splitString(flightEnd, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            },
            {
              name: 'Flight Duration',
              value: timeDuration(
                formatMomentInput(splitString(flightEnd, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT),
                formatMomentInput(splitString(flightStart, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            }
          ],
          columnsPerRow: 1
        }
      ]
    : isHistorical && !flightDetected
    ? [
        {
          type: AccordionBodyItemType.Grid,
          items: [
            {
              name: 'Connected Start',
              value: getTime(
                formatMomentInput(splitString(connectedStartTime, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            },
            {
              name: 'Connected End',
              value: getTime(
                formatMomentInput(splitString(connectedEndTime, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            },
            {
              name: 'Connected Time',
              value: connectedTime
            }
          ],
          columnsPerRow: 1
        }
      ]
    : isHistorical && flightDetected
    ? [
        {
          type: AccordionBodyItemType.Grid,
          items: [
            {
              name: 'Origin',
              value: origin ? origin : '--',
              airport: origin ? airports?.[origin] : null
            },
            {
              name: 'Destination',
              value: destination ? destination : '--',
              airport: destination ? airports?.[destination] : null
            },
            {
              name: 'Flight Start',
              value: getTime(
                formatMomentInput(splitString(flightStart, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            },
            {
              name: 'Flight End',
              value: getTime(
                formatMomentInput(splitString(flightEnd, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            },
            {
              name: 'Flight Duration',
              value: flightDuration || '--'
            }
          ],
          columnsPerRow: 1
        }
      ]
    : [
        {
          type: AccordionBodyItemType.Grid,
          items: [
            {
              name: 'Phase of Flight',
              value: flightPhase
            },
            {
              name: 'Origin',
              value: origin ? origin : '--',
              airport: origin ? airports?.[origin] : null
            },
            {
              name: 'Destination',
              value: destination ? destination : '--',
              airport: destination ? airports?.[destination] : null
            },
            {
              name: 'Flight Start',
              value:
                isNil(flightEnd) && isNil(flightStart) && flightPhase === FlightPhase.ON_GROUND
                  ? 'Not Started'
                  : getTime(
                      formatMomentInput(splitString(flightStart, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
                    )
            },
            {
              name: 'Flight End',
              value: getTime(
                formatMomentInput(splitString(flightEnd, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            },
            {
              name: 'Flight Duration',
              value: timeDuration(
                formatMomentInput(splitString(flightEnd, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT),
                formatMomentInput(splitString(flightStart, '.', 0), DATE_TIME_VERBOSE_NO_SECONDS_LOCAL_FORMAT)
              )
            },
            {
              name: 'Last Updated',
              value: formatLastUpdatedTime(
                formatMomentInput(splitString(connectedEndTime, '.', 0), TIME_WITH_HR_MIN_FORMAT)
              )
            }
          ],
          columnsPerRow: 1
        }
      ];

  return (
    <LeftContainerCard id="flight-information" width={width} height={528} gutter={gutter} isLoading={isLoading}>
      <FlightDetailsExpansion
        panelId="flightInformation"
        title={
          !isDark && isHistorical && !flightDetected ? 'Session Information' : 'Flight Information'
        }
        Icon={FlightTakeoffIcon}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sections={cardSections}
      />
    </LeftContainerCard>
  );
};

export default FlightInformation;
