/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Footer component
 */

import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

const StyledFooter = styled.footer`
  grid-area: footer;
  background-color: #dadada;
  box-shadow: none !important;
  padding: 0px 10px 0px 10px;
  transition: padding 100ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  z-index: 2;
  height: 17px !important;
  width: 135px !important;
  position: absolute;
  top: 99%;
  left: 50%;
  text-align: right;
  font-size: 11px;
  transform: translate(-50%, -50%);
`;

const Footer = () => {
  const copyrightYear = moment().utc().year();

  return <StyledFooter>Copyright &copy; {copyrightYear} Viasat Inc.</StyledFooter>;
};

export default Footer;
