/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight ID Card
 */
import {Tooltip, TooltipProps} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import React, {useState} from 'react';
import styled from '@emotion/styled';
import {BLACK, FLIGHT_DETAILS_LIVE_BG_COLOR, GREY, WHITE, CONFIG_DIVIDER_BG} from '../common/theme/Colors';
import LeftContainerCard from './LeftContainerCard';

const HEIGHT = 300;
const COPY_TEXT = 'Copy';

const InnerContainer = styled.div`
  border: 1px solid ${CONFIG_DIVIDER_BG};
  border-radius: 4px;
  width: 100%;
  background: ${FLIGHT_DETAILS_LIVE_BG_COLOR};
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;

const StyledCopyContainer = styled.div`
  margin-left: auto;
`;

const StyledCopyIcon = styled(CopyIcon)`
  font-size: 18px;
  cursor: pointer;
  margin-right: 12px;
`;

const FlightIdContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  margin: 4px 0 12px;
`;

const LabelContainer = styled.div`
  margin: 12px 0 0 16px;
  font-size: 12px;
  color: ${GREY};
`;

const FlightIdTextContainer = styled.div`
  margin-left: 16px;
`;

const copyTooltipProps: TooltipProps = {
  title: '',
  children: <></>,
  componentsProps: {
    tooltip: {
      sx: {
        color: WHITE,
        backgroundColor: BLACK,
        marginBottom: '-16px',
        marginLeft: '-12px',
        fontFamily: "'Source Sans Pro', sans-serif"
      }
    },
    arrow: {
      sx: {
        color: BLACK,
      }
    }
  }
};

interface FlightIdCardProps {
  width: number;
  gutter: number;
  flightId: string;
  isLoading: boolean;
}

const FlightIdCard: React.FC<FlightIdCardProps> = ({width, gutter, flightId, isLoading}) => {
  const [copyTooltipText, setCopyTooltipText] = useState(COPY_TEXT);

  const handleCopyClick = () => {
    try {
      const textArea: any = document.createElement('textArea');
      textArea.value = flightId;
      document.body.appendChild(textArea);
      if (navigator.userAgent.match(/ipad|iphone/i)) {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setCopyTooltipText('Copied!');
    } catch (e) {}
  };

  return (
    <LeftContainerCard id="flight-id" width={width} height={HEIGHT} gutter={gutter} isLoading={isLoading}>
      <InnerContainer>
        <LabelContainer>FLIGHT ID</LabelContainer>
        <FlightIdContainer>
          <FlightIdTextContainer>{flightId}</FlightIdTextContainer>
          <StyledCopyContainer id="copyFlightId" onClick={handleCopyClick}>
            <Tooltip
              {...copyTooltipProps}
              placement="top"
              arrow
              disableFocusListener
              disableTouchListener
              enterDelay={250}
              onClose={() => {
                if (copyTooltipText !== COPY_TEXT) {
                  setTimeout(() => setCopyTooltipText(COPY_TEXT), 100);
                }
              }}
              title={
                <React.Fragment>
                  <div style={{lineHeight: '24px', margin: '8px 10px', fontSize: '12px', fontWeight: 400}}>
                    {copyTooltipText}
                  </div>
                </React.Fragment>
              }
            >
              <span>
                <StyledCopyIcon />
              </span>
            </Tooltip>
          </StyledCopyContainer>
        </FlightIdContainer>
      </InnerContainer>
    </LeftContainerCard>
  );
};

export default FlightIdCard;
