/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Base index
 */
import './utils/MuiClassNameSetup';
import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from '@mui/material/styles';
import {Global} from '@emotion/react';
import * as FullStory from '@fullstory/browser';
import {QueryClientProvider} from '@tanstack/react-query';

import {StoreProvider} from './store/Store';
import {Reducer} from './store/Reducer';
import AppProviders from './AppProviders';
import OuterAppWrapper from './OuterAppWrapper';
import theme from './AppTheme';
import * as serviceWorker from './serviceWorker';
import globalStyle from './components/common/theme/GlobalStyle';
import {DEV_ENVIRONMENT, environment, fullStoryOrgId} from './utils/config';
import {queryClient} from './utils/useFetchV2';

const inputGlobalStyles = <Global styles={globalStyle} />;

const devMode = environment === DEV_ENVIRONMENT;
FullStory.init({orgId: fullStoryOrgId, recordCrossDomainIFrames: true, devMode});
console.info(`Environment: ${environment}, devMode: ${devMode}`);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AppProviders>
      {inputGlobalStyles}
      <QueryClientProvider client={queryClient}>
        <StoreProvider reducer={Reducer}>
          <OuterAppWrapper />
        </StoreProvider>
      </QueryClientProvider>
    </AppProviders>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
