/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Styles specific to the reusable common popup component
 */

import styled from '@emotion/styled';
import {MODAL_BG, POPUP_TEXT_COLOR} from './theme/Colors';

export const PopupBox = styled.div<{boxHeight: number; messageHeight: number; boxColor: string}>`
  position: fixed;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0px 0px;
  border-left-width: 12px;
  width: 313px;
  max-height: ${(props) => props.boxHeight}px;
  z-index: 2;
  border: 1px solid ${(props) => props.boxColor};
  background-color: ${MODAL_BG};
  border-left-color: ${(props) => props.boxColor};
  border-left-width: 12px;
  top: 20px;
  right: 10px;
  font-family: 'Source Sans Pro';

  .popup-envelop {
    position: relative;
    & > div {
      padding: 8px 0px;
    }
    .popup-heading {
      height: 23px;
      width: 300px;
      color: ${POPUP_TEXT_COLOR};
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.1px;
      line-height: 23px;
    }
    .popup-message {
      width: 219px;
      max-height: ${(props) => props.messageHeight}px;
      font-size: 16px;
      line-height: 24px;
      color: #465967;
      padding-left: 25px;
    }

    .popup-buttons {
      padding-left: 6px;
    }
    .left-button {
      justify-content: center;
      align-items: center;
      min-width: 101px;
      height: 32px;
      border: 1px solid ${POPUP_TEXT_COLOR};
      box-sizing: border-box;
      border-radius: 1000px;
      margin: 0px 16px;
      background: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
    .right-button {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-decoration-line: underline;
      color: ${POPUP_TEXT_COLOR};
      border: none;
      background: none;
      cursor: pointer;
    }
  }
`;

export interface DialogAlertPopupProps {
  idBase: string;
  id: string;
  trigger: boolean;
  heading: string;
  message: string;
  onClose: () => void;
  dialogAlertBoxActions: any;
  dialogBoxHeight: number;
  dialogMessageHeight: number;
  dialogColor: string;
  dialogAlertIcon: any;
}
