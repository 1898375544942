/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: FlightListPage component
 */

import React, {useMemo, useEffect, useState} from 'react';
import {DatePickerState, HorizontalTabs, ListGridColumn} from '@viasat/insights-components';

import ListViewComponent from '../ListViewComponent';
import {EID_FLIGHTLIST} from '../../../utils/constants';
import {useStore} from '../../../store/Store';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {FlightListAction, FLIGHT_LIST_STORE_CONTEXT} from '../../../store/reducers/FlightListReducer';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import flightListQuery, {FlightInfo} from '../../../store/queries/flightList/flightListQuery';
import flightListTotalQuery, {FlightListTotalType} from '../../../store/queries/flightList/flightListTotalQuery';
import endUsersQuery, {EndUser} from '../../../store/queries/common/endUsersQuery';
import servicePlansQuery, {servicePlans} from '../../../store/queries/common/servicePlansQuery';
import {
  useSortingParams,
  useFilteringParams,
  useQueryInputsDateRange,
  useLimitParam
} from '../../../store/queries/queryUtils';
import useFetch from '../../../utils/useFetch';
import {
  buildFlightListColumns,
  FlightListColumnId,
  getFlightListFilters,
  getQueryFieldMapping,
  QUERY_FIELD_MAPPING
} from './flightListUtil';
import tabDefinitions from '../tabDefinitions';
import tailInfoQuery, {TailInfo} from '../../../store/queries/flightList/tailInfoQuery';
import {getFilterOptions} from '../../../utils/filterUtils';
import {FilterAction} from '../../../store/reducers/FiltersReducer';
import {DatePickerAction} from '../../../store/reducers/DatePickerReducer';
import {AirportsType} from '../../../utils/MapUtil';
import airportsQuery from '../../../store/queries/common/airportsQuery';
import {AppAction} from '../../../store/reducers/AppReducer';
import {handleExportClick} from '../exportListCSV';
import {IHandleSortProps} from '../listUtils';

export interface FlightListPageProps {}

const MAX_FLIGHTS_DISPLAYED = 3000;

const maxRowNotificationMessage = (
  <span>
    We've displayed the <strong>first {MAX_FLIGHTS_DISPLAYED} flights</strong> on the list. You can refine your search
    with additional <strong>filters</strong> or download the full results as a <strong>CSV</strong> file.
  </span>
);

const FlightListPage: React.FC<FlightListPageProps> = () => {
  const [validTabs] = useState(tabDefinitions);
  const {store, dispatch} = useStore();
  const datePickerState = store.datePicker;
  const {airports} = store.app;
  const {isValueAddedReseller, isInternal} = store.init;
  const {
    sort,
    filters: {filters = []},
    hideColumns: {hiddenColumns, sharelinkHiddenColumns},
    showFlightListNotification,
    flightListTotal = 0
  } = store.flightList;

  // if sharelink hidden columns are populated in session storage, then use, otherwise use hidden columns from local storage
  const currentHiddenColumns = sharelinkHiddenColumns ? sharelinkHiddenColumns : hiddenColumns;

  const queryParams = useQueryInputsDateRange(
    store.customer.current.code,
    datePickerState.startDate,
    datePickerState.endDate
  );

  // Prerequisite queries

  // End Users
  const {data: endUsersData, isLoading: isEndUsersLoading} = useFetch<EndUser[]>(endUsersQuery, queryParams);
  const isEndUser = endUsersData && endUsersData.length === 0;

  // Tail Info
  const {data: tailInfoData, isLoading: isTailInfoLoading} = useFetch<TailInfo[]>(tailInfoQuery, queryParams);
  const {data: servicePlansData, isLoading: isServicePlansLoading} = useFetch<servicePlans[]>(
    servicePlansQuery,
    queryParams
  );

  const arePrereqsLoading = isEndUsersLoading || isTailInfoLoading || isServicePlansLoading; // Add other prereq loading states here

  // Main queries

  // To prevent extra queries, leave things as undefined until all prereqs are done
  const flightListFilters = useMemo(
    () => (arePrereqsLoading ? undefined : getFlightListFilters(isEndUser, isValueAddedReseller || isInternal)),
    [arePrereqsLoading, isEndUser, isInternal, isValueAddedReseller]
  );

  const queryParamsWithFiltering = useFilteringParams(queryParams, flightListFilters, filters);

  const queryParamsWithSorting = useSortingParams(queryParamsWithFiltering, sort.queryField, sort.order);

  const queryParamsWithLimit = useLimitParam(queryParamsWithSorting, MAX_FLIGHTS_DISPLAYED);

  const emptyParams = useMemo(() => ({}), []);
  const [flightListData, setFlightListData] = useState(null);

  // Airports
  const {data: airportsList, isLoading: isAirportsLoading} = useFetch<AirportsType>(airportsQuery, emptyParams);

  useEffect(() => {
    if (isAirportsLoading) return;
    if (airportsList && !isAirportsLoading) {
      dispatch({
        type: AppAction.SET_AIRPORTS,
        payload: {
          airports: airportsList
        }
      });
    }
  }, [dispatch, airportsList, isAirportsLoading]);

  // Flight List
  const {data: flightsData, isLoading: isFlightListLoading} = useFetch<FlightInfo[]>(
    flightListQuery,
    queryParamsWithLimit
  );

  useEffect(() => {
    if (isFlightListLoading || isAirportsLoading) return;
    const flightsList = flightsData.map((flight: FlightInfo) => {
      return {
        ...flight,
        origin: {
          airportData: airports ? airports[flight.origin] : undefined,
          code: flight.origin
        },
        destination: {
          airportData: airports ? airports[flight.destination] : undefined,
          code: flight.destination
        }
      };
    });

    setFlightListData(flightsList);
  }, [isFlightListLoading, flightsData, airportsList, isAirportsLoading, airports]);

  // Flight List Total
  const {data: totalFlightsData, isLoading: isFlightListTotalLoading} = useFetch<FlightListTotalType>(
    flightListTotalQuery,
    queryParamsWithLimit
  );

  const isLoading = arePrereqsLoading || isFlightListTotalLoading || isFlightListLoading;

  const flightListColumns = buildFlightListColumns(isEndUser);

  // useEffect Purpose: Update the options available for filtering each of the filterable columns
  useEffect(() => {
    if (tailInfoData && endUsersData && flightListFilters && airports) {
      const {domainOptions, rangeOptions} = getFilterOptions(
        {tailInfoData, endUsersData, airportsData: airports, servicePlansData},
        flightListFilters
      );
      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, FLIGHT_LIST_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [tailInfoData, endUsersData, dispatch, flightListFilters, airports, servicePlansData]);

  // Validates/updates column sort fields - waits for end user status
  useEffect(() => {
    handleSortChange({column: sort.column, order: sort.order});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndUser]);

  /**
   * Handles the Sorting actions on the list view
   * @param param0 IHandleSortProps with column to sort and the respective sort order - asc/desc
   */
  const handleSortChange = ({column, order}: IHandleSortProps) => {
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, FLIGHT_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: QUERY_FIELD_MAPPING[column],
        validColumns: flightListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: flightListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(FlightListColumnId[col.key])
        )
      }
    });
  };

  // Prevents flight max notification from disappearing and reappearing while sorting
  useEffect(() => {
    if (totalFlightsData !== null && totalFlightsData !== flightListTotal) {
      dispatch({type: FlightListAction.SET_FLIGHT_LIST_TOTAL, payload: totalFlightsData});
    }
  }, [totalFlightsData, flightListTotal, dispatch]);

  const setShowFlightListNotification = (show: boolean) => {
    dispatch({
      type: FlightListAction.SET_SHOW_FLIGHT_LIST_NOTIFICATION,
      payload: show
    });
  };

  const showNotification = showFlightListNotification && MAX_FLIGHTS_DISPLAYED < flightListTotal;

  const TabsComponent = (
    <HorizontalTabs
      getFullElementId={(name, type) => `${EID_FLIGHTLIST}--listTabs__${name}-${type}`}
      tabs={(validTabs || []).filter(({disabled}) => !disabled)}
      titleCount={isLoading ? undefined : totalFlightsData}
    />
  );

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(FlightListColumnId[columnId]);

  const [downloadProgressPercentage, setDownloadProgressPercentage] = useState(0);
  const clamp = (n: number, min: number, max: number) => Math.max(Math.min(n, max), min);
  const [timerIndex, setTimerIndex] = useState(-1);
  const clearIndex = () => {
    if (timerIndex > -1) {
      clearTimeout(timerIndex);
      setTimerIndex(-1);
    }
  };

  useEffect(() => {
    clearIndex();
    if (downloadProgressPercentage > 0 && downloadProgressPercentage < 100) {
      // using line equation : ms = 2.278 * row + 2975
      const update = (7.778 * flightListTotal + 7975) / 50;
      const idx = window.setTimeout(() => {
        let amount = 0;
        const n = downloadProgressPercentage;
        if (n >= 0 && n < 20) {
          amount = 10; // 2  steps
        } else if (n >= 20 && n < 50) {
          amount = 4; // 9 steps
        } else if (n >= 50 && n < 80) {
          amount = 2; // 15 steps
        } else if (n >= 80 && n < 99) {
          amount = 0.5; // 40 steps
        }
        setDownloadProgressPercentage(clamp(n + amount, 0, 99.4));
      }, update);
      setTimerIndex(idx);
    }
    // eslint-disable-next-line
  }, [downloadProgressPercentage]);

  const handleExport = async (downloadSuccess, downloadFailure) => {
    handleExportClick(
      queryParamsWithSorting,
      flightListColumns,
      currentHiddenColumns,
      downloadSuccess,
      setDownloadProgressPercentage,
      downloadFailure,
      FlightListColumnId,
      ['flight'],
      'flights',
      getQueryFieldMapping,
      'flightList/flightsExport'
    );
  };

  const handleFilterChange = () => {
    setShowFlightListNotification(true); // allows notification to be shown again on filter change
  };

  const onDatePickerChange = (newState: DatePickerState) => {
    dispatch({type: DatePickerAction.SET_DATE_RANGE, payload: newState});
    setShowFlightListNotification(true); // allows notification to be shown again on date change
  };

  return (
    <ListViewComponent
      idPrefix={'flightList'}
      listContext={'flightList'}
      listSort={sort}
      tabs={TabsComponent}
      showGroupHeader={false}
      arePrereqsLoading={arePrereqsLoading}
      isLoading={isLoading}
      listTotal={flightListTotal}
      listColumns={flightListColumns}
      hiddenColumns={currentHiddenColumns}
      listData={flightListData}
      listFilters={filters}
      handleExport={handleExport}
      percentageComplete={downloadProgressPercentage}
      columnOverscan={10}
      handleSortChange={handleSortChange}
      getQueryFieldForColumn={getQueryFieldForColumn}
      notificationMessage={maxRowNotificationMessage}
      showNotification={showNotification}
      setShowNotification={setShowFlightListNotification}
      currentDateRange={datePickerState}
      onSetDateRange={onDatePickerChange}
      handleFilterChange={handleFilterChange}
      fixedColumnCount={1}
    />
  );
};

export default FlightListPage;
