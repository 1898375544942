/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: MapOverlay Configuration component Styles
 */

import {makeStyles} from 'tss-react/mui';
import styled from '@emotion/styled';

import {
  BLACK,
  MAP_POPUP_ACCENT_COLOR,
  WHITE,
  ACTIVE_BUTTON_BACKGROUND,
  SIDEBAR_ICON_COLOR,
  SIDEBAR_ICON_ACTIVE_COLOR,
  CONFIG_DIVIDER_BG,
  DRAG_BUTTON_BACKGROUND,
  COLUMN_SELECTOR_BUTTON_BG,
  COLUMN_SELECTOR_BUTTON_BG_HOVER
} from '../../common/theme/Colors';
import {MAP_POPUP_Z_INDEX} from '../../../utils/constants';
import {AircraftStatus} from '../../../store/queries/fleetMap/aircraftStatusQuery';

export const TailTagConfigurationTitle = styled.div`
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 32px;
  color: ${BLACK};
`;

export const TailTagConfigurationContainer = styled.div`
  height: 32px;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 32px;
  display: flex;
  width: 100%;
  color: ${MAP_POPUP_ACCENT_COLOR};
`;

export const MapOverlayConfigurationPopupDiv = styled.div<{searchBoxDisabled: boolean}>`
  width: 240px;
  height: auto;
  background-color: ${WHITE};
  position: absolute;
  border-radius: 4px;
  top: ${(props) => (props.searchBoxDisabled ? '16px' : '90px')};
  right: 54px;
  z-index: ${MAP_POPUP_Z_INDEX};
  padding: 8px 16px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12),
    0px 1px 5px 0px rgba(0, 0, 0, 0.2);
`;

export const TailTagSmallSpan = styled.span`
  cursor: pointer;
  padding-top: 5px;
  margin-right: 8px;
`;

export const TailTagLargeSpan = styled.span`
  cursor: pointer;
  padding-top: 3px;
`;

export const TailTagSizeHoverStyle = makeStyles()(() => {
  return {
    labelIcon: {
      '&:hover': {
        color: `${ACTIVE_BUTTON_BACKGROUND}`
      }
    }
  };
});

export const CustomSwitchContainer = styled.div`
  width: 50px;
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  .MuiSwitch-switchBase.Mui-checked {
    color: ${SIDEBAR_ICON_ACTIVE_COLOR};
  }
  .MuiSwitch-switchBase.MuiSwitch-track {
    background-color: ${SIDEBAR_ICON_COLOR};
  }
  .MuiSwitch-colorPrimary {
    color: ${WHITE};
  }
  .Mui-checked {
    color: ${SIDEBAR_ICON_ACTIVE_COLOR};
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${SIDEBAR_ICON_ACTIVE_COLOR};
  }
`;

export const ConfigDivider = styled.div`
  width: 230px;
  height: 1px;
  background: ${CONFIG_DIVIDER_BG};
  margin: 20px 0 10px 0;
`;

export const CoverageMapTitle = styled.div<{highlighted?: boolean}>`
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 32px;
  text-transform: uppercase;
  color: ${BLACK};
  background: ${(props) => (props.highlighted ? DRAG_BUTTON_BACKGROUND : WHITE)};
`;

export const CoverageMapContainer = styled.div`
  height: 32px;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  color: ${MAP_POPUP_ACCENT_COLOR};
`;

export const CoverageMapOuterContainer = styled.div`
  margin: 4px 0;
  width: 100%;
`;

export const CoveragePopupDiv = styled.div<{searchBoxDisabled: boolean}>`
  width: 230px;
  height: auto;
  background-color: ${WHITE};
  position: absolute;
  border-radius: 4px;
  top: ${(props) => (props.searchBoxDisabled ? '16px' : '80px')};
  right: 64px;
  z-index: ${MAP_POPUP_Z_INDEX};
  padding: 8px 0 12px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12),
    0px 1px 5px 0px rgba(0, 0, 0, 0.2);

  & > div > div > div {
    padding: 0px 12px;
  }
`;

export const MapOverlayButtonWrapper = styled.div`
  display: flex;
  margin-top: 4px;
  padding-top: 8px;
  padding-bottom: 2px;
  justify-content: space-evenly;
  button {
    font-size: 10px;
    font-weight: 600;
    padding: 4px 12px;
    margin: 0;
    text-transform: uppercase;
    background-color: ${COLUMN_SELECTOR_BUTTON_BG};
    width: 80px;
    &:hover {
      background-color: ${COLUMN_SELECTOR_BUTTON_BG_HOVER};
    }
  }
`;

export const HelpWrap = styled.div`
  svg {
    height: 12px;
    width: 12px;
  }
`;

export const ScrollContainer = styled.div<{maxHeight: number}>`
  overflow: hidden;
  max-height: ${(props) => props.maxHeight}px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
  }
`;

export const ExpandContainer = styled.span`
  svg {
    cursor: pointer;
    float: right;
    height: 22px;
    position: relative;
    top: 5px;
    left: 5px;
  }
`;

export interface MapOverlayConfigurationsProps {
  map: google.maps.Map;
  showMapOverlayPopup: boolean;
  closeOverlayPopup: () => void;
  mapHeight: number;
  searchBoxDisabled: boolean;
  setTailTagOpen: (t: boolean) => void;
  isTailTagOpen: boolean;
  setShowLargePopup: (t: boolean) => void;
  showLargePopup: boolean;
  disableTailTagConfig: boolean;
  mapOverlayContext: number;
  selectedAircraftData: AircraftStatus;
  selectedAircraftNetwork: string;
}
export interface KaSatelliteRegionGroup {
  region: string;
  kaSatellites: {
    id: number;
    name: string;
  }[];
}
export interface KuSatelliteRegionGroup {
  region: string;
  kuSatellites: {
    id: string;
    name: string;
  }[];
}
