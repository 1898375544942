/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Placeholder when a list has all columns hidden
 *
 */

import React from 'react';
import styled from '@emotion/styled';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AllColumnsHiddenPlaceHolderContainer = styled.div`
  position: relative;
  top: calc(50vh - 200px);
  font-family: 'Uni Neue', 'Source Sans Pro', sans-serif;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  svg {
    fill: #226394;
    font-size: 170px;
  }
  h1 {
    margin: 0;
    padding: 0;
    font-size: 42px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 15px;
  }
  h3 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    max-width: 295px;
  }
`;

export const AllColumnsHiddenPlaceHolder = ({idPrefix, page}) => (
  <AllColumnsHiddenPlaceHolderContainer id={`${idPrefix}--${page}__allColumnsHidden-indicator`}>
    <VisibilityOffIcon />
    <h1>All columns are hidden</h1>
    <h3>Try adjusting your column options to find what you are looking for.</h3>
  </AllColumnsHiddenPlaceHolderContainer>
);
