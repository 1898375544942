/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utility functions for interacting with the store
 */


/**
 * Gets the session storage key based on the given store context
 * @param queryContext identifies the context for the store
 */
export const _getStoreDataPersistKey = (storeContext: string): string => `Store.${storeContext}`;

/**
 * Returns the local storage entry name for the UI state of the given component
 * @param stateStorageContext context (generally a view) in which the component resides
 * @param componentName the component's name
 */
const _getUiComponentStorageLocation = (stateStorageContext: string, componentName: string) => {
  return `UI-State.${stateStorageContext}.${componentName}`;
};

/**
 * Stores the given UI component's state in local storage
 * @param stateStorageContext context (generally a view) in which the component resides
 * @param componentName the component's name
 * @param state JSON data representing the component's state
 */
export const storeUiComponentState = (stateStorageContext: string, componentName: string, state: any) => {
  localStorage.setItem(_getUiComponentStorageLocation(stateStorageContext, componentName), JSON.stringify(state));
};

/**
 * Loads the given UI component's state from local storage
 * @param stateStorageContext context (generally a view) in which the component resides
 * @param componentName the component's name
 * @param defaultState the default JSON state data to return if none is stored
 * @return JSON data representing the component's stored state or the default if none was stored
 */
export const loadUiComponentState = (stateStorageContext: string, componentName: string, defaultState: any) => {
  const storageLocation = _getUiComponentStorageLocation(stateStorageContext, componentName);
  const storedValue = localStorage.getItem(storageLocation);

  let parsedValue = defaultState;
  if (storedValue) {
    // Protect ourselves from potentially bad data (i.e. invalid JSON)
    try {
      parsedValue = JSON.parse(storedValue);
    } catch (error) {
      console.error(
        `Exception while parsing UI component state from local storage (location '${storageLocation}', value '${storedValue}'): ${error.toString()}`
      );
    }
  }

  return parsedValue;
};
