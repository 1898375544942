/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List Query
 */

import {Query} from '../types';

export interface HistoryPopupProps {
  popupWidth: number;
  popupTitle: string;
  historyDetails: object[];
  historyRowContent: (historyRow: any) => JSX.Element;
  disableCellText?: boolean | false;
  showHistoryLoader?: boolean | false;
  historyLoaderCount?: number | 1;
  headerTotalValue?: number;
  tooltipText?: string;
  historyLoaderContent?: (index: number, cellIdBase: string) => JSX.Element;
}

export interface ITrafficChartSeries {
  color: string;
  data: number[];
  name: string;
  pointWidth: number;
  showInLegend: boolean;
}

export interface ITrafficChartConfig {
  chartConfig: {
    chartHeight: number;
    chartType: string;
    chartWidth: number;
    customXAxis: any;
    customYAxis: any;
    id: string;
    plotOptions: any;
    series: ITrafficChartSeries[];
  };
  disable: boolean;
  toolTip?: (series: ITrafficChartSeries[]) => JSX.Element;
}
export interface TailListInfo {
  customer: string;
  var: string;
  serialNumber: string;
  aircraftId: string;
  tailId: string;
  totalFlights: number;
  totalFlightTime: string;
  averageFlightTime: string;
  totalFlightTimeRaw: number;
  averageFlightTimeRaw: number;
  groundSessions: number;
  networkCapability: string;
  ipAddress: string;
  macAddress: string;
  servicePlanName: string;
  aircraftManufacturer: string;
  aircraftType: string;
  equipmentHistoryCount: number;
  equipmentHistoryCountHistory?: HistoryPopupProps;
  servicePlanNameHistory?: HistoryPopupProps;
  trafficComposition?: ITrafficChartConfig;
  customerHistory?: HistoryPopupProps;
  kaTerminalVersion?: string;
  kaTerminalVersionStatus?: string;
  kaTerminalVersionStatusValue?: number;
  bdtVersion?: string;
  bdtVersionStatus?: string;
  bdtVersionStatusValue?: number;
  kuTerminalVersion?: string;
  kuTerminalVersionStatus?: string;
  kuTerminalVersionStatusValue?: number;
  sedVersion?: string;
  sedVersionStatus?: string;
  sedVersionStatusValue?: number;
  sscfVersion?: string;
  sscfVersionStatus?: string;
  sscfVersionStatusValue?: number;
  openCases?: number;
  totalConnectedTime?: string;
  averageConnectedTime?: string;
  totalDataUsed?: number;
  planDataUsed?: number;
  flightDataUsed?: number;
  groundDataUsed?: number;
  roamingDataUsed?: number;
  isRowHighlighted?: boolean;
  failedHandoverPercent?: number;
  failedHandoverPercentValue?: string;
  disconnectedEventCount?: number;
  disconnectedEventCountHistory?: HistoryPopupProps;
  ifcAvailabilityPercent?: string;
  failedHandoverPercentHistory?: HistoryPopupProps;
}

interface TailListRaw {
  customer: string;
  serialNumber: string;
  aircraftId: string;
  tailId: string;
  totalFlights: number;
  totalFlightTime: number;
  averageFlightTime: number;
  groundSessions: number;
  networkCapability: string;
  ipAddress: string;
  macAddress: string;
  servicePlanName: string;
  aircraftManufacturer: string;
  aircraftType: string;
  var: string;
  equipmentHistoryCount: number;
  createdDate: string;
  deactivatedDate: string;
}

/**
 * Transforms the given raw tail info data into (regular) tail info data
 * @param tailInfoRawList tailInfoRaw values from the api
 * @returns tailInfo Transformed tail info
 */
const transformRawTailInfo = (tailInfoRawList: TailListRaw[] | null): TailListInfo[] | null => {
  if (!tailInfoRawList) return null;

  const tailInfo: TailListInfo[] = tailInfoRawList.map((tailInfoRaw) => ({
    customer: tailInfoRaw.customer ? tailInfoRaw.customer : 'Unassigned',
    var: tailInfoRaw.var,
    serialNumber: tailInfoRaw.serialNumber,
    aircraftId: tailInfoRaw.aircraftId,
    tailId: tailInfoRaw.tailId,
    totalFlights: tailInfoRaw.totalFlights,
    totalFlightTime: Number(tailInfoRaw.totalFlightTime).toString(),
    averageFlightTime: Number(tailInfoRaw.averageFlightTime).toString(),
    totalFlightTimeRaw: Number(tailInfoRaw.totalFlightTime),
    averageFlightTimeRaw: Number(tailInfoRaw.averageFlightTime),
    groundSessions: tailInfoRaw.groundSessions,
    networkCapability: tailInfoRaw.networkCapability,
    ipAddress: tailInfoRaw.ipAddress,
    macAddress: tailInfoRaw.macAddress,
    servicePlanName: tailInfoRaw.servicePlanName,
    aircraftManufacturer: tailInfoRaw.aircraftManufacturer,
    aircraftType: tailInfoRaw.aircraftType,
    equipmentHistoryCount: tailInfoRaw.equipmentHistoryCount ? tailInfoRaw.equipmentHistoryCount : 0,
    createdDate: tailInfoRaw.createdDate,
    deactivatedDate: tailInfoRaw.deactivatedDate
  }));

  return tailInfo;
};

const tailListQuery: Query<TailListInfo[]> = {
  route: 'tailList/tails',
  transform: transformRawTailInfo
};

export default tailListQuery;
