/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Interfaces and arrow functions for fleet map aircraft popups
 */

import React from 'react';
import {isNil} from 'lodash';
import {AirportCodeTooltip, DataToolTip, SatelliteDisconnectedIcon, SatelliteIcon} from '@viasat/insights-components';
import LabTerminalIcon from '../../common/theme/icons/LabTerminalIcon';
import WarningIcon from '@mui/icons-material/WarningSharp';
import {getAvailabilityColor, splitString, flightPhaseContentLookup} from '../../../utils/MapUtil';
import AvailabilityDot from '../../common/AvailabilityDot';
import ArrivalIcon from '../../common/theme/icons/ArrivalIcon';
import DepartureIcon from '../../common/theme/icons/DepartureIcon';
import TimeRemainingIcon from '../../common/theme/icons/TimeRemainingIcon';
import {POPUP_AIRCRAFT_ICON_COLOR, MAP_POPUP_DEFAULT_BORDER} from '../../common/theme/Colors';
import {
  PopupNetworkTypeContainer,
  PopupNetworkTypeChip,
  PopupTitleContainer,
  PopupTimeContainer,
  Span,
  TooltipContent,
  SelectedPopupTimeContainer,
  DisableSelect,
  HiddenSelect,
  DarkFlightInfo,
  InfoMessage
} from './AircraftPopupStyles';
import {formatMomentInputAsTimeWithHrMin} from '../../../utils/DateTimeUtils';
import {
  NO_DATA_INDICATOR,
  ConnectionStatus,
  NO_DATA_TIMESTAMP,
  POPUP_FLIGHT_NOT_STARTED,
  FlightPhase,
  DEMO_OBFUSCATED_STR_LENGTH
} from '../../../utils/constants';
import {AircraftStatus} from '../../../store/queries/fleetMap/aircraftStatusQuery';
import {FlightPathInfoDataProps} from '../../../store/queries/fleetMap/flightPathInfoQuery';

interface PopupNetworkTypeFragmentProps {
  iconSize: number;
  fontSize: number;
  height: number;
  elementIdBase: string;
  status: string;
  networkType: string;
}

export const PopupNetworkTypeFragment = (props: PopupNetworkTypeFragmentProps): React.ReactElement => {
  const {elementIdBase, status, networkType} = props;

  return (
    <PopupNetworkTypeContainer id={`${elementIdBase}networkType-container`}>
      <PopupNetworkTypeChip {...props}>
        {status === ConnectionStatus.OFFLINE || status === ConnectionStatus.DISCONNECTED ? (
          <SatelliteDisconnectedIcon sx={{color: POPUP_AIRCRAFT_ICON_COLOR}} />
        ) : (
          <SatelliteIcon sx={{color: POPUP_AIRCRAFT_ICON_COLOR}} />
        )}
        {status === ConnectionStatus.OFFLINE ? (
          <Span style={{width: 10, paddingLeft: 2}}>{NO_DATA_INDICATOR}</Span>
        ) : status === ConnectionStatus.DISCONNECTED ? (
          <Span style={{width: 'fit-content'}}>
            &nbsp;{ConnectionStatus.DISCONNECTED}&nbsp;({networkType})
          </Span>
        ) : (
          networkType
        )}
      </PopupNetworkTypeChip>
    </PopupNetworkTypeContainer>
  );
};

interface PopupTitleFragmentProps {
  availDotSize: number;
  primaryIdFontSize: number;
  secondaryIdFontSize: number;
  flightPhaseFontSize: number;
  flightPhaseIconSize: number;
  outerPadding: string;
  status: string;
  tailId: string;
  serialNumber: string;
  elementIdBase: string;
  flightPhase: string;
  isLargePopup: boolean;
  mapDivRef: React.MutableRefObject<any>;
  isLabTerminal?: boolean;
  endUser?: string;
}

export const PopupTitleFragment = (props: PopupTitleFragmentProps): React.ReactElement => {
  const {status, serialNumber, tailId, elementIdBase, flightPhase, isLargePopup, mapDivRef, isLabTerminal, endUser} =
    props;

  return (
    <PopupTitleContainer {...props}>
      <Span className="availability-container">
        <AvailabilityDot id={elementIdBase} sx={{color: getAvailabilityColor(status), width: 17, height: 16}} />
      </Span>
      <Span className="title-container">
        {
          <>
            <Span className="serial-number" id={`${elementIdBase}serialNumber-label`}>
              {serialNumber ? serialNumber : NO_DATA_INDICATOR}
              {' /'}
            </Span>
            <Span className="tail-id" id={`${elementIdBase}tailId-label`}>
              {tailId ? (
                serialNumber.length + tailId.length >= DEMO_OBFUSCATED_STR_LENGTH ? (
                  <DataToolTip
                    arrow
                    placement="top"
                    enterDelay={250}
                    title={
                      <>
                        <div style={{lineHeight: '18px', margin: '11px 13px'}}>
                          <div>
                            <span style={{fontWeight: 600}}>{tailId}</span>
                          </div>
                        </div>
                      </>
                    }
                  >
                    <span>
                      {tailId.substring(0, 3)}
                      <DisableSelect>...</DisableSelect>
                      <HiddenSelect>{tailId.substring(3, tailId.length - 3)}</HiddenSelect>
                      {tailId.substring(tailId.length - 3)}
                    </span>
                  </DataToolTip>
                ) : (
                  tailId
                )
              ) : (
                NO_DATA_INDICATOR
              )}
            </Span>
          </>
        }
      </Span>
      <Span className="title-divider">&nbsp;</Span>
      <Span className="flight-phase-container" id={`${elementIdBase}flightPhase-container`}>
        {isLabTerminal
          ? getLabTerminalFragment(endUser, elementIdBase, isLargePopup, endUser, mapDivRef)
          : getFlightPhaseFragment(flightPhase, elementIdBase, status, isLargePopup, mapDivRef)}
      </Span>
    </PopupTitleContainer>
  );
};

const FragmentIconWrapper = ({Icon, id, color}) => {
  return (
    <span className="flight-phase-icon">
      <Icon id={id} sx={{color: {color}}} />
    </span>
  );
};

const FragmentTooltipWrapper: React.FC<{
  children: JSX.Element;
  disableToolTip: boolean;
  title: string;
  mapDivRef: React.MutableRefObject<any>;
}> = ({children, disableToolTip, title, mapDivRef}) => {
  if (!disableToolTip) {
    return (
      <DataToolTip
        key="flightPhaseTooltip"
        arrow
        placement="top"
        enterDelay={600}
        marginBottom="-16px"
        arrowPadding="6px"
        popperProps={{container: mapDivRef.current ? mapDivRef.current.mapRef : null}}
        title={
          <React.Fragment>
            <TooltipContent>{title}</TooltipContent>
          </React.Fragment>
        }
      >
        {children}
      </DataToolTip>
    );
  } else {
    return <>{children}</>;
  }
};

/**
 * Generates the Lab Terminal fragment
 * @param endUser End User Name
 * @returns LabTerminal fragment
 */
export const getLabTerminalFragment = (
  labIdentifier: string,
  elementIdBase: string,
  isLargePopup: boolean,
  endUser: string,
  mapDivRef: React.MutableRefObject<any>
): React.ReactElement => {
  return (
    <>
      {isLargePopup ? (
        <span id={`${elementIdBase}flightPhase-label`} className="flight-phase-name">
          {labIdentifier}
        </span>
      ) : null}
      <FragmentTooltipWrapper disableToolTip={isLargePopup} title={endUser} mapDivRef={mapDivRef}>
        <div>
          <FragmentIconWrapper
            Icon={LabTerminalIcon}
            id={`${elementIdBase}flightPhaseLabTerminal-icon`}
            color={POPUP_AIRCRAFT_ICON_COLOR}
          />
        </div>
      </FragmentTooltipWrapper>
    </>
  );
};

/**
 * Generates the flight phase fragment
 * @param flightPhase Flight Phase
 * @returns Flight Phase fragment
 */
export const getFlightPhaseFragment = (
  flightPhase: string,
  elementIdBase: string,
  status: string,
  isLargePopup: boolean,
  mapDivRef: React.MutableRefObject<any>
): React.ReactElement => {
  const flightPhaseContent = flightPhase ? flightPhaseContentLookup[flightPhase] : null;

  return (
    <>
      {isLargePopup ? (
        <span id={`${elementIdBase}flightPhase-label`} className="flight-phase-name">
          {flightPhaseContent && status !== ConnectionStatus.OFFLINE ? flightPhaseContent.text : null}
        </span>
      ) : null}
      {flightPhaseContent && status !== ConnectionStatus.OFFLINE ? (
        !isLargePopup ? (
          <>
            <DataToolTip
              key="flightPhaseTooltip"
              arrow
              placement="top"
              enterDelay={600}
              marginBottom="-16px"
              arrowPadding="6px"
              popperProps={{container: mapDivRef.current ? mapDivRef.current.mapRef : null}}
              title={
                <React.Fragment>
                  <TooltipContent>{flightPhaseContent.text}</TooltipContent>
                </React.Fragment>
              }
            >
              <span className="flight-phase-icon">
                <flightPhaseContent.Icon
                  id={`${elementIdBase}flightPhase${flightPhaseContent.idText}-icon`}
                  sx={{color: {POPUP_AIRCRAFT_ICON_COLOR}}}
                />
              </span>
            </DataToolTip>
          </>
        ) : (
          <span className="flight-phase-icon">
            <flightPhaseContent.Icon
              id={`${elementIdBase}flightPhase${flightPhaseContent.idText}-icon`}
              sx={{color: {POPUP_AIRCRAFT_ICON_COLOR}}}
            />
          </span>
        )
      ) : (
        NO_DATA_INDICATOR
      )}
    </>
  );
};

export interface PopupTimeFragmentProps {
  directionIconSize: number;
  timeLabelFontSize: number;
  timeFontSize: number;
  utcFontSize: number;
  elementIdBase: string;
  mapDivRef: React.MutableRefObject<any>;
  padding: string;
  departureTstamp: string;
  arrivalTstamp: string;
  flightPhase: string;
  departureTstampLabel?: string;
  arrivalTstampLabel?: string;
  aircraftInfo: any;
  airports: any;
}

export interface SelectedPopupTimeFragmentProps {
  directionIconSize: number;
  timeLabelFontSize: number;
  timeFontSize: number;
  utcFontSize: number;
  elementIdBase: string;
  mapDivRef: React.MutableRefObject<any>;
  padding: string;
  departureTstamp: string;
  arrivalTstamp: string;
  flightPhase: string;
  flightStartHorizLine: number;
  flightNoStartHorizLine: number;
  flightEndHorizLine: number;
  departureTstampLabel?: string;
  arrivalTstampLabel?: string;
  aircraftInfo: any;
  airports: any;
}

/**
 * Aircraft Popup Departure and Arrival data container element
 * @param props - popup time fragment properties
 * @returns Aircraft Popup time container element
 */
export const SelectedPopupTimeFragment = (props: SelectedPopupTimeFragmentProps): React.ReactElement => {
  const {
    elementIdBase,
    mapDivRef,
    departureTstamp,
    arrivalTstamp,
    flightPhase,
    departureTstampLabel,
    arrivalTstampLabel,
    aircraftInfo,
    airports
  } = props;
  return (
    <SelectedPopupTimeContainer {...props}>
      <Span className="time-container">
        <>
          {/*Departure starts */}
          <Span style={{paddingBottom: 2}}>
            <DepartureIcon />
            <Span className="time-label" id={`${elementIdBase}departure-label`}>
              {departureTstampLabel}
            </Span>
            <Span className={'flight_dotted_line'}>&nbsp;</Span>

            <Span className="time" style={{color: 'black'}} id={`${elementIdBase}departureTime-label`}>
              {!isNil(aircraftInfo.origin) ? (
                <AirportCodeTooltip
                  airportCode={aircraftInfo.origin}
                  airport={airports && aircraftInfo ? airports[aircraftInfo.origin] : null}
                  getFullElementId={(name: string, type: string) =>
                    `${aircraftInfo.origin}--airportTooltip__${name}-${type}`
                  }
                  position={'top'}
                  yMargin={'-10px'}
                  arrow={true}
                  invertColors={false}
                  disable={isNil(aircraftInfo.origin)}
                >
                  <span style={{fontWeight: 600}}>{aircraftInfo.origin} &nbsp;</span>
                </AirportCodeTooltip>
              ) : (
                <></>
              )}
              <DataToolTip
                key="UTCTooltip"
                arrow
                placement="top"
                enterDelay={600}
                marginBottom="-16px"
                arrowPadding="6px"
                popperProps={{
                  container: mapDivRef.current ? mapDivRef.current.mapRef : null,
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -8]
                      }
                    }
                  ]
                }}
                title={
                  <React.Fragment>
                    <TooltipContent>UTC</TooltipContent>
                  </React.Fragment>
                }
              >
                <span>
                  {departureTstamp || aircraftInfo.actualDepartureTstamp
                    ? formatMomentInputAsTimeWithHrMin(
                        splitString(departureTstamp || aircraftInfo.actualDepartureTstamp, '.', 0)
                      )
                    : arrivalTstamp || flightPhase !== FlightPhase.ON_GROUND
                    ? NO_DATA_TIMESTAMP
                    : POPUP_FLIGHT_NOT_STARTED}
                </span>
              </DataToolTip>
            </Span>
          </Span>
          {/* Departure ends */}
          {/* Expected Arrival starts */}
          <Span>
            <ArrivalIcon />
            <Span className="time-label" id={`${elementIdBase}arrival-label`}>
              {arrivalTstampLabel}
            </Span>
            <Span className={'flight_dotted_line'}>&nbsp;</Span>

            <Span className="time" style={{color: 'black'}} id={`${elementIdBase}arrivalTime-label`}>
              {!isNil(aircraftInfo.destination) ? (
                <AirportCodeTooltip
                  airportCode={aircraftInfo.destination}
                  airport={airports && aircraftInfo ? airports[aircraftInfo.destination] : null}
                  getFullElementId={(name: string, type: string) =>
                    `${aircraftInfo.destination}--airportTooltip__${name}-${type}`
                  }
                  position={'top'}
                  yMargin={'-10px'}
                  arrow={true}
                  invertColors={false}
                  disable={isNil(aircraftInfo.destination)}
                >
                  <span style={{fontWeight: 600}}>{aircraftInfo.destination} &nbsp;</span>
                </AirportCodeTooltip>
              ) : (
                <></>
              )}
              <DataToolTip
                key="UTCTooltip"
                arrow
                placement="top"
                enterDelay={600}
                marginBottom="-16px"
                arrowPadding="6px"
                popperProps={{
                  container: mapDivRef.current ? mapDivRef.current.mapRef : null,
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -8]
                      }
                    }
                  ]
                }}
                title={
                  <React.Fragment>
                    <TooltipContent>UTC</TooltipContent>
                  </React.Fragment>
                }
              >
                <span>
                  {arrivalTstamp || aircraftInfo.actualArrivalTstamp || aircraftInfo.estimatedArrivalTstamp
                    ? formatMomentInputAsTimeWithHrMin(
                        splitString(
                          arrivalTstamp || aircraftInfo.actualArrivalTstamp || aircraftInfo.estimatedArrivalTstamp,
                          '.',
                          0
                        )
                      )
                    : NO_DATA_TIMESTAMP}
                </span>
              </DataToolTip>
            </Span>
          </Span>
          {/* Expected Arrival ends */}
          {/* Remaining starts */}
          {!isNil(aircraftInfo.origin) &&
          !isNil(aircraftInfo.remainingTime) &&
          (isNil(aircraftInfo.actualArrivalTstamp) || isNil(arrivalTstamp)) ? (
            <Span>
              <TimeRemainingIcon />
              <Span className="time-label" id={`${elementIdBase}remaining-time-label`}>
                Remaining
              </Span>
              <Span className={'flight_dotted_line'}>&nbsp;</Span>

              <Span style={{justifyContent: 'flex-end'}}>
                <Span className="time" id={`${elementIdBase}remaining-value`}>
                  {aircraftInfo.remainingTime}
                </Span>
              </Span>
            </Span>
          ) : (
            <></>
          )}
          {/* Remaining ends */}

          {aircraftInfo?.isDark ? (
            <>
              <Span style={{borderTop: `1px solid ${MAP_POPUP_DEFAULT_BORDER}`}}></Span>
              <DarkFlightInfo>
                <InfoMessage>
                  <WarningIcon sx={{color: '#CD3209', width: 17, height: 15}} />
                  <span className="darkFlight">Dark flight detected:</span>
                  &nbsp; Reason unknown
                </InfoMessage>
              </DarkFlightInfo>
            </>
          ) : (
            <></>
          )}
        </>
      </Span>
    </SelectedPopupTimeContainer>
  );
};

/**
 * Aircraft Popup Departure and Arrival data container element
 * @param props - popup time fragment properties
 * @returns Aircraft Popup time container element
 */
export const PopupTimeFragment = (props: PopupTimeFragmentProps): React.ReactElement => {
  const {
    elementIdBase,
    mapDivRef,
    departureTstamp,
    arrivalTstamp,
    flightPhase,
    departureTstampLabel,
    arrivalTstampLabel,
    aircraftInfo,
    airports
  } = props;
  return (
    <PopupTimeContainer {...props}>
      <Span className="time-container">
        <>
          {/* DARK flight start */}
          {/* Departure Title Starts */}
          <div>
            <Span>
              <DepartureIcon />
              <Span className="time-label" id={`${elementIdBase}departure-label`}>
                {departureTstampLabel}
              </Span>
            </Span>
            {/* Departure title ends */}
            {/* Arrival title starts */}
            <Span>
              <ArrivalIcon />
              <Span className="time-label" id={`${elementIdBase}arrival-label`}>
                {arrivalTstampLabel}
              </Span>
            </Span>
            {/* Arrival Title ends */}
            {/* Remaining title starts */}
            {!isNil(aircraftInfo.origin) &&
            !isNil(aircraftInfo.remainingTime) &&
            (isNil(aircraftInfo.actualArrivalTstamp) || isNil(arrivalTstamp)) ? (
              <Span>
                <TimeRemainingIcon />
                <Span className="time-label" id={`${elementIdBase}remaining-time-label`}>
                  Remaining
                </Span>
              </Span>
            ) : (
              <></>
            )}
          </div>
          {/* Remaining Title ends */}
          {/* Departure Value Starts */}
          <div>
            <Span style={{justifyContent: 'flex-end'}}>
              <Span className="time" style={{color: 'black'}} id={`${elementIdBase}departureTime-label`}>
                {!isNil(aircraftInfo.origin) ? (
                  <AirportCodeTooltip
                    airportCode={aircraftInfo.origin}
                    airport={airports && aircraftInfo ? airports[aircraftInfo.origin] : null}
                    getFullElementId={(name: string, type: string) =>
                      `${aircraftInfo.origin}--airportTooltip__${name}-${type}`
                    }
                    position={'top'}
                    yMargin={'-10px'}
                    arrow={true}
                    invertColors={false}
                    disable={isNil(aircraftInfo.origin)}
                  >
                    <span style={{fontWeight: 600}}>{aircraftInfo.origin} &nbsp;</span>
                  </AirportCodeTooltip>
                ) : (
                  <></>
                )}
                <DataToolTip
                  key="UTCTooltip"
                  arrow
                  placement="top"
                  enterDelay={600}
                  marginBottom="-16px"
                  arrowPadding="6px"
                  popperProps={{
                    container: mapDivRef.current ? mapDivRef.current.mapRef : null,
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -8]
                        }
                      }
                    ]
                  }}
                  title={
                    <React.Fragment>
                      <TooltipContent>UTC</TooltipContent>
                    </React.Fragment>
                  }
                >
                  <span>
                    {departureTstamp || aircraftInfo.actualDepartureTstamp
                      ? formatMomentInputAsTimeWithHrMin(
                          splitString(departureTstamp || aircraftInfo.actualDepartureTstamp, '.', 0)
                        )
                      : arrivalTstamp || flightPhase !== FlightPhase.ON_GROUND
                      ? NO_DATA_TIMESTAMP
                      : POPUP_FLIGHT_NOT_STARTED}
                  </span>
                </DataToolTip>
              </Span>
            </Span>

            {/* Departure Value ends */}
            {/* Arrival Value starts */}
            <Span style={{justifyContent: 'flex-end'}}>
              <Span className="time" style={{color: 'black'}} id={`${elementIdBase}arrivalTime-label`}>
                {!isNil(aircraftInfo.destination) ? (
                  <AirportCodeTooltip
                    airportCode={aircraftInfo.destination}
                    airport={airports && aircraftInfo ? airports[aircraftInfo.destination] : null}
                    getFullElementId={(name: string, type: string) =>
                      `${aircraftInfo.destination}--airportTooltip__${name}-${type}`
                    }
                    position={'top'}
                    yMargin={'-10px'}
                    arrow={true}
                    invertColors={false}
                    disable={isNil(aircraftInfo.destination)}
                  >
                    <span style={{fontWeight: 600}}>{aircraftInfo.destination} &nbsp;</span>
                  </AirportCodeTooltip>
                ) : (
                  <></>
                )}
                <DataToolTip
                  key="UTCTooltip"
                  arrow
                  placement="top"
                  enterDelay={600}
                  marginBottom="-16px"
                  arrowPadding="6px"
                  popperProps={{
                    container: mapDivRef.current ? mapDivRef.current.mapRef : null,
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -8]
                        }
                      }
                    ]
                  }}
                  title={
                    <React.Fragment>
                      <TooltipContent>UTC</TooltipContent>
                    </React.Fragment>
                  }
                >
                  <span>
                    {arrivalTstamp || aircraftInfo.estimatedArrivalTstamp || aircraftInfo.actualArrivalTstamp
                      ? formatMomentInputAsTimeWithHrMin(
                          splitString(
                            arrivalTstamp || aircraftInfo.estimatedArrivalTstamp || aircraftInfo.actualArrivalTstamp,
                            '.',
                            0
                          )
                        )
                      : NO_DATA_TIMESTAMP}
                  </span>
                </DataToolTip>
              </Span>
            </Span>
            {/* Arrival value ends */}
            {/* Remaining Value starts */}
            {!isNil(aircraftInfo.origin) &&
            !isNil(aircraftInfo.remainingTime) &&
            (isNil(aircraftInfo.actualArrivalTstamp) || isNil(arrivalTstamp)) ? (
              <Span style={{justifyContent: 'flex-end'}}>
                <Span className="time" id={`${elementIdBase}remaining-value`}>
                  {aircraftInfo.remainingTime ? aircraftInfo.remainingTime : '--'}
                </Span>
              </Span>
            ) : (
              <></>
            )}
          </div>
          {/* Remaining value ends */}
          {/* DARK flight start */}
        </>
      </Span>
      {aircraftInfo.isDark && (
        <DarkFlightInfo>
          <InfoMessage>
            <WarningIcon sx={{color: '#CD3209', width: 17, height: 15}} />
            <span className="darkFlight">Dark flight:</span>
            &nbsp; Reason unknown
          </InfoMessage>
        </DarkFlightInfo>
      )}
    </PopupTimeContainer>
  );
};

export interface HoveredAircraftPopupProps {
  status: string;
  aircraftId: string;
  tailId: string;
  serialNumber: string;
  endUser?: string;
  lastHeard: string;
  networkType: string;
  mapDivRef: React.MutableRefObject<any>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  zIndex: number;
  border?: string;
  highlightPopup: boolean;
  showLargePopup: boolean;
  flightPhase: string;
  departureTstamp: string;
  arrivalTstamp: string;
  departureTstampLabel: string;
  arrivalTstampLabel: string;
  isLabTerminal?: boolean;
  aircraftInfo: any;
}

export interface SelectedAircraftPopupProps {
  selectedAircraft: AircraftStatus;
  flightPath: FlightPathInfoDataProps;
  onClose: () => void;
  mapDivRef: React.MutableRefObject<any>;
  popupSerialNumber: any;
}
