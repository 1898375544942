/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Share button component
 */

import React from 'react';
import {ShareLink} from '@viasat/insights-components';

import {useStore} from '../../../../store/Store';
import {getCurrentUrlPath, addFiltersToUrl} from '../../../../utils/LinkUtils';

export interface ShareButtonProps {
  idBase: string;
  customFilters?: object;
  disabled?: boolean;
  skipCustomerFilters?: boolean;
  skipDateFilters?: boolean;
  viewContext?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  idBase,
  customFilters,
  disabled,
  skipCustomerFilters,
  skipDateFilters,
  viewContext
}) => {
  const {store} = useStore();
  const urlPath = getCurrentUrlPath();
  const urlString = addFiltersToUrl(urlPath, customFilters, store, !skipCustomerFilters, !skipDateFilters, viewContext);
  return (
    <ShareLink
      getFullElementId={(name, type) => `${idBase}__${name}-${type}`}
      disabled={disabled}
      urlPath={urlString}
      urlQuery={''}
    />
  );
};

export default ShareButton;
