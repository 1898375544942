/*
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: The aircraft marker point on a google map
 */
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AirplaneIcon from '@mui/icons-material/Flight';
import {OnGroundIcon} from '@viasat/insights-components';
import {OverlayView, useGoogleMap} from '@react-google-maps/api';
import {LoadingSpinner} from '@viasat/insights-components';
import LabTerminalIcon from '../common/theme/icons/LabTerminalIcon';

import {WHITE} from '../common/theme/Colors';
import {
  AirplaneContainer,
  AirplaneHoverContainer,
  AirplaneInnerContainer,
  LoadingFlightPathCaptionProps,
  LoadingFlightPathContainer
} from './MapStyles';
import {getAvailabilityColor, getAircraftStyleAttributes, calculateZIndex, AircraftMapData} from '../../utils/MapUtil';
import HoveredAircraftPopup from './aircraftPopups/HoveredAircraftPopup';
import {getElementId} from '../../utils/ElementIdUtils';
import {useStore} from '../../store/Store';
import {FleetMapAction, InitialFleetMapState} from '../../store/reducers/FleetMapReducer';
import {
  MAP_ELID_SECTION,
  SELECTED_AIRCRAFT_Z_INDEX,
  POPUP_CONTAINER_BORDER,
  POPUP_CONTAINER_HOVER_BORDER,
  HOVER_DELAY_MS,
  Z_INDEX,
  ConnectionStatus,
  FlightPhase,
  BAND_KU
} from '../../utils/constants';
import WarningIcon from '@mui/icons-material/WarningSharp';

export interface MapAircraftProps {
  aircraft: AircraftMapData;
  mapDivRef: React.MutableRefObject<any>;
  hoveredAircraft: string;
  setHoveredAircraft: (f: any) => void;
  setPopup: (aircraft: AircraftMapData | undefined) => void;
  isTailTagOpen: boolean;
  showLargePopup: boolean;
  internal?: boolean;
  onClick?: () => void;
  zIndexOverride?: number;
  isFlightPathLoading?: boolean;
}

/**
 * The aircraft marker point on a google map
 * @param props Map Aircraft Props
 * @returns map aircraft
 */
const MapAircraft: React.FC<MapAircraftProps> = (props) => {
  const {
    aircraft,
    mapDivRef,
    hoveredAircraft,
    setHoveredAircraft,
    setPopup,
    isTailTagOpen,
    showLargePopup,
    internal,
    onClick,
    zIndexOverride,
    isFlightPathLoading
  } = props;

  const {store, dispatch} = useStore();
  const navigate = useNavigate();
  const {view, selectedAircraftId} = store.fleetMap;
  const isolateRoute = view.isolateRoute === 'true' ? true : false;

  const {
    flightId,
    faFlightId,
    aircraftId,
    lastLatitude,
    lastLongitude,
    tailId,
    serialNumber,
    endUser,
    connectedStartTimestamp,
    connectedEndTimestamp,
    lastNetwork,
    lastFlightPhase,
    flightStartTimestamp,
    flightEndTimestamp,
    lastHeading,
    status,
    pingsConnectionEndTimestamp,
    isDark
  } = aircraft;

  const [timerId, setTimerId] = useState<any>(undefined);
  const [isPopupHovered, setIsPopupHovered] = useState<boolean>(false);
  const isHovered = hoveredAircraft === aircraftId;
  const isActive = isHovered || isPopupHovered;
  const isPopup = selectedAircraftId === aircraftId;
  const map = useGoogleMap();
  const aircraftAttributes = getAircraftStyleAttributes(status);

  const handleAircraftClick = () => {
    const selectedAircraftId = store.fleetMap.selectedAircraftId;
    const selectedOfflineAircraft = store.fleetMap.selectedOfflineAircraft;
    if (aircraftId === selectedAircraftId || aircraftId === selectedOfflineAircraft) {
      dispatch({
        type: FleetMapAction.SET_SELECTED_AIRCRAFT,
        payload: InitialFleetMapState.selectedAircraftId
      });
      dispatch({
        type: FleetMapAction.SET_SELECTED_OFFLINE_AIRCRAFT,
        payload: InitialFleetMapState.selectedAircraftId
      });
      dispatch({
        type: FleetMapAction.RESET_VIEW
      });
    } else if (status !== ConnectionStatus.OFFLINE) {
      map?.setCenter({lat: lastLatitude, lng: lastLongitude});
      setPopup(aircraft);
      dispatch({
        type: FleetMapAction.SET_SELECTED_AIRCRAFT,
        payload: aircraftId
      });
      setHoveredAircraft(undefined);
      sessionStorage.userMapZoom = map.getZoom();
      sessionStorage.userMapCenter = map.getCenter();
    } else {
      dispatch({
        type: FleetMapAction.SET_SELECTED_AIRCRAFT,
        payload: InitialFleetMapState.selectedAircraftId
      });
      setHoveredAircraft(aircraftId);
      dispatch({
        type: FleetMapAction.SET_SELECTED_OFFLINE_AIRCRAFT,
        payload: aircraftId
      });
      navigate(`/flight/details/${flightId ? flightId : faFlightId}`);
    }
  };

  const AirplaneHoverMarker = (
    <>
      <AirplaneHoverContainer
        id={getElementId(MAP_ELID_SECTION, `aircraft-${serialNumber}`, 'airplane', 'container')}
        data-latitude={lastLatitude}
        data-longitude={lastLongitude}
        onClick={onClick || handleAircraftClick}
        onMouseEnter={() => {
          if (timerId) clearTimeout(timerId);
          const newTimerId = setTimeout(() => {
            if (selectedAircraftId !== aircraftId && sessionStorage.getItem('mapPathHover') !== 'true') {
              setHoveredAircraft(aircraftId);
            }
          }, HOVER_DELAY_MS);
          setTimerId(newTimerId);
        }}
        onMouseLeave={() => {
          if (timerId) {
            setTimerId(undefined);
            clearTimeout(timerId);
          }
          setHoveredAircraft(undefined);
        }}
        zIndex={
          typeof zIndexOverride !== 'undefined'
            ? zIndexOverride
            : isPopupHovered || isHovered
            ? Z_INDEX.HOVER
            : isPopup
            ? SELECTED_AIRCRAFT_Z_INDEX
            : calculateZIndex(status, isDark)
        }
      />
      {isTailTagOpen || isActive ? (
        <HoveredAircraftPopup
          status={status}
          aircraftId={aircraftId}
          serialNumber={serialNumber}
          endUser={endUser}
          tailId={tailId}
          lastHeard={lastNetwork === BAND_KU ? connectedEndTimestamp : pingsConnectionEndTimestamp}
          networkType={lastNetwork}
          mapDivRef={mapDivRef}
          aircraftInfo={aircraft}
          onMouseEnter={() => {
            setIsPopupHovered(true);
          }}
          onMouseLeave={() => {
            setIsPopupHovered(false);
          }}
          zIndex={
            isPopupHovered || isHovered ? Z_INDEX.HOVER : isPopup ? SELECTED_AIRCRAFT_Z_INDEX : calculateZIndex(status, isDark)
          }
          border={
            (isPopupHovered || isHovered) && isTailTagOpen ? POPUP_CONTAINER_HOVER_BORDER : POPUP_CONTAINER_BORDER
          }
          highlightPopup={(isPopupHovered || isHovered) && isTailTagOpen}
          showLargePopup={showLargePopup}
          flightPhase={lastFlightPhase}
          departureTstamp={aircraft?.isLabTerminal ? connectedStartTimestamp : flightStartTimestamp}
          arrivalTstamp={aircraft?.isLabTerminal ? connectedEndTimestamp : flightEndTimestamp}
          departureTstampLabel={aircraft?.isLabTerminal ? 'Connection Start' : 'Flight Start'}
          arrivalTstampLabel={aircraft?.isLabTerminal ? 'Connection End' : 'Flight End'}
          isLabTerminal={aircraft?.isLabTerminal}
        />
      ) : (
        <> </>
      )}
    </>
  );

  const AirplaneMarker = (
    <>
      <AirplaneContainer
        id={getElementId(MAP_ELID_SECTION, `aircraft-${serialNumber}`, 'airplane-marker', 'container')}
        data-latitude={lastLatitude}
        data-longitude={lastLongitude}
        display={isolateRoute ? (selectedAircraftId === aircraftId ? 'inline-block' : 'none') : 'inline-block'}
        zIndex={
          typeof zIndexOverride !== 'undefined'
            ? zIndexOverride
            : isPopupHovered || isHovered
            ? Z_INDEX.HOVER
            : isPopup
            ? SELECTED_AIRCRAFT_Z_INDEX
            : calculateZIndex(status, isDark)
        }
        animation={isPopup ? `${aircraftAttributes.pulse} 1.75s infinite` : null}
      >
        <AirplaneInnerContainer
          style={{
            opacity: selectedAircraftId ? (selectedAircraftId === aircraftId ? 1 : 0.6) : 1,
            background: `${getAvailabilityColor(status)}`
          }}
        >
          {aircraft?.isLabTerminal ? (
            <LabTerminalIcon sx={{color: WHITE, width: 24, height: 15}} />
          ) : lastFlightPhase === FlightPhase.ON_GROUND ? (
            <>
              <OnGroundIcon sx={{color: WHITE, width: 24, height: 15}} />
              {aircraft?.isDark && <WarningIcon className="darkFlightIcon" />}
            </>
          ) : (
            <>
              <AirplaneIcon
                fontSize="small"
                style={{
                  color: WHITE,
                  padding: '0',
                  height: '16px',
                  width: '16px',
                  transform: `rotate(${
                    lastHeading !== null && lastFlightPhase !== FlightPhase.ON_GROUND ? lastHeading : 0
                  }deg)`
                }}
              />
              {aircraft?.isDark && <WarningIcon className="darkFlightIcon" />}
            </>
          )}
        </AirplaneInnerContainer>
      </AirplaneContainer>
      {isFlightPathLoading && selectedAircraftId === aircraftId ? (
        <LoadingFlightPathContainer bottom="32px" left="-65px" width="150px" padding="2px 0px 2px 10px">
          <LoadingSpinner
            id={getElementId(MAP_ELID_SECTION, 'flightPath', 'loading', 'spinner')}
            direction="row"
            caption="Loading Flight Path..."
            captionProps={LoadingFlightPathCaptionProps}
            size={15}
          />
        </LoadingFlightPathContainer>
      ) : (
        <></>
      )}
    </>
  );

  return internal ? (
    <>
      {AirplaneMarker}
      {AirplaneHoverMarker}
    </>
  ) : (
    <>
      <OverlayView
        mapPaneName={selectedAircraftId ? OverlayView.MAP_PANE : OverlayView.OVERLAY_LAYER}
        position={{lat: lastLatitude, lng: lastLongitude}}
        getPixelPositionOffset={(x, y) => ({x: -15, y: -15})}
      >
        {AirplaneMarker}
      </OverlayView>
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={{lat: lastLatitude, lng: lastLongitude}}
        getPixelPositionOffset={(x, y) => ({x: -15, y: -15})}
      >
        {selectedAircraftId === aircraftId ? AirplaneMarker : AirplaneHoverMarker}
      </OverlayView>
    </>
  );
};

export default MapAircraft;
