/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Confirmation Dialog Component
 */

import React from 'react';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {isNil} from 'lodash';
import {LoadingSpinner} from '@viasat/insights-components';
import {getElementIdFromSectionBase} from '../../../../utils/ElementIdUtils';

interface ConfirmationDialogProps {
  confirmationTitle: string;
  confirmationContent: string;
  errorMessage: string;
  handleClose: (confirm: boolean) => void;
  isLoading: boolean;
  showDialog: boolean;
}

const Spinner = styled.div`
  display: inline;
`;
const ConfirmationDialogActions = styled(DialogActions)`
  .dialog-action-btn {
    margin-right: 10px;
    color: black;
  }
`;

const ConfirmationDialog: React.FC<any> = (props: ConfirmationDialogProps) => {
  const {showDialog, handleClose, confirmationTitle, confirmationContent, errorMessage, isLoading} = props;
  const idBase = 'confirmation-dialog';
  return (
    <Dialog
      open={showDialog}
      onClose={() => handleClose(false)}
      id={getElementIdFromSectionBase(idBase, 'close', '0')}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{isNil(errorMessage) ? confirmationTitle : 'Error'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {isNil(errorMessage) ? confirmationContent : errorMessage}
        </DialogContentText>
      </DialogContent>
      <ConfirmationDialogActions>
        <Button
          className="dialog-action-btn"
          id={getElementIdFromSectionBase(idBase, 'cancel', '0')}
          onClick={() => handleClose(false)}
        >
          Cancel
        </Button>
        {isNil(errorMessage) ? (
          <Button
            className="dialog-action-btn"
            id={getElementIdFromSectionBase(idBase, 'continue', '0')}
            onClick={() => handleClose(true)}
            autoFocus
            disabled={isLoading}
          >
            Continue
            {isLoading ? (
              <>
                <Spinner>
                  <LoadingSpinner id={`${idBase}-loading-spinner`} isSpinner={true} size={15} />
                </Spinner>
              </>
            ) : (
              <></>
            )}
          </Button>
        ) : (
          <></>
        )}
      </ConfirmationDialogActions>
    </Dialog>
  );
};
export default ConfirmationDialog;
