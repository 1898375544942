/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List - Handover Failure History Query
 */

import moment from 'moment';
import {Query} from '../types';
import {NO_DATA_INDICATOR} from '../../../utils/constants';
import {DATE_TIME_VERBOSE_FORMAT} from '../../../utils/DateTimeUtils';

export interface IHandoverFailures {
  aircraftId: string;
  flightId: string;
  flightStartTstamp: string;
  handoverFailuresCount: number;
}

const transformRawHandoverFailureInfo = (handoverFailures: IHandoverFailures[] | null): IHandoverFailures[] | null => {
  if (!handoverFailures) return null;

  const handoverFailureInfo: IHandoverFailures[] = handoverFailures.map((historyInfo) => ({
    aircraftId: historyInfo.aircraftId,
    flightId: historyInfo.flightId,
    handoverFailuresCount: historyInfo.handoverFailuresCount,
    flightStartTstamp: historyInfo.flightStartTstamp
      ? moment.utc(new Date(historyInfo.flightStartTstamp)).format(DATE_TIME_VERBOSE_FORMAT)
      : NO_DATA_INDICATOR
  }));

  return handoverFailureInfo;
};

const handoverFailuresHistoryQuery: Query<IHandoverFailures[]> = {
  route: 'tailList/handoverFailures',
  transform: transformRawHandoverFailureInfo
};

export default handoverFailuresHistoryQuery;
