/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connectivity Outlook Page
 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import {useBeforeunload} from 'react-beforeunload';
import {orderBy} from 'lodash';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import PrintIcon from '@mui/icons-material/Print';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FlightTakeOffIcon from '@mui/icons-material/FlightTakeoff';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {LoadingBar, LoadingSpinner, SingleDatePicker} from '@viasat/insights-components';

import * as StyledElms from './ConnectivityOutlookStyles';
import PageContainerTemplate from '../common/layout/page/PageContainerTemplate';
import ConnectivityOutlookMap from './ConnectivityOutlookMap';
import EventsTimelineCard from './EventsTimelineCard';
import {useStore} from '../../store/Store';
import useFetch from '../../utils/useFetch';
import flightEventsQuery, {FlightPlanEvents} from '../../store/queries/connectivityPlanner/flightEventsQuery';
import {DATE_MONTH_NAME_AND_TIME_COMMA_SEPARATED, formatMomentInput} from '../../utils/DateTimeUtils';
import {AirportsType} from '../../utils/MapUtil';
import {ConnectivityPlannerAction} from '../../store/reducers/ConnectivityPlannerReducer';
import flightPlansByIdQuery, {FlightPlanInfo} from '../../store/queries/connectivityPlanner/flightPlansByIdQuery';
import flightPathQuery, {IFlightPath} from '../../store/queries/connectivityPlanner/flightPathQuery';
import {
  getUpdatedFlightPlans,
  DepartureTimeChange,
  resetFlightPlans,
  totalFlightDuration,
  formatFlightDuration
} from './ConnectivityOutlookUtils';
import airportsQuery from '../../store/queries/common/airportsQuery';
import EventsListCard from './EventsListCard';
import SaveDepartureTime from './SaveDepartureTime';
import {getSelectedLeg, getSelectedLegMapMarkers} from './SelectedFlightLegUtils';
import {
  DIALOG_ALERT_WARNING_COLOR,
  DIALOG_ALERT_WARNING_ICON_COLOR,
  EVENTS_CARD_FLIGHT_ICON_COLOR,
  VIASAT_LOGO_ICON_COLOR,
  WHITE
} from '../common/theme/Colors';
import {getElementIdFromSectionBase} from '../../utils/ElementIdUtils';
import DialogAlertPopup from '../common/DialogAlertPopup';
import ViasatLogoIcon from '../common/theme/icons/ViasatLogoIcon';
import {AppAction} from '../../store/reducers/AppReducer';
import StyledButton from '../common/StyledButton';
import {reflowChartCardsForPrint} from '../common/elements/chart/chartUtils';

const ConnectivityOutlookPage: React.FC<any> = () => {
  const {store, dispatch} = useStore();
  const printComponentRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const {selectedFlightPlans, flightPathInfo, flightPlanInfo, flightEventsInfo, selectedFlightLegIdx} =
    store.connectivityPlanner;
  const eventsCount = flightEventsInfo?.reduce((acc, curr) => acc + curr.events.length, 0);
  const checkEventsOverflow = eventsCount ? eventsCount > 12 : false;
  const [isDepartureTimeChanged, setIsDepartureTimeChanged] = useState(null);
  const [isFlightPathChanging, setIsFlightPathChanging] = useState(false);
  const [arePrereqsLoading, setArePrereqsLoading] = useState(true);
  const [timeOverlapWarningAlert, setTimeOverlapWarningAlert] = useState(false);
  const [totalDuration, setTotalDuration] = useState<number>(0);

  const copyrightYear = moment().utc().year();

  const intl = useIntl();
  const idBase = 'connectivityOutlook';
  const groupCode = store.customer.current.code;
  const flightPlanIds = JSON.stringify(
    selectedFlightPlans
      ? selectedFlightPlans?.map((flightPlan) => {
          return flightPlan.id;
        })
      : []
  );

  const isMultipleFlightLegs = JSON.parse(flightPlanIds)?.length > 1;

  const routeName = !arePrereqsLoading
    ? getSelectedLegMapMarkers(flightPathInfo, flightPlanInfo, selectedFlightLegIdx)
    : null;

  if (!flightPlanIds) {
    dispatch({
      type: ConnectivityPlannerAction.RESET_SELECTED_FLIGHT_PLANS,
      payload: []
    });
    navigate('/connectivity-outlook-planner');
  }
  const queryParamsWithFlightPlanIdsandGroupCode = useMemo(
    () => ({groupCode: groupCode, flightPlanIds: JSON.parse(flightPlanIds)}),
    [groupCode, flightPlanIds]
  );

  const emptyParams = useMemo(() => ({}), []);

  // Airports
  const {data: airportsList, isLoading: isAirportsLoading} = useFetch<AirportsType>(airportsQuery, emptyParams);

  // Flight plan information based on flight plan Id
  const {data: flightPlanData, isLoading: isFlightPlanDataLoading} = useFetch<FlightPlanInfo[]>(
    flightPlansByIdQuery,
    queryParamsWithFlightPlanIdsandGroupCode
  );
  const queryParamsWithFlightPlanIds = useMemo(() => ({flightPlanIds: JSON.parse(flightPlanIds)}), [flightPlanIds]);

  // Flight Events based on flight plan Id
  const {data: flightEventsData, isLoading: isFlightEventsLoading} = useFetch<FlightPlanEvents[]>(
    flightEventsQuery,
    queryParamsWithFlightPlanIds
  );

  // Flight Path based on flight plan Id
  const {data: flightPathData, isLoading: isFlightPathLoading} = useFetch<IFlightPath[]>(
    flightPathQuery,
    queryParamsWithFlightPlanIds
  );

  /**
   * set proper selected flight index on load. (-1 if single flight plan selected else the selected index remains the same)
   */
  useEffect(() => {
    if (!isMultipleFlightLegs || selectedFlightLegIdx >= JSON.parse(flightPlanIds)?.length) {
      dispatch({
        type: ConnectivityPlannerAction.SET_SELECTED_FLIGHT_LEG,
        payload: -1
      });
    }
  }, [isMultipleFlightLegs, selectedFlightLegIdx, flightPlanIds, dispatch]);

  useEffect(() => {
    setArePrereqsLoading(isAirportsLoading || isFlightPlanDataLoading || isFlightEventsLoading || isFlightPathLoading);
  }, [isAirportsLoading, isFlightPlanDataLoading, isFlightEventsLoading, isFlightPathLoading]);

  /**
   * When the user navigates to other tabs or pages without saving the edited departure time - to show the save popup
   */
  useEffect(() => {
    setIsDepartureTimeChanged(isDepartureTimeChanged);
    return () => {
      if (isDepartureTimeChanged && location.pathname !== '/connectivity-outlook-page') {
        dispatch({
          type: ConnectivityPlannerAction.SHOW_SAVE_MODAL,
          payload: true
        });
        navigate('/connectivity-outlook-page');
      } else {
        dispatch({
          type: ConnectivityPlannerAction.SET_SELECTED_FLIGHT_LEG,
          payload: -1
        });
        dispatch({
          type: ConnectivityPlannerAction.RESET_SELECTED_FLIGHT_PLANS,
          payload: []
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch]);

  /**
   * To show the browser defined message when the user refreshes the page without saving the edited departure time
   * @param event The default event parameter
   */
  const onPageRefresh = useBeforeunload((event: any) => {
    if (isDepartureTimeChanged) {
      event.preventDefault();
    }
  });
  /**
   * The before unload listener to be called when the user refreshes the browser page
   */
  useEffect(() => {
    window.addEventListener('beforeunload', onPageRefresh);
    return () => {
      window.removeEventListener('beforeunload', onPageRefresh);
    };
  }, [onPageRefresh]);

  /**
   * Edits the Departure time based on the date picker and reset values
   * This will return Alert box buttons to perform some actions like ok/discard changes
   */
  const DialogAlertBoxActions = () => {
    return (
      <>
        <button
          id={getElementIdFromSectionBase(idBase, 'ok', '0')}
          className="left-button"
          style={{width: 58, minWidth: 0}}
          onClick={() => setTimeOverlapWarningAlert(false)}
        >
          OK
        </button>
        <button
          id={getElementIdFromSectionBase(idBase, 'discard-changes', '0')}
          className="right-button"
          onClick={() => resetDepartureTime()}
        >
          Discard Changes
        </button>
      </>
    );
  };

  /**
   * Print page header component
   */
  const PrintPageHeader = (props) => {
    const {serialNumber, tailId, duration, routeName, top} = props;

    return (
      <StyledElms.PrintHeader top={top}>
        Connectivity Outlook for S/N: &nbsp;
        {serialNumber} /{tailId}
        <br />
        <div style={{display: 'flex', marginBottom: 8}}>
          {routeName?.map((route, id) => {
            return (
              <div key={`${route.name}-${id}`} id={`${route.name}-${id}`}>
                {route.name}
                {routeName.length - 1 === id ? (
                  ''
                ) : (
                  <ArrowForwardIcon style={{color: EVENTS_CARD_FLIGHT_ICON_COLOR, height: 18, width: 18}} />
                )}
                &nbsp;
              </div>
            );
          })}
          <div className="duration">{formatFlightDuration(duration)}</div>
        </div>
      </StyledElms.PrintHeader>
    );
  };

  /**
   * Edits or Resets the Departure time based on the date picker and reset values
   * @param date Modified Departure time from the date picker
   */
  const editDepartureTime = useCallback(
    (date: string) => {
      let updatedDepartureTime = date;
      setIsFlightPathChanging(true);
      setIsDepartureTimeChanged(true);
      const modifiedFlightData = getUpdatedFlightPlans(
        flightPlanInfo,
        flightPathInfo,
        flightEventsInfo,
        updatedDepartureTime,
        selectedFlightLegIdx
      );
      dispatch({
        type: ConnectivityPlannerAction.EDIT_DEPARTURE_TIME,
        payload: {
          modifiedFlightPath: modifiedFlightData.flightPaths,
          modifiedFlightPlanData: modifiedFlightData.flightPlans,
          modifiedFlightEventsData: modifiedFlightData.flightPlanEvents
        }
      });
      // Refreshing the flight path after 0.5 second to re-render the updated flight path
      setTimeout(() => {
        setIsFlightPathChanging(false);
      }, 500);
    },
    [flightPathInfo, flightPlanInfo, flightEventsInfo, selectedFlightLegIdx, dispatch]
  );
  /**
   * resets the Departure time to the original departure time
   */
  const resetDepartureTime = () => {
    setIsDepartureTimeChanged(false);
    setIsFlightPathChanging(true);
    const restoredFlightData = resetFlightPlans(flightPlanInfo, flightPathInfo, flightEventsInfo, selectedFlightPlans);
    dispatch({
      type: ConnectivityPlannerAction.EDIT_DEPARTURE_TIME,
      payload: {
        modifiedFlightPath: restoredFlightData.flightPaths,
        modifiedFlightPlanData: restoredFlightData.flightPlans,
        modifiedFlightEventsData: restoredFlightData.flightPlanEvents
      }
    });
    // Refreshing the flight path after 0.5 second to re-render the updated flight path
    setTimeout(() => {
      setIsFlightPathChanging(false);
    }, 500);
  };
  useEffect(() => {
    if (airportsList) {
      dispatch({
        type: AppAction.SET_AIRPORTS,
        payload: {
          airports: airportsList
        }
      });
    }
  }, [dispatch, airportsList]);

  useEffect(() => {
    if (!isFlightPathLoading) {
      dispatch({
        type: ConnectivityPlannerAction.SET_FLIGHT_PATH_DATA,
        payload: flightPathData
      });
    }
  }, [flightPathData, selectedFlightLegIdx, isFlightPathLoading, dispatch]);

  useEffect(() => {
    if (!isFlightPlanDataLoading) {
      flightPlanData?.map((flightPlan) => (flightPlan.isDepartureTimeChanged = DepartureTimeChange.NO_CHANGE));
      dispatch({
        type: ConnectivityPlannerAction.SET_FLIGHT_PLAN_DATA,
        payload: orderBy(flightPlanData, 'departureTime')
      });
    }
  }, [flightPlanData, isFlightPlanDataLoading, dispatch]);

  useEffect(() => {
    if (!isFlightEventsLoading) {
      dispatch({
        type: ConnectivityPlannerAction.SET_FLIGHT_EVENTS_DATA,
        payload: flightEventsData
      });
    }
  }, [flightEventsData, isFlightEventsLoading, dispatch]);

  /**
   * Navigates back to Flight Plan List
   */
  const gotoFlightPlanList = () => {
    if (!isDepartureTimeChanged) {
      dispatch({
        type: ConnectivityPlannerAction.SET_SELECTED_FLIGHT_LEG,
        payload: -1
      });
      dispatch({
        type: ConnectivityPlannerAction.RESET_SELECTED_FLIGHT_PLANS,
        payload: []
      });
      navigate('/connectivity-outlook-planner');
    } else {
      dispatch({
        type: ConnectivityPlannerAction.SHOW_SAVE_MODAL,
        payload: true
      });
    }
  };
  useEffect(() => {
    const autoShiftCheck = flightPlanInfo.some((item) => item.isDepartureTimeChanged === DepartureTimeChange.SHIFTED);
    setTimeOverlapWarningAlert(autoShiftCheck);
  }, [flightPlanInfo]);

  useEffect(() => {
    const flightDuration =
      !arePrereqsLoading && !isFlightPathLoading
        ? selectedFlightLegIdx === -1
          ? totalFlightDuration(flightPlanInfo)
          : flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)]?.totalElapsedTime
        : null;
    setTotalDuration(Number(flightDuration));
  }, [arePrereqsLoading, flightPlanInfo, isFlightPathLoading, selectedFlightLegIdx]);

  return (
    <PageContainerTemplate
      title={''}
      subtitle=""
      getFullElementId={(name, type) => `${idBase}--pageContainer__${name}-${type}`}
      leftStack={[
        <StyledElms.BackButton
          id="connectivity-outlook--header__back-button"
          key="back-button"
          onClick={() => gotoFlightPlanList()}
        >
          <ArrowBack />
        </StyledElms.BackButton>,
        <StyledElms.OutlookHeading key="connectivity-outlook-title">
          <span className="title">Flight Connectivity Planner</span>
          <span className="aircraft-info">
            {!arePrereqsLoading ? (
              `Flight Plans - ${flightPlanInfo[0]?.tailId} / ${flightPlanInfo[0]?.serialNumber}`
            ) : (
              <LoadingBar id={`outlook-title-loading`} width={200} height={20} />
            )}
          </span>
        </StyledElms.OutlookHeading>
      ]}
      rightStack={[
        <SaveDepartureTime
          idBase="connectivity-outlook-save-departure-time"
          key="save-btn"
          disabled={!isDepartureTimeChanged}
          isDepartureTimeChanged={isDepartureTimeChanged}
          setIsDepartureTimeChanged={setIsDepartureTimeChanged}
        />,
        <ReactToPrint
          trigger={() => (
            <StyledButton key="print-btn" disabled={false} id="connectivity-outlook--header__print-button">
              <PrintIcon sx={{fontSize: 24}} />
              <StyledElms.PrintText>Print</StyledElms.PrintText>
            </StyledButton>
          )}
          content={() => printComponentRef.current}
          onBeforeGetContent={() => {
            reflowChartCardsForPrint('1546px');
          }}
          onAfterPrint={() => {
            reflowChartCardsForPrint('auto');
          }}
        />
      ]}
    >
      <StyledElms.ConnectivityOutlookOverallBody ref={printComponentRef} height={checkEventsOverflow ? 4500 : 2300}>
        {!arePrereqsLoading ? (
          <PrintPageHeader
            serialNumber={flightPlanInfo && flightPlanInfo.length ? flightPlanInfo[0].serialNumber : '--'}
            tailId={flightPlanInfo && flightPlanInfo.length ? flightPlanInfo[0].tailId : '--'}
            routeName={routeName}
            duration={Number(totalDuration)}
          />
        ) : (
          <></>
        )}
        {arePrereqsLoading || isFlightPathChanging ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: arePrereqsLoading || isFlightPathChanging ? 'block' : 'none',
              zIndex: 5
            }}
          >
            <LoadingSpinner id="map-spinner" direction="column" caption="Loading Connectivity Outlook..." size={40} />
          </div>
        ) : (
          <>
            <StyledElms.ConnectivityOutlookBody key="connectivityOutlookBody">
              {timeOverlapWarningAlert ? (
                <DialogAlertPopup
                  idBase="connectivity-outlook"
                  id={getElementIdFromSectionBase('connectivity-outlook', 'auto-shift', '0')}
                  trigger={timeOverlapWarningAlert}
                  heading={intl.formatMessage({id: 'dialog_alert_popup.edit_departure_time_overlap.tittle'})}
                  message={intl.formatMessage({id: 'dialog_alert_popup.edit_departure_time_overlap.message'})}
                  dialogAlertBoxActions={<DialogAlertBoxActions />}
                  onClose={null}
                  dialogBoxHeight={195}
                  dialogMessageHeight={70}
                  dialogColor={DIALOG_ALERT_WARNING_COLOR}
                  dialogAlertIcon={
                    <WarningIcon style={{color: DIALOG_ALERT_WARNING_ICON_COLOR, paddingRight: '5px'}} />
                  }
                />
              ) : (
                <></>
              )}
              <EventsListCard
                isLoading={arePrereqsLoading || isFlightPathChanging}
                isMultipleFlightLegs={isMultipleFlightLegs}
                setIsFlightPathChanging={setIsFlightPathChanging}
                resetDepartureTime={resetDepartureTime}
                checkHeight={checkEventsOverflow}
              />
              <ConnectivityOutlookMap isLoading={arePrereqsLoading || isFlightPathChanging} />
            </StyledElms.ConnectivityOutlookBody>
            {checkEventsOverflow ? (
              <StyledElms.ContinueLabel>Continued on next page...</StyledElms.ContinueLabel>
            ) : (
              <></>
            )}
            {!isFlightPathLoading && !arePrereqsLoading ? (
              <StyledElms.EventsTimelineContainer>
                <StyledElms.EditDepartureTime
                  className={
                    flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)]?.isDepartureTimeChanged !==
                      DepartureTimeChange.NO_CHANGE && 'departure-changed'
                  }
                >
                  <FlightTakeOffIcon
                    style={{
                      width: 25,
                      height: 20,
                      color:
                        flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)]?.isDepartureTimeChanged !==
                        DepartureTimeChange.NO_CHANGE
                          ? WHITE
                          : EVENTS_CARD_FLIGHT_ICON_COLOR
                    }}
                  />
                  <SingleDatePicker
                    getFullElementId={(name: string, type: string) => `${name}-${type}`}
                    datePickerbuttonText={
                      flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)]?.isDepartureTimeChanged !==
                      DepartureTimeChange.NO_CHANGE
                        ? `Depart - ${formatMomentInput(
                            flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)]?.departureTime,
                            DATE_MONTH_NAME_AND_TIME_COMMA_SEPARATED
                          )}`
                        : `Edit Departure Time`
                    }
                    currentDate={moment
                      .utc(flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)]?.departureTime)
                      .format()}
                    onSetDate={(date) => {
                      editDepartureTime(date);
                    }}
                  />

                  {flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)]?.isDepartureTimeChanged !==
                    DepartureTimeChange.NO_CHANGE && (
                    <StyledElms.ResetDepartureTime onClick={() => resetDepartureTime()}>
                      <RotateLeftIcon />
                    </StyledElms.ResetDepartureTime>
                  )}
                </StyledElms.EditDepartureTime>
                <EventsTimelineCard
                  isLoading={arePrereqsLoading || isFlightPathChanging}
                  eventsTimelineChartHeight={195}
                />
              </StyledElms.EventsTimelineContainer>
            ) : (
              <></>
            )}
          </>
        )}
        <StyledElms.PrintFooter>
          <ViasatLogoIcon viewBox="0 0 66 22" style={{width: 66, height: 22}} htmlColor={VIASAT_LOGO_ICON_COLOR} />
          Copyright &copy; {copyrightYear} Viasat,Inc. All right reserved
        </StyledElms.PrintFooter>

        {checkEventsOverflow ? (
          <PrintPageHeader
            serialNumber={flightPlanInfo && flightPlanInfo.length ? flightPlanInfo[0].serialNumber : '--'}
            tailId={flightPlanInfo && flightPlanInfo.length ? flightPlanInfo[0].tailId : '--'}
            routeName={routeName}
            duration={Number(totalDuration)}
            top={2400}
          />
        ) : (
          <></>
        )}
      </StyledElms.ConnectivityOutlookOverallBody>
    </PageContainerTemplate>
  );
};

export default ConnectivityOutlookPage;
