/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Connectivity Planner reducers
 */
import {union, isEmpty, findIndex} from 'lodash';
import moment from 'moment';
import {Severity} from '@viasat/insights-components';
import {SnackbarOrigin} from '@mui/material';

import {
  QUERY_FIELD_MAPPING,
  flightPlanListDefaultStartDate,
  flightPlanListDefaultEndDate
} from '../../components/connectivityOutlook/ConnectivityOutlookUtils';
import {DATE_TIME_LOCAL_FORMAT} from '../../utils/DateTimeUtils';
import {AirportsType} from '../../utils/MapUtil';

import {delegateToReducers} from '../Reducer';
import {CommonAction} from '../types';
import {setFilters, setOrderColumnQuery, isValueChanged, setSharelinkHiddenColumns} from '../UrlMap';
import {ColumnSortReducer, ColumnSortState} from './ColumnSortReducer';
import {CustomerAction, CustomerReducer} from './CustomerReducer';
import {DatePickerState, validateDatePicker} from './DatePickerReducer';
import {FiltersReducer, FiltersState} from './FiltersReducer';
import {HideColumnsReducer, HideColumnsState, InitialHideColumnsState} from './HideColumnsReducer';
import {SnackBarReducer, SnackBarState, createSnackBar} from './SnackBarReducer';
import {CancelTokenState, CancelTokenReducer} from './CancelTokenReducer';
import {IFlightPath} from '../queries/connectivityPlanner/flightPathQuery';
import {FlightPlanEvents} from '../queries/connectivityPlanner/flightEventsQuery';
import {FlightPlanInfo} from '../queries/connectivityPlanner/flightPlansByIdQuery';

const snackBarPosition: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'bottom'
};
export const CONNECTIVITY_PLANNER_STORE_CONTEXT = 'connectivityPlanner';
export const CONNECTIVITY_PLANNER_ROUTE = '/connectivity-outlook-planner';

export const ConnectivityPlannerSessionStoreKeys: string[] = [
  'selectedFlightPlans',
  'filters.filters',
  'hideColumns.hiddenColumns',
  'sort.column',
  'sort.order',
  'sort.queryField',
  'dateRange',
  'selectedFlightLegIdx',
  'departureTime',
  'isShowSaveModal'
];

const InitialDatePickerState: DatePickerState = {
  startDate: flightPlanListDefaultStartDate(7),
  endDate: flightPlanListDefaultEndDate(7),
  persistStartDate: false,
  persistEndDate: false,
  snackBar: []
};

export enum ConnectivityPlannerAction {
  SET_SELECTED_AIRCRAFT = 'SET_SELECTED_AIRCRAFT',
  SET_SELECTED_FILES = 'SET_SELECTED_FILES',
  RESET_SELECTED_FILES = 'RESET_SELECTED_FILES',
  REMOVE_FILE_FROM_LIST = 'REMOVE_FILE_FROM_LIST',
  SET_FLIGHT_PLAN_CONTENT = 'SET_FLIGHT_PLAN_CONTENT',
  SET_SELECTED_FLIGHT_PLANS = 'SET_SELECTED_FLIGHT_PLANS',
  REMOVE_FLIGHT_PLAN_FROM_LIST = 'REMOVE_FLIGHT_PLAN_FROM_LIST',
  SET_SHOW_FLIGHT_PLAN_LIST_NOTIFICATION = 'SET_SHOW_FLIGHT_PLAN_LIST_NOTIFICATION',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  SET_NEW_FLIGHT_PLAN_COUNT = 'SET_NEW_FLIGHT_PLAN_COUNT',
  RESET_NEW_FLIGHT_PLAN_COUNT = 'RESET_NEW_FLIGHT_PLAN_COUNT',
  SET_AIRPORTS = 'SET_AIRPORTS',
  SET_DELETED_FLIGHT_PLAN_COUNT = 'SET_DELETED_FLIGHT_PLAN_COUNT',
  RESET_DELETED_FLIGHT_PLAN_COUNT = 'RESET_DELETED_FLIGHT_PLAN_COUNT',
  RESET_SELECTED_FLIGHT_PLANS = 'RESET_SELECTED_FLIGHT_PLANS',
  SET_AIRCRAFT_LIST = 'SET_AIRCRAFT_LIST',
  SAVE_FLIGHT_PLAN_ERROR = 'SAVE_FLIGHT_PLAN_ERROR',
  SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
  SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS',
  REMOVE_FLIGHT_PLAN_CONTENT = 'REMOVE_FLIGHT_PLAN_CONTENT',
  SET_IS_UPLOAD = 'SET_IS_UPLOAD',
  SET_FLIGHT_PLAN_DATA = 'SET_FLIGHT_PLAN_DATA',
  SET_FLIGHT_PATH_DATA = 'SET_FLIGHT_PATH_DATA',
  SET_FLIGHT_EVENTS_DATA = 'SET_FLIGHT_EVENTS_DATA',
  EDIT_DEPARTURE_TIME = 'EDIT_DEPARTURE_TIME',
  UPDATE_FLIGHT_PLAN_TAIL_ID = 'UPDATE_FLIGHT_PLAN_TAIL_ID',
  SET_SELECTED_FLIGHT_LEG = 'SET_SELECTED_FLIGHT_LEG',
  OVERWRITE_FLIGHT_PLAN = 'OVERWRITE_FLIGHT_PLAN',
  SAVE_AS_COPY_FLIGHT_PLAN = 'SAVE_AS_COPY_FLIGHT_PLAN',
  SAVE_FLIGHT_PLAN_SUCCESS = 'SAVE_FLIGHT_PLAN_SUCCESS',
  DEPARTURE_TIME_CHANGED = 'DEPARTURE_TIME_CHANGED',
  SHOW_SAVE_MODAL = 'SHOW_SAVE_MODAL',
  SET_SERIAL_NUMBER_TAIL_ID = 'SET_SERIAL_NUMBER_TAIL_ID',
  SET_NEW_UPLOADED_PLANS_ALERT = 'SET_NEW_UPLOADED_PLANS_ALERT',
  SET_VIEW_OUTLOOK_OVERLAP_ALERTS = 'SET_VIEW_OUTLOOK_OVERLAP_ALERTS',
  SET_LINK_SELECTED_FLIGHT_PLAN = 'SET_LINK_SELECTED_FLIGHTP_LAN',
  REFRESH_FLIGHT_PLAN_LIST = 'REFRESH_FLIGHT_PLAN_LIST'
}

export interface RoutePart {
  designator: string;
  trueAirspeedKnots: number;
  altitudeFt: number;
  latitude: number;
  longitude: number;
  groundSpeedKts?: number;
  timeSinceTakeoffMin?: number;
  trueCourseDeg?: number;
}

export interface EETEntry {
  designator: string;
  estimatedElapsedTime: number;
}

interface IFlightPlanContent {
  uniqueId?: number;
  flightPlanNumber: string;
  tailId: string;
  departureTime: string;
  departureAirport: string;
  flightType: string;
  aircraftType: string;
  flightRules: string;
  equipmentQualifiers: string;
  wakeTurbulenceCategory: string;
  aircraftCount: number;
  totalElapsedTime: number;
  destinationAirport: string;
  alternateDestinationAirport1: string;
  alternateDestinationAirport2: string;
  rawRoute: string;
  routes: RoutePart[];
  eetEntries: EETEntry[];
  routeListString: string;
  depatureTime?: string;
  arrivalTime?: string;
  departureTimeLocal?: string;
  arrivalTimeLocal?: string;
}

export interface IAircraftList {
  tailId: string;
  serialNumber: string;
  aircraftType: string;
  isSelected?: boolean;
}

export interface ConnectivityPlannerState {
  isLoading: boolean;
  flightPlanImportFiles: any;
  flightPlanContent: IFlightPlanContent[];
  selectedFlightPlans: FlightPlanInfo[];
  filters: FiltersState;
  hideColumns: HideColumnsState;
  sort: ColumnSortState;
  snackBar: SnackBarState;
  showFlightPlanListNotification: boolean;
  dateRange: DatePickerState;
  newFlightPlansCount: number;
  airports: AirportsType;
  deletedFlightPlansCount: number;
  aircraftList: IAircraftList[];
  cancelTokenSource: CancelTokenState[];
  flightPlanInfo: FlightPlanInfo[];
  flightPathInfo: IFlightPath[];
  flightEventsInfo: FlightPlanEvents[];
  selectedFlightLegIdx: number;
  isShowSaveModal: boolean;
  isAlertEnabled: boolean;
  flightPlanOverlap: boolean;
  refreshFlightPlans: boolean;
}

export const InitialConnectivityPlannerState: ConnectivityPlannerState = {
  isLoading: false,
  flightPlanImportFiles: [],
  flightPlanContent: [],
  selectedFlightPlans: [],
  filters: {
    filters: [],
    domainOptions: [],
    rangeOptions: {}
  },
  hideColumns: InitialHideColumnsState,
  sort: {
    options: undefined,
    column: 'departureTime',
    order: 'desc',
    queryField: QUERY_FIELD_MAPPING['departureTime']
  },
  snackBar: [],
  showFlightPlanListNotification: true,
  dateRange: InitialDatePickerState,
  newFlightPlansCount: 0,
  airports: undefined,
  deletedFlightPlansCount: 0,
  aircraftList: [],
  cancelTokenSource: [],
  flightPlanInfo: [],
  flightPathInfo: null,
  flightEventsInfo: [],
  selectedFlightLegIdx: -1,
  isShowSaveModal: false,
  isAlertEnabled: false,
  flightPlanOverlap: false,
  refreshFlightPlans: false
};

export const ConnectivityPlannerReducer = (state: ConnectivityPlannerState, action: any): ConnectivityPlannerState => {
  const snackBars =
    (state.deletedFlightPlansCount || state.newFlightPlansCount) && state.snackBar ? state.snackBar : [];
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      if (action.payload.reset) {
        state = {
          ...InitialConnectivityPlannerState,
          isLoading: true
        };
      }
      break;

    case CommonAction.PROCESS_URL_PARAMS:
      const updates: any = {};
      if (action.payload.path === CONNECTIVITY_PLANNER_ROUTE) {
        if (isValueChanged(state.dateRange.startDate, action.payload.params.connectivityPlannerStartDate)) {
          updates.startDate = action.payload.params.connectivityPlannerStartDate;
          updates.persistStartDate = true;
        }

        if (isValueChanged(state.dateRange.endDate, action.payload.params.connectivityPlannerEndDate)) {
          updates.endDate = action.payload.params.connectivityPlannerEndDate;
          updates.persistEndDate = true;
        }

        if (!isEmpty(updates)) {
          state.dateRange = validateDatePicker({
            ...state,
            ...updates
          });
        }
        state = setFilters(state, action.payload);
        state = setOrderColumnQuery(state, action.payload);
        state = setSharelinkHiddenColumns(state, action.payload, CONNECTIVITY_PLANNER_STORE_CONTEXT);
      }
      break;

    case ConnectivityPlannerAction.SET_SELECTED_FILES:
      action.payload.forEach((file) => {
        file.uniqueId = Math.floor(Math.random() * (5000 - 500) + 500);
      });
      const selectedFiles = union(state.flightPlanImportFiles, action.payload);
      return {
        ...state,
        flightPlanImportFiles: selectedFiles
      };
    case ConnectivityPlannerAction.UPDATE_FLIGHT_PLAN_TAIL_ID:
      const {flightPlan, selectedFlightPlanId, searchResults, selectedAircraftIndex, aircraftList} = action.payload;
      flightPlan.forEach((importedFile) => {
        if (importedFile.uniqueId === selectedFlightPlanId) {
          importedFile['error'] = '';
          importedFile.content.tailId = searchResults[selectedAircraftIndex]['tailId'];
          importedFile.content.serialNumber = searchResults[selectedAircraftIndex]['serialNumber'];
          importedFile.content.flightPlanNumber = importedFile.content.flightPlanNumber.includes('undefined')
            ? importedFile.content.flightPlanNumber.replace(
                'undefined',
                searchResults[selectedAircraftIndex]['serialNumber']
              )
            : importedFile.content.flightPlanNumber;
          importedFile.content.aircraftIdentifier = searchResults[selectedAircraftIndex]['tailId'];
          importedFile.content.aircraftType = searchResults[selectedAircraftIndex]['aircraftType'];
          const flightPlanContentCheck = findIndex(state.flightPlanContent, {
            uniqueId: importedFile.content.uniqueId
          });
          if (flightPlanContentCheck === -1) {
            state.flightPlanContent.push(importedFile.content);
          }
        }
      });
      aircraftList.forEach((aircraftListItem) => {
        aircraftListItem.isSelected = false;
      });
      return {
        ...state,
        flightPlanImportFiles: flightPlan,
        aircraftList: aircraftList
      };
    case ConnectivityPlannerAction.OVERWRITE_FLIGHT_PLAN: {
      const {flightPlan, selectedFlightPlanId} = action.payload;
      flightPlan.forEach((importedFile) => {
        if (importedFile.uniqueId === selectedFlightPlanId) {
          importedFile['error'] = '';
          importedFile.content.isOverwrite = true;
          const flightPlanContentCheck = findIndex(state.flightPlanContent, {
            uniqueId: importedFile.content.uniqueId
          });
          if (flightPlanContentCheck === -1) {
            state.flightPlanContent.push(importedFile.content);
          }
        }
      });
      return {
        ...state,
        flightPlanImportFiles: flightPlan
      };
    }
    case ConnectivityPlannerAction.SAVE_AS_COPY_FLIGHT_PLAN: {
      const {flightPlan, selectedFlightPlanId} = action.payload;
      flightPlan.forEach((importedFile) => {
        if (importedFile.uniqueId === selectedFlightPlanId) {
          importedFile['error'] = '';
          importedFile.content.isSaveCopy = true;
          const flightPlanContentCheck = findIndex(state.flightPlanContent, {
            uniqueId: importedFile.content.uniqueId
          });
          if (flightPlanContentCheck === -1) {
            state.flightPlanContent.push(importedFile.content);
          }
        }
      });
      return {
        ...state,
        flightPlanImportFiles: flightPlan
      };
    }
    case ConnectivityPlannerAction.SET_ERROR_MESSAGE: {
      const importFilesListIndex = findIndex(state.flightPlanImportFiles, {uniqueId: action.payload.fileId});
      if (importFilesListIndex > -1) {
        state.flightPlanImportFiles[importFilesListIndex]['error'] = action.payload.error;
        state.flightPlanImportFiles[importFilesListIndex]['content'] = action.payload.content;
      }
      return state;
    }

    case ConnectivityPlannerAction.SET_UPLOAD_PROGRESS: {
      const importFilesListIndex = findIndex(state.flightPlanImportFiles, {uniqueId: action.payload.fileId});
      if (importFilesListIndex > -1) {
        state.flightPlanImportFiles[importFilesListIndex]['progress'] = action.payload.progress;
      }

      return state;
    }
    case ConnectivityPlannerAction.SET_SERIAL_NUMBER_TAIL_ID: {
      const importFilesListIndex = findIndex(state.flightPlanImportFiles, {uniqueId: action.payload.fileId});
      if (importFilesListIndex > -1) {
        state.flightPlanImportFiles[importFilesListIndex]['content'] = {
          serialNumber: action.payload.serialNumber,
          tailId: action.payload.tailId
        };
      }

      return state;
    }
    case ConnectivityPlannerAction.SET_IS_UPLOAD: {
      const importFilesListIndex = findIndex(state.flightPlanImportFiles, {uniqueId: action.payload.fileId});
      if (importFilesListIndex > -1) {
        state.flightPlanImportFiles[importFilesListIndex]['isUploading'] = action.payload.isUploading;
      }

      return state;
    }
    case ConnectivityPlannerAction.RESET_SELECTED_FILES:
      return {
        ...state,
        flightPlanImportFiles: action.payload
      };

    case ConnectivityPlannerAction.REMOVE_FILE_FROM_LIST:
      const fileList = state.flightPlanImportFiles.filter((file) => file.uniqueId !== action.payload);

      return {
        ...state,
        flightPlanImportFiles: fileList
      };

    case ConnectivityPlannerAction.SET_AIRCRAFT_LIST:
      return {...state, aircraftList: action.payload};

    case ConnectivityPlannerAction.SET_FLIGHT_PLAN_CONTENT:
      const flightPlanContentCheck = findIndex(state.flightPlanContent, {
        uniqueId: action.payload.flightPlan.uniqueId
      });
      if (flightPlanContentCheck === -1) {
        const flightPlan = action.payload?.flightPlan;
        state.flightPlanContent.push(flightPlan);
      }
      return state;

    case ConnectivityPlannerAction.REMOVE_FLIGHT_PLAN_CONTENT:
      const contentList = action.payload?.emptyList
        ? []
        : state.flightPlanContent.filter((content) => content.uniqueId !== action.payload);
      return {...state, flightPlanContent: contentList};

    case ConnectivityPlannerAction.SET_NEW_FLIGHT_PLAN_COUNT: {
      return {
        ...state,
        newFlightPlansCount: action.payload
      };
    }

    case ConnectivityPlannerAction.SAVE_FLIGHT_PLAN_ERROR: {
      if (snackBars.length === 0 && action.payload) {
        snackBars.push(
          createSnackBar({
            message: action.payload,
            severity: Severity.INFO,
            getFullElementId: (name, type) => `${name}-${type}`,
            position: snackBarPosition
          })
        );
      }
      return {
        ...state,
        flightPlanContent: null,
        snackBar: snackBars
      };
    }

    case ConnectivityPlannerAction.SAVE_FLIGHT_PLAN_SUCCESS: {
      if (snackBars.length === 0 && action.payload) {
        snackBars.push(
          createSnackBar({
            message: action.payload,
            severity: Severity.SUCCESS,
            getFullElementId: (name, type) => `${name}-${type}`,
            position: snackBarPosition
          })
        );
      }
      return {
        ...state,
        flightPlanContent: null,
        snackBar: snackBars
      };
    }

    case ConnectivityPlannerAction.RESET_NEW_FLIGHT_PLAN_COUNT:
      return {
        ...state,
        newFlightPlansCount: 0,
        snackBar: []
      };

    case ConnectivityPlannerAction.SET_DELETED_FLIGHT_PLAN_COUNT: {
      if (snackBars.length === 0 && action.payload) {
        snackBars.push(
          createSnackBar({
            message: `${action.payload} flight plan(s) have been deleted`,
            severity: Severity.INFO,
            getFullElementId: (name, type) => `${name}-${type}`,
            position: snackBarPosition
          })
        );
      }
      return {
        ...state,
        deletedFlightPlansCount: action.payload,
        selectedFlightPlans: [],
        snackBar: snackBars
      };
    }

    case ConnectivityPlannerAction.RESET_SELECTED_FLIGHT_PLANS:
      state = {
        ...state,
        selectedFlightPlans: []
      };
      break;

    case ConnectivityPlannerAction.RESET_DELETED_FLIGHT_PLAN_COUNT:
      return {
        ...state,
        deletedFlightPlansCount: 0,
        snackBar: []
      };

    case ConnectivityPlannerAction.SET_SELECTED_FLIGHT_PLANS:
      const selectedFlightPlans = [...state.selectedFlightPlans, action.payload];
      selectedFlightPlans.sort((a, b) => moment(a.departureTime).utc().diff(moment(b.departureTime).utc()));
      state = {...state, selectedFlightPlans: selectedFlightPlans};
      break;

    case ConnectivityPlannerAction.REMOVE_FLIGHT_PLAN_FROM_LIST:
      const updatedSelectedFlightPlans = state.selectedFlightPlans.filter(function (value) {
        return value.id !== action.payload.id;
      });

      state = {
        ...state,
        selectedFlightPlans: updatedSelectedFlightPlans
      };
      break;

    case ConnectivityPlannerAction.SET_LINK_SELECTED_FLIGHT_PLAN:
      state.selectedFlightPlans = [];
      state = {...state, selectedFlightPlans: [action.payload]};
      break;

    case ConnectivityPlannerAction.SET_SHOW_FLIGHT_PLAN_LIST_NOTIFICATION:
      state = {...state, showFlightPlanListNotification: action.payload};
      break;
    case ConnectivityPlannerAction.SET_DATE_RANGE:
      const {startDate, endDate, persistStartDate, persistEndDate, viewContext} = action.payload;
      const copDateRange = {
        startDate: moment.utc(startDate).format(DATE_TIME_LOCAL_FORMAT),
        endDate: moment.utc(endDate).format(DATE_TIME_LOCAL_FORMAT),
        persistStartDate,
        persistEndDate
      };
      if (viewContext && viewContext === CONNECTIVITY_PLANNER_STORE_CONTEXT) {
        state = {
          ...state,
          dateRange: copDateRange
        };
      }

      break;
    case ConnectivityPlannerAction.SET_AIRPORTS:
      return {
        ...state,
        airports: action.payload.airports
      };
    case ConnectivityPlannerAction.SET_FLIGHT_PLAN_DATA:
      return {...state, flightPlanInfo: action.payload};
    case ConnectivityPlannerAction.SET_FLIGHT_PATH_DATA:
      return {...state, flightPathInfo: action.payload};
    case ConnectivityPlannerAction.SET_FLIGHT_EVENTS_DATA:
      return {...state, flightEventsInfo: action.payload};
    case ConnectivityPlannerAction.EDIT_DEPARTURE_TIME: {
      return {
        ...state,
        flightPathInfo: action.payload.modifiedFlightPath,
        flightPlanInfo: action.payload.modifiedFlightPlanData,
        flightEventsInfo: action.payload.modifiedFlightEventsData
      };
    }
    case ConnectivityPlannerAction.SHOW_SAVE_MODAL:
      return {...state, isShowSaveModal: action.payload};
    case ConnectivityPlannerAction.SET_SELECTED_FLIGHT_LEG:
      return {...state, selectedFlightLegIdx: action.payload};
    case ConnectivityPlannerAction.SET_NEW_UPLOADED_PLANS_ALERT:
      return {...state, isAlertEnabled: action.payload};
    case ConnectivityPlannerAction.SET_VIEW_OUTLOOK_OVERLAP_ALERTS:
      return {...state, flightPlanOverlap: action.payload};
    case ConnectivityPlannerAction.REFRESH_FLIGHT_PLAN_LIST:
      return {...state, refreshFlightPlans: action.payload};
    default:
      // NO STATE CHANGE
      break;
  }

  state = delegateToReducers(
    CONNECTIVITY_PLANNER_STORE_CONTEXT,
    state,
    action,
    {
      customer: CustomerReducer,
      filters: FiltersReducer,
      hideColumns: HideColumnsReducer,
      sort: ColumnSortReducer,
      snackBar: SnackBarReducer,
      cancelToken: CancelTokenReducer
    },
    InitialConnectivityPlannerState
  );
  return state;
};
