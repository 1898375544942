/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ChartWrapper Container for Satellite Health chart
 */

import {range} from 'lodash';
import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import {useIntl} from 'react-intl';

import {ChartType} from '../../common/elements/chart/types';
import {getLiveMask} from '../../common/elements/chart/RenderOverlay';
import ToolTipDateTimeLabel from '../../common/elements/chart/ToolTip/ToolTipDateTimeLabel';
import {adjustCategorizedChartData, ChartTimeSettings} from '../flightDetailsUtil';
import {HandoverStatusCode, ISatelliteHealthData} from '../../../store/queries/flightDetails/satelliteHealthQuery';
import SatelliteHealthToolTip from './tooltip/SatelliteHealthTooltip';

import {
  areMsTimestampMinutesEqualTo,
  DATE_CONCISE_FORMAT,
  msAsString,
  MS_IN_MIN,
  stringAsMs,
  TIME_CONCISE_NO_PAD_FORMAT
} from '../../../utils/DateTimeUtils';

import {
  CHART_AXIS_MINOR_TICK,
  LEGEND_TEXT,
  BEAM_CONNECTED,
  HANDOVER_SUCCESS,
  HANDOVER_FAILURE,
  INTERNAL_CHART_GRIDLINE
} from '../../common/theme/Colors';
import {formatConfig} from '../../common/elements/chart/chartUtils';
import ScatterPlotChart from '../../common/elements/chart/ScatterPlotChart';

interface SatelliteHealthProps {
  currentTime: string;
  isLoading: boolean;
  satelliteHealthData: ISatelliteHealthData[];
  chartTimeSettings: ChartTimeSettings;
  liveMask: boolean;
  isValueAddedReseller: boolean;
}

const ChartContainer = styled.div`
  .highcharts-xaxis-grid path {
    stroke-width: 0;
  }
  .highcharts-xaxis path {
    stroke-width: 1px;
    stroke: ${CHART_AXIS_MINOR_TICK};
  }
  .highcharts-grid.highcharts-yaxis-grid path {
    stroke: ${INTERNAL_CHART_GRIDLINE};
  }
  .highcharts-grid.highcharts-yaxis-grid path:nth-child(even) {
    stroke: none;
  }
`;

const biasUpIndex = (i: number) => i + 0.5;
const biasDownIndex = (i: number) => i - 0.5;

const getSeries = (satelliteIndex: any[], data: ISatelliteHealthData[]) => (code: HandoverStatusCode) =>
  data
    .filter(
      (row) =>
        row &&
        row.handoverStatus === code &&
        satelliteIndex.find((index) => index.satellite === row.satellite && index.beam === row.beam)
    )
    .map((row) => {
      const index = satelliteIndex.find(
        (index) => index && index.satellite === row.satellite && index.beam === row.beam
      ).index;
      const tstamp = stringAsMs(row.tstamp);
      return [tstamp, biasUpIndex(index)];
    });

/**
 * Create a Data structure to capture the order and beamId ranges from the raw data
 * @param localDate date/time string to be converted
 * @returns a list of beams, their satellite, their in order index on the graph and the time ranges a beam falls on the graph
 */
const getSatelliteIndex = (data: ISatelliteHealthData[]) => {
  const delim = ':';
  const beamSatelliteIndex = data.reduce(
    (memo, row) => {
      if (!row) {
        return memo;
      }
      const beamSatId = `${row.satellite}${delim}${row.beam}`;

      const currBeamRow = memo.beamIndex[beamSatId] || {minTs: Number.MAX_SAFE_INTEGER, maxTs: 0};
      return {
        beamIndex: {
          ...memo.beamIndex,
          [beamSatId]: {
            satellite: row.satellite,
            beam: row.beam,
            minTs: Math.min(currBeamRow.minTs, stringAsMs(row.tstamp)),
            maxTs: Math.max(currBeamRow.maxTs, stringAsMs(row.tstamp))
          }
        },
        satelliteList: memo.satelliteList.includes(row.satellite)
          ? memo.satelliteList
          : [...memo.satelliteList, row.satellite].sort().reverse()
      };
    },
    {
      beamIndex: {},
      satelliteList: []
    }
  );
  return Object.keys(beamSatelliteIndex.beamIndex).map((beamSatId) => {
    const beamIdIndex = beamSatelliteIndex.beamIndex[beamSatId];
    const index = beamIdIndex ? beamSatelliteIndex.satelliteList.indexOf(beamIdIndex.satellite) : -1;
    return {
      index,
      ...beamIdIndex
    };
  });
};

const SatelliteHealth: React.FC<SatelliteHealthProps> = ({
  currentTime,
  isLoading,
  satelliteHealthData,
  chartTimeSettings,
  liveMask,
  isValueAddedReseller
}) => {
  const [chartConfig, setChartConfig] = useState<any>(null);

  const intl = useIntl();

  useEffect(() => {
    if (!isLoading && satelliteHealthData && chartTimeSettings.start && chartTimeSettings.end) {
      // General config
      const id = 'flightDetails--SatelliteHealthCard__timeSeries-chart';
      const highchartsIdBase = 'SatelliteHealth';
      const chartType = ChartType.SCATTER;
      const height = 180;
      const marginLeft = 75;
      const marginRight = 25;
      const defaultChartPalette = [];
      const palette = [];
      const connectNulls = false;
      const disableMarker = true;

      // X-axis
      const xAxisTickPositions = [];
      let rawxAxisData = satelliteHealthData.map((i) => stringAsMs(i.tstamp));

      let {xAxisData, prependTimestamps, appendTimestamps} = adjustCategorizedChartData(
        rawxAxisData,
        [],
        chartTimeSettings,
        5,
        chartTimeSettings.tickIntervalMinutes / 2
      );

      const prependNullData = prependTimestamps.map((ts) => [ts, null]);
      const appendNullData = appendTimestamps.map((ts) => [ts, null]);

      const xAxisTickInterval = chartTimeSettings.tickIntervalMinutes * MS_IN_MIN;
      const xAxisLabelXOffset = 0;
      const xAxisFormatter = (value: any) => {
        // Note: Minor ticks are not supported for category-based axes
        if (Number(value) < 1e12 || !areMsTimestampMinutesEqualTo(value, chartTimeSettings.labeledTickMinutesValues)) {
          return '';
        }

        const hourLabel = msAsString(value, TIME_CONCISE_NO_PAD_FORMAT);
        const dayLabel = msAsString(value, DATE_CONCISE_FORMAT);
        return hourLabel === '0:00' ? dayLabel : hourLabel;
      };

      const satelliteIndex = getSatelliteIndex(satelliteHealthData);
      const uniqueIndices = satelliteIndex.reduce(
        (memo, {index}) => (memo.includes(index) ? memo : [...memo, index]),
        []
      );
      const yAxisLabelXOffset = uniqueIndices.length === 1 ? -23 : -10;
      const yAxisTitleOffset = 50;
      const yAxisMin = 0;
      const yAxisMax = uniqueIndices.length; // set height to the number of unique satellites
      const yAxisTickPositions = range(0, yAxisMax, 0.5);

      const PAD_IN_MS = (chartTimeSettings.tickIntervalMinutes / 2) * MS_IN_MIN; //matches padding in other charts
      const xAxisMin = stringAsMs(chartTimeSettings.start) - PAD_IN_MS;
      const xAxisMax = stringAsMs(chartTimeSettings.end) + PAD_IN_MS;
      const yAxisLabel = '';
      const yAxisTickInterval = null;

      const yAxisFormatter = (value: any) => {
        const index = value.pos;
        const biasIndex = biasDownIndex(index);
        if (biasIndex === Math.floor(index)) {
          const row = satelliteIndex.find((satellite) => satellite.index === biasIndex);
          if (row) {
            return row.satellite;
          }
        }
        return null;
      };

      // Series
      const seriesGetter = getSeries(satelliteIndex, satelliteHealthData);

      const series = [
        {
          name: !isValueAddedReseller ? 'Beam Connected' : 'Satellite Connected',
          color: BEAM_CONNECTED,
          data: seriesGetter(HandoverStatusCode.connected)
        },
        {
          name: 'Handover Success',
          color: HANDOVER_SUCCESS,
          data: seriesGetter(HandoverStatusCode.success),
          marker: {
            symbol: 'square'
          }
        },
        {
          name: 'Handover Failure',
          color: HANDOVER_FAILURE,
          // one of these needs extra data adjustments
          data: [...prependNullData, ...seriesGetter(HandoverStatusCode.failure), ...appendNullData],
          marker: {
            symbol: 'triangle'
          }
        }
      ];
      const liveMaskObj = getLiveMask(chartTimeSettings.start, chartTimeSettings.end, currentTime);
      // Tooltips
      const htmlTooltip = true;
      const tooltip = (i18n: any) => (input: any) => {
        const {y, x, color} = input;
        if (Number(x) < 1e12) {
          return {value: null};
        }
        const index = biasDownIndex(y);
        const row =
          satelliteIndex.find(
            (satellite) => satellite.index === index && x >= satellite.minTs && x <= satellite.maxTs
          ) || {};

        return {
          label: {
            value: <ToolTipDateTimeLabel dateTime={x} />
          },
          points: [
            {
              value: (
                <SatelliteHealthToolTip
                  idBase={`tooltip-${x}`}
                  color={color}
                  satellite={row.satellite}
                  beamId={row.beam}
                  handoverLabel={input.series.name}
                  isValueAddedReseller={isValueAddedReseller}
                />
              )
            }
          ],
          color: false
        };
      };

      // Handle noData Options
      const noDataOptions = {
        enabled: true,
        showEmptyXAxis: false
      };

      const legend = true;
      const legendItemStyle = {
        fontSize: '12px',
        fontWeight: '600',
        color: LEGEND_TEXT
      };

      setChartConfig({
        id,
        highchartsIdBase,
        chartType,
        height,
        marginRight,
        marginLeft,
        defaultChartPalette,
        palette,
        connectNulls,
        disableMarker,
        xAxisData,
        xAxisTickInterval,
        xAxisLabelXOffset,
        xAxisFormatter,
        xAxisTickPositions,
        yAxisLabel,
        yAxisLabelXOffset,
        yAxisMin,
        yAxisMax,
        yAxisTitleOffset,
        yAxisTickPositions,
        yAxisTickInterval,
        yAxisFormatter,
        series,
        htmlTooltip,
        tooltip,
        liveMask: {
          totalChartMinutes: liveMaskObj.totalChartMinutes,
          elapsedChartMinutes: liveMaskObj.elapsedChartMinutes,
          enabledLiveMask: liveMask,
          internal: true
        },
        legend,
        xAxisMin,
        xAxisMax,
        legendItemStyle,
        noDataOptions
      });
    }
    // eslint-disable-next-line
  }, [satelliteHealthData, chartTimeSettings]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer>
      {Object.keys(formattedConfig).length === 0 ? <></> : <ScatterPlotChart {...formattedConfig} />}
    </ChartContainer>
  );
};

export default SatelliteHealth;
