/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft Status List Util for handling the data cells
 */

import moment from 'moment';
import {DATE_TIME_LOCAL_FORMAT} from '../../utils/DateTimeUtils';
import {convertObjectToFilterRangeOptions, FilterOptionsType, FilterType} from '../../utils/filterUtils';
import {
  FORMAT_VALUE_DEFAULT_PRECISION,
  FORMAT_VALUE_DEFAULT_UNITS,
  FORMAT_VALUE_USE_SEPARATOR,
  FORMAT_VALUE_WITH_PRECISION,
  FORMAT_VALUE_WITH_UNITS
} from '../../utils/constants';
import {formatValue} from '../../utils/DateTimeUtils';

export enum FilterId {
  customer = 'customer',
  serialNumber = 'serialNumber',
  tailId = 'tailId',
  aircraftType = 'aircraftType',
  networkCapability = 'networkCapability',
  aircraftStatus = 'aircraftStatus'
}

export enum QUARTER {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth',
  FIFTH = 'fifth',
  SIXTH = 'sixth'
}

/**
 * Returns the range options for Customer filter
 * @param props Filter container properties
 * @returns Array of Customer Filter key/value pairs
 */
export const getCustomerFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.endUsersData ? convertObjectToFilterRangeOptions(props.endUsersData, 'name') : [];

/**
 * Returns the range options for the Serial Number filter
 * @param props Filter container properties
 * @returns Array of Serial Number Filter key/value pairs
 */
export const getSerialNumberFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'serialNumber') : [];

/**
 * Returns the range options for the Tail ID filter
 * @param props Filter container properties
 * @returns Array of Tail ID Filter key/value pairs
 */
export const getTailIdFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'tailId') : [];

/**
 * Returns the range options for the Aircraft Type filter
 * @param props Filter container properties
 * @returns Array of Aircraft Type Filter key/value pairs
 */
export const getAircraftTypeFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'aircraftType') : [];

/**
 * Returns the range options for the Network filter
 * @param props Filter container properties
 * @returns Array of Network Filter key/value pairs
 */
export const getNetworkFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'networkCapability') : [];

/**
 * Returns the filter options for Aircraft Status Filter
 * @returns Array of Aircraft Status Status key/value pairs
 */
export const getAircraftStatusFilterOptions = (): Array<FilterOptionsType> => [
  {
    optionKey: 'Active',
    optionValue: 'Active'
  },
  {
    optionKey: 'Decommissioned',
    optionValue: 'Decommissioned'
  }
];

export const SUMMARY_METRICS_FILTERS: Array<FilterType> = [
  {
    title: 'Customer',
    id: FilterId.customer,
    getValues: getCustomerFilterRangeOptions
  },
  {
    title: 'Tail ID',
    id: FilterId.tailId,
    getValues: getTailIdFilterRangeOptions
  },
  {
    title: 'Serial Number',
    id: FilterId.serialNumber,
    getValues: getSerialNumberFilterRangeOptions
  },
  {
    title: 'Network',
    id: FilterId.networkCapability,
    getValues: getNetworkFilterRangeOptions
  },
  {
    title: 'Aircraft Type',
    id: FilterId.aircraftType,
    getValues: getAircraftTypeFilterRangeOptions
  },
  {
    title: 'Aircraft Status',
    id: FilterId.aircraftStatus,
    getValues: getAircraftStatusFilterOptions
  }
];

/**
 * Performs a deep copy of the filter list and hides field(s) based on the
 * passed in parameters.
 * @param isEndUser End user flag
 * @param isInternal Internal user flag
 * @param isVarUser VAR user flag
 * @returns A list of filters for summary metrics
 */
export const getSummaryMetricsFilters = (
  isEndUser: boolean,
  isInternal: boolean,
  isVarUser: boolean
): Array<FilterType> => {
  const filterList = [
    ...(isEndUser
      ? SUMMARY_METRICS_FILTERS.filter((filter) => filter.id !== FilterId.customer)
      : SUMMARY_METRICS_FILTERS)
  ];
  const finalFilterList = [
    ...(isInternal || isVarUser ? filterList : filterList.filter((filter) => filter.id !== FilterId.aircraftStatus))
  ];

  return finalFilterList.sort((a: FilterType, b: FilterType): number => {
    return a.title > b.title ? 1 : -1;
  });
};

/** Offset from now in milliseconds to use as the maximum selectable time */
const OFFSET_FOR_MAX_DATE_IN_MILLISECONDS: number = 20 * 60 * 1000; // 20 minutes ago

/**
 * Gets the Start of the Month
 * @returns Default Start date for the DatePicker
 */
export const summaryMetricsDefaultStartDate = (delta: number) => {
  return moment.utc(getMaxMoment()).startOf('month').add(delta, 'month').format(DATE_TIME_LOCAL_FORMAT);
};

/**
 * Gets the End of the day NOW
 * @returns Default End date for the DatePicker
 */
export const summaryMetricsDefaultEndDate = (delta: number) => {
  return moment.utc(getMaxMoment()).endOf('day').add(delta, 'day').format(DATE_TIME_LOCAL_FORMAT);
};

/**
 * Gets the maximum selectable time in milliseconds,
 * by doing NOW - OFFSET_FOR_MAX_DATE_IN_MILLISECONDS
 *
 * @returns number Maximum selectable time in milliseconds
 */
const getMaxMillis = (): moment.Moment => moment.utc().subtract(OFFSET_FOR_MAX_DATE_IN_MILLISECONDS, 'milliseconds');

/**
 * Gets the maximum selectable time as a Moment object,
 * by doing NOW - OFFSET_FOR_MAX_DATE_IN_MILLISECONDS
 *
 * @returns Moment Maximum selectable time in a Moment object
 */
export const getMaxMoment = (): moment.Moment => moment.utc(getMaxMillis());

/**
 * Generates the default presets for summary metrics date picker
 */
export const getDatePickerPresets = () => {
  const currentMonth = moment.utc().month() + 1;

  const firstMonthOfAllQuarters = [1, 4, 7, 10];

  const datePickerPresets = [
    {
      id: 'dateRangeMonthToDate',
      className: 'month_to_date',
      dateRange: [0, 0],
      label: 'Month to Date',
      customDelta: true,
      deltaUnit: ['month']
    },
    {
      id: 'dateRangeLastMonth',
      className: 'last_month',
      dateRange: [-1, -1],
      label: 'Last Month',
      customDelta: true,
      deltaUnit: ['month', 'month']
    },
    {
      id: 'dateRangeLastQuarter',
      className: 'last_quarter',
      dateRange: [-1, -1],
      label: 'Last Quarter',
      customDelta: true,
      deltaUnit: ['quarter', 'quarter']
    }
  ];
  if (!firstMonthOfAllQuarters.includes(currentMonth)) {
    datePickerPresets.push({
      id: 'dateRangeQuarterToDate',
      className: 'quarter_to_date',
      dateRange: [0, 0],
      label: 'Quarter to Date',
      customDelta: true,
      deltaUnit: ['quarter']
    });
  }
  datePickerPresets.push({
    id: 'dateRangeLastYear',
    className: 'last_365_days',
    dateRange: [-1, -1],
    label: 'Last 365 days',
    customDelta: true,
    deltaUnit: ['year', 'year']
  });
  return datePickerPresets;
};

/**
 * Data Usage value formatter
 * @param value Data Usage GB
 * @param withPrecision Display with precision 1, otherwise omit (optional, default=true)
 * @param withUnits Display with 'GB', otherwise omit (optional, default=true)
 * @return Formatted Data Usage value as a string
 */
export const dataUsageValueFormatter = (
  value: number,
  withPrecision: boolean = FORMAT_VALUE_WITH_PRECISION,
  withUnits: boolean = FORMAT_VALUE_WITH_UNITS
): string => {
  const precision = withPrecision ? 1 : FORMAT_VALUE_DEFAULT_PRECISION;
  const units = withUnits ? ' GB' : FORMAT_VALUE_DEFAULT_UNITS;
  return formatValue(value, precision, units, FORMAT_VALUE_USE_SEPARATOR);
};

/**
 * Convert data usage in MB to GB
 * @param value Data Usage in MB
 */
export const convertToGb = (dataInMb: number) => {
  return dataInMb / 1000;
};
/**
 * Convert data usage in GB to MB
 * @param value Data usage in GB
 */
export const convertToMb = (dataInGb: number) => {
  return dataInGb * 1000;
};
/**
 * Gets GB/Hr based on total usage and month
 * @param totalUsage Data Usage in MB
 * @param month name of the month
 */
export const getGbPerHr = (totalUsage: number, connectedTime: number) => {
  if (totalUsage && connectedTime) {
    return totalUsage / Math.ceil(connectedTime / 60);
  } else {
    return 0;
  }
};

/**
 * Gets MB/Hr based on total usage and month
 * @param totalUsage Data Usage in MB
 * @param month name of the month
 */
export const getMbPerHr = (totalUsage: number, connectedTime: number) => {
  if (totalUsage && connectedTime) {
    return (totalUsage / Math.ceil(connectedTime / 60)) * 1000;
  } else {
    return 0;
  }
};
/**
 * Converts Month Name to Short Name
 * @param monthName name of the month
 */
export const formatMonth = (monthName: string, year: string) => {
  return moment().month(monthName).format('MMM') + `'` + year.slice(-2);
};

/**
 * Formats traffic composition cumulative string as needed
 * @param dateStr
 * @returns Formatted date string
 */
export const formatChartDateRange = (dateStr: string): string => {
  const dateObject = new Date(dateStr);
  const shortMonth = dateObject.toLocaleString('en-US', {month: 'short'}).toUpperCase();
  const date = dateObject.toLocaleString('en-US', {day: '2-digit'});
  const hours = ('0' + dateObject.getHours()).slice(-2);
  const mins = ('0' + dateObject.getMinutes()).slice(-2);
  const formattedDate = `${date} ${shortMonth} ${hours}:${mins}`;
  return formattedDate;
};
