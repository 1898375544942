/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Events Timeline ToolTip Style and Formatting
 */

import React from 'react';
import moment from 'moment';
import {ColorProperty} from 'csstype';
import styled from '@emotion/styled';

import SeriesPrefixIcon from '../../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesLabel from '../../../common/elements/chart/ToolTip/SeriesLabel';
import {FONT_WEIGHT_SEMIBOLD} from '../../../../utils/constants';
import {MAP_POPUP_ACCENT_COLOR} from '../../../common/theme/Colors';
import {formatStartEndDurationValue} from '../../../../utils/DateTimeUtils';

export interface EventDetailInfo {
  prefixIconColor?: ColorProperty;
  label: string;
  dtLow: moment.MomentInput;
  dtHigh?: moment.MomentInput;
}

export interface EventsTimelineToolTipProps {
  prefixIconColor?: ColorProperty;
  eventName: number | string;
  dtLow: moment.MomentInput;
  dtHigh: moment.MomentInput;
  seriesLabelWidth?: string;
}

const ConnectivityDuration = styled.div`
  white-space: nowrap;
  font-weight: 600;
  margin-left: 50px;
  font-size: 10px;
  color: ${MAP_POPUP_ACCENT_COLOR};
  display: inline-block;
  vertical-align: super;
`;

export const EventsTimelineToolTip: React.FC<EventsTimelineToolTipProps> = ({
  prefixIconColor,
  eventName,
  dtLow,
  dtHigh,
  seriesLabelWidth
}: EventsTimelineToolTipProps): JSX.Element => {
  return (
    <>
      <div
        style={{
          display: 'block',
          margin: '0 0 4px 0'
        }}
      >
        <SeriesPrefixIcon color={prefixIconColor} />
        <SeriesLabel
          label={eventName}
          fontWeight={FONT_WEIGHT_SEMIBOLD}
          width={seriesLabelWidth ? seriesLabelWidth : '90px'}
        />
        <ConnectivityDuration>({formatStartEndDurationValue(dtLow, dtHigh)})</ConnectivityDuration>
      </div>
    </>
  );
};

export default EventsTimelineToolTip;
