/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Hook to listen to the location hook and send out spa coms events
 */

import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {spaComs} from '@viasat/insights-spa-package';

/**
 * Creates a customer selector disable hook based on the location
 * @returns Sends the Spa Communication event to disable the group
 */
export default function useCustomerSelectorDisabler(): void {
  const disableListRegEx = [new RegExp('/flight/details/.*', 'i'), new RegExp('/connectivity-outlook-page', 'i')];
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    const disable = disableListRegEx.some(reg => location.pathname.match(reg));
    if (isDisabled !== disable) {
      spaComs.sendGroupDisable(disable);
      setIsDisabled(disable);
    }
    // eslint-disable-next-line
  }, [location]);

  return void 0;
}
