/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Fleet Map reducers
 */

import {Severity} from '@viasat/insights-components';
import {FlightPathDataType} from '../../components/mapView/MapFlightPathUtil';
import {matchesSelectedSatBeamFromState, ISelectedSatBeam} from '../../components/mapView/settings/MapHelper';
import {AircraftStatus} from '../queries/fleetMap/aircraftStatusQuery';
import {delegateToReducers} from '../Reducer';
import {CommonAction} from '../types';
import {setFilters, updateKuKaSatelliteState} from '../UrlMap';
import {CustomerAction, CustomerReducer} from './CustomerReducer';
import {FiltersReducer, FiltersState, InitialFiltersState} from './FiltersReducer';
import {createSnackBar, SnackBarReducer, SnackBarState} from './SnackBarReducer';
import {SatelliteBeamIncidentEvent} from '../queries/fleetMap/satelliteBeamIncidentEvents';

export const FLEET_MAP_STORE_CONTEXT = 'fleetMap';
export const FLEET_MAP_ROUTE = '/fleet-map';

export const FleetMapSessionStoreKeys: string[] = [
  'filters.filters',
  'overlays.showKaOverlay',
  'overlays.showKuOverlay',
  'overlays.kaRegionalServiceCoverage',
  'overlays.kuRegionalServiceCoverage',
  'overlays.showTxInhibitZoneBelow10kFt',
  'overlays.showTxInhibitZoneAllAltitudes',
  'overlays.selectedKaSatellites',
  'overlays.selectedKuSatellites',
  'overlays.showConnectedBeams',
  'tags.tailTagsOpen',
  'tags.tagSizeLarge',
  'view.zoom',
  'view.center',
  'view.isolateRoute',
  'view.satBeam',
  'selectedAircraftId'
];

export enum FleetMapAction {
  SET_SHOW_KU_OVERLAY = 'SET_SHOW_KU_OVERLAY',
  SET_SHOW_KA_OVERLAY = 'SET_SHOW_KA_OVERLAY',
  SET_SHOW_KA_REGIONAL_OVERLAY = 'SET_SHOW_KA_REGIONAL_OVERLAY',
  SET_SHOW_KU_REGIONAL_OVERLAY = 'SET_SHOW_KU_REGIONAL_OVERLAY',
  SET_TX_INHIBIT_ZONE_BELOW_10K_FEET = 'SET_TX_INHIBIT_ZONE_BELOW_10K_FEET',
  SET_TX_INHIBIT_ZONE_AT_ALL_ALTITUDES = 'SET_TX_INHIBIT_ZONE_AT_ALL_ALTITUDES',

  SET_TAIL_TAG_OPEN = 'SET_TAIL_TAG_OPEN',
  SET_TAG_SIZE_LARGE = 'SET_TAG_SIZE_LARGE',

  RESET_VIEW = 'RESET_VIEW',
  SET_VIEW_CENTER = 'SET_VIEW_CENTER',
  SET_VIEW_ZOOM = 'SET_VIEW_ZOOM',
  SET_ISOLATE_ROUTE = 'SET_ISOLATE_ROUTE',
  ISOLATE_MODE_EXITED_SNACK_BAR = 'ISOLATE_MODE_EXITED_SNACK_BAR',

  SET_FILTERED_DATA = 'SET_FILTERED_DATA',

  SET_SELECTED_AIRCRAFT = 'SET_SELECTED_AIRCRAFT',
  SELECTED_AIRCRAFT_NOT_AVAILABLE_SNACK_BAR = 'SELECTED_AIRCRAFT_NOT_AVAILABLE_SNACK_BAR',
  SET_SELECTED_OFFLINE_AIRCRAFT = 'SET_SELECTED_OFFLINE_AIRCRAFT',

  SET_FLIGHT_PATH_UNAVAILABLE_CONTAINER = 'SET_FLIGHT_PATH_UNAVAILABLE_CONTAINER',
  FAIL_REQUEST = 'FAIL_REQUEST',
  SET_KA_SATELLITES = 'SET_KA_SATELLITES',
  SET_KU_SATELLITES = 'SET_KU_SATELLITES',
  SET_CONNECTED_BEAMS = 'SET_CONNECTED_BEAMS',
  SET_SATELLITE_BEAMS = 'SET_SATELLITE_BEAMS',
  RESET_SATELLITE_BEAMS = 'RESET_SATELLITE_BEAMS',
  VALIDATE_SATELLITE_BEAMS = 'VALIDATE_SATELLITE_BEAMS',
  SET_SATELLITE_BEAM_INCIDENT_EVENTS = 'SET_SATELLITE_BEAM_INCIDENT_EVENTS'
}

export interface OverlaysState {
  showKaOverlay: string;
  showKuOverlay: string;
  kaRegionalServiceCoverage: string[];
  kuRegionalServiceCoverage: string[];
  selectedKaSatellites: number[];
  selectedKuSatellites: string[];
  showConnectedBeams: string;
  showTxInhibitZoneBelow10kFt: string;
  showTxInhibitZoneAllAltitudes: string;
  satelliteBeamIncidentEvents: SatelliteBeamIncidentEvent[];
}

export const InitialOverlaysState: OverlaysState = {
  showKaOverlay: undefined,
  showKuOverlay: undefined,
  kaRegionalServiceCoverage: [],
  kuRegionalServiceCoverage: [],
  selectedKaSatellites: [],
  selectedKuSatellites: [],
  showConnectedBeams: undefined,
  showTxInhibitZoneBelow10kFt: undefined,
  showTxInhibitZoneAllAltitudes: undefined,
  satelliteBeamIncidentEvents: []
};

export interface TagsState {
  tailTagsOpen: string;
  tagSizeLarge: string;
}

export const InitialTagsState: TagsState = {
  tailTagsOpen: undefined,
  tagSizeLarge: undefined
};

export interface ViewState {
  zoom: string;
  center: string;
  isolateRoute: string;
  satBeam?: ISelectedSatBeam;
}

export const InitialViewState: ViewState = {
  zoom: undefined,
  center: undefined,
  isolateRoute: undefined,
  satBeam: undefined
};

export interface FleetMapState {
  filters: FiltersState;
  overlays: OverlaysState;
  tags: TagsState;
  view: ViewState;

  aircraftStatusData: AircraftStatus[];
  filteredData: AircraftStatus[];

  selectedAircraftId: string;
  selectedOfflineAircraft: string;

  isFlightPathLoading: boolean;
  flightPathUnavailable: boolean;
  flightPath: FlightPathDataType;

  isMetricsLoading: boolean;
  isLoading: boolean;
  readyToRefreshData: boolean;
  error: string;

  snackBar: SnackBarState;
}

export const InitialFleetMapState: FleetMapState = {
  filters: InitialFiltersState,
  overlays: InitialOverlaysState,
  tags: InitialTagsState,
  view: InitialViewState,

  aircraftStatusData: [],
  filteredData: null,

  selectedAircraftId: null,
  selectedOfflineAircraft: null,

  isFlightPathLoading: false,
  flightPathUnavailable: false,
  flightPath: null,

  isMetricsLoading: true,
  isLoading: false,
  readyToRefreshData: false,
  error: null,

  snackBar: []
};

export const FleetMapReducer = (state: FleetMapState, action: any): FleetMapState => {
  const snackBars = state.snackBar ? state.snackBar : [];
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      if (action.payload.reset) {
        state = {
          ...InitialFleetMapState,
          isLoading: true
        };
      }
      break;

    case CommonAction.PROCESS_URL_PARAMS:
      if (action.payload.path === FLEET_MAP_ROUTE) {
        state = setFilters(state, action.payload);

        state.overlays = InitialOverlaysState;
        if (action.payload.params.overlays) {
          const {overlays} = action.payload.params;
          state.overlays = overlays;
          state = updateKuKaSatelliteState(state, overlays);
        }

        state.tags = InitialTagsState;
        if (action.payload.params.tags) {
          const {tags} = action.payload.params;
          state.tags = tags;
        }

        state.view = InitialViewState;
        if (action.payload.params.view) {
          const {view} = action.payload.params;
          state.view = view;
        }

        if (action.payload.params.selectedAircraftId) {
          state.selectedAircraftId = action.payload.params.selectedAircraftId;
        }
      }
      break;

    case FleetMapAction.SET_SHOW_KU_OVERLAY:
      state = {
        ...state,
        overlays: {
          ...state.overlays,
          showKaOverlay: state.overlays.showKaOverlay,
          showKuOverlay: action.payload,
          showTxInhibitZoneAllAltitudes: state.overlays.showTxInhibitZoneAllAltitudes,
          showTxInhibitZoneBelow10kFt: state.overlays.showTxInhibitZoneBelow10kFt
        }
      };
      break;

    case FleetMapAction.SET_SHOW_KA_OVERLAY:
      state = {
        ...state,
        overlays: {
          ...state.overlays,
          showKaOverlay: action.payload,
          showKuOverlay: state.overlays.showKuOverlay,
          showTxInhibitZoneAllAltitudes: state.overlays.showTxInhibitZoneAllAltitudes,
          showTxInhibitZoneBelow10kFt: state.overlays.showTxInhibitZoneBelow10kFt
        }
      };
      break;
    case FleetMapAction.SET_SATELLITE_BEAM_INCIDENT_EVENTS:
      state = {
        ...state,
        overlays: {
          ...state.overlays,
          satelliteBeamIncidentEvents: action.payload
        }
      };
      break;

    case FleetMapAction.SET_TX_INHIBIT_ZONE_BELOW_10K_FEET:
      state = {
        ...state,
        overlays: {
          ...state.overlays,
          showKaOverlay: state.overlays.showKaOverlay,
          showKuOverlay: state.overlays.showKuOverlay,
          showTxInhibitZoneBelow10kFt: action.payload
        }
      };
      break;
    case FleetMapAction.SET_TX_INHIBIT_ZONE_AT_ALL_ALTITUDES:
      state = {
        ...state,
        overlays: {
          ...state.overlays,
          showKaOverlay: state.overlays.showKaOverlay,
          showKuOverlay: state.overlays.showKuOverlay,
          showTxInhibitZoneAllAltitudes: action.payload
        }
      };
      break;
    case FleetMapAction.SET_KA_SATELLITES:
      const curKaSatellites = [...state.overlays.selectedKaSatellites];
      if (action.payload.checked) {
        if (!curKaSatellites.includes(action.payload.satelliteId)) {
          state = {
            ...state,
            overlays: {
              ...state.overlays,
              selectedKaSatellites: [...curKaSatellites, action.payload.satelliteId]
            }
          };
        }
      } else {
        state = {
          ...state,
          overlays: {
            ...state.overlays,
            selectedKaSatellites: curKaSatellites.filter((satelliteId) => satelliteId !== action.payload.satelliteId)
          }
        };
      }
      break;
    case FleetMapAction.SET_KU_SATELLITES:
      const curKuSatellites = [...state.overlays.selectedKuSatellites];
      if (action.payload.checked) {
        if (!curKuSatellites.includes(action.payload.satelliteId)) {
          state = {
            ...state,
            overlays: {
              ...state.overlays,
              selectedKuSatellites: [...curKuSatellites, action.payload.satelliteId]
            }
          };
        }
      } else {
        state = {
          ...state,
          overlays: {
            ...state.overlays,
            selectedKuSatellites: curKuSatellites.filter((satelliteId) => satelliteId !== action.payload.satelliteId)
          }
        };
      }
      break;
    case FleetMapAction.SET_SHOW_KA_REGIONAL_OVERLAY:
      const curKaRegionServiceCoverage = state.overlays.kaRegionalServiceCoverage
        ? [...state.overlays.kaRegionalServiceCoverage]
        : [];
      if (action.payload.checked) {
        if (!curKaRegionServiceCoverage.includes(action.payload.region)) {
          state = {
            ...state,
            overlays: {
              ...state.overlays,
              kaRegionalServiceCoverage: [...curKaRegionServiceCoverage, action.payload.region]
            }
          };
        }
      } else {
        state = {
          ...state,
          overlays: {
            ...state.overlays,
            kaRegionalServiceCoverage: curKaRegionServiceCoverage.filter((region) => region !== action.payload.region)
          }
        };
      }
      break;
    case FleetMapAction.SET_SHOW_KU_REGIONAL_OVERLAY:
      const curKuRegionServiceCoverage = state.overlays.kuRegionalServiceCoverage
        ? [...state.overlays.kuRegionalServiceCoverage]
        : [];
      if (action.payload.checked) {
        if (!curKuRegionServiceCoverage.includes(action.payload.region)) {
          state = {
            ...state,
            overlays: {
              ...state.overlays,
              kuRegionalServiceCoverage: [...curKuRegionServiceCoverage, action.payload.region]
            }
          };
        }
      } else {
        state = {
          ...state,
          overlays: {
            ...state.overlays,
            kuRegionalServiceCoverage: curKuRegionServiceCoverage.filter((region) => region !== action.payload.region)
          }
        };
      }
      break;
    case FleetMapAction.SET_CONNECTED_BEAMS:
      state = {
        ...state,
        overlays: {...state.overlays, showConnectedBeams: action.payload}
      };
      break;
    case FleetMapAction.SET_TAIL_TAG_OPEN:
      state = {
        ...state,
        tags: {tailTagsOpen: action.payload, tagSizeLarge: state.tags.tagSizeLarge}
      };
      break;

    case FleetMapAction.SET_TAG_SIZE_LARGE:
      state = {
        ...state,
        tags: {tailTagsOpen: state.tags.tailTagsOpen, tagSizeLarge: action.payload}
      };
      break;

    case FleetMapAction.RESET_VIEW:
      state = {
        ...state,
        view: {
          ...InitialFleetMapState.view
        }
      };
      break;

    case FleetMapAction.SET_VIEW_CENTER:
      state = {
        ...state,
        view: {
          zoom: state.view.zoom,
          center: action.payload,
          isolateRoute: state.view.isolateRoute
        }
      };
      break;

    case FleetMapAction.SET_VIEW_ZOOM:
      state = {
        ...state,
        view: {
          zoom: action.payload,
          center: state.view.center,
          isolateRoute: state.view.isolateRoute
        }
      };
      break;

    case FleetMapAction.SET_ISOLATE_ROUTE:
      state = {
        ...state,
        view: {
          zoom: state.view.zoom,
          center: state.view.center,
          isolateRoute: action.payload
        }
      };
      break;

    case FleetMapAction.ISOLATE_MODE_EXITED_SNACK_BAR: {
      const intl = action.payload.intl;
      const isolateRouteExitMsg = intl.formatMessage({
        id: 'fleet_map.isolate_route_exit.snackMsg'
      });
      snackBars.push(
        createSnackBar({
          message: isolateRouteExitMsg,
          severity: Severity.INFO,
          getFullElementId: (name, type) => `${name}-${type}`
        })
      );
      return {
        ...state,
        snackBar: [...state.snackBar]
      };
    }

    case FleetMapAction.SET_FILTERED_DATA:
      sessionStorage.selectedFilters = JSON.stringify(state.filters);
      return {
        ...state,
        filteredData: action.payload.filteredData
      };

    case FleetMapAction.SET_SELECTED_AIRCRAFT:
      return {
        ...state,
        selectedAircraftId: action.payload
      };

    case FleetMapAction.SELECTED_AIRCRAFT_NOT_AVAILABLE_SNACK_BAR:
      {
        const intl = action.payload.intl;
        const aircraftNotAvailableMessage = intl.formatMessage(
          {id: 'fleet_map.selected_aircraft.not_available.snackMsg'},
          {aircraftId: action.payload.aircraftId}
        );
        snackBars.push(
          createSnackBar({
            message: aircraftNotAvailableMessage,
            severity: Severity.WARNING,
            getFullElementId: (name, type) => `${name}-${type}`
          })
        );
        state = {
          ...state,
          snackBar: snackBars
        };
      }

      break;

    case FleetMapAction.SET_SELECTED_OFFLINE_AIRCRAFT:
      return {
        ...state,
        selectedOfflineAircraft: action.payload
      };

    case FleetMapAction.SET_FLIGHT_PATH_UNAVAILABLE_CONTAINER:
      return {
        ...state,
        flightPathUnavailable: action.payload
      };

    case FleetMapAction.SET_SATELLITE_BEAMS:
      state = {
        ...state,
        view: {
          zoom: state.view.zoom,
          center: state.view.center,
          isolateRoute: state.view.isolateRoute,
          satBeam: action.payload
        }
      };
      break;
    case FleetMapAction.RESET_SATELLITE_BEAMS:
      state = {
        ...state,
        view: {
          ...state.view,
          satBeam: InitialViewState.satBeam
        }
      };
      break;
    case FleetMapAction.VALIDATE_SATELLITE_BEAMS:
      state = validateAndResetViewSelectedSatBeam(state, action.payload.satBeam);
      break;
    case FleetMapAction.FAIL_REQUEST:
      return {...state, error: action.payload, isLoading: false};

    default:
      // NO STATE CHANGE
      break;
  }

  state = delegateToReducers(
    FLEET_MAP_STORE_CONTEXT,
    state,
    action,
    {
      customer: CustomerReducer,
      filters: FiltersReducer,
      snackBar: SnackBarReducer
    },
    InitialFleetMapState
  );
  return state;
};

/**
 * Validate and Reset view.satBeam from the FleetMap Page
 * Create snackbar when appropriate, internal only (isInternal && !viewAsCustomer)
 * @param state FleetMapState to update
 * @param satId Selected satId to remove on FleetMap Page
 * @param beamId Selected beamId to remove on FleetMap Page
 * @returns valid State
 */
export const validateAndResetViewSelectedSatBeam = (state: FleetMapState, satDet: ISelectedSatBeam): FleetMapState => {
  const snackBar = [];
  if (matchesSelectedSatBeamFromState(state, satDet)) {
    const unavailableMsg = 'Selected Satellite Beam is not available.';
    snackBar.push(
      createSnackBar({
        message: unavailableMsg,
        severity: Severity.INFO,
        getFullElementId: (name, type) => `${name}-${type}`
      })
    );
  }

  return {
    ...state,
    view: {
      ...state.view,
      satBeam: InitialViewState.satBeam
    },
    snackBar
  };
};
