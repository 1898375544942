/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Antenna Encoder Query
 */
import {Query} from '../types';

export interface IAntennaEncoderInfo {
  azimuth_deg: number;
  elevation_deg: number;
  timestamp: string;
  isTailBlocked: boolean;
}

export const useAntennaEncoderAngleQuery: Query<IAntennaEncoderInfo[]> = {
  route: `flightDetails/antennaEncoderAngle`,
  transform: null
};
export default useAntennaEncoderAngleQuery;
