/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List - Customer History Query
 */

import moment from 'moment';
import {DATE_VERBOSE_FULL_FORMAT} from '../../../utils/DateTimeUtils';
import {Query} from '../types';

export interface TailCustomerHistoryInfo {
  aircraftId: string;
  tailId: string;
  customerName: string;
  deactivatedDate: string;
}

const transformRawTailCustomerHistoryInfo = (
  customerHistoryList: TailCustomerHistoryInfo[] | null
): TailCustomerHistoryInfo[] | null => {
  if (!customerHistoryList) return null;

  const customerHistoryInfo: TailCustomerHistoryInfo[] = customerHistoryList.map((historyInfo) => ({
    aircraftId: historyInfo.aircraftId,
    tailId: historyInfo.tailId,
    customerName: historyInfo.customerName,
    deactivatedDate: moment.utc(historyInfo.deactivatedDate).format(DATE_VERBOSE_FULL_FORMAT)
  }));

  return customerHistoryInfo;
};

const tailCustomerHistoryQuery: Query<TailCustomerHistoryInfo[]> = {
  route: 'tailList/customerHistory',
  transform: transformRawTailCustomerHistoryInfo
};

export default tailCustomerHistoryQuery;
