/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for listgrid column show/hide - Should be composed
 */

import {Severity} from '@viasat/insights-components';
import {SnackBarState, createSnackBar} from './SnackBarReducer';

export interface HideColumnsState {
  hiddenColumns: string[];
  sharelinkHiddenColumns?: string[];
}

export const InitialHideColumnsState: HideColumnsState = {
  hiddenColumns: [],
  sharelinkHiddenColumns: undefined
};

export const SessionStoreKeys: string[] = [];

declare type ParentState = {hideColumns: HideColumnsState; snackBar?: SnackBarState};

export enum HideColumnsAction {
  SET_HIDDEN_COLUMNS = 'SET_HIDDEN_COLUMNS',
  VALIDATE_HIDDEN_COLUMNS = 'VALIDATE_HIDDEN_COLUMNS',
  SET_INVALID_HIDDEN_COLUMNS_SNACKBAR = 'SET_INVALID_HIDDEN_COLUMNS_SNACKBAR'
}

/**
 * Checks if hidden columns are valid, generates a snackbar for all invalid hidden columns
 *
 * @param state Current hidden column state
 * @param validColumns Valid columns
 * @returns snackbar message for invalid hidden column(s)
 */
export const validateHiddenColumns = (
  state: ParentState,
  validColumns: string[],
  defaultState: ParentState
): ParentState => {
  // if sharelink hidden columns in session store is populated, then use that, otherwise use normal hidden columns
  let currentHiddenColumns = state.hideColumns.sharelinkHiddenColumns
    ? state.hideColumns.sharelinkHiddenColumns
    : state.hideColumns.hiddenColumns;
  const defaultHiddenColumns = defaultState.hideColumns.hiddenColumns;

  const msg = ['Invalid Hidden Columns'];
  const messages = [...state.snackBar];

  // difference of hiddenColumns and validColumns
  const invalidColumnNames = currentHiddenColumns.filter((columnName) => {
    return validColumns && validColumns.length > 0 && !validColumns.includes(columnName);
  });

  if (invalidColumnNames.length > 0) {
    // remove invalidColumnNames from hiddenColumns
    currentHiddenColumns = currentHiddenColumns.filter((columnName) => {
      return !invalidColumnNames.includes(columnName);
    });
    // if empty, set defaults
    currentHiddenColumns = currentHiddenColumns.length > 0 ? currentHiddenColumns : defaultHiddenColumns;
    msg.push(`(${invalidColumnNames.join(', ')})`);
    messages.push(
      createSnackBar({
        message: msg.join(' '),
        severity: Severity.WARNING,
        getFullElementId: (name, type) => `${name}-${type}`
      })
    );
  }

  return state.hideColumns.sharelinkHiddenColumns
    ? {
        ...state,
        hideColumns: {
          hiddenColumns: state.hideColumns.hiddenColumns,
          sharelinkHiddenColumns: currentHiddenColumns
        },
        snackBar: messages
      }
    : {
        ...state,
        hideColumns: {
          hiddenColumns: currentHiddenColumns,
          sharelinkHiddenColumns: undefined
        },
        snackBar: messages
      };
};

/**
 * Generates a snackbar for all invalid hidden columns
 *
 * @param state Current state
 * @param invalidColumns invalid columns
 * @returns new state with snackbar message for invalid hidden column(s)
 */
export const setInvalidHiddenColumnsSnackbar = (state: ParentState, invalidColumns: string[]): ParentState => {
  const msg = ['Invalid Hidden Columns'];
  const messages = [...state.snackBar];

  if (invalidColumns.length > 0) {
    msg.push(`(${invalidColumns.join(', ')})`);
    messages.push(
      createSnackBar({
        message: msg.join(' '),
        severity: Severity.WARNING,
        getFullElementId: (name, type) => `${name}-${type}`
      })
    );
  }

  return {
    ...state,
    snackBar: messages
  };
};

/**
 * Hide columns action reducer
 * @param state Current State
 * @param action Action to perform
 * @param defaultState Initial Default State for embedded store
 * @returns Updated state
 */
export const HideColumnsReducer = (state: ParentState, action: any, defaultState: ParentState): ParentState => {
  const {...payload} = action.payload || {};

  switch (action.type) {
    case HideColumnsAction.SET_HIDDEN_COLUMNS:
      state = {
        ...state,
        hideColumns: {
          hiddenColumns: payload.hiddenColumns,
          sharelinkHiddenColumns: undefined // reset sharelink in sessionStorage
        }
      };
      break;
    case HideColumnsAction.VALIDATE_HIDDEN_COLUMNS:
      state = validateHiddenColumns(state, payload.validColumns, defaultState);
      break;

    case HideColumnsAction.SET_INVALID_HIDDEN_COLUMNS_SNACKBAR:
      state = setInvalidHiddenColumnsSnackbar(state, payload.invalidColumns);
      break;
    default:
      break;
  }
  return state;
};
