/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: End Users Query
 */
import {Query} from '../types';

export interface EndUser {
  name: string;
  code: string;
}

const endUsersQuery: Query<EndUser[]> = {
  route: 'common/endUsers',
  transform: null
};

export default endUsersQuery;
