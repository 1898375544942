/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Events list card
 */

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import FlightTakeOffIcon from '@mui/icons-material/FlightTakeoff';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FlightLandingIcon from '@mui/icons-material/FlightLand';
import {LoadingSpinner} from '@viasat/insights-components';
import {getElementIdFromSectionBase} from '../../utils/ElementIdUtils';
import {useStore} from '../../store/Store';
import {splitString} from '../../utils/MapUtil';
import {
  DATE_MONTH_NAME_AND_TIME,
  TIME_WITH_MINUTES_FORMAT,
  formatMomentInput,
  DATE_CONCISE_FORMAT,
  TIME_WITH_HR_MIN_FORMAT,
  DATE_TIME_FORMAT_WITH_HR_MINS
} from '../../utils/DateTimeUtils';
import * as StyledElms from './ConnectivityOutlookStyles';
import {FlightEvent, FlightPlanEvents} from '../../store/queries/connectivityPlanner/flightEventsQuery';
import {ConnectivityPlannerAction} from '../../store/reducers/ConnectivityPlannerReducer';
import {getSelectedLeg, getSelectedLegEventsList} from './SelectedFlightLegUtils';
import {
  ConnectivityOutlookIconColors,
  DepartureTimeChange,
  ConnectivityOutlookIconTextColors,
  totalFlightDuration,
  formatFlightDuration
} from './ConnectivityOutlookUtils';
import ConnectivityStatusIcon from '../common/theme/icons/ConnectivityStatusIcon';
import {EVENTS_CARD_FLIGHT_ICON_COLOR} from '../common/theme/Colors';
import {useIntl} from 'react-intl';

interface IEventsListCardProps {
  isLoading: boolean;
  isMultipleFlightLegs: boolean;
  setIsFlightPathChanging: (isChanging: boolean) => void;
  resetDepartureTime: () => void;
  checkHeight: boolean;
}
/**
 *  Component to display the event details of single and multiple flight plan
 *  @return Events card
 */
const EventsListCard = ({
  isLoading,
  isMultipleFlightLegs,
  setIsFlightPathChanging,
  resetDepartureTime,
  checkHeight
}: IEventsListCardProps) => {
  const intl = useIntl();
  const idBase = 'connectivityOutlook';
  const {store, dispatch} = useStore();
  const {flightPlanInfo, selectedFlightLegIdx, flightEventsInfo} = store.connectivityPlanner;
  const [flightLegListDropdown, setFlightLegListDropdown] = useState(false);
  const [eventsListInfo, setEventsListInfo] = useState<FlightPlanEvents[]>(null);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [totalEvents, setTotalEvents] = useState<number>(0);
  const toggleFlightLegListDropdown = () => {
    setFlightLegListDropdown(!flightLegListDropdown);
  };

  /**
   * Selects a flight leg and updates the view with the selected flight leg data
   * @param flightLegIndex Selected flight leg from the list of flight legs
   */
  const selectFlightLeg = (flightLegIndex: number) => {
    setIsFlightPathChanging(true);
    dispatch({
      type: ConnectivityPlannerAction.SET_SELECTED_FLIGHT_LEG,
      payload: flightLegIndex
    });
    setTimeout(() => {
      setIsFlightPathChanging(false);
      setFlightLegListDropdown(false);
    }, 500);
  };

  useEffect(() => {
    if (!isLoading) {
      const eventsList = getSelectedLegEventsList(flightEventsInfo, selectedFlightLegIdx);

      /**
       * calculates the number of connectivity events for a flight plan
       * @param flightPlanEvents list of events for a given flight plan
       */
      const totalFlightEvents = (flightPlanEvents: FlightPlanEvents[]) => {
        let totalEvents = 0;
        flightPlanEvents.forEach((flightPlan) => {
          totalEvents += flightPlan.events.length;
        });
        return totalEvents;
      };

      setEventsListInfo(eventsList);
      setTotalDuration(totalFlightDuration(flightPlanInfo));
      setTotalEvents(totalFlightEvents(eventsList));
    }
  }, [isLoading, flightEventsInfo, flightPlanInfo, selectedFlightLegIdx]);
  /**
   * Sub component that displays switch to toggle between flight legs in events card header
   */
  const FlightLegSwitch = () => {
    return (
      <StyledElms.FlightLegSwitch
        id={getElementIdFromSectionBase(idBase, 'flight-legs-toggle', '0')}
        onClick={() => toggleFlightLegListDropdown()}
      >
        {selectedFlightLegIdx === -1
          ? 'Combined'
          : `${flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].departureAirport} - ${
              flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].destinationAirport
            }`}
        {flightLegListDropdown ? <ArrowDropUp /> : <ArrowDropDown />}
      </StyledElms.FlightLegSwitch>
    );
  };
  /**
   * Sub component that displays list of flight legs
   */
  const FlightLegList = () => {
    return (
      <StyledElms.FlightPlanLegs id={getElementIdFromSectionBase(idBase, 'flight-plan-legs', '0')}>
        <StyledElms.FlightPlanLegListItem
          onClick={() => selectFlightLeg(-1)}
          className={selectedFlightLegIdx === -1 ? 'active' : ''}
          id={getElementIdFromSectionBase(idBase, 'flight-plan-leg-combined', '0')}
        >
          <div className="combined-cell">Combined</div>
          <div style={{marginLeft: '-26px'}}>
            {flightPlanInfo[0].departureAirport} - {flightPlanInfo[flightPlanInfo.length - 1].destinationAirport}
          </div>
          <div>{formatMomentInput(splitString(flightPlanInfo[0].departureTime, '.', 0), DATE_CONCISE_FORMAT)}</div>
          <div>{formatMomentInput(splitString(flightPlanInfo[0].departureTime, '.', 0), TIME_WITH_HR_MIN_FORMAT)}</div>
        </StyledElms.FlightPlanLegListItem>
        {flightPlanInfo.map((flightLeg, index) => {
          return (
            <StyledElms.FlightPlanLegListItem
              key={index}
              onClick={() => selectFlightLeg(index)}
              className={selectedFlightLegIdx === index ? 'active' : ''}
              id={getElementIdFromSectionBase(idBase, 'flight-plan-leg', `${index}`)}
            >
              <div>{flightLeg.flightPlanIdentifier}</div>
              <div>
                {flightLeg.departureAirport} - {flightLeg.destinationAirport}
              </div>
              <div>{formatMomentInput(splitString(flightLeg.departureTime, '.', 0), DATE_CONCISE_FORMAT)}</div>
              <div>{formatMomentInput(splitString(flightLeg.departureTime, '.', 0), TIME_WITH_HR_MIN_FORMAT)}</div>
            </StyledElms.FlightPlanLegListItem>
          );
        })}
      </StyledElms.FlightPlanLegs>
    );
  };
  /**
   * Sub component that displays flight leg's origin, destination and duration
   */
  const FlightLeg = () => {
    return (
      <StyledElms.FlightPlanLeg id={getElementIdFromSectionBase(idBase, 'departure-arrival-airport', '0')}>
        {selectedFlightLegIdx === -1 ? (
          <>
            <span>
              {flightPlanInfo[0].departureAirport} - {flightPlanInfo[flightPlanInfo.length - 1].destinationAirport}
            </span>
            <StyledElms.TotalDuration>{formatFlightDuration(totalDuration)}</StyledElms.TotalDuration>
          </>
        ) : (
          <>
            <span>
              {flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].departureAirport} -{' '}
              {flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].destinationAirport}
            </span>
            <StyledElms.TotalDuration>
              {formatFlightDuration(Number(flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].totalElapsedTime))}
            </StyledElms.TotalDuration>
          </>
        )}
      </StyledElms.FlightPlanLeg>
    );
  };
  /**
   * Sub component that displays flight leg's departure time
   */
  const FlightLegDepart = ({
    departureTime,
    departureAirport,
    showResetDepartureTime,
    hideBorder,
    id,
    height
  }: {
    departureTime: string;
    departureAirport: string;
    showResetDepartureTime: boolean;
    hideBorder?: boolean;
    id: string;
    height: number;
  }) => {
    return (
      <StyledElms.FlightPlanDepart id={id} hideBorder={hideBorder} height={height}>
        <FlightTakeOffIcon style={{color: EVENTS_CARD_FLIGHT_ICON_COLOR}} />
        <div className="printStyle">
          <span>
            Depart <div className="printAirport"> - {departureAirport}</div>
          </span>
          <span className={showResetDepartureTime ? 'departureTime changed' : 'departureTime'}>
            {formatMomentInput(splitString(departureTime, '.', 0), DATE_MONTH_NAME_AND_TIME)}
            {showResetDepartureTime && (
              <span onClick={() => resetDepartureTime()} className="reset-departure-time">
                <RotateLeftIcon />
              </span>
            )}
          </span>
        </div>
      </StyledElms.FlightPlanDepart>
    );
  };
  /**
   * Sub component that displays flight leg's arrival time
   */
  const FlightLegArrival = ({
    arrivalTime,
    arrivalAirport,
    hideBorder,
    id,
    height
  }: {
    arrivalTime: string;
    arrivalAirport: string;
    hideBorder?: boolean;
    id: string;
    height: number;
  }) => {
    return (
      <StyledElms.FlightPlanArrival id={id} hideBorder={hideBorder} height={height}>
        <FlightLandingIcon style={{color: EVENTS_CARD_FLIGHT_ICON_COLOR}} />
        <div className="printStyle">
          <span>
            Arrive <div className="printAirport"> - {arrivalAirport}</div>
          </span>
          <span className="arrivalTime" id={getElementIdFromSectionBase(idBase, 'arrival-time', '0')}>
            {formatMomentInput(splitString(arrivalTime, '.', 0), DATE_MONTH_NAME_AND_TIME)}
          </span>
        </div>
      </StyledElms.FlightPlanArrival>
    );
  };
  /**
   * Sub component that displays list of events for a given flight leg
   */
  const EventsListBody = ({
    flightLegEvents,
    flightLegIndex
  }: {
    flightLegEvents: FlightEvent[];
    flightLegIndex: number;
  }) => {
    return (
      <StyledElms.EventListBody minHeight={flightLegEvents.length * 60 > 200 ? 200 : flightLegEvents.length * 60}>
        {flightLegEvents.map((flightEvent: FlightEvent, eventsIndex: number) => {
          return (
            <div key={`events-list-row-${flightLegIndex}-${flightEvent.eventId}`}>
              <StyledElms.EventListRow showBorder={false}>
                <StyledElms.EventIcon>
                  <ConnectivityStatusIcon
                    id="connectivity-outlook-restriction-icon"
                    text={flightEvent.eventId.toString()}
                    color={ConnectivityOutlookIconColors[flightEvent.event.availability]}
                    textColor={ConnectivityOutlookIconTextColors[flightEvent.event.availability]}
                    textX={'3.5'}
                    textY={'0.5'}
                  />{' '}
                </StyledElms.EventIcon>
                <StyledElms.Event>
                  <StyledElms.EventTime
                    id={getElementIdFromSectionBase(idBase, 'event-time', `${flightEvent.eventId}`)}
                  >
                    {formatMomentInput(splitString(flightEvent.timestamp, '.', 0), TIME_WITH_MINUTES_FORMAT)} -{' '}
                    {formatMomentInput(
                      splitString(
                        moment
                          .utc(flightEvent.timestamp)
                          .clone()
                          .add(flightEvent.duration, 'minutes')
                          .format(DATE_TIME_FORMAT_WITH_HR_MINS),
                        '.',
                        0
                      ),
                      TIME_WITH_MINUTES_FORMAT
                    )}
                  </StyledElms.EventTime>
                  <StyledElms.EventName id={getElementIdFromSectionBase(idBase, 'event', `${flightEvent.eventId}`)}>
                    {flightEvent.event.displayName}
                  </StyledElms.EventName>
                </StyledElms.Event>
                <StyledElms.EventDuration
                  id={getElementIdFromSectionBase(idBase, 'event-duration', `${flightEvent.eventId}`)}
                >
                  {formatFlightDuration(flightEvent.duration)}
                </StyledElms.EventDuration>
              </StyledElms.EventListRow>
              <StyledElms.FlightPlanBodyDivider hideDivider={flightEvent.eventId === totalEvents} />
            </div>
          );
        })}
      </StyledElms.EventListBody>
    );
  };
  console.log(checkHeight);
  return (
    <StyledElms.EventsContainer id={'event-list-container'} checkHeight={checkHeight}>
      {isLoading || !flightPlanInfo || !flightPlanInfo.length ? (
        <>
          <div
            style={{
              height: '300px'
            }}
          >
            <StyledElms.LoadingSpinnerContainer>
              <LoadingSpinner
                id="outlook-events-spinner"
                direction="column"
                caption="Loading Events Card..."
                size={25}
                isSpinner={true}
              />
              <StyledElms.LabelContainer>
                Loading Events Card<span>...</span>
              </StyledElms.LabelContainer>
            </StyledElms.LoadingSpinnerContainer>
          </div>
        </>
      ) : (
        <>
          <StyledElms.FlightPlanHeader id={getElementIdFromSectionBase(idBase, 'flight-plan-number', '0')}>
            <StyledElms.FlightLegTitle>Flight Plan</StyledElms.FlightLegTitle>
            {isMultipleFlightLegs ? (
              <FlightLegSwitch />
            ) : (
              !isMultipleFlightLegs &&
              flightPlanInfo &&
              flightPlanInfo.length && (
                <span className="serialNumber">
                  {flightPlanInfo && flightPlanInfo.length ? flightPlanInfo[0].flightPlanIdentifier : '--'}
                </span>
              )
            )}
          </StyledElms.FlightPlanHeader>
          {flightLegListDropdown && <FlightLegList />}
          <FlightLeg />
          <FlightLegDepart
            id={getElementIdFromSectionBase(idBase, 'departure-arrival', '0')}
            height={32}
            departureTime={flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].departureTime}
            departureAirport={flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].departureAirport}
            showResetDepartureTime={
              flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].isDepartureTimeChanged !==
              DepartureTimeChange.NO_CHANGE
            }
          />
          <StyledElms.FlightPlanBody>
            {eventsListInfo &&
              eventsListInfo.map((flightPlanEvents: FlightPlanEvents, flightLegIndex: number) => {
                return (
                  <div key={flightPlanEvents.flightPlanId} id={'event-list-container-body'}>
                    {isMultipleFlightLegs && flightLegIndex !== 0 && (
                      <>
                        <FlightLegDepart
                          id={getElementIdFromSectionBase(idBase, 'departure-arrival', `${flightLegIndex + 1}`)}
                          height={16}
                          departureTime={flightPlanInfo[flightLegIndex].departureTime}
                          departureAirport={flightPlanInfo[flightLegIndex].departureAirport}
                          showResetDepartureTime={
                            flightPlanInfo[flightLegIndex].isDepartureTimeChanged !== DepartureTimeChange.NO_CHANGE
                          }
                          hideBorder
                        />
                        <StyledElms.FlightPlanBodyDivider hideDivider={false} />
                      </>
                    )}
                    {flightPlanEvents.events.length ? (
                      <EventsListBody flightLegEvents={flightPlanEvents.events} flightLegIndex={flightLegIndex} />
                    ) : isMultipleFlightLegs && selectedFlightLegIdx === -1 ? (
                      <></>
                    ) : (
                      <StyledElms.NoEventsMessage>
                        {intl.formatMessage({id: 'connectivity_outlook.events_card.no_events_message'})}
                      </StyledElms.NoEventsMessage>
                    )}
                    {isMultipleFlightLegs && flightLegIndex !== eventsListInfo.length - 1 && (
                      <>
                        <FlightLegArrival
                          id={getElementIdFromSectionBase(idBase, 'departure-arrival', `${flightLegIndex + 1}`)}
                          height={16}
                          arrivalTime={flightPlanInfo[flightLegIndex].arrivalTime}
                          arrivalAirport={flightPlanInfo[flightLegIndex].destinationAirport}
                          hideBorder
                        />
                        <StyledElms.FlightPlanBodyDivider hideDivider={false} />
                      </>
                    )}
                  </div>
                );
              })}
          </StyledElms.FlightPlanBody>
          <FlightLegArrival
            id={getElementIdFromSectionBase(
              idBase,
              'departure-arrival',
              `${eventsListInfo?.length > 1 ? eventsListInfo?.length + 1 : eventsListInfo?.length}`
            )}
            height={32}
            arrivalTime={
              selectedFlightLegIdx === -1
                ? flightPlanInfo[flightPlanInfo.length - 1].arrivalTime
                : flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].arrivalTime
            }
            arrivalAirport={
              selectedFlightLegIdx === -1
                ? flightPlanInfo[flightPlanInfo.length - 1].destinationAirport
                : flightPlanInfo[getSelectedLeg(selectedFlightLegIdx)].destinationAirport
            }
          />
        </>
      )}
    </StyledElms.EventsContainer>
  );
};

export default EventsListCard;
