/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tooltip Series Value used to identify chart series values
 */

import React from 'react';
import {FontSizeProperty, FontWeightProperty, LineHeightProperty, TextAlignProperty, WidthProperty} from 'csstype';
import {
  TOOLTIP_SERIES_TEXT_FONT_SIZE,
  FONT_WEIGHT_SEMIBOLD,
  TOOLTIP_SERIES_LINE_HEIGHT
} from '../../../../../utils/constants';

export interface SeriesValueProps {
  fontSize?: FontSizeProperty<string>;
  fontWeight?: FontWeightProperty;
  lineHeight?: LineHeightProperty<string>;
  textAlign?: TextAlignProperty;
  value: number | string;
  width: WidthProperty<string>;
}

const SeriesValue: React.FC<SeriesValueProps> = ({
  fontSize = TOOLTIP_SERIES_TEXT_FONT_SIZE,
  fontWeight = FONT_WEIGHT_SEMIBOLD,
  lineHeight = TOOLTIP_SERIES_LINE_HEIGHT,
  textAlign = 'right',
  value,
  width
}: SeriesValueProps): JSX.Element => {
  return (
    <span
      style={{
        display: 'inline-block',
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        overflow: 'hidden',
        textAlign: textAlign,
        width: width
      }}
    >
      {value}
    </span>
  );
};

export default SeriesValue;
