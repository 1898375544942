/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Events Timeline Card on the Flight Details Page
 */

import React from 'react';
import {useIntl} from 'react-intl';
import {ChartCard, TimelineIcon} from '@viasat/insights-components';

import {ChartTimeSettings} from '../flightDetailsUtil';
import EventsTimeline from '../charts/EventsTimeline';
import {EventsTimelineData, FlightPhaseData} from '../charts/EventsTimelineUtils';
import {formatMomentInput, TIME_WITH_HR_MIN_FORMAT} from '../../../utils/DateTimeUtils';

interface EventTimelineCardProps {
  currentTime: string;
  isLoading: boolean;
  chartTimeSettings: ChartTimeSettings;
  maxDate: number;
  isLiveFlight: boolean;
  lastFlightPhase: string;
  eventsTimeLineData: EventsTimelineData[];
  flightPhaseData: FlightPhaseData;
  parentLeftOffset: number;
  liveMask: boolean;
  eventsTimelineChartHeight: number;
  setLiveFlightOffset?: (payload: {startOffSet: number; unit: number}) => void;
  liveFlightOffset?: {
    startOffSet: number;
    unit: number;
  };
  scrollContainerRef: any;
}

const EventsTimelineCard = ({
  currentTime,
  isLoading: parentIsLoading,
  chartTimeSettings,
  maxDate,
  isLiveFlight,
  lastFlightPhase,
  eventsTimeLineData,
  flightPhaseData,
  parentLeftOffset,
  liveMask,
  eventsTimelineChartHeight,
  setLiveFlightOffset,
  liveFlightOffset,
  scrollContainerRef
}: EventTimelineCardProps) => {
  const intl = useIntl();

  return (
    <div id="EventTimelineCardEvents">
      <ChartCard
        disableDrag={true}
        getFullElementId={(name, type) => `flightDetails--eventsTimeline__${name}-${type}`}
        Icon={TimelineIcon}
        title={'Events Timeline'}
        disableHelpIcon={false}
        helpTooltipMessage={intl.formatMessage({id: 'flight_details.events_timeline.title_tooltip'})}
        summaries={
          isLiveFlight
            ? [
                {
                  getFullElementId: (name, type) => `flightDetails--eventsTimeline__${name}-${type}`,
                  key: 'data-usage-timeline-total-data-usage',
                  name: 'LAST UPDATED',
                  value: formatMomentInput(flightPhaseData?.connectionEnd, TIME_WITH_HR_MIN_FORMAT)
                }
              ]
            : []
        }
        isLoading={parentIsLoading}
        isError={false}
        chartHeight={eventsTimelineChartHeight}
      >
        <EventsTimeline
          currentTime={currentTime}
          isLoading={parentIsLoading}
          eventsTimeline={eventsTimeLineData}
          flightPhaseData={flightPhaseData}
          maxDate={maxDate}
          isLiveFlight={isLiveFlight}
          lastFlightPhase={lastFlightPhase}
          chartTimeSettings={chartTimeSettings}
          parentLeftOffset={parentLeftOffset}
          liveMask={liveMask}
          setLiveFlightOffset={setLiveFlightOffset}
          liveFlightOffset={liveFlightOffset}
          scrollContainerRef={scrollContainerRef}
        />
      </ChartCard>
    </div>
  );
};

export default EventsTimelineCard;
