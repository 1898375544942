/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Typedefs for Chart Components
 */

import {ToolTipData} from './ToolTip/ToolTip';
import {IChartOverlayOptions} from './RenderOverlay';

export enum ChartType {
  LINE = 'line',
  COLUMN = 'column',
  COLUMN_RANGE = 'columnrange',
  RANGED_BAR = 'rangedBar',
  STACKED_COLUMN = 'stackedColumn',
  PIE = 'pie',
  SCATTER = 'scatter',
  AREA = 'area'
}

export interface XAxisLabelPlotline {
  index: number;
  label: string;
}

export enum PlotLineType {
  horizontal = 'horizontal',
  vertical = 'vertical'
}

export enum ChartDataTypes {
  pctPoints = 'pctPoints',
  percentage = 'percentage',
  number = 'number',
  string = 'string'
}

interface ILiveMask {
  totalChartMinutes: number;
  elapsedChartMinutes: number;
  enabledLiveMask: boolean;
  internal: boolean;
}

interface IChartBase {
  chartType: ChartType;
  defaultChartPalette: string[];
  highchartsIdBase?: string;
  id: string;
  noDataOptions?: IChartOverlayOptions;
  palette?: string[];
  timePeriod?: string;
  title: string;
  titleTooltip?: string;
  tooltip?: (input: any) => ToolTipData;
}

export interface IPieChart extends IChartBase {
  titleValue: number | string;
  titleName: string;
  chartData: {
    y: number;
    name: string;
    percent: string | number;
    change?: string | number;
    isUpwardTrendGood?: boolean;
  };
}

interface IHistogramChart extends IChartBase {
  legendItemWidth?: number;
  legendWidth?: number;
  seriesFormatter?: (value: any) => string;
  xAxisData: any;
  xAxisLabelXOffset?: number;
  xAxisFormat?: ChartDataTypes;
  xAxisFormatter?: (value: any) => string;
  xAxisLabel?: string;
  xAxisMin?: number;
  xAxisMax?: number;
  yAxisCeiling?: number;
  yAxisFloor?: number;
  yAxisFormat?: ChartDataTypes;
  yAxisFormatter?: (context: any) => string;
  yAxisLabel: string;
  yAxisLabelXOffset?: number;
  yAxisMax?: number;
  yAxisMin?: number;
  htmlTooltip?: boolean;
  liveMask?: ILiveMask;
}

export interface IColumnChart extends IHistogramChart {
  height: number;
  marginLeft?: number;
  marginRight?: number;
  spacingLeft?: number;
  spacingRight?: number;
  plotline?: number;
  plotlineCaption?: string;
  plotlineColor?: string;
  plotlineLabelYOffset?: number;
  plotlineType?: string;
  plotOptionsBorderRadiusTopLeft?: any;
  plotOptionsBorderRadiusTopRight?: any;
  pointPadding?: number;
  pointWidth?: number;
  seriesButton?: boolean;
  yAxisLabelFormat?: any;
  xAxisLabelPlotlines: XAxisLabelPlotline[];
  yAxisData: any;
  yAxisOffset?: number;
  yAxisTickInterval?: number;
  yAxisTickPositions?: number[];
  xAxisLabelFormat?: any;
  xAxisTickmarkPlacement?: string;
  xAxisTickInterval?: number;
  xAxisMinorTickInterval?: number;
  xAxisTickPositions?: number[];
}

export interface ILineChartYAxis {
  label: string;
  labelXOffset: number;
  plotline?: number;
  plotlineColor?: string;
  tickInterval: number;
  tickPositions: number[];
  titleOffset: number;
  opposite: boolean;
}

export interface ILineChart extends IHistogramChart {
  connectNulls?: boolean;
  disableMarker?: boolean;
  height: number;
  marginLeft?: number;
  marginRight?: number;
  spacingLeft?: number;
  spacingRight?: number;
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  legend: boolean;
  legendItemStyle?: any;
  plotline?: number;
  plotlineColor?: string;
  plotlineType?: string;
  series: any;
  sharedTooltip: boolean;
  xAxisLabelXOffset: number;
  xAxisMinorTickInterval?: number;
  xAxisTickInterval: number;
  xAxisTickPositions: number[];
  yAxis: ILineChartYAxis[];
  yAxisLabelStyles?: Highcharts.CSSObject;
  plotBands?: any;
}

export interface IHorizontalBarChart extends IHistogramChart {
  yAxisData: any;
}

export interface IStackedColumnChartYAxis {
  label: string;
  opposite: boolean;
  min?: number;
  yAxisFormatter?: (context: any) => string;
}

export interface IStackedColumnChart extends IHistogramChart {
  hideTotals: boolean;
  patternDefinition: any;
  xAxisTickInterval: number;
  yAxisData: any;
  yAxis: IStackedColumnChartYAxis[];
  legend: boolean;
  legendItemStyle?: any;
  series: any;
  sharedTooltip: boolean;
  xAxisMinorTickInterval?: number;
  xAxisTickPositions?: number[];
  legendTooltips: Array<ILegendTooltip>;
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  paddings?: Record<string, number>;
  marginLeft?: number;
  marginRight?: number;
  spacingLeft?: number;
  spacingRight?: number;
  customXAxis?: any;
  stackingOption?: any;
  customLegend?: any;
  customStackLabels?: any;
  states?: any;
  customLegendClick: (event: any) => void;
}

export interface IPercentChart extends IHistogramChart {
  series: any;
  height: number;
  legend: boolean;
  sharedTooltip: boolean;
  xAxisLabelXOffset: number;
  xAxisTickInterval: number;
  xAxisTickPositions: number[];
  yAxis: ILineChartYAxis[];
  connectNulls?: boolean;
  disableMarker?: boolean;
  marginLeft?: number;
  marginRight?: number;
  spacingLeft?: number;
  spacingRight?: number;
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  legendItemStyle?: any;
  plotline?: number;
  plotlineColor?: string;
  plotlineType?: string;
}

export interface ILegendTooltip {
  title: string;
  x: number;
  y: number;
  arrowX: number;
}

export interface IXaxisLabelStyle {
  rotation: number;
  fontSize: string;
  fontWeight: string;
}

export interface IColumnRangeChart extends IHistogramChart {
  categories: any;
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  legendTooltips: Array<ILegendTooltip>;
  series: any;
  customXAxis?: any;
  xAxisTickInterval: number;
  xAxisMinorTickInterval?: number;
  xAxisLabelPadding?: number;
  yAxisData: any;
  tooltipDisabled?: boolean;
  positioner: any;
  barWidth: number;
  seriesAnimation: boolean;
  totalDuration: any;
  setLiveFlightOffset: (payload: {startOffSet: number; unit: number}) => void;
  chartHeight?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
  spacingLeft?: number;
  spacingRight?: number;
  yAxisOffset?: number;
  customYAxis?: any;
  customLegendClick: (event: any) => void;
  spacingTop?: number;
  patternDefinition?: any;
}

export interface IScatterPlotChart extends IHistogramChart {
  yAxisLabel: string;
  yAxisTickInterval: number;
  yAxisLabelXOffset?: number;
  yAxisFormatter?: (context: any) => string;
  yAxisTickPositions: number[];
  yAxisTitleOffset: number;
  xAxisLabel: string;
  xAxisTickInterval: number;
  xAxisLabelXOffset?: number;
  xAxisFormatter?: (value: any) => string;
  xAxisTickPositions: number[];
  xAxisMinorTickInterval: number;
  height: number;
  spacingRight?: number;
  marginLeft?: number;
  marginRight?: number;
  spacingLeft?: number;
  xAxisData: any;
  seriesFormatter?: (value: any) => string;
  legend?: boolean;
  legendItemStyle?: any;
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  htmlTooltip?: boolean;
  xAxisMin: number;
  xAxisMax: number;
  yAxisMin: number;
  yAxisMax: number;
  yAxis: any;
  series: any;
}
