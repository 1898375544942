/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Satellite Beam Network Incident Events Query
 *
 */

import {Query} from '../types';

export interface SatelliteBeamIncident {
  CAUSE: string;
  IMPACT: string;
  LINK: string;
}

export interface SatelliteBeamIncidentEvent {
  SAT_BEAM_ID: string;
  SATELLITE_ID: number;
  BEAM_ID: number;
  INCIDENTS: SatelliteBeamIncident[];
}


export const satelliteBeamIncidentEventsQuery: Query<SatelliteBeamIncidentEvent[]> = {
  route: 'beamOverlay/satelliteBeamIncidentEvents',
  transform: null
};
