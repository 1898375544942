/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft Status List Util for handling the data cells
 */
import React from 'react';
import moment from 'moment';
import StarIcon from '@mui/icons-material/Star';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {ConfirmationNumber, InsertChartOutlined} from '@mui/icons-material';
import {
  DefaultCellRendererV2,
  ListGridColumn,
  CustomLink,
  DateCellRendererV2,
  CellRenderer,
  LoadingBar
} from '@viasat/insights-components';

import {
  BAND_KU,
  ConnectionStatus,
  DATA_USAGE_AVAILABLE_TOOLTIP,
  NO_DATA_USAGE_TOOLTIP,
  SwVersionStatus
} from '../../../../utils/constants';
import {
  getColumnIdEnumName,
  getColumnFullTitle,
  getColumnFormattedFullTitle,
  AirportTooltipCellRenderer,
  getLocationFilterRangeOptions
} from '../../../lists/listUtils';
import {convertObjectToFilterRangeOptions, FilterOptionsType, FilterType} from '../../../../utils/filterUtils';
import {
  convertFilterValues,
  flightPhaseContentLookup,
  FLIGHT_PHASE_ASCENDING,
  FLIGHT_PHASE_CRUISE,
  FLIGHT_PHASE_DESCENDING,
  FLIGHT_PHASE_GROUNDED,
  getAvailabilityColor,
  getServiceStatusFilterRangeOptions,
  splitString,
  getSwVersionStatusColor
} from '../../../../utils/MapUtil';
import {timeDuration} from '../../../flightDetails/flightDetailsUtil';
import {
  GRID_CELL_LINK_COLOR,
  GRID_CELL_TEXT_DURATION_COLOR,
  WATCHLIST_ICON_COLOR,
  NON_WATCHLIST_ICON_COLOR,
  SWITCH_VIEW_ICON_COLOR,
  GRID_CELL_TEXT_ACTIVE_COLOR,
  ABOVE_TARGET_COLOR,
  BELOW_LOWER_THRESHOLD_COLOR
} from '../../../common/theme/Colors';
import {DATE_TIME_FORMAT_WITH_HR_MINS} from '../../../../utils/DateTimeUtils';
import {DefaultedTextCellRenderer} from '../../../common/elements/listGridCells/ListGridDefaultedText';
import AvailabilityDot from '../../../common/AvailabilityDot';
import {ListGridIconTextOrLink} from '../../../common/elements/listGridCells/ListGridIconTextOrLink';
import WarningIcon from '@mui/icons-material/WarningSharp';
import {LoadingIndicator} from '../../../dashboard/SummaryMetricsStyles';
import DiamondIcon from '../../../common/DiamondIcon';

export const ASLV_DEFAULT_SORT_COLUMN = 'status';
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

// Aircraft Status List Hidden Columns mappings for encoding/decoding sharelink
// Required: Enum name should match columnId name, case-sensitive
export enum AircraftStatusListColumnId {
  favorite = -1, // fixed column, cannot be hidden
  status = 0,
  customer = 1,
  serialNumber,
  tailId,
  aircraftType,
  networkCapability,
  flightPhase,
  origin,
  destination,
  flightStart,
  flightEnd,
  flightDuration,
  dataUsage,
  kaBandTerminal,
  kaBandTerminalStatus,
  beamDataTable,
  beamDataTableStatus,
  kuBandTerminal,
  kuBandTerminalStatus,
  sedMapFile,
  sedMapFileStatus,
  sscfMapFile,
  sscfMapFileStatus,
  // Above Column Ids should remain unchanged for backwards-compatibility.
  // Validated by src/components/lists/__tests__/hiddenColumnsBackwardsCompatibility.spec.ts
  // New Column Ids should be added after this line
  cases,
  softwareRevisions,
  valueAddedReseller,
  support
}

export interface IAircraftStatusData {
  favorite: boolean;
  customer: string;
  serialNumber: string;
  tailId: string;
  aircraftId: string;
  aircraftManufacturer: string;
  aircraftType: string;
  networkCapability: string;
  flightStart: string;
  flightEnd: string;
  flightDuration: string;
  connectedDuration: string;
  dataUsage: string;
  flightId: string;
  status: string;
  flightPhase: string;
  displayOrder: number;
  openCases?: number;
  sortIdx?: number;
  isRowHighlighted?: boolean;
  valueAddedReseller?: string;
}

/**
 * Get Aircraft Status List ColumnId Name
 * @param columnId columnId enum to convert to name
 * @returns name of columnId as string
 */
export const getColumnIdName = (columnId: AircraftStatusListColumnId) =>
  getColumnIdEnumName(columnId, AircraftStatusListColumnId);

// Query Field Constants
export const QUERY_FIELD_MAPPING = {
  status: 'status',
  dataUsageStatus: 'dataUsageStatus',
  customer: 'customer',
  serialNumber: 'serialNumber',
  tailId: 'tailId',
  aircraftType: 'aircraftType',
  networkCapability: 'networkCapability',
  flightPhase: 'flightPhase',
  flightStart: 'flightStartTimestamp',
  flightEnd: 'flightEndTimestamp',
  flightDuration: 'flightDurationSeconds',
  dataUsage: 'dataUsageBytes',
  softwareRevisions: 'softwareRevisions',
  cases: 'totalOpenCases',
  valueAddedReseller: 'valueAddedReseller',
  kaBandTerminalStatus: 'kaTerminalSwVersionStatus',
  kaBandTerminal: 'kaTerminalSwVersion',
  beamDataTable: 'bdtVersion',
  beamDataTableStatus: 'bdtVersionStatus',
  kuBandTerminal: 'kuTerminalSwVersion',
  kuBandTerminalStatus: 'kuTerminalSwVersionStatus',
  sedMapFile: 'sedFileVersion',
  sedMapFileStatus: 'sedFileVersionStatus',
  sscfMapFile: 'sscfFileVersion',
  sscfMapFileStatus: 'sscfFileVersionStatus',
  origin: 'origin',
  destination: 'destination'
};

/**
 * Get Aircraft Status List Query Field Mapping
 * @param columnId columnId enum to convert to query field
 * @param append append string to columnId name (default='')
 * @returns query field as string, otherwise undefined
 */
export const getQueryFieldMapping = (columnId: AircraftStatusListColumnId, append: string = '') => {
  const columnIdName = getColumnIdName(columnId) + append;
  return columnIdName ? QUERY_FIELD_MAPPING[columnIdName] : undefined;
};

const setColumnPropsList = (columnPropsList: ListGridColumn[]) => {
  return columnPropsList.map((column: ListGridColumn) => {
    const updatedColumn = column.fullTitlePrefix
      ? {
          ...column,
          fullTitle: getColumnFullTitle(column.fullTitlePrefix, column.title),
          formattedFullTitle: getColumnFormattedFullTitle(column.fullTitlePrefix, column.title)
        }
      : column;

    return updatedColumn;
  });
};

export const WatchListCellRenderer: CellRenderer = ({rowData, cellIdBase, column}) => {
  const {icon, textUnderlineColor, handleClick, rowTooltipText} = column;
  const {favorite} = rowData;

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      text={<StarIcon sx={{color: favorite ? WATCHLIST_ICON_COLOR : NON_WATCHLIST_ICON_COLOR, marginTop: '8px'}} />}
      tooltipText={rowTooltipText}
      rowData={rowData}
      descriptionText={null}
      textUnderlineColor={textUnderlineColor}
      handleClick={handleClick}
    />
  );
};

export const ActionItemsCellRenderer: CellRenderer = ({rowData, cellIdBase, column}) => {
  const {icon, textUnderlineColor, handleClick, rowTooltipText} = column;

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      text={<MoreHorizIcon style={{color: GRID_CELL_TEXT_ACTIVE_COLOR}} />}
      tooltipText={rowTooltipText}
      rowData={rowData}
      descriptionText={null}
      textUnderlineColor={textUnderlineColor}
      handleClick={handleClick}
    />
  );
};
export const ConnectionStatusCellRenderer: CellRenderer = ({rowData, cellData, cellIdBase, column}) => {
  const {flightId, status, connectionStart, connectionEnd, lastNetwork, pingsConnectedStart, pingsConnectedEnd} =
    rowData;
  const {handleClick} = column;

  const connectedStart = lastNetwork === BAND_KU ? connectionStart : pingsConnectedStart;
  const connectedEnd = lastNetwork === BAND_KU ? connectionEnd : pingsConnectedEnd;
  const statusDuration = calculateConnectionStatusDuration(connectedStart, connectedEnd, status);
  const statusDurationDate = status === ConnectionStatus.CONNECTED ? connectionStart : connectionEnd;

  let icon = <AvailabilityDot id={flightId} sx={{color: getAvailabilityColor(status), width: 17, height: 16}} />;
  let statusText = cellData;
  if (rowData.isDark) {
    icon = <WarningIcon id={flightId} sx={{color: '#465967', width: 17, height: 23}} />;
    statusText = 'Dark Flight';
  }

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      tooltipIcon={icon}
      text={statusText}
      descriptionText={statusDuration}
      rowData={rowData}
      tooltipText={`${status} since ${statusDurationDate} (${statusDuration})`}
      handleClick={handleClick}
    />
  );
};

export const ConnectionStatusIconCellRenderer: CellRenderer = ({rowData, cellIdBase, column}) => {
  const {handleClick} = column;

  const {flightId, status, connectionStart, connectionEnd, lastNetwork, pingsConnectedStart, pingsConnectedEnd} =
    rowData;

  const connectedStart = lastNetwork === BAND_KU ? connectionStart : pingsConnectedStart;
  const connectedEnd = lastNetwork === BAND_KU ? connectionEnd : pingsConnectedEnd;
  const statusDuration = calculateConnectionStatusDuration(connectedStart, connectedEnd, status);

  let icon = (
    <AvailabilityDot
      id={flightId}
      sx={{color: getAvailabilityColor(status), width: 24, height: 24, verticalAlign: 'unset !important'}}
    />
  );
  let tooltipIcon = <AvailabilityDot id={flightId} sx={{color: getAvailabilityColor(status), width: 16, height: 16}} />;
  if (rowData.isDark) {
    icon = <WarningIcon id={flightId} sx={{color: '#465967', width: 24, height: 24}} />;
  }

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      rowData={rowData}
      handleClick={handleClick}
      tooltipText={`${status}`}
      tooltipIcon={tooltipIcon}
      tooltipExtendedText={`${statusDuration}`}
    />
  );
};

export const TailIdLinkRenderer: CellRenderer = ({cellData, rowData}) => {
  const {flightId} = rowData;
  return <CustomLink to={`/flight/details/${flightId}`}>{cellData}</CustomLink>;
};

export const SoftwareRevisionsCellRenderer: CellRenderer = ({rowData, cellIdBase, column}) => {
  const {handleClick, rowTooltipText} = column;
  const {flightId} = rowData;

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={<AvailabilityDot id={flightId} sx={{color: 'green', width: 17, height: 16}} />}
      text={'View'}
      tooltipText={rowTooltipText}
      rowData={rowData}
      handleClick={handleClick}
    />
  );
};

export const FlightPhaseCellRenderer: CellRenderer = ({rowData, cellIdBase, cellData}) => {
  const flightPhaseContent = flightPhaseContentLookup[rowData.flightPhase?.toString()];

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={
        rowData.flightPhase ? (
          <flightPhaseContent.Icon sx={{color: SWITCH_VIEW_ICON_COLOR, fontSize: '1.1875rem', marginTop: '1px'}} />
        ) : (
          <></>
        )
      }
      text={cellData}
      rowData={rowData}
      marginLeft={20}
    />
  );
};

export const FlightPhaseIconCellRenderer: CellRenderer = ({rowData, cellIdBase, cellData}) => {
  const flightPhaseContent = flightPhaseContentLookup[rowData.flightPhase?.toString()];

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={
        rowData.flightPhase ? (
          <flightPhaseContent.Icon sx={{color: SWITCH_VIEW_ICON_COLOR, fontSize: '1.1875rem', marginTop: '1px'}} />
        ) : null
      }
      rowData={rowData}
      tooltipText={`${rowData.flightPhase}`}
      tooltipIcon={null}
      placement="left"
    />
  );
};

export const CasesCellRenderer: CellRenderer = ({rowData, cellIdBase, column}) => {
  const {handleClick, rowTooltipText} = column;
  const {openCases} = rowData;
  let icon;
  let text;
  let textUnderlineColor;

  if (openCases !== undefined) {
    if (openCases > 0) {
      icon = <ConfirmationNumber sx={{color: GRID_CELL_LINK_COLOR}} />;
      text = <span style={{color: GRID_CELL_LINK_COLOR, paddingLeft: '10px'}}>{openCases}</span>;
    } else {
      icon = <React.Fragment></React.Fragment>;
      text = '';
      textUnderlineColor = GRID_CELL_TEXT_DURATION_COLOR;
    }
  }

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      text={text}
      tooltipText={rowTooltipText}
      rowData={rowData}
      textUnderlineColor={textUnderlineColor}
      handleClick={handleClick}
    />
  );
};

export const TailSummaryCellRenderer: CellRenderer = ({rowData, cellIdBase, column}) => {
  const {handleClick, rowTooltipText} = column;

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={<InsertChartOutlined sx={{color: GRID_CELL_LINK_COLOR, width: '20px', height: '20px'}} />}
      text={'View'}
      tooltipText={rowTooltipText}
      rowData={rowData}
      handleClick={handleClick}
    />
  );
};

export const IconTextOrLinkCellRenderer: CellRenderer = ({rowData, cellIdBase, column}) => {
  const {handleClick, rowTooltipText, icon} = column;

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      text={<SupportAgentIcon sx={{color: GRID_CELL_LINK_COLOR}} />}
      tooltipText={rowTooltipText}
      rowData={rowData}
      handleClick={handleClick}
    />
  );
};

export const SoftwareRevisionsExpandedCellRenderer: CellRenderer = ({rowData, cellIdBase, column, cellData}) => {
  const {handleClick} = column;
  if (cellData === undefined) {
    return (
      <LoadingIndicator>
        <LoadingBar id={`listGrid__columnSerialNumber-${cellIdBase}-loadingBar`} width={76} height={14} />
      </LoadingIndicator>
    );
  } else if (cellData === null) {
    let icon = (
      <AvailabilityDot
        sx={{
          color: '#FFFFFF',
          borderRadius: '50%',
          border: '1px dashed #9FAFBC',
          width: 11,
          height: 10,
          verticalAlign: 'unset !important'
        }}
      />
    );
    return (
      <ListGridIconTextOrLink
        cellIdBase={cellIdBase}
        icon={icon}
        text={'--'}
        rowData={rowData}
        handleClick={handleClick}
      />
    );
  }
  const status = rowData[`${column.dataKey}Status`];
  const icon =
    status !== SwVersionStatus.PILOT ? (
      <AvailabilityDot
        id={`${rowData['aircraft_id']}_${rowData[column.dataKey]}`}
        sx={{color: getSwVersionStatusColor(status), width: 17, height: 16}}
      />
    ) : (
      <DiamondIcon
        id={`${rowData['aircraft_id']}_${rowData[column.dataKey]}`}
        sx={{color: getSwVersionStatusColor(status), marginTop: 0.5}}
      />
    );
  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      text={rowData[column.dataKey]}
      rowData={rowData}
      tooltipText={status}
      placement="left"
    />
  );
};

export const DataUsageCellRenderer: CellRenderer = ({rowData, cellIdBase, cellData}) => {
  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={null}
      text={cellData ? cellData : '--'}
      rowData={rowData}
      placement="left"
    />
  );
};

export const DataUsageStatusCellRenderer: CellRenderer = ({rowData, cellIdBase}) => {
  const icon = !rowData.dataUsageStatus ? (
    <AvailabilityDot
      id={'dataUsageStatusIcon_' + rowData.aircraftId}
      sx={{color: rowData.dataUsageStatus ? ABOVE_TARGET_COLOR : BELOW_LOWER_THRESHOLD_COLOR, width: 17, height: 16}}
    />
  ) : (
    <AvailabilityDot
      id={'dataUsageStatusIcon_' + rowData.aircraftId}
      sx={{
        color: '#FFFFFF',
        borderRadius: '50%',
        border: '1px dashed #9FAFBC',
        width: 11,
        height: 10,
        verticalAlign: 'unset !important'
      }}
    />
  );

  const {status, connectionStart, connectionEnd, lastNetwork, pingsConnectedStart, pingsConnectedEnd} = rowData;

  const connectedStart = lastNetwork === BAND_KU ? connectionStart : pingsConnectedStart;
  const connectedEnd = lastNetwork === BAND_KU ? connectionEnd : pingsConnectedEnd;
  const statusDuration = calculateConnectionStatusDuration(connectedStart, connectedEnd, status);
  const tooltip = rowData.dataUsageStatus ? DATA_USAGE_AVAILABLE_TOOLTIP : NO_DATA_USAGE_TOOLTIP(statusDuration);

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      text={!rowData.dataUsageStatus ? statusDuration : ''}
      rowData={rowData}
      tooltipText={tooltip}
      placement="left"
    />
  );
};

export const StatusIcon = ({version, versionStatus, marginBottom = 0}) => {
  return versionStatus !== SwVersionStatus.PILOT ? (
    <AvailabilityDot
      id={`${version}_bdt_version_status_dot`}
      sx={{
        color: getSwVersionStatusColor(versionStatus),
        width: 17,
        height: 16
      }}
    />
  ) : (
    <DiamondIcon
      id={`${version}_bdt_version_status_dot`}
      sx={{
        color: getSwVersionStatusColor(versionStatus),
        width: 24,
        height: 24,
        marginTop: 0.3,
        marginLeft: -0.2,
        marginRight: -0.6,
        marginBottom: marginBottom
      }}
    />
  );
};

/**
 * Returns a list of columns that should be included based on the given
 * @return list of ListGridColumnProps objects
 */
export const buildAircraftStatusListColumns = (
  isInternal: boolean,
  isEndUser: boolean,
  handleSoftwareRevisionsClick: (rowData: any) => void,
  handleOpenCasesClick: (rowData: any) => void,
  handleSummaryMetricsLinkClick: (rowData: any) => void,
  handleWatchListClick: (rowData: any) => void,
  handleSupportRequestClick: (rowData: any) => void,
  handleActionItemsClick?: (rowData: any, $event: any) => void,
  showCompactView?: boolean
) => {
  const columnPropsList: ListGridColumn[] = [
    {
      key: 'favorite',
      dataKey: 'favorite',
      showHideGroup: '__static',
      title: 'Watchlist',
      getColumnJsx: WatchListCellRenderer,
      align: 'center',
      width: 104,
      frozen: true,
      sortable: false,
      handleClick: handleWatchListClick
    },
    {
      key: 'status',
      dataKey: 'status',
      showHideGroup: '__groupless',
      title: showCompactView ? 'Status' : 'Connection Status',
      align: showCompactView ? 'center' : 'left',
      getColumnJsx: showCompactView ? ConnectionStatusIconCellRenderer : ConnectionStatusCellRenderer,
      width: showCompactView ? 86 : 238,
      frozen: true,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.connection_status.column.title'
    },
    {
      key: 'dataUsageStatus',
      dataKey: 'dataUsageStatus',
      showHideGroup: '__groupless',
      title: 'Usage Status',
      align: 'left',
      getColumnJsx: DataUsageStatusCellRenderer,
      width: 156,
      frozen: true,
      sortable: false,
      columnHeaderTooltipText: 'aircraft_status_list.usage_status.column.title'
    },
    {
      key: 'serialNumber',
      dataKey: 'serialNumber',
      showHideGroup: '__groupless',
      title: 'SN',
      getColumnJsx: DefaultCellRendererV2,
      width: 110,
      frozen: true,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.serial_number.column.title'
    },
    {
      key: 'tailId',
      dataKey: 'tailId',
      showHideGroup: '__groupless',
      title: 'Tail ID',
      getColumnJsx: TailIdLinkRenderer,
      width: 120,
      frozen: true,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.tail_id.column.title'
    },
    {
      key: 'flightPhase',
      dataKey: 'flightPhase',
      showHideGroup: 'Flight Info',
      title: showCompactView ? 'Phase' : 'Flight Phase',
      align: showCompactView ? 'center' : 'left',
      getColumnJsx: showCompactView ? FlightPhaseIconCellRenderer : FlightPhaseCellRenderer,
      width: showCompactView ? 83 : 150,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.flight_phase.column.title'
    },
    {
      key: 'origin',
      dataKey: 'origin',
      showHideGroup: 'Flight Info',
      title: showCompactView ? 'Org' : 'Origin',
      getColumnJsx: AirportTooltipCellRenderer,
      width: showCompactView ? 67 : 150,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.origin.column.title'
    },
    {
      key: 'destination',
      dataKey: 'destination',
      showHideGroup: 'Flight Info',
      title: showCompactView ? 'Dst' : 'Destination',
      getColumnJsx: AirportTooltipCellRenderer,
      width: showCompactView ? 64 : 150,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.destination.column.title'
    },
    {
      key: 'flightStart',
      dataKey: 'flightStart',
      showHideGroup: 'Flight Info',
      title: showCompactView ? 'Flt Start' : 'Flight Start',
      defaultSort: 'desc',
      getColumnJsx: DateCellRendererV2,
      width: showCompactView ? 159 : 170,
      sortable: false,
      columnHeaderTooltipText: 'aircraft_status_list.flight_start.column.title'
    }
  ];

  if (!showCompactView) {
    columnPropsList.push({
      key: 'flightEnd',
      dataKey: 'flightEnd',
      showHideGroup: 'Flight Info',
      title: 'Flight End',
      defaultSort: 'desc',
      getColumnJsx: DateCellRendererV2,
      width: 170,
      sortable: false,
      columnHeaderTooltipText: 'aircraft_status_list.flight_start.column.title'
    });
  }

  columnPropsList.push(
    {
      key: 'flightDuration',
      dataKey: 'flightDuration',
      showHideGroup: 'Flight Info',
      title: showCompactView ? 'Flt Duration' : 'Flight Duration',
      align: 'right',
      getColumnJsx: DefaultCellRendererV2,
      width: showCompactView ? 128 : 150,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.flight_duration.column.title'
    },
    {
      key: 'dataUsage',
      dataKey: 'dataUsage',
      showHideGroup: 'Flight Info',
      title: showCompactView ? 'Usage (MB)' : 'Data Usage (MB)',
      align: 'left',
      getColumnJsx: DataUsageCellRenderer,
      width: showCompactView ? 112 : 160,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.data_usage.column.title'
    },
    {
      key: 'aircraftType',
      dataKey: 'aircraftType',
      showHideGroup: 'Tail Info',
      title: 'Model',
      getColumnJsx: DefaultCellRendererV2,
      width: 160,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.aircraft_type.column.title'
    },
    {
      key: 'networkCapability',
      dataKey: 'networkCapability',
      showHideGroup: 'Tail Info',
      title: 'Network',
      getColumnJsx: DefaultCellRendererV2,
      width: 170,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.network_capability.column.title'
    }
  );

  if (showCompactView) {
    columnPropsList.push({
      key: 'softwareRevisions',
      dataKey: 'softwareRevisions',
      showHideGroup: 'Software',
      title: 'SW Revisions',
      getColumnJsx: SoftwareRevisionsCellRenderer,
      width: 127,
      sortable: false,
      handleClick: handleSoftwareRevisionsClick,
      columnHeaderTooltipText: 'aircraft_status_list.software_revisions.column.title'
    });
  }

  if (!showCompactView) {
    columnPropsList.push(
      {
        key: 'kaBandTerminal',
        dataKey: 'kaTerminalSwVersion',
        showHideGroup: 'Software',
        title: 'Ka-Band Terminal',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'aircraft_status_list.ka_band_terminal.column.title'
      },
      {
        key: 'beamDataTable',
        dataKey: 'bdtVersion',
        showHideGroup: 'Software',
        title: 'Beam Data Table',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'aircraft_status_list.beam_data_table.column.title'
      },
      {
        key: 'kuBandTerminal',
        dataKey: 'kuTerminalSwVersion',
        showHideGroup: 'Software',
        title: 'Ku-Band Terminal',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'aircraft_status_list.ku_band_terminal.column.title'
      },
      {
        key: 'sedMapFile',
        dataKey: 'sedFileVersion',
        showHideGroup: 'Software',
        title: 'SED Map File (Ku)',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'aircraft_status_list.sed_map_file.column.title'
      },
      {
        key: 'sscfMapFile',
        dataKey: 'sscfFileVersion',
        showHideGroup: 'Software',
        title: 'SSCF Map File (Ku)',
        getColumnJsx: SoftwareRevisionsExpandedCellRenderer,
        width: 160,
        sortable: true,
        columnHeaderTooltipText: 'aircraft_status_list.sscf_map_file.column.title'
      }
    );
  }

  columnPropsList.push({
    key: 'cases',
    dataKey: 'cases',
    showHideGroup: 'Support',
    title: 'Cases',
    getColumnJsx: CasesCellRenderer,
    width: 100,
    sortable: false,
    handleClick: handleOpenCasesClick,
    removeSort: true
  });

  if (isInternal) {
    columnPropsList.push({
      key: 'valueAddedReseller',
      dataKey: 'valueAddedReseller',
      showHideGroup: 'Customer Info',
      title: 'Var',
      getColumnJsx: DefaultedTextCellRenderer,
      width: 150,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.var.column.title'
    });
  }
  if (!isEndUser) {
    columnPropsList.push({
      key: 'customer',
      dataKey: 'customer',
      showHideGroup: 'Customer Info',
      title: 'Customer',
      getColumnJsx: DefaultedTextCellRenderer,
      width: 280,
      sortable: true,
      columnHeaderTooltipText: 'aircraft_status_list.customer.column.title'
    });
  }

  columnPropsList.push({
    key: 'actionItem',
    dataKey: 'actionItem',
    showHideGroup: '__static',
    title: '',
    getColumnJsx: ActionItemsCellRenderer,
    align: 'center',
    width: 66,
    frozen: 'right',
    sortable: false,
    handleClick: handleActionItemsClick
  });

  const labeledList = setColumnPropsList(columnPropsList);
  return labeledList;
};

export enum FilterId {
  status = 'status',
  customer = 'customer',
  serialNumber = 'serialNumber',
  tailId = 'tailId',
  aircraftType = 'aircraftType',
  networkCapability = 'networkCapability',
  flightPhase = 'flightPhase',
  valueAddedReseller = 'valueAddedReseller',
  origin = 'origin',
  destination = 'destination',
  dataUsageStatus = 'dataUsageStatus',
  kaBandTerminal = 'kaTerminalSwVersionStatus',
  bdtVersion = 'bdtVersionStatus',
  kuBandTerminal = 'kuTerminalSwVersionStatus',
  sedFileVersion = 'sedFileVersionStatus',
  sscfFileVersion = 'sscfFileVersionStatus'
}

/**
 * Returns the range options for Customer filter
 * @param props Filter container properties
 * @returns Array of Customer Filter key/value pairs
 */
export const getCustomerFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.endUsersData ? convertObjectToFilterRangeOptions(props.endUsersData, 'name') : [];

/**
 * Returns the range options for Var filter
 * @param props Filter container properties
 * @returns Array of Var Filter key/value pairs
 */
export const getVarFilterRangeOptions = (props: any) =>
  props.aircraftStatusData ? convertObjectToFilterRangeOptions(props.aircraftStatusData, 'valueAddedReseller') : [];

/**
 * Returns the range options for the Serial Number filter
 * @param props Filter container properties
 * @returns Array of Serial Number Filter key/value pairs
 */
export const getSerialNumberFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'serialNumber') : [];

/**
 * Returns the range options for the Tail ID filter
 * @param props Filter container properties
 * @returns Array of Tail ID Filter key/value pairs
 */
export const getTailIdFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'tailId') : [];

/**
 * Returns the range options for the Aircraft Type filter
 * @param props Filter container properties
 * @returns Array of Aircraft Type Filter key/value pairs
 */
export const getAircraftTypeFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'aircraftType') : [];

/**
 * Returns the range options for the Network filter
 * @param props Filter container properties
 * @returns Array of Network Filter key/value pairs
 */
export const getNetworkFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.tailInfoData ? convertObjectToFilterRangeOptions(props.tailInfoData, 'networkCapability') : [];

/**
 * Returns the filter options for Data Usage Status Filter
 * @returns Array of Data Usage Status key/value pairs
 */
export const getDataUsageFilterOptions = (): Array<FilterOptionsType> => [
  {
    optionKey: 'True',
    optionValue: 'Yes'
  },
  {
    optionKey: 'False',
    optionValue: 'No'
  }
];

/**
 * Returns the filter options for Software versions Filter
 * @returns Array of Software versions Status key/value pairs
 */
export const getSoftwareVersionsFilterOptions = (): Array<FilterOptionsType> => [
  {
    optionKey: 'Production',
    optionValue: 'Production'
  },
  {
    optionKey: 'Pilot',
    optionValue: 'Pilot'
  },
  {
    optionKey: 'Update Available',
    optionValue: 'Update Available'
  }
];

/**
 * Returns the range options for the flight phase filter
 * @param props Filter container properties
 * @returns Array of Flight phase filter key/value pairs
 */
export const getFlightPhaseFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertFilterValues([FLIGHT_PHASE_ASCENDING, FLIGHT_PHASE_CRUISE, FLIGHT_PHASE_DESCENDING, FLIGHT_PHASE_GROUNDED]);

export const AIRCRAFT_LIST_FILTERS: Array<FilterType> = [
  {
    title: 'Aircraft Type',
    id: FilterId.aircraftType,
    getValues: getAircraftTypeFilterRangeOptions
  },
  {
    title: 'Connection Status',
    id: FilterId.status,
    getValues: getServiceStatusFilterRangeOptions
  },
  {
    title: 'VAR',
    id: FilterId.valueAddedReseller,
    getValues: getVarFilterRangeOptions
  },
  {
    title: 'Customer',
    id: FilterId.customer,
    getValues: getCustomerFilterRangeOptions
  },
  {
    title: 'Flight Phase',
    id: FilterId.flightPhase,
    getValues: getFlightPhaseFilterRangeOptions
  },
  {
    title: 'Network',
    id: FilterId.networkCapability,
    getValues: getNetworkFilterRangeOptions
  },
  {
    title: 'Serial Number',
    id: FilterId.serialNumber,
    getValues: getSerialNumberFilterRangeOptions
  },
  {
    title: 'Tail ID',
    id: FilterId.tailId,
    getValues: getTailIdFilterRangeOptions
  },
  {
    title: 'Origin',
    id: FilterId.origin,
    getValues: getLocationFilterRangeOptions
  },
  {
    title: 'Destination',
    id: FilterId.destination,
    getValues: getLocationFilterRangeOptions
  },
  {
    title: 'Data Usage Status',
    id: FilterId.dataUsageStatus,
    getValues: getDataUsageFilterOptions
  },
  {
    title: 'Ka-Band Terminal',
    id: FilterId.kaBandTerminal,
    getValues: getSoftwareVersionsFilterOptions
  },
  {
    title: 'Beam Data Table',
    id: FilterId.bdtVersion,
    getValues: getSoftwareVersionsFilterOptions
  },
  {
    title: 'Ku-Band Terminal',
    id: FilterId.kuBandTerminal,
    getValues: getSoftwareVersionsFilterOptions
  },
  {
    title: 'SED Map File (Ku)',
    id: FilterId.sedFileVersion,
    getValues: getSoftwareVersionsFilterOptions
  },
  {
    title: 'SSCF Map File (Ku)',
    id: FilterId.sscfFileVersion,
    getValues: getSoftwareVersionsFilterOptions
  }
];

/**
 * Performs a deep copy of the filter list and hides field(s) based on the
 * passed in parameters.
 * @param isEndUser End user flag
 * @returns A list of filters for aircraft status list
 */
export const getAircraftStatusListFilters = (isEndUser: boolean, isInternal: boolean): Array<FilterType> => {
  const filterList = [
    ...(isEndUser ? AIRCRAFT_LIST_FILTERS.filter((filter) => filter.id !== FilterId.customer) : AIRCRAFT_LIST_FILTERS)
  ];
  const updatedFilterList = [
    ...(isInternal ? filterList : filterList.filter((filter) => filter.id !== FilterId.valueAddedReseller))
  ];
  return updatedFilterList.sort((a: FilterType, b: FilterType): number => {
    return a.title > b.title ? 1 : -1;
  });
};

/**
 * Turns a cell into a connection status with duration
 * @param CellMetadataProps Cell information
 * @returns A cell enriched with connection status information
 */
export const aircraftConnectionStatus = ({cellData, row}) => {
  if (row && row.isDark) {
    cellData.icon = <WarningIcon id={row.flightId} sx={{color: '#465967', width: 17, height: 23}} />;
  }
  if (row && row.status) {
    const statusDuration = calculateConnectionStatusDuration(row.connectionStart, row.connectionEnd, row.status);
    const statusDurationDate = row.status === ConnectionStatus.CONNECTED ? row.connectionStart : row.connectionEnd;
    cellData.icon = (
      <AvailabilityDot id={row.flightId} sx={{color: getAvailabilityColor(row.status), width: 17, height: 16}} />
    );
    cellData.descriptionText = statusDuration;
    cellData.rowTooltipText = `${row.status} since ${statusDurationDate} (${statusDuration})`;
  }
  return cellData;
};

/**
 * Calculates the Connection Status duration of the aircraft
 * @param connectionStart Aircraft's Flight connection start time
 * @param connectionEnd Aircraft's Flight connection end time
 * @param aircraftStatus Current Status of the aircraft
 * @returns Connection Duration 0h 0m format
 */
export const calculateConnectionStatusDuration = (
  connectionStart: string,
  connectionEnd: string,
  aircraftStatus: string
): string => {
  let statusDurationValue = null;
  const startTime = sessionStorage.timeOverrideValue ? moment(sessionStorage.timeOverrideValue) : moment.utc();

  if (aircraftStatus === ConnectionStatus.CONNECTED) {
    statusDurationValue = timeDuration(
      moment.utc(splitString(connectionEnd, '.', 0)).format(DATE_TIME_FORMAT_WITH_HR_MINS),
      moment.utc(splitString(connectionStart, '.', 0)).format(DATE_TIME_FORMAT_WITH_HR_MINS)
    );
  } else {
    statusDurationValue = timeDuration(
      startTime.format(DATE_TIME_FORMAT_WITH_HR_MINS),
      moment.utc(splitString(connectionEnd, '.', 0)).format(DATE_TIME_FORMAT_WITH_HR_MINS)
    );
  }
  return statusDurationValue ? statusDurationValue : '--';
};
