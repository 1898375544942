/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Events Timeline Card on the Connectivity Outlook page
 */

import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {ChartCard} from '@viasat/insights-components';

import {useStore} from '../../store/Store';
import EventsTimeline from './EventsTimeline';
import {WHITE, TRANSPARENT} from '../common/theme/Colors';
import {getSelectedLegDisconnectedTime} from './SelectedFlightLegUtils';
import {FlightLegEventsData, formatFlightDuration, generateEventsTimelineData} from './ConnectivityOutlookUtils';

export interface IOriginDestinationData {
  id?: string;
  origin: string;
  destination: string;
  start: string;
  end: string;
}

interface IEventsTimelineCardProps {
  isLoading: boolean;
  eventsTimelineChartHeight: number;
}

const EventsTimeLineCardContainer = styled.div`
  .chart-card {
    margin-top: -36px;
    padding: 0px;
    margin-left: 0px;
    border: none;
  }
  @media print {
    margin-left: 35px;
  }
  background-color: ${WHITE};
`;

const EventsTimelineCard = ({isLoading, eventsTimelineChartHeight}: IEventsTimelineCardProps) => {
  const {store} = useStore();

  const {flightPathInfo, selectedFlightLegIdx} = store.connectivityPlanner;
  const [eventsTimeLineData, setEventsTimeLineData] = useState<FlightLegEventsData[]>(null);
  const [totalDisconnectedTime, setTotalDisconnectedTime] = useState<number>(0);

  useEffect(() => {
    if (!isLoading && flightPathInfo && flightPathInfo.length) {
      const eventsTimeLine = generateEventsTimelineData(flightPathInfo, selectedFlightLegIdx);
      setEventsTimeLineData(eventsTimeLine);
      setTotalDisconnectedTime(getSelectedLegDisconnectedTime(eventsTimeLine, selectedFlightLegIdx));
    }
  }, [isLoading, flightPathInfo, selectedFlightLegIdx]);

  return (
    <EventsTimeLineCardContainer id="ConnectivityOutlookEventsTimelineCard">
      <ChartCard
        borderColor={TRANSPARENT}
        margin={'0px 0px 0px 1px'}
        disableDrag={true}
        getFullElementId={(name, type) => `connectivityOutlook--eventsTimeline__${name}-${type}`}
        title={''}
        disableHelpIcon={true}
        summaries={[
          {
            getFullElementId: (name, type) => `connectivityOutlook--eventsTimeline__${name}-${type}`,
            key: 'connectivity-outlook-events-timeline-total-offline-time',
            name: 'TOTAL OFFLINE TIME',
            value: eventsTimeLineData && eventsTimeLineData.length ? formatFlightDuration(totalDisconnectedTime) : null
          }
        ]}
        isLoading={isLoading}
        isError={false}
      >
        <EventsTimeline
          isLoading={isLoading}
          eventsTimeline={eventsTimeLineData}
          eventsTimelineChartHeight={eventsTimelineChartHeight}
        />
      </ChartCard>
    </EventsTimeLineCardContainer>
  );
};

export default EventsTimelineCard;
