/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Aircraft Status List
 */

import {set} from 'lodash';
import {CustomerAction} from './CustomerReducer';
import {ColumnSortReducer, ColumnSortState} from './ColumnSortReducer';
import {SnackBarState, SnackBarReducer} from './SnackBarReducer';
import {HideColumnsState, HideColumnsReducer, InitialHideColumnsState} from './HideColumnsReducer';
import {delegateToReducers} from '../Reducer';
import {setViewAndColumnType, setFilters, setOrderColumnQuery, setSharelinkHiddenColumns} from '../UrlMap';
import {QUERY_FIELD_MAPPING} from '../../components/lists/aircraftStatus/util/aircraftStatusListUtil';
import {CommonAction} from '../types';
import {FiltersReducer, FiltersState} from './FiltersReducer';
import {AircraftStatusInfo} from '../queries/aircraftStatusList/aircraftStatusListQuery';
import {
  SERVICE_STATUS_FILTER_CONNECTED,
  SERVICE_STATUS_FILTER_DISCONNECTED,
  SERVICE_STATUS_FILTER_IMPAIRED
} from '../../utils/constants';

export const AIRCRAFT_STATUS_LIST_STORE_CONTEXT = 'aircraftStatusList';
export const AIRCRAFT_STATUS_LIST_ROUTE = '/lists/aircraft-status';

export const AircraftStatusListSessionStoreKeys: string[] = [
  'sort.column',
  'sort.order',
  'sort.queryField',
  'filters.filters',
  'hideColumns.hiddenColumns',
  'hideColumns.sharelinkHiddenColumns',
  'showCardView',
  'favoriteAircrafts',
  'autoRefresh',
  'showCompactView'
];

// Not Persisted Session Store Key with default state to use
const NotPersistedSessionStoreKeys: Record<string, any> = {
  'hideColumns.sharelinkHiddenColumns': InitialHideColumnsState.sharelinkHiddenColumns
};

export interface AircraftStatusListState {
  filters: FiltersState;
  sort: ColumnSortState;
  hideColumns: HideColumnsState;
  snackBar: SnackBarState;
  showListNotification: boolean;
  aircraftTotal: number;
  showCardView: boolean;
  favoriteAircrafts: AircraftStatusInfo[];
  autoRefresh: boolean;
  defaultView: string;
  showCompactView: boolean;
  defaultColumnView: string;
}

export enum VIEW_TYPE {
  CARD_VIEW = 'CARD_VIEW',
  LIST_VIEW = 'LIST_VIEW'
}

export enum VIEW_COLUMN_TYPE {
  COMPACT_VIEW = 'COMPACT_VIEW',
  EXPANDED_VIEW = 'EXPANDED_VIEW'
}

export const InitialAircraftStatusListState: AircraftStatusListState = {
  filters: {
    filters: [
      {
        filterId: '1',
        domainOptionsKey: 'status',
        rangeOptionsKeys: [
          SERVICE_STATUS_FILTER_CONNECTED,
          SERVICE_STATUS_FILTER_DISCONNECTED,
          SERVICE_STATUS_FILTER_IMPAIRED
        ]
      }
    ],
    domainOptions: [],
    rangeOptions: {}
  },
  sort: {
    options: undefined,
    column: 'status',
    order: 'desc',
    queryField: QUERY_FIELD_MAPPING['status']
  },
  hideColumns: InitialHideColumnsState,
  snackBar: [],
  showListNotification: true,
  aircraftTotal: undefined,
  showCardView: false,
  favoriteAircrafts: [],
  autoRefresh: true,
  defaultView: null,
  showCompactView: false,
  defaultColumnView: null
};

export enum AircraftStatusListAction {
  SET_SHOW_AIRCRAFT_STATUS_LIST_NOTIFICATION = 'SET_SHOW_AIRCRAFT_STATUS_LIST_NOTIFICATION',
  SET_AIRCRAFT_STATUS_LIST_TOTAL = 'SET_AIRCRAFT_STATUS_LIST_TOTAL',
  CHANGE_VIEW = 'CHANGE_VIEW',
  SET_DEFAULT_VIEW = 'SET_DEFAULT_VIEW',
  SET_FAVORITE_AIRCRAFTS = 'SET_FAVORITE_AIRCRAFTS',
  SET_FAVORITE_AIRCRAFTS_FROM_SELECTOR = 'SET_FAVORITE_AIRCRAFTS_FROM_SELECTOR',
  SET_AUTO_REFRESH = 'SET_AUTO_RREFRESH',
  CHANGE_COLUMN_VIEW = 'CHANGE_COLUMN_VIEW',
  SET_DEFAULT_COLUMN_VIEW = 'SET_DEFAULT_COLUMN_VIEW'
}

/**
 * Filter Not Persisted Session Store Keys
 * @param sessionData Current session state data
 * @returns New filtered state data
 */
export const filterNotPersistedSessionStoreKeys = (sessionData: any) => {
  const dataClone = JSON.parse(sessionData);

  Object.keys(NotPersistedSessionStoreKeys).forEach((sessionStoreKey) => {
    const defaultState = NotPersistedSessionStoreKeys[sessionStoreKey];
    console.debug(`Will not persist '${sessionStoreKey}', set to '${defaultState}'`);
    set(dataClone, sessionStoreKey, defaultState);
  });

  return JSON.stringify(dataClone);
};

/**
 * Aircraft Status List action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const AircraftStatusListReducer = (state: AircraftStatusListState, action: any): AircraftStatusListState => {
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      if (action.payload.reset) {
        const newState = {
          ...InitialAircraftStatusListState,
          favoriteAircrafts:
            state.favoriteAircrafts && state.favoriteAircrafts.length
              ? state.favoriteAircrafts
              : localStorage.watchlistAircraft
              ? JSON.parse(localStorage.watchlistAircraft)
              : []
        };
        // reducer should always return a 'new' object
        state = JSON.parse(JSON.stringify(newState));
      }
      break;

    case CommonAction.PROCESS_URL_PARAMS:
      if (action.payload.path === AIRCRAFT_STATUS_LIST_ROUTE) {
        state = setOrderColumnQuery(state, action.payload);
        state = setFilters(state, action.payload);
        state = setSharelinkHiddenColumns(state, action.payload, AIRCRAFT_STATUS_LIST_STORE_CONTEXT);
        state = setViewAndColumnType(state, action.payload);
      }
      break;

    case AircraftStatusListAction.SET_SHOW_AIRCRAFT_STATUS_LIST_NOTIFICATION:
      state = {...state, showListNotification: action.payload};
      break;

    case AircraftStatusListAction.SET_AIRCRAFT_STATUS_LIST_TOTAL:
      state = {...state, aircraftTotal: action.payload};
      break;
    case AircraftStatusListAction.CHANGE_VIEW:
      state = {
        ...state,
        showCardView: action.payload,
        defaultView: action.payload ? VIEW_TYPE.CARD_VIEW : VIEW_TYPE.LIST_VIEW
      };
      break;
    case AircraftStatusListAction.SET_DEFAULT_VIEW:
      state = {
        ...state,
        defaultView: action.payload
      };
      break;
    case AircraftStatusListAction.CHANGE_COLUMN_VIEW:
      state = {
        ...state,
        showCompactView: action.payload,
        defaultColumnView: action.payload ? VIEW_COLUMN_TYPE.COMPACT_VIEW : VIEW_COLUMN_TYPE.EXPANDED_VIEW
      };
      break;
    case AircraftStatusListAction.SET_DEFAULT_COLUMN_VIEW:
      state = {...state, defaultColumnView: action.payload};
      break;
    case AircraftStatusListAction.SET_AUTO_REFRESH:
      state = {...state, autoRefresh: action.payload};
      break;
    case AircraftStatusListAction.SET_FAVORITE_AIRCRAFTS:
      let favorites: AircraftStatusInfo[] = [];
      action.payload.forEach((aircraft) => {
        if (aircraft.favorite) {
          favorites = [...state.favoriteAircrafts, aircraft];
        } else {
          favorites = state.favoriteAircrafts.filter(function (value) {
            return value.aircraftId !== aircraft.aircraftId;
          });
        }
      });
      localStorage.watchlistAircraft = JSON.stringify(favorites);
      state = {...state, favoriteAircrafts: favorites};
      break;
    case AircraftStatusListAction.SET_FAVORITE_AIRCRAFTS_FROM_SELECTOR:
      let favoriteItems: AircraftStatusInfo[] = [];
      action.payload.forEach((aircraft) => {
        favoriteItems.push(aircraft);
      });
      state = {...state, favoriteAircrafts: favoriteItems};
      localStorage.watchlistAircraft = JSON.stringify(favoriteItems);
      break;

    default:
      state = {
        ...state,
        favoriteAircrafts: localStorage.watchlistAircraft ? JSON.parse(localStorage.watchlistAircraft) : []
      };
      break;
  }
  state = delegateToReducers(
    AIRCRAFT_STATUS_LIST_STORE_CONTEXT,
    state,
    action,
    {
      sort: ColumnSortReducer,
      filters: FiltersReducer,
      hideColumns: HideColumnsReducer,
      snackBar: SnackBarReducer
    },
    InitialAircraftStatusListState
  );
  return state;
};
