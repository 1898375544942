/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: List view component
 */

import React from 'react';
import styled from '@emotion/styled';
import {Breakpoint} from '@mui/material/styles';
import {useIntl} from 'react-intl';
import {
  DatePicker,
  DatePickerState,
  HiddenColumnSelector,
  ListGridColumn,
  ListGridV2
} from '@viasat/insights-components';

import {getElementIdSectionBase} from '../../utils/ElementIdUtils';
import PageContainerTemplate from '../common/layout/page/PageContainerTemplate';
import ListViewSortContainer from '../lists/ListViewSortContainer';
import ShareButton from '../common/elements/share/ShareButton';
import {LoadingButton} from '@viasat/insights-components';
import FilterHookContainer from '../common/elements/filterSelector/FilterHookContainer';
import {AllColumnsHiddenPlaceHolder} from './AllColumnsHiddenPlaceHolder';
import {translateFiltersArrayToObject} from '../../utils/filterUtils';
import {translateHiddenColumnsArrayToObject} from './hiddenColumnsUtils';
import NotificationContainer from '../common/NotificationContainer';
import {removeDatePickerGuardrails} from '../../utils/config';
import {useStore} from '../../store/Store';
import {getActionTypeWithContext} from '../../store/reducerUtils';
import {HideColumnsAction} from '../../store/reducers/HideColumnsReducer';
import CardViewComponent from './aircraftStatus/CardViewComponent';
import {MODAL_FONT_COLOR} from '../common/theme/Colors';
import ViewAsCustomerButton from '../common/elements/viewAsCustomer/ViewAsCustomerButton';
import ListSearch from './ListSearch';
import SwitchCompactExpandedView from './SwitchCompactExpandedView';

export interface ListViewComponentProps {
  idPrefix: string;
  isLoading: boolean;
  tabs: JSX.Element;
  listContext: any;
  listColumns: ListGridColumn[];
  fixedColumnCount: number;
  hiddenColumns: string[];
  listSort: any;
  listData: any;
  listTotal: number;
  listFilters: any;
  handleSortChange: ({column, order}: {column: string; order: 'asc' | 'desc' | ''}) => void;
  notificationMessage: any;
  arePrereqsLoading: boolean;
  showNotification: boolean;
  setShowNotification: (show: boolean) => void;
  handleFilterChange?: () => void;
  currentDateRange?: DatePickerState;
  onSetDateRange?: (newState: DatePickerState) => void;
  listTitle?: string;
  subTitle?: string;
  subTitleLoading?: boolean;
  customRightActions?: JSX.Element[];
  customLeftActions?: JSX.Element[];
  listContainerHeight?: string;
  handleExport?: (success: () => void, failure: (err: any) => void) => void;
  percentageComplete?: number;
  getQueryFieldForColumn?: (column: string) => string;
  showGroupHeader?: boolean;
  listGridSizing?: {
    width?: Breakpoint;
    inlineHeight?: number;
    inlineWidth?: number;
  };
  rowOverscan?: number;
  columnOverscan?: number;
  emptyPlaceHolderStatement?: string;
  emptyPlaceHolderDescription?: string;
  handleRowSelection?: (selectedRow: any) => void;
  handleRowDeselection?: (removedRow: any) => void;
  rowSelectionKey?: string;
  rowDisableKey?: string;
  disabledToolipText?: string;
  selectedRows?: any[];
  showCardView?: boolean;
  showCompactView?: boolean;
  isEndUser?: boolean;
  handleWatchListClick?: (selectedRow: any) => void;
  handleOpenCasesClick?: (selectedRow: any) => void;
  handleSummaryMetricsLinkClick?: (selectedRow: any) => void;
  handleSupportRequestClick?: (selectedRow: any) => void;
  datePickerPresets?: any[];
  viewContext?: string;
  showViewAsCustomer?: boolean;
  scrollTop?: number;
  showListSearch?: boolean;
  setListDataIndex?: (id: number) => void;
  setScrollTop?: (top: number) => void;
  showListSearchStatusIcon?: boolean;
  showSearchSecondaryLabel?: boolean;
  showColumnviewToggle?: boolean;
  handleColumnViewChange?: (showCompactView: boolean) => void;
}

const ListGridContainer = styled.div<{notificationOffset: boolean; height?: string; showCompactView?: boolean}>`
  position: relative;
  height: calc(
    100vh - ${(props) => (props.height ? props.height : '105px')} -
      ${(props) => (props.notificationOffset ? '40px' : '0px')}
  );
  overflow: hidden;
  margin-left: 12px;
  @media only screen and (max-width: 1178px) {
    height: calc(
      100vh - ${(props) => (props.height ? props.height : '105px')} -
        ${(props) => (props.notificationOffset ? '52px' : '0px')}
    );
  }
  @media only screen and (max-width: 548px) {
    height: calc(
      100vh - ${(props) => (props.height ? props.height : '105px')} -
        ${(props) => (props.notificationOffset ? '68px' : '0px')}
    );
  }
  .BaseTable__table-frozen-right .BaseTable__header-cell:first-of-type,
  .BaseTable__table-frozen-right .BaseTable__row-cell:first-of-type,
  .BaseTable__table-main .BaseTable__row-cell:last-of-type {
    padding-right: 0px;
  }
  .BaseTable__table-frozen-right {
    box-shadow: none;
    .BaseTable__header-row:first-of-type .BaseTable__header-cell:last-of-type {
      border-left: none;
    }
    .BaseTable__header-row:last-of-type .BaseTable__header-cell:last-of-type {
      border-left: none;
    }
    .BaseTable__row-cell:last-of-type {
      border-left: 1px solid ${MODAL_FONT_COLOR};
    }
  }
  .BaseTable__table-frozen-left .BaseTable__header .BaseTable__header-row:first-of-type {
    .BaseTable__header-cell:nth-of-type(2) {
      width: ${(props) => (props.showCompactView ? '25px !important' : '86px')};
    }
    .BaseTable__header-cell:nth-of-type(4) {
      visibility: ${(props) => (props.showCompactView ? 'hidden !important' : 'visible')};
    }
  }
`;

const ListViewComponent = (props: ListViewComponentProps) => {
  const {
    idPrefix,
    tabs,
    listTitle,
    subTitle,
    subTitleLoading,
    customRightActions,
    customLeftActions,
    listColumns,
    hiddenColumns,
    listData,
    listTotal,
    listSort,
    listFilters,
    handleExport,
    percentageComplete,
    showGroupHeader,
    arePrereqsLoading,
    isLoading,
    handleSortChange,
    listContext,
    getQueryFieldForColumn,
    notificationMessage,
    showNotification,
    setShowNotification,
    currentDateRange,
    onSetDateRange,
    handleFilterChange,
    emptyPlaceHolderStatement,
    emptyPlaceHolderDescription,
    listContainerHeight,
    showCardView,
    showCompactView,
    isEndUser,
    handleWatchListClick,
    handleOpenCasesClick,
    handleSummaryMetricsLinkClick,
    handleSupportRequestClick,
    datePickerPresets,
    viewContext,
    showViewAsCustomer = false,
    scrollTop,
    showListSearch = false,
    setListDataIndex,
    setScrollTop,
    showListSearchStatusIcon,
    showSearchSecondaryLabel,
    showColumnviewToggle,
    handleColumnViewChange
  } = props;
  const intl = useIntl();
  const {dispatch, store} = useStore();
  const {isInternal} = store.init;

  const headerIdBase = getElementIdSectionBase(idPrefix, 'header');
  const listColumnsIntl = listColumns.map((column) => ({
    ...column,
    tooltipText: column.columnHeaderTooltipText ? intl.formatMessage({id: column.columnHeaderTooltipText}) : null
  }));

  const columns = listColumnsIntl.filter((column) => !(hiddenColumns || []).includes(column.key.toString()));
  const currentHiddenColumns = listColumnsIntl
    .filter((col) => !columns.includes(col))
    .map((hiddenCol) => hiddenCol.key.toString());

  const onHiddenColumnsChange = (hiddenColumns: string[]) => {
    dispatch({
      type: getActionTypeWithContext(HideColumnsAction.SET_HIDDEN_COLUMNS, listContext),
      payload: {hiddenColumns}
    });
  };

  return (
    <PageContainerTemplate
      title={listTitle}
      subtitle={subTitle}
      getFullElementId={(name, type) => `${idPrefix}--pageContainer__${name}-${type}`}
      isSubtitleLoading={subTitleLoading}
      tabs={tabs}
      leftStack={
        customLeftActions
          ? customLeftActions
          : [
              <DatePicker
                key="datePicker"
                getFullElementId={(name: string, type: string) => `${headerIdBase + 'DatePicker'}__${name}-${type}`}
                currentDateRange={currentDateRange}
                onSetDateRange={onSetDateRange}
                removeDatePickerGuardrails={removeDatePickerGuardrails}
                datePickerPresets={datePickerPresets}
                datePickerGuardrailLimitMonths={13}
              />,
              <FilterHookContainer
                key="filters"
                idPrefix={idPrefix}
                storeContext={listContext}
                handleFilterChange={handleFilterChange}
              />,
              <ListViewSortContainer
                key="sorts"
                idPrefix={idPrefix}
                disabled={arePrereqsLoading}
                storeContext={listContext}
                columns={listColumnsIntl}
                showColumnGroups={true}
                getQueryFieldForColumn={getQueryFieldForColumn}
              />,
              <HiddenColumnSelector
                key="columns"
                getFullElementId={(name, type) => `${idPrefix}--columnSelector__${name}-${type}`}
                isDisabled={arePrereqsLoading}
                columns={listColumnsIntl.map((col) => ({
                  id: col.key.toString(),
                  showHideGroup: col.showHideGroup,
                  title: col.title
                }))}
                hiddenColumns={currentHiddenColumns}
                onHiddenColumnsChange={onHiddenColumnsChange}
              />,
              showColumnviewToggle && (
                <SwitchCompactExpandedView
                  key="switchColumnViews"
                  compactView={showCompactView}
                  onChangeColumnView={handleColumnViewChange}
                  isDisabled={isLoading}
                />
              )
            ]
      }
      rightStack={
        customRightActions
          ? customRightActions
          : [
              showViewAsCustomer ? <ViewAsCustomerButton idBase="tailList" key="tailList" /> : <></>,
              showListSearch ? (
                <ListSearch
                  idPrefix={`${idPrefix}-list-search`}
                  data={listData}
                  setAircraftStatusDataIndex={setListDataIndex}
                  setScrollTop={setScrollTop}
                  showListSearchStatusIcon={showListSearchStatusIcon}
                  showSearchSecondaryLabel={showSearchSecondaryLabel}
                />
              ) : (
                <></>
              ),
              !showCardView ? (
                <LoadingButton
                  getFullElementId={(name, type) => `${idPrefix}--exportButton__${name}-${type}`}
                  key={`export${idPrefix}`}
                  title="CSV"
                  successMessage="Done"
                  errorMessage="Error"
                  messageDurationMs={5000}
                  isDisabled={isLoading || listTotal === 0 || columns?.length === 0}
                  onClick={handleExport}
                  percentageComplete={percentageComplete}
                />
              ) : (
                <React.Fragment key="uploadFlightPlanEmpty"></React.Fragment>
              ),
              <ShareButton
                idBase={idPrefix}
                key={`share${idPrefix}`}
                disabled={arePrereqsLoading}
                customFilters={{
                  ...listSort,
                  ...translateFiltersArrayToObject(listFilters),
                  ...translateHiddenColumnsArrayToObject(currentHiddenColumns, listContext)
                }}
                viewContext={viewContext}
              />
            ]
      }
    >
      <NotificationContainer
        id={`${listContext}__notificationContainer-label`}
        handleClick={() => setShowNotification(false)}
        show={showNotification && !isLoading}
        message={notificationMessage}
      />

      {!showCardView ? (
        <ListGridContainer
          notificationOffset={showNotification}
          height={listContainerHeight}
          showCompactView={showCompactView}
        >
          {columns?.length > 0 ? (
            <ListGridV2
              columns={columns}
              listGridData={listData ? listData : []}
              listGridDataTotal={listTotal}
              isHeaderLoading={arePrereqsLoading}
              isLoading={isLoading}
              fullWidthLoadingBars={true}
              selectedSort={listSort}
              showGroupHeader={showGroupHeader}
              handleSortChange={handleSortChange}
              emptyPlaceHolderDescription={emptyPlaceHolderDescription}
              emptyPlaceHolderStatement={emptyPlaceHolderStatement}
              scrollTop={scrollTop ? scrollTop : undefined}
            />
          ) : (
            <AllColumnsHiddenPlaceHolder idPrefix={idPrefix} page="list" />
          )}
        </ListGridContainer>
      ) : (
        <CardViewComponent
          handleWatchListClick={handleWatchListClick}
          handleSummaryMetricsLinkClick={handleSummaryMetricsLinkClick}
          handleOpenCasesClick={handleOpenCasesClick}
          handleSupportRequestClick={handleSupportRequestClick}
          isEndUser={isEndUser}
          listData={listData}
          isLoading={isLoading}
          isInternal={isInternal}
        />
      )}
    </PageContainerTemplate>
  );
};

export default ListViewComponent;
