/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Basic Fetch Hook using React Query
 */
import {QueryClient, useQuery, UseQueryResult} from '@tanstack/react-query';
import {bizavApiUrl as apiBase} from './config';
import {logout} from './auth';

export const queryClient = new QueryClient();

type Verb = 'GET' | 'POST';

const LOGOUT_STATUS = 401;

export interface FetchParams {
  route: string;
  params: Record<string, any> | null; // Null means do not query yet.
  method?: Verb;
}

/**
 * Encodes query parameters for the fetch
 * @param params Query params
 * @returns URL encoded params
 */
const encodeQueryParams = (params: Record<string, any>): string =>
  Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

/**
 * Perform fetch with appropriate base url and headers
 * @param route
 * @param body
 * @param abortSignal
 * @returns fetch response
 */
const queryWithHeaders = async (
  {route, method = 'POST', params}: FetchParams,
  signal?: AbortSignal
): Promise<Response | null> => {
  if (!params) return null;

  const fullRoute = `${apiBase}/${route}`;

  return await fetch(
    method === 'GET' && Object.keys(params).length > 0 ? `${fullRoute}?${encodeQueryParams(params)}` : fullRoute,
    {
      // Query params are not currently supported, but wouldn't be hard to add
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.token}`
      },
      body: method === 'POST' ? JSON.stringify(params) : undefined,
      signal
    }
  );
};

/**
 * Query function for react query that performs a fetch and returns JSON. If logout status, then we log out.
 * If error, we print and throw an error.
 *
 * @param fetchParams Fetch parameters
 * @param signal Abort signal
 * @returns JSON data if successful
 */
const queryFn = async (fetchParams: FetchParams, signal?: AbortSignal): Promise<any> => {
  const response = await queryWithHeaders(fetchParams, signal);
  if (!response) return null;

  if (response.status === LOGOUT_STATUS) {
    const errorMessage = `Received response status: ${response.status}, logging out`;
    console.error(errorMessage);
    logout();
    throw new Error(errorMessage);
  } else if (response.status >= 300) {
    const errorMessage = `Received response status: ${response.status}`;
    console.error(errorMessage);
    return {status: response.status, message: response.json()};
  }

  return await response.json();
};

/**
 * Fetch hook
 * @param key Fetch key
 * @param transform Transformation function
 * @returns Basic Hook Data
 */
const useFetchV2 = <Data>(
  fetchParams: FetchParams,
  transform?: ((data: any) => Data) | null,
  refetchInterval?: number
): UseQueryResult<Data, any> => {
  const enabled = Boolean(fetchParams.params);
  const queryKey = [fetchParams.route, fetchParams.params];

  // We want to clear out queries with the same route, but different parameters.
  // The reason being, I don't want out cache to explode because we set cacheTime to infinite.
  if (enabled && !queryClient.getQueryState(queryKey)) {
    queryClient.removeQueries({queryKey: [fetchParams.route], exact: false});
  }

  return useQuery({
    queryKey,
    select: transform ?? undefined,
    queryFn: async (context) => await queryFn(fetchParams, context.signal),
    enabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchIntervalInBackground: true,
    refetchInterval: refetchInterval ?? false
  });
};

export default useFetchV2;
