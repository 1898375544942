/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Map Flight Path Util
 */
import {isEmpty} from 'lodash';
import {
  BELOW_LOWER_THRESHOLD_COLOR,
  ABOVE_HIGHER_THRESHOLD_COLOR,
  ABOVE_TARGET_COLOR,
  NO_DATA_COLOR,
  NO_SERVICE_COLOR
} from '../common/theme/Colors';
import {ConnectivityOutlookStatus} from '../connectivityOutlook/ConnectivityOutlookUtils';
import {ConnectionStatus} from '../../utils/constants';
import {MapViewContext, MapPointType, getDistance} from '../../utils/MapUtil';
import {FlightPathBeamIncident} from '../../store/queries/flightDetails/flightPathBeamIncidents';
export interface FlightPathPointType extends MapPointType {
  events: any[];
  timestamp: string;
  availability: string | null;
  isRoaming?: boolean;
  hasIncident?: boolean;
  incidents?: FlightPathBeamIncident;
}

export interface FlightPathDataType {
  flightPath: FlightPathPointType[];
  start: MapPointType | null;
  end: MapPointType | null;
  origin?: MapPointType | null;
  destination?: MapPointType | null;
  futureFlightPath?: MapPointType[] | [];
}

export type PaneNames = keyof google.maps.MapPanes;
export interface MapFlightPathProps {
  mapPaneName: PaneNames;
  parentLeftOffset: number;
  flightPathData: FlightPathDataType;
  isFlightPathLoading: boolean;
  hoverLineContainerRef?: any;
  chartTimeSettings?: any;
  liveFlightOffset?: {
    startOffSet: number;
    unit: number;
  };
  isMinimized?: boolean;
  mapViewContext: MapViewContext;
  disablePopup: boolean;
  arrowHeadLocation?: {lat: number; lng: number; timestamp?: string}[];
  isFullScreen?: boolean;
  isLiveFlight?: boolean;
}
export interface HoverPoint {
  position: google.maps.LatLngLiteral;
  color: string;
  startTimestamp: string;
  endTimestamp: string;
  availability: string;
  idx: string;
  isRoaming?: boolean;
  hasIncident?: boolean;
  incidents?: FlightPathBeamIncident;
}

export interface PolylineConfiguration {
  flightPath: google.maps.LatLngLiteral[];
  color?: string;
  startTimestamp?: string;
  endTimestamp?: string;
  availability?: string;
  isFuture?: boolean;
  isRoaming?: boolean;
  hasIncident?: boolean;
  incidents?: FlightPathBeamIncident;
}

/**
 * Converts the availability string into a color
 * @param availability Availability string
 * @returns The availability color
 */
export const getPathColor = (availability: string) => {
  if (availability === ConnectionStatus.CONNECTED) {
    return ABOVE_TARGET_COLOR;
  }

  if (
    availability === ConnectionStatus.IMPAIRED ||
    availability === ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION ||
    availability === ConnectivityOutlookStatus.SATELLITE_HANDOVER
  ) {
    return ABOVE_HIGHER_THRESHOLD_COLOR;
  }

  if (availability === ConnectionStatus.DISCONNECTED) {
    return BELOW_LOWER_THRESHOLD_COLOR;
  }

  if (
    availability === ConnectivityOutlookStatus.OUT_OF_COVERAGE ||
    availability === ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS ||
    availability === ConnectivityOutlookStatus.NO_SERVICE
  ) {
    return NO_SERVICE_COLOR;
  }

  return NO_DATA_COLOR;
};

/**
 * Get the segements of the flight path based on the availability
 * @param flightPathData Raw Flight path data
 * @returns sorted flight path segment list
 */
export const getFlightPathSegmentsBasedOnAvailability = (
  flightPathData: FlightPathDataType
): FlightPathPointType[][] => {
  let currentAvailability = null;
  let isRoaming = false;
  let hasIncident = false;
  let flightPathSegments = [];
  flightPathData.flightPath.forEach((pathPoint) => {
    if (pathPoint.availability !== currentAvailability) {
      currentAvailability = pathPoint.availability;
      flightPathSegments.push([]);
    } else if (pathPoint.isRoaming !== isRoaming) {
      isRoaming = pathPoint.isRoaming;
      flightPathSegments.push([]);
    } else if (pathPoint.hasIncident !== hasIncident) {
      hasIncident = pathPoint.hasIncident;
      flightPathSegments.push([]);
    }
    flightPathSegments[flightPathSegments.length - 1].push(pathPoint);
  });
  return flightPathSegments;
};

/**
 * Build the configuration to render the polyline
 * @param flightPathSegments sorted flight path segment list
 * @returns The input to render polyline
 */
export const constructPolylineInput = (
  flightPathSegments: FlightPathPointType[][],
  mapViewContext: MapViewContext
): PolylineConfiguration[] => {
  let polylinePathData: PolylineConfiguration[] = [];
  if (mapViewContext === MapViewContext.CONNECTIVITY_OUTLOOK_MAP) {
    flightPathSegments.forEach((pathSegment, index) => {
      if (index === flightPathSegments.length - 1) return;
      const firstPointOfNextSegment = flightPathSegments[index + 1][0];

      pathSegment.push(firstPointOfNextSegment);
    });
  }
  flightPathSegments.forEach((pathSegment) => {
    polylinePathData.push({
      flightPath: pathSegment.map((pathPoint) => {
        return {lat: pathPoint.lat, lng: pathPoint.lng};
      }),
      color: getPathColor(pathSegment[0].availability),
      availability: pathSegment[0].availability,
      startTimestamp: pathSegment[0].timestamp,
      endTimestamp: pathSegment[pathSegment.length - 1].timestamp,
      isRoaming: pathSegment[0].isRoaming,
      hasIncident: pathSegment[0].hasIncident,
      incidents: pathSegment[0].incidents
    });
  });
  if (mapViewContext !== MapViewContext.CONNECTIVITY_OUTLOOK_MAP) {
    polylinePathData.forEach((pathSegment, index) => {
      if (index === 0) return;
      const lastPointofPreviousSegment =
        polylinePathData[index - 1].flightPath[polylinePathData[index - 1].flightPath.length - 1];
      pathSegment.flightPath.unshift({lat: lastPointofPreviousSegment.lat, lng: lastPointofPreviousSegment.lng});
    });
  }

  return polylinePathData;
};

/**
 * Build the future flight path segments
 * @param flightPathData raw flight path information
 * @returns The future flight Path
 */
export const getFutureFlightPath = (flightPathData: FlightPathDataType): PolylineConfiguration => {
  const {flightPath, futureFlightPath, destination} = flightPathData;
  const latestFlightPathPoint = flightPath.length > 0 ? flightPath[flightPath.length - 1] : undefined;

  const resolvedDestination =
    !destination || !destination.lat || !destination.lng
      ? futureFlightPath && futureFlightPath.length > 0
        ? futureFlightPath[futureFlightPath.length - 1]
        : null
      : destination;
  const currentDistToDest =
    latestFlightPathPoint && resolvedDestination ? getDistance(latestFlightPathPoint, resolvedDestination) : undefined;

  const filteredFuturePath =
    resolvedDestination && currentDistToDest
      ? futureFlightPath.filter((futurePoint) => {
          const waypointDistToDest = getDistance(futurePoint, resolvedDestination);
          return currentDistToDest > waypointDistToDest;
        })
      : futureFlightPath || [];
  // If the future flight path exists, include the destination airport once
  if (!isEmpty(filteredFuturePath) && destination && destination.lat && destination.lng) {
    const futureDestinationPoint = filteredFuturePath.find(
      (path) => path.lat === destination.lat && path.lng === destination.lng
    );
    filteredFuturePath.unshift({
      lat: flightPath[flightPath.length - 1].lat,
      lng: flightPath[flightPath.length - 1].lng
    });
    if (!futureDestinationPoint) {
      filteredFuturePath.push(destination);
    }
  }
  if (filteredFuturePath.length > 0) {
    return {
      flightPath: filteredFuturePath,
      isFuture: true
    };
  }
};
