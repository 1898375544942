/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Arrival icon
 */

import {createSvgIcon} from '@mui/material';

const ArrivalIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.12 2.039c-1.804.181-3.413.768-4.84 1.767-3.542 2.48-5.102 6.953-3.858 11.059a9.762 9.762 0 0 0 2.517 4.196 9.792 9.792 0 0 0 3.333 2.217c3.362 1.332 7.041.802 9.928-1.433.437-.338 1.307-1.208 1.645-1.645C21.255 16.379 22 14.234 22 12c0-4.568-3.158-8.613-7.57-9.697-.984-.241-2.402-.355-3.31-.264m1.62 2.002A7.98 7.98 0 0 1 19.182 8.5a7.965 7.965 0 0 1-1.417 9.03A7.977 7.977 0 0 1 12 19.999c-3.765 0-7.052-2.668-7.823-6.351a7.995 7.995 0 0 1 4.324-8.831 7.919 7.919 0 0 1 4.239-.776M8.643 8.069C8.291 8.193 8 8.614 8 9c0 .08.029.234.065.343.059.178.278.41 2.289 2.427L12.578 14h-1.864c-2.019 0-2.053.003-2.34.222a1.19 1.19 0 0 0-.243.289c-.095.161-.111.233-.111.489s.016.328.111.489c.061.103.17.234.243.289.299.228.201.222 3.641.22 2.539-.001 3.195-.012 3.315-.055.221-.079.414-.241.539-.454l.111-.189.011-3.217c.012-3.634.025-3.469-.29-3.784a.987.987 0 0 0-1.17-.173 1.065 1.065 0 0 0-.489.585c-.026.093-.042.851-.042 2.008v1.859l-2.23-2.224c-2.017-2.011-2.249-2.23-2.427-2.289a1.032 1.032 0 0 0-.7.004"
      fillRule="evenodd"
    />
  </svg>,
  'ArrivalIcon'
);
export default ArrivalIcon;
