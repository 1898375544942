/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Summary Metrics
 */
import moment from 'moment';
import {isEmpty, set} from 'lodash';
import {CustomerAction} from './CustomerReducer';
import {SnackBarState, SnackBarReducer} from './SnackBarReducer';
import {delegateToReducers} from '../Reducer';
import {isValueChanged, setFilters} from '../UrlMap';
import {CommonAction} from '../types';
import {FiltersReducer, FiltersState} from './FiltersReducer';
import {DATE_TIME_LOCAL_FORMAT, getDefaultEndDate} from '../../utils/DateTimeUtils';
import {DatePickerState, validateDatePicker} from './DatePickerReducer';
import {summaryMetricsDefaultStartDate} from '../../components/dashboard/summaryMetricsUtil';

export const SUMMARY_METRICS_STORE_CONTEXT = 'summaryMetrics';
export const SUMMARY_METRICS_ROUTE = '/dashboard/summary-metrics';

export enum DATA_USAGE_TREND_TOGGLE {
  GB_PER_HOUR = 'GB_PER_HOUR',
  TOTAL_GB = 'TOTAL_GB'
}

const InitialDatePickerState: DatePickerState = {
  startDate: summaryMetricsDefaultStartDate(0),
  endDate: getDefaultEndDate(),
  persistStartDate: false,
  persistEndDate: false,
  snackBar: []
};

export const SummaryMetricsSessionStoreKeys: string[] = ['filters.filters', 'cardOrder'];

// Not Persisted Session Store Key with default state to use
const NotPersistedSessionStoreKeys: Record<string, any> = {};

export interface SummaryMetricsState {
  filters: FiltersState;
  snackBar: SnackBarState;
  dateRange: DatePickerState;
  showGbPerHour: boolean;
  cardOrder: string[];
}

export const InitialSummaryMetricsState: SummaryMetricsState = {
  filters: {
    filters: [
      {
        filterId: '1',
        domainOptionsKey: 'aircraftStatus',
        rangeOptionsKeys: ['Active', 'Decommissioned']
      }
    ],
    domainOptions: [],
    rangeOptions: {}
  },
  snackBar: [],
  dateRange: InitialDatePickerState,
  showGbPerHour: false,
  cardOrder: []
};

export enum SummaryMetricsAction {
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  FILTER_BY_TAIL_ID = 'FILTER_BY_TAIL_ID',
  SET_DATA_USAGE_TREND_TOGGLE = 'SET_DATA_USAGE_TREND_TOGGLE',
  SET_SUMMARY_METRICS_CARD_ORDER = 'SET_FLIGHT_DETAILS_CARD_ORDER'
}

/**
 * Filter Not Persisted Session Store Keys
 * @param sessionData Current session state data
 * @returns New filtered state data
 */
export const filterNotPersistedSessionStoreKeys = (sessionData: any) => {
  const dataClone = JSON.parse(sessionData);

  Object.keys(NotPersistedSessionStoreKeys).forEach((sessionStoreKey) => {
    const defaultState = NotPersistedSessionStoreKeys[sessionStoreKey];
    console.debug(`Will not persist '${sessionStoreKey}', set to '${defaultState}'`);
    set(dataClone, sessionStoreKey, defaultState);
  });

  return JSON.stringify(dataClone);
};

/**
 * Summary Metrics action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const SummaryMetricsReducer = (state: SummaryMetricsState, action: any): SummaryMetricsState => {
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      if (action.payload.reset) {
        const newState = InitialSummaryMetricsState;
        // reducer should always return a 'new' object
        state = JSON.parse(JSON.stringify(newState));
      }
      break;

    case CommonAction.PROCESS_URL_PARAMS:
      const updates: any = {};
      if (action.payload.path === SUMMARY_METRICS_ROUTE) {
        if (isValueChanged(state.dateRange.startDate, action.payload.params.summaryMetricsStartDate)) {
          updates.startDate = action.payload.params.summaryMetricsStartDate;
          updates.persistStartDate = true;
        }

        if (isValueChanged(state.dateRange.endDate, action.payload.params.summaryMetricsEndDate)) {
          updates.endDate = action.payload.params.summaryMetricsEndDate;
          updates.persistEndDate = true;
        }

        if (!isEmpty(updates)) {
          state.dateRange = validateDatePicker({
            ...state,
            ...updates
          });
        }
        state = setFilters(state, action.payload);
      }
      break;
    case SummaryMetricsAction.FILTER_BY_TAIL_ID:
      const tailIdFilter = {
        filters: {
          filters: [
            {
              filterId: '1',
              domainOptionsKey: 'tailId',
              rangeOptionsKeys: action.payload
            }
          ],
          domainOptions: [],
          rangeOptions: {}
        }
      };
      state = {...state, ...tailIdFilter};
      break;
    case SummaryMetricsAction.SET_DATE_RANGE:
      const {startDate, endDate, persistStartDate, persistEndDate, viewContext} = action.payload;
      const summaryMetricsDateRange = {
        startDate: moment.utc(startDate).format(DATE_TIME_LOCAL_FORMAT),
        endDate: moment.utc(endDate).format(DATE_TIME_LOCAL_FORMAT),
        persistStartDate,
        persistEndDate
      };
      if (viewContext && viewContext === SUMMARY_METRICS_STORE_CONTEXT) {
        state = {
          ...state,
          dateRange: summaryMetricsDateRange
        };
      }
      break;
    case SummaryMetricsAction.SET_DATA_USAGE_TREND_TOGGLE:
      state = {...state, showGbPerHour: action.payload === DATA_USAGE_TREND_TOGGLE.GB_PER_HOUR};
      break;

    case SummaryMetricsAction.SET_SUMMARY_METRICS_CARD_ORDER:
      state = {
        ...state,
        cardOrder: action.payload
      };
      break;
    default:
      break;
  }
  state = delegateToReducers(
    SUMMARY_METRICS_STORE_CONTEXT,
    state,
    action,
    {
      filters: FiltersReducer,
      snackBar: SnackBarReducer
    },
    InitialSummaryMetricsState
  );
  return state;
};
