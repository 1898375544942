/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Switch List and Card button component
 */

import React from 'react';
import List from '@mui/icons-material/List';
import ViewStreamSharpIcon from '@mui/icons-material/ViewStreamSharp';
import styled from '@emotion/styled';

import {TOOLBAR_BUTTON_INACTIVE_TEXT_COLOR, TOOLBAR_BUTTON_DISABLED_TEXT_COLOR} from '../common/theme/Colors';
import StyledButton from '../common/StyledButton';

const SwitchViewText = styled.span<{paddingTop: number}>`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  padding-left: 8px;
  padding-top: ${(props) => props.paddingTop}px;
  @media only screen and (max-width: 875px) {
    display: none;
  }
`;

interface SwitchListCardViewProps {
  cardView: boolean;
  isDisabled: boolean;
  onChangeView: (showCardView: boolean) => void;
}

const SwitchListCardView: React.FC<SwitchListCardViewProps> = ({cardView, isDisabled, onChangeView}) => {
  const color = !isDisabled ? TOOLBAR_BUTTON_INACTIVE_TEXT_COLOR : TOOLBAR_BUTTON_DISABLED_TEXT_COLOR;
  return !cardView ? (
    <StyledButton id="switchToCardView" disabled={isDisabled} onClick={() => onChangeView(true)}>
      <ViewStreamSharpIcon sx={{color: color, fontSize: 24}} />
      <SwitchViewText paddingTop={0}>Card View</SwitchViewText>
    </StyledButton>
  ) : (
    <StyledButton id="switchToListView" disabled={isDisabled} onClick={() => onChangeView(false)}>
      <List sx={{color: color, fontSize: 24}} />
      <SwitchViewText paddingTop={1}>List View</SwitchViewText>
    </StyledButton>
  );
};

export default SwitchListCardView;
