/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Telescope icon
 */

import {createSvgIcon} from '@mui/material';

const TelescopeIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8996 6.9L18.1996 7.9L14.1996 1L15.8996 0L19.8996 6.9ZM7.79961 5.9L10.7996 11.1L16.8996 7.6L13.8996 2.4L7.79961 5.9ZM9.39961 10.7L7.39961 7.2L3.09961 9.7L5.09961 13.2L9.39961 10.7ZM0.0996094 12.6L1.09961 14.3L3.69961 12.8L2.69961 11.1L0.0996094 12.6ZM10.0996 12L9.79961 11.6L5.49961 14.1L5.79961 14.5C5.99961 14.8 6.29961 15.1 6.59961 15.3L4.99961 20H6.99961L8.39961 15.7H8.49961L9.99961 20H11.9996L10.0996 14.4C10.5996 13.7 10.5996 12.8 10.0996 12Z" />
  </svg>,
  'Telescope'
);
export default TelescopeIcon;
