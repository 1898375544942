/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description - Connectivity Outlook event icon (Events card, flight path, and events timeline)
 */

import React from 'react';

interface IConnectivityStatusIcon {
  id: string;
  text: string;
  color: string;
  textColor: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
  viewBox?: string;
  textX: string;
  textY: string;
}
/**
 *  Forms an icon which indicates the connectivity status and number of events
 *  @return Connectivity Outlook Event Icon
 */
const ConnectivityStatusIcon: React.FC<IConnectivityStatusIcon> = ({
  id,
  text,
  color,
  textColor,
  width = 24,
  height = 24,
  strokeWidth = 0,
  viewBox,
  textX,
  textY
}) => {
  const viewBoxBoundaries = viewBox ? viewBox : `0 0 ${width + strokeWidth} ${height + strokeWidth}`;
  return (
    <svg
      id={`${id}-${text}`}
      width={width + strokeWidth}
      height={height + strokeWidth}
      viewBox={viewBoxBoundaries}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={width / 2 + strokeWidth}
        cy={width / 2 + strokeWidth}
        r={width / 2 - strokeWidth}
        fill={color}
        stroke="white"
        strokeWidth={strokeWidth}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        fill={textColor}
        fontSize={width / 2}
        fontFamily="Source Sans Pro"
        fontWeight="700"
        dy={textX}
        dx={textY}
      >
        {text}
      </text>
    </svg>
  );
};

export default ConnectivityStatusIcon;
