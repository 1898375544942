/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common configuration
 */

declare let externalConfig: any;

// Allow for default behavior via not defining externalConfig
let internalConfig: any = {};
try {
  internalConfig = externalConfig;
} catch (e) {}

export const DEV_ENVIRONMENT = 'dev';

const environment = internalConfig.ENVIRONMENT_NAME || DEV_ENVIRONMENT;
const bizavApiUrl = internalConfig.BIZAV_API_BASE_URL || 'http://localhost:5001/api/v1';
const bizavClientUrl = internalConfig.BIZAV_CLIENT_URL || 'http://localhost:3001';
const reportingUrl = internalConfig.REPORTING_URL || 'http://localhost:3001';
const logLevel = internalConfig.LOG_LEVEL || 'info';
const googleMapsId = internalConfig.GOOGLE_MAPS || 'AIzaSyDMfESFvGDXwVFuTAMzCQ6HHz5XaVqKvcE';
const removeDatePickerGuardrails = (internalConfig.REMOVE_DATEPICKER_GUARDRAILS || 'false') === 'true';
const datePickerGuardrailLimitMonths = internalConfig.DATEPICKER_GUARDRAIL_LIMIT_MONTHS || 12;
const liveTimeOverrideEnabled = internalConfig.LIVE_TIME_OVERRIDE_DISABLED !== 'true';
const editDepartureTimeEnabled = internalConfig.CONNECTIVITY_OUTLOOK_EDIT_DEPARTURE_TIME_DISABLED !== 'true';
const viewAsCustomerEnabled = internalConfig.VIEW_AS_CUSTOMER_DISABLED !== 'true';
const hideViewAsCustomerForDemoUser = internalConfig.HIDE_VIEW_AS_CUSTOMER_FOR_DEMO_USER !== 'true';
const showDashboardNavMenu = internalConfig.HIDE_DASHBOARD_NAV_MENU !== 'true';
const showAslvTab = internalConfig.HIDE_ASLV_TAB !== 'true';
const showTailSummaryColumn = internalConfig.HIDE_ASLV_SUMMARY_METRICS_COLUMN !== 'true';
const disableAslvSorting = true;
const requestSupportEnabled = internalConfig.ASLV_SUPPORT_BUTTON_DISABLED !== 'true';
const hideGBPerHourButton = internalConfig.HIDE_GB_PER_HOUR_BUTTON !== 'true';
const fullStoryOrgId = internalConfig.FULL_STORY_ORG_ID || 'o-1KAGQA-na1';
const faIntegrationEnabled = internalConfig.FA_INTEGRATION_DISABLED !== 'true';
const showTailListTab = internalConfig.HIDE_TAIL_LIST_TAB !== 'true';

export {
  environment,
  bizavApiUrl,
  bizavClientUrl,
  reportingUrl,
  logLevel,
  googleMapsId,
  removeDatePickerGuardrails,
  datePickerGuardrailLimitMonths,
  liveTimeOverrideEnabled,
  editDepartureTimeEnabled,
  viewAsCustomerEnabled,
  hideViewAsCustomerForDemoUser,
  showDashboardNavMenu,
  showAslvTab,
  disableAslvSorting,
  showTailSummaryColumn,
  requestSupportEnabled,
  hideGBPerHourButton,
  fullStoryOrgId,
  faIntegrationEnabled,
  showTailListTab
};
