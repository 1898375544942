/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 *  Description: Wrapper Component for theme styles and locale
 */

import React from 'react';
import {IntlProvider} from 'react-intl';
import createCache from '@emotion/cache';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import {CacheProvider} from '@emotion/react';
import {ThemeProvider} from '@mui/material/styles';
import appTheme from './components/common/theme/appTheme';
import locale_en from './locale/en.json';

interface AppProvidersProps {
  children: any;
}

// MUI default components styles were taking priority over the emotion styled css.
// Using the CacheProvider, injecting the emotion styled css after the MUI styles.
export const myCache = createCache({
  key: 'emotion-styled-css',
  insertionPoint: document.getElementById('jss-insertion-point')
});

const AppProviders: React.FC<AppProvidersProps> = (props) => {
  return (
    <IntlProvider locale="en" messages={locale_en}>
      <StyledEngineProvider injectFirst>
          <CacheProvider value={myCache}>
            <ThemeProvider theme={appTheme}>{props.children}</ThemeProvider>
          </CacheProvider>
        </StyledEngineProvider>
    </IntlProvider>
  );
};

export default AppProviders;
