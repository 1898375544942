/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ListGrid icon text or link cell
 *
 */
import React from 'react';
import {isEmpty, isNil} from 'lodash';
import styled from '@emotion/styled';
import {GRID_CELL_LINK_COLOR, GRID_CELL_TEXT_ACTIVE_COLOR, GRID_CELL_TEXT_DURATION_COLOR} from '../../theme/Colors';
import ListGridConnectionStatusTooltip from '../tooltip/ListGridConnectionStatusTooltip';

interface ListGridIconTextOrLinkProps {
  cellIdBase: string;
  text?: string | JSX.Element;
  icon?: any;
  tooltipText?: string;
  tooltipExtendedText?: string;
  tooltipIcon?: any;
  descriptionText?: string;
  textUnderlineColor?: string;
  handleClick?: (rowData: any, $event?: any) => void;
  rowData: any;
  placement?: string;
  marginLeft?: number;
}

const Container = styled.div<{text: string | JSX.Element}>`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: ${(props) => (props.text ? 'flex' : 'inline-flex')};
  flex-direction: row;
  cursor: pointer;
`;

const Icon = styled.div`
  width: 12px;
  height: 16px;
`;

const TextOrLink = styled.div<{hasDescription: boolean, marginLeft?: number}>`
  width: ${(props) => (props.hasDescription ? '98px' : 'auto')};
  color: ${GRID_CELL_TEXT_ACTIVE_COLOR};
  margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}px` : '10px')};
  margin-right: 8px;
`;

const StyledLink = styled.a<{decorationColor: boolean}>`
  color: ${GRID_CELL_LINK_COLOR};
  text-decoration-color: ${(props) => (props.decorationColor ? GRID_CELL_TEXT_DURATION_COLOR : '')};
`;

const Description = styled.div`
  width: 70px;
  color: ${GRID_CELL_TEXT_DURATION_COLOR};
  text-align: right;
  margin-right: 16px;
`;

export const ListGridIconTextOrLink: React.FC<ListGridIconTextOrLinkProps> = ({
  cellIdBase,
  icon,
  text,
  tooltipText,
  tooltipExtendedText,
  tooltipIcon,
  descriptionText,
  textUnderlineColor,
  handleClick,
  rowData,
  placement,
  marginLeft
}) => {
  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    handleClick(rowData, event);
  };
  return (
    <>
      <ListGridConnectionStatusTooltip
        placement={placement ? placement : "right"}
        icon={tooltipIcon ? tooltipIcon : null}
        tooltipText={tooltipText}
        tooltipExtendedText={tooltipExtendedText ? tooltipExtendedText : null}
        idPrefix={cellIdBase}
        hide={isEmpty(tooltipText) || isNil(tooltipText)}
      >
        <Container id={`${cellIdBase}_iconTextOrLink`} text={text}>
          {icon ? <Icon>{icon}</Icon> : <></>}
          {text !== undefined ? (
            <TextOrLink hasDescription={descriptionText !== null} marginLeft={marginLeft}>
              {handleClick ? (
                <StyledLink
                  href="#"
                  decorationColor={textUnderlineColor != null}
                  onClick={onLinkClick}
                  id={`${cellIdBase}_iconWithLink`}
                >
                  {text}
                </StyledLink>
              ) : (
                <span id={`${cellIdBase}_iconWithText`}>{text}</span>
              )}
            </TextOrLink>
          ) : (
            <></>
          )}

          {descriptionText && (
            <Description id={`${cellIdBase}_iconWithTextandDescription`}>{descriptionText}</Description>
          )}
        </Container>
      </ListGridConnectionStatusTooltip>
    </>
  );
};

export default ListGridIconTextOrLink;
