/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Renders the filter container component
 */

import React from 'react';
import {FilterSelector} from '@viasat/insights-components';
import {useStore} from '../../../../store/Store';
import {FilterRow, FilterAction} from '../../../../store/reducers/FiltersReducer';
import {getActionTypeWithContext} from '../../../../store/reducerUtils';

export interface FilterHookContainerProps {
  storeContext: string;
  idPrefix: string;
  handleFilterChange?: () => void;
}

const FilterHookContainer: React.FC<FilterHookContainerProps> = ({
  idPrefix,
  storeContext,
  handleFilterChange
}: FilterHookContainerProps) => {
  const {store, dispatch} = useStore();
  const {
    filters: {filters, domainOptions, rangeOptions}
  } = store[storeContext];
  return (
    <>
      <FilterSelector
        getFullElementId={(name, type) => `${idPrefix}FilterSelector__${name}-${type}`}
        filters={filters}
        domainOptions={domainOptions}
        rangeOptions={rangeOptions}
        onFiltersChange={(filters: FilterRow[]) =>
          dispatch({
            type: getActionTypeWithContext(FilterAction.SET_SELECTED_FILTER, storeContext),
            payload: {
              filters
            }
          })
        }
        filterChangeCallback={() => handleFilterChange()}
      />
    </>
  );
};

export default FilterHookContainer;
