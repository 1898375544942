/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Traffic Composition Card on the Flight Details Page
 */

import React from 'react';
import {useIntl} from 'react-intl';
import {ChartCard, MetricsIcon} from '@viasat/insights-components';

import TrafficComposition from '../charts/TrafficComposition';
import {TrafficCompositionData, IDataUsageInfo} from '../charts/TrafficCompositionUtils';
import {ChartTimeSettings} from '../flightDetailsUtil';
import {useStore} from '../../../store/Store';
import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';

interface TrafficCompositionCardProps {
  currentTime?: string;
  isLoading: boolean;
  chartData: TrafficCompositionData[];
  dataUsage: IDataUsageInfo;
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
  draggableProps?: any;
}

const TrafficCompositionCard = ({
  currentTime,
  isLoading,
  chartData,
  chartTimeSettings,
  parentLeftOffset,
  liveMask,
  draggableProps,
  dataUsage
}: TrafficCompositionCardProps) => {
  const intl = useIntl();

  // hide button click handler
  const {dispatch} = useStore();
  const onHideClick = (id) =>
    dispatch({
      type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY,
      payload: {
        hide: true,
        id
      }
    });

  return (
    <div id="TrafficCompositionCardEvents">
      <ChartCard
        draggableProps={draggableProps}
        onHideClick={onHideClick}
        getFullElementId={(name, type) => `flightDetails--trafficComposition__${name}-${type}`}
        Icon={MetricsIcon}
        title="Traffic Composition"
        disableHelpIcon={false}
        helpTooltipMessage={intl.formatMessage({id: 'flight_details.traffic_composition.title_tooltip'})}
        isLoading={isLoading}
        isError={false}
      >
        <TrafficComposition
          currentTime={currentTime}
          isLoading={isLoading}
          trafficCompositionData={chartData}
          chartTimeSettings={chartTimeSettings}
          parentLeftOffset={parentLeftOffset}
          liveMask={liveMask}
          dataUsage={dataUsage}
        />
      </ChartCard>
    </div>
  );
};

export default TrafficCompositionCard;
