/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Init query route
 */
import {Query} from './types';

export interface InitData {
  isInternal: boolean;
  isDemoUser: boolean;
  isValueAddedReseller: boolean;
  email: string;
  uid: string;
  fullName: string;
}

/**
 * Transforms the raw init query data
 * @param initDataRaw Raw data from the init query
 * @returns Transformed init query data
 */
export const transformInitData = (initDataRaw: InitData): InitData | null => initDataRaw || null;

export const initQuery: Query<InitData> = {
  route: 'init',
  transform: transformInitData
};
