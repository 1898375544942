/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Isolate route icon
 */

import {createSvgIcon} from '@mui/material';

const IsolateRouteIcon = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5154 3.25954C10.5154 2.12712 11.4334 1.20911 12.5658 1.20911C13.6982 1.20911 14.6162 2.12712 14.6162 3.25954C14.6162 4.39197 13.6982 5.30998 12.5658 5.30998C11.4334 5.30998 10.5154 4.39197 10.5154 3.25954ZM13.2493 3.25955C13.2493 2.88207 12.9433 2.57607 12.5658 2.57607C12.1883 2.57607 11.8823 2.88207 11.8823 3.25955C11.8823 3.63702 12.1883 3.94302 12.5658 3.94302C12.9433 3.94302 13.2493 3.63702 13.2493 3.25955ZM2.3136 12.8283C2.3136 11.6958 3.23161 10.7778 4.36404 10.7778C5.49646 10.7778 6.41448 11.6958 6.41448 12.8283C6.41448 13.9607 5.49646 14.8787 4.36404 14.8787C3.23161 14.8787 2.3136 13.9607 2.3136 12.8283ZM5.04752 12.8283C5.04752 12.4508 4.74151 12.1448 4.36404 12.1448C3.98656 12.1448 3.68056 12.4508 3.68056 12.8283C3.68056 13.2057 3.98656 13.5117 4.36404 13.5117C4.74151 13.5117 5.04752 13.2057 5.04752 12.8283ZM9.14839 3.25955C9.14839 2.88207 8.84239 2.57607 8.46491 2.57607H6.07274L5.93195 2.57923C4.29866 2.65281 2.99708 4.00027 2.99708 5.65172C2.99708 7.35036 4.3741 8.72738 6.07274 8.72738H11.5406L11.6486 8.73074C12.542 8.78649 13.2493 9.52869 13.2493 10.4361C13.2493 11.3798 12.4843 12.1448 11.5406 12.1448H8.46491L8.39044 12.1488C8.048 12.1859 7.78143 12.4759 7.78143 12.8283C7.78143 13.2057 8.08744 13.5117 8.46491 13.5117H11.5406L11.6814 13.5086C13.3146 13.435 14.6162 12.0875 14.6162 10.4361C14.6162 8.73744 13.2392 7.36042 11.5406 7.36042H6.07274L5.96467 7.35706C5.07133 7.30131 4.36404 6.55912 4.36404 5.65172C4.36404 4.70803 5.12905 3.94302 6.07274 3.94302H8.46491L8.53939 3.93901C8.88183 3.90191 9.14839 3.61186 9.14839 3.25955Z"
    />
  </svg>,
  'IsolateRouteIcon'
);
export default IsolateRouteIcon;
