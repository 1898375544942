/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: WatchListSelector component
 */

import React, {useState} from 'react';
import {PopperWrapper} from '@viasat/insights-components';

import useWindowSize from '../../../utils/useWindowSize';
import WatchListItemsList from './WatchListItemsList';
import WatchListSelectorButton from './WatchListSelectorButton';
import {useStore} from '../../../store/Store';
import {AircraftStatusListAction} from '../../../store/reducers/AircraftStatusListReducer';
import {IAircraftStatusData} from './util/aircraftStatusListUtil';

export interface WatchListSelectorProps {
  getFullElementId: (name: string, type: string) => string;
  listData: IAircraftStatusData[];
  disabled: boolean;
}

const WatchListSelector: React.FC<WatchListSelectorProps> = ({
  getFullElementId,
  listData,
  disabled
}: WatchListSelectorProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [buttonAnchorEl, setButtonAnchorEl] = useState(null);

  const {store, dispatch} = useStore();
  const {favoriteAircrafts} = store.aircraftStatusList;
  let favoriteSerialNumbersList = [];
  favoriteAircrafts?.forEach((aircraft) => {
    favoriteSerialNumbersList.push(aircraft.serialNumber);
  });
  const {width} = useWindowSize();
  const isMobile = width < 590;
  const selectorPlacement = isMobile ? 'bottom-end' : 'bottom-start';

  const populated = favoriteAircrafts.length;

  const togglePickerButton = (open: boolean, $event: any) => {
    setButtonAnchorEl($event.currentTarget);
    setOpen(open);
  };

  const onItemChange = (items: string[]) => {
    let favoriteRowData = listData.filter((item) => items.includes(item.serialNumber));
    favoriteRowData?.forEach((row, id) => {
      row.favorite = !row.favorite;
    });
    dispatch({
      type: AircraftStatusListAction.SET_FAVORITE_AIRCRAFTS_FROM_SELECTOR,
      payload: favoriteRowData
    });
  };

  return (
    <>
      <WatchListSelectorButton
        getFullElementId={getFullElementId}
        populated={Boolean(populated)}
        onOpen={(open: boolean, event: any) => togglePickerButton(open, event)}
        open={open}
        disabled={disabled}
      />
      {open ? (
        <PopperWrapper
          open={open}
          placement={selectorPlacement}
          anchorElement={buttonAnchorEl}
          onExternalClick={($event: any) => setOpen(false)}
          transition
        >
          <WatchListItemsList
            getFullElementId={(name, type) => `WatchListItemSelector__${name}-${type}`}
            selected={favoriteSerialNumbersList}
            onChange={onItemChange}
            disabled={disabled}
            list={listData.map(({serialNumber, favorite, tailId}, idx: number) => ({
              key: `${serialNumber}_range_${idx}`,
              value: serialNumber,
              title: `${serialNumber} / ${tailId}`,
              checked: favorite
            }))}
          />
        </PopperWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default WatchListSelector;
