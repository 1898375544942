/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Flight List
 */

import {set} from 'lodash';
import {CustomerAction} from './CustomerReducer';
import {ColumnSortReducer, ColumnSortState} from './ColumnSortReducer';
import {SnackBarState, SnackBarReducer} from './SnackBarReducer';
import {HideColumnsState, HideColumnsReducer, InitialHideColumnsState} from './HideColumnsReducer';
import {delegateToReducers} from '../Reducer';
import {setFilters, setOrderColumnQuery, setSharelinkHiddenColumns} from '../UrlMap';
import {QUERY_FIELD_MAPPING} from '../../components/lists/flight/flightListUtil';
import {CommonAction} from '../types';
import {FiltersReducer, FiltersState} from './FiltersReducer';

export const FLIGHT_LIST_STORE_CONTEXT = 'flightList';
export const FLIGHT_LIST_ROUTE = '/lists/flight';

export const FlightListSessionStoreKeys: string[] = [
  'sort.column',
  'sort.order',
  'sort.queryField',
  'filters.filters',
  'hideColumns.hiddenColumns',
  'hideColumns.sharelinkHiddenColumns'
];

// Not Persisted Session Store Key with default state to use
const NotPersistedSessionStoreKeys: Record<string, any> = {
  'hideColumns.sharelinkHiddenColumns': InitialHideColumnsState.sharelinkHiddenColumns
};

export interface FlightListState {
  filters: FiltersState;
  sort: ColumnSortState;
  hideColumns: HideColumnsState;
  snackBar: SnackBarState;
  showFlightListNotification: boolean;
  flightListTotal: number;
}

export const InitialFlightListState: FlightListState = {
  filters: {
    filters: [],
    domainOptions: [],
    rangeOptions: {}
  },
  sort: {
    options: undefined,
    column: 'connectedStart',
    order: 'desc',
    queryField: QUERY_FIELD_MAPPING['connectedStart']
  },
  hideColumns: InitialHideColumnsState,
  snackBar: [],
  showFlightListNotification: true,
  flightListTotal: undefined
};

export enum FlightListAction {
  SET_SHOW_FLIGHT_LIST_NOTIFICATION = 'SET_SHOW_FLIGHT_LIST_NOTIFICATION',
  SET_FLIGHT_LIST_TOTAL = 'SET_FLIGHT_LIST_TOTAL'
}

/**
 * Filter Not Persisted Session Store Keys
 * @param sessionData Current session state data
 * @returns New filtered state data
 */
export const filterNotPersistedSessionStoreKeys = (sessionData: any) => {
  const dataClone = JSON.parse(sessionData);

  Object.keys(NotPersistedSessionStoreKeys).forEach((sessionStoreKey) => {
    const defaultState = NotPersistedSessionStoreKeys[sessionStoreKey];
    console.debug(`Will not persist '${sessionStoreKey}', set to '${defaultState}'`);
    set(dataClone, sessionStoreKey, defaultState);
  });

  return JSON.stringify(dataClone);
};

/**
 * Flight List action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const FlightListReducer = (state: FlightListState, action: any): FlightListState => {
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      if (action.payload.reset) {
        // reducer should always return a 'new' object
        state = JSON.parse(JSON.stringify(InitialFlightListState));
      }
      break;

    case CommonAction.PROCESS_URL_PARAMS:
      if (action.payload.path === FLIGHT_LIST_ROUTE) {
        state = setOrderColumnQuery(state, action.payload);
        state = setFilters(state, action.payload);
        state = setSharelinkHiddenColumns(state, action.payload, FLIGHT_LIST_STORE_CONTEXT);
      }
      break;

    case FlightListAction.SET_SHOW_FLIGHT_LIST_NOTIFICATION:
      state = {...state, showFlightListNotification: action.payload};
      break;

    case FlightListAction.SET_FLIGHT_LIST_TOTAL:
      state = {...state, flightListTotal: action.payload};
      break;

    default:
      break;
  }
  state = delegateToReducers(
    FLIGHT_LIST_STORE_CONTEXT,
    state,
    action,
    {
      sort: ColumnSortReducer,
      filters: FiltersReducer,
      hideColumns: HideColumnsReducer,
      snackBar: SnackBarReducer
    },
    InitialFlightListState
  );
  return state;
};
