/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Antenna Encoder Card
 */

import {useIntl} from 'react-intl';
import {useStore} from '../../../store/Store';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';

import {ChartCard} from '@viasat/insights-components';

import {ChartTimeSettings} from '../flightDetailsUtil';

import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';
import {IAntennaEncoderInfo} from '../../../store/queries/flightDetails/antennaEncoderAngleQuery';
import AntennaEncoderChart from '../charts/AntennaEncoder';

interface IAntennaEncoderCardProps {
  currentTime: string;
  chartData: IAntennaEncoderInfo[];
  isLoading: boolean;
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
  draggableProps?: any;
}

const AntennaEncoderCard = ({
  currentTime,
  chartData,
  isLoading,
  chartTimeSettings,
  parentLeftOffset,
  liveMask,
  draggableProps
}: IAntennaEncoderCardProps) => {
  const intl = useIntl();

  // hide button click handler
  const {dispatch} = useStore();
  const onHideClick = (id) =>
    dispatch({
      type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY,
      payload: {
        hide: true,
        id
      }
    });

  return (
    <div id="AntennaEncoderCardEvents">
      <ChartCard
        draggableProps={draggableProps}
        onHideClick={onHideClick}
        getFullElementId={(name, type) => `flightDetails--antennaEncoder__${name}-${type}`}
        Icon={SettingsInputAntennaIcon}
        title={'Antenna Pointing'}
        disableHelpIcon={false}
        helpTooltipMessage={intl.formatMessage({id: 'flight_details.antenna_encoder.title_tooltip'})}
        isLoading={isLoading}
        isError={false}
      >
        <AntennaEncoderChart
          currentTime={currentTime}
          isLoading={isLoading}
          antennaEncoderData={chartData}
          chartTimeSettings={chartTimeSettings}
          parentLeftOffset={parentLeftOffset}
          liveMask={liveMask}
        />
      </ChartCard>
    </div>
  );
};

export default AntennaEncoderCard;
