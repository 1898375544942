/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List Cell Renderers
 */

import {useState, useEffect} from 'react';
import {
  CellRenderer,
  ChartViewCellRenderer,
  DefaultCellRendererV2,
  LoadingBar,
  PopperWrapper,
  HistoryCell,
  HistoryCellPopup
} from '@viasat/insights-components';
import {HistoryPopupProps} from '../../../store/queries/tailList/tailListQuery';
import {LoadingIndicator} from '../../dashboard/SummaryMetricsStyles';
import TextCellRenderer from '../../common/elements/listGridCells/ListGridText';
import ListGridIconTextOrLink from '../../common/elements/listGridCells/ListGridIconTextOrLink';
import {ITrafficChartSeries} from '../../../store/queries/tailList/tailListQuery';
import SeriesLabel from '../../common/elements/chart/ToolTip/SeriesLabel';
import SeriesPrefixIcon from '../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesValue from '../../common/elements/chart/ToolTip/SeriesValue';
import AvailabilityDot from '../../common/AvailabilityDot';
import {STATUS_PILOT, getAirctaftSoftwareStatus} from '../../../store/queries/tailList/swVersionQuery';
import {getSwVersionStatusColor} from '../../../utils/MapUtil';
import DiamondIcon from '../../common/DiamondIcon';
import disconnectedEventsQuery from '../../../store/queries/tailList/disconnectedEvents';
import {
  useQueryInputsForDisconnectedEvents,
  useQueryInputsForHandoverFailures
} from '../../../store/queries/queryUtils';
import {useStore} from '../../../store/Store';
import {getElementIdFragmentBase} from '../../../utils/ElementIdUtils';
import {idPrefix as tailListIdPrefix} from './TailListPage';
import handoverFailuresHistoryQuery from '../../../store/queries/tailList/handoverFailureHistoryQuery';
import useFetchV2 from '../../../utils/useFetchV2';

export const IconTextOrLinkCellRenderer: CellRenderer = ({rowData, cellIdBase, column, cellData}) => {
  const {handleClick, rowTooltipText, icon} = column;

  return !cellData ? (
    <TextCellRenderer cellData={cellData} columns={[]} column={column} columnIndex={0} rowData={rowData} rowIndex={0} />
  ) : (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={icon}
      text={cellData}
      tooltipText={rowTooltipText}
      rowData={rowData}
      handleClick={handleClick}
    />
  );
};

export const TrafficCompositionCellRenderer: CellRenderer = ({rowData, cellIdBase, cellData, column}) => {
  return cellData === undefined ? (
    <LoadingIndicator>
      <LoadingBar id={`${cellIdBase}-loadingBar`} width={76} height={14} />
    </LoadingIndicator>
  ) : (
    <ChartViewCellRenderer cellIdBase={cellIdBase} cellData={cellData} rowData={rowData} />
  );
};

export const TailListCellRenderer: CellRenderer = ({rowData, cellIdBase, cellData, column}) => {
  return cellData === undefined ? (
    <LoadingIndicator>
      <LoadingBar id={`${cellIdBase}-loadingBar`} width={76} height={14} />
    </LoadingIndicator>
  ) : (
    <DefaultCellRendererV2 cellIdBase={cellIdBase} cellData={cellData} rowData={rowData} />
  );
};

/*
 * Generates traffic composition bar chart tooltip
 * @param series Series Data
 * @returns Tooltip element
 */
export const trafficCompositionTooltip = (series: ITrafficChartSeries[]): JSX.Element => {
  return (
    <div id={getElementIdFragmentBase(tailListIdPrefix, 'trafficComposition', 'tooltip')}>
      <SeriesLabel label="Overall Traffic Composition" width="190px" fontWeight={700} fontSize="12" />
      <br />
      {series.map((trafficType: ITrafficChartSeries) => {
        return (
          <>
            <SeriesPrefixIcon color={trafficType.color} />
            <SeriesLabel label={trafficType.name} width="190px" fontWeight={700} fontSize="12" />
            <SeriesValue value={`${Math.round(trafficType.data[0])} %`} width="38px" fontWeight={700} fontSize="12" />
            <br />
          </>
        );
      })}
    </div>
  );
};

export const SoftwareRevisionsCellRenderer: CellRenderer = ({rowData, cellIdBase, column}) => {
  const {handleClick, rowTooltipText} = column;
  const overallAircraftSwStatus = getAirctaftSoftwareStatus(rowData);
  const getIconBasedOnSwVersionStatus = (overallAircraftSwStatus: string) => {
    return overallAircraftSwStatus !== null ? (
      overallAircraftSwStatus !== STATUS_PILOT ? (
        <AvailabilityDot
          id={`${cellIdBase}--swRevisionStaus`}
          sx={{color: getSwVersionStatusColor(overallAircraftSwStatus), width: 17, height: 16}}
        />
      ) : (
        <DiamondIcon
          id={`${cellIdBase}--swRevisionStaus`}
          sx={{color: getSwVersionStatusColor(overallAircraftSwStatus), marginTop: 0.5}}
        />
      )
    ) : (
      <AvailabilityDot
        sx={{color: '#FFFFFF', borderRadius: '50%', border: '1px dashed #9FAFBC', width: 11, height: 10}}
      />
    );
  };

  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={getIconBasedOnSwVersionStatus(overallAircraftSwStatus)}
      text={overallAircraftSwStatus ? 'View' : '--'}
      tooltipText={rowTooltipText}
      rowData={rowData}
      handleClick={overallAircraftSwStatus ? handleClick : null}
    />
  );
};

export const DisconnectedEventsHistoryCellRenderer: CellRenderer = ({
  cellData,
  cellIdBase,
  rowIndex,
  rowData,
  column
}) => {
  const {store} = useStore();
  const historyPopupProps: HistoryPopupProps | any = rowData[`${column.key}History`];
  const showPopup = historyPopupProps?.historyDetails && historyPopupProps?.historyDetails.length > 0;

  const [openModal, setOpenModal] = useState(false);

  const groupCode = store.customer.current.code ? store.customer.current.code : '';

  const queryParams = useQueryInputsForDisconnectedEvents(
    groupCode,
    rowData.aircraftId,
    store.tailList.dateRange.startDate,
    store.tailList.dateRange.endDate
  );

  const {data: disconnectedEventsData, isLoading: disconnectedEventsLoading} = useFetchV2(
    {route: disconnectedEventsQuery.route, params: openModal && showPopup ? queryParams : undefined},
    disconnectedEventsQuery.transform
  );

  const popupDataProps = {historyDetails: disconnectedEventsData, showHistoryLoader: disconnectedEventsLoading};
  return (
    <TailListHistoryComponent
      cellData={cellData}
      cellIdBase={cellIdBase}
      rowIndex={rowIndex}
      rowData={rowData}
      column={column}
      setOpenModal={setOpenModal}
      openModal={openModal}
      popupDataProps={popupDataProps}
    />
  );
};

export const HandoverFailureHistoryCellRenderer: CellRenderer = ({cellData, cellIdBase, rowIndex, rowData, column}) => {
  const {store} = useStore();
  const historyPopupProps: HistoryPopupProps | any = rowData[`${column.key}History`];
  const showPopup = historyPopupProps?.historyDetails && historyPopupProps?.historyDetails.length > 0;

  const [openModal, setOpenModal] = useState(false);
  const groupCode = store.customer.current.code ? store.customer.current.code : '';

  const queryParams = useQueryInputsForHandoverFailures(
    groupCode,
    rowData.aircraftId,
    store.tailList.dateRange.startDate,
    store.tailList.dateRange.endDate
  );

  const {data: handoverFailureData, isLoading: handoverFailureLoading} = useFetchV2(
    {route: handoverFailuresHistoryQuery.route, params: openModal && showPopup ? queryParams : undefined},
    handoverFailuresHistoryQuery.transform
  );
  const totalHandoverFailure = handoverFailureData
    ? handoverFailureData.reduce((acc, flightData) => acc + flightData.handoverFailuresCount, 0)
    : 1;

  const popupDataProps = {
    historyDetails: handoverFailureData,
    showHistoryLoader: handoverFailureLoading,
    headerTotalValue: totalHandoverFailure
  };
  return (
    <TailListHistoryComponent
      cellData={cellData}
      cellIdBase={cellIdBase}
      rowIndex={rowIndex}
      rowData={rowData}
      column={column}
      setOpenModal={setOpenModal}
      openModal={openModal}
      popupDataProps={popupDataProps}
    />
  );
};

interface IDynamicHistoryProps {
  cellData: string;
  cellIdBase: string;
  rowIndex: number;
  rowData: any;
  column: any;
  setOpenModal: any;
  openModal: boolean;
  popupDataProps: any;
}

const TailListHistoryComponent: React.FC<IDynamicHistoryProps> = ({
  cellData,
  cellIdBase,
  rowIndex,
  rowData,
  column,
  setOpenModal,
  openModal,
  popupDataProps
}) => {
  const historyPopupProps: HistoryPopupProps | any = rowData[`${column.key}History`];
  const showPopup = historyPopupProps?.historyDetails && historyPopupProps?.historyDetails.length > 0;

  const [openTooltip, setOpenTooltip] = useState(false);
  const [{clientY, anchorEl}, setPopupProps] = useState({clientY: -1, anchorEl: null});
  // closes the modal on vertical scroll
  useEffect(() => {
    setOpenModal(false);
    setPopupProps({clientY: -1, anchorEl: null});
    // eslint-disable-next-line
  }, [rowIndex]);

  const closePopup = () => {
    setOpenModal(false);
    setPopupProps({clientY: -1, anchorEl: null});
  };

  const openPopup = (newEvent: any) => {
    if (showPopup) {
      setOpenModal(true);
      setPopupProps({clientY: newEvent.clientY, anchorEl: newEvent.currentTarget});
    }
  };
  return (
    <>
      <HistoryCell
        selected={openModal || openTooltip}
        enabled={showPopup}
        showToolTip={openTooltip && !openModal && showPopup}
        onClick={openPopup}
        onMouseEnter={() => {
          setOpenTooltip(true);
        }}
        onMouseLeave={() => {
          setOpenTooltip(false);
        }}
        cellIdBase={cellIdBase}
        cellData={cellData}
        disableCellText={historyPopupProps?.disableCellText}
        tooltipText={historyPopupProps?.tooltipText}
      />
      {openModal && showPopup ? (
        <PopperWrapper open={openModal} placement="bottom-start" anchorElement={anchorEl} onExternalClick={closePopup}>
          <HistoryCellPopup
            historyPopupProps={{
              ...historyPopupProps,
              ...popupDataProps
            }}
            cellIdBase={cellIdBase}
            clientY={clientY}
            openModal={openModal}
            rowIndex={rowIndex}
          />
        </PopperWrapper>
      ) : null}
    </>
  );
};
