/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for customer reducer
 */

import {SnackBarState, SnackBarReducer} from './SnackBarReducer';
import {delegateToReducers} from '../Reducer';
import {CommonAction} from '../types';
import {isValueChanged} from '../UrlMap';

export const CUSTOMER_STORE_CONTEXT = 'customer';

export interface CustomerState {
  previous: {
    code: string | undefined;
  };
  current: {
    code: string | undefined;
  };
  default: {
    code: string | undefined;
  };
  snackBar: SnackBarState;
}

export const InitialCustomerState: CustomerState = {
  previous: {
    code: undefined
  },
  current: {
    code: undefined
  },
  default: {
    code: undefined
  },
  snackBar: []
};

export const CustomerSessionStoreKeys: string[] = ['previous.code', 'current.code', 'default.code'];

export enum CustomerAction {
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_DEFAULT_CUSTOMER = 'SET_DEFAULT_CUSTOMER'
}

/**
 * Customer Picker action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const CustomerReducer = (state: CustomerState, action: any): CustomerState => {
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      state = {
        ...state,
        previous: {code: state.current?.code},
        current: action.payload
      };
      break;
    case CustomerAction.SET_DEFAULT_CUSTOMER:
      state = {
        ...state,
        default: action.payload
      };
      if (!state.current.code) {
        state = {
          ...state,
          previous: {code: undefined},
          current: action.payload
        };
      }
      break;
    case CommonAction.PROCESS_URL_PARAMS:
      if (isValueChanged(state.current?.code, action.payload.params?.customerCode)) {
        state = {
          ...state,
          previous: state.current,
          current: {
            code: action.payload.params.customerCode
          }
        };
      }
      break;
    default:
      break;
  }

  state = delegateToReducers(
    CUSTOMER_STORE_CONTEXT,
    state,
    action,
    {
      snackBar: SnackBarReducer
    },
    InitialCustomerState
  );

  return state;
};
