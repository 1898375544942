/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description - Non flight pin icon
 */

import React from 'react';

interface NonFlightPinIconProps {
  id: string;
}

const NonFlightPinIcon: React.FC<NonFlightPinIconProps> = ({id}) => {
  return (
    <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1442 31.4394L12.1441 31.4395C12.1052 31.4786 12.0533 31.5 11.9999 31.5C11.9466 31.4999 11.8947 31.4786 11.8559 31.4395L11.8558 31.4394C11.6289 31.2108 8.77376 28.3118 5.97878 24.4779C4.58129 22.5609 3.20656 20.4205 2.1829 18.2717C1.15637 16.1168 0.50057 13.9896 0.500594 12.0892L0.500547 12.0844C0.470593 9.00181 1.67292 6.03745 3.83551 3.85877C5.99797 1.68023 8.93867 0.470407 11.9951 0.50055L11.9951 0.500647L12.0049 0.50055C15.0613 0.470407 18.002 1.68023 20.1645 3.85877C22.3271 6.03745 23.5294 9.00181 23.4995 12.0844H23.4994V12.0892C23.4994 13.9896 22.8436 16.1168 21.8171 18.2717C20.7934 20.4205 19.4187 22.5609 18.0212 24.4779C15.2262 28.3118 12.3711 31.2108 12.1442 31.4394Z"
        fill="url(#paint0_linear_3140_6882)"
        stroke="white"
      />
      <svg viewBox="-4 1 33 33" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5 19v2h-19v-2h19Zm-9-4.5a1.5 1.5 0 1 1 .001 2.999A1.5 1.5 0 0 1 12.5 14.5Zm7 0a1.5 1.5 0 1 1 .001 2.999A1.5 1.5 0 0 1 19.5 14.5ZM10 2l5 7.998 5.498-.002c.83.005 1.496.675 1.5 1.502a1.508 1.508 0 0 1-1.503 1.5H3.34L2.003 8.005l1.5.002 1.5 2 5.145-.002-2.143-7.999L10 2Z"
          fill="white"
        />
      </svg>
      <defs>
        <linearGradient id="paint0_linear_3140_6882" x1="0" y1="0" x2="0" y2="32" gradientUnits="userSpaceOnUse">
          <stop stop-color="#2992BB" />
          <stop offset="1" stop-color="#125986" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default NonFlightPinIcon;
