/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Styled Components base CSS
 */
import {isEmpty} from 'lodash';
import {css} from '@emotion/react';

interface Breakpoints {
  sm: number;
  md: number;
  lg: number;
  xl: number;

  [key: string]: number;
}

enum Breakpoint {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}

// https://material-ui.com/layout/breakpoints
export const breakpoints: Breakpoints = {
  xs: 360,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
};

const isBoolean = (v) => v === true || v === false;

// Iterate through the sizes and create a media template
const screenSizeQuery = Object.keys(breakpoints).reduce((acc: any, label: Breakpoint) => {
  const minWidthSize = label === Breakpoint.xs ? 0 : breakpoints[label];
  acc[label] = (v1: any, v2: any) => css`
    @media (min-width: ${minWidthSize}px) {
      ${css(v1, v2)}
    }
  `;
  return acc;
}, {});

/**
 *  Apply Media queries for RWD
 * @param styleProperty
 * @param multiScreenSettings
 * @param appTheme
 * @param force
 * @return screen size style property and its value
 */
export const applyMediaQueriesForStyle = (
  styleProperty: string,
  multiScreenSettings: any,
  appTheme: any,
  force?: any
): string[] | string => {
  // ordering of generation of css for screen sizes items must be in order of size
  if (multiScreenSettings) {
    return Object.keys(breakpoints).map((screenSize: Breakpoint) => {
      const screenSizeProp = screenSizeQuery[screenSize];
      if (screenSizeProp) {
        let styleValue = multiScreenSettings[screenSize];

        if (!isBoolean(styleValue) && isEmpty(styleValue) && typeof styleValue !== 'function') {
          return '';
        }

        // handle where default belongs to the app theme
        if (typeof styleValue === 'function') {
          styleValue = styleValue(appTheme);
        } else if (typeof styleValue === 'string' && styleValue.indexOf('theme.') !== -1) {
          styleValue = appTheme[styleValue.split('.')[1]];
        }

        if (force) {
          styleValue = styleValue + ' !important';
        }
        return screenSizeProp`${styleProperty + ':' + styleValue}`;
      } else {
        return '';
      }
    });
  } else {
    return '';
  }
};
