/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Map View Wrapper component
 */

import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';
import {find, isNil} from 'lodash';
import moment from 'moment';

import ShareButton from '../common/elements/share/ShareButton';
import ViewAsCustomerButton from '../common/elements/viewAsCustomer/ViewAsCustomerButton';
import PageContainerTemplate from '../common/layout/page/PageContainerTemplate';
import {useStore} from '../../store/Store';
import {FleetMapAction} from '../../store/reducers/FleetMapReducer';
import {API_REREQUEST_INTERVAL_MS, BAND_KU, MAP_CENTER, MAP_DEFAULT_ZOOM} from '../../utils/constants';
import FilterHookContainer from '../common/elements/filterSelector/FilterHookContainer';
import LiveTimeEntry from '../common/LiveTimeEntry';
import {liveTimeOverrideEnabled} from '../../utils/config';
import MapAllAircraft from './MapAllAircraft';
import {translateFiltersArrayToObject} from '../../utils/filterUtils';
import useFetch, {QueryParams} from '../../utils/useFetch';
import useAircraftStatusQuery, {
  AircraftStatus,
  getAircraftStatus
} from '../../store/queries/fleetMap/aircraftStatusQuery';
import useFlightPathInfoQuery, {FlightPathInfoDataProps} from '../../store/queries/fleetMap/flightPathInfoQuery';
import {useQueryInputsWithCurrentTimestamp, useQueryInputsForFlightPath} from '../../store/queries/queryUtils';
import {DATE_TIME_FORMAT_WITH_MILLISECONDS} from '../../utils/DateTimeUtils';
import MapStateContainer from './MapStateContainer';
import Footer from '../common/layout/footer/Footer';
import {AirportsType} from '../../utils/MapUtil';
import airportsQuery from '../../store/queries/common/airportsQuery';
import {AppAction} from '../../store/reducers/AppReducer';
import useFlightPathRoamingUsageQuery, {
  FlightPathRoamingUsageInfo
} from '../../store/queries/common/flightRoamingUsageQuery';
import {satelliteBeamIncidentEventsQuery} from '../../store/queries/fleetMap/satelliteBeamIncidentEvents';
import {
  FlightPathBeamIncident,
  useFlightPathBeamIncidentsQuery
} from '../../store/queries/flightDetails/flightPathBeamIncidents';

const MapViewWrapper: React.FC = () => {
  const {store, dispatch} = useStore();
  const {isInternal} = store.init;
  const {filters, filteredData, overlays, tags, view, selectedAircraftId} = store.fleetMap;
  const [{timeOverrideEnable, timeOverrideValue}, setTimeOverride] = useState({
    timeOverrideEnable: sessionStorage.timeOverrideEnable === 'true' ? true : false,
    timeOverrideValue: sessionStorage.timeOverrideValue ? moment(sessionStorage.timeOverrideValue) : moment.utc()
  });

  let [timeStamp, setTstamp] = useState(
    timeOverrideEnable
      ? moment(timeOverrideValue).format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
      : moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
  );

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [zoom, setZoom] = useState(sessionStorage.curMapZoom ? Number(sessionStorage.curMapZoom) : MAP_DEFAULT_ZOOM);

  const mapDivRef = useRef(null);

  const updateTimeOverride = (timeOverrideEnable: boolean, timeOverrideValue: moment.Moment) => {
    setTimeOverride({timeOverrideEnable, timeOverrideValue});
    sessionStorage.timeOverrideEnable = timeOverrideEnable;
    sessionStorage.timeOverrideValue = timeOverrideValue;
    setTstamp(
      timeOverrideEnable
        ? moment(timeOverrideValue).format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
    );
  };

  const [refreshFromInterval, setRefreshFromInterval] = useState<boolean>(false);
  const [flightPathInfo, setFlightPathInfo] = useState<FlightPathInfoDataProps>(null);

  const groupCode = store.customer.current.code ? store.customer.current.code : '';

  const queryParamsWithCurrentTstamp = useQueryInputsWithCurrentTimestamp(groupCode, timeStamp);

  // Callback to determine whether the current cached data for a given query should be returned while a new query is in
  // progress (i.e.loading).
  const canUseAircraftStatusCacheWhileLoading = useCallback(
    (cacheQueryParams: QueryParams): boolean => {
      return (
        cacheQueryParams.groupCode === groupCode &&
        (!timeOverrideEnable || cacheQueryParams.currentTstamp === timeStamp)
      );
    },
    [groupCode, timeOverrideEnable, timeStamp]
  );

  const emptyParams = useMemo(() => ({}), []);

  const {data: airportsList, isLoading: isAirportsLoading} = useFetch<AirportsType>(airportsQuery, emptyParams);

  const {
    data: aircraftStatusQueryData,
    isLoading: isAircraftStatusLoading,
    hasError: hasAircraftStatusError
  } = useFetch<AircraftStatus[]>(
    useAircraftStatusQuery,
    queryParamsWithCurrentTstamp,
    canUseAircraftStatusCacheWhileLoading
  );

  const {data: satelliteBeamIncidentEventsData} = useFetch(
    satelliteBeamIncidentEventsQuery,
    useMemo(() => {
      if (!isInternal) return null;
      return queryParamsWithCurrentTstamp;
    }, [isInternal, queryParamsWithCurrentTstamp])
  );

  useEffect(() => {
    if (airportsList) {
      dispatch({
        type: AppAction.SET_AIRPORTS,
        payload: {
          airports: airportsList
        }
      });
    }
  }, [dispatch, airportsList]);

  useEffect(() => {
    if (!satelliteBeamIncidentEventsData || !satelliteBeamIncidentEventsData.length) return;
    dispatch({
      type: FleetMapAction.SET_SATELLITE_BEAM_INCIDENT_EVENTS,
      payload: satelliteBeamIncidentEventsData
    });
  }, [satelliteBeamIncidentEventsData, dispatch]);

  const showAircraftLoading = isAircraftStatusLoading && !aircraftStatusQueryData && isAirportsLoading;
  const arePrereqsHasError = hasAircraftStatusError;

  if (isAircraftStatusLoading && refreshFromInterval) {
    setRefreshFromInterval(false);
  }

  const arePrereqsLoading = !refreshFromInterval && showAircraftLoading;

  const aircraftWithUpdatedStatus = useMemo(() => {
    return !aircraftStatusQueryData || !timeOverrideEnable
      ? aircraftStatusQueryData
      : aircraftStatusQueryData.map((aircraft) => ({
          ...aircraft,
          status: getAircraftStatus(
            aircraft.last5MinutesConnectivity,
            moment.utc(
              aircraft?.lastNetwork === BAND_KU ? aircraft.connectedEndTimestamp : aircraft.pingsConnectionEndTimestamp
            ),
            moment.utc(aircraft.connectedEndTimestamp),
            moment.utc(aircraft.pingsConnectionEndTimestamp),
            aircraft.isOnGround,
            aircraft.lastNetwork,
            moment.utc(timeStamp)
          )
        }));
  }, [aircraftStatusQueryData, timeOverrideEnable, timeStamp]);

  const selectedAircraftData = find(aircraftWithUpdatedStatus, {aircraftId: selectedAircraftId}) || null;

  let flightPathRequestParams = useQueryInputsForFlightPath(
    groupCode,
    selectedAircraftData?.aircraftId,
    selectedAircraftData?.connectedStartTimestamp,
    selectedAircraftData?.connectedEndTimestamp,
    selectedAircraftData?.isOnGround,
    selectedAircraftData?.flightId,
    selectedAircraftData?.origin,
    selectedAircraftData?.destination,
    selectedAircraftData?.isDark
  );

  // Callback to determine whether the current cached data for a given query should be returned while a new query is in
  // progress (i.e.loading).
  const canUseFlightPathCacheWhileLoading = useCallback(
    (cacheQueryParams: QueryParams): boolean => {
      return (
        cacheQueryParams.groupCode === groupCode && cacheQueryParams?.aircraftId === selectedAircraftData?.aircraftId,
        cacheQueryParams?.startTstamp === selectedAircraftData?.connectedStartTimestamp
      );
    },
    [groupCode, selectedAircraftData]
  );

  const {
    data: flightPathData,
    isLoading: isFlightPathDataLoading,
    hasError: flightPathDataError
  } = useFetch<FlightPathInfoDataProps>(
    useFlightPathInfoQuery,
    flightPathRequestParams,
    canUseFlightPathCacheWhileLoading
  );

  const {data: flightPathRoamingUsage, isLoading: isFlightPathRoamingUsageLoading} = useFetch<
    FlightPathRoamingUsageInfo[]
  >(useFlightPathRoamingUsageQuery, flightPathRequestParams, canUseFlightPathCacheWhileLoading);

  const {data: flightPathBeamIncidents, isLoading: isFlightPathBeamIncidentsLoading} = useFetch<
    FlightPathBeamIncident[]
  >(useFlightPathBeamIncidentsQuery, flightPathRequestParams, canUseFlightPathCacheWhileLoading);

  const showFlightPathLoading = isFlightPathDataLoading || !flightPathData || isFlightPathRoamingUsageLoading;
  const usePrevious = (value) => {
    const ref = useRef([undefined, undefined]);
    if (ref.current[0] !== value) {
      ref.current = [value, ref.current[0]];
    }
    return ref.current[1];
  };
  const previouslySelectedAircraftId = usePrevious(selectedAircraftId);

  useEffect(() => {
    if (selectedAircraftId !== previouslySelectedAircraftId) {
      setFlightPathInfo(null);
    }
    if (isNil(selectedAircraftId)) {
      setFlightPathInfo(null);
    }
  }, [selectedAircraftId, previouslySelectedAircraftId]);
  useEffect(() => {
    if (showFlightPathLoading || flightPathData?.aircraftId !== selectedAircraftData?.aircraftId) return;

    if (flightPathDataError) {
      dispatch({
        type: FleetMapAction.SET_SELECTED_AIRCRAFT,
        payload: undefined
      });
    } else if (flightPathData && flightPathRoamingUsage) {
      // If there's a gap between the selected aircraft and the end of the flight path, add the aircraft's location to
      // the end of the flight path
      if (
        selectedAircraftData &&
        flightPathData.end &&
        (flightPathData.end.lat !== selectedAircraftData.lastLatitude ||
          flightPathData.end.lng !== selectedAircraftData.lastLongitude)
      ) {
        flightPathData.end = {
          ...flightPathData.end,
          lat: selectedAircraftData.lastLatitude,
          lng: selectedAircraftData.lastLongitude
        };

        flightPathData.flightPath.push(flightPathData.end);
      }
      flightPathData.flightPath.map((flightPath) => {
        const flightRoaming = find(flightPathRoamingUsage, {timestamp: flightPath.timestamp});
        if (flightRoaming && flightRoaming.isRoaming) {
          flightPath.events.push({name: 'Roaming', time: flightPath.timestamp});
        }
        return flightPath;
      });
      setFlightPathInfo(flightPathData);
    }
  }, [
    flightPathDataError,
    flightPathData,
    flightPathRoamingUsage,
    showFlightPathLoading,
    selectedAircraftData,
    dispatch
  ]);

  useEffect(() => {
    if (arePrereqsHasError) {
      const errorMsg = 'Error in retrieving aircraft and flight phase data';
      dispatch({type: FleetMapAction.FAIL_REQUEST, payload: errorMsg});
    }
  }, [arePrereqsHasError, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sessionStorage.timeOverrideEnable !== 'true') {
        setTstamp(moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS));
        setRefreshFromInterval(true);
      }
    }, API_REREQUEST_INTERVAL_MS);

    // You want the changing of the inputs to reset the interval
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      isFlightPathDataLoading ||
      isFlightPathBeamIncidentsLoading ||
      !flightPathBeamIncidents ||
      flightPathBeamIncidents.length === 0
    )
      return;
    flightPathData.flightPath?.forEach((flightPathPoint) => {
      const flightPathIncidents = flightPathBeamIncidents?.find((incident) =>
        moment(flightPathPoint.timestamp).isBetween(incident.IMPACT_START, incident.IMPACT_END)
      );
      flightPathPoint.hasIncident = flightPathIncidents ? true : false;
      flightPathPoint.incidents = flightPathIncidents ? flightPathIncidents : undefined;
    });
  }, [flightPathData, flightPathBeamIncidents, isFlightPathDataLoading, isFlightPathBeamIncidentsLoading]);

  const customFilters = {
    ...translateFiltersArrayToObject(filters.filters),
    overlays,
    tags,
    view,
    selectedAircraftId: selectedAircraftId
  };

  return (
    <PageContainerTemplate
      title="Fleet Map"
      subtitle={arePrereqsLoading || !filteredData ? '' : `(${filteredData.length})`}
      getFullElementId={(name, type) => `fleetMap--pageContainer__${name}-${type}`}
      isSubtitleLoading={arePrereqsLoading || !filteredData}
      leftStack={[
        <FilterHookContainer storeContext="fleetMap" key="filters" idPrefix="fleetMap" handleFilterChange={() => {}} />,
        liveTimeOverrideEnabled ? (
          <div key="manual-date-time">
            <LiveTimeEntry
              idBase="fleetMap--container"
              enable={timeOverrideEnable}
              value={timeOverrideValue}
              onChange={updateTimeOverride}
            />
          </div>
        ) : null
      ]}
      rightStack={[
        <ViewAsCustomerButton idBase="fleetMap" key={'fleetMap'} />,
        <ShareButton
          idBase="fleetMap"
          key={'ShareMapView'}
          disabled={arePrereqsLoading}
          customFilters={customFilters}
          skipDateFilters={true}
        />
      ]}
    >
      <MapStateContainer
        getFullElementId={(name, type) => `fleetMap__${name}-${type}`}
        isLoading={arePrereqsLoading}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        zoom={zoom}
        setZoom={setZoom}
        center={sessionStorage.curMapCenter ? JSON.parse(sessionStorage.curMapCenter) : MAP_CENTER}
        mapContainerStyle={{
          width: '100%',
          height: isFullScreen ? '100vh' : 'calc(100vh - 53px)',
          backgroundColor: 'transparent'
        }}
        mapDivRef={mapDivRef}
      >
        <MapAllAircraft
          flightPath={flightPathInfo}
          isFlightPathLoading={showFlightPathLoading && flightPathData?.aircraftId !== selectedAircraftData?.aircraftId}
          aircraftStatusData={aircraftWithUpdatedStatus}
          setIsFullScreen={setIsFullScreen}
          isFullscreen={isFullScreen}
          mapDivRef={mapDivRef}
          parentLeftOffset={0}
          zoom={zoom}
        />
        <Footer />
      </MapStateContainer>
    </PageContainerTemplate>
  );
};

export default MapViewWrapper;
