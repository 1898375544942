/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Data Usage ToolTip Style and Formatting
 */

import React from 'react';
import {ColorProperty} from 'csstype';

import SeriesPrefixIcon from '../../../common/elements/chart/ToolTip/SeriesPrefixIcon';
import SeriesLabel from '../../../common/elements/chart/ToolTip/SeriesLabel';
import SeriesValue from '../../../common/elements/chart/ToolTip/SeriesValue';

import {formatDataUsageValue} from '../../flightDetailsUtil';

export interface DataUsageToolTipProps {
  prefixIconColor?: ColorProperty;
  dataUsageType: number | string;
  dataUsage: number;
}

const DataUsageToolTip: React.FC<DataUsageToolTipProps> = ({
  prefixIconColor,
  dataUsageType,
  dataUsage
}: DataUsageToolTipProps): JSX.Element => {
  return (
    <>
      <SeriesPrefixIcon color={prefixIconColor} />
      <SeriesLabel label={dataUsageType} width="105px" />
      <SeriesValue value={formatDataUsageValue(dataUsage)} width="66px" />
    </>
  );
};

export default DataUsageToolTip;
