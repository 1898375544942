/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Contact details Popup
 */
import React from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Lang, useFormInputValidation} from 'react-form-input-validation';
import {
  POPUP_TEXT_COLOR,
  CLOSE_ICON_COLOR,
  POPUP_BLOCK_BACKGROUND_COLOR,
  PROGRESS_BAR_BG_COLOR,
  SUPPORT_BORDER_COLOR,
  CASE_LIST_TEXT_COLOR,
  POPUP_BACKGROUND_COLOR,
  ACTIVE_BUTTON_COLOR,
  USAGE_EXCEEDED_TEXT,
  EXPANSIONPANEL_TEXT,
  LEFT_PANEL_FLIGHT_PHASE_ICON_COLOR
} from '../../../common/theme/Colors';
import {WhoAmI} from '../../../../store/queries/common/whoAmIQuery';

const ContactDetailsPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px;
  gap: 32px;
  position: relative;
  width: 600px;
  border-radius: 16px;
  background: ${POPUP_BACKGROUND_COLOR};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
  gap: 14px;
  width: 552px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  .input-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: ${EXPANSIONPANEL_TEXT};
  }
`;

const StyledInput = styled.input`
  border: 1px solid ${LEFT_PANEL_FLIGHT_PHASE_ICON_COLOR};
  font-size: 16px;
  font-family: 'Source Sans Pro';
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-radius: 4px;
  width: 98%;
  height: 40px;
  top: 12px;
  padding-left: 16px;
`;

const ContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 552px;
  flex: none;
  order: 0;
  flex-grow: 0;
  .head-text {
    padding: 0px 0px;
    align-items: flex-start;
    letter-spacing: 0.2px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${POPUP_TEXT_COLOR};
  }
  .close-icon {
    width: 10%;
    margin-top: 2px;
    margin-right: 10px;
    color: ${CLOSE_ICON_COLOR};
    cursor: pointer;
    position: absolute;
    right: 0px;
  }
  .frame-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    width: 536px;
    color: ${CASE_LIST_TEXT_COLOR};
    flex: none;
    order: 0;
    flex-grow: 1;
  }
  .error {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${USAGE_EXCEEDED_TEXT};
  }
`;

const FormActions = styled.div`
  left: -24px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 0px 0px 0px;
  gap: 16px;
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-top: 1px solid ${PROGRESS_BAR_BG_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 600px;
  height: 88px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  .action-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${EXPANSIONPANEL_TEXT};
    width: 310px;
    height: 40px;
    text-align: center;
  }
  .cancel-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 4px;
    cursor: pointer;
    border: 1px solid ${SUPPORT_BORDER_COLOR};
    background: ${POPUP_BLOCK_BACKGROUND_COLOR};
    border-radius: 1000px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px;
  }
  .next-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    cursor: pointer;
    gap: 4px;
    border-radius: 1000px;
    position: relative;
    height: 40px;
    right: 0px;
    top: 0px;
    margin-right: 20px;
    background: ${ACTIVE_BUTTON_COLOR};
    border: 1px solid ${POPUP_BLOCK_BACKGROUND_COLOR};
  }
  .next-button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_BACKGROUND_COLOR};
  }
  .next-arrow {
    color: ${POPUP_BACKGROUND_COLOR};
    width: 17.33px;
    height: 17.33px;
  }
  .button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_TEXT_COLOR};
  }
`;

interface ContactDetailsPopupProps {
  closeModal: (openModal: any) => void;
  setOpenAddIssuePopUpModal: (openModal: boolean) => void;
  setPhoneNumber: (phone: string) => void;
  userDetails: WhoAmI;
}

const ContactDetailsPopup: React.FC<ContactDetailsPopupProps> = ({
  closeModal,
  setOpenAddIssuePopUpModal,
  setPhoneNumber,
  userDetails
}) => {
  const [fields, errors, form] = useFormInputValidation(
    {
      name: userDetails.firstname,
      email_address: userDetails.email,
      phone_number: ''
    },
    {
      name: 'required',
      email_address: 'required|email',
      phone_number: 'required|numeric|digits_between:10,12'
    }
  );
  form.useLang(Lang.en);

  const handleNextClick = async (event) => {
    const isValid = await form.validate(event);
    if (isValid) {
      setOpenAddIssuePopUpModal(true);
      setPhoneNumber(fields['phone_number']);
    }
  };

  return (
    <ContactDetailsPopupContainer>
      <ContactDetailsContainer>
        <CloseIcon className="close-icon" onClick={closeModal} />
        <div className="head-text">Request Service from Viasat MCC</div>
        <div className="frame-text">
          To request support for all service or Viasat equipment related problems fill in and submit the information
          below. This will automatically generate a case for your request and sent to Viasat MCC. Viasat support staff
          will reply via email as quickly as possible.
        </div>
        <Form id="form" onSubmit={handleNextClick}>
          <div className="input-text">Name *</div>
          <StyledInput
            id="name"
            type="text"
            name="name"
            onBlur={form.handleBlurEvent}
            onChange={form.handleChangeEvent}
            value={fields['name']}
          />
          <label className="error">{errors['name'] ? errors['name'] : ''}</label>
          <div className="input-text">Notification Email *</div>
          <StyledInput
            id="email"
            type="email"
            name="email_address"
            onBlur={form.handleBlurEvent}
            onChange={form.handleChangeEvent}
            value={fields['email_address']}
          />
          <label className="error">{errors['email_address'] ? errors['email_address'] : ''}</label>
          <div className="input-text">Please Add Your Phone Number *</div>
          <StyledInput
            id="phone"
            type="tel"
            name="phone_number"
            onBlur={form.handleBlurEvent}
            onChange={form.handleChangeEvent}
            value={fields['phone_number']}
          />
          <label className="error">{errors['phone_number'] ? errors['phone_number'] : ''}</label>
          <FormActions>
            <div className="action-text">
              For immediate assistance, please call Viasat MCC @
              <span style={{fontWeight: 'bold'}}> 1-866-VSAT-AIR</span>
            </div>
            <button className="cancel-button" onClick={closeModal}>
              <span className="button-text">Cancel</span>
            </button>
            <button className="next-button" type="submit">
              <span className="next-button-text">Next</span>
              <ArrowForwardIcon className="next-arrow" />
            </button>
          </FormActions>
        </Form>
      </ContactDetailsContainer>
    </ContactDetailsPopupContainer>
  );
};
export default ContactDetailsPopup;
