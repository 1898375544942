/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: A Fetch Hook and basic data transforms for Tail List
 */

import useFetch from '../../../utils/useFetch';
import trafficCompositionQuery, {
  ITrafficCompositionData
} from '../../../store/queries/tailList/trafficCompositionQuery';
import connectivityTimeMetricsQuery, {
  IConnectivityTimeMetrics
} from '../../../store/queries/tailList/connectivityTimeMetrics';
import connectivityUsageMetricsQuery, {
  IConnectivityUsageMetrics
} from '../../../store/queries/tailList/connectivityUsageMetrics';
import tailCustomerHistoryQuery, {TailCustomerHistoryInfo} from '../../../store/queries/tailList/customerHistoryQuery';
import equipmentHistoryQuery, {EquipmentHistoryInfo} from '../../../store/queries/tailList/equipmentHistoryQuery';
import openCasesQuery, {IOpenCases} from '../../../store/queries/tailList/openCasesQuery';
import tailPlanHistoryQuery, {TailPlanHistoryInfo} from '../../../store/queries/tailList/planHistoryQuery';
import swVersionQuery, {SWVersionInfo} from '../../../store/queries/tailList/swVersionQuery';
import tailListQuery, {TailListInfo} from '../../../store/queries/tailList/tailListQuery';
import performanceMetricsQuery, {IPerformanceMetrics} from '../../../store/queries/tailList/performanceMetricsQuery';

const useFetchTailListBase = (
  queryParams: any,
  queryParamsWithLimit: any,
  queryParamsWithSWFiltering: any,
  queryParamsInternal: any,
  isInternal: boolean
) => {
  const querySet = {
    tailListData: useFetch<TailListInfo[]>(tailListQuery, queryParamsWithLimit),
    trafficCompositionData: useFetch<ITrafficCompositionData[]>(trafficCompositionQuery, queryParamsWithLimit),
    equipmentHistoryData: useFetch<EquipmentHistoryInfo[]>(equipmentHistoryQuery, queryParamsWithLimit),
    tailPlanHistoryData: useFetch<TailPlanHistoryInfo[]>(tailPlanHistoryQuery, queryParams),
    tailCustomerHistoryData: useFetch<TailCustomerHistoryInfo[]>(tailCustomerHistoryQuery, queryParamsInternal),
    tailSWVersionsData: useFetch<SWVersionInfo[]>(swVersionQuery, queryParamsWithSWFiltering),
    totalOpenCases: useFetch<IOpenCases[]>(openCasesQuery, queryParams),
    connectivityTimeMetrics: useFetch<IConnectivityTimeMetrics[]>(connectivityTimeMetricsQuery, queryParams),
    connectivityUsageMetrics: useFetch<IConnectivityUsageMetrics[]>(connectivityUsageMetricsQuery, queryParams),
    performanceMetricsData: useFetch<IPerformanceMetrics[]>(performanceMetricsQuery, queryParamsInternal)
  };

  const filterDataLoaded =
    !querySet.trafficCompositionData.isLoading &&
    !querySet.equipmentHistoryData.isLoading &&
    !querySet.tailPlanHistoryData.isLoading &&
    !querySet.tailCustomerHistoryData.isLoading &&
    !querySet.tailSWVersionsData.isLoading &&
    !querySet.totalOpenCases.isLoading &&
    !querySet.connectivityTimeMetrics.isLoading &&
    !querySet.connectivityUsageMetrics.isLoading &&
    !querySet.performanceMetricsData.isLoading &&
    !querySet.tailListData.isLoading &&
    !querySet.performanceMetricsData.isLoading &&
    Boolean(querySet.tailListData.data) &&
    Boolean(querySet.trafficCompositionData.data) &&
    Boolean(querySet.equipmentHistoryData.data) &&
    Boolean(querySet.tailPlanHistoryData.data) &&
    Boolean(querySet.tailCustomerHistoryData.data) &&
    Boolean(querySet.tailSWVersionsData.data) &&
    Boolean(querySet.totalOpenCases.data) &&
    Boolean(querySet.connectivityUsageMetrics.data) &&
    Boolean(querySet.connectivityTimeMetrics.data) &&
    Boolean(querySet.performanceMetricsData.data);

  const isLoading = isInternal
    ? querySet.tailListData.isLoading ||
      querySet.trafficCompositionData.isLoading ||
      querySet.equipmentHistoryData.isLoading ||
      querySet.tailPlanHistoryData.isLoading ||
      querySet.tailCustomerHistoryData.isLoading ||
      querySet.tailSWVersionsData.isLoading ||
      querySet.totalOpenCases.isLoading ||
      querySet.connectivityTimeMetrics.isLoading ||
      querySet.connectivityUsageMetrics.isLoading ||
      querySet.performanceMetricsData.isLoading
    : querySet.tailListData.isLoading ||
      querySet.trafficCompositionData.isLoading ||
      querySet.equipmentHistoryData.isLoading ||
      querySet.tailPlanHistoryData.isLoading ||
      querySet.tailSWVersionsData.isLoading ||
      querySet.totalOpenCases.isLoading ||
      querySet.connectivityTimeMetrics.isLoading ||
      querySet.connectivityUsageMetrics.isLoading;

  const derivedData = {
    isLoading,
    filterDataLoaded
  };

  return {
    ...querySet,
    ...derivedData
  };
};

export default useFetchTailListBase;
