/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight Details Page
 */
import React from 'react';
import {useLocation, useParams} from 'react-router-dom';

import FlightDetailsPage from './FlightDetailsPage';
import {lookupTitle} from '../../utils/browserUtils';

const FlightDetailsContainer: React.FC<any> = ({historyBreadcrumbs}) => {
  const savedHistory = (historyBreadcrumbs || {}).list || [];
  const breadCrumbs = savedHistory.map((pathname) => lookupTitle(pathname)).filter((i) => i !== '');
  const {pathname} = useLocation();
  const {flightId} = useParams<{flightId: string}>();

  const displayBreadCrumbs = (breadCrumbs || []).reduceRight(
    (memo, crumb) => {
      if (!memo.stop) {
        memo.acc.unshift(crumb);
      }
      if (!crumb.includes('Flight Details')) {
        memo.stop = true;
      }
      return memo;
    },
    {stop: false, acc: []}
  ).acc;

  return <FlightDetailsPage pathname={pathname} breadCrumbs={displayBreadCrumbs} flightId={flightId} />;
};

export default FlightDetailsContainer;
