/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft Status Query
 */

import moment from 'moment';

import {BAND_KA, BAND_KU, ConnectionStatus, FlightPhase} from '../../../utils/constants';
import {
  DATE_TIME_FORMAT_WITH_MILLISECONDS,
  formatHMSecondsValue,
  msAsString,
  SECONDS_PER_MINUTE
} from '../../../utils/DateTimeUtils';
import {Query} from '../types';

export interface AircraftStatus {
  aircraftId: string;
  aircraftManufacturer: string;
  isLabTerminal?: boolean;
  endUser?: string;
  aircraftType: string;
  serialNumber: string;
  tailId: string;
  networkCapability: string;
  flightId: string;
  faFlightId: string;
  connectedStartTimestamp: string;
  connectedEndTimestamp: string;
  connectionDurationSeconds: number;
  flightStartTimestamp: string;
  flightEndTimestamp: string;
  cruiseStartTimestamp: string;
  cruiseEndTimestamp: string;
  startLatitude: number;
  startLongitude: number;
  lastLatitude: number;
  lastLongitude: number;
  lastHeading: number;
  lastNetwork: string;
  lastFlightPhase: string;
  last5MinutesConnectivity: number;
  dataUsageBytes: number;
  flightDetected: boolean;
  status: string;
  isOnGround: boolean;
  pingsConnectionStartTimestamp: string;
  pingsConnectionEndTimestamp: string;
  isDark: boolean;
  origin: string;
  destination: string;
  actualDepartureTstamp: string;
  actualArrivalTstamp: string;
  estimatedArrivalTstamp: string;
  remainingTime: string;
}

export interface AircraftStatusRaw {
  aircraftId: string;
  aircraftManufacturer: string;
  isLabTerminal?: boolean;
  endUser?: string;
  aircraftType: string;
  serialNumber: string;
  networkCapability: string;
  tailId: string;
  flightId: string;
  faFlightId: string;
  connectedStartTimestamp: number;
  connectedEndTimestamp: number;
  connectionDurationSeconds: number;
  flightStartTimestamp: number;
  flightEndTimestamp: number;
  cruiseStartTimestamp: number;
  cruiseEndTimestamp: number;
  startLatitude: number;
  startLongitude: number;
  lastLatitude: number;
  lastLongitude: number;
  lastHeading: number;
  lastNetwork: string;
  lastFlightPhase: string;
  last5MinutesConnectivity: number;
  dataUsageBytes: number;
  flightDetected: boolean;
  pingsConnectionStartTimestamp: number;
  pingsConnectionEndTimestamp: number;
  isDark: boolean;
  origin: string;
  destination: string;
  actualDepartureTstamp: number;
  actualArrivalTstamp: number;
  estimatedArrivalTstamp: number;
  remainingTime: number;
}

/**
 * Determines status by the given connectedEndTimestamp and last5MinutesConnectivity
 * @param last5MinutesConnectivity Latest connectivity status
 * @param connectedEndTimestamp Connected end timestamp
 * @param ndrConnectionEndTime NDR Connected end timestamp
 * @param pingsConnectionEndTime Pings Connected end timestamp
 * @param isOnGround Whether or not the aircraft is on the ground
 * @param nowTimestamp Timestamp to be compared with the connected end. Defaults to now (UTC).
 * @returns Status of aircraft
 */
export const getAircraftStatus = (
  last5MinutesConnectivity: number,
  connectedEndTimestamp: moment.Moment,
  ndrConnectionEndTime: moment.Moment,
  pingsConnectionEndTime: moment.Moment,
  isOnGround: boolean,
  lastNetwork: string,
  nowTimestamp: moment.Moment = moment.utc()
) => {
  const connectedWithinLast60Minutes = nowTimestamp.diff(connectedEndTimestamp, 'seconds') <= 60 * SECONDS_PER_MINUTE; // 60 mins = 3600 seconds

  // There are two reasons for the following connectivity time padding:
  // 1) Our Snowflake data pipeline requires further optimization and can end up taking longer than anticipated to report the status of a given aircraft.
  // 2) At 8 minutes, exactly 40% of the 5 minute window is still within the last 10 minutes, so anything past that is guaranteed to be within the DISCONNECTED state.
  // Overall, these delays are set in place to prevent aircraft from flickering between DISCONNECTED and CONNECTED.
  const streamDelay = 3 * SECONDS_PER_MINUTE; // 3 mins = 180 seconds
  const last5Minutes = 5 * SECONDS_PER_MINUTE; // 5 mins = 300 seconds
  const connectedWithinPipelineDelay =
    nowTimestamp.diff(connectedEndTimestamp, 'seconds') <= streamDelay + last5Minutes;

  // This will return true/false based on difference between ndr & pings end time
  const isPingsDataDelay =
    ndrConnectionEndTime && pingsConnectionEndTime
      ? ndrConnectionEndTime.diff(pingsConnectionEndTime, 'minutes') <= 3
      : false;
  if (connectedWithinPipelineDelay) {
    if (last5MinutesConnectivity >= 0.75) {
      return ConnectionStatus.CONNECTED;
    }

    if (last5MinutesConnectivity >= 0.4) {
      if (isPingsDataDelay && lastNetwork === BAND_KA) {
        return ConnectionStatus.CONNECTED;
      } else {
        return ConnectionStatus.IMPAIRED;
      }
    }

    return ConnectionStatus.DISCONNECTED;
  }

  // On-ground aircraft that aren't connectedWithinPipelineDelay are always considered offline
  if (isOnGround) {
    return ConnectionStatus.OFFLINE;
  }

  if (connectedWithinLast60Minutes) {
    return ConnectionStatus.DISCONNECTED;
  }

  return ConnectionStatus.OFFLINE;
};

/**
 * Transforms the given raw aircraft status data into (regular) flight status data
 * @param aircraftStatusRaw Raw aircraftStatus values from the api
 * @returns Transformed AircraftStatus data
 */
export const transformRawAircraftStatusList = (
  aircraftStatusRawList: AircraftStatusRaw[] | null
): AircraftStatus[] | null => {
  if (!aircraftStatusRawList) return null;

  const aircraftStatusList: AircraftStatus[] = aircraftStatusRawList.map((aircraftStatusRaw) => {
    const {
      aircraftId,
      aircraftManufacturer,
      isLabTerminal,
      endUser,
      aircraftType,
      serialNumber,
      tailId,
      networkCapability,
      flightId,
      connectedStartTimestamp,
      connectedEndTimestamp,
      connectionDurationSeconds,
      flightStartTimestamp,
      flightEndTimestamp,
      cruiseStartTimestamp,
      cruiseEndTimestamp,
      startLatitude,
      startLongitude,
      lastLatitude,
      lastLongitude,
      lastHeading,
      lastNetwork,
      lastFlightPhase,
      last5MinutesConnectivity,
      dataUsageBytes,
      flightDetected,
      pingsConnectionStartTimestamp,
      pingsConnectionEndTimestamp,
      isDark,
      origin,
      destination,
      actualArrivalTstamp,
      actualDepartureTstamp,
      estimatedArrivalTstamp,
      remainingTime,
      faFlightId
    } = aircraftStatusRaw;

    const isOnGround = lastFlightPhase === FlightPhase.ON_GROUND;
    const aircraftConnectionEndTimestamp =
      lastNetwork === BAND_KU ? connectedEndTimestamp : pingsConnectionEndTimestamp;

    const status = getAircraftStatus(
      last5MinutesConnectivity,
      moment.utc(aircraftConnectionEndTimestamp),
      moment.utc(connectedEndTimestamp),
      moment.utc(pingsConnectionEndTimestamp),
      isOnGround,
      lastNetwork
    );

    return {
      aircraftId: aircraftId,
      aircraftManufacturer: aircraftManufacturer,
      isLabTerminal: isLabTerminal,
      endUser: endUser,
      aircraftType: aircraftType,
      serialNumber: serialNumber,
      tailId: tailId,
      networkCapability: networkCapability,
      flightId: flightId,
      faFlightId,
      connectedStartTimestamp: connectedStartTimestamp
        ? msAsString(connectedStartTimestamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      connectedEndTimestamp: connectedEndTimestamp
        ? msAsString(connectedEndTimestamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      connectionDurationSeconds: connectionDurationSeconds,
      flightStartTimestamp: flightStartTimestamp
        ? msAsString(flightStartTimestamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      flightEndTimestamp: flightEndTimestamp
        ? msAsString(flightEndTimestamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      cruiseStartTimestamp: cruiseStartTimestamp
        ? msAsString(cruiseStartTimestamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      cruiseEndTimestamp: cruiseEndTimestamp
        ? msAsString(cruiseEndTimestamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      startLatitude: startLatitude,
      startLongitude: startLongitude,
      lastLatitude: lastLatitude,
      lastLongitude: lastLongitude,
      lastHeading: lastHeading,
      lastNetwork: lastNetwork,
      lastFlightPhase: lastFlightPhase,
      last5MinutesConnectivity: last5MinutesConnectivity,
      dataUsageBytes: dataUsageBytes,
      flightDetected: flightDetected,
      status: status,
      isOnGround,
      pingsConnectionStartTimestamp: pingsConnectionStartTimestamp
        ? msAsString(pingsConnectionStartTimestamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      pingsConnectionEndTimestamp: pingsConnectionEndTimestamp
        ? msAsString(pingsConnectionEndTimestamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      isDark,
      origin,
      destination,
      actualArrivalTstamp: actualArrivalTstamp
        ? msAsString(actualArrivalTstamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      actualDepartureTstamp: actualDepartureTstamp
        ? msAsString(actualDepartureTstamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      estimatedArrivalTstamp: estimatedArrivalTstamp
        ? msAsString(estimatedArrivalTstamp, DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : null,
      remainingTime: remainingTime ? formatHMSecondsValue(remainingTime / 1000) : null
    };
  });

  return aircraftStatusList;
};

const useAircraftStatusQuery: Query<AircraftStatus[]> = {
  route: 'fleetMap/aircraftStatusV2',
  transform: transformRawAircraftStatusList
};

export default useAircraftStatusQuery;
