/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utility functions to maps Url State into Store State
 */

import {isNil} from 'lodash';
import {Severity} from '@viasat/insights-components';

import {translateHiddenColumnsStringToColumnIds} from '../components/lists/hiddenColumnsUtils';
import {FleetMapState} from './reducers/FleetMapReducer';
import {setInvalidHiddenColumnsSnackbar} from './reducers/HideColumnsReducer';
import {createSnackBar} from './reducers/SnackBarReducer';
import {convertToInt} from './reducerUtils';
import {UrlParamsPayload} from './types';
import {VIEW_TYPE, VIEW_COLUMN_TYPE} from './reducers/AircraftStatusListReducer';

// TODO (CMDO-77872): move this to a generic utility file, preferably one focused on FP / lodash-like utils
//  this is NOT specific to UrlMap
/**
 * Returns whether the new value is non-null, not undefined, and different from the current value
 * @param currentValue the current value
 * @param newValue the new value
 * @returns true if the value has changed, false otherwise
 */
export const isValueChanged = (currentValue: any, newValue: any): boolean => {
  return !isNil(newValue) && newValue !== currentValue;
};

/**
 * Take parsed url filters and return a new state
 * Reset filters state if no url filters
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setFilters = (state: any, urlParams: UrlParamsPayload): any => {
  const filters = getFiltersToApply(urlParams.params);
  if (filters.length > 0) {
    state = {
      ...state,
      filters: {
        ...state.filters,
        filters
      }
    };
  } else {
    state = {
      ...state,
      filters: {
        ...state.filters,
        filters: []
      }
    };
  }
  return state;
};

/**
 * Take parsed url sort/order and return new state
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setOrderColumn = (state: any, urlParams: UrlParamsPayload): any => {
  if (urlParams.params.order && urlParams.params.column) {
    const {order, column} = urlParams.params;
    state = {
      ...state,
      sort: {
        ...state.sort,
        order,
        column
      }
    };
  }
  return state;
};

/**
 * Take parsed url sort/order/queryField and return new state
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setOrderColumnQuery = (state: any, urlParams: UrlParamsPayload): any => {
  if (urlParams.params.order && urlParams.params.column && urlParams.params.queryField) {
    const {order, column, queryField} = urlParams.params;
    state = {
      ...state,
      sort: {
        ...state.sort,
        order,
        column,
        queryField
      }
    };
  }
  return state;
};

/**
 * Take parsed url hidden columns and return new state
 * NOTE: only saves in session storage
 * @param state current state
 * @param urlParams parsed url data
 * @param listContext list context
 * @return new state
 */
export const setSharelinkHiddenColumns = (state: any, urlParams: UrlParamsPayload, listContext: string): any => {
  if (urlParams.params.hiddenColumns) {
    const {hiddenColumns: sharelinkHiddenColumnsString} = urlParams.params;

    const {columnIds, invalidColumnIds} = translateHiddenColumnsStringToColumnIds(
      sharelinkHiddenColumnsString,
      listContext
    );

    if (invalidColumnIds.length > 0) state = setInvalidHiddenColumnsSnackbar(state, invalidColumnIds);

    // New State, Sharelink hiddenColumns saved to sessionStorage
    state = {
      ...state,
      hideColumns: {
        hiddenColumns: state.hideColumns.hiddenColumns, // preserve hiddenColumns
        sharelinkHiddenColumns: columnIds // set sharelink hiddenColumns
      }
    };
  } else {
    // Show All, use hiddenColumns Default
    state = {
      ...state,
      hideColumns: {
        hiddenColumns: state.hideColumns.hiddenColumns, // preserve hiddenColumns
        sharelinkHiddenColumns: [] // set sharelink hiddenColumns
      }
    };
  }
  return state;
};

/**
 * Take parsed url sort/order/queryField and return new state
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setViewAndColumnType = (state: any, urlParams: UrlParamsPayload): any => {
  state = {
    ...state,
    ...(urlParams.params?.showCardView
      ? {
          defaultView: urlParams.params.showCardView === 'true' ? VIEW_TYPE.CARD_VIEW : VIEW_TYPE.LIST_VIEW,
          showCardView: urlParams.params.showCardView === 'true'
        }
      : {}),
    ...(urlParams.params?.showCompactView
      ? {
          showCompactView: urlParams.params.showCompactView === 'true' ? true : false,
          defaultColumnView:
            urlParams.params.showCompactView === 'true' ? VIEW_COLUMN_TYPE.COMPACT_VIEW : VIEW_COLUMN_TYPE.EXPANDED_VIEW
        }
      : {})
  };
  return state;
};

/**
 * Returns Filters to apply from url filter object
 * @param filtersObject filtersObject from the url
 * @return Filters to apply
 */
export const getFiltersToApply = (filtersObject: any) => {
  return Object.keys(filtersObject).reduce((acc, key) => {
    const dashIndex = key.indexOf('-');
    if (dashIndex < 0) {
      return acc;
    }
    // getDash Index:
    const value = filtersObject[key];
    const filterId = key.slice(0, dashIndex);
    const domainOptionsKey = key.slice(dashIndex + 1);
    return [...acc, {filterId, domainOptionsKey, rangeOptionsKeys: value}];
  }, []);
};

export const setHistoricalConnectedStart = (state: any, urlParams: UrlParamsPayload) => {
  if (urlParams.params.connectedStart) {
    const {connectedStart} = urlParams.params;
    state = {
      ...state,
      historicalConnectedStart: connectedStart
    };
  }
  return state;
};

/**
 * Updates the state with overlay information if available or resets it to the default
 * @param state Flight map state
 * @param overlays URL overlays object
 * @returns Updated flight map state
 */
export const updateKuKaSatelliteState = (state: FleetMapState, overlays: any) => {
  if (!overlays) {
    return state;
  }

  let newSelectedKaSatellites = state.overlays.selectedKaSatellites ? state.overlays.selectedKaSatellites : [];
  let newSelectedKuSatellites = state.overlays.selectedKuSatellites ? state.overlays.selectedKuSatellites : [];

  try {
    if (overlays && overlays.selectedKaSatellites) {
      newSelectedKaSatellites = overlays.selectedKaSatellites.map((satIdStr) => convertToInt(satIdStr));
    }
  } catch (e) {
    state.snackBar.push(
      createSnackBar({
        message: 'Share link contains invalid satellites, ignoring.',
        severity: Severity.WARNING,
        getFullElementId: (name, type) => `${name}-${type}`
      })
    );
  }

  return {
    ...state,
    overlays: {
      ...state.overlays,
      ...overlays,
      selectedKaSatellites: newSelectedKaSatellites,
      selectedKuSatellites: newSelectedKuSatellites
    }
  };
};
