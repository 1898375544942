/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Events Timeline chart utility functions and interfaces
 *
 */
import moment from 'moment';
import {findIndex, isNil} from 'lodash';
import {CHART_CARD_PADDING, MISSING_DATA_POINT_COUNT, FlightPhase} from '../../../utils/constants';
import {EventDetailInfo} from './tooltip/EventsTimelineToolTip';

export enum FlightPhaseCategory {
  unknownLabel = 'Unknown',
  groundLabel = 'Ground',
  ascDescLabel = 'Asc/Desc',
  cruisingLabel = 'Cruising'
}

export enum EventsTimelineStatus {
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
  IMPAIRED = 'Impaired',
  CONNECTED_ROAMING = 'Connected:Roaming',
  DISCONNECTED_ROAMING = 'Disconnected:Roaming',
  IMPAIRED_ROAMING = 'Impaired:Roaming'
}

export interface EventsTimelineData {
  eventDate: moment.Moment;
  status: EventsTimelineStatus;
  eventName?: string;
  isRoaming?: boolean;
}

export interface FlightPhaseData {
  connectionStart: moment.Moment;
  connectionEnd: moment.Moment;
  flightStart: moment.Moment;
  flightEnd: moment.Moment;
  cruiseStart: moment.Moment;
  cruiseEnd: moment.Moment;
}

export interface SeriesData {
  name: EventsTimelineStatus;
  color: string;
  borderColor: string;
  data: SeriesDataPoint[];
  legendIndex: Number;
  xAxis?: number;
  yAxis?: number;
  showInLegend?: boolean;
}

export interface SeriesDataPoint {
  x: number;
  low: number;
  high: number;
  eventName?: string;
  iconText?: number;
}

/**
 * Builds a EventDetailInfo with undefined dtHigh value
 * @param label The label for the EventDetailInfo
 * @param startDate The dtLow value for EventDetailInfo
 * @returns EventDetailInfo
 */
export const toolTipValue = (label: string, startDate: string): EventDetailInfo => {
  return {
    label: label,
    dtLow: startDate,
    dtHigh: undefined
  };
};

/**
 * Tooltip positioner
 * @param chartContainer Chart container
 * @param plotX Plot X value
 * @param plotY Plot Y value
 * @param boxWidth Box width
 * @param boxHeight Box height
 * @returns x, y position
 */
 export const positioner = (scrollContainer: any, barWidth: number) => (
  chartContainer: any,
  plotX: number,
  plotY: number,
  boxWidth: number,
  boxHeight: number
) => {
  const layoutYOffset = 62;
  const layoutYMinimizedOffset = 62;
  const layoutXOffsetMenuOpen = 168;
  const layoutXOffsetDrawerOpen = 378;

  const menuOpened = scrollContainer.className.includes('menuOpened');
  const drawerOpen = scrollContainer.className.includes('drawerOpen');
  const mapMinimized = scrollContainer.className.includes('mapMinimized');

  const offsetY = mapMinimized ? layoutYMinimizedOffset : layoutYOffset;

  const anchorX =
    chartContainer.offsetLeft +
    32 +
    (menuOpened ? layoutXOffsetMenuOpen : 0) +
    (drawerOpen ? layoutXOffsetDrawerOpen : 0);
  const anchorY = chartContainer.offsetTop + offsetY;

  const topPadding = 8;
  const bottomPadding = 12;
  const scrollLeft = scrollContainer.parentElement.parentElement.scrollLeft;
  const scrollTop = scrollContainer.scrollTop;
  const scrollWidth = scrollContainer.clientWidth - CHART_CARD_PADDING;
  // left/right
  const leftX = plotX - scrollLeft - boxWidth / 5;
  const rightX = leftX + boxWidth;
  const rightOverflowOffset = Math.min(0, scrollWidth - rightX);
  const leftOverHang = Math.abs(leftX) / 2;
  const rightOverHang = Math.abs(rightOverflowOffset);
  const leftOverflowOffset =
    leftX < 0 ? (rightOverflowOffset < 0 ? rightOverHang : Math.min(rightOverHang, leftOverHang)) : 0;

  // top/bottom
  const yTop = anchorY + plotY - scrollTop;
  const aboveOffset = -(barWidth / 2 + boxHeight + topPadding);
  const belowOffset = barWidth / 2 + bottomPadding;

  const x = anchorX + leftX + rightOverflowOffset + leftOverflowOffset;
  const y = yTop + aboveOffset < 0 ? yTop + belowOffset : yTop + aboveOffset;

  return {x, y};
};

/**
 * Generates timeline with disconnected status from start (inclusive) to end (inclusive) dates
 * @param startDate Start of timeline
 * @param endDate End of timeline
 * @returns EventsTimelineData array from start to end all with disconnected status
 */
export const generateDisconnectedEvents = (startDate: moment.Moment, endDate: moment.Moment): EventsTimelineData[] => {
  const timeline: EventsTimelineData[] = [];
  let date = moment.utc(startDate);
  while (date <= endDate) {
    timeline.push({
      eventDate: date,
      status: EventsTimelineStatus.DISCONNECTED
    });
    date = moment.utc(date).add(1, 'minute');
  }
  timeline.pop();
  timeline.pop();
  return timeline;
};

/**
 * Updates preFilledEventsData with real partial data from API endpoint
 * @param preFilledEventsTimeline Prefilled EventsTimelineData array
 * @param eventsTimeline EventsTimelineData array from API endpoint
 */
export const updatePreFilledEventsData = (
  preFilledEventsTimeline: EventsTimelineData[],
  eventsTimeline: EventsTimelineData[]
) => {
  let unknownPointCount = 0;
  let unknownEvents = [];
  preFilledEventsTimeline.forEach((element: EventsTimelineData) => {
    const actualData = eventsTimeline.find(
      (actualEvent) => actualEvent.eventDate.valueOf() === element.eventDate.valueOf()
    );

    if (actualData) {
      if (unknownPointCount <= MISSING_DATA_POINT_COUNT) {
        unknownEvents.forEach((unknownPointElement: EventsTimelineData) => {
          unknownPointElement.status = actualData.status;
          const unknownEventIndex = findIndex(preFilledEventsTimeline, {eventDate: unknownPointElement.eventDate});
          preFilledEventsTimeline[unknownEventIndex].status = unknownPointElement.status;
        });
        unknownPointCount = 0;
        unknownEvents = [];
      }
      element.status = actualData.status;
    } else {
      unknownPointCount++;
      unknownEvents.push(element);
    }
  });
};

interface Bucket {
  start: moment.Moment;
  end: moment.Moment;
}

const getBucketAsString = (bucket?: Bucket) => {
  return !bucket ? 'unused' : `"${bucket.start.toISOString()}"->"${bucket.end.toISOString()}""`;
};

/**
 * Returns whether the given event is in the given bucket
 * @param eventTstamp Timestamp of the event
 * @param bucket Bucket to check, can be undefined
 * @returns True if the bucket is valid and the event is in the bucket, False otherwise
 */
export const eventInBucket = (eventTstamp: moment.Moment, bucket?: Bucket) => {
  return Boolean(bucket) && eventTstamp >= bucket.start && eventTstamp < bucket.end;
};

export interface FlightPhaseBuckets {
  groundStart?: Bucket;
  ascending?: Bucket;
  cruising?: Bucket;
  descending?: Bucket;
  groundEnd?: Bucket;
}

export const getFlightPhaseBucketsAsString = (flightPhaseBuckets: FlightPhaseBuckets) => {
  return `Ground Start=${getBucketAsString(flightPhaseBuckets.groundStart)}\nAscending=${getBucketAsString(
    flightPhaseBuckets.ascending
  )}\nCruising=${getBucketAsString(flightPhaseBuckets.cruising)}\nDescending=${getBucketAsString(
    flightPhaseBuckets.descending
  )}\nGround End=${getBucketAsString(flightPhaseBuckets.groundEnd)}`;
};

/**
 * Returns the flight phase buckets given the provided flight phase data, handling the cases where particular flight
 * phase timestamps may be null/undefined
 * @param flightPhaseData Flight phase data
 * @param lastFightPhase Last known flight phase
 * @returns The flight phase buckets
 */
export const getFlightPhaseBuckets = (flightPhaseData: FlightPhaseData, lastFightPhase: string): FlightPhaseBuckets => {
  const hasFlightStart = !isNil(flightPhaseData.flightStart);
  const hasCruiseStart = !isNil(flightPhaseData.cruiseStart);
  const hasCruiseEnd = !isNil(flightPhaseData.cruiseEnd);
  const hasFlightEnd = !isNil(flightPhaseData.flightEnd);

  const isAscending = lastFightPhase === FlightPhase.ASCENDING;

  // Note: Uncomment the below lines for troubleshooting flight phase buckets
  // console.log(
  //   `getFlightPhaseBuckets: hasFlightStart=${hasFlightStart}, hasCruiseStart=${hasCruiseStart}, hasCruiseEnd=${hasCruiseEnd}, hasFlightEnd=${hasFlightEnd}`
  // );

  // If none of the flight phase timestamps are valid, consider this to be a non-flight
  // (i.e. all data points are on the ground)
  if (!hasFlightStart && !hasCruiseStart && !hasCruiseEnd && !hasFlightEnd) {
    const groundStart = {start: flightPhaseData.connectionStart, end: flightPhaseData.connectionEnd};

    // Note: Uncomment the below lines for troubleshooting flight phase buckets
    // console.log(
    //   `getFlightPhaseBuckets: All flight phase timestamps null, Ground Start=${getBucketAsString(groundStart)}`
    // );

    return {groundStart};
  }

  // Note: If we've reached this point, at least one of the flight phase timestamps is valid
  // (i.e. the aircraft did actually fly)
  let groundStart = undefined;
  let ascending = undefined;
  let cruising = undefined;
  let descending = undefined;
  let groundEnd = undefined;

  // Ground Start
  if (hasFlightStart || hasCruiseStart) {
    groundStart = {
      start: flightPhaseData.connectionStart,
      end: hasFlightStart ? flightPhaseData.flightStart : flightPhaseData.cruiseStart
    };
  }

  // Ascending
  if ((hasCruiseStart || isAscending) && hasFlightStart) {
    ascending = {
      start: flightPhaseData.flightStart,
      end: hasCruiseStart ? flightPhaseData.cruiseStart : flightPhaseData.connectionEnd
    };
  }

  // Cruising
  if (!isAscending) {
    cruising = {
      start: hasCruiseStart
        ? flightPhaseData.cruiseStart
        : hasFlightStart
        ? flightPhaseData.flightStart
        : flightPhaseData.connectionStart,
      end: hasCruiseEnd
        ? flightPhaseData.cruiseEnd
        : hasFlightEnd
        ? flightPhaseData.flightEnd
        : flightPhaseData.connectionEnd
    };
  }

  // Descending
  if (hasCruiseEnd) {
    descending = {
      start: flightPhaseData.cruiseEnd,
      end: hasFlightEnd ? flightPhaseData.flightEnd : flightPhaseData.connectionEnd
    };
  }

  // Ground End
  if (hasFlightEnd) {
    groundEnd = {
      start: flightPhaseData.flightEnd,
      end: flightPhaseData.connectionEnd
    };
  }

  // Note: Uncomment the below lines for troubleshooting flight phase buckets
  // console.log(
  //   `getFlightPhaseBuckets:\n${getFlightPhaseBucketsAsString({
  //     groundStart,
  //     ascending,
  //     cruising,
  //     descending,
  //     groundEnd
  //   })}`
  // );

  return {
    groundStart,
    ascending,
    cruising,
    descending,
    groundEnd
  };
};

/**
 * Gets the flight phase bucket for a given event
 * @param eventTstamp Event point to determine the flight phase bucket for
 * @param flightPhaseBuckets Flight phase buckets
 * @param categories Flight phase categories to bucketize the points into
 * @returns The appropriate flight phase bucket's index for the given event, or undefined if no buckets match
 */
export const getEventFlightPhase = (
  eventTstamp: moment.Moment,
  flightPhaseBuckets: FlightPhaseBuckets,
  categories: string[]
): number => {
  const getFlightPhaseIdx = (target: string) => categories.findIndex((e) => e === target);

  // Ground phase
  if (
    eventInBucket(eventTstamp, flightPhaseBuckets.groundStart) ||
    eventInBucket(eventTstamp, flightPhaseBuckets.groundEnd)
  ) {
    return getFlightPhaseIdx(FlightPhaseCategory.groundLabel);
  }

  // Ascending/Descending phases
  if (
    eventInBucket(eventTstamp, flightPhaseBuckets.ascending) ||
    eventInBucket(eventTstamp, flightPhaseBuckets.descending)
  ) {
    return getFlightPhaseIdx(FlightPhaseCategory.ascDescLabel);
  }

  // Cruising phase
  if (eventInBucket(eventTstamp, flightPhaseBuckets.cruising)) {
    return getFlightPhaseIdx(FlightPhaseCategory.cruisingLabel);
  }

  // Doesn't match any buckets
  return undefined;
};

/**
 * Merges all consecutive similar series data points as a single data point
 * @param series SeriesData object which has details of each one minute point
 * @returns SeriesData object where all consecutive similar points are merged as one
 */
export const mergePointsInSeries = (series: SeriesData): SeriesData => {
  const mergedData: SeriesDataPoint[] = [];
  let currentPoint: SeriesDataPoint = undefined;
  series.data.forEach((newPoint) => {
    if (currentPoint) {
      if (currentPoint.x === newPoint.x && currentPoint.high === newPoint.low) {
        currentPoint = {...currentPoint, high: newPoint.high};
      } else {
        mergedData.push(currentPoint);
        currentPoint = newPoint;
      }
    } else {
      currentPoint = newPoint;
    }
  });

  if (currentPoint) {
    mergedData.push(currentPoint);
  }

  return {...series, data: mergedData};
};
