/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Data Usage Trend Card
 */

import {useStore} from '../../store/Store';
import {useIntl} from 'react-intl';
import {isNil} from 'lodash';
import {IMonthlyUsage} from '../../store/queries/summaryMetrics/dataUsageTrend';
import {CardContainer, DataUsageTrendContainer, NoDataAvailable} from './SummaryMetricsStyles';
import {formatConfig} from '../common/elements/chart/chartUtils';
import StackedColumnChart from '../common/elements/chart/StackedColumnChart';
import {ChartType} from '../common/elements/chart/types';
import {ChartWrapper} from '@viasat/insights-components';
import {
  LEGEND_TEXT,
  SUMMERY_METRICS_TOTAL_DATA_USAGE_CHART_PALETTE,
  DATA_USAGE_TREND_CHART_HATCH_COLOR,
  DATA_USAGE_TREND_CHART_ROLLING_AVERAGE_COLOR,
  DATA_USAGE_TREND_CHART_BORDER,
  DATA_USAGE_TREND_CHART_AIRCRAFT_COUNT_COLOR
} from '../common/theme/Colors';
import {convertToGb, formatMonth, getMbPerHr} from './summaryMetricsUtil';
import moment from 'moment';
import {DATA_USAGE_TREND_TOGGLE, SummaryMetricsAction} from '../../store/reducers/SummaryMetricsReducer';
import {IMonthlyAircraftCount} from '../../store/queries/summaryMetrics/monthlyAircraftCountQuery';
import {ChartCard} from './SummaryMetricsPage';
import StyledButton from '../common/StyledButton';
import styled from '@emotion/styled';

export interface DataUsageTrendCardProps {
  dataUsageTrend: IMonthlyUsage[];
  rollingUsage: IMonthlyUsage[];
  monthOverAircraftCount: IMonthlyAircraftCount[];
  isLoading: boolean;
  isRollingUsageLoading: boolean;
  draggableProps?: any;
}

const ToolTipLabelText = styled.span`
  color: #465967;
  margin-right: 10px;
`;
const ToolTipLabelValue = styled.span`
  color: #202e39;
`;
const ToolTipRow = styled.div`
  line-height: 16px;
  justify-content: space-between;
  display: flex;
`;
const ToolTipWrapper = styled.div`
  fontfamily: 'Source Sans Pro';
  fontsize: 14px;
  fontstyle: normal;
  fontweight: 400;
  color: #202e39;
  lineheight: 20px;
  strokewidth: 0px;
  whitespace: nowrap;
`;

const ToolTipLabel: React.FC<{usage: number; connectedHours?: number; showGbPerHr: boolean; aircraftCount: number}> = ({
  usage,
  connectedHours,
  showGbPerHr,
  aircraftCount
}): JSX.Element => {
  return (
    <ToolTipWrapper>
      <ToolTipRow>
        <ToolTipLabelText>Aircraft</ToolTipLabelText>
        <ToolTipLabelValue>{Number(aircraftCount)}</ToolTipLabelValue>
      </ToolTipRow>
      {showGbPerHr ? (
        <>
          <ToolTipRow>
            <ToolTipLabelText>MB Per Hour</ToolTipLabelText>
            <ToolTipLabelValue>{Number(usage).toFixed(0).toLocaleString()}</ToolTipLabelValue>
          </ToolTipRow>
          <ToolTipRow>
            <ToolTipLabelText>Connected Hours</ToolTipLabelText>
            <ToolTipLabelValue>{connectedHours.toLocaleString()}</ToolTipLabelValue>
          </ToolTipRow>
        </>
      ) : (
        <ToolTipRow>
          <ToolTipLabelText>Total GB</ToolTipLabelText>
          <ToolTipLabelValue>{Number(usage.toFixed(0)).toLocaleString()}</ToolTipLabelValue>
        </ToolTipRow>
      )}
    </ToolTipWrapper>
  );
};
const DataUsageTrendCard: React.FC<DataUsageTrendCardProps> = ({
  dataUsageTrend,
  rollingUsage,
  monthOverAircraftCount,
  isLoading,
  isRollingUsageLoading,
  draggableProps
}) => {
  const intl = useIntl();
  const {store, dispatch} = useStore();
  const showGbPerHour = store.summaryMetrics.showGbPerHour;

  const formatChartConfig = (
    isLoading: boolean,
    dataUsageTrend: IMonthlyUsage[],
    rollingUsage: IMonthlyUsage[],
    isRollingUsageLoading: boolean
  ) => {
    // generating dataUsageTrend based on TotalGB or GB/hr toggle
    const dataUsageTrendInGb = dataUsageTrend.map((monthlyUsage) => {
      return !showGbPerHour
        ? {
            inFlightUsage: convertToGb(monthlyUsage.inFlightUsage),
            onGroundUsage: convertToGb(monthlyUsage.onGroundUsage),
            connectedTime: monthlyUsage.connectedTime / 60,
            month: monthlyUsage.month,
            year: monthlyUsage.year
          }
        : {
            inFlightUsage: convertToGb(getMbPerHr(monthlyUsage.inFlightUsage, monthlyUsage.connectedTime)),
            onGroundUsage: convertToGb(getMbPerHr(monthlyUsage.onGroundUsage, monthlyUsage.connectedTime)),
            connectedTime: monthlyUsage.connectedTime / 60,
            month: monthlyUsage.month,
            year: monthlyUsage.year
          };
    });

    //generating placeholder usage for current Month Hacth
    const highestUsageInGB = Math.max(
      ...dataUsageTrendInGb?.map((monthlyUsage) => monthlyUsage.inFlightUsage + monthlyUsage.onGroundUsage)
    );
    const currentMonthSeries = new Array(11);
    currentMonthSeries.fill(0).push(highestUsageInGB);

    // generating Rolling average for each month
    const rollingAverage = [];
    if (!isRollingUsageLoading) {
      const rollingUsageCumulative = rollingUsage?.map(
        (monthlyUsage) => monthlyUsage.inFlightUsage + monthlyUsage.onGroundUsage
      );
      //Calculating rolling average for last 12 months using last 18 months usage
      for (let index = 6; index < 18; index++) {
        if (index === rollingUsageCumulative?.length - 1) {
          const currentMonthAverage = rollingUsageCumulative && rollingUsageCumulative[index];
          rollingAverage.push(
            !showGbPerHour
              ? convertToGb(currentMonthAverage)
              : convertToGb(getMbPerHr(currentMonthAverage, rollingUsage[index]?.connectedTime))
          );
        } else {
          const lastSixMonthsUsage = rollingUsageCumulative?.slice(index - 5, index + 1);
          const currentMonthAverage =
            lastSixMonthsUsage?.reduce((a, b) => {
              return a + b;
            }) / 6;
          rollingAverage.push(
            !showGbPerHour
              ? convertToGb(currentMonthAverage)
              : convertToGb(getMbPerHr(currentMonthAverage, rollingUsage[index]?.connectedTime))
          );
        }
      }
    }

    // aircraft count data
    const aircraftCountData = [...monthOverAircraftCount].reverse().map((month) => month.count);

    //general stacked column chart config
    const id = 'summaryMetrics-DataUsageTrend-chart';
    const highchartsIdBase = 'DataUsageTrend';
    const chartType = ChartType.STACKED_COLUMN;
    const stackingOption = 'normal';
    const palette = [];
    const marginLeft = 75;
    const marginRight = 50;
    const paddings = {
      pointPadding: 0.1,
      groupPadding: 0.2
    };
    const noDataOptions = {
      enabled: true,
      showEmptyXAxis: false,
      showEmptyYAxis: false
    };
    const states = {
      hover: {
        brightness: 0
      },
      inactive: {
        opacity: 1
      }
    };
    //legend
    const legend = true;
    const legendItemStyle = {
      fontSize: '12px',
      fontWeight: '600',
      fontFamily: 'Source Sans Pro',
      color: LEGEND_TEXT,
      verticalAlign: 'bottom'
    };
    const legendTooltips = [];
    const customLegend = {
      Aircraft: `<div style="font-family: Source Sans Pro; font-size: 12px; font-style: normal; font-weight: 600; color:grey; display:flex; align-items: center;"><div style="width:10px;height:10px;border:2px solid ${DATA_USAGE_TREND_CHART_AIRCRAFT_COUNT_COLOR};border-radius:50%;margin-right:12px"></div>Aircraft</div>`,
      '6 Month Rolling Average': `<div style="font-family: Source Sans Pro; font-size: 12px; font-style: normal; font-weight: 600; color:grey; display:flex; align-items: center;"><div style="width:10px;height:10px;border:2px dashed #62C31B;border-radius:50%;margin-right:12px"></div>6 Month Rolling Average</div>`,
      'Month to Date (MTD)': `<div style="font-family: Source Sans Pro; font-size: 12px; font-style: normal; font-weight: 600; color:grey; display:flex">Month to Date (MTD)</div>`
    };
    const customLegendClick = (event) => {
      event.preventDefault();
    };
    // xAxis
    const customXAxis = {
      categories: dataUsageTrend?.map((monthlyUsage) => formatMonth(monthlyUsage.month, monthlyUsage.year))
    };
    // yAxis
    const yAxisFormatter = (label: any) => {
      return showGbPerHour
        ? Number(label.value).toLocaleString() + ' MB'
        : Number(label.value).toLocaleString() + ' GB';
    };
    const yAxis = [
      {
        label: showGbPerHour ? 'MB per hour' : '',
        opposite: false,
        yAxisFormatter: yAxisFormatter
      },
      {
        label: `Aircraft`,
        opposite: true,
        min: 0
      }
    ];
    // Series Data Labels
    const formattedStackLabel = (dataLabel: number, chartWidth: number) => {
      const fomattedDataLabel = Number(dataLabel.toFixed(0)).toLocaleString();
      if (chartWidth < 700 && fomattedDataLabel.length > 3) {
        return `<div style="font-family: Source Sans Pro; font-size: 14px; font-style: normal; font-weight: 400; color:#202E39; line-height:20px;stroke-width:0px;transform: rotate(-60deg)">${fomattedDataLabel}</div>`;
      } else {
        return `<div style="font-family: Source Sans Pro; font-size: 14px; font-style: normal; font-weight: 400; color:#202E39; line-height:20px;stroke-width:0px;">${fomattedDataLabel}</div>`;
      }
    };
    const customStackLabels = {
      enabled: true,
      allowOverlap: true,
      useHTML: true,
      y: -1,
      formatter: function () {
        if (this.x === dataUsageTrend.length - 1) {
          return '';
        } else {
          return formattedStackLabel(this.total, this.axis.chart.chartWidth);
        }
      }
    };
    //Hatch Pattern Defintion
    const patternDefinition = {
      patterns: [
        {
          id: 'usageTrendPattern',
          path: {
            d: 'M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2',
            stroke: DATA_USAGE_TREND_CHART_HATCH_COLOR,
            strokeWidth: 1
          },
          width: 10,
          height: 10
        }
      ]
    };
    // Custom tooltip
    const tooltip = (i18n: any) => (input: any) => {
      const {x} = input;
      const monthMMM = (month) => moment().month(month).format('MMM');
      const currentMonthUsage = dataUsageTrendInGb.filter(
        (monthlyUsage) => x.slice(0, 3) === monthMMM(monthlyUsage.month)
      )[0];
      const currentAircraftCount = monthOverAircraftCount?.filter(
        (aircraftCount) => x.slice(0, 3) === monthMMM(aircraftCount.month)
      )[0];
      const connectedhours = showGbPerHour ? Math.ceil(currentMonthUsage.connectedTime) : 0;
      const usage = currentMonthUsage.inFlightUsage + currentMonthUsage.onGroundUsage;
      const aircraftCount = currentAircraftCount?.count;
      return {
        label: {
          value: (
            <ToolTipLabel
              usage={Number(usage.toFixed(0))}
              connectedHours={connectedhours}
              showGbPerHr={showGbPerHour}
              aircraftCount={aircraftCount}
            />
          )
        },
        points: [],
        color: false
      };
    };
    //current month usage config
    const currentMonthUsageConfig = {
      name: 'Month to Date (MTD)',
      color: 'url(#usageTrendPattern)',
      data: currentMonthSeries,
      zIndex: 0,
      showInLegend: true,
      enableMouseTracking: false,
      marker: {
        enabled: false,
        symbol: 'circle'
      }
    };
    // six month rolling usage config
    const sixMonthRollingUsageConfig = {
      name: '6 Month Rolling Average',
      type: 'line',
      data: rollingAverage,
      dashStyle: 'ShortDash',
      color: DATA_USAGE_TREND_CHART_ROLLING_AVERAGE_COLOR,
      zIndex: 0,
      marker: {
        enabled: false,
        symbol: 'circle'
      }
    };
    //onGround usage config
    const ongroundUsageConfig = {
      name: 'On-ground',
      color: SUMMERY_METRICS_TOTAL_DATA_USAGE_CHART_PALETTE[1],
      data: dataUsageTrendInGb.map((monthlyUsage) => monthlyUsage.onGroundUsage),
      marker: {
        symbol: 'circle'
      },
      yAxis: 0
    };
    //inFlight usage config
    const inflightUsageConfig = {
      name: 'In-flight',
      color: SUMMERY_METRICS_TOTAL_DATA_USAGE_CHART_PALETTE[0],
      data: dataUsageTrendInGb.map((monthlyUsage) => monthlyUsage.inFlightUsage),
      marker: {
        symbol: 'circle'
      },
      yAxis: 0,
      dataLabels: {
        enabled: true,
        useHTML: true,
        inside: false,
        allowOverlap: true,
        y: 5,
        zIndex: 6,
        formatter: function () {
          if (this.point.x === dataUsageTrend.length - 1) {
            return formattedStackLabel(
              dataUsageTrendInGb[dataUsageTrendInGb.length - 1].inFlightUsage +
                dataUsageTrendInGb[dataUsageTrendInGb.length - 1].onGroundUsage,
              this.series.yAxis.chart.chartWidth
            );
          } else return '';
        }
      }
    };
    // aircraft config
    const aircraftCountConfig = {
      name: 'Aircraft',
      type: 'line',
      step: 'center',
      data: aircraftCountData,
      color: DATA_USAGE_TREND_CHART_AIRCRAFT_COUNT_COLOR,
      zIndex: 0,
      marker: {
        enabled: false,
        symbol: 'circle'
      },
      yAxis: 1
    };

    // series based on TotalGB or GB/hr toggle
    const series = [
      currentMonthUsageConfig,
      aircraftCountConfig,
      sixMonthRollingUsageConfig,
      inflightUsageConfig,
      ongroundUsageConfig
    ];

    const chartConfig = {
      id,
      highchartsIdBase,
      palette,
      chartType,
      stackingOption,
      marginLeft,
      marginRight,
      noDataOptions,
      customXAxis,
      series,
      paddings,
      legend,
      legendItemStyle,
      tooltip,
      yAxis,
      yAxisFormatter,
      patternDefinition,
      customLegend,
      customStackLabels,
      legendTooltips,
      states,
      customLegendClick
    };
    const formattedConfig = formatConfig(intl, chartConfig);

    return (
      <ChartWrapper
        getFullElementId={(name, type) => `summaryMetrics--dataUsageTrend--stackedColumnChart__${name}-${type}`}
        isLoading={isLoading}
        isError={false}
        chartHeight={310}
      >
        {Object.keys(formattedConfig).length === 0 ? <></> : <StackedColumnChart {...formattedConfig} />}
      </ChartWrapper>
    );
  };

  const setTotalGb = () => {
    dispatch({
      type: SummaryMetricsAction.SET_DATA_USAGE_TREND_TOGGLE,
      payload: DATA_USAGE_TREND_TOGGLE.TOTAL_GB
    });
  };

  const setGbPerHr = () => {
    dispatch({
      type: SummaryMetricsAction.SET_DATA_USAGE_TREND_TOGGLE,
      payload: DATA_USAGE_TREND_TOGGLE.GB_PER_HOUR
    });
  };

  return (
    <ChartCard
      id="data-usage-trend"
      isLoading={isLoading}
      title=" 12 Month Usage Trend"
      helpText={intl.formatMessage({id: 'summary_metrics.data_usage_trend_chart.title.tooltip'})}
      borderColor={DATA_USAGE_TREND_CHART_BORDER}
      headerActions={
        <>
          <StyledButton
            id="data-usage-trend-gbperhr-button"
            selected={showGbPerHour}
            onClick={setGbPerHr}
            className={showGbPerHour ? 'selected' : 'deSelected'}
          >
            MB/Hr
          </StyledButton>
          <StyledButton
            id="data-usage-trend-totalgb-button"
            selected={!showGbPerHour}
            onClick={setTotalGb}
            className={!showGbPerHour ? 'selected' : 'deSelected'}
          >
            Total GB
          </StyledButton>
        </>
      }
      draggableProps={draggableProps}
    >
      {!isNil(dataUsageTrend) && !isLoading ? (
        <CardContainer>
          <DataUsageTrendContainer>
            {formatChartConfig(isLoading, dataUsageTrend, rollingUsage, isRollingUsageLoading)}
          </DataUsageTrendContainer>
        </CardContainer>
      ) : (
        <NoDataAvailable>No data available.</NoDataAvailable>
      )}
    </ChartCard>
  );
};

export default DataUsageTrendCard;
