/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List component
 */

import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DatePickerState, HorizontalTabs, ListGridColumn} from '@viasat/insights-components';
import {useStore} from '../../../store/Store';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import {TAIL_LIST_STORE_CONTEXT, TailListAction} from '../../../store/reducers/TailListReducer';
import {
  useSortingParams,
  useQueryInputsDateRange,
  useFilteringParams,
  useLimitParam,
  useQueryInputsDateRangeInternalUsers
} from '../../../store/queries/queryUtils';
import {EID_FLIGHTLIST} from '../../../utils/constants';
import {
  TailListColumnId,
  buildTailListColumns,
  getQueryFieldMapping,
  getTailListFilters,
  sortTailList,
  QUERY_FIELD_MAPPING,
  SOFTWARE_VERSIONS_MAPPING,
  buildTailListData
} from './util/tailListUtil';
import {getDatePickerPresets} from '../../dashboard/summaryMetricsUtil';
import ListViewComponent from '../ListViewComponent';
import {TabDefinitions} from '../tabDefinitions';
import tabDefinitions from '../tabDefinitions';
import {getFilterOptions} from '../../../utils/filterUtils';
import {FilterAction} from '../../../store/reducers/FiltersReducer';
import {FLIGHT_LIST_STORE_CONTEXT} from '../../../store/reducers/FlightListReducer';
import {DatePickerAction} from '../../../store/reducers/DatePickerReducer';
import {handleExportClick} from '../exportListCSV';
import OpenCaseListPopup from '../aircraftStatus/OpenCaseListPopup';
import RequestUpdateStatusPopup from '../aircraftStatus/requestSupport/RequestUpdateStatusPopup';
import SoftwareRevisionsPopup from '../aircraftStatus/SoftwareRevisionsPopup';
import {IHandleSortProps} from '../listUtils';
import useFetchTailListBase from './useFetchTailListBase';
import endUsersQuery, {EndUser} from '../../../store/queries/common/endUsersQuery';
import servicePlansQuery, {servicePlans} from '../../../store/queries/common/servicePlansQuery';
import tailInfoQuery, {TailInfo} from '../../../store/queries/flightList/tailInfoQuery';
import useFetch from '../../../utils/useFetch';

export interface TailListPageProps {
  initialTabState: TabDefinitions;
  isLoading: boolean;
}

export const MAX_TAILS_DISPLAYED = 3000;
export const idPrefix = 'tailList';

const maxRowNotificationMessage = (
  <span>
    We've displayed the <strong>first {MAX_TAILS_DISPLAYED} tails</strong> on the list. You can refine your search with
    additional <strong>filters</strong> or download the full results as a <strong>CSV</strong> file.
  </span>
);

const TailListPage: React.FC<TailListPageProps> = () => {
  const [validTabs] = useState(tabDefinitions);
  const {store, dispatch} = useStore();
  const navigate = useNavigate();
  const [selectedAircraft, setSelectedAircraft] = useState<any>(undefined);
  const [selectedCaseCount, setSelectedCaseCount] = useState<number>(null);
  const [openAircraftCaseListModal, setOpenAircraftCaseListModal] = useState<boolean>(false);
  const [requestUpdateStatus, setRequestUpdateStatus] = useState<boolean>(false);

  const [tailListDataIndex, setTailListDataIndex] = useState<number>(-1);
  const [openAircraftSwRevisionsModal, setOpenAircraftSwRevisionsModal] = useState<boolean>(false);

  const [scrollTop, setScrollTop] = useState<number>(-1);
  const isInternal = store.init.isInternal;
  const datePickerState = store.tailList.dateRange;
  const {
    sort,
    filters: {filters = []},
    hideColumns: {hiddenColumns, sharelinkHiddenColumns},
    showTailListNotification,
    showCompactView
  } = store.tailList;

  const {viewAsCustomer} = store.init;

  const groupCode = store.customer.current.code ? store.customer.current.code : '';

  const [downloadProgressPercentage, setDownloadProgressPercentage] = useState(0);
  const clamp = (n: number, min: number, max: number) => Math.max(Math.min(n, max), min);
  const [timerIndex, setTimerIndex] = useState(-1);
  const clearIndex = () => {
    if (timerIndex > -1) {
      clearTimeout(timerIndex);
      setTimerIndex(-1);
    }
  };

  // if sharelink hidden columns are populated in session storage, then use, otherwise use hidden columns from local storage
  const currentHiddenColumns = sharelinkHiddenColumns ? sharelinkHiddenColumns : hiddenColumns;

  const queryParams = useQueryInputsDateRange(groupCode, datePickerState.startDate, datePickerState.endDate);
  const queryParamsInternal = useQueryInputsDateRangeInternalUsers(
    groupCode,
    datePickerState.startDate,
    datePickerState.endDate,
    isInternal
  );
  // Tail Info
  const {data: tailInfoData, isLoading: isTailInfoLoading} = useFetch<TailInfo[]>(tailInfoQuery, queryParams);

  // End Users
  const {data: endUsersData, isLoading: isEndUsersLoading} = useFetch<EndUser[]>(endUsersQuery, queryParams);
  const {data: servicePlansData, isLoading: isServicePlansLoading} = useFetch<servicePlans[]>(
    servicePlansQuery,
    queryParams
  );
  const isEndUser = endUsersData && endUsersData.length === 0;

  const arePrereqsLoading = isEndUsersLoading || isTailInfoLoading || isServicePlansLoading;

  const tailListFilters = useMemo(
    () => (arePrereqsLoading ? undefined : getTailListFilters(isEndUser)),
    [arePrereqsLoading, isEndUser]
  );
  const swFilters = filters.filter((filterRow) => filterRow.domainOptionsKey.includes('Version'));
  const nonSWFilters = filters.filter((filterRow) => !filterRow.domainOptionsKey.includes('Version'));
  const queryParamsWithFiltering = useFilteringParams(queryParams, tailListFilters, nonSWFilters);

  const queryParamsWithLimit = useLimitParam(queryParamsWithFiltering, MAX_TAILS_DISPLAYED);
  const queryParamsWithSWFiltering = useFilteringParams(queryParams, tailListFilters, swFilters);

  const {
    tailListData: {data: tailListData},
    trafficCompositionData: {data: trafficCompositionData},
    equipmentHistoryData: {data: equipmentHistoryData},
    tailPlanHistoryData: {data: tailPlanHistoryData},
    tailCustomerHistoryData: {data: tailCustomerHistoryData},
    tailSWVersionsData: {data: tailSWVersionsData},
    totalOpenCases: {data: totalOpenCases},
    connectivityTimeMetrics: {data: connectivityTimeMetrics},
    connectivityUsageMetrics: {data: connectivityUsageMetrics},
    performanceMetricsData: {data: performanceMetricsData},
    isLoading: baseLoading
  } = useFetchTailListBase(
    queryParams,
    queryParamsWithLimit,
    queryParamsWithSWFiltering,
    queryParamsInternal,
    isInternal
  );

  const queryParamsWithFilteringExportCSV = useFilteringParams(queryParams, tailListFilters, filters);
  const queryParamsWithSorting = useSortingParams(queryParamsWithFilteringExportCSV, sort.queryField, sort.order);

  const isDataLoading = baseLoading;

  const handleFilterChange = () => {
    setShowTailListNotification(true); // allows notification to be shown again on filter change
  };

  const excludedColumnIds = ['equipmentHistoryCount', 'cases', 'trafficComposition', 'softwareRevisions'];
  const handleExport = async (downloadSuccess, downloadFailure) => {
    const tailListColumns = buildTailListColumns(
      isInternal,
      isEndUser,
      viewAsCustomer,
      false,
      handleTotalFlightsClick,
      handleGroundSessionsClick,
      handleOpenCasesClick,
      handleSoftwareRevisionsClick
    );
    handleExportClick(
      queryParamsWithSorting,
      tailListColumns,
      currentHiddenColumns,
      downloadSuccess,
      setDownloadProgressPercentage,
      downloadFailure,
      TailListColumnId,
      excludedColumnIds,
      'tails',
      getQueryFieldMapping,
      'tailList/tailsExport'
    );
  };

  const onDatePickerChange = (newState: DatePickerState) => {
    dispatch({
      type: TailListAction.SET_DATE_RANGE,
      payload: {...newState, viewContext: TAIL_LIST_STORE_CONTEXT}
    });
  };

  const handleTotalFlightsClick = (rowData: any) => {
    dispatch({
      type: getActionTypeWithContext(FilterAction.SET_SELECTED_FILTER, FLIGHT_LIST_STORE_CONTEXT),
      payload: {
        filters: [
          {filterId: 1, domainOptionsKey: 'tailId', rangeOptionsKeys: [rowData.tailId]},
          {filterId: 2, domainOptionsKey: 'flightDetected', rangeOptionsKeys: ['TRUE']}
        ]
      }
    });
    dispatch({type: DatePickerAction.SET_DATE_RANGE, payload: datePickerState});
    navigate(`/lists/flight`);
  };

  const handleGroundSessionsClick = (rowData: any) => {
    dispatch({
      type: getActionTypeWithContext(FilterAction.SET_SELECTED_FILTER, FLIGHT_LIST_STORE_CONTEXT),
      payload: {
        filters: [
          {filterId: 1, domainOptionsKey: 'tailId', rangeOptionsKeys: [rowData.tailId]},
          {filterId: 2, domainOptionsKey: 'flightDetected', rangeOptionsKeys: ['FALSE']}
        ]
      }
    });
    dispatch({type: DatePickerAction.SET_DATE_RANGE, payload: datePickerState});
    navigate(`/lists/flight`);
  };
  /**
   * event handler on click of open cases count
   * @param rowData Row information
   */
  const handleOpenCasesClick = (rowData: any) => {
    setSelectedAircraft(rowData);
    setSelectedCaseCount(rowData.openCases);
    setOpenAircraftCaseListModal(true);
  };

  /**
   * Updates the current view type (compact/expanded)
   * @param showCompactView boolean value to change current view (compact --> expanded or expanded --> compact)
   */
  const onChangeColumnView = (showCompactView: boolean) => {
    dispatch({
      type: TailListAction.CHANGE_TAIL_LIST_COLUMN_VIEW,
      payload: showCompactView
    });
  };

  /**
   * On click Event handler of the software revisions.
   * @param rowData Row information
   */
  const handleSoftwareRevisionsClick = (rowData: any) => {
    setSelectedAircraft(rowData);
    setOpenAircraftSwRevisionsModal(true);
  };

  const tailListColumns = buildTailListColumns(
    isInternal,
    isEndUser,
    viewAsCustomer,
    showCompactView,
    handleTotalFlightsClick,
    handleGroundSessionsClick,
    handleOpenCasesClick,
    handleSoftwareRevisionsClick
  );
  // Set Filters
  useEffect(() => {
    if (tailInfoData && endUsersData && tailListFilters) {
      const {domainOptions, rangeOptions} = getFilterOptions(
        {listData: tailInfoData, endUsersData, servicePlansData, context: TAIL_LIST_STORE_CONTEXT},
        tailListFilters
      );
      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, TAIL_LIST_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [tailInfoData, endUsersData, dispatch, tailListFilters, servicePlansData]);

  const tailListDataProcessed = useMemo(() => {
    if (
      (isInternal &&
        (!tailListData ||
          !trafficCompositionData ||
          !equipmentHistoryData ||
          !tailPlanHistoryData ||
          !tailCustomerHistoryData ||
          !tailSWVersionsData ||
          !totalOpenCases ||
          !connectivityTimeMetrics ||
          !connectivityUsageMetrics ||
          !performanceMetricsData)) ||
      (!isInternal &&
        (!tailListData ||
          !trafficCompositionData ||
          !equipmentHistoryData ||
          !tailPlanHistoryData ||
          !tailSWVersionsData ||
          !totalOpenCases ||
          !connectivityTimeMetrics ||
          !connectivityUsageMetrics))
    )
      return [];
    return buildTailListData(
      tailListData,
      trafficCompositionData,
      equipmentHistoryData,
      tailPlanHistoryData,
      tailCustomerHistoryData,
      tailSWVersionsData,
      totalOpenCases,
      connectivityTimeMetrics,
      connectivityUsageMetrics,
      performanceMetricsData,
      {column: sort.column, order: sort.order},
      swFilters
    );
  }, [
    tailListData,
    trafficCompositionData,
    equipmentHistoryData,
    tailPlanHistoryData,
    tailCustomerHistoryData,
    tailSWVersionsData,
    totalOpenCases,
    connectivityTimeMetrics,
    connectivityUsageMetrics,
    performanceMetricsData,
    sort.column,
    sort.order,
    swFilters,
    isInternal
  ]);

  useEffect(() => {
    clearIndex();
    if (downloadProgressPercentage > 0 && downloadProgressPercentage < 100) {
      // using line equation : ms = 2.278 * row + 2975
      const update = (7.778 * tailListDataProcessed?.length + 7975) / 50;
      const idx = window.setTimeout(() => {
        let amount = 0;
        const n = downloadProgressPercentage;
        if (n >= 0 && n < 20) {
          amount = 10; // 2  steps
        } else if (n >= 20 && n < 50) {
          amount = 4; // 9 steps
        } else if (n >= 50 && n < 80) {
          amount = 2; // 15 steps
        } else if (n >= 80 && n < 99) {
          amount = 0.5; // 40 steps
        }
        setDownloadProgressPercentage(clamp(n + amount, 0, 99.4));
      }, update);
      setTimerIndex(idx);
    }
    // eslint-disable-next-line
  }, [downloadProgressPercentage]);

  const setShowTailListNotification = (show: boolean) => {
    dispatch({
      type: TailListAction.SET_SHOW_TAIL_LIST_NOTIFICATION,
      payload: show
    });
  };

  const showNotification = showTailListNotification && MAX_TAILS_DISPLAYED < tailListDataProcessed?.length;

  const TabsComponent = (
    <HorizontalTabs
      getFullElementId={(name, type) => `${EID_FLIGHTLIST}--listTabs__${name}-${type}`}
      tabs={(validTabs || []).filter(({disabled}) => !disabled)}
      titleCount={isDataLoading ? undefined : tailListDataProcessed?.length}
    />
  );

  // Below part is for Search in Tail List
  // It does scroll to top on the row item event as well along with the row highlight
  useEffect(() => {
    if (tailListDataIndex > -1) {
      tailListDataProcessed.forEach((row) => {
        row.isRowHighlighted = false;
      });

      tailListDataProcessed[tailListDataIndex].isRowHighlighted = true;
      setScrollTop(tailListDataIndex);
    }
    //eslint-disable-next-line
  }, [tailListDataIndex]);

  /**
   * Handles the Sorting actions on the list view
   * @param param0 IHandleSortProps with column to sort and the respective sort order - asc/desc
   */
  const handleSortChange = ({column, order}: IHandleSortProps) => {
    sortTailList(column, order, tailListDataProcessed);
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, TAIL_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: excludedColumnIds.includes(column) ? 'tailId' : QUERY_FIELD_MAPPING[column],
        validColumns: tailListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: tailListColumns.map((col: ListGridColumn) => getQueryFieldMapping(TailListColumnId[col.key]))
      }
    });
  };

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(TailListColumnId[columnId]);

  return (
    <>
      {requestUpdateStatus && (
        <RequestUpdateStatusPopup openModal={requestUpdateStatus} setOpenModal={setRequestUpdateStatus} />
      )}
      {openAircraftCaseListModal && (
        <OpenCaseListPopup
          selectedAircraft={selectedAircraft}
          caseCount={selectedCaseCount}
          openModal={openAircraftCaseListModal}
          setOpenModal={setOpenAircraftCaseListModal}
          setRequestUpdateStatus={setRequestUpdateStatus}
        />
      )}
      {openAircraftSwRevisionsModal && (
        <SoftwareRevisionsPopup
          selectedAircraft={selectedAircraft}
          openModal={openAircraftSwRevisionsModal}
          setOpenModal={setOpenAircraftSwRevisionsModal}
          swVersionFields={SOFTWARE_VERSIONS_MAPPING}
        ></SoftwareRevisionsPopup>
      )}
      <ListViewComponent
        idPrefix={idPrefix}
        listContext={idPrefix}
        listSort={sort}
        tabs={TabsComponent}
        showGroupHeader={true}
        arePrereqsLoading={arePrereqsLoading}
        isLoading={isDataLoading}
        listTotal={tailListDataProcessed?.length}
        listColumns={tailListColumns}
        hiddenColumns={currentHiddenColumns}
        listData={tailListDataProcessed}
        listFilters={filters}
        handleExport={handleExport}
        percentageComplete={downloadProgressPercentage}
        columnOverscan={10}
        handleSortChange={handleSortChange}
        getQueryFieldForColumn={getQueryFieldForColumn}
        notificationMessage={maxRowNotificationMessage}
        showNotification={showNotification}
        setShowNotification={setShowTailListNotification}
        currentDateRange={datePickerState}
        onSetDateRange={onDatePickerChange}
        handleFilterChange={handleFilterChange}
        fixedColumnCount={4}
        datePickerPresets={getDatePickerPresets()}
        viewContext={TAIL_LIST_STORE_CONTEXT}
        showViewAsCustomer={true}
        showListSearch={true}
        setListDataIndex={setTailListDataIndex}
        scrollTop={scrollTop}
        showListSearchStatusIcon={false}
        showSearchSecondaryLabel={true}
        showCompactView={showCompactView}
        showColumnviewToggle={true}
        handleColumnViewChange={onChangeColumnView}
      />
    </>
  );
};

export default TailListPage;
