/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tooltip DateTime Label used for the Title
 */

import React from 'react';
import {MONTH_NAME_DATE_AND_DAY_FORMAT, TIME_WITH_SECONDS_FORMAT, msAsString} from '../../../../../utils/DateTimeUtils';
import BoldSpan from '../../../BoldSpan';
import ToolTipLabel from './ToolTipLabel';

export interface ToolTipDataTimeLabelProps {
  dateTime: number;
}

const ToolTipDateTimeLabel: React.FC<ToolTipDataTimeLabelProps> = ({
  dateTime
}: ToolTipDataTimeLabelProps): JSX.Element => {
  return (
    <div style={{marginBottom: '4px'}}>
      <ToolTipLabel>
        {msAsString(dateTime, MONTH_NAME_DATE_AND_DAY_FORMAT)},&nbsp;
        <BoldSpan>{msAsString(dateTime, TIME_WITH_SECONDS_FORMAT)}</BoldSpan>
      </ToolTipLabel>
    </div>
  );
};

export default ToolTipDateTimeLabel;
