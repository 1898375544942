/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ListGrid defaulted text cell
 *
 */

import React from 'react';
import styled from '@emotion/styled';
import {CellRenderer, LongText} from '@viasat/insights-components';
import {GRID_CELL_DEFAULTED_TEXT_COLOR} from '../../theme/Colors';

interface ListGridDefaultCellProps {
  cellText: string;
  defaultText: string;
  maxLength?: number;
  disable?: boolean;
  width?: number;
}

const DefaultedSpan = styled.span<{isDefaulted: boolean; width?: number}>`
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  color: ${(props) => props.isDefaulted && GRID_CELL_DEFAULTED_TEXT_COLOR};
`;

export const ListGridDefaultedText: React.FC<ListGridDefaultCellProps> = ({
  cellText,
  maxLength,
  disable,
  defaultText,
  width
}: ListGridDefaultCellProps) => {
  const isDefaulted = cellText === null;
  const fullTitle = !isDefaulted ? cellText : defaultText;

  // This value is arbitrary, to closely match width: 88% & text-overflow: ellipsis to show ToolTips
  // does not account for variable width chars
  const DEFAULT_TRUNCATE_AMOUNT = 34;

  return (
    <DefaultedSpan isDefaulted={isDefaulted} width={width}>
      <LongText maxLength={maxLength ? maxLength : DEFAULT_TRUNCATE_AMOUNT} fullTitle={fullTitle} disable={disable} />
    </DefaultedSpan>
  );
};

export const DefaultedTextCellRenderer: CellRenderer = ({cellData, column, rowData}) => {
  const {width} = column;
  const {isDisabled} = rowData;
  return <ListGridDefaultedText cellText={cellData} disable={isDisabled} defaultText={'Unassigned'} width={width} />;
};

export default DefaultedTextCellRenderer;
