/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Summary Metrics Aircraft details
 */

import {chunk} from 'lodash';
import {Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Stack, Divider} from '@mui/material';

import {
  AircraftDetailsContainer,
  AircraftInfoPrintHeaderBottomBorder,
  AircraftInfoPrintHeaderLabel,
  AircraftInfoPrintHeaderTitle,
  AircraftInfoPrintHeaderTitleCount,
  AircraftInfoStackContainer,
  LabelText,
  LineBreak,
  SummaryMetricsPrintHeader,
  ValueText
} from './SummaryMetricsStyles';
import {getBrowserName} from '../../utils/browserUtils';
import {IActiveAircraftInfo} from '../../store/queries/summaryMetrics/activeAircraftInfoQuery';
import {DatePickerState} from '../../store/reducers/DatePickerReducer';
import {PRINT_AIRCRAFT_LIST_UNASSIGNED_COLOR} from '../common/theme/Colors';
import {PrintFilters, IPrintFilter} from './SummaryMetricsPage';

interface ISummaryMetricsAircraftDetailsProps {
  aircraftInfo: IActiveAircraftInfo[];
  isLoading: boolean;
  isInternal: boolean;
  isValueAddedReseller: boolean;
  datePickerState: DatePickerState;
  allFilters: IPrintFilter;
}

const AIRCRAFT_LIST_SUB_CHUNK_SIZE = 25;
const AIRCRAFT_LIST_INTERNAL_CHUNK_SIZE = 50;
const AIRCRAFT_LIST_EXTERNAL_CHUNK_SIZE = 100;
interface IAircraftInfoStackProps {
  aircraftData: IActiveAircraftInfo[];
  showCustomerData: boolean;
  mainIndex: number;
  subIndex: number;
  chunkSize: number;
}
const AircraftInfoStack: React.FC<IAircraftInfoStackProps> = ({
  aircraftData,
  showCustomerData,
  mainIndex,
  subIndex,
  chunkSize
}) => {
  return (
    <AircraftInfoStackContainer key={`${subIndex}-aircraft-stack-container`}>
      <TableContainer sx={{width: showCustomerData ? 560 : 280}}>
        <Table sx={{width: showCustomerData ? 560 : 280}}>
          <TableHead>
            <TableRow>
              <TableCell className="tableCell" />
              {showCustomerData ? (
                <TableCell align="left" padding="normal" className="tableCell">
                  Customer
                </TableCell>
              ) : null}
              <TableCell align="left" padding="normal" className="tableCell">
                Serial Number
              </TableCell>
              <TableCell align="left" padding="normal" className="tableCell">
                Tail ID
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aircraftData.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell align="right" component="th" scope="row" padding="normal" className="tableCell">
                  {mainIndex * chunkSize + AIRCRAFT_LIST_SUB_CHUNK_SIZE * subIndex + idx + 1}
                </TableCell>
                {showCustomerData ? (
                  <TableCell align="left" padding="normal" className="tableCell">
                    {row['customer']?.length > 32 ? (
                      `${row['customer']?.substring(0, 32)}...`
                    ) : row['customer'] ? (
                      row['customer']
                    ) : (
                      <div style={{color: PRINT_AIRCRAFT_LIST_UNASSIGNED_COLOR}}>Unassigned</div>
                    )}
                  </TableCell>
                ) : null}
                <TableCell align="left" padding="normal" className="tableCell">
                  {row['serialNumber']}
                </TableCell>
                <TableCell align="left" padding="normal" className="tableCell">
                  {row['tailId']}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AircraftInfoStackContainer>
  );
};

const SummaryMetricsAircraftDetails: React.FC<ISummaryMetricsAircraftDetailsProps> = ({
  aircraftInfo,
  isLoading,
  isInternal,
  isValueAddedReseller,
  datePickerState,
  allFilters
}) => {
  const showCustomerData = isInternal || isValueAddedReseller;
  const aircraftListChunkSize =
    isInternal || isValueAddedReseller ? AIRCRAFT_LIST_INTERNAL_CHUNK_SIZE : AIRCRAFT_LIST_EXTERNAL_CHUNK_SIZE;
  const aircraftInfoChunkList = chunk(aircraftInfo, aircraftListChunkSize);

  return (
    <>
      {!isLoading
        ? aircraftInfoChunkList.map((aircraftData, main_index) => {
            const aircraftInfoSubChunkList = chunk(aircraftData, AIRCRAFT_LIST_SUB_CHUNK_SIZE);
            return (
              <>
                <SummaryMetricsPrintHeader key={`${main_index}-print-header`} browserType={getBrowserName()}>
                  <AircraftInfoPrintHeaderTitle className="title">
                    Summary Metrics for
                    <AircraftInfoPrintHeaderTitleCount className="count">
                      {aircraftInfo?.length}
                    </AircraftInfoPrintHeaderTitleCount>
                    Aircraft
                  </AircraftInfoPrintHeaderTitle>
                  <LineBreak />
                  <AircraftInfoPrintHeaderLabel className="headerLabel">
                    <LabelText>Date </LabelText>
                    <ValueText>
                      {datePickerState.startDate} - {datePickerState.endDate}
                    </ValueText>
                  </AircraftInfoPrintHeaderLabel>
                  <LineBreak />
                  <PrintFilters allFilters={allFilters} />

                  <AircraftInfoPrintHeaderBottomBorder className="bottomLineMulti" />
                </SummaryMetricsPrintHeader>
                <AircraftDetailsContainer
                  key={`${main_index}-aircraft=details-container`}
                  browserType={getBrowserName()}
                >
                  <Stack
                    key={`${main_index}-aircraft-list-stack`}
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    {aircraftInfoSubChunkList.map((aircraftDataRow, idx) => {
                      return (
                        <AircraftInfoStack
                          aircraftData={aircraftDataRow}
                          mainIndex={main_index}
                          subIndex={idx}
                          showCustomerData={showCustomerData}
                          chunkSize={aircraftListChunkSize}
                        />
                      );
                    })}
                  </Stack>
                </AircraftDetailsContainer>
              </>
            );
          })
        : null}
    </>
  );
};

export default SummaryMetricsAircraftDetails;
