/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight plan uploader
 */

import React, {useState, useCallback} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import {isEmpty, isNil, forEach} from 'lodash';
import {LoadingSpinner} from '@viasat/insights-components';
import PopUpModal from '../common/Modal';
import {MODAL_TITLE_COLOR} from '../common/theme/Colors';
import FileUpload from './FileUpload';
import * as StyledElms from './ConnectivityOutlookStyles';
import {getElementIdFromSectionBase} from '../../utils/ElementIdUtils';
import {useStore} from '../../store/Store';
import {ConnectivityPlannerAction, IAircraftList} from '../../store/reducers/ConnectivityPlannerReducer';
import {
  UPLOAD_MODAL_SUB_TITLE,
  UPLOAD_MODAL_TITLE,
  AIRCRAFT_LIST_SUBTITILE,
  AIRCRAFT_LIST_TITLE
} from '../../utils/constants';
import {bizavApiUrl} from '../../utils/config';
import {AircraftList, SearchInputClearIconContainer} from './ConnectivityOutlookStyles';
import {CancelTokenAction} from '../../store/reducers/CancelTokenReducer';
import {BlackBoldSpan} from '../mapView/MapStyles';

interface FlightPlanUploaderProps {
  idBase: string;
  initialModalState: boolean;
  filteredAircraftList: IAircraftList[];
  searchCriteria: string;
  setSearchCriteria: (string) => void;
}

const HighlightedSpan = ({criteria, label, className, id}) => {
  const criteriaLower = criteria.toLowerCase();
  const regEx = new RegExp(`(${criteria})`, 'ig');

  return (
    <span className={className} id={id} key={id}>
      {label.split(regEx).map((text, idx) =>
        text.toLowerCase() === criteriaLower ? (
          <BlackBoldSpan id={`${id}-${idx}-bold`} key={`${id}-${idx}-bold`}>
            {text}
          </BlackBoldSpan>
        ) : (
          text
        )
      )}
    </span>
  );
};

const FlightPlanUploader = ({
  idBase,
  initialModalState,
  filteredAircraftList,
  searchCriteria,
  setSearchCriteria
}: FlightPlanUploaderProps) => {
  const {store, dispatch} = useStore();
  const groupCode = store.customer.current.code;
  const {flightPlanContent, flightPlanImportFiles, cancelTokenSource, aircraftList} = store.connectivityPlanner;
  const [modalOpen, setModalOpen] = useState<boolean>(initialModalState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFlightPlanId, setSelectedFlightPlanId] = useState<number>(null);
  const [selectedAircraftIndex, setSelectedAircraftIndex] = useState<number>(-1);
  const [isAircraftListDisplay, setIsAircraftListDisplay] = useState<boolean>(false);

  /**
   * On Cancel button click at the Upload modal
   */
  const handleModalClose = useCallback(() => {
    if (typeof cancelTokenSource != typeof undefined) {
      cancelTokenSource.forEach((file) => {
        dispatch({
          type: CancelTokenAction.CANCEL_REQUEST,
          payload: {fileId: file['id']}
        });
      });
    }
    dispatch({
      type: CancelTokenAction.REMOVE_TOKEN,
      payload: {emptyList: true}
    });
    dispatch({
      type: ConnectivityPlannerAction.RESET_SELECTED_FILES,
      payload: []
    });
    dispatch({
      type: ConnectivityPlannerAction.REMOVE_FLIGHT_PLAN_CONTENT,
      payload: {emptyList: true}
    });
    setModalOpen(false);
  }, [dispatch, cancelTokenSource]);

  /**
   * Opens up the upload modal
   * @returns Sets the Modal Open state
   */
  const uploadFlightPlan = () => setModalOpen(true);

  /**
   * Save Flight Plan - Continue button on click
   */
  const saveFlightPlans = useCallback(async () => {
    if (!isNil(flightPlanContent)) {
      setIsLoading(true);
      const sourceOfCancelToken = axios.CancelToken.source();
      dispatch({
        type: CancelTokenAction.SET_CANCEL_TOKEN_SOURCE,
        payload: {id: 0, token: sourceOfCancelToken}
      });
      const config = {
        headers: {Authorization: `Bearer ${localStorage.token}`},
        cancelToken: sourceOfCancelToken.token
      };

      try {
        Promise.all(
          flightPlanContent.map((flightPlan) =>
            axios.post(
              `${bizavApiUrl}/connectivityPlanner/addFlightPlan`, // Request URL
              {flightPlans: [flightPlan], groupCode: groupCode}, // Request Payload
              config // Request headers
            )
          )
        ).then(
          axios.spread((...addFlightPlanResponse) => {
            let totalInsertUpdate = 0;
            forEach(addFlightPlanResponse, (flightPlanResponse) => {
              totalInsertUpdate += flightPlanResponse?.data?.inserted + flightPlanResponse?.data?.updated;
            });
            dispatch({
              type: ConnectivityPlannerAction.SET_NEW_FLIGHT_PLAN_COUNT,
              payload: totalInsertUpdate
            });
            setIsLoading(false);
            //Removes the already uploaded file from store
            dispatch({
              type: ConnectivityPlannerAction.REMOVE_FILE_FROM_LIST,
              payload: 0
            });
            dispatch({
              type: ConnectivityPlannerAction.REMOVE_FLIGHT_PLAN_CONTENT,
              payload: {emptyList: true}
            });
            dispatch({
              type: ConnectivityPlannerAction.SET_NEW_UPLOADED_PLANS_ALERT,
              payload: true
            });
            handleModalClose();
          })
        );
      } catch (error) {
        if (error?.message !== 'cancel') {
          dispatch({
            type: ConnectivityPlannerAction.SAVE_FLIGHT_PLAN_ERROR,
            payload: 'Unable to save the flight plan(s).'
          });
        }
        dispatch({
          type: ConnectivityPlannerAction.SET_NEW_FLIGHT_PLAN_COUNT,
          payload: 0
        });
        setIsLoading(false);
        //Removes the already uploaded file from store
        dispatch({
          type: ConnectivityPlannerAction.REMOVE_FILE_FROM_LIST,
          payload: 0 // This will be replaced by empty list, once we enable multiple file import options
        });
        dispatch({
          type: ConnectivityPlannerAction.REMOVE_FLIGHT_PLAN_CONTENT,
          payload: {emptyList: true}
        });
        handleModalClose();
      }
    }
  }, [groupCode, flightPlanContent, handleModalClose, dispatch]);

  const updateUploadedFileContent = () => {
    setIsAircraftListDisplay(false);

    dispatch({
      type: ConnectivityPlannerAction.UPDATE_FLIGHT_PLAN_TAIL_ID,
      payload: {
        flightPlan: flightPlanImportFiles,
        selectedFlightPlanId: selectedFlightPlanId,
        searchResults: searchResults,
        selectedAircraftIndex: selectedAircraftIndex,
        aircraftList: aircraftList
      }
    });
    setSelectedAircraftIndex(-1);
    setSearchCriteria('');
  };

  const searchResults = filteredAircraftList?.length || searchCriteria.length > 0 ? filteredAircraftList : aircraftList;

  const modalContentStyle = {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontSize: '20px',
    width: '545px',
    minHeight: '230px',
    height: 'auto'
  };

  return (
    <>
      <StyledElms.UploadButton id={`${idBase}-upload-flight-plan-btn`} onClick={uploadFlightPlan}>
        Upload a Flight Plan
      </StyledElms.UploadButton>
      <PopUpModal
        id={`${idBase}-flightPlanUploadModal`}
        title={!isAircraftListDisplay ? UPLOAD_MODAL_TITLE : AIRCRAFT_LIST_TITLE}
        subTitle={!isAircraftListDisplay ? UPLOAD_MODAL_SUB_TITLE : AIRCRAFT_LIST_SUBTITILE}
        open={modalOpen}
        titlecolor={MODAL_TITLE_COLOR}
      >
        <div style={modalContentStyle}>
          {!isAircraftListDisplay ? (
            <div>
              <FileUpload
                idBase="ConnectivityPlanner"
                setIsAircraftListDisplay={setIsAircraftListDisplay}
                setSelectedFlightPlanId={setSelectedFlightPlanId}
              />
              <StyledElms.CancelButton onClick={handleModalClose}>
                <StyledElms.CancelButtonLabel
                  id={getElementIdFromSectionBase(`${idBase}-Upload-cancel`, 'upload', 'button')}
                >
                  Cancel
                </StyledElms.CancelButtonLabel>
              </StyledElms.CancelButton>
              <StyledElms.UploadContinueButton
                id={getElementIdFromSectionBase(`${idBase}-Upload-continue-button`, 'upload', 'button')}
                disabled={
                  isEmpty(flightPlanContent) || flightPlanContent.length !== flightPlanImportFiles.length || isLoading
                }
                onClick={() => saveFlightPlans()}
                left={20}
                width={425}
              >
                <StyledElms.ContinueButtonLabel
                  id={getElementIdFromSectionBase(`${idBase}-Upload-continue-label`, 'upload', 'button')}
                  disabled={
                    isEmpty(flightPlanContent) || flightPlanContent.length !== flightPlanImportFiles.length || isLoading
                  }
                >
                  Continue &nbsp;
                  {isLoading ? <LoadingSpinner id={`${idBase}-loading-spinner`} isSpinner={true} size={15} /> : <></>}
                </StyledElms.ContinueButtonLabel>
              </StyledElms.UploadContinueButton>
            </div>
          ) : (
            <div>
              <StyledElms.AircraftSearchBox id={getElementIdFromSectionBase(idBase, 'searchResult', 'searchBox')}>
                <SearchIcon className="search-icon" />
                <StyledElms.SearchInput
                  id={getElementIdFromSectionBase(idBase, 'searchResult', 'searchInput')}
                  autoComplete="off"
                  value={searchCriteria}
                  onChange={($event) => setSearchCriteria($event.target.value)}
                />
                {searchCriteria.length ? (
                  <SearchInputClearIconContainer>
                    <CancelIcon onClick={() => setSearchCriteria('')} className="clear-icon" />
                  </SearchInputClearIconContainer>
                ) : (
                  <></>
                )}
              </StyledElms.AircraftSearchBox>
              <AircraftList noResults={searchResults.length === 0}>
                {searchResults?.length ? (
                  searchResults.map((row, idx) => {
                    return (
                      <StyledElms.RowContainerWithBorder
                        id={`aircraftList-row-container-${idx}`}
                        key={`aircraftList-row-container-${idx}`}
                        noValueFound={false}
                        isSelected={idx === selectedAircraftIndex}
                      >
                        <StyledElms.AircraftListItem
                          id={`${row.tailId}-${idx}-bold`}
                          key={`${row.tailId}-${idx}-${idx}-bold`}
                        >
                          <HighlightedSpan
                            id={`searchResult__${row.tailId}-tailInfo`}
                            key={`searchResult__${row.tailId}-tailInfo-highlight`}
                            className="detail"
                            criteria={searchCriteria}
                            label={`${row.tailId}`}
                          />
                        </StyledElms.AircraftListItem>
                        <StyledElms.AircraftListItem
                          id={`${row.serialNumber}-${idx}-bold`}
                          key={`${row.serialNumber}-${idx}-${idx}-bold`}
                        >
                          <HighlightedSpan
                            id={`searchResult__${row.serialNumber}-tailInfo`}
                            key={`searchResult__${row.serialNumber}-tailInfo-highlight`}
                            className="detail"
                            criteria={searchCriteria}
                            label={`${row.serialNumber}`}
                          />
                        </StyledElms.AircraftListItem>
                        <StyledElms.AircraftListItemAircraftType
                          id={`${row.aircraftType}-${idx}-bold`}
                          key={`${row.aircraftType}-${idx}-${idx}-bold`}
                        >
                          <HighlightedSpan
                            id={`searchResult__${row.aircraftType}-tailInfo`}
                            key={`searchResult__${row.aircraftType}-tailInfo-highlight`}
                            className="detail"
                            criteria={searchCriteria}
                            label={`${row.aircraftType}`}
                          />
                        </StyledElms.AircraftListItemAircraftType>
                        <StyledElms.SelectOption
                          isSelected={idx === selectedAircraftIndex}
                          onClick={() => setSelectedAircraftIndex(idx === selectedAircraftIndex ? -1 : idx)}
                        >
                          {idx === selectedAircraftIndex ? 'Deselect' : 'Select'}
                        </StyledElms.SelectOption>
                      </StyledElms.RowContainerWithBorder>
                    );
                  })
                ) : (
                  <>
                    <StyledElms.AircraftListNoItemsLabel>No results found</StyledElms.AircraftListNoItemsLabel>
                  </>
                )}
              </AircraftList>
              <StyledElms.AircraftListBackButton
                onClick={() => {
                  setSelectedAircraftIndex(-1);
                  setSearchCriteria('');
                  setIsAircraftListDisplay(false);
                }}
              >
                <StyledElms.CancelButtonLabel
                  id={getElementIdFromSectionBase(`${idBase}-Tail-list-upload-back`, 'upload', 'button')}
                >
                  Back
                </StyledElms.CancelButtonLabel>
              </StyledElms.AircraftListBackButton>
              <StyledElms.AircraftListContinueButton
                id={getElementIdFromSectionBase(`${idBase}-Tail-list-upload-continue-button`, 'upload', 'button')}
                disabled={selectedAircraftIndex < 0}
                onClick={() => updateUploadedFileContent()}
                left={20}
                width={425}
              >
                <StyledElms.ContinueButtonLabel
                  id={getElementIdFromSectionBase(`${idBase}-Tail-list-upload-continue-label`, 'upload', 'button')}
                  disabled={selectedAircraftIndex < 0}
                >
                  Continue &nbsp;
                </StyledElms.ContinueButtonLabel>
              </StyledElms.AircraftListContinueButton>
            </div>
          )}
        </div>
      </PopUpModal>
    </>
  );
};

export default FlightPlanUploader;
