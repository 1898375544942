/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Flight List Total Query
 */

import {Query} from '../types';

export type FlightListTotalType = number | null;

interface FlightListTotalRaw {
  totalFlights: number;
}

/**
 * Extracts the total flights value from the returned object
 * @param flightListTotalRaw the returned object
 * @returns the total flights value
 */
const getTotalValue = (flightListTotalRaw: FlightListTotalRaw | null): FlightListTotalType | null => {
  if (!flightListTotalRaw) return null;

  return flightListTotalRaw.totalFlights;
};

const flightListTotalQuery: Query<FlightListTotalType> = {
  route: 'flightList/totalFlights',
  transform: getTotalValue
};

export default flightListTotalQuery;
