/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List SW Query
 */

import {SOFTWARE_VERSIONS_MAPPING} from '../../../components/lists/tail/util/tailListUtil';
import {Query} from '../types';

export interface SWVersionInfo {
  aircraftId: string;
  kaTerminalVersion: string;
  kaTerminalVersionStatus: string;
  kaTerminalVersionStatusValue: number;
  latestKaTerminalVersion: string;
  bdtVersion: string;
  bdtVersionStatus: string;
  bdtVersionStatusValue: number;
  latestBdtVersion: string;
  kuTerminalVersion: string;
  kuTerminalVersionStatus: string;
  kuTerminalVersionStatusValue: number;
  latestKuTerminalVersion: string;
  sedVersion: string;
  sedVersionStatus: string;
  sedVersionStatusValue: number;
  latestSedVersion: string;
  sscfVersion: string;
  sscfVersionStatus: string;
  sscfVersionStatusValue: number;
  latestSscfVersion: string;
}

export interface SWVersionRawInfo {
  aircraftId: string;
  kaTerminalVersion: string;
  kaTerminalVersionStatus: string;
  latestKaTerminalVersion: string;
  bdtVersion: string;
  bdtVersionStatus: string;
  latestBdtVersion: string;
  kuTerminalVersion: string;
  kuTerminalVersionStatus: string;
  latestKuTerminalVersion: string;
  sedVersion: string;
  sedVersionStatus: string;
  latestSedVersion: string;
  sscfVersion: string;
  sscfVersionStatus: string;
  latestSscfVersion: string;
}

export const STATUS_UPDATE_AVAILABLE = 'Update Available';
export const STATUS_PILOT = 'Pilot';
export const STATUS_PRODUCTION = 'Production';

/**
 * Get status value based on Software Version Status
 * @param status - Software Version Status
 * @returns Status value in number
 */
const getSWVersionStatusValue = (status: string): number => {
  switch (status) {
    case STATUS_UPDATE_AVAILABLE:
      return 3;
    case STATUS_PILOT:
      return 2;
    case STATUS_PRODUCTION:
      return 1;
    default:
      return 0;
  }
};

/**
 * Get status value based on Software Version Status
 * @param tailData - tail data
 * @returns overall software version status of the aircraft
 */

export const getAirctaftSoftwareStatus = (tailData: any) => {
  let overallAircraftSwStatus = null;
  let swVersionStatus = [];
  Object.keys(SOFTWARE_VERSIONS_MAPPING).forEach((swVersion) => {
    swVersionStatus.push(tailData[`${swVersion}Status`]);
  });
  if (swVersionStatus.includes(STATUS_PILOT)) {
    overallAircraftSwStatus = STATUS_PILOT;
  } else if (swVersionStatus.includes(STATUS_UPDATE_AVAILABLE)) {
    overallAircraftSwStatus = STATUS_UPDATE_AVAILABLE;
  } else if (swVersionStatus.includes(STATUS_PRODUCTION)) {
    overallAircraftSwStatus = STATUS_PRODUCTION;
  }
  return overallAircraftSwStatus;
};

/**
 * Transforms the given raw sw info data into sw info with status value
 * @param swInfoRawList SWVersionRawInfo values from the api
 * @returns Transformed sw info
 */
const transformRawSWInfo = (swInfoRawList: SWVersionRawInfo[] | null): SWVersionInfo[] | null => {
  if (!swInfoRawList) return null;

  const swInfo: SWVersionInfo[] = swInfoRawList.map((swInfoRaw) => ({
    aircraftId: swInfoRaw.aircraftId,
    kaTerminalVersion: swInfoRaw.kaTerminalVersion,
    latestKaTerminalVersion: swInfoRaw.latestKaTerminalVersion,
    kaTerminalVersionStatus: swInfoRaw.kaTerminalVersionStatus,
    kaTerminalVersionStatusValue: getSWVersionStatusValue(swInfoRaw.kaTerminalVersionStatus),
    bdtVersion: swInfoRaw.bdtVersion,
    bdtVersionStatus: swInfoRaw.bdtVersionStatus,
    latestBdtVersion: swInfoRaw.latestBdtVersion,
    bdtVersionStatusValue: getSWVersionStatusValue(swInfoRaw.bdtVersionStatus),
    kuTerminalVersion: swInfoRaw.kuTerminalVersion,
    latestKuTerminalVersion: swInfoRaw.latestKuTerminalVersion,
    kuTerminalVersionStatus: swInfoRaw.kuTerminalVersionStatus,
    kuTerminalVersionStatusValue: getSWVersionStatusValue(swInfoRaw.kuTerminalVersionStatus),
    sedVersion: swInfoRaw.sedVersion,
    sedVersionStatus: swInfoRaw.sedVersionStatus,
    latestSedVersion: swInfoRaw.latestSedVersion,
    sedVersionStatusValue: getSWVersionStatusValue(swInfoRaw.sedVersionStatus),
    sscfVersion: swInfoRaw.sscfVersion,
    sscfVersionStatus: swInfoRaw.sscfVersionStatus,
    sscfVersionStatusValue: getSWVersionStatusValue(swInfoRaw.sscfVersionStatus),
    latestSscfVersion: swInfoRaw.latestSscfVersion
  }));

  return swInfo;
};

const swVersionQuery: Query<SWVersionInfo[]> = {
  route: 'tailList/swVersions',
  transform: transformRawSWInfo
};

export default swVersionQuery;
