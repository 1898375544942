/***
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Pages for Slider Menu
 */

import React from 'react';
import Map from '@mui/icons-material/Map';
import ViewList from '@mui/icons-material/ViewList';
import {SliderMenuItem} from '@viasat/insights-components';
import {ViewQuilt} from '@mui/icons-material';

import TelescopeIcon from './components/common/theme/icons/TelescopeIcon';
import ListViewPage from './components/lists/ListViewPage';
import MapViewWrapper from './components/mapView/MapViewWrapper';
import ConnectivityOutlookContainer from './components/connectivityOutlook/ConnectivityOutlookContainer';
import DashboardPage from './components/dashboard/DashboardPage';
import {showDashboardNavMenu} from './utils/config';

export interface PageProps {
  title: string;
}

interface PageInfo extends SliderMenuItem {
  component: any;
  props: PageProps;
}

const SUMMARY_METRICS_NAV_MENU = {
  name: 'Summary Metrics',
  key: 'dashboard',
  icon: <ViewQuilt />,
  route: '/dashboard/summary-metrics',
  ariaLabel: 'summaryMetrics',
  component: <DashboardPage />,
  props: {title: 'Summary Metrics'}
};

const PAGES: PageInfo[] = [
  {
    name: 'Fleet Map',
    key: 'fleet-map',
    icon: <Map />,
    route: '/fleet-map',
    ariaLabel: 'fleet map page',
    component: MapViewWrapper,
    props: {title: 'Fleet Map'}
  },
  {
    name: 'Lists',
    key: 'lists',
    icon: <ViewList />,
    route: '/lists/flight',
    ariaLabel: 'flight lists page',
    component: <ListViewPage />,
    props: {title: 'Lists'}
  },
  {
    name: 'Connectivity Planner',
    key: 'connectivity-outlook-planner',
    icon: <TelescopeIcon sx={{width: 20, height: 20}} />,
    route: '/connectivity-outlook-planner',
    ariaLabel: 'connectivity outlook planner',
    component: ConnectivityOutlookContainer,
    props: {title: 'Connectivity Planner'}
  }
];

if (showDashboardNavMenu) {
  PAGES.splice(1, 0, SUMMARY_METRICS_NAV_MENU);
}

export default PAGES;
