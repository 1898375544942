/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Support Request Popup
 */
import React from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  POPUP_TEXT_COLOR,
  CLOSE_ICON_COLOR,
  POPUP_BLOCK_BACKGROUND_COLOR,
  PROGRESS_BAR_BG_COLOR,
  SUPPORT_BORDER_COLOR,
  CASE_LIST_TEXT_COLOR,
  POPUP_BACKGROUND_COLOR,
  ACTIVE_BUTTON_COLOR,
  BELOW_LOWER_THRESHOLD_COLOR
} from '../../../common/theme/Colors';
import SupportRequestIcon from '../../../common/theme/icons/SupportRequestIcon';
import {requestSupportEnabled} from '../../../../utils/config';

const SupportPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px;
  gap: 32px;
  background: ${POPUP_BACKGROUND_COLOR};
  position: relative;
  width: 600px;
  border-radius: 16px;
`;
const SupportPopupTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 552px;
  height: 270px;
  flex: none;
  order: 0;
  flex-grow: 0;
  .support-agent {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 17.5%;
    bottom: 17.5%;
    background: ${ACTIVE_BUTTON_COLOR};
  }
  .head {
    height: 32px;
    font-style: normal;
    font-family: 'Source Sans Pro';
    font-weight: 800;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: ${POPUP_TEXT_COLOR};
  }
  .text {
    height: 72px;
    width: 520px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_TEXT_COLOR};
  }
  .for-assistance {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${CASE_LIST_TEXT_COLOR};
  }
  .close-icon {
    width: 10%;
    margin-top: 0px;
    color: ${CLOSE_ICON_COLOR};
    cursor: pointer;
    position: absolute;
    right: 0px;
  }
`;

const RequestSupport = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 0px 0px 0px;
  gap: 16px;
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-top: 1px solid ${PROGRESS_BAR_BG_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 600px;
  height: 104px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  .support-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 4px;
    border: 1px solid ${SUPPORT_BORDER_COLOR};
    background: ${POPUP_BLOCK_BACKGROUND_COLOR};
    border-radius: 1000px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px;
    cursor: pointer;
  }
  .button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_TEXT_COLOR};
  }
  .existing-case {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 4px;
    border-radius: 1000px;
    position: relative;
    width: 292px;
    height: 40px;
    right: 0px;
    top: 0px;
    cursor: pointer;
    margin-right: 20px;
    background: ${ACTIVE_BUTTON_COLOR};
    border: 1px solid ${POPUP_BLOCK_BACKGROUND_COLOR};
  }
  .statusBall {
    position: relative;
    width: 16px;
    height: 16px;
    right: -4px;
    top: -4px;
    background: ${BELOW_LOWER_THRESHOLD_COLOR};
    border: 1px solid ${POPUP_BACKGROUND_COLOR};
  }
  .existing-case-text {
    width: 244px;
    height: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${POPUP_BACKGROUND_COLOR};
    font-family: 'Source Sans Pro';
    font-style: normal;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;

interface SupportPopupProps {
  caseCount: number;
  setOpenAircraftCaseListModal: (openModal: any) => void;
  handleExistingCasesClick: (rowData: any) => void;
  closeModal: (rowData: any) => void;
}

const SupportPopup: React.FC<SupportPopupProps> = ({
  caseCount,
  setOpenAircraftCaseListModal,
  handleExistingCasesClick,
  closeModal
}) => {
  return (
    <SupportPopupContainer>
      <SupportPopupTextContainer>
        <CloseIcon className="close-icon" onClick={closeModal} />
        <br></br>
        <SupportRequestIcon id="support-agent"></SupportRequestIcon>
        <div className="head">How do you want us to help?</div>
        <div className="text">
          Additional help is available if you need it, including a phone call with a Viasat specialist.
        </div>
        <div className="for-assistance">
          For immediate assistance, please call <span style={{fontWeight: 'bold'}}> 1-866-VSAT-AIR</span>
        </div>
      </SupportPopupTextContainer>

      <RequestSupport>
        <button className="support-button" onClick={requestSupportEnabled ? handleExistingCasesClick : null}>
          <span className="button-text">Request Support</span>
        </button>
        <button className="existing-case" onClick={() => setOpenAircraftCaseListModal(true)}>
          <span className="existing-case-text">Check an existing case ({caseCount})</span>
          {caseCount > 0 ? (
            <div style={{position: 'absolute', top: -4, left: 274}}>
              <svg
                focusable="false"
                width="17"
                height="16"
                viewBox="0 0 16 17"
                aria-hidden="true"
                role="presentation"
                style={{
                  position: 'relative',
                  verticalAlign: 'text-top'
                }}
              >
                <circle
                  id="open-cases-dot"
                  cx={9}
                  cy={8}
                  r={8}
                  fill={`${BELOW_LOWER_THRESHOLD_COLOR}`}
                  stroke="white"
                />
              </svg>
            </div>
          ) : (
            <></>
          )}
        </button>
      </RequestSupport>
    </SupportPopupContainer>
  );
};
export default SupportPopup;
