/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Connectivity Outlook Events Timeline chart
 */

import moment from 'moment';
import React, {useState, useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {useIntl} from 'react-intl';

import {
  areMsTimestampMinutesEqualTo,
  msAsString,
  TIME_CONCISE_NO_PAD_FORMAT,
  DATE_CONCISE_FORMAT,
  MS_IN_MIN,
  formatMomentInput,
  DATE_FORMAT,
  DATE_TIME_FORMAT_WITH_HR_MINS,
  formatMomentInputAsTimeWithHrMin
} from '../../utils/DateTimeUtils';
import ToolTipDateTimeRangeLabel from '../common/elements/chart/ToolTip/ToolTipDateTimeRangeLabel';
import {ChartType} from '../common/elements/chart/types';
import {
  AXIS_TEXT,
  EVENTS_TIMELINE_DASHED_GRID_LINE_COLOR,
  EVENTS_TIMELINE_GRID_LINE_COLOR
} from '../common/theme/Colors';
import {SeriesDataPoint, mergePointsInSeries} from '../flightDetails/charts/EventsTimelineUtils';
import EventsTimelineToolTip from '../flightDetails/charts/tooltip/EventsTimelineToolTip';
import {IOriginDestinationData} from './EventsTimelineCard';
import {checkShowEmptyAxis} from '../common/elements/chart/RenderOverlay';
import {CHART_NO_DATA_X_AXIS, CHART_NO_DATA_Y_AXIS} from '../../utils/constants';
import {ABOVE_TARGET_COLOR} from '../common/theme/Colors';
import {
  ConnectivityOutlookIconColors,
  ConnectivityOutlookStatus,
  FlightLegChartSettings,
  getChartSettingsForEventsTimeline
} from './ConnectivityOutlookUtils';
import {getSelectedLegEventsTimeline} from './SelectedFlightLegUtils';
import EventsTimelineTooltip from './EventsTimelineTooltip';
import {useStore} from '../../store/Store';
import {formatConfig} from '../common/elements/chart/chartUtils';
import ColumnRangeChart from '../common/elements/chart/ColumnRangeChart';

const ChartContainer = styled.div`
  svg text,
  svg tspan {
    font-family: 'Source Sans Pro', sans-serif;
  }
`;

export interface IFlightLegsEventsTimeLineData {
  flightPlanId: string;
  flightLegEvents: IEventsTimelineData[];
  xAxis?: number;
  yAxis?: number;
  showInLegend?: boolean;
}
export interface IEventsTimelineData {
  eventDate: moment.Moment;
  status: ConnectivityOutlookStatus;
  eventName?: ConnectivityOutlookStatus;
  iconText?: number;
}

interface IEventsTimelineProps {
  eventsTimeline: IFlightLegsEventsTimeLineData[];
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  isLoading: boolean;
  eventsTimelineChartHeight: number;
}
interface IDataLabel {
  enabled: boolean;
  formatter: () => string;
  useHTML: boolean;
  align: string;
  inside: boolean;
  x: number;
  allowOverlap?: boolean;
  position?: string;
}
interface SeriesData {
  name: ConnectivityOutlookStatus;
  color: string;
  borderColor: string;
  data: SeriesDataPoint[];
  dataLabels: IDataLabel[];
  legendIndex: Number;
  showInLegend?: boolean;
  xAxis?: number;
  yAxis?: number;
  pointWidth?: number;
  states?: {inactive: {opacity: number}};
}
/**
 *  Renders an inverted column range chart for connectivity events timeline
 *  @return Events time line chart
 */
const EventsTimeline: React.FC<IEventsTimelineProps> = ({
  isLoading,
  eventsTimeline,
  hideAllLegendItemsOnLoad,
  legendItemsToHideOnLoad,
  eventsTimelineChartHeight
}: IEventsTimelineProps) => {
  const theme: any = useTheme();
  const [chartConfig, setChartConfig] = useState<any>(null);
  const {store} = useStore();
  const {flightPathInfo, flightPlanInfo, selectedFlightLegIdx} = store.connectivityPlanner;

  const intl = useIntl();

  useEffect(() => {
    if (!isLoading && eventsTimeline && eventsTimeline.length) {
      const eventsTimelineToDisplay: IFlightLegsEventsTimeLineData[] = getSelectedLegEventsTimeline(
        eventsTimeline,
        selectedFlightLegIdx
      );

      const chartTimeSettings: FlightLegChartSettings[] = getChartSettingsForEventsTimeline(
        flightPathInfo,
        flightPlanInfo,
        selectedFlightLegIdx
      );
      const originDestinationData: IOriginDestinationData[] = flightPlanInfo.map((flightPlan) => ({
        id: flightPlan.id,
        origin: flightPlan.departureAirport,
        destination: flightPlan.destinationAirport,
        start: flightPlan.departureTime,
        end: flightPlan.arrivalTime
      }));

      // General config
      const id = 'connectivityOutlook--eventsTimeline__timeSeries-chart';
      const highchartsIdBase = 'connectivityOutlook--eventsTimeline';
      const chartType = ChartType.RANGED_BAR;
      const defaultChartPalette = theme ? theme.defaultChartPalette : [];

      const toMs = (input: Date | string) => Number(moment.utc(input).format('x'));

      // Connectivity outlook category bucket
      const categories = [''];

      // Handle noData Options
      const noDataOptions = {
        enabled: true,
        showEmptyYAxis: false
      };

      // Grid line above the bar
      const plotLines = [
        {
          color: EVENTS_TIMELINE_GRID_LINE_COLOR,
          width: 1,
          value: -0.12
        },
        {
          color: EVENTS_TIMELINE_DASHED_GRID_LINE_COLOR,
          width: 1,
          value: 0,
          dashStyle: 'ShortDash'
        }
      ];

      const originDestinationFormatter = (
        title: string,
        subtitle: string,
        alignment: string,
        position: string,
        showEventsTimelineConnectedBar?: boolean,
        eventLabel?: string
      ) => {
        const eventsTimelineConnectedStartBar = `<div style="border-left: 3px solid ${ABOVE_TARGET_COLOR}; height: 22px;margin-top: 20px;"></div>`;
        const eventsTimelineConnectedEndBar = `<div style="border-left: 3px solid ${ABOVE_TARGET_COLOR}; height: 22px; transform: translateX(50%);margin-top: 20px;"></div>`;

        const flightLegStartBar = `<div style="display: flex;flex-direction: row;margin-left: -14px;margin-top: 17px;"><div style="border-left: 1px solid ${EVENTS_TIMELINE_GRID_LINE_COLOR}; height: 72px;"></div>${
          showEventsTimelineConnectedBar ? eventsTimelineConnectedStartBar : eventLabel
        }</div>`;

        const flightLegEndBar = `<div style="display: flex;flex-direction: row;margin-top: 20px;  margin-left: calc(50% + 40px);">${
          showEventsTimelineConnectedBar ? eventsTimelineConnectedEndBar : ''
        }<div style="border-left: 1px solid ${EVENTS_TIMELINE_GRID_LINE_COLOR}; height: 72px; margin-left:${
          showEventsTimelineConnectedBar ? '0px' : '5px'
        };"></div></div>`;

        const xAxisSubtitle = `<div style = "font-size: 10px; font-weight: 400; text-align: ${alignment};">
                               ${formatMomentInput(subtitle, `${DATE_FORMAT}`).toUpperCase()}
                               </div>
                               <div style = "font-size: 10px; font-weight: 400; text-align: ${alignment};">
                               ${formatMomentInputAsTimeWithHrMin(subtitle).toUpperCase()}
                               </div>`;
        const xAxisTitle = `<div style="position: ${position}; font-family: Source Sans Pro; font-size: 14px; font-style: normal; font-weight: 600; color:black; line-height: 12px; margin-top:-45px; padding-left:0px"><div style = "text-align: ${alignment}"> 
                            ${title}
                            </div> 
                            ${xAxisSubtitle}</div> 
      ${alignment === 'left' ? flightLegStartBar : flightLegEndBar}`;
        return xAxisTitle;
      };

      // X-axis
      const customXAxis = eventsTimelineToDisplay.map((flightLegData: IFlightLegsEventsTimeLineData, index) => {
        let left = 0;

        chartTimeSettings.forEach((flightLegChart, i) => {
          if (i <= index - 1) left += flightLegChart.chartSettings.width;
        });
        const flightLegChart: FlightLegChartSettings = chartTimeSettings.find(
          (row) => row.flightPlanId === flightLegData.flightPlanId
        );
        return {
          categories,
          plotLines: plotLines,
          title: {
            rotation: 0,
            margin: 25,
            y: -2.5
          },

          lineWidth: 0,
          tickInterval: 1,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: '500',
              transform: 'translate(-3px)',
              color: AXIS_TEXT
            },
            x: -2
          },
          showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_X_AXIS),
          width: flightLegChart.chartSettings.width + '%',
          left: (index === 0 ? 0 : left) + '%'
        };
      });

      // This padding value will cause the x-axis labels to rotate at the same point
      const xAxisLabelPadding = 0;

      const customYAxis = eventsTimelineToDisplay.map((flightLegData: IFlightLegsEventsTimeLineData, index) => {
        let left = 0;
        chartTimeSettings.forEach((fligtLegChart, i) => {
          if (i <= index - 1) left += fligtLegChart.chartSettings.width;
        });
        const flightLegChart: FlightLegChartSettings = chartTimeSettings.find(
          (row) => row.flightPlanId === flightLegData.flightPlanId
        );
        return {
          type: 'datetime' as Highcharts.AxisTypeValue,
          title: {
            text: ''
          },
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          tickInterval: flightLegChart.chartSettings.tickIntervalMinutes * MS_IN_MIN,
          minorTicks: true,
          startOnTick: false,
          endOnTick: false,
          labels: {
            formatter: function () {
              if (
                Number(this.value) < 1e12 ||
                !areMsTimestampMinutesEqualTo(this.value, flightLegChart.chartSettings.labeledTickMinutesValues) ||
                moment(formatMomentInput(moment(this.value), DATE_TIME_FORMAT_WITH_HR_MINS)).diff(
                  formatMomentInput(
                    moment(flightLegData.flightLegEvents[flightLegData.flightLegEvents.length - 1].eventDate),
                    DATE_TIME_FORMAT_WITH_HR_MINS
                  )
                ) > 0
              ) {
                return '';
              }

              const hourLabel = msAsString(this.value, TIME_CONCISE_NO_PAD_FORMAT);
              const dayLabel = msAsString(this.value, DATE_CONCISE_FORMAT);
              return hourLabel === '0:00' ? dayLabel : hourLabel;
            },
            x: 0,
            y: 18,
            style: {
              fontSize: '10px',
              fontWeight: '600',
              color: AXIS_TEXT,
              whiteSpace: 'nowrap',
              textOverflow: 'none'
            },
            padding: xAxisLabelPadding ? xAxisLabelPadding : undefined
          },
          tickWidth: flightLegChart.chartSettings.tickIntervalMinutes * MS_IN_MIN ? 1 : 0,
          tickLength: flightLegChart.chartSettings.tickIntervalMinutes * MS_IN_MIN ? 8 : 10,
          min: toMs(flightLegChart.chartSettings.start),
          max: toMs(flightLegChart.chartSettings.end),
          showEmpty: checkShowEmptyAxis(noDataOptions, CHART_NO_DATA_Y_AXIS),
          width: flightLegChart.chartSettings.width + '%',
          left: (index === 0 ? 0 : left) + '%',
          offset: -55
        };
      });

      // Events timeline data label formatter for rendering custom tooltip
      const dataLabelFormatter = (point: SeriesDataPoint, status: string) => {
        return ReactDOMServer.renderToString(<EventsTimelineTooltip point={point} status={status} />);
      };
      // Series
      const newSeries = (
        status: ConnectivityOutlookStatus,
        legendIndex: Number,
        showInLegend: boolean,
        index: number
      ): SeriesData => ({
        name: status,
        color: ConnectivityOutlookIconColors[status],
        borderColor: ConnectivityOutlookIconColors[status],
        data: [],
        showInLegend: index === 0 ? showInLegend : false,
        dataLabels: [
          {
            enabled: true,
            formatter: function () {
              const selectedOriginData = originDestinationData.filter(
                (selectedPair) =>
                  moment(formatMomentInput(moment(selectedPair['start']), DATE_TIME_FORMAT_WITH_HR_MINS)).diff(
                    formatMomentInput(moment(this.point.low), DATE_TIME_FORMAT_WITH_HR_MINS)
                  ) === 0
              );
              if (selectedOriginData.length > 0) {
                if (
                  status === ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION ||
                  status === ConnectivityOutlookStatus.SATELLITE_HANDOVER ||
                  status === ConnectivityOutlookStatus.NO_SERVICE ||
                  status === ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS ||
                  status === ConnectivityOutlookStatus.OUT_OF_COVERAGE
                ) {
                  // Origin with timestamp label with flight leg start bar, event status icon
                  const eventLabel = `<div style="margin-left: -6px;margin-top: 20px;"> ${dataLabelFormatter(
                    this.point,
                    status
                  )} </div>`;
                  return originDestinationFormatter(
                    selectedOriginData[0]['origin'],
                    selectedOriginData[0]['start'],
                    'left',
                    'absolute',
                    false,
                    eventLabel
                  );
                } else {
                  // Origin with timestamp label with flight leg start , connected status start bar
                  return originDestinationFormatter(
                    selectedOriginData[0]['origin'],
                    selectedOriginData[0]['start'],
                    'left',
                    'none',
                    true
                  );
                }
              }
              if (
                status === ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION ||
                status === ConnectivityOutlookStatus.SATELLITE_HANDOVER ||
                status === ConnectivityOutlookStatus.NO_SERVICE ||
                status === ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS ||
                status === ConnectivityOutlookStatus.OUT_OF_COVERAGE
              ) {
                //events status icon
                return dataLabelFormatter(this.point, status);
              }

              return;
            },
            useHTML: true,
            align: 'left',
            inside: true,
            x: -12,
            allowOverlap: true,
            position: 'center'
          },
          {
            enabled: true,
            formatter: function () {
              const selectedDestinationData = originDestinationData.filter(
                (selectedPair) =>
                  moment(formatMomentInput(moment(selectedPair['end']), DATE_TIME_FORMAT_WITH_HR_MINS)).diff(
                    formatMomentInput(moment(this.point.high), DATE_TIME_FORMAT_WITH_HR_MINS)
                  ) === 0
              );
              if (selectedDestinationData.length > 0) {
                let showEventsTimelineConnectedBar = false;
                if (status === ConnectivityOutlookStatus.CONNECTED) {
                  showEventsTimelineConnectedBar = true;
                }
                return originDestinationFormatter(
                  selectedDestinationData[0]['destination'],
                  selectedDestinationData[0]['end'],
                  'right',
                  'none',
                  showEventsTimelineConnectedBar
                );
              }
            },
            useHTML: true,
            align: 'right',
            inside: true,
            x: -12,
            allowOverlap: true,
            position: 'center'
          }
        ],
        legendIndex: legendIndex,
        pointWidth: status === ConnectivityOutlookStatus.CONNECTED ? 4 : 21,
        states: {
          inactive: {
            opacity: 1
          }
        }
      });

      const newPoint = (row: IEventsTimelineData): SeriesDataPoint => ({
        x: 0,
        low: row.eventDate.valueOf(),
        high: row.eventDate.valueOf() + 60 * 1000,
        eventName: row.eventName,
        iconText: row.iconText
      });

      const initialSeries = eventsTimelineToDisplay.map((timeLineData: IFlightLegsEventsTimeLineData, index) => {
        return {
          [ConnectivityOutlookStatus.CONNECTED]: newSeries(ConnectivityOutlookStatus.CONNECTED, 2, true, index),
          [ConnectivityOutlookStatus.SATELLITE_HANDOVER]: newSeries(
            ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION,
            1,
            true,
            index
          ),
          [ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION]: newSeries(
            ConnectivityOutlookStatus.POSSIBLE_SERVICE_INTERRUPTION,
            1,
            false,
            index
          ),
          [ConnectivityOutlookStatus.OUT_OF_COVERAGE]: newSeries(ConnectivityOutlookStatus.NO_SERVICE, 0, true, index),
          [ConnectivityOutlookStatus.REGULATORY_RESTRICTIONS]: newSeries(
            ConnectivityOutlookStatus.NO_SERVICE,
            0,
            false,
            index
          ),
          [ConnectivityOutlookStatus.NO_SERVICE]: newSeries(ConnectivityOutlookStatus.NO_SERVICE, 0, false, index)
        };
      });

      const series = [];
      eventsTimelineToDisplay.map((timeLineData: IFlightLegsEventsTimeLineData, index) => {
        const seriesIndex = timeLineData.flightLegEvents.reduce((memo: any, row: IEventsTimelineData) => {
          const pt = newPoint(row);
          if (memo[row.status]) {
            memo[row.status].data = [...memo[row.status].data, pt];
          }
          return memo;
        }, initialSeries[index]);

        let seriesToDisplay = Object.keys(seriesIndex).map((key) => mergePointsInSeries(seriesIndex[key]));

        // Make the data entries to be ordered by the x value to eliminate all of the "Highcharts error #15" messages
        // Note: .sort() is sorting in-place
        for (const singleSeries of seriesToDisplay) {
          singleSeries.data.sort((a: SeriesDataPoint, b: SeriesDataPoint) => a.x - b.x);
          singleSeries.xAxis = timeLineData.xAxis;
          singleSeries.yAxis = timeLineData.yAxis;
        }
        series.push(...seriesToDisplay);
        return series;
      });
      // Tooltips
      const htmlTooltip = true;

      const tooltip = (i18n: any) => (input: any) => {
        const {
          point: {color, low, high},
          series: {name}
        } = input;
        return {
          label: {
            value: <ToolTipDateTimeRangeLabel dtLow={low} dtHigh={high} timeDurationTooltip={false} />
          },
          points: [
            {
              value: (
                <EventsTimelineToolTip
                  eventName={name}
                  prefixIconColor={color}
                  dtLow={low}
                  dtHigh={high}
                  seriesLabelWidth="150px"
                />
              )
            }
          ],
          color: false
        };
      };

      const legendTooltips = [
        {
          title: 'connectivity_outlook.events_timeline.no_service.title_tooltip',
          x: -120,
          y: -160,
          arrowX: -120
        },
        {
          title: 'connectivity_outlook.events_timeline.interruption.title_tooltip',
          x: 20,
          y: -160,
          arrowX: 20
        },
        {
          title: 'connectivity_outlook.events_timeline.connected.title_tooltip',
          x: 170,
          y: -160,
          arrowX: 125
        }
      ];

      const chartHeight = eventsTimelineChartHeight;
      const spacingRight = -95;
      const spacingLeft = -60;
      const marginBottom = 20;
      const barWidth = 21;
      const marginLeft = 15;
      const spacingTop = 3;
      const positioner = (chartContainer: any, plotX: number, plotY: number, boxWidth: number, boxHeight: number) => {
        return {x: plotX, y: plotY + 315};
      };
      const tooltipDisabled = true;

      const customLegendClick = (event) => {
        event.preventDefault();
      };
      setChartConfig({
        id,
        chartType,
        categories,
        series,
        tooltip,
        htmlTooltip,
        positioner,
        tooltipDisabled,
        defaultChartPalette,
        xAxisLabelPadding,
        customXAxis,
        highchartsIdBase,
        legendTooltips,
        noDataOptions,
        hideAllLegendItemsOnLoad,
        legendItemsToHideOnLoad,
        barWidth,
        chartHeight,
        plotLines,
        spacingLeft,
        spacingRight,
        marginBottom,
        customYAxis,
        customLegendClick,
        marginLeft,
        spacingTop
      });
    }
    // eslint-disable-next-line
  }, [eventsTimeline, flightPathInfo, flightPlanInfo, selectedFlightLegIdx, isLoading]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer>
      {Object.keys(formattedConfig).length === 0 ? <></> : <ColumnRangeChart {...formattedConfig} />}
    </ChartContainer>
  );
};
export default EventsTimeline;
