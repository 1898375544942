/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Hoverline via mouse over event
 */
import * as React from 'react';
import styled from '@emotion/styled';
import {
  useMouseOverEvent,
  getTimelineHoverLeft,
  getTimelineHoverDate,
  getTimelineHoverInside,
  getMapHoverLineLeft
} from './useDetailHoverLine';

import {ChartTimeSettings} from './flightDetailsUtil';
import {EventsTimelineData, EventsTimelineStatus} from './charts/EventsTimelineUtils';
import {
  NO_DATA_COLOR,
  ABOVE_TARGET_COLOR,
  ABOVE_HIGHER_THRESHOLD_COLOR,
  BELOW_LOWER_THRESHOLD_COLOR
} from '../common/theme/Colors';

const HoverLineParent = styled.div`
  height: 0px;
`;
const Line = styled.div`
  z-index: 1;
  position: absolute;
  width: 2px;
  background-color: ${NO_DATA_COLOR};
  pointer-events: none;
  opacity: 0.5;
`;
const Circle = styled.div`
  z-index: -1;
  position: absolute;
  width: 2px;
  background-color: ${NO_DATA_COLOR};
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

interface HoverLineProps {
  containerRef: any;
  parentLeftOffset: number;
  chartTimeSettings: ChartTimeSettings;
  liveFlightOffset: {
    startOffSet: number;
    unit: number;
  };
  isMinimized: boolean;
  eventsTimelineData: EventsTimelineData[];
  isLoading: boolean;
  lineHeight: number;
}

const HoverLine: React.FC<HoverLineProps> = ({
  containerRef,
  parentLeftOffset,
  chartTimeSettings,
  liveFlightOffset,
  isMinimized,
  eventsTimelineData,
  isLoading,
  lineHeight
}: HoverLineProps) => {
  const curr = containerRef ? containerRef.current : null;
  const topCurr = curr ? curr.parentElement.parentElement : null;
  const {scrollTop, offsetTop} = curr ? curr : ({} as any);
  const {scrollLeft} = topCurr ? topCurr : ({} as any);

  const {x, y, start: pathSegmentStart} = useMouseOverEvent();
  const insideMap = pathSegmentStart !== null;

  const chartStart = chartTimeSettings ? chartTimeSettings.start : '';

  const resolvedX = insideMap ? x : x + scrollLeft;

  const hoverDate = getTimelineHoverDate(
    resolvedX,
    parentLeftOffset,
    chartStart,
    liveFlightOffset?.startOffSet,
    liveFlightOffset?.unit
  );
  const inside = getTimelineHoverInside(y, offsetTop, scrollTop, isMinimized);

  const resolvedIndex = insideMap
    ? (eventsTimelineData || [])?.findIndex(
        ({eventDate, status}) =>
          eventDate.isSame(pathSegmentStart) && Object.values(EventsTimelineStatus).includes(status)
      )
    : eventsTimelineData?.findIndex(
        ({eventDate, status}) =>
          eventDate.toString() === hoverDate && Object.values(EventsTimelineStatus).includes(status)
      );

  const resolvedLeft = insideMap
    ? getMapHoverLineLeft(
        pathSegmentStart,
        chartStart,
        parentLeftOffset,
        liveFlightOffset?.startOffSet,
        liveFlightOffset?.unit
      )
    : getTimelineHoverLeft(resolvedX, parentLeftOffset);

  let background = NO_DATA_COLOR;

  if (!isLoading && (inside || insideMap) && resolvedIndex in eventsTimelineData) {
    const indexStatus = eventsTimelineData[resolvedIndex]?.status;
    if (indexStatus === EventsTimelineStatus.CONNECTED) {
      background = ABOVE_TARGET_COLOR;
    }
    if (indexStatus === EventsTimelineStatus.IMPAIRED) {
      background = ABOVE_HIGHER_THRESHOLD_COLOR;
    }
    if (indexStatus === EventsTimelineStatus.DISCONNECTED) {
      background = BELOW_LOWER_THRESHOLD_COLOR;
    }
  }

  return (
    <HoverLineParent id="hover-line">
      {!isLoading && (inside || insideMap) && resolvedIndex in eventsTimelineData ? (
        <>
          <Circle
            style={{
              left: resolvedLeft - 3,
              background
            }}
          />
          <Line
            style={{
              height: `${lineHeight}px`,
              left: resolvedLeft,
              background
            }}
          />
        </>
      ) : (
        <></>
      )}
    </HoverLineParent>
  );
};
export default HoverLine;
