/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Hook to listen to the location hook at add a breadbrumb history
 */

import {useState, useEffect} from 'react';
import {useLocation, useNavigationType} from 'react-router-dom';
import {setTitle} from './browserUtils';
import {_getStoreDataPersistKey} from './StoreUtils';

export interface HistoryBreadCrumbs {
  list: string[];
}

export default function useHistoryBreadcrumbs(): HistoryBreadCrumbs {
  const historyKey = _getStoreDataPersistKey('history');
  const existingHistory = sessionStorage.getItem(historyKey) || '[]';
  let parsedHistory;

  try {
    parsedHistory = JSON.parse(existingHistory);
  } catch (e) {
    parsedHistory = [];
  }
  const location = useLocation();
  const navigationType = useNavigationType();
  const [historyList, setHistoryList] = useState<string[]>(parsedHistory);

  useEffect(() => {
    setTitle();

    if (location.pathname !== historyList[historyList.length - 1]) {
      const backNavigation = historyList[historyList.length - 2] === location.pathname;
      const newHistory =
        navigationType === 'POP' && backNavigation ? historyList.slice(0, -1) : [...historyList, location.pathname];
      setHistoryList(newHistory);
      sessionStorage.setItem(historyKey, JSON.stringify(newHistory));
    }
    // eslint-disable-next-line
  }, [location]);

  return {list: historyList};
}
