/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Software Revisions Popup
 */
import React from 'react';
import {useIntl} from 'react-intl';
import styled from '@emotion/styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {BottomDataToolTip} from '@viasat/insights-components';
import PopUpModal from '../../common/Modal';
import {
  HEADER_ICON_BORDER_COLOR,
  SW_REVISIONS_HARDWARE_COLOR,
  SW_REVISIONS_ICON_COLOR,
  SW_REVISIONS_MODAL_TITLE_COLOR,
  SW_REVISIONS_TITLE_BORDER_COLOR
} from '../../common/theme/Colors';
import BoldSpan from '../../common/BoldSpan';
import {StatusIcon} from './util/aircraftStatusListUtil';
import {STATUS_UPDATE_AVAILABLE, getAirctaftSoftwareStatus} from '../../../store/queries/tailList/swVersionQuery';

const SoftwareRevisionsModalHeaderContainer = styled.div`
  width: 444px;
  display: flex;
  direction: row;
  border-bottom: 1px solid ${SW_REVISIONS_TITLE_BORDER_COLOR};
  margin-right: -16px;
  padding: 0px 16px 16px;
  #software-revisions-modal-tooltip {
    max-width: 100px;
  }
  .headerText {
    width: 90%;
    color: ${SW_REVISIONS_MODAL_TITLE_COLOR};
    font-weight: 500;
    .helpIcon {
      margin-left: 11.33px;
      margin-bottom: -5px;
      color: ${SW_REVISIONS_ICON_COLOR};
      cursor: pointer;
    }
  }
  .closeIcon {
    margin-top: 5px;
    color: ${SW_REVISIONS_ICON_COLOR};
    cursor: pointer;
    right: 16px;
    position: absolute;
  }
`;

const SoftwareRevisionsContainer = styled.div`
  width: 430px;
  height: auto;
  max-height: 408px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 24px 24px;
  font-family: Source Sans Pro;
`;

const SoftwareRevisionsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 8px;
  font-size: 16px;
`;

const SoftwareRevisionsHeaderRow = styled.div<{border?: boolean}>`
  width: 94%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-size: 16px;
  border-bottom: ${(props) => (props.border ? `1px solid ${HEADER_ICON_BORDER_COLOR}` : 'none')};
`;

const SoftwareRevisionsHeader = styled.div<{width?: string}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  color: ${SW_REVISIONS_MODAL_TITLE_COLOR};
  display: flex;
`;

const SoftwareRevisionsAircraftInformation = styled.div`
  width: 100%;
  color: ${SW_REVISIONS_MODAL_TITLE_COLOR};
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0;
`;
const SoftwareRevisionsAircraftLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

const SoftwareRevisionLabel = styled.div<{width}>`
  width: ${(props) => `${props.width}%`};
  font-weight: 400;
  color: ${SW_REVISIONS_HARDWARE_COLOR};
`;

const SoftwareRevisionValue = styled.div<{width}>`
  width: ${(props) => `${props.width}%`};
  font-weight: 600;
  color: ${SW_REVISIONS_MODAL_TITLE_COLOR};
  margin-right: 10px;
`;

const SoftwareRevisionsHelpText = styled.div`
  margin-top: 24px;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  color: #9fafbc;
`;
interface SoftwareRevisionsPopupProps {
  selectedAircraft: any;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  swVersionFields?: any;
}

const SoftwareRevisionsPopup: React.FC<SoftwareRevisionsPopupProps> = ({
  selectedAircraft,
  openModal,
  setOpenModal,
  swVersionFields
}) => {
  const intl = useIntl();
  const SW_VERSION_FIELDS = swVersionFields
    ? swVersionFields
    : {
        kaTerminalSwVersion: 'Ka-Band Terminal',
        bdtVersion: 'Beam Data Table (Ka)',
        kuTerminalSwVersion: 'Ku-Band Terminal',
        sedFileVersion: 'SED Map File (Ku)',
        sscfFileVersion: 'SSCF Map File (Ku)'
      };
  const LATEST_TERMINAL_VERSION_MAPPING = {
    kaTerminalVersion: 'latestKaTerminalVersion',
    bdtVersion: 'latestBdtVersion',
    kuTerminalVersion: 'latestKuTerminalVersion',
    sedFileVersion: 'latestSedVersion',
    sscfFileVersion: 'latestSscfVersion'
  };
  const overallAircraftSwStatus = getAirctaftSoftwareStatus(selectedAircraft);

  // function to close the modal.
  const closeModal = () => {
    setOpenModal(false);
  };
  /**
   * method that return the header of the software revisions modal
   * @returns software revisions modal header
   */
  const SoftwareRevisionsModalHeader = () => {
    return (
      <SoftwareRevisionsModalHeaderContainer>
        <div className="headerText">
          Software Revisions
          <BottomDataToolTip
            id={`software-revisions-modal-tooltip`}
            arrow
            placement="bottom"
            enterDelay={300}
            title={
              <div style={{lineHeight: '18px', fontSize: 14, margin: '6px 10px', maxWidth: '200px'}}>
                <BoldSpan>{intl.formatMessage({id: 'aircraft_status.software_revisions_modal_tooltip'})}</BoldSpan>
              </div>
            }
          >
            <InfoOutlinedIcon className="helpIcon" />
          </BottomDataToolTip>
        </div>
        <CloseIcon className="closeIcon" onClick={closeModal} />
      </SoftwareRevisionsModalHeaderContainer>
    );
  };

  return (
    <div>
      <PopUpModal
        id="swRevisionsModal"
        open={openModal}
        title={<SoftwareRevisionsModalHeader />}
        titlecolor={SW_REVISIONS_MODAL_TITLE_COLOR}
        borderRadius="16px"
        padding="0px"
      >
        <SoftwareRevisionsContainer>
          <SoftwareRevisionsHeaderRow border={true}>
            <SoftwareRevisionsAircraftInformation>
              <SoftwareRevisionsAircraftLabel>Serial Number</SoftwareRevisionsAircraftLabel>
              <SoftwareRevisionsAircraftLabel id="SoftwareRevisionsContainer__serialNumber">
                <BoldSpan>{selectedAircraft.serialNumber}</BoldSpan>
              </SoftwareRevisionsAircraftLabel>
            </SoftwareRevisionsAircraftInformation>
            <SoftwareRevisionsAircraftInformation>
              <SoftwareRevisionsAircraftLabel>Tail ID</SoftwareRevisionsAircraftLabel>
              <SoftwareRevisionsAircraftLabel>
                <BoldSpan>{selectedAircraft.tailId}</BoldSpan>
              </SoftwareRevisionsAircraftLabel>
            </SoftwareRevisionsAircraftInformation>
            <SoftwareRevisionsAircraftInformation>
              <SoftwareRevisionsAircraftLabel>Aircraft Type</SoftwareRevisionsAircraftLabel>
              <SoftwareRevisionsAircraftLabel>
                <BoldSpan>{selectedAircraft.aircraftType}</BoldSpan>
              </SoftwareRevisionsAircraftLabel>
            </SoftwareRevisionsAircraftInformation>
          </SoftwareRevisionsHeaderRow>
          <SoftwareRevisionsHeaderRow>
            <SoftwareRevisionsHeader>
              <BoldSpan>Software currently installed on aircraft</BoldSpan>
            </SoftwareRevisionsHeader>
          </SoftwareRevisionsHeaderRow>
          {overallAircraftSwStatus === STATUS_UPDATE_AVAILABLE && (
            <SoftwareRevisionsRow>
              <SoftwareRevisionLabel width={50} />
              <SoftwareRevisionValue id={`SoftwareRevisionsContainer__Installed-column`} width={20}>
                Installed
              </SoftwareRevisionValue>
              <SoftwareRevisionValue id={`SoftwareRevisionsContainer__updateAvailable-column`} width={30}>
                Update Available
              </SoftwareRevisionValue>
            </SoftwareRevisionsRow>
          )}

          {Object.keys(SW_VERSION_FIELDS)?.map((swVersion) => {
            return (
              <>
                {selectedAircraft[`${swVersion}`] ? (
                  <SoftwareRevisionsRow key={selectedAircraft[`${swVersion}`]}>
                    <SoftwareRevisionLabel width={50}>
                      <StatusIcon
                        version={selectedAircraft[`${swVersion}`]}
                        versionStatus={selectedAircraft[`${swVersion}Status`]}
                        marginBottom={-1.5}
                      />
                      {SW_VERSION_FIELDS[`${swVersion}`]}
                    </SoftwareRevisionLabel>
                    <SoftwareRevisionValue id={`SoftwareRevisionsContainer__${swVersion}`} width={25}>
                      {selectedAircraft[`${swVersion}`]}
                    </SoftwareRevisionValue>
                    {selectedAircraft[`${swVersion}Status`] === STATUS_UPDATE_AVAILABLE ? (
                      <SoftwareRevisionValue
                        id={`SoftwareRevisionsContainer__${LATEST_TERMINAL_VERSION_MAPPING[swVersion]}`}
                        width={25}
                      >
                        {selectedAircraft[`${LATEST_TERMINAL_VERSION_MAPPING[swVersion]}`]}
                      </SoftwareRevisionValue>
                    ) : (
                      <SoftwareRevisionValue width={25} />
                    )}
                  </SoftwareRevisionsRow>
                ) : null}
              </>
            );
          })}
          <SoftwareRevisionsHelpText>
            {intl.formatMessage({id: 'aircraft_status.software_revisions_helper_text'})}
          </SoftwareRevisionsHelpText>
        </SoftwareRevisionsContainer>
      </PopUpModal>
    </div>
  );
};

export default SoftwareRevisionsPopup;
