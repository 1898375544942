/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Tail List - Plan History Query
 */

import moment from 'moment';
import {DATE_VERBOSE_FULL_FORMAT} from '../../../utils/DateTimeUtils';
import {Query} from '../types';

export interface TailPlanHistoryInfo {
  aircraftId: string;
  tailId: string;
  planName: string;
  deactivatedTime: string;
}

const transformRawTailPlanHistoryInfo = (
  planHistoryList: TailPlanHistoryInfo[] | null
): TailPlanHistoryInfo[] | null => {
  if (!planHistoryList) return null;

  const planHistoryInfo: TailPlanHistoryInfo[] = planHistoryList.map((historyInfo) => ({
    aircraftId: historyInfo.aircraftId,
    tailId: historyInfo.tailId,
    planName: historyInfo.planName,
    deactivatedTime: historyInfo.deactivatedTime
      ? moment.utc(historyInfo.deactivatedTime).format(DATE_VERBOSE_FULL_FORMAT)
      : null
  }));

  return planHistoryInfo;
};

const tailPlanHistoryQuery: Query<TailPlanHistoryInfo[]> = {
  route: 'tailList/planHistory',
  transform: transformRawTailPlanHistoryInfo
};

export default tailPlanHistoryQuery;
