/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Equipment History Query
 */

import moment from 'moment';
import {Query} from '../types';

export interface EquipmentHistoryInfo {
    aircraftId: string;
    tailId: string;
    configurationType: string;
    partNumber: string;
    serialNumber: string;
    macAddress: string;
    lastModifiedDate: string;
    isRemoved: boolean;
}

interface EquipmentHistoryRaw {
    aircraftId: string;
    tailId: string;
    configurationType: string;
    partNumber: string;
    serialNumber: string;
    macAddress: string;
    lastModifiedDate: string;
    isRemoved: boolean;
}

/**
 * Transforms the given raw equipment history info data into (regular) equipment history info data
 * @param equipmentHistoryInfoRawList equipmentHistoryInfoRaw values from the api
 * @returns equipmentHistoryInfo Transformed equipment history info
 */
const transformRawEquipmentHistoryInfo = (equipmentHistoryInfoRawList: EquipmentHistoryRaw[] | null): EquipmentHistoryInfo[] | null => {
  if (!equipmentHistoryInfoRawList) return null;

  const equipmentHistoryInfo: EquipmentHistoryInfo[] = equipmentHistoryInfoRawList.map((equipmentHistoryInfoRaw) => ({
    aircraftId: equipmentHistoryInfoRaw.aircraftId,
    tailId: equipmentHistoryInfoRaw.tailId,
    configurationType: equipmentHistoryInfoRaw.configurationType,
    partNumber: equipmentHistoryInfoRaw.partNumber,
    serialNumber: equipmentHistoryInfoRaw.serialNumber,
    macAddress: equipmentHistoryInfoRaw.macAddress,
    lastModifiedDate: moment(new Date(equipmentHistoryInfoRaw.lastModifiedDate)).format('YYYY-MMM-DD'),
    isRemoved: equipmentHistoryInfoRaw.isRemoved
  }));

  return equipmentHistoryInfo;
};

const equipmentHistoryQuery: Query<EquipmentHistoryInfo[]> = {
  route: 'tailList/equipmentHistory',
  transform: transformRawEquipmentHistoryInfo
};

export default equipmentHistoryQuery;
