/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Switch Compact and Expanded button component
 */

import React from 'react';
import {ViewCompact} from '@mui/icons-material';
import styled from '@emotion/styled';

import {
  TOOLBAR_BUTTON_INACTIVE_TEXT_COLOR,
  TOOLBAR_BUTTON_DISABLED_TEXT_COLOR,
  ACTIVE_BUTTON_COLOR
} from '../common/theme/Colors';
import {getElementIdFromSectionBase} from '../../utils/ElementIdUtils';
import StyledButton from '../common/StyledButton';

const SwitchViewText = styled.span`
  font-family: 'Source Sans Pro';
  padding-left: 8px;
  padding-top: 1px;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  @media only screen and (max-width: 875px) {
    display: none;
  }
`;

interface SwitchCompactExpandedViewProps {
  compactView: boolean;
  isDisabled: boolean;
  onChangeColumnView: (showCompactView: boolean) => void;
}

const SwitchCompactExpandedView: React.FC<SwitchCompactExpandedViewProps> = ({
  compactView,
  isDisabled,
  onChangeColumnView
}) => {
  const color = !isDisabled ? TOOLBAR_BUTTON_INACTIVE_TEXT_COLOR : TOOLBAR_BUTTON_DISABLED_TEXT_COLOR;

  return (
    <StyledButton
      populated={compactView}
      disabled={isDisabled}
      onClick={() => onChangeColumnView(!compactView)}
      id={getElementIdFromSectionBase('aircraft-status', compactView ? 'expandedView' : 'compactView', 'button')}
    >
      <ViewCompact sx={{color: compactView ? ACTIVE_BUTTON_COLOR : color, fontSize: 24}} />
      <SwitchViewText>{'Compact View'}</SwitchViewText>
    </StyledButton>
  );
};

export default SwitchCompactExpandedView;
