/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ChartWrapper Container for Upload Usage
 */

import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import {useIntl} from 'react-intl';

import {LEGEND_TEXT, CHART_AXIS_MINOR_TICK, DOWNLOAD_UPLOAD_USAGE_CHART_PALETTE} from '../../common/theme/Colors';
import {
  DATE_CONCISE_FORMAT,
  TIME_CONCISE_NO_PAD_FORMAT,
  stringAsMs,
  msAsString,
  areMsTimestampMinutesEqualTo
} from '../../../utils/DateTimeUtils';

import {ChartType} from '../../common/elements/chart/types';
import {ChartTimeSettings, adjustCategorizedChartData, getXAxisTickPositions} from '../flightDetailsUtil';

import ToolTipDateTimeLabel from '../../common/elements/chart/ToolTip/ToolTipDateTimeLabel';

import DownloadUploadCardToolTip from './tooltip/DownloadUploadCardToolTip';
import {DATA_USAGE_UNITS_MB} from '../../../utils/constants';
import {formatConfig} from '../../common/elements/chart/chartUtils';
import LineChart from '../../common/elements/chart/LineChart';

export interface UploadUsageData {
  rl_usage: number;
  timestamp: string;
}
interface UploadUsageProps {
  currentTime: string;
  isLoading: boolean;
  uploadUsage: UploadUsageData[];
  hideAllLegendItemsOnLoad?: boolean;
  legendItemsToHideOnLoad?: string[];
  chartTimeSettings: ChartTimeSettings;
  parentLeftOffset: number;
  liveMask: boolean;
}

const ChartContainer = styled.div`
  .highcharts-xaxis-grid path {
    stroke-width: 0;
  }
  .highcharts-crosshair,
  .highcharts-yaxis-grid,
  .highcharts-xaxis path {
    stroke-width: 1px;
    stroke: ${CHART_AXIS_MINOR_TICK};
  }
`;

const UploadUsage: React.FC<UploadUsageProps> = ({
  currentTime,
  isLoading,
  uploadUsage,
  hideAllLegendItemsOnLoad,
  legendItemsToHideOnLoad,
  chartTimeSettings,
  parentLeftOffset,
  liveMask
}: UploadUsageProps) => {
  const [chartConfig, setChartConfig] = useState<any>(null);

  const intl = useIntl();

  useEffect(() => {
    if (!isLoading && chartTimeSettings.start && chartTimeSettings.end) {
      // General config
      const id = 'flightDetails--uploadUsage__timeSeries-chart';
      const highchartsIdBase = 'uploadUsage';
      const chartType = ChartType.LINE;
      const height = 160;
      const marginLeft = 75;
      const marginRight = 25;
      const defaultChartPalette = [];
      const palette = [];
      const seriesLabel = '';
      const connectNulls = false;
      const disableMarker = false;
      const uploadUsageData = uploadUsage ? uploadUsage : [];

      // X-axis
      let rawXAxisData = uploadUsageData.map((i) => stringAsMs(i.timestamp));
      const xAxisLabelPlotlines = [];
      const xAxisLabelXOffset = 0;
      const xAxisFormatter = (value: any) => {
        // Note: Minor ticks are not supported for category-based axes
        if (Number(value) < 1e12 || !areMsTimestampMinutesEqualTo(value, chartTimeSettings.labeledTickMinutesValues)) {
          return '';
        }

        const hourLabel = msAsString(value, TIME_CONCISE_NO_PAD_FORMAT);
        const dayLabel = msAsString(value, DATE_CONCISE_FORMAT);
        return hourLabel === '0:00' ? dayLabel : hourLabel;
      };

      // Y-axis
      const yAxis = [
        {
          label: `Upload Usage (${DATA_USAGE_UNITS_MB})`,
          labelXOffset: -5,
          plotline: undefined,
          plotlineColor: undefined,
          tickInterval: undefined,
          tickPositions: undefined,
          titleOffset: 50,
          opposite: false
        }
      ];

      const yAxisFormatter = (label: any) => label.value;

      // Series
      let uploadUsages = uploadUsageData.map((row) => row.rl_usage);
      let {xAxisData, yAxisData} = adjustCategorizedChartData(
        rawXAxisData,
        uploadUsages,
        chartTimeSettings,
        5,
        chartTimeSettings.tickIntervalMinutes / 2
      );
      const series = [
        {
          name: 'Upload Usage',
          color: DOWNLOAD_UPLOAD_USAGE_CHART_PALETTE[1],
          marker: {
            symbol: 'circle'
          },
          data: yAxisData,
          yAxis: 0
        }
      ];

      // Set the x-axis tick mark positions based on the adjusted x-axis data
      const xAxisTickPositions = getXAxisTickPositions(xAxisData, chartTimeSettings);

      // Legend
      const legend = false;
      const legendItemStyle = {
        fontSize: '12px',
        fontWeight: '600',
        color: LEGEND_TEXT
      };

      // Tooltips
      const htmlTooltip = true;
      const sharedTooltip = true;
      const tooltip = (i18n: any) => (input: any) => {
        const {x, points} = input;
        return {
          label: {
            value: <ToolTipDateTimeLabel dateTime={x} />
          },
          points: points.map((pt) => {
            const {
              y,
              color,
              series: {name: seriesName}
            } = pt;
            return {
              value: <DownloadUploadCardToolTip prefixIconColor={color} rateType={seriesName} avgRate={y} />
            };
          }),
          color: false
        };
      };

      // Handle noData Options
      const noDataOptions = {
        enabled: true,
        showEmptyXAxis: false,
        showEmptyYAxis: false
      };

      setChartConfig({
        id,
        highchartsIdBase,
        chartType,
        height,
        marginLeft,
        marginRight,
        defaultChartPalette,
        palette,
        seriesLabel,
        connectNulls,
        disableMarker,
        xAxisData,
        xAxisLabelPlotlines,
        xAxisTickPositions,
        xAxisLabelXOffset,
        xAxisFormatter,
        yAxis,
        yAxisFormatter,
        series,
        legend,
        legendItemStyle,
        hideAllLegendItemsOnLoad,
        legendItemsToHideOnLoad,
        htmlTooltip,
        sharedTooltip,
        tooltip,
        liveMask,
        noDataOptions
      });
    }
    // eslint-disable-next-line
  }, [uploadUsage, chartTimeSettings]);

  const formattedConfig = formatConfig(intl, chartConfig);

  return (
    <ChartContainer>
      {Object.keys(formattedConfig).length === 0 ? <></> : <LineChart {...formattedConfig} />}
    </ChartContainer>
  );
};

export default UploadUsage;
