/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft Status Card view list container component
 */
import React from 'react';
import styled from '@emotion/styled';
import {isNil} from 'lodash';
import {ConfirmationNumberOutlined, InsertChartOutlined, SupportAgent} from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import StarIconOutline from '@mui/icons-material/StarOutline';
import {Button} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {AirportCodeTooltip, LinkCellRenderer} from '@viasat/insights-components';
import {showTailSummaryColumn} from '../../../utils/config';
import {NO_DATA_INDICATOR, DATA_USAGE_AVAILABLE_TOOLTIP, NO_DATA_USAGE_TOOLTIP} from '../../../utils/constants';
import {getElementId, getElementIdFragmentBase, getElementIdFromSectionBase} from '../../../utils/ElementIdUtils';
import {getAvailabilityColor} from '../../../utils/MapUtil';
import AvailabilityDot from '../../common/AvailabilityDot';
import {ListGridDefaultedText} from '../../common/elements/listGridCells/ListGridDefaultedText';
import ListGridConnectionStatusTooltip from '../../common/elements/tooltip/ListGridConnectionStatusTooltip';
import {
  ABOVE_TARGET_COLOR,
  AIRCRAFT_LIST_OPTION_CONTAINER_COLOR,
  BELOW_LOWER_THRESHOLD_COLOR,
  DATA_USAGE_TIME_FILTER_COLOR,
  DRAG_BUTTON_BACKGROUND,
  GRID_CELL_LINK_COLOR,
  MODAL_TITLE_COLOR,
  PROGRESS_BAR_BG_COLOR,
  SIDEBAR_ICON_ACTIVE_COLOR,
  WHITE_COLOR
} from '../../common/theme/Colors';
import {StatusIcon, calculateConnectionStatusDuration} from './util/aircraftStatusListUtil';

const StatusContainer = styled.div<{bgColor: string; height: number}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 56px;
  height: ${(props) => props.height}px;
  background: ${(props) => props.bgColor};
  cursor: pointer;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionButtonLabel = styled.div`
  margin-left: 6px;
`;

const TailInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 340px;
  height: 140px;
  margin: 14px 14px 14px 18px;
  border-right: 1px solid ${DRAG_BUTTON_BACKGROUND};
`;

const FlightInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 325px;
  height: 112px;
  margin: 14px 14px 14px 18px;
  border-right: 1px solid ${DRAG_BUTTON_BACKGROUND};
`;

const SwRevisionsInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 325px;
  height: 112px;
  margin: 14px 14px 14px 18px;
  border-right: 1px solid ${DRAG_BUTTON_BACKGROUND};
`;

const CardActions = styled.div<{hasOpenCases: boolean; childIndex: number}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 187px;
  padding: 4px 0px;
  .card-action-btn {
    justify-content: left;
    margin: 4px 0px;
    border: 1px solid ${PROGRESS_BAR_BG_COLOR};
    border-radius: 20px;
    width: 167px;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 4px 12px;
    text-transform: none;
    color: ${AIRCRAFT_LIST_OPTION_CONTAINER_COLOR};
  }
  .card-action-btn:nth-child(${(props) => props.childIndex}) {
    color: ${(props) => (props.hasOpenCases ? WHITE_COLOR : GRID_CELL_LINK_COLOR)};
    background: ${(props) => (props.hasOpenCases ? SIDEBAR_ICON_ACTIVE_COLOR : 'none')};
  }
`;

const DataItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px;
`;

const DataLabel = styled.div<{width?: number}>`
  color: ${DATA_USAGE_TIME_FILTER_COLOR};
  width: ${(props) => (props.width ? props.width : '130')}px;
  display: flex;
  flex-direction: row;
  margin-right: 32px;
`;

const SwRevisionsLabel = styled.div`
  display: flex;
`;
const DataValue = styled.div<{color?: string; width?: string; flexDirection?: string}>`
  color: ${(props) => (props.color ? props.color : MODAL_TITLE_COLOR)};
  min-width: ${(props) => (props.width ? props.width : '196px')};
  max-width: 220px;
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
`;

const CardListDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
`;

interface CardListProps {
  listName: string;
  listData: any;
  isEndUser: boolean;
  isInternal: boolean;
  handleWatchListClick: (selectedRow: any) => void;
  handleSummaryMetricsLinkClick: (selectedRow: any) => void;
  handleOpenCasesClick: (selectedRow: any) => void;
  handleSupportRequestClick: (selectedRow: any) => void;
}

const LONG_TEXT_TRUNCATE_LENGTH = 24;

const idBase = 'aircraftStatusCardView';

const idElemBase = 'aircraftStatus';
const idSubSection = 'cardView';

const CardListContainer: React.FC<CardListProps> = ({
  listName,
  listData,
  isEndUser,
  isInternal,
  handleWatchListClick,
  handleSummaryMetricsLinkClick,
  handleOpenCasesClick,
  handleSupportRequestClick
}) => {
  return (
    <CardListDataDiv id={getElementIdFragmentBase(idElemBase, idSubSection, listName)}>
      {listData.map((aircraftStatus, index) => {
        const statusDuration = calculateConnectionStatusDuration(
          aircraftStatus.connectionStart,
          aircraftStatus.connectionEnd,
          aircraftStatus.status
        );
        const cellData = {
          value: aircraftStatus.tailId,
          link: `/flight/details/${aircraftStatus.flightId}`
        };
        return (
          <Card
            sx={{height: isInternal ? 165 : 140, margin: '9px 16px 16px 16px'}}
            id={getElementId(idElemBase, idSubSection, 'data', index)}
            key={aircraftStatus.serialNumber + '_' + index}
          >
            <CardContent sx={{padding: 0, display: 'flex'}}>
              <StatusContainer bgColor={getAvailabilityColor(aircraftStatus.status)} height={isInternal ? 165 : 140}>
                {aircraftStatus.favorite ? (
                  <StarIcon
                    id={getElementIdFragmentBase(idElemBase, idSubSection, 'watchlistAction')}
                    onClick={() => handleWatchListClick(aircraftStatus)}
                    sx={{color: WHITE_COLOR, margin: 2}}
                  />
                ) : (
                  <StarIconOutline
                    id={getElementIdFragmentBase(idElemBase, idSubSection, 'watchlistAction')}
                    onClick={() => handleWatchListClick(aircraftStatus)}
                    sx={{color: WHITE_COLOR, margin: 2}}
                  />
                )}
              </StatusContainer>
              <DataContainer>
                <TailInfo>
                  <DataItem>
                    <DataLabel>Serial Number</DataLabel>
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'serialNumber')}>
                      {aircraftStatus.serialNumber}
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Tail ID</DataLabel>{' '}
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'tailId')}>
                      <LinkCellRenderer cellData={cellData} row={aircraftStatus} />
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Aircraft Type</DataLabel>
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'aircraftType')}>
                      {aircraftStatus.aircraftType}
                    </DataValue>
                  </DataItem>
                  {isInternal ? (
                    <DataItem>
                      <DataLabel>VAR</DataLabel>
                      <DataValue>
                        <ListGridDefaultedText
                          cellText={aircraftStatus.valueAddedReseller}
                          maxLength={LONG_TEXT_TRUNCATE_LENGTH}
                          disable={false}
                          defaultText={'Unassigned'}
                        />
                      </DataValue>
                    </DataItem>
                  ) : (
                    <></>
                  )}
                  {!isEndUser ? (
                    <DataItem>
                      <DataLabel>Customer Name</DataLabel>
                      <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'customerName')}>
                        <ListGridDefaultedText
                          cellText={aircraftStatus.customer}
                          maxLength={LONG_TEXT_TRUNCATE_LENGTH}
                          disable={false}
                          defaultText={'Unassigned'}
                        />
                      </DataValue>
                    </DataItem>
                  ) : (
                    <></>
                  )}
                </TailInfo>
                <FlightInfo>
                  <DataItem>
                    <DataLabel>Flight Phase</DataLabel>
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'flightPhase')}>
                      {aircraftStatus.flightPhase}
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Origin</DataLabel>
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'origin')} width="fit-content">
                      {aircraftStatus.origin?.airportData ? (
                        <AirportCodeTooltip
                          airportCode={aircraftStatus.origin?.code?.toString()}
                          airport={aircraftStatus.origin?.airportData}
                          getFullElementId={(name: string, type: string) =>
                            `${aircraftStatus.origin.code}--airportTooltip__${name}-${type}`
                          }
                          position={'top'}
                          yMargin={'-10px'}
                          arrow={true}
                          xOffset={-300}
                          invertColors={false}
                          disable={isNil(aircraftStatus.origin.code)}
                        >
                          {aircraftStatus.origin.code}
                        </AirportCodeTooltip>
                      ) : aircraftStatus.origin?.code ? (
                        aircraftStatus.origin.code
                      ) : (
                        NO_DATA_INDICATOR
                      )}
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Destination</DataLabel>
                    <DataValue
                      id={getElementIdFragmentBase(idElemBase, idSubSection, 'destination')}
                      width="fit-content"
                    >
                      {aircraftStatus.destination?.airportData ? (
                        <AirportCodeTooltip
                          airportCode={aircraftStatus.destination?.code?.toString()}
                          airport={aircraftStatus.destination?.airportData}
                          getFullElementId={(name: string, type: string) =>
                            `${aircraftStatus.destination.code}--airportTooltip__${name}-${type}`
                          }
                          position={'top'}
                          yMargin={'-10px'}
                          arrow={true}
                          invertColors={false}
                          disable={isNil(aircraftStatus.destination.code)}
                        >
                          {aircraftStatus.destination.code}
                        </AirportCodeTooltip>
                      ) : aircraftStatus.destination?.code ? (
                        aircraftStatus.destination.code
                      ) : (
                        NO_DATA_INDICATOR
                      )}
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Flight Start</DataLabel>{' '}
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'departureTime')}>
                      {aircraftStatus.flightStart ? aircraftStatus.flightStart : NO_DATA_INDICATOR}
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Flight End</DataLabel>{' '}
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'arrivalTime')}>
                      {aircraftStatus.flightEnd ? aircraftStatus.flightEnd : NO_DATA_INDICATOR}
                    </DataValue>
                  </DataItem>
                </FlightInfo>
                <FlightInfo>
                  <DataItem>
                    <DataLabel>Connectivity Status</DataLabel>
                    <DataValue
                      id={getElementIdFragmentBase(idElemBase, idSubSection, 'connectivityStatus')}
                      color={getAvailabilityColor(aircraftStatus.status)}
                    >
                      {aircraftStatus.status}
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Status Duration</DataLabel>{' '}
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'statusDuration')}>
                      <ListGridConnectionStatusTooltip
                        placement="left"
                        icon={
                          <AvailabilityDot
                            id={aircraftStatus.flightId}
                            sx={{color: getAvailabilityColor(aircraftStatus.status), width: 17, height: 16}}
                          />
                        }
                        tooltipText={`${aircraftStatus.status} since ${aircraftStatus.connectionEnd} (${statusDuration})`}
                        idPrefix={'aircraftStatusCardList'}
                      >
                        {statusDuration}
                      </ListGridConnectionStatusTooltip>
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Flight Duration</DataLabel>{' '}
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'flightDuration')}>
                      {aircraftStatus.flightDuration ? aircraftStatus.flightDuration : NO_DATA_INDICATOR}
                    </DataValue>
                  </DataItem>
                  <DataItem>
                    <DataLabel>Data Usage</DataLabel>{' '}
                    <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'dataUsage')} flexDirection="row">
                      <ListGridConnectionStatusTooltip
                        placement="left"
                        icon={``}
                        tooltipText={
                          aircraftStatus.dataUsageStatus ? DATA_USAGE_AVAILABLE_TOOLTIP : NO_DATA_USAGE_TOOLTIP(statusDuration)
                        }
                        idPrefix={'aircraftStatusCardList'}
                      >
                        <AvailabilityDot
                          id={`dataUsageStatus_${aircraftStatus.aircraftId}`}
                          sx={{
                            color: aircraftStatus.dataUsageStatus ? ABOVE_TARGET_COLOR : BELOW_LOWER_THRESHOLD_COLOR,
                            width: 17,
                            height: 16
                          }}
                        />
                      </ListGridConnectionStatusTooltip>
                      {aircraftStatus.dataUsage ? `${aircraftStatus.dataUsage} MB` : NO_DATA_INDICATOR}
                    </DataValue>
                  </DataItem>
                </FlightInfo>

                <SwRevisionsInfo>
                  {aircraftStatus.kaTerminalSwVersion ? (
                    <DataItem key={`${aircraftStatus.kaTerminalSwVersion}-ka_terminal_sw_version_item`}>
                      <DataLabel width={160}>
                        <StatusIcon
                          version={aircraftStatus.kaTerminalSwVersion}
                          versionStatus={aircraftStatus.kaTerminalSwVersionStatus}
                        />

                        <SwRevisionsLabel>Ka-Band Terminal</SwRevisionsLabel>
                      </DataLabel>
                      <DataValue
                        id={getElementIdFragmentBase(idElemBase, idSubSection, 'ka_terminal_sw_version_value')}
                      >
                        {aircraftStatus.kaTerminalSwVersion}
                      </DataValue>
                    </DataItem>
                  ) : null}
                  {aircraftStatus.bdtVersion ? (
                    <DataItem key={`${aircraftStatus.bdtVersion}_bdt_version`}>
                      <DataLabel width={160}>
                        <StatusIcon
                          version={aircraftStatus.bdtVersion}
                          versionStatus={aircraftStatus.bdtVersionStatus}
                        />

                        <SwRevisionsLabel>Beam Data Table (Ka)</SwRevisionsLabel>
                      </DataLabel>
                      <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'bdt_version_value')}>
                        {aircraftStatus.bdtVersion}
                      </DataValue>
                    </DataItem>
                  ) : null}
                  {aircraftStatus.kuTerminalSwVersion ? (
                    <DataItem key={`${aircraftStatus.kuTerminalSwVersion}_ku_terminal_version_item`}>
                      <DataLabel width={160}>
                        <StatusIcon
                          version={aircraftStatus.kuTerminalSwVersion}
                          versionStatus={aircraftStatus.kuTerminalSwVersionStatus}
                        />

                        <SwRevisionsLabel>Ku-Band Terminal</SwRevisionsLabel>
                      </DataLabel>
                      <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'ku_terminal_version_value')}>
                        {aircraftStatus.kuTerminalSwVersion}
                      </DataValue>
                    </DataItem>
                  ) : null}
                  {aircraftStatus.sedFileVersion ? (
                    <DataItem key={`${aircraftStatus.sedFileVersion}-sed_version_item`}>
                      <DataLabel width={160}>
                        <StatusIcon
                          version={aircraftStatus.sedFileVersion}
                          versionStatus={aircraftStatus.sedFileVersionStatus}
                        />

                        <SwRevisionsLabel>SED Map File (Ku)</SwRevisionsLabel>
                      </DataLabel>
                      <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'sed_version_value')}>
                        {aircraftStatus.sedFileVersion}
                      </DataValue>
                    </DataItem>
                  ) : null}
                  {aircraftStatus.sscfFileVersion ? (
                    <DataItem key={`${aircraftStatus.sscfFileVersion}_sscf_version_item`}>
                      <DataLabel width={160}>
                        <StatusIcon
                          version={aircraftStatus.sscfFileVersion}
                          versionStatus={aircraftStatus.sscfFileVersionStatus}
                        />

                        <SwRevisionsLabel>SSCF Map File (Ku)</SwRevisionsLabel>
                      </DataLabel>
                      <DataValue id={getElementIdFragmentBase(idElemBase, idSubSection, 'sscf_version_value')}>
                        {aircraftStatus.sscfFileVersion}
                      </DataValue>
                    </DataItem>
                  ) : null}
                </SwRevisionsInfo>
                <CardActions hasOpenCases={aircraftStatus.openCases} childIndex={showTailSummaryColumn ? 2 : 1}>
                  {showTailSummaryColumn ? (
                    <Button
                      className="card-action-btn"
                      id={getElementIdFromSectionBase(idBase, `tailSummary-${index}`, 'button')}
                      onClick={() => handleSummaryMetricsLinkClick(aircraftStatus)}
                    >
                      <>
                        <InsertChartOutlined sx={{color: GRID_CELL_LINK_COLOR, width: '20px', height: '20px'}} />
                        <ActionButtonLabel> Tail Summary</ActionButtonLabel>
                      </>
                    </Button>
                  ) : (
                    <React.Fragment key="tailSummary"></React.Fragment>
                  )}

                  {aircraftStatus.openCases ? (
                    <Button
                      className="card-action-btn"
                      id={getElementIdFromSectionBase(idBase, `openCases-${index}`, 'button')}
                      onClick={() => handleOpenCasesClick(aircraftStatus)}
                    >
                      <>
                        <ConfirmationNumberOutlined
                          sx={{color: aircraftStatus.openCases ? WHITE_COLOR : GRID_CELL_LINK_COLOR}}
                        />
                        <ActionButtonLabel>
                          Open Cases {aircraftStatus.openCases ? `(${aircraftStatus.openCases})` : ''}
                        </ActionButtonLabel>
                      </>
                    </Button>
                  ) : (
                    <></>
                  )}

                  <Button
                    className="card-action-btn"
                    id={getElementIdFromSectionBase(idBase, `contactSupport-${index}`, 'button')}
                    onClick={() => handleSupportRequestClick(aircraftStatus)}
                  >
                    <>
                      <SupportAgent sx={{color: GRID_CELL_LINK_COLOR, width: '20px', height: '20px'}} />
                      <ActionButtonLabel>Contact Support</ActionButtonLabel>
                    </>
                  </Button>
                </CardActions>
              </DataContainer>
            </CardContent>
          </Card>
        );
      })}
    </CardListDataDiv>
  );
};

export default CardListContainer;
