/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Reducer for Filters state - Should be composed
 */
import {isEmpty, partition} from 'lodash';
import {Severity} from '@viasat/insights-components';

import {createSnackBar, SnackBar} from './SnackBarReducer';

export interface FilterRow {
  filterId: string;
  domainOptionsKey?: string;
  rangeOptionsKeys?: Array<string>;
}

export interface OptionRow {
  optionKey: string;
  optionValue: string;
}

export interface RangeOption {
  [optionKey: string]: OptionRow[];
}

export interface FiltersState {
  filters: FilterRow[];
  domainOptions: OptionRow[];
  rangeOptions: RangeOption;
}

export const InitialFiltersState: FiltersState = {
  filters: [],
  domainOptions: [],
  rangeOptions: {}
};

declare type ParentState = {filters: FiltersState; snackBar?: SnackBar[]};

export enum FilterAction {
  SET_DOMAIN_OPTION = 'SET_DOMAIN_OPTION',
  SET_RANGE_OPTION = 'SET_RANGE_OPTION',
  REMOVE_FILTER = 'REMOVE_FILTER',
  REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS',
  ADD_FILTER = 'ADD_FILTER',
  SET_DOMAIN_RANGE_OPTIONS = 'SET_DOMAIN_RANGE_OPTIONS',
  SET_SELECTED_FILTER = 'SET_SELECTED_FILTER'
}

/**
 * Checks if all rangeOptions are populated
 *
 * @param rangeOptions Range options set in the store
 * @returns True if all rangeOptions values populated
 */
export const areRangeOptionsLoaded = (rangeOptions: RangeOption): boolean =>
  Object.entries(rangeOptions).every(([key, value]: [string, OptionRow[]]) => value.length >= 1);

/**
 * Checks if a filter has all valid values
 *
 * @param filter Filter set in the store
 * @param rangeOptions Range options set in the store
 * @returns True if all filter values are correct else fails
 */
export const isValidFilter = (filter: FilterRow, rangeOptions: RangeOption): boolean =>
  Boolean(filter.domainOptionsKey) &&
  Boolean(filter.rangeOptionsKeys) &&
  typeof rangeOptions[filter.domainOptionsKey] !== 'undefined' &&
  rangeOptions[filter.domainOptionsKey].length > 0 &&
  Boolean(
    rangeOptions[filter.domainOptionsKey].find((domainOption: OptionRow) =>
      filter.rangeOptionsKeys.includes(domainOption.optionKey)
    )
  );

/**
 * Checks if filters added from URL are valid, generates a snackbar for all invalid filters
 *
 * @param state Current filter state
 * @returns state
 */
export const validateFilters = (state: ParentState): ParentState => {
  const {rangeOptions, filters, domainOptions} = state.filters;
  if (isEmpty(rangeOptions) || !areRangeOptionsLoaded(rangeOptions)) {
    return state;
  }
  const [validFilters, invalidFilters] = partition(
    filters,
    (filter: FilterRow) =>
      isValidFilter(filter, rangeOptions) ||
      filter.rangeOptionsKeys === undefined ||
      filter.rangeOptionsKeys.length === 0
  );
  return {
    ...state,
    filters: {
      ...state.filters,
      filters: validFilters
    },
    // eslint-disable-next-line
    snackBar: invalidFilters.reduce((memo: SnackBar[], filter: FilterRow) => {
      const validKey = domainOptions.find((domainOption: OptionRow) => {
        return domainOption.optionKey === filter.domainOptionsKey;
      });
      const filterKey = validKey ? validKey.optionValue : filter.domainOptionsKey;
      if (memo) {
        return [
          ...memo,
          createSnackBar({
            message: `Invalid Filter: (${filterKey} : ${filter.rangeOptionsKeys})`,
            severity: Severity.WARNING,
            getFullElementId: (name, type) => `${name}-${type}`
          })
        ].sort((a: SnackBar, b: SnackBar) => b.date.diff(a.date));
      }
    }, state.snackBar)
  };
};

/**
 * Embedded store for Filters
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const FiltersReducer = (state: ParentState, action: any): ParentState => {
  const {...payload} = action.payload || {};

  switch (action.type) {
    case FilterAction.SET_DOMAIN_RANGE_OPTIONS:
      state = validateFilters({
        ...state,
        filters: {
          ...state.filters,
          domainOptions: payload.domainOptions,
          rangeOptions: payload.rangeOptions
        }
      });
      break;
    case FilterAction.SET_SELECTED_FILTER:
      state = {
        ...state,
        filters: {
          ...state.filters,
          filters: payload.filters
        }
      };
      break;
    default:
      break;
  }

  return state;
};
