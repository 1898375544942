/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: View As Customer Button
 */

import React, {useCallback} from 'react';
import styled from '@emotion/styled';
import {Visibility as VisibilityIcon} from '@mui/icons-material';
import {getElementIdFromSectionBase} from '../../../../utils/ElementIdUtils';
import StyledButton from '../../StyledButton';
import {useStore} from '../../../../store/Store';
import {InitAction} from '../../../../store/reducers/InitReducer';
import {viewAsCustomerEnabled, hideViewAsCustomerForDemoUser} from '../../../../utils/config';
import {MODAL_FONT_COLOR} from '../../theme/Colors';

interface ViewAsCustomerButtonProps {
  idBase: string;
}

const ButtonLabel = styled.span`
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
  @media only screen and (max-width: 875px) {
    display: none;
  }
`;

const ViewAsCustomerButton: React.FC<ViewAsCustomerButtonProps> = ({idBase}) => {
  const {
    store: {
      init: {viewAsCustomer, isInternal, isDemoUser}
    },
    dispatch
  } = useStore();

  const onClick = useCallback(() => {
    dispatch({type: InitAction.SET_VIEW_AS_CUSTOMER});
  }, [dispatch]);

  return viewAsCustomerEnabled && (isInternal || (isDemoUser && !hideViewAsCustomerForDemoUser)) ? (
    <StyledButton
      id={getElementIdFromSectionBase(idBase, 'viewAsCustomer', 'button')}
      populated={Boolean(viewAsCustomer)}
      onClick={onClick}
    >
      <VisibilityIcon style={{fontSize: 24, color: MODAL_FONT_COLOR}} />
      <ButtonLabel>View as Customer</ButtonLabel>
    </StyledButton>
  ) : (
    <></>
  );
};

export default ViewAsCustomerButton;
