/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Satellite Health Card on the Flight Details Page
 */

import React from 'react';
import {useIntl} from 'react-intl';
import {ChartCard, SatelliteBeamIcon} from '@viasat/insights-components';

import {ChartTimeSettings} from '../flightDetailsUtil';
import {ISatelliteHealthData} from '../../../store/queries/flightDetails/satelliteHealthQuery';
import SatelliteHealth from '../charts/SatelliteHealth';
import {useStore} from '../../../store/Store';
import {FlightDetailsAction} from '../../../store/reducers/FlightDetailsReducer';

export interface SatelliteHealthCardProps {
  chartData: ISatelliteHealthData[];
  currentTime: string;
  isLoading: boolean;
  chartTimeSettings: ChartTimeSettings;
  liveMask: boolean;
  draggableProps?: any;
}

const SatelliteHealthCard: React.FC<SatelliteHealthCardProps> = ({
  currentTime,
  chartData,
  isLoading,
  chartTimeSettings,
  liveMask,
  draggableProps
}) => {
  const intl = useIntl();

  // hide button click handler
  const {dispatch, store} = useStore();
  const {isValueAddedReseller, isInternal} = store.init;
  const onHideClick = (id) =>
    dispatch({
      type: FlightDetailsAction.SET_FLIGHT_DETAILS_CARD_DISPLAY,
      payload: {
        hide: true,
        id
      }
    });

  return (
    <div id="SatelliteHealthCardEvents">
      <ChartCard
        draggableProps={draggableProps}
        isInternal={isInternal || !isValueAddedReseller}
        onHideClick={onHideClick}
        getFullElementId={(name, type) => `flightDetails--satelliteHealth__${name}-${type}`}
        Icon={SatelliteBeamIcon}
        title={!isValueAddedReseller ? 'Satellite Beam Number Used' : 'Satellites Used'}
        disableHelpIcon={false}
        helpTooltipMessage={
          !isValueAddedReseller
            ? intl.formatMessage({id: 'flight_details.satellite_health.title_tooltip'})
            : intl.formatMessage({id: 'flight_details.satellite_health_var.title_tooltip'})
        }
        summaries={[]}
        isLoading={isLoading}
        isError={false}
        chartHeight={195}
      >
        <SatelliteHealth
          currentTime={currentTime}
          satelliteHealthData={chartData}
          isLoading={isLoading}
          chartTimeSettings={chartTimeSettings}
          liveMask={liveMask}
          isValueAddedReseller={isValueAddedReseller}
        />
      </ChartCard>
    </div>
  );
};

export default SatelliteHealthCard;
