/**
 * Copyright (C) 2022 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Styled Linear progress bar component
 */

import React from 'react';
import {makeStyles} from 'tss-react/mui';
import {PROGRESS_BAR_BG_COLOR} from './theme/Colors';
import LinearProgress from '@mui/material/LinearProgress';
interface ILinearProps {
  value: number;
  color: string;
  progressBgColor?: string;
  barBorderRadius?: number;
  barHeight?: number;
}
const LinearProgressBar: React.FC<ILinearProps> = ({value, color, progressBgColor, barBorderRadius, barHeight}) => {
  const useStyles = makeStyles()(() => {
    return {
      root: {
        height: barHeight ? barHeight : 10,
        borderRadius: barBorderRadius ? barBorderRadius : 5
      },
      colorPrimary: {
        backgroundColor: progressBgColor ? progressBgColor : PROGRESS_BAR_BG_COLOR
      },
      bar: {
        borderRadius: barBorderRadius ? barBorderRadius : 5,
        backgroundColor: color
      }
    };
  });

  const {classes} = useStyles();

  return (
    <LinearProgress
      variant="determinate"
      value={value}
      classes={{
        root: classes.root,
        colorPrimary: classes.colorPrimary,
        bar: classes.bar
      }}
    />
  );
};
export default LinearProgressBar;
